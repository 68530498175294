import React from 'react';
import { makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles((theme) => ({
  AddButton: {
    backgroundColor: '#1076ff',
    borderRadius: '50%',
    cursor: 'pointer',
    color: 'white',
    marginInlineStart: 5,
    marginInlineEnd: 5,
    marginBottom: 18
  },
  MinusButton: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    cursor: 'pointer',
    color: 'white',
    marginInlineStart: 5,
    marginInlineEnd: 5,
    marginBottom: 18
  },
  Container: {
    marginTop: 15
  }
}));

export const CustomSlider = ({ children, onAddClick, onMinusClick, disabled }) => {
  const classes = useStyles();

  return (
    <div className={classes.Container}>
      <RemoveIcon disabled={disabled} fontSize="medium" onClick={!disabled && onMinusClick} className={classes.MinusButton} />
      {children}
      <AddIcon disabled={disabled} fontSize="medium" onClick={!disabled && onAddClick} className={classes.AddButton} />
    </div>
  );
};
