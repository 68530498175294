import { guardedClient } from '../../utils/axios-instance';

export const fetchSubBlocks = async (blockId) => {
  const result = await guardedClient.get('/subblocks', { params: { blockId } });
  return result.data;
};

export const addSubBlock = async (subBlock) => {
  const result = await guardedClient.post('/subblocks', subBlock);
  return result.data;
};

export const updateSubBlock = async ({ subBlockId, name }) => {
  const result = await guardedClient.put(`/subblocks/${subBlockId}`, { name });
  return result.data;
};

export const fetchSubBlockById = async (id) => {
  const result = await guardedClient.get(`/subblocks/${id}`);
  return result.data;
};

export const deleteSubBlock = async (id) => {
  const result = await guardedClient.delete(`/subblocks/${id}`);
  return result.data;
};
