import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Typography,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';
import axios from 'axios';
import { Close } from '@material-ui/icons';
import { useStores } from '../../store/root/root.store';
import GridMap from '../gridMaps/GridMap';
import Map from '../maps/chaperone-map';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  closeButton: {
    color: theme.palette.inverted.main
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  }
}));

export const MapPathDialog = ({ state, show = false, onClose = () => {} }) => {
  const classes = useStyles();
  const { applicationStore } = useStores();
  const [type, setType] = useState('googleMap');
  const [gridMapData, setGridMapData] = useState([]);
  const [cords, setCords] = useState([]);
  useEffect(() => {
    axios
      .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region: state.region ?? state.subsection.region_id,
        property: state.property ?? state.subsection.property_id,
        section: state.section ?? state.subsection.section_id,
        subsection: Number(state.subsection) ?? state.subsection.id
      })
      .then((result) => {
        setGridMapData(result.data.data);
        const cordsResults = result.data.data.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.long) }));
        // console.log(cords)
        setCords(cordsResults);
      });
  }, []);

  return (
    <Dialog open={show} className={classes.root} fullWidth>
      <DialogTitle className={classes.title}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="p">
            Subsection Visualization
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            {type === 'noMap' && <GridMap gridMapData={gridMapData} />}
            {type === 'googleMap' && <Map googleMapData={cords} />}
            <FormControl className={classes.formControl}>
              <Typography variant="h5" component="span" className={classes.label}>
                Map View
              </Typography>
              <Grid item>
                <RadioGroup row defaultValue="googleMap" name="mapView" onChange={(_, value) => setType(value)}>
                  <FormControlLabel value="googleMap" control={<Radio />} label="Google Map Path" />
                  <FormControlLabel value="noMap" control={<Radio />} label="Grid Map Path" />
                </RadioGroup>
              </Grid>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
