import {
  makeStyles,
  Grid,
  Fade,
  Typography,
  useTheme,
  useMediaQuery,
  Box,
  CircularProgress,
  Dialog,
  DialogContent
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../store/root/root.store';
import AuditCard from '../components/audit/audit-card.component';
import AuditDataDisplay from '../components/audit/audit-data-display.component';
import AuditSort from '../components/audit/audit-sort.component';
import { formatTimestamp } from '../utils/audits.utils';

const useStyles = makeStyles((theme) => ({
  normalScreen: {
    padding: theme.spacing(2)
  },
  smallScreen: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  text: {
    color: theme.palette.inverted.main
  },
  // https://stackoverflow.com/questions/74113968/material-ui-fade-scroll-styling-on-reportscomponent
  scrollable: {
    width: '100%',
    gridAutoFlow: 'column',
    gridTemplateColumns: 'repeat(auto-fill,minmax(15em, 1fr)) !important',
    gridAutoColumns: 'minmax(15em, 1fr)',
    overflowX: 'scroll'
  },
  progress: {
    color: theme.palette.secondary.main
  },
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0
    }
  }
}));

export const AutonomyAuditReportsPage = observer(() => {
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { autonomyAuditReportsStore } = useStores();
  const [scrollPosition, setScrollPosition] = React.useState(0);
  const [maxScrollHeight, setMaxScrollHeight] = useState(0);

  useEffect(() => {
    autonomyAuditReportsStore.selectRecord(null);
    autonomyAuditReportsStore.setFirstLoad(true);
  }, []);

  let reportsComponent = null;

  useEffect(() => {
    reportsComponent = document.getElementById('reportsComponent');
    const computeScroll = () => {
      setScrollPosition(reportsComponent.scrollTop);
    };
    reportsComponent.addEventListener('scroll', computeScroll);
    return () => reportsComponent.removeEventListener('scroll', computeScroll);
  });

  useEffect(() => {
    if (scrollPosition < 100 && autonomyAuditReportsStore.showFilter !== true) autonomyAuditReportsStore.setShowFilter(true);
    else if (scrollPosition > 0 && autonomyAuditReportsStore.showFilter !== false) autonomyAuditReportsStore.setShowFilter(false);
    setMaxScrollHeight(reportsComponent.scrollHeight - reportsComponent.clientHeight);
  }, [scrollPosition]);

  // Global variable set to beginning of time
  let lastDate = 0;

  const displayDate = (timestamp) => {
    if (formatTimestamp(timestamp, true).date !== formatTimestamp(lastDate, true).date) {
      lastDate = timestamp;
      return true;
    }
    return false;
  };

  return (
    <>
      <Fade in>
        <Grid container direction="row" className={isSmall ? classes.smallScreen : classes.normalScreen} spacing={2}>
          <Grid item xs={12} md={4} lg={4} xl={3}>
            <Box>
              <AuditSort />
            </Box>
            <Box
              id="reportsComponent"
              className={classes.scrollable}
              sx={{
                maxHeight: autonomyAuditReportsStore.showFilter ? '33vh' : '80vh',
                maskImage:
                  scrollPosition < maxScrollHeight - 10
                    ? ['linear-gradient(to top, transparent 0%, black 24px, black calc(100% - 5px), transparent 100%)']
                    : ['linear-gradient(to top, black calc(100% - 5px), transparent 100%)']
              }}
            >
              {autonomyAuditReportsStore.records.map((record, index) => (
                <React.Fragment key={`${record.timestamp}-${record.robotSerialNumber}`}>
                  {displayDate(record.timestamp) || index === 0 ? (
                    <Box mt={1}>
                      <Grid container direction="row" justifyContent="space-between" alignItems="center">
                        <Grid item>
                          <Typography align="left" variant="h6" className={classes.text} gutterBottom>
                            {formatTimestamp(record.timestamp, true).date}
                          </Typography>
                        </Grid>
                        {index === 0 && (
                          <Grid item>
                            <Typography align="right" variant="subtitle1" className={classes.text} gutterBottom>
                              {`${autonomyAuditReportsStore.records.length} ${
                                autonomyAuditReportsStore.records.length === 1 ? 'record' : 'records'
                              }`}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                    </Box>
                  ) : null}
                  <AuditCard
                    record={record}
                    robotSerialNumber={record.robotSerialNumber}
                    subsection={record.subsectionName}
                    timestamp={record.timestamp}
                    completedSuccessfully={record.completedSuccessfully}
                    maxDeviation={record.maxDeviation}
                    averageDeviation={record.averageDeviation}
                    isLoading={autonomyAuditReportsStore.selectedRecord?.loading}
                  />
                </React.Fragment>
              ))}
              {autonomyAuditReportsStore.loading && (
                <Grid container justifyContent="center">
                  <Box mt={3} mb={1}>
                    <CircularProgress className={classes.progress} />
                  </Box>
                </Grid>
              )}
              {!autonomyAuditReportsStore.records.length && !autonomyAuditReportsStore.loading && (
                <Box mt={2}>
                  <Typography align="center" variant="h4" className={classes.text}>
                    No Results
                  </Typography>
                  <Typography align="center" variant="h5" className={classes.text}>
                    Try changing your filter parameters.
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={8} lg={8} xl={9}>
            <AuditDataDisplay />
          </Grid>
        </Grid>
      </Fade>
      <Dialog
        className={classes.dialog}
        onClose={() => autonomyAuditReportsStore.setShowFullScreenDialog(false)}
        open={autonomyAuditReportsStore.showFullScreenDialog}
        fullScreen
      >
        <DialogContent>
          <AuditDataDisplay />
        </DialogContent>
      </Dialog>
    </>
  );
});
