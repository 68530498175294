import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography, Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import RobotsTable from '../../components/tables/RobotsTable';
import { ROUTE_AUDIT_OFFICER_DETAILS, ROUTE_SOLAR_AUDIT_OFFICER_DETAILS, ROUTE_AUDIT_OFFICER } from '../routes';
import { useStores } from '../../store/root/root.store';
import { SOLAR_LAWN_MOWING } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  titleLabel: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  }
}));

export const AuditOfficerPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const { autonomyRobotStore } = useStores();
  const isSolarUseCase = localStorage.getItem('useCase') === SOLAR_LAWN_MOWING;

  const getRobots = async () => {
    autonomyRobotStore.getRobots();
  };
  useEffect(() => {
    getRobots();
  }, []);

  const handleRobotSelection = (robotId, status) => {
    const currentSelectedRobot = autonomyRobotStore.getById(robotId);
    autonomyRobotStore.setSelectedRobot(robotId);
    if ((status ?? currentSelectedRobot.status) !== 'OFFLINE') {
      push({
        pathname: isSolarUseCase ? ROUTE_SOLAR_AUDIT_OFFICER_DETAILS : ROUTE_AUDIT_OFFICER_DETAILS,
        state: {
          robotId,
          region: currentSelectedRobot.current_region_id,
          property: currentSelectedRobot.current_property_id
        }
      });
    }
  };

  /**
   * Sort robots in table by its status
   * IN_USE -> EXEC_SUSPENDED -> WPS_SUSPENDED -> AVAILABLE -> OFFLINE
   */
  const statusOrder = { IN_USE: 1, EXEC_SUSPENDED: 2, WPS_SUSPENDED: 3, AVAILABLE: 4, OFFLINE: 5 };
  const sortRobotsCompareFn = (a, b) => (statusOrder[a.status] > statusOrder[b.status] ? 1 : -1);

  return (
    <Fade in={true}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item>
          <Typography variant="h3" className={classes.titleLabel}>
            Audit Robots
          </Typography>
          <br />
        </Grid>
        <RobotsTable
          sortRobots={sortRobotsCompareFn}
          selectable
          onSelectRobot={handleRobotSelection}
          location={ROUTE_AUDIT_OFFICER}
          realTimeUpdate
          propertyDropDown
        />
      </Grid>
    </Fade>
  );
});
