import { guardedClient } from '../../utils/axios-instance';

export const fetchBlocks = async (propertyId) => {
  const result = await guardedClient.get('/blocks', { params: { propertyId } });
  return result.data;
};

export const addBlock = async (block) => {
  const result = await guardedClient.post('/blocks', block);
  return result.data;
};

export const updateBlock = async ({ blockId, panelWidth, name }) => {
  const result = await guardedClient.put(`/blocks/${blockId}`, { panelWidth: Number(panelWidth), name });
  return result.data;
};

export const fetchBlockById = async (id) => {
  const result = await guardedClient.get(`/blocks/${id}`);
  return result.data;
};

export const deleteBlock = async (id) => {
  const result = await guardedClient.delete(`/blocks/${id}`);
  return result.data;
};

/**
 * This service function makes an API request to reset the status of all subrows
 * belonging to block with {blockId} to NOT_STARTED
 * @param {Number} blockId Block ID where subrows belonging to it will be reset
 * @returns request result
 */
export const resetBlockSubrows = async (blockId, resetType) => {
  if (blockId) {
    const result = await guardedClient.patch('/blocks/reset-block', {
      blockId,
      resetType
    });
    if (result) return result;
  }
  return null;
};
