import { types, flow } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { Block } from './models/block.model';
import { addBlock, deleteBlock, fetchBlockById, fetchBlocks, updateBlock } from '../../services/api/blocks.service';
import { alphaNumericOrder } from '../../utils/ui.utils';

const BlocksStoreInternal = types
  .model('BlocksStore', {
    blocks: types.array(Block),
    selectedBlockId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedBlockId,
    getById: (id) => self.blocks.find((r) => r.id === parseInt(id)),
    getByName: (name) => self.blocks.find((r) => r.name === name),
    getSelectedBlock: () => self.blocks.find((r) => r.id === self.selectedBlockId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      return yield fetchBlocks(filter);
    }),
    getApiCreate: flow(function* f(block) {
      return yield addBlock(block);
    }),
    getApiUpdate: flow(function* f(block) {
      return yield updateBlock(block);
    }),
    onFetchSuccess(results) {
      self.blocks = results.toSorted(alphaNumericOrder((c) => c.name));
    },
    onCreateSuccess(created) {
      console.log('sucess');
      //  self.regions.push(created);
    },
    onUpdateSuccess(data) {
      console.log(data);
    },
    onSaveError(error) {
      console.log(error);
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load Blocks');
    },
    addBlock: flow(function* addBlock(block) {
      return yield self.create(block);
    }),
    getBlocks: flow(function* getBlocks(propertyId) {
      yield self.fetch(propertyId);
    }),
    updateBlock: flow(function* updateBlock(block) {
      return yield self.update(block);
    }),
    getBlockById({ id }) {
      return fetchBlockById(id);
    },
    deleteBlock({ id }) {
      return deleteBlock(id);
    },
    setSelectedBlock(id) {
      self.selectedBlockId = id;
    },
    clearSelectedBlock() {
      self.selectedBlockId = null;
    },
    setError(msg) {
      self.throwError(msg);
    },
    clearBlocks() {
      self.blocks = [];
    },
    checkDuplicates(name, propertyId) {
      return self.blocks.some((item) => item.name === name && item.property_id === propertyId);
    }
  }));

export const BlocksStore = types.compose(BlocksStoreInternal, ResourceStore).named('BlocksStore');
