import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Typography, Button, Fade, useTheme, useMediaQuery } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import RobotsTable from '../components/tables/RobotsTable';
import { useStores } from '../store/root/root.store';
import { ROUTE_ADD_ROBOT, ROUTE_MANAGE_ROBOTS } from './routes';

const useStyles = makeStyles((theme) => ({
  normalScreen: {
    padding: theme.spacing(2)
  },
  smallScreen: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  text: {
    color: theme.palette.inverted.main
  },
  button: {
    height: 40,
    width: 150,
    backgroundColor: '##0000FF'
  }
}));

export const ManageRobotsPage = observer(() => {
  const history = useHistory();
  const { autonomyRobotStore } = useStores();
  const classes = useStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const getRobots = async () => {
    autonomyRobotStore.getRobots();
  };

  useEffect(() => {
    getRobots();
  }, []);

  return (
    <Fade in>
      <Grid container direction="column" className={isSmall ? classes.smallScreen : classes.normalScreen} spacing={2}>
        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={7} container direction="row" justifyContent="flex-start" alignItems="center">
            <Typography align="left" variant="h3" className={classes.text}>
              Manage Robots
            </Typography>
          </Grid>
          <Grid item xs={5} container direction="row" justifyContent="flex-end" alignItems="flex-start">
            <Button variant="contained" color="secondary" className={classes.button} onClick={() => history.push(ROUTE_ADD_ROBOT)}>
              Add New Robot
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <RobotsTable showManageActions realTimeUpdate location={ROUTE_MANAGE_ROBOTS} propertyDropDown />
        </Grid>
      </Grid>
    </Fade>
  );
});
