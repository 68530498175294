import React from 'react';
import { Route, Link, Redirect } from 'react-router-dom';
import { useStores } from '../../store/root/root.store';
import { useObserver } from 'mobx-react-lite';
import { Typography, Grid, Paper, makeStyles, Button, CircularProgress, Fade } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(4)
  },
  background: {
    paddingTop: theme.spacing(2)
  }
}));

export const PrivateRoute = ({ children, checkValidRoute = () => true, ...rest }) => {
  const { authenticationStore } = useStores();
  const classes = useStyles();

  return useObserver(() => (
    <>
      {authenticationStore.loading ? (
        <CircularProgress />
      ) : (
        <>
          {authenticationStore.isAuthenticated() ? (
            checkValidRoute() ? (
              <Route {...rest} render={({ location }) => children} />
            ) : (
              <Redirect to="/home" />
            )
          ) : (
            <Fade in={true}>
              <Grid container direction="row" justifyContent="center" alignItems="center" className={classes.background}>
                <Grid item xs={4}>
                  <Paper elevation={0} className={classes.card} color="inverted">
                    <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
                      <Grid container item xs={12}>
                        <Typography variant="h4">Unauthorized</Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Typography variant="body1">You are not authorized to view this page.</Typography>
                      </Grid>
                      <Grid container item xs={12}>
                        <Button component={Link} to="/" fullWidth variant="contained" color="secondary" disableElevation>
                          Return to login
                        </Button>
                      </Grid>
                    </Grid>
                  </Paper>
                </Grid>
              </Grid>
            </Fade>
          )}
        </>
      )}
    </>
  ));
};
