import { types } from 'mobx-state-tree';

export const AutonomyAuditFilter = types.model({
  countryId: types.maybeNull(types.number),
  regionId: types.maybeNull(types.number),
  propertyId: types.maybeNull(types.number),
  sectionId: types.maybeNull(types.number),
  pathType: types.maybeNull(types.string),
  subsectionIds: types.maybeNull(types.array(types.number)),
  completedSuccessfully: types.maybeNull(types.boolean),
  useCase: types.maybeNull(types.boolean),
  robots: types.maybeNull(types.array(types.string)),
  startTime: types.maybeNull(types.number),
  endTime: types.maybeNull(types.number),
  averageDeviationStart: types.maybeNull(types.number),
  averageDeviationEnd: types.maybeNull(types.number),
  maxDeviationStart: types.maybeNull(types.number),
  maxDeviationEnd: types.maybeNull(types.number),
  nextToken: types.maybeNull(types.string)
});
