import { useEffect, useState } from 'react';
import { getAllUsers } from '../../services/api/users.service';

export const useUsers = () => {
  const [users, setUsers] = useState([]);

  const fetchUsers = async () => {
    try {
      const res = await getAllUsers();
      setUsers(res.results);
    } catch {
      setUsers([]);
    }
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  return [users, fetchUsers];
};
