import * as math from 'mathjs';

// ---------------- DEVIATION CALCULATIONS ----------------

// Calculates the cross product of a 2D vector
const cross = (a, b) => {
  return a[0] * b[1] - a[1] * b[0];
};

export const generateDeviationArray = async (taughtPath, repeatedPath) => {
  let currentIndex = 0;

  const maxTaughtIndex = taughtPath.length;
  const maxRepeatedIndex = repeatedPath.length;

  const lateralError = [];

  // Skips over duplicate entries from taught path CSVs
  while (taughtPath[currentIndex].x === taughtPath[currentIndex + 1].x || taughtPath[currentIndex].y === taughtPath[currentIndex + 1].y) {
    currentIndex += 1;
  }

  for (let i = 0; i < maxRepeatedIndex; i += 1) {
    const row = repeatedPath[i];
    const pos = [];
    pos.push(parseFloat(row.x), parseFloat(row.y));
    const lookaheadPos = [];
    lookaheadPos.push(parseFloat(taughtPath[currentIndex].x), parseFloat(taughtPath[currentIndex].y));
    let j = 0;
    let prevDistance = Number.MAX_SAFE_INTEGER;

    while (currentIndex + j + 2 < maxTaughtIndex) {
      const desiredPathAccessor = [];
      desiredPathAccessor.push(parseFloat(taughtPath[currentIndex + j + 2].x), parseFloat(taughtPath[currentIndex + j + 2].y));
      if (math.norm(math.subtract(pos, lookaheadPos)) > math.norm(math.subtract(pos, desiredPathAccessor))) {
        currentIndex = currentIndex + 1 + j;
      } else if (prevDistance < math.norm(math.subtract(pos, desiredPathAccessor))) break;

      const desiredPathTemp = [];
      desiredPathTemp.push(parseFloat(taughtPath[currentIndex + 1].x), parseFloat(taughtPath[currentIndex + 1].y));
      prevDistance = math.norm(math.subtract(pos, desiredPathTemp));
      j += 1;
    }
    const p1 = lookaheadPos;
    const p2 = [];
    p2.push(parseFloat(taughtPath[currentIndex + 1].x), parseFloat(taughtPath[currentIndex + 1].y));
    const p3 = pos;

    lateralError.push(math.norm(math.divide(cross(math.subtract(p2, p1), math.subtract(p1, p3)), math.norm(math.subtract(p2, p1)))));
  }
  return lateralError;
};

// ---------------- DATE FORMATTING ----------------

const isToday = (date) => {
  const today = new Date();
  return today.getFullYear() === date.getFullYear() && today.getMonth() === date.getMonth() && today.getDate() === date.getDate();
};

const isYesterday = (date) => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  return (
    date.getDate() === yesterday.getDate() && date.getMonth() === yesterday.getMonth() && date.getFullYear() === yesterday.getFullYear()
  );
};

const isSameYear = (date) => {
  const today = new Date();
  return today.getFullYear() === date.getFullYear();
};

export const formatTimestamp = (timestamp, isShort) => {
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(timestamp * 1000);
  let formattedTime = {};

  if (isShort) {
    formattedTime = date.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric' });
    if (isToday(date)) return { time: formattedTime, date: 'Today' };
    else if (isYesterday(date)) return { time: formattedTime, date: 'Yesterday' };
    let options = {};
    if (isSameYear(date)) options = { weekday: 'short', month: 'short', day: 'numeric' };
    else options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    return {
      time: formattedTime,
      date: date.toLocaleDateString('en-US', options)
    };
  }
  formattedTime = date.toLocaleTimeString('en-US', { hour12: true, hour: 'numeric', minute: 'numeric', second: 'numeric' });

  let options = {};
  if (isSameYear(date)) options = { weekday: 'long', month: 'long', day: 'numeric' };
  else options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

  return `${date.toLocaleDateString('en-US', options)}, ${formattedTime}`;
};

// ---------------- DEVIATION FORMATTING ----------------

function roundToTwoDigits(num) {
  const absNum = Math.abs(num);
  const log10 = Math.log10(absNum);
  const digits = Math.max(0, 2 - Math.floor(log10));
  return +num.toFixed(digits);
}

export const formatDeviation = (deviation) => {
  if (deviation) {
    // Deviation comes in as meters
    if (deviation >= 1) return `${roundToTwoDigits(deviation)}m`;
    if (deviation >= 0.01) return `${roundToTwoDigits(deviation * 100)}cm`;
    return `${roundToTwoDigits(deviation * 1000)}mm`;
  }
};

// ---------------- SUBROW FORMATTING ----------------

export const getSubrowName = (subsection) => {
  const splittedRow = subsection?.split('/').at(-1);
  const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
  return splittedRowName;
};

export const getPathType = (uri) => {
  const regexWithPathType = new RegExp(`(\\d+)\\/(\\d+)\\/(\\d+)\\/(.+)\\/subrows\\/(.+)\\/(.+)\\.csv`);

  if (uri.match(regexWithPathType)) {
    return uri.match(regexWithPathType)[4];
  }
  return 'legacy';
};
