import { types } from 'mobx-state-tree';

export const Subsection = types.model({
  id: types.integer,
  name: types.string,
  chaperone_name: types.string,
  execution_period: types.number,
  order_index: types.integer,
  repeat_status: types.maybeNull(types.string),
  operation_robot: types.string,
  recording_date: types.string,
  last_execution_date: types.maybeNull(types.string),
  last_edit_date: types.maybeNull(types.string),
  max_speed: types.maybeNull(types.integer),
  lookahead_distance: types.maybeNull(types.integer),
  x_threshold: types.maybeNull(types.integer),
  y_threshold: types.maybeNull(types.integer),
  created_at: types.string,
  updated_at: types.string,
  section_id: types.integer,
  property_id: types.integer,
  region_id: types.integer,
  street_address: types.maybeNull(types.string),
  current_robot_serial_number: types.maybeNull(types.string)
});
