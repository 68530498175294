import { guardedClient } from '../../utils/axios-instance';

/*
    @todo split this service file to multiple files
    based on the resources, one file per resource
*/
export class RegionsService {
  resourceUrl = '/';

  regionsUrl = '/regions';

  sectionsUrl = '/sections';

  subsectionsUrl = '/subsections';

  uploadUrl = '/file-manager';

  countriesUrl = '/countries';

  propertyUrl = '/properties';

  // Regions
  async fetchRegions(countryId) {
    const result = await guardedClient.get(`${this.regionsUrl}`, {
      params: {
        countryId
      }
    });
    return result.data;
  }

  async fetchRegionsByCountryId(countryId) {
    const result = await guardedClient.get(`${this.regionsUrl}?country=${countryId}`);
    return result.data;
  }

  async fetchRegionById(regionId) {
    const result = await guardedClient.get(`${this.regionsUrl}/${regionId}`);
    return result.data;
  }

  async addRegion(region) {
    const result = await guardedClient.post(`${this.regionsUrl}`, region);
    return result.data;
  }

  async updateRegion(region) {
    const result = await guardedClient.put(`${this.regionsUrl}/${region.regionId}`, {
      name: region.name,
      country_id: region.countryId
    });
    return result.data;
  }

  async deleteRegion(id) {
    const result = await guardedClient.delete(`${this.regionsUrl}/${id}`);
    return result.data;
  }

  // properties
  async fetchProperties(region) {
    const result = await guardedClient.get(`${this.propertyUrl}?region=${region}`);
    return result.data;
  }

  async fetchPropertyById(id) {
    const result = await guardedClient.get(`${this.propertyUrl}/${id}`);
    return result.data;
  }

  async addProperty(property) {
    const result = await guardedClient.post(`${this.propertyUrl}`, property);
    return result.data;
  }

  async updateProperty(property) {
    const result = await guardedClient.put(`${this.propertyUrl}/${property.propertyId}`, {
      name: property.name,
      city: property.address.city,
      postalCode: property.address.postalCode,
      province: property.address.province,
      street: property.address.street
    });
    return result.data;
  }

  async deleteProperty(id) {
    const result = await guardedClient.delete(`${this.propertyUrl}/${id}`);
    return result.data;
  }

  // sections
  async fetchSections(propertyId) {
    const result = await guardedClient.get(`${this.sectionsUrl}`, { params: { propertyId } });
    return result.data;
  }

  async fetchSectionById(id) {
    const result = await guardedClient.get(`${this.sectionsUrl}/${id}`);
    return result.data;
  }

  async updateSection(section) {
    const result = await guardedClient.put(`${this.sectionsUrl}/${section.sectionId}`, {
      panel_width: Number(section.panelWidth),
      name: section.name
    });
    return result.data;
  }

  async addSection(section) {
    const result = await guardedClient.post(`${this.sectionsUrl}`, section);
    return result.data;
  }

  async deleteSection(id) {
    const result = await guardedClient.delete(`${this.sectionsUrl}/${id}`);
    return result.data;
  }

  // subsections
  async fetchSubsections(country, region, property, section) {
    const result = await guardedClient.get(`${this.subsectionsUrl}`, {
      params: {
        country,
        region,
        property,
        section
      }
    });
    return result.data;
  }

  async addSubsection(subsection) {
    const result = await guardedClient.post(`${this.subsectionsUrl}`, subsection);
    return result.data;
  }

  // file-manager

  async uploadSolarRows(solarRowsFile) {
    const result = await guardedClient.post(`${this.uploadUrl}/subrows`, solarRowsFile);
    return result.data;
  }

  // countries
  async fetchCountries() {
    const result = await guardedClient.get(`${this.countriesUrl}`);
    return result.data;
  }

  async addCountry(country) {
    const result = await guardedClient.post(`${this.countriesUrl}`, country);
    return result.data;
  }

  // @todo: modify this
  async modifySubsections(subsection) {
    const result = await guardedClient.patch(`${this.subsectionsUrl}/${subsection.id}`, subsection);
    return result.data;
  }

  async swapSubsections(items) {
    const result = await guardedClient.post(`${this.subsectionsUrl}/swap`, items);
    return result.data;
  }

  async deleteSubsection(id) {
    const result = await guardedClient.delete(`${this.subsectionsUrl}/${id}`);
    return result.data;
  }
}
