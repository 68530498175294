import { makeStyles } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '../../store/root/root.store';
import { AuditMapMarker } from './audit-map-marker.component';
import { findCenter } from '../../utils/google-maps-helpers';

const useStyles = makeStyles((theme) => ({
  map: {
    // Forced to set hight for google maps.  Perhaps revisit trying to calculate
    height: '700px',
    width: '100%',
  },
}));

export const AuditReportsDataMap = () => {
  const classes = useStyles();
  const { auditReportsStore, applicationStore } = useStores();
  const env = applicationStore.getEnvironment();

  /**
   * Runs when the google maps api has init'd
   * @param {*} map
   * @param {*} googleMaps
   */
  const handleApiLoaded = (map, googleMaps) => {
    console.debug('Google Maps API loaded', map, googleMaps);

    // Zooms the map to include all given points
    const bounds = new googleMaps.LatLngBounds();
    auditReportsStore.recordsWithGpsCoords.map((r) =>
      bounds.extend({ lat: r.robot_state_stamped.robot_state.location_state.latitude, lng: r.robot_state_stamped.robot_state.location_state.longitude })
    );
    map.fitBounds(bounds);

    // Mobile
    googleMaps.event.addDomListener(map, 'touchend', function (event) {});
    googleMaps.event.addListener(map, 'touchend', function (event) {});
  };

  const record = auditReportsStore.recordsWithGpsCoords.length > 0 ? auditReportsStore.recordsWithGpsCoords[0] : null;
  const averageCenter = findCenter(auditReportsStore.recordsWithGpsCoords.map((r) => r.location));
  const center = record ? { lat: averageCenter.latitude, lng: averageCenter.longitude } : env.googleMaps.defaultCenter;

  return useObserver(() => {
    return (
      <div className={classes.map}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "AIzaSyDUGf8FJu1Jm7oY4OrEQ4MnaHLwJOLNtHc", v: '3.43' }}
          defaultCenter={env.googleMaps.defaultCenter}
          center={center}
          defaultZoom={20.56 || env.googleMaps.defaultZoom}
          onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
          yesIWantToUseGoogleMapApiInternals
          options={{
            streetViewControl: true,
            overviewMapControl: true,
            disableDefaultUI: false,
            zoomControl: true,
            mapTypeId: 'hybrid',
            draggable: true
          }}
        >
          {auditReportsStore.recordsWithGpsCoords.map((record) => {
            return (
              <AuditMapMarker
                key={record.id}
                reportRecord={record}
                lat={record.robot_state_stamped.robot_state.location_state.latitude}
                lng={record.robot_state_stamped.robot_state.location_state.longitude}
              />
            );
          })}
        </GoogleMapReact>
      </div>
    );
  });
};
