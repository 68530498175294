import { types } from 'mobx-state-tree';
import { RobotStateStamped } from './audit-robot-state-stamped.model';

export const DataViewOptions = types.enumeration('DataView', ['table', 'map', 'snail']);

export const AuditFilter = types.model({
  startTime: types.maybeNull(types.Date),
  endTime: types.maybeNull(types.Date),
  robot: types.maybeNull(types.string),
  country: types.maybeNull(types.union(types.number, types.string)),
  region: types.maybeNull(types.union(types.number, types.string)),
  property: types.maybeNull(types.union(types.number, types.string)),
  country_name: types.maybeNull(types.string),
  region_name: types.maybeNull(types.string),
  property_name: types.maybeNull(types.string),
  operator: types.maybeNull(types.string),
  robot_state_stamped: types.maybeNull(RobotStateStamped),
  dataView: types.optional(DataViewOptions, 'table'),
  isVideoFeed: types.maybeNull(types.boolean),
  robotList: types.maybeNull(types.array(types.string))
});
