import React, { useState } from 'react';
import { Button, Fade, Modal, Grid, Paper, makeStyles, FormControl, TextField, Typography } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute'
  },
  paper: {
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& > div': { marginBottom: '5px' },
    paddingTop: '10px',
    paddingBottom: '10px',
    width: '40%',
    height: '35%'
  },
  components: {
    display: 'flex',
    alignItems: 'center'
  },
  textbox: {
    marginTop: '5px'
  },
  save: {
    marginLeft: '10px'
  }
}));

/**
 *
 * @param {Boolean} open - Shows or hides component
 * @param {Function} onClose - Function handles closing the comment box
 * @param {Function} onSave - Handles closing the comment box
 * @param {Object} infraction - An infraction object instance
 * @param {Function} handleComment - Handles saving comments to the database
 * @param {String} infractionComment - An infraction comment
 *
 */
export const CommentModal = observer(({ open, onClose, onSave, infraction, handleComment, infractionComment }) => {
  const classes = useStyles();
  const [comment, setComment] = useState([]);

  /**
   * Handles closing comment box
   * @param {*} event
   * @param {*} reason
   */
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      onClose();
    }
  };

  return (
    <Modal
      open={open}
      onClose={(event, reason) => {
        handleClose(event, reason);
      }}
      className={classes.modal}
      closeAfterTransition
    >
      <Fade in={open}>
        <Paper className={classes.paper}>
          <Typography variant="h4">Comments</Typography>
          <Grid container className={classes.components}>
            <FormControl fullWidth>
              <TextField
                defaultValue={infractionComment}
                fullWidth
                className={classes.textbox}
                placeholder=""
                variant="filled"
                multiline
                rows={7}
                onChange={(event) => {
                  setComment(event.target.value);
                }}
              >
                {' '}
              </TextField>
            </FormControl>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end">
            <Button
              variant="outlined"
              onClick={() => {
                onClose(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => {
                onSave();
                handleComment(infraction.id, comment, new Date(infraction.created_at));
              }}
              className={classes.save}
            >
              Save
            </Button>
          </Grid>
        </Paper>
      </Fade>
    </Modal>
  );
});
