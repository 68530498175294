import { differenceInMilliseconds } from 'date-fns';
import { flow, getEnv, getRoot, types } from 'mobx-state-tree';
export const ResourceStore = types
  .model('ResourceStore', {
    loading: false,
    saving: false,
    hasMoreResults: false,
    lastResult: types.maybeNull(types.string),
    lastFetch: types.maybeNull(types.Date)
  })
  .views((self) => ({
    isLoading: () => self.loading,
    isSaving: () => self.saving
  }))
  .actions((self) => ({
    fetch: flow(function* fetch(filter = null) {
      try {
        self.loading = true;
        const { nextToken, results, hasMoreResults, lastResult = null } = yield self.getApiFetch(filter);

        self.onFetchSuccess(results, nextToken);

        self.hasMoreResults = hasMoreResults;
        self.lastResult = hasMoreResults ? lastResult : null;
        self.lastFetch = new Date();
      } catch (error) {
        console.error('Fetch Error', error, error.response?.data);
        self.onFetchError(error.response?.data);
      } finally {
        self.loading = false;
      }
    }),
    lazyFetch: flow(function* lazyFetch() {
      const { lazyCacheTimeout } = getEnv(self);
      if (self.lastFetch === null || differenceInMilliseconds(new Date(), self.lastFetch) > lazyCacheTimeout) {
        yield self.fetch();
      }
    }),
    create: flow(function* create(entity) {
      try {
        self.loading = true;
        const data = yield self.getApiCreate(entity);
        self.onCreateSuccess(data);
        return data;
      } catch (error) {
        console.error('Create Error', error.response?.data);
        self.onCreateError(error.response?.data);
      } finally {
        self.loading = false;
      }
    }),
    update: flow(function* update(entity) {
      try {
        self.saving = true;
        const data = yield self.getApiUpdate(entity);
        self.onUpdateSuccess(data);
      } catch (error) {
        console.error('Update Error', error);
        self.onSaveError(error.response?.data);
      } finally {
        self.saving = false;
      }
    }),
    del: flow(function* del(entity) {
      try {
        self.loading = true;
        const { data } = yield self.getApiDelete(entity);
        self.onDeleteSuccess(data);
      } catch (error) {
        console.error('Delete Error', error.response?.data);
        self.onSaveError(error.response?.data);
      } finally {
        self.loading = false;
      }
    }),
    throwError(message) {
      const { applicationStore } = getRoot(self);
      applicationStore.pushError('Error', message);
    }
  }));
