import React, { useEffect, useState } from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline, Marker } from 'react-google-maps';
import greenMarker from '../../assets/images/green-marker.png';

function ChaperoneMap({ googleMapData, currentPositionIndex = 0, robotLat, robotLng, resetRobot }) {
  let path = [];
  const [progress, setProgress] = useState([]);
  const [dragged, setDragged] = useState(false); // state used to disable el robot tracking after using dragging action
  const [center, setCenter] = useState({}); // state used to store the map center

  useEffect(() => {
    path = googleMapData.filter((_, index) => index >= currentPositionIndex);
    setCenter({ lat: Number(path?.[0]?.lat ?? undefined), lng: Number(path?.[0]?.lng ?? undefined) });
  }, [googleMapData]);

  useEffect(() => {
    if (robotLat && robotLng) {
      if (robotLat && robotLat !== 0 && robotLng && robotLng !== 0) {
        setProgress([...progress, { lat: robotLat, lng: robotLng }]);
        if (!dragged) {
          // set the center of the Map to be equal the center of the Robot only if user is not controlling the map
          setCenter({ lat: Number(robotLat) || Number(path[0].lat), lng: Number(robotLng) || Number(path[0].lng) });
        }
      }
    }
  }, [robotLat, robotLng]);

  useEffect(() => {
    if (resetRobot) {
      setProgress([]);
    }
  }, [resetRobot]);

  const mapOptions = {
    zoomControl: false,
    mapTypeControl: false,
    scaleControl: false,
    streetViewControl: false,
    rotateControl: false,
    fullscreenControl: false
  };

  return path.length ? (
    <GoogleMap
      defaultZoom={18}
      center={{ lat: center.lat, lng: center.lng }}
      mapTypeId="satellite"
      tilt={0}
      options={mapOptions}
      onDragStart={() => {
        // Disable robot following and reset center to return to the normal behaviour
        setDragged(true);
        setCenter({});
      }}
      onDragEnd={() => {
        // Enable robot following and reset center to return to the normal behaviour
        setDragged(false);
      }}
    >
      <>
        <Polyline path={path} options={{ strokeColor: '#00ffff' }} />
        {progress.length && <Polyline path={progress} options={{ strokeColor: '#FF0000' }} />}
        <Marker position={progress[progress.length - 1]} />
        {!progress.length && <Marker icon={greenMarker} position={path[0]} />}
      </>
    </GoogleMap>
  ) : (
    ''
  );
}
const MapComponent = withScriptjs(withGoogleMap(ChaperoneMap));
const MapView = ({
  googleMapData,
  currentPositionIndex,
  robotLat,
  robotLng,
  resetRobot,
  customStyle,
  width = '100%',
  height = '400px'
}) => (
  <MapComponent
    currentPositionIndex={currentPositionIndex}
    googleMapData={googleMapData}
    robotLat={robotLat}
    robotLng={robotLng}
    resetRobot={resetRobot}
    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDUGf8FJu1Jm7oY4OrEQ4MnaHLwJOLNtHc&v=3.exp&libraries=geometry,drawing,places"
    loadingElement={<div style={{ height: '100%' }} />}
    containerElement={<div style={{ MarginTop: '20px', height, width }} className={customStyle} />}
    mapElement={<div style={{ height: '100%' }} />}
  />
);
export default MapView;
