import React, { useState, useEffect } from 'react';
import { Slider, Typography, Grid, makeStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { useStores } from '../../store/root/root.store';

const StyledSlider = withStyles({
  active: {},
  thumb: {
    height: 16,
    width: 16,
    backgroundColor: '#fff',
    marginTop: -8,
    marginLeft: -8
  },
  track: {
    height: 2
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#bfbfbf'
  },
  mark: {
    backgroundColor: '#bfbfbf',
    height: 16,
    width: 1,
    marginTop: -6
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor'
  }
})(Slider);

const useStyles = makeStyles((theme) => ({
  label: {
    textTransform: 'uppercase'
  }
}));

export const ControlSlider = ({ label = '', defaultValue = 0, robotControlMode, onChange = () => {}, marks, ...props }) => {
  const [value, setValue] = useState(null);
  const { controlStore } = useStores();
  const classes = useStyles();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  /** Function returns boolean to denote if component should be disabled
   */
  const isDisabled = () => {
    let disabled = false;
    if (label === 'Deck Offset' || label === 'Carriage Offset' || robotControlMode === 'manual') {
      disabled = true;
    }
    return disabled;
  };
  return (
    <Grid item container direction="column" justifyContent="space-between" alignItems="stretch">
      <Grid item>
        <Typography className={classes.label} gutterBottom>
          {label}
        </Typography>
      </Grid>
      <Grid item style={{ marginTop: 0, marginRight: 3, marginBottom: 0, marginLeft: 3 }}>
        <StyledSlider
          {...props}
          disabled={isDisabled()}
          onChange={(e, newValue) => {
            setValue(newValue);
            if (newValue !== value) {
              onChange(newValue);
            }
          }}
          value={value}
          marks={marks}
        />
      </Grid>
    </Grid>
  );
};
