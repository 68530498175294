/* eslint-disable no-unused-expressions */
import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Grid, Typography, Slider } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { useStores } from '../../store/root/root.store';
import { CustomSlider } from '../core/custom-slider.component';
import { LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD, GAMMA, CONTRAST } from '../../services/chaperone/robot-connection.service';

const RepeatSettingsDialog = observer(
  ({
    isChaperoneRepeat,
    readOnlyMode,
    handleClose,
    serialNumber,
    linearSpeed,
    lookAheadDistance,
    angularSpeed,
    videoGamma,
    videoContrast,
    interRowDirection
  }) => {
    const { MultiRobotsStore } = useStores();
    const valuetext = (value) => `${value}°C`;

    const changeLinearSpeed = (event, newValue) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { linearSpeed: newValue });
    };

    const changeLookAheadDistance = (event, newValue) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { lookAheadDistance: newValue });
    };

    const changeAngularSpeed = (event, newValue) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { angularSpeed: newValue });
    };

    const changeGamma = (event, newValue) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { videoGamma: newValue });
    };

    const changeContrast = (event, newValue) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { videoContrast: newValue });
    };

    const changeInterRowDirection = (event, newInterRowDirection) => {
      MultiRobotsStore.updateDynamicConfigParameters(serialNumber, { interRowDirection: newInterRowDirection });
    };

    return (
      <Dialog open fullWidth>
        <DialogTitle>Settings</DialogTitle>
        <DialogTitle>Autonomy</DialogTitle>
        <DialogContent>
          {!isChaperoneRepeat ? (
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              {window.innerWidth <= 768 ? (
                <Typography id="input-slider" style={{ minWidth: 300 }}>
                  Maximum Linear Speed Limit (Km/hr):
                </Typography>
              ) : (
                <Typography id="input-slider">Maximum Linear Speed Limit (Km/hr):</Typography>
              )}
              <CustomSlider
                disabled={readOnlyMode}
                onMinusClick={() => {
                  linearSpeed > LINEAR_SPEED.min && changeLinearSpeed({}, parseFloat((linearSpeed - LINEAR_SPEED.step).toFixed(2)));
                }}
                onAddClick={() => {
                  linearSpeed < LINEAR_SPEED.max && changeLinearSpeed({}, parseFloat((linearSpeed + LINEAR_SPEED.step).toFixed(2)));
                }}
              >
                <Slider
                  disabled={readOnlyMode}
                  defaultValue={LINEAR_SPEED.default}
                  value={linearSpeed.toFixed(1)}
                  onChange={changeLinearSpeed}
                  getAriaValueText={valuetext}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="on"
                  min={LINEAR_SPEED.min}
                  max={LINEAR_SPEED.max}
                  step={LINEAR_SPEED.step}
                  style={{ width: 120, margin: '8px' }}
                  marks
                />
              </CustomSlider>
            </Grid>
          ) : null}

          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography id="input-slider">Maximum Angular Speed Limit (deg/s):</Typography>
            <CustomSlider
              disabled={readOnlyMode}
              onMinusClick={() => {
                angularSpeed >= ANGULAR_SPEED.min && changeAngularSpeed({}, parseFloat((angularSpeed - ANGULAR_SPEED.step).toFixed(2)));
              }}
              onAddClick={() => {
                angularSpeed <= ANGULAR_SPEED.max && changeAngularSpeed({}, parseFloat((angularSpeed + ANGULAR_SPEED.step).toFixed(2)));
              }}
            >
              <Slider
                disabled={readOnlyMode}
                defaultValue={ANGULAR_SPEED.default}
                value={angularSpeed.toFixed(1)}
                onChange={changeAngularSpeed}
                getAriaValueText={valuetext}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="on"
                min={ANGULAR_SPEED.min}
                max={ANGULAR_SPEED.max}
                step={ANGULAR_SPEED.step}
                style={{ width: 120, margin: '8px' }}
                marks
              />
            </CustomSlider>
          </Grid>

          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography id="input-slider">Lookahead distance(M) [Greater value -> More stable/Less accurate]:</Typography>
            <CustomSlider
              disabled={readOnlyMode}
              onMinusClick={() => {
                lookAheadDistance > LOOK_AHEAD.min &&
                  changeLookAheadDistance({}, parseFloat((lookAheadDistance - LOOK_AHEAD.step).toFixed(2)));
              }}
              onAddClick={() => {
                lookAheadDistance < LOOK_AHEAD.max &&
                  changeLookAheadDistance({}, parseFloat((lookAheadDistance + LOOK_AHEAD.step).toFixed(2)));
              }}
            >
              <Slider
                disabled={readOnlyMode}
                defaultValue={LOOK_AHEAD.default}
                getAriaValueText={valuetext}
                value={lookAheadDistance.toFixed(1)}
                onChange={changeLookAheadDistance}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="on"
                min={LOOK_AHEAD.min}
                max={LOOK_AHEAD.max}
                step={LOOK_AHEAD.step}
                style={{ width: 120, margin: '8px' }}
                marks
              />
            </CustomSlider>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography variant="button" style={{ marginRight: '20px' }}>
              {' '}
              Inter-Row Direction
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={interRowDirection}
              exclusive
              onChange={changeInterRowDirection}
              aria-label="inter-row-direction"
            >
              <ToggleButton value="l-r">FORWARD</ToggleButton>
              <ToggleButton value="r-l">REVERSE</ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </DialogContent>

        {!isChaperoneRepeat ? <DialogTitle>Video Streaming</DialogTitle> : null}

        {!isChaperoneRepeat && (
          <DialogContent>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography id="input-slider">Brightness [Lower -> shadows looks brighter & harder to see brighter highlights]:</Typography>
              <CustomSlider
                onMinusClick={() => {
                  videoGamma >= GAMMA.min && changeGamma({}, parseFloat((videoGamma - GAMMA.step).toFixed(2)));
                }}
                onAddClick={() => {
                  videoGamma <= GAMMA.max && changeGamma({}, parseFloat((videoGamma + GAMMA.step).toFixed(2)));
                }}
              >
                <Slider
                  defaultValue={GAMMA.default}
                  value={videoGamma}
                  onChange={changeGamma}
                  getAriaValueText={valuetext}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="on"
                  min={GAMMA.min}
                  max={GAMMA.max}
                  step={GAMMA.step}
                  style={{ width: 120, margin: '8px' }}
                  marks
                />
              </CustomSlider>
            </Grid>

            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography id="input-slider">Contrast:</Typography>
              <CustomSlider
                onMinusClick={() => {
                  videoContrast >= CONTRAST.min && changeContrast({}, parseFloat((videoContrast - 1).toFixed(2)));
                }}
                onAddClick={() => {
                  videoContrast <= CONTRAST.max && changeContrast({}, parseFloat((videoContrast + 1).toFixed(2)));
                }}
              >
                <Slider
                  defaultValue={CONTRAST.default}
                  value={videoContrast}
                  onChange={changeContrast}
                  getAriaValueText={valuetext}
                  aria-labelledby="continuous-slider"
                  valueLabelDisplay="on"
                  min={CONTRAST.min}
                  max={CONTRAST.max}
                  step={CONTRAST.step}
                  style={{ width: 120, margin: '8px' }}
                  marks
                />
              </CustomSlider>
            </Grid>
          </DialogContent>
        )}
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    );
  }
);

export default RepeatSettingsDialog;
