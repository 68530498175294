import { types } from 'mobx-state-tree';
import { RobotStateStamped } from './audit-robot-state-stamped.model';

const Country = types.model({
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
});

const Region = types.model({
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
});
const Property = types.model({
  id: types.maybeNull(types.integer),
  name: types.maybeNull(types.string)
});

export const AuditReport = types.model({
  id: types.identifierNumber,
  country_id: types.maybeNull(types.union(types.number, types.string)),
  region_id: types.maybeNull(types.union(types.number, types.string)),
  property_id: types.maybeNull(types.union(types.number, types.string)),
  country: types.maybeNull(Country),
  region: types.maybeNull(Region),
  property: types.maybeNull(Property),
  serial_number: types.late(() => types.string),
  robot_state_stamped: types.maybeNull(RobotStateStamped),
  created_at: types.string,
  operator: types.maybeNull(types.late(() => types.string)),
  front_camera: types.optional(types.string, ''),
  rear_camera: types.optional(types.string, '')
});
