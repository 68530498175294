import { useEffect, useState } from 'react';
import { useStores } from '../store/root/root.store';
import { guardedClient } from '../utils/axios-instance';

export const useSolarSubRowsData = () => {
  const [solarSubRowsData, setSolarSubRowsData] = useState([]);
  const [cords, setCords] = useState([]);
  const { blocksStore, chaperonePropertyStore, regionsStore, subrowStore, subBlocksStore } = useStores();

  const selectedBlockId = blocksStore.getSelectedBlock()?.id;
  const selectedPropertyId = chaperonePropertyStore.getSelectedProperty()?.id;
  const selectedRegionId = regionsStore.getSelectedRegion()?.id;
  const selectedSubBlockId = subBlocksStore.getSelectedSubBlock()?.id;
  const selectedPathType = subrowStore.currentPathType;

  useEffect(() => {
    guardedClient
      .get('/robots/solar-map/', {
        params: {
          regionId: selectedRegionId,
          propertyId: selectedPropertyId,
          blockId: selectedBlockId,
          subblockId: selectedSubBlockId,
          pathType: selectedPathType
        }
      })
      .then((res) => {
        setSolarSubRowsData(res.data.data);
        // eslint-disable-next-line no-unused-vars
        const coordinates = Object.entries(res.data.data).map(([key, points]) =>
          points.map((sample) => ({ key, lat: Number(sample.lat), lng: Number(sample.long), angle: Number(sample.angle) }))
        );
        setCords(coordinates);
      })
      .catch((err) => console.log(err));
  }, []);
  return { solarSubRowsData, cords };
};
