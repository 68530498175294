import { types } from 'mobx-state-tree';
import { BluetoothAdapter } from './audit-bluetooth-adapter.model';

export const JoystickState = types.model({
  connected: types.maybeNull(types.boolean),
  valid_command: types.maybeNull(types.boolean),
  connection_strength: types.maybeNull(types.string),
  controller_type: types.maybeNull(types.string),
  info: types.array(types.string),
  bluetooth_adapter: types.maybeNull(types.array(BluetoothAdapter))
});
