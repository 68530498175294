import { useState, useEffect, useRef } from 'react';

/** If mapData is empty, polylines will not be rendered. Marker indicating robot position however will always be shown on map  */
export const useSolarMap = ({ mapData, robotLat, robotLng, resetRobot }) => {
  const [progress, setProgress] = useState([]);
  const [dragged, setDragged] = useState(false); // state used to disable robot tracking after using dragging action
  const [center, setCenter] = useState({ lat: robotLat, lng: robotLng }); // Initialize center with robot's position

  const robotLatRef = useRef(robotLat);
  const robotLngRef = useRef(robotLng);

  useEffect(() => {
    if (mapData?.length > 0) {
      setCenter({
        lat: Number(mapData?.[0]?.[0]?.lat ?? undefined),
        lng: Number(mapData?.[0]?.[0]?.lng ?? undefined)
      });
    } else {
      setCenter({ lat: robotLat, lng: robotLng });
    }
  }, [mapData, robotLat, robotLng]);

  useEffect(() => {
    if (robotLat && robotLat !== 0 && robotLng && robotLng !== 0) {
      robotLatRef.current = robotLat;
      robotLngRef.current = robotLng;
      setProgress([...progress, { lat: robotLat, lng: robotLng }]);
      // Set the center of the Map to be equal the center of the Robot only if user is not controlling the map
      if (!dragged) {
        setCenter({
          lat: Number(robotLat) || (mapData[0] && mapData[0][0] && Number(mapData[0][0].lat)),
          lng: Number(robotLng) || (mapData[0] && mapData[0][0] && Number(mapData[0][0].lng))
        });
      }
    }
  }, [robotLat, robotLng]);

  useEffect(() => {
    if (resetRobot) {
      setProgress([]);
    }
  }, [resetRobot]);

  return { progress, center, dragged, setDragged, setCenter, robotLatRef, robotLngRef };
};
