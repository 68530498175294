import React from 'react';
import { withStyles, Button, Dialog, DialogActions, Typography, makeStyles } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import MuiDialogContent from '@material-ui/core/DialogContent';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  tertiary: {
    color: '#0182f5',
    borderColor: '#0182f5'
  },
  quaternary: {
    color: 'black',
    borderColor: 'black'
  }
}));

const ActionsDialog = ({ dialogTitle, style, dialogBody, backdropProps, container, open, actions = [], onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} style={style} BackdropProps={backdropProps} container={container} onClose={onClose} fullWidth>
      <DialogTitle onClose={onClose}>{dialogTitle}</DialogTitle>
      {dialogBody && (
        <DialogContent>
          <Typography gutterBottom>{dialogBody}</Typography>
        </DialogContent>
      )}
      {!!actions.length && (
        <DialogActions>
          {actions.map((action) => (
            <Button
              key={action.name}
              onClick={action.handler}
              className={action.color === 'tertiary' ? classes.tertiary : action.color === 'quaternary' ? classes.quaternary : ''}
              variant={action.variant ? action.variant : 'contained'}
              color={action.color === 'tertiary' ? '' : action.color === 'quaternary' ? '' : action.color ? action.color : 'secondary'}
              disabled={action.isButtonDisabled}
            >
              {action.name}
            </Button>
          ))}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default ActionsDialog;
