export const secondsToElapsedTime = (value) => {
  const numdays = Math.floor((value % 31536000) / 86400);
  const numhours = Math.floor(((value % 31536000) % 86400) / 3600);
  const numminutes = Math.floor((((value % 31536000) % 86400) % 3600) / 60);
  const numseconds = (((value % 31536000) % 86400) % 3600) % 60;
  let readableTime = '';
  if (numdays) readableTime += `${numdays}d `;
  if (numhours) readableTime += `${numhours}h `;
  if (numminutes) readableTime += `${numminutes}m `;
  if (numseconds) readableTime += `${numseconds}s`;
  return readableTime || '0s';
};

export const addIntervalToDate = (date, units, interval) => {
  if (!(date instanceof Date)) return undefined;
  let ret = new Date(date); // don't change original date
  const checkRollover = () => {
    if (ret.getDate() !== date.getDate()) ret.setDate(0);
  };
  switch (String(interval).toLowerCase()) {
    case 'year':
      ret.setFullYear(ret.getFullYear() + units);
      checkRollover();
      break;
    case 'quarter':
      ret.setMonth(ret.getMonth() + 3 * units);
      checkRollover();
      break;
    case 'month':
      ret.setMonth(ret.getMonth() + units);
      checkRollover();
      break;
    case 'week':
      ret.setDate(ret.getDate() + 7 * units);
      break;
    case 'day':
      ret.setDate(ret.getDate() + units);
      break;
    case 'hour':
      ret.setTime(ret.getTime() + units * 3600000);
      break;
    case 'minute':
      ret.setTime(ret.getTime() + units * 60000);
      break;
    case 'second':
      ret.setTime(ret.getTime() + units * 1000);
      break;
    case 'decisecond':
      ret.setTime(ret.getTime() + units * 100);
      break;
    default:
      ret = undefined;
      break;
  }
  return ret;
};

export const getCurrentTime = () => {
  const now = new Date();
  return `${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}.${now.getMilliseconds()}`;
};
