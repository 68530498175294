import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../store/root/root.store';
import MultiAuditOfficer from '../components/control/multi-robots/multi-audit-officer';
import { AddRobotToMultiAuditView } from '../components/control/multi-robots/add-robot-to-multi--audit-officer-view';

/**
 * Main page for multi-robots audit officer view
 */
export const MultiRobotsAuditOfficerPage = observer(() => {
  const { autonomyRobotStore, MultiRobotsStore, subrowStore } = useStores();
  const [swEstopAllRobots, setSwEstopAllRobots] = useState(false);
  const { selectedAuditRobots } = autonomyRobotStore;
  const { robots } = autonomyRobotStore;
  const { currentPathType } = subrowStore;

  const getSelectedRobots = () => {
    robots.forEach((robot) => {
      const isRobotSelected = selectedAuditRobots.find((robotSN) => robot.serial_number === robotSN);
      if (isRobotSelected) {
        MultiRobotsStore.addRobot({
          id: robot.id,
          serialNumber: robot.serial_number,
          status: robot.status,
          useCase: robot.use_case,
          operatingSubsectionId: robot.operating_subsection_id,
          name: robot.name,
          batteryLevel: robot.battery_level,
          siteName: robot.current_property_name,
          currentRow: robot.operating_subrow_id,
          pathType: currentPathType
        });
      }
    });
  };

  useEffect(() => {
    getSelectedRobots();
    return () => {
      autonomyRobotStore.resetSelectedAuditRobts();
      MultiRobotsStore.resetRobots();
    };
  }, []);

  return (
    <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
      {[...MultiRobotsStore.robots.keys()].map((robotSerialNumber) => (
        <MultiAuditOfficer
          key={robotSerialNumber}
          robotSerialNumber={robotSerialNumber}
          swEstopAllRobots={swEstopAllRobots}
          setSwEstopAllRobots={setSwEstopAllRobots}
        />
      ))}
      <AddRobotToMultiAuditView />
    </div>
  );
});
