import { guardedClient } from '../../utils/axios-instance';

/**
 * Sends an email to the user responsible for the infraction by making a POST request to the server
 *
 * @param {String} infractionEmail -The email address of the user responsible for the infraction
 * @param {Object} robot - The robot object containing the updated assignee and other properties.
 * @param {String} infractionDate - The date of the infraction
 */
export const infractionEmailNotification = async (infractionEmail, robot, senderUserEmail, infractionDate, infractionComment, videoLink) => {
  if (infractionEmail && robot) {
    try {
      await guardedClient.post('email/send-infraction-email', {
        email: infractionEmail,
        robotName: robot,
        senderEmail: senderUserEmail,
        infractionDate,
        infractionComment,
        videoLink
      });
    } catch (e) {
      console.error(e);
    }
  }
};
