import { MetricsCategory } from './constants';

export const getOperationsExcelColumns = (metricsCategory = MetricsCategory.Distances) => {
  if (metricsCategory === MetricsCategory.Acreages) {
    return [
      { key: 'serial_number', header: 'Robot' },
      { key: 'time', header: 'Time' },
      { key: 'total_acres', header: 'Total Mowing Acres' },
      { key: 'speed', header: 'Avg Speed Km/h' },
      { key: 'speed_with_blades', header: 'Avg Speed (Blades on) Km/h' },
      { key: 'numberOfChargingInstances', header: '# of charging instances' }
    ];
  }
  return [
    { key: 'serial_number', header: 'Robot' },
    { key: 'time', header: 'Time' },
    { key: 'total_distance', header: 'Kms Tested' },
    { key: 'speed', header: 'Avg Speed Km/h' },
    { key: 'numberOfChargingInstances', header: '# of charging instances' }
  ];
};

const KILOMETRES_TO_METRES_FACTOR = 1000.0;
const DECK_WIDTH = 1.7907;
const METRES_TO_ACRES_FACTOR = 4047.0;

export const convertDistanceInKmToAcres = (distanceInKm) =>
  (distanceInKm * KILOMETRES_TO_METRES_FACTOR * DECK_WIDTH) / METRES_TO_ACRES_FACTOR;
