import { FormControl, Typography, makeStyles, MenuItem, Select } from '@material-ui/core';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';

export const DEFAULT_VALUE = 'DEFAULT';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: '250px'
  },
  label: {
    textTransform: 'capitalize'
  }
}));

export const ControlledSelect = ({
  name,
  onChange = () => {},
  control,
  defaultValue = DEFAULT_VALUE,
  required = false,
  children,
  ...props
}) => {
  const [value, setValue] = useState();
  const classes = useStyles();

  const isRequiredError = control.errorsRef?.current[name]?.type === 'notDefault';

  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e);
  };

  return (
    <FormControl className={classes.root}>
      <Typography style={{ fonSize: 'inherit' }} id={`${name}-select-label`} className={classes.label}>
        {name}
      </Typography>
      <Controller
        as={
          <Select labelId={`${name}-select-label`} id={`${name}-select-id`} onChange={handleChange} fullWidth>
            {defaultValue === DEFAULT_VALUE ? (
              <MenuItem key={DEFAULT_VALUE} value={defaultValue}>
                No Selection
              </MenuItem>
            ) : null}
            {children}
          </Select>
        }
        name={name}
        onClick={() => {
          props.updateRobot();
        }}
        control={control}
        value={value}
        defaultValue={defaultValue}
        error={isRequiredError}
        helpertext={isRequiredError ? 'Required' : ''}
        rules={{
          validate: {
            notDefault: (value) => {
              if (required) {
                return value !== DEFAULT_VALUE;
              } else {
                return true;
              }
            }
          }
        }}
      />
    </FormControl>
  );
};
