import { makeStyles, Button, Typography } from '@material-ui/core';
import React from 'react';
import { monthNames, robotCurrentStatus } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  tileContainer: {
    background: theme.palette.secondary.main + '4D',
    border: '1px solid' + theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    borderRadius: '.25rem',
    padding: '1% 2%',
    margin: '1% 2%',
    fontWeight: 'bold',
    height: 'max-content'
  },
  numbers: {
    fontSize: '250%'
  },
  title: {
    fontSize: '150%'
  },
  button: {
    backgroundColor: '#e3e3de',
    '&:hover': {
      background: '#9e9e9d'
    },
    display: 'border-box',
    marginBottom: '3px'
  }
}));

function InfoTile({ tile, handleShowRobotStatusTable }) {
  const styles = useStyles();
  return (
    <div className={styles.tileContainer}>
      <h1 className={styles.numbers}>{`${tile?.prefix} ${
        isNaN(tile?.value) || Number.isInteger(tile?.value) ? tile?.value : tile?.value.toFixed(2)
      } ${tile?.postfix}`}</h1>
      <h1 className={styles?.title}>{tile?.title}</h1>
      {tile?.title === 'Total Autonomous Fleet Distance' && <Typography>{`Since: ${monthNames[tile?.month]}, ${tile?.year}`}</Typography>}
      {tile?.title === 'Robots In Operation' && (
        <Button className={styles.button} onClick={() => handleShowRobotStatusTable(robotCurrentStatus.OPERATIONAL)}>
          Currently Operating
        </Button>
      )}
      {tile?.title === 'Idle Robots' && (
        <Button className={styles.button} onClick={() => handleShowRobotStatusTable(robotCurrentStatus.IDLE)}>
          Idle Robots
        </Button>
      )}
      {tile?.title === 'Robots With Running Blades' && (
        <Button className={styles.button} onClick={() => handleShowRobotStatusTable(robotCurrentStatus.BLADE_RUNNING)}>
          Robots With Running Blades
        </Button>
      )}
    </div>
  );
}

export default InfoTile;
