import mqtt from 'mqtt';
import { Environment } from './environment';
import { guardedClient } from './utils/axios-instance';
import { STATUS_MAP } from './utils/constants';

const validateClientConnected = (client) => {
  if (!client) {
    throw new Error('Client is not connected yet. Call client.connect() first!');
  }
};

const awsEventTopicBase = Environment().IotTopic || 'swap';
const env = Environment().envName || 'prod';
const awsEventTopic = `${awsEventTopicBase}-${env}`;

const handleMessage = (topic, message) => {
  let robotSerialNumber;
  let state;
  let user;
  let currentSubsection;
  let robotState;
  let timestamp;
  const payload = JSON.parse(message.toString('utf8'));
  const robotStatePattern = new RegExp(`${awsEventTopic}\/.*\/robot_state$`);

  if (robotStatePattern.test(topic)) {
    robotSerialNumber = topic.substring(topic.indexOf('/') + 1, topic.lastIndexOf('/'));
    robotState = payload.robot_state;
    state = payload.robot_state?.navigation_state?.wps_state;
    user = payload.robot_state?.network_state?.active_client?.user;
    currentSubsection =
      payload.robot_state?.navigation_state?.current_path_state?.path_db_id !== -1
        ? parseInt(payload.robot_state?.navigation_state?.current_path_state?.path_db_id)
        : null;
    timestamp = new Date(payload.stamp * 1000);
  } else {
    const { clientId } = payload;
    if (clientId.startsWith(`${awsEventTopic}-`)) {
      robotSerialNumber = clientId.substring(awsEventTopic.length + 1);
      if (topic.includes('disconnected')) {
        state = '100';
      }
    } else {
      console.warn('[*] Not a robot');
    }
  }
  return [robotSerialNumber, STATUS_MAP[state], user, currentSubsection, robotState, timestamp];
};

const getRealTimeClientEndpoint = async () => {
  const response = await guardedClient.get(`${Environment().ChaperoneBaseUrl}/helpers/realtime-client-endpoint`);
  return response.data.results;
};

export default () => {
  let client = null;

  const clientWrapper = {};
  clientWrapper.connect = () =>
    guardedClient.get(`${Environment().ChaperoneBaseUrl}/helpers/realtime-client-endpoint`).then((response) => {
      client = mqtt.connect(response.data.results);
      client.subscribe(`${awsEventTopic}/+/robot_state`);
      client.subscribe(`$aws/events/presence/connected/${awsEventTopic}+`);
      client.subscribe(`$aws/events/presence/disconnected/${awsEventTopic}+`);
      client.on('connect', () => {
        console.log('Connected to AWS IoT Broker');
      });
      client.on('close', () => {
        console.log('Connection to AWS IoT Broker closed');
      });
      client.on('reconnect', () => {
        console.log('Reconnecting to AWS IoT Broker');
      });
      client.on('reconnect', () => {
        console.log('Reconnecting to AWS IoT Broker');
      });
    });
  clientWrapper.onConnect = (callback) => {
    validateClientConnected(client);
    client.on('connect', callback);
    return clientWrapper;
  };
  clientWrapper.onDisconnect = (callback) => {
    validateClientConnected(client);
    client.on('close', callback);
    return clientWrapper;
  };

  clientWrapper.onMessageReceived = (callback) => {
    validateClientConnected(client);
    client.on('message', (topic, message) => {
      const [robot, state, user, currentSubsection, robotState, timestamp] = handleMessage(topic, message);
      if ((robot && state) || robotState) {
        callback(robot, state, user, currentSubsection, robotState, timestamp);
      }
    });
    return clientWrapper;
  };

  clientWrapper.closeConnection = (callback) => {
    if (client) {
      client.end(() => {
        callback?.('Disconnected');
      });
    }
  };

  return clientWrapper;
};

export class MqttClient {
  client = null;

  handlerCallback = null;

  reconnectionAttempts = 0;

  constructor(handlerCallback) {
    this.handlerCallback = handlerCallback;
  }

  async connect() {
    const endpoint = await getRealTimeClientEndpoint();
    this.client = mqtt.connect(endpoint);
    this.client.subscribe(`${awsEventTopic}/+/robot_state`);
    this.client.subscribe(`$aws/events/presence/connected/${awsEventTopic}+`);
    this.client.subscribe(`$aws/events/presence/disconnected/${awsEventTopic}+`);
    this.client.on('connect', () => {
      console.log('Connected to AWS IoT Broker');
      this.reconnectionAttempts = 0;
    });
    this.client.on('close', async () => {
      console.log('Connection to AWS IoT Broker closed');
      if (this.client.reconnecting) {
        this.reconnectionAttempts += 1;
        console.log('Reconnection attempts = ', this.reconnectionAttempts);
        if (this.reconnectionAttempts > 1) {
          console.log('Reconnection attempts exceeded');
          this.client.end();
          await this.connect();
        }
      }
    });
    this.client.on('reconnect', () => {
      console.log('Reconnecting to AWS IoT Broker');
    });
    this.client.on('message', (topic, message) => {
      const [robot, state, user, currentSubsection, robotState, timestamp] = handleMessage(topic, message);
      if ((robot && state) || robotState) {
        this.handlerCallback(robot, state, user, currentSubsection, robotState, timestamp);
      }
    });
  }

  /**
   *
   * @param {String} topic topic name
   * @param {JSON/String} message topic payload or message
   * @param {Number} qos quality of service. 1 by default, message will be sent atleast once
   */
  async publish(topic, message, qos = 1) {
    console.log('Published topic');
    this.client.publish(topic, message, { qos }, (error) => {
      if (error) {
        console.error('Error publishing message', error);
      } else {
        console.log(`Published message to: ${topic}`);
      }
    });
  }

  disconnect() {
    if (this.client) {
      this.client.end();
    }
  }
}
