import React, { useEffect, useState, useRef } from 'react';
import './GridMap.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import SolarLineChart from './SolarLineChart';

let index = 0;
const styles = {
  chartComponentsContainer: {
    // marginTop: '16px',
    width: '100%',
    display: 'grid',
    alignItems: 'center'
  },
  remoteGuardianStyle: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    width: '25%',
    display: 'grid',
    alignItems: 'end',
    border: '3px solid gray'
  },
  chartWrapper: { alignSelf: 'flex-start', width: '100%' }
};

const SolarGridMap = ({
  robotEnabled,
  solarRows,
  selectedSolarRows,
  robotLng,
  robotLat,
  robotHeadingRad,
  resetRobot,
  isZoomEnabled,
  isAutonomyAudits = false,
  currentPositionIndex = 0,
  currentSplittedRowName = '',
  customStyle = false
}) => {
  const [multipleLines, setMultipleLines] = useState([]);
  const [selectedMultipleLines, setSelectedMultipleLines] = useState([]);
  const [robotLocation, setRobotLocation] = useState({ x: '0.0', y: '0.0', angle: 0 });
  const [currentRepeatedSubrow, setCurrentRepeatedSubRow] = useState();
  const [onlineRobotPath, setOnlineRobotPath] = useState([]);
  const [scale, setScale] = useState(1);
  const zoomRef = useRef(null);

  useEffect(() => {
    const selectedLines = [];
    const data = Object.entries(solarRows).map(([path, points]) => {
      if (selectedSolarRows.includes(path.trim())) {
        selectedLines.push(true);
      } else {
        selectedLines.push(false);
      }
      if (currentPositionIndex && path.trim() === currentSplittedRowName) {
        return points
          .map((sample, idx) => ({
            label: `${idx}`,
            lat: sample.lat,
            long: sample.long,
            angle: sample.angle
          }))
          .filter((el) => parseInt(el.label) >= currentPositionIndex);
      }
      return points.map((sample, idx) => ({
        label: `${idx}`,
        lat: sample.lat,
        long: sample.long,
        angle: sample.angle
      }));
    });
    const currentRepeatedSubrowData = solarRows[selectedSolarRows[0]]?.map((sample, idx) => ({
      label: `${idx}`,
      lat: sample.lat,
      long: sample.long,
      angle: sample.angle
    }));
    setCurrentRepeatedSubRow(currentRepeatedSubrowData);
    setMultipleLines(data);
    setSelectedMultipleLines(selectedLines);
  }, [solarRows, selectedSolarRows.length, currentPositionIndex, currentSplittedRowName]);

  useEffect(() => {
    const referencePoint = {
      lat: multipleLines?.[0]?.[0]?.[0]?.lat ?? 0,
      long: multipleLines?.[0]?.[0]?.[0]?.long ?? 0,
      angle: multipleLines?.[0]?.[0]?.[0]?.angle ?? 0
    };
    if (resetRobot) {
      setRobotLocation({ x: '0.0', y: '0.0', angle: 0 });
      setOnlineRobotPath([]);
    } else {
      const diffAngle = robotHeadingRad - referencePoint.angle;
      if (robotLng && robotLat) {
        setRobotLocation({
          lng: parseFloat(robotLng),
          lat: parseFloat(robotLat),
          angle: robotHeadingRad ? diffAngle * 57.2958 : 0
        });
        onlineRobotPath.push({ label: `${index}`, lng: parseFloat(robotLng), lat: parseFloat(robotLat) });
        setOnlineRobotPath(onlineRobotPath);
      }
    }
  }, [robotLng, robotLat, robotHeadingRad, setOnlineRobotPath, onlineRobotPath, resetRobot]);

  return (
    <div style={customStyle ? styles.remoteGuardianStyle : styles.chartComponentsContainer}>
      {isZoomEnabled ? (
        <div style={styles.chartWrapper}>
          <TransformWrapper
            ref={zoomRef}
            initialScale={1}
            panning={{ disabled: scale === 1, velocityDisabled: true }} // Don't pan when the user didn't zoom into the child
            onZoom={(ref) => setScale(ref.state.scale)}
          >
            {({ zoomIn, zoomOut, resetTransform }) => (
              <>
                <div className="tools">
                  <button onClick={() => zoomIn()}>Zoom in</button>
                  <button onClick={() => zoomOut()}>Zoom out</button>
                  <button onClick={() => resetTransform()}>reset</button>
                </div>
                <TransformComponent>
                  {robotEnabled ? (
                    <SolarLineChart
                      customStyle={customStyle}
                      isAutonomyAudits={isAutonomyAudits}
                      width={430}
                      height={430}
                      multipleLines={multipleLines}
                      selectedMultipleLines={selectedMultipleLines}
                      currentRepeatedSubrow={currentRepeatedSubrow}
                      robotLocation={robotLocation}
                      onlineRobotPath={onlineRobotPath}
                    />
                  ) : (
                    <SolarLineChart
                      width={430}
                      height={430}
                      isAutonomyAudits={isAutonomyAudits}
                      multipleLines={multipleLines}
                      currentRepeatedSubrow={currentRepeatedSubrow}
                      selectedMultipleLines={selectedMultipleLines}
                    />
                  )}
                </TransformComponent>
              </>
            )}
          </TransformWrapper>
        </div>
      ) : (
        <div style={styles.chartWrapper}>
          {robotEnabled ? (
            <SolarLineChart
              customStyle={customStyle}
              width={430}
              height={430}
              multipleLines={multipleLines}
              selectedMultipleLines={selectedMultipleLines}
              currentRepeatedSubrow={currentRepeatedSubrow}
              robotLocation={robotLocation}
              onlineRobotPath={onlineRobotPath}
            />
          ) : (
            <SolarLineChart
              width={430}
              height={430}
              multipleLines={multipleLines}
              currentRepeatedSubrow={currentRepeatedSubrow}
              selectedMultipleLines={selectedMultipleLines}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SolarGridMap;
