import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  Paper
} from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useForm } from 'react-hook-form';

const useStyles = makeStyles((theme) => ({
  root: {},

  formControl: {
    marginBottom: theme.spacing(2)
  },
  uppercase: {
    '& > input': {
      textTransform: 'uppercase'
    },
    '& > input::placeholder': {
      textTransform: 'none'
    }
  }
}));

export const AddSpeed = ({ show, onSubmit, onClose }) => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });

  return useObserver(() => (
    <Dialog open={show} onClose={() => onClose()} className={classes.root} fullWidth>
      <DialogTitle onClose={() => onClose()}> What's the desired robot's speed in this zone (Km/hr)?</DialogTitle>
      <DialogContent>
        <Paper component="form" elevation={0} color="inverted">
          <FormControl variant="outlined" fullWidth error={!!errors.name} className={classes.formControl}>
            <OutlinedInput
              aria-describedby="name-error-text"
              fullWidth
              name="speedValue"
              placeholder="Desired Speed (default = 1 Km/hr)"
              inputRef={register({ maxLength: 5 })}
            />
            <FormHelperText id="name-error-text">{errors.name?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="name-error-text">{errors.name?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => onClose()} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
            Set desired speed
          </Button>
        </>
      </DialogActions>
    </Dialog>
  ));
};
