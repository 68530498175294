import { Grid, makeStyles, Typography } from '@material-ui/core';
import { RadioButtonChecked, RadioButtonUnchecked } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.light,
    textShadow: `0 0 5px 10px ${theme.palette.secondary.main}`,
  },
  inactive: { color: theme.palette.grey[300] },
  label: {
    textTransform: 'uppercase',
  },
}));

export const ToolIndicator = ({ toolType, isActive }) => {
  const classes = useStyles();

  return useObserver(() => (
    <>
      <Grid item container direction='row' justifyContent='space-between' alignItems='stretch'>
        <Grid item>
          <Typography className={classes.label} gutterBottom>
            {toolType}
          </Typography>
        </Grid>
        <Grid item>
          {isActive ? (
            <RadioButtonChecked className={classes.active} />
          ) : (
            <RadioButtonUnchecked className={classes.inactive} />
          )}
        </Grid>
      </Grid>
    </>
  ));
};
