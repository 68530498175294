import Excel from 'exceljs';
import { saveAs } from 'file-saver';

export const constructExcel = (data, columns, sheetName) => {
  const workbook = new Excel.Workbook();
  const worksheet = workbook.addWorksheet(sheetName);

  worksheet.columns = columns;

  if (worksheet.columns.length > 0) {
    data.forEach((dataPoint) => worksheet.addRow(dataPoint));
  }
  return workbook;
};

export const autoWidth = (worksheet, minimalWidth = 10) => {
  worksheet.columns.forEach((column) => {
    let maxColumnLength = 0;
    column.eachCell({ includeEmpty: true }, (cell) => {
      maxColumnLength = Math.max(maxColumnLength, minimalWidth, cell.value ? cell.value.toString().length : 0);
    });
    column.width = maxColumnLength + 2;
  });
};

export const mergeEqualAdjacentCellsInColumn = (worksheet, columnNumber, startRow) => {
  const rows = worksheet.getColumn(1);
  const rowsCount = rows['_worksheet']['_rows'].length;

  let prevRowValue = worksheet.getRow(startRow).getCell(columnNumber).text;
  let valueStartIndex = startRow;
  const columnRanges = [];
  for (let i = startRow; i <= rowsCount; i++) {
    const currentValue = worksheet.getRow(i).getCell(columnNumber).text;
    if (currentValue !== prevRowValue) {
      columnRanges.push({ start: valueStartIndex, end: i - 1 });
      valueStartIndex = i;
      prevRowValue = worksheet.getRow(valueStartIndex).getCell(columnNumber).text;
    }
    prevRowValue = currentValue;
  }
  columnRanges.push({ start: valueStartIndex, end: rowsCount });

  for (let i = 0; i < columnRanges.length; i += 1) {
    worksheet.mergeCells(columnRanges[i].start, columnNumber, columnRanges[i].end, columnNumber);
  }
};

export const saveExcelWorkook = async (workbook, fileName) => {
  const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
  saveAs(new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }), `${fileName}.xlsx`);
};
