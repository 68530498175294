/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable prefer-template */
/* eslint-disable no-unused-expressions */
import { Button, Grid, makeStyles, TableCell, Icon, Fade } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { ROUTE_CHAPERONE, ROUTE_TEACH_SUBSECTION } from './routes';

import { useHistory } from 'react-router-dom';
import { useStores } from '../store/root/root.store';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
  ChaperoneRobotConnectionService,
  LINEAR_SPEED,
  ANGULAR_SPEED,
  LOOK_AHEAD,
  SALT_RATE
} from '../services/chaperone/robot-connection.service';
import Replay from '@material-ui/icons/Replay';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import Map from '../components/maps/chaperone-map';
import GridMap from '../components/gridMaps/GridMap';
import { StripedTableRow } from '../components/core/striped-table-row.component';
import { StripedTable } from '../components/core/striped-table.component';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import FirstState from '../assets/images/state_0.png';
import SecondState from '../assets/images/state_1.png';
import ThirdState from '../assets/images/state_2.png';
import ForthState from '../assets/images/state_3.png';
import FifthState from '../assets/images/unknown_state.png';
import { CustomSlider } from '../components/core/custom-slider.component';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { debounce } from '../utils';
import { getDistance } from 'geolib';
import ActionsDialog from '../components/dialogs/actions.dialog';
import { NOT_STARTED, IN_PROGRESS, COMPLETED, SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING } from '../utils/constants';
import RepeatSettingsDialog from '../components/dialogs/repeat-settings.dialog';
import RestartRobotDialog from '../components/dialogs/actions.dialog';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { isDevMode } from '../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    color: theme.palette.inverted.main
  },
  PlayCircleOutlineIcon: {
    color: 'white',
    height: 55,
    width: 55
  },
  formControlTextField: {
    paddingLeft: theme.spacing(18.5),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  cancelButton: {
    margin: theme.spacing(2),
    height: 50,
    width: 160,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: theme.spacing(1),
    minWidth: 200
  },
  Slider: {
    margin: theme.spacing(1),
    color: '#0178FF',
    width: 120
  },
  sliderLabel: {
    color: '#fff',
    padding: '10px',
    backgroundColor: '#4472c4',
    borderRadius: '5px',
    marginLeft: '7px'
  },
  toggle: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    Width: 30,
    height: 50
  },
  toggleGroup: {
    Width: '100%',
    height: '100%'
  },
  toggleLabel: {
    color: 'Black',
    Width: 30
  }
}));

const RobotData_INTERVAL_TIME = 400; // in milliseconds

export const RepeatPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const [start, setStart] = useState('true');
  const [pause, setPause] = useState(false);

  const [googleMap, setGoogleMap] = useState(false);
  const [gridMap, setGridMap] = useState(true);
  const [resume, setResume] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const { applicationStore, controlStore, countriesStore, autonomyRobotStore, subsectionStore } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const keepSelectedRobot = useRef(false);
  // const [connecting, setConnecting] = useState(false);
  const [linearSpeed, setLinearSpeed] = useState(LINEAR_SPEED.default);
  const [lookAheadDistance, setLookAheadDistance] = useState(LOOK_AHEAD.default);
  const [angularSpeed, setAngularSpeed] = useState(ANGULAR_SPEED.default);
  const [manager, setManager] = useState('false');
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [cords, setCords] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [openDialog, setOpenDialog] = useState(false);
  const [subsection, setSubsection] = useState('');
  const [nextSubsection, setNextSubsection] = useState('');
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openConfirmRestartRobotDialog, setOpenConfirmRestartRobotDialog] = useState(false);
  const [toBeSelectedControlMode, setToBeSelectedControlMode] = useState('');
  const [userRobot, setUserRobot] = useState(false);
  const [referencePoint, setReferencePoint] = useState();
  const [positionError, setPositionError] = useState(false);
  const [gridMapData, setGridMapData] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [subsectionMeta, setSubsectionMeta] = useState({});
  const [saltRate, setSaltRate] = useState(0);
  const subsectionsBucket = applicationStore.getEnvironment().SubsectionsBucket;
  const isGrassCutting = [SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING].includes(autonomyRobotStore.getSelectedRobot().use_case);
  const [isSuspendedTeachingDialogOpen, setIsSuspendedTeachingDialogOpen] = useState(false);
  const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
  const username = localStorage.getItem('username');
  const isMountedRef = useRef(null);

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const getMapData = (region, property, section, subsection) => {
    axios
      .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region,
        property,
        section,
        subsection
      })
      .then((result) => {
        setGridMapData(result.data.data);
        const resultingCords = result.data.data.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.long) }));
        setCords(resultingCords);
      });
  };

  const fetchSubsectionMeta = async (subsectionId) => {
    const response = await axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`);
    setSubsectionMeta(response.data.results);
    return response.data.results;
  };

  const fetchCurrentSubsectionMeta = async (subsectionId) => {
    const response = await axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`);
    return response.data.results;
  };

  const sendRepeatCmdToRobot = async (action, customCommand, currentSubsectionMeta = {}) => {
    setUserRobot(true);
    let subsectionId = subsection ?? autonomyRobotStore.getSelectedRobot()?.operating_subsection_id;
    if (!subsectionId && !state.subsection) {
      return;
    }
    const command = customCommand ?? 'REPEAT';
    let argsList =
      action +
      (subsectionMeta?.section?.property?.region?.id || state.region) +
      '_' +
      (subsectionMeta?.section?.property?.id || state.property) +
      '_' +
      (subsectionMeta?.section?.id || state.section) +
      '_' +
      subsectionId +
      '.csv' +
      ',' +
      localStorage.getItem('username');
    if (Object.keys(currentSubsectionMeta).length) {
      subsectionId = autonomyRobotStore.getSelectedRobot()?.operating_subsection_id;
      argsList =
        action +
        currentSubsectionMeta?.section?.property?.region?.id +
        '_' +
        currentSubsectionMeta?.section?.property?.id +
        '_' +
        currentSubsectionMeta?.section?.id +
        '_' +
        subsectionId +
        '.csv' +
        ',' +
        localStorage.getItem('username');
    }

    console.log({ argsList });
    robotConnection?.current?.ros?.cmdRobotService(command, [argsList], (result) => {
      setLoading(false);
      if (result.error_message === '') return;
      console.log('There was the following error with the repeat action: ' + result.error_message);
      applicationStore.pushError(
        'Error',
        'The robot encountered an error with this repeat action, please report this to the autonomy team if the issue persists'
      );
    });
  };

  const handleReleaseRobot = async () => {
    const cmd = `CANCEL,swapautonomy`;
    const currentSubsectionMeta = await fetchCurrentSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    console.log('ReleaseCmdToRobot: ', cmd);
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      sendRepeatCmdToRobot(cmd, null, currentSubsectionMeta);
      setIsSuspendedRepeatingDialogOpen(false);
    } else {
      sendRepeatCmdToRobot(cmd, 'TEACH', currentSubsectionMeta);
      setIsSuspendedTeachingDialogOpen(false);
    }
  };

  const handleSuspendedTeachingDialogGoBack = () => {
    setIsSuspendedTeachingDialogOpen(false);
    const robotState = autonomyRobotStore.getSelectedRobot()?.status;
    push({ pathname: ROUTE_TEACH_SUBSECTION, loc: { ...state, state: robotState } });
  };

  const handleSuspendedRepeatingDialogGoBack = async () => {
    const subsectionInfo = await fetchSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    setSubsection(subsectionInfo?.id);
    getMapData(
      subsectionInfo?.section?.property?.region?.id,
      subsectionInfo?.section?.property?.id,
      subsectionInfo?.section?.id,
      subsectionInfo?.id
    );
    setIsSuspendedRepeatingDialogOpen(false);
    setResume(true);
    setStart(false);
    // Call the last wps_index service
    robotConnection?.current?.ros?.getLastWpsIndexService((wpsInexStr) => {
      console.log({ wpsInexStr });
      setCurrentPositionIndex(parseInt(wpsInexStr));
    });
  };

  const changeLinearSpeed = (event, newValue) => {
    setLinearSpeed(newValue);
  };

  const changeSaltRate = (event, newValue) => {
    setSaltRate(newValue);
  };

  useEffect(() => {
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedRepeatingDialogOpen(true);
    } else if (autonomyRobotStore.getSelectedRobot().status === 'WPS_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedTeachingDialogOpen(true);
    }
    keepSelectedRobot.current = true;
  }, [autonomyRobotStore]);

  useEffect(() => {
    setSaltRate(controlStore.salter_level);
  }, [controlStore.salter_level]);

  useEffect(() => {
    if (!controlStore.gpsFixStatus.includes('RTK') && controlStore.getControlMode() == 'autonomous') {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !");
      if (pause) {
        // setPause(!pause);
        setResume(!resume);
        switchToManualControl();
        sendRepeatCmdToRobot(`PAUSE,swapautonomy`);
      }
    }
  }, [controlStore.gpsFixStatus]);

  const startLoadingMsg = () => {
    if (isDevMode) {
      console.log('Developing mode...');
    }
    showLoadingDialog(`We are provisioning the robot now, it will begin the path in a few seconds.`);
  };

  const openNewSubsection = async () => {
    setOpenDialog(false);
    startNextSubsection();
  };

  const closeDialogue = () => {
    setOpenDialog(false);
    push(ROUTE_CHAPERONE);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    if (state.subsection) {
      axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${state.subsection}`)
        .then((res) => setSubsectionMeta(res.data.results));
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  const handleTabClosing = () => {
    if (!start) {
      console.log('closed');
      // sendRepeatCmdToRobot(`SUSPEND,swapautonomy`);
    }
  };

  const alertUser = async (ev) => {
    if (!start) {
      ev.preventDefault();
      ev.returnValue = 'Are you sure you want to close?';
      const exit = window.confirm('Are you sure you want to close?');
      await subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
      console.log(exit);
      if (!exit)
        subsectionStore.setRepeatState(
          state.region,
          state.property,
          state.section,
          subsection,
          IN_PROGRESS,
          autonomyRobotStore.getSelectedRobot().serial_number
        );
    }
    return 'Are you sure you want to close?';
  };

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  useEffect(() => {
    subsectionStore.getSubsections(countriesStore.selectedCountryId, state.region, state.property, state.section);
    setSubsection(state.subsection);
  }, []);

  const getStateIcon = (state) => {
    const icons = [FirstState, SecondState, ThirdState, ForthState, FifthState];
    return icons[state];
  };

  const current_params = () => `${linearSpeed},${lookAheadDistance},${angularSpeed},${saltRate}`;

  const startNextSubsection = () => {
    try {
      axios
        .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
          region: state?.region,
          property: state?.property,
          section: state?.section,
          subsection: subsection === '' ? state.subsection : subsection
        })
        .then((result) => {
          setGridMapData(result.data.data);
          var cords = result.data.data.map((coordinates) => {
            return { lat: Number(coordinates.lat), lng: Number(coordinates.long) };
          });
          setCords(cords);
        });
    } catch (e) {
      throw e;
    }
    controlStore.resetRobotPosition();
    switchToAutonomousControl();
    setResetRobot(true);
    controlStore.resetRobotPosition();
    setResetRobot(true);
    let params_str = current_params();
    subsectionStore.setRepeatState(
      state.region,
      state.property,
      state.section,
      nextSubsection,
      IN_PROGRESS,
      autonomyRobotStore.getSelectedRobot().serial_number
    );
    sendRepeatCmdToRobot(`START,swapautonomy`);
    startLoadingMsg();
    robotConnection?.current?.ros.updateNavParams(params_str);
  };

  const isValidPosition = () => {
    if (isDevMode) {
      return true;
    }
    if (!(referencePoint?.lat && referencePoint?.lng && referencePoint?.angle)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage('The location data is not valid.');
      return false;
    }
    const distance = getDistance(
      { latitude: Number(referencePoint?.lat), longitude: Number(referencePoint?.lng) },
      { latitude: controlStore.lat, longitude: controlStore.lng },
      0.01
    );
    let diff_angle = controlStore.current_heading_rad - Number(referencePoint?.angle);
    if (diff_angle > 3.14) {
      diff_angle -= 6.28;
    } else if (diff_angle < -3.14) {
      diff_angle += 6.28;
    }
    if (Math.fround(distance) > Math.fround(1)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subsection, the robot is not close to the starting point.");
      return false;
    }
    if (Math.abs(diff_angle) > 0.75) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subsection, the robot's heading is not close to the initial recorded orientation.");
      return false;
    }
    return true;
  };

  const changeRepeatingState = () => {
    if (resume) {
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (!controlStore.gpsFixStatus.includes('RTK')) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
        return;
      } else if (!controlStore.stableLocalization) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
        return;
      }
      console.log('GPS data is good to allow the robot to goooo!!');
      setPause(!pause);
      setResume(!resume);
      switchToAutonomousControl();
      sendRepeatCmdToRobot(`RESUME,swapautonomy`);
    } else if (pause) {
      setPause(!pause);
      setResume(!resume);
      switchToManualControl();
      sendRepeatCmdToRobot(`PAUSE,swapautonomy`);
    } else if (start) {
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (controlStore.lat && controlStore.lng) {
        if (!controlStore.gpsFixStatus.includes('RTK')) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
          return;
        } else if (!controlStore.stableLocalization) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
          return;
        }
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('GPS  Error, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }

      if (!isValidPosition()) {
        return;
      }
      setStart(!start);
      setPause(!pause);
      if (controlStore.getControlMode() != 'autonomous') {
        switchToAutonomousControl();
        setResetRobot(true);
      }
      controlStore.resetRobotPosition();
      setResetRobot(true);
      let params_str = current_params();
      subsectionStore.setRepeatState(
        state.region,
        state.property,
        state.section,
        subsection,
        IN_PROGRESS,
        autonomyRobotStore.getSelectedRobot().serial_number
      );
      axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/robots/${autonomyRobotStore.selectedRobotId}/status`)
        .then((res) => {
          if (res.data.results.status === 'AVAILABLE' || userRobot) {
            sendRepeatCmdToRobot(`START,swapautonomy`);
            startLoadingMsg();
            robotConnection?.current?.ros.updateNavParams(params_str);
          } else {
            setConnectionError(true);
            setErrorMessage('Robot is already in-use, check it again later or select another robot!');
          }
        })
        .catch((err) => {
          console.log(err);
          setConnectionError(true);
          setErrorMessage('Robot is already in-use, check it again later or select another robot!');
        });
    }
  };

  const restartRobot = () => {
    setOpenConfirmRestartRobotDialog(true);
  };

  const restart = () => {
    robotConnection?.current?.ros?.restartRobotService('robot');
    setOpenConfirmRestartRobotDialog(false);
  };

  const confirmChangeDriveState = () => {
    changeDriveState({}, toBeSelectedControlMode);
    setOpenWarningDialog(false);
  };

  const switchToManualControl = () => {
    if (controlStore.getControlMode() != 'manual') robotConnection?.current?.ros.setControlMode('manual');
  };

  const switchToAutonomousControl = () => {
    if (controlStore.getControlMode() != 'autonomous') robotConnection?.current?.ros.setControlMode('autonomous');
  };

  const toggleDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (controlStore.wpsState === 0) {
        console.log('discarded');
        return;
      } else if (controlStore.wpsState === 40 && toggleManualControl === false) {
        setOpenWarningDialog(true);
        setToBeSelectedControlMode(toggleManualControl);
      } else {
        changeDriveState({}, toggleManualControl);
      }
    }
  };

  const togglePlowControlMode = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      // if (controlStore.wpsState !== 4) {
      //   console.log('discarded');
      //   return;
      // }
      if (controlStore.getPlowControlMode() == 'autonomous') {
        robotConnection?.current?.ros.setControlMode('PLOW,manual');
      } else {
        robotConnection?.current?.ros.setControlMode('PLOW,autonomous');
      }
    }
  };

  const toggleSalterControlMode = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      // if (controlStore.wpsState !== 4) {
      //   console.log('discarded');
      //   return;
      // }
      if (controlStore.getSalterControlMode() == 'autonomous') {
        robotConnection?.current?.ros.setControlMode('SALTER,manual');
      } else {
        robotConnection?.current?.ros.setControlMode('SALTER,autonomous');
      }
    }
  };

  const changeDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (!start) {
        if (controlStore.getControlMode() != 'autonomous' && resume) {
          setPause(!pause);
          setResume(!resume);
          switchToAutonomousControl();
          sendRepeatCmdToRobot(`RESUME,swapautonomy`);
        } else if (controlStore.getControlMode() == 'autonomous' && pause) {
          setPause(!pause);
          setResume(!resume);
          switchToManualControl();
          sendRepeatCmdToRobot(`PAUSE,swapautonomy`);
        }
      }
    }
  };
  const cancelRepeatingTask = async (done = false) => {
    if (!start) {
      setStart(!start);
      if (resume) {
        setResume(!resume);
      } else if (pause) {
        setPause(!pause);
      }
      switchToManualControl();
      if (!done) {
        subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
        await sendRepeatCmdToRobot(`CANCEL,swapautonomy`);
      } else {
        await sendRepeatCmdToRobot(`FINISH,swapautonomy`);
      }
    }
    if (!done) {
      controlStore.resetStore();
    } else {
      controlStore.resetDoneRepeating();
    }
  };
  function valuetext(value) {
    return `${value}°C`;
  }

  const getDistanceBetweenSubscetions = async () => {
    var cords1 = [];
    var cords2 = [];
    var next_Subsection = '';
    try {
      await axios
        .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
          region: state.region,
          property: state.property,
          section: state.section,
          subsection: subsection
        })
        .then((result) => {
          cords1 = result.data.data.map((coordinates) => {
            return { lat: Number(coordinates.lat), lng: Number(coordinates.long) };
          });
        });
    } catch (e) {
      throw e;
    }
    try {
      await axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/next`, {
          params: {
            section_id: state.section,
            order_index: subsectionStore.getById(subsection)?.order_index,
            operation_robot: subsectionStore.getById(subsection)?.operation_robot
          }
        })
        .then((result) => {
          console.log(result.data.results);
          next_Subsection = result.data.results.id;
          setNextSubsection(next_Subsection);
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      throw e;
    }
    if (next_Subsection !== '') {
      try {
        await axios
          .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
            region: state.region,
            property: state.property,
            section: state.section,
            subsection: next_Subsection || ''
          })
          .then((result) => {
            cords2 = result.data.data.map((coordinates) => {
              return { lat: Number(coordinates.lat), lng: Number(coordinates.long) };
            });
          });
      } catch (e) {
        throw e;
      }
      var distance = 0;
      setSubsection(next_Subsection);
      if (cords1.length > 0 && cords2.length > 0)
        distance = getDistance(
          { latitude: cords1[cords1.length - 1].lat, longitude: cords1[cords1.length - 1].lng },
          { latitude: cords2[0].lat, longitude: cords2[0].lng },
          0.01
        );
      console.log(distance);
      return distance;
    } else {
      return -1;
    }
  };

  const monitorRobotData = useCallback(async () => {
    if (robotConnectionService.current !== null) {
      // check for idle robot state data
      if ((Date.now() - controlStore.lastRobotStateReceived) / 1000 > 20 && controlStore.getControlMode() == 'autonomous') {
        // in seconds
        setPositionError(true);
        setConnectionError(true);
        if (pause) {
          setErrorMessage("RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !");
          // setPause(!pause);
          setResume(!resume);
          switchToManualControl();
          sendRepeatCmdToRobot(`PAUSE,swapautonomy`);
        } else {
          setErrorMessage('RTK Error: No sensory data has been recieved, the robot does not have an RTK GPS data !');
        }
      }
      // Check for change in other Waypoints state
      if (Number(controlStore.wpsState) === 0) {
        setStart(true);
        setPause(false);
        setResume(false);
      } else if (Number(controlStore.wpsState) === 4) {
        setStart(false);
        setPause(true);
      } else if (!start && controlStore.isRepeatingDone) {
        subsectionStore.setRepeatState(
          state.region,
          state.property,
          state.section,
          subsection,
          COMPLETED,
          autonomyRobotStore.getSelectedRobot().serial_number
        );
        //Repeating is Done
        console.log('WAYPOINTS REPEATING IS DONE');
        cancelRepeatingTask(true);
        var distance = await getDistanceBetweenSubscetions();
        console.log(distance);
        if (Math.fround(distance) <= Math.fround(0.5) && Math.fround(distance) > Math.fround(-0.001)) {
          setOpenDialog(true);
        }
      }
    }
  }, [cancelRepeatingTask, robotConnectionService, start, setStart]);

  useEffect(() => {
    let params_str = current_params();
    robotConnection?.current?.ros.updateNavParams(params_str);
  }, [linearSpeed, lookAheadDistance, angularSpeed, saltRate]);

  useEffect(() => {
    console.log({ state });
    setManager(state?.manager);
    axios
      .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region: state?.region,
        property: state?.property,
        section: state?.section,
        subsection: subsection === '' ? state.subsection : subsection
      })
      .then((result) => {
        setReferencePoint({
          lat: result.data.data[0].lat,
          lng: result.data.data[0].long,
          angle: result.data.data[0].angle
        });
        setGridMapData(result.data.data);
        setCurrentPositionIndex(0);
        const cordsData = result.data.data.map((coordinates, idx) => ({
          lat: Number(coordinates.lat),
          lng: Number(coordinates.long),
          idx
        }));
        setCords(cordsData);
      });

    return () => {
      controlStore.resetRobotPosition();
      controlStore.resetStore();
    };
  }, []);

  useEffect(() => {
    // setConnecting(true);
    if (state) {
      isMountedRef.current = true;
      robotConnectionService.current = new ChaperoneRobotConnectionService(
        () => {
          // onConnected
          robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robot_state) => controlStore.updateRobotState(robot_state));
          robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission(() => controlStore.setDoneRepeating());
          robotConnection.current = robotConnectionService.current;
        },
        () => {
          // onDisconnect
          console.log('Lost connection to robot');
          if (robotConnectionService.current !== null) {
            if (!start) {
              subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
            }
            // sendRepeatCmdToRobot(`CANCEL,swapautonomy`);
            robotConnectionService?.current?.retryConnection();
          }
          handleConnectionError();
          robotConnection.current = null;
        },
        autonomyRobotStore.getSelectedRobot().serial_number,
        username,
        'chaperone_repeat'
      );
      robotConnectionService?.current?.connectToRobot(handleConnectionError);
    }
    return () => {
      if (robotConnectionService.current !== null) {
        setSubsection(subsection === '' ? state.subsection : subsection);
        if (!start) {
          subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
        }
        // sendRepeatCmdToRobot(`CANCEL,swapautonomy`);
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
        const selectedRobotIdAtCleanup = autonomyRobotStore.selectedRobotId;
      }
      if (!keepSelectedRobot.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
    };
  }, []);

  useEffect(() => {
    // Set interval timer for checking Joystick state
    const timer = setInterval(monitorRobotData, RobotData_INTERVAL_TIME);
    clearInterval(timer);
  }, [monitorRobotData]);

  window.onload = (e) => {
    push({
      pathname: ROUTE_CHAPERONE,
      loc: {
        region: state?.region,
        property: state?.property,
        section: state?.section,
        useCase: state?.useCase
      }
    });
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
    if (positionError) {
      setPositionError(false);
      return;
    }
    push(ROUTE_CHAPERONE);
  };

  const closeSettingDialog = () => {
    setShowSettingsDialog(false);
  };

  return (
    <Fade in={true}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <ActionsDialog
          dialogTitle="Robot is not autonomous now, do you want it in autonomous mode?"
          open={openWarningDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: () => setOpenWarningDialog(false) },
            { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmChangeDriveState }
          ]}
        />
        {showSettingsDialog && (
          <RepeatSettingsDialog
            isChaperoneRepeat={true}
            manager={manager}
            handleClose={closeSettingDialog}
            currentLinearSpeed={linearSpeed}
            setLinearSpeed={setLinearSpeed}
            currentLookAheadDistance={lookAheadDistance}
            setLookAheadDistance={setLookAheadDistance}
            currentAngularSpeed={angularSpeed}
            setAngularSpeed={setAngularSpeed}
          />
        )}
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <Grid item>
          <StripedTable headers={['Robot Name', 'Region Name', 'Property Name', 'Section Name', 'subsection Name']} align="center">
            <StripedTableRow>
              <TableCell align="center">{autonomyRobotStore.getSelectedRobot()?.serial_number}</TableCell>
              <TableCell align="center">{subsectionMeta?.section?.property?.region?.name}</TableCell>
              <TableCell align="center">{subsectionMeta?.section?.property?.name}</TableCell>
              <TableCell align="center">{subsectionMeta?.section?.name}</TableCell>
              <TableCell align="center">{subsectionMeta?.name}</TableCell>
            </StripedTableRow>
          </StripedTable>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <SettingsIcon
            className={classes.PlayCircleOutlineIcon}
            color="action"
            onClick={() => {
              setShowSettingsDialog(true);
            }}
          />
          <PlayCircleOutlineIcon
            className={classes.PlayCircleOutlineIcon}
            color="action"
            style={{
              display: start || resume ? 'inline' : 'none'
            }}
            onClick={changeRepeatingState}
          ></PlayCircleOutlineIcon>
          <PauseCircleOutlineIcon
            className={classes.PlayCircleOutlineIcon}
            color="action"
            style={{
              display: !start && !resume && pause ? 'inline' : 'none'
            }}
            onClick={changeRepeatingState}
          ></PauseCircleOutlineIcon>
          <Replay
            className={classes.PlayCircleOutlineIcon}
            color="action"
            style={{
              display: 'inline'
            }}
            onClick={restartRobot}
          ></Replay>
          {!start && Number(controlStore.wpsState) > 0 && (
            <Button onClick={() => cancelRepeatingTask(false)} className={classes.cancelButton} variant="contained">
              Cancel Sub-section
            </Button>
          )}
          <EStopIndicator
            eStopEngaged={controlStore.estopState || controlStore.swEstopState}
            videoStream={false}
            width={65}
            height={65}
            marginLeft={0}
            marginTop={10}
            marginRight={5}
            marginBottom={0}
          />
          <div className={classes.toggle}>
            {/* {console.log(manualControl)} */}
            <ToggleButtonGroup
              className={classes.toggleGroup}
              exclusive
              value={controlStore.getControlMode() != 'autonomous'}
              onChange={toggleDriveState}
              aria-label="text formatting"
            >
              <ToggleButton color="primary" value={true} aria-label="bold">
                Manual
              </ToggleButton>
              <ToggleButton color="secondary" value={false} aria-label="italic">
                Autonomous
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          {width <= 768 ? (
            <Typography id="input-slider" className={classes.label} style={{ minWidth: 300 }}>
              Maximum Linear Speed Limit (Km/hr):
            </Typography>
          ) : (
            <Typography id="input-slider" className={classes.label}>
              Maximum Linear Speed Limit (Km/hr):
            </Typography>
          )}
          <CustomSlider
            onMinusClick={() => {
              linearSpeed > LINEAR_SPEED.min && changeLinearSpeed({}, parseFloat((linearSpeed - LINEAR_SPEED.step).toFixed(2)));
            }}
            onAddClick={() => {
              linearSpeed < LINEAR_SPEED.max && changeLinearSpeed({}, parseFloat((linearSpeed + LINEAR_SPEED.step).toFixed(2)));
            }}
          >
            <Slider
              className={classes.Slider}
              defaultValue={LINEAR_SPEED.default}
              value={linearSpeed}
              onChange={changeLinearSpeed}
              getAriaValueText={valuetext}
              aria-labelledby="continuous-slider"
              min={LINEAR_SPEED.min}
              max={LINEAR_SPEED.max}
              step={LINEAR_SPEED.step}
              marks
            />
          </CustomSlider>
          <div className={classes.sliderLabel}>{linearSpeed}</div>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          {width <= 768 ? (
            <Typography id="input-slider" className={classes.label} style={{ minWidth: 300 }}>
              Plow Control Mode:
            </Typography>
          ) : (
            <Typography id="input-slider" className={classes.label}>
              Plow Control Mode:
            </Typography>
          )}
          <div className={classes.toggle}>
            {/* {console.log(manualControl)} */}
            <ToggleButtonGroup
              className={classes.toggleGroup}
              exclusive
              value={controlStore.getPlowControlMode() != 'autonomous'}
              onChange={togglePlowControlMode}
              aria-label="text formatting"
            >
              <ToggleButton color="primary" value={true} aria-label="bold">
                Manual
              </ToggleButton>
              <ToggleButton color="secondary" value={false} aria-label="italic">
                Autonomous
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          {width <= 768 ? (
            <Typography id="input-slider" className={classes.label} style={{ minWidth: 300 }}>
              Salter Control Mode:
            </Typography>
          ) : (
            <Typography id="input-slider" className={classes.label}>
              Salter Control Mode:
            </Typography>
          )}
          <div className={classes.toggle}>
            {/* {console.log(manualControl)} */}
            <ToggleButtonGroup
              className={classes.toggleGroup}
              exclusive
              value={controlStore.getSalterControlMode() != 'autonomous'}
              onChange={toggleSalterControlMode}
              aria-label="text formatting"
            >
              <ToggleButton color="primary" value={true} aria-label="bold">
                Manual
              </ToggleButton>
              <ToggleButton color="secondary" value={false} aria-label="italic">
                Autonomous
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        {!isGrassCutting && (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography id="input-slider" className={classes.label}>
              Salt Rate:
            </Typography>
            <CustomSlider
              onMinusClick={() => {
                if (saltRate > SALT_RATE.min) setSaltRate(saltRate - SALT_RATE.step);
              }}
              onAddClick={() => {
                if (saltRate < SALT_RATE.max) setSaltRate(saltRate + SALT_RATE.step);
              }}
            >
              <Slider
                className={classes.Slider}
                defaultValue={saltRate}
                value={saltRate}
                onChange={changeSaltRate}
                aria-labelledby="continuous-slider"
                min={SALT_RATE.min}
                max={SALT_RATE.max}
                step={SALT_RATE.step}
                marks
              />
            </CustomSlider>
            <div className={classes.sliderLabel}>{saltRate * 25}%</div>
          </Grid>
        )}

        {!isGrassCutting && (
          <Grid item>
            <StripedTable headers={['Height', 'Mode']} align="center">
              <StripedTableRow>
                <TableCell align="center">
                  {controlStore.plowHeight === 0 ? 'Active Down' : controlStore.plowHeight === 1 ? 'Up' : 'Float'}
                </TableCell>
                <TableCell align="center">
                  <Icon className={classes.marker}>
                    <img alt="Plow state" src={getStateIcon(controlStore.plowState)} height={30} width={45} />
                  </Icon>
                </TableCell>
              </StripedTableRow>
            </StripedTable>
          </Grid>
        )}

        <FormControlLabel
          style={{ color: 'white' }}
          checked
          control={<Checkbox style={{ color: 'white' }} checked={gridMap} />}
          onChange={(event) => {
            setGridMap(event.target.checked);
          }}
          label="Grid Map"
        />

        <FormControlLabel
          style={{ color: 'white' }}
          control={
            <Checkbox
              style={{ color: 'white' }}
              checked={googleMap}
              onChange={(event) => {
                setGoogleMap(event.target.checked);
              }}
            />
          }
          label="Google Map"
        />
        <ActionsDialog
          dialogTitle="Next subsection is less than 50cm from this subsection, do you want to start it?"
          open={openDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeDialogue },
            { color: 'secondary', name: 'Start next subsection', variant: 'contained', handler: openNewSubsection }
          ]}
        />
        <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
        {googleMap && (
          <Map
            robotLat={controlStore.lat}
            resetRobot={resetRobot}
            googleMapData={cords}
            currentPositionIndex={currentPositionIndex}
            robotLng={controlStore.lng}
          />
        )}
        {gridMap && (
          <GridMap
            currentPositionIndex={currentPositionIndex}
            gridMapData={gridMapData}
            robotEnabled
            resetRobot={resetRobot}
            robotLng={controlStore.lng}
            robotLat={controlStore.lat}
            robotHeadingRad={controlStore.current_heading_rad}
          />
        )}
        <RestartRobotDialog
          actions={[
            { name: 'Restart', color: '#EA2840', textColor: 'white', handler: restart },
            { name: 'Cancel', handler: () => setOpenConfirmRestartRobotDialog(false) }
          ]}
          dialogTitle="Are you sure you want to restart the robot?"
          open={openConfirmRestartRobotDialog}
        />
        <ActionsDialog
          dialogTitle="The robot is currently suspended and there is a pending teaching operation, do you want to release the robot and cancel the current teaching operation?"
          open={isSuspendedTeachingDialogOpen}
          actions={[
            { color: 'primary', name: 'cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
            { color: 'secondary', name: 'Continue teaching', variant: 'contained', handler: handleSuspendedTeachingDialogGoBack }
          ]}
        />
        <ActionsDialog
          dialogTitle="The robot is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current repeating operation?"
          open={isSuspendedRepeatingDialogOpen}
          actions={[
            { color: 'primary', name: 'cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
            { color: 'secondary', name: 'Continue repeating', variant: 'contained', handler: handleSuspendedRepeatingDialogGoBack }
          ]}
        />
      </Grid>
    </Fade>
  );
});
