import { types } from 'mobx-state-tree';

export const Block = types.model({
  id: types.integer,
  name: types.string,
  property_id: types.integer,
  drive_line: types.maybeNull(types.array(types.array(types.number))),
  panel_width: types.maybeNull(types.number),
  created_at: types.string,
  updated_at: types.string
});
