import { DIRECTIONS, PATH_TYPES } from './constants';

export const formatSubrows = (subrows, region, property, block, subBlock) => {
  const isLegacy = subrows.map((s) => s.path_type === PATH_TYPES.LEGACY || s.path_type === null).reduce((a, b) => a || b, false);
  if (isLegacy) {
    return subrows.map((subrow) => ({
      ...subrow,
      name: `${property}/${block}/${subBlock}/subrows/${subrow.order_index}_to_${subrow.order_index + 1}_${
        DIRECTIONS[subrow.direction]
      }__${region}_${property}_${block}_${subBlock}.csv`
    }));
  }
  return subrows.map((subrow) => ({
    ...subrow,
    name: `${property}/${block}/subrows/${subrow.order_index}_${
      DIRECTIONS[subrow.direction]
    }__${region}_${property}_${block}_${subBlock}.csv`
  }));
};
