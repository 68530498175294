import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Grid,
  Hidden,
  makeStyles,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Select,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  Chip,
  Checkbox
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { add, set } from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStores } from '../../store/root/root.store';
import { ControlledDatePicker } from '../inputs/controlled-date-picker.component';
import { DEFAULT_VALUE } from '../inputs/controlled-select.component';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%'
  },
  accordion: {
    marginBottom: theme.spacing(2)
  },
  accordionSummary: {
    justifyContent: 'center'
  },
  toggleLabel: {
    paddingRight: theme.spacing(1),
    textTransform: 'none'
  },
  toggleButton: {
    width: '100%'
  },
  fullWidth: {
    width: '100%'
  },
  formControl: {
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2),
    minWidth: '250px'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  }
}));

export const AuditFilter = ({ ...props }) => {
  const classes = useStyles();
  const { autonomyRobotStore, chaperonePropertyStore, regionsStore, auditReportsStore, countriesStore } = useStores();
  const { handleSubmit, control, getValues, reset } = useForm({
    defaultValues: { dataView: auditReportsStore.getDefaultDataView() },
    mode: 'onBlur'
  });
  const [defaultStartDate, setDefaultStartDate] = useState();
  const [defaultEndDate, setDefaultEndDate] = useState();
  const [isVideoFeed, setIsVideoFeed] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('DEFAULT');
  const [currentRegion, setCurrentRegion] = useState('DEFAULT');
  const [currentProperty, setCurrentProperty] = useState('DEFAULT');
  const [robotUseCase, setRobotUseCase] = useState('SOLAR_LAWN_MOWING');
  const [loadingData, setLoadingData] = useState(false);
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [selectAllRobots, setSelectAllRobots] = useState(false);
  /** Keeps track of selected robots for specific regions & properties. Structure is {region: {property:[selectedRobots]}} */
  const [previousSelectedRobots, setPreviousSelectedRobots] = useState({});

  const [firstLoad, setFirstLoad] = useState(true);
  const [valuesUpdated, setValuesUpdated] = useState(false);

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const [openFilter, setOpenFilter] = useState(true);

  const getRobots = async () => {
    const selectedCountry = currentCountry && currentCountry !== 'DEFAULT' ? currentCountry : undefined;
    const selectedRegion = currentRegion && currentRegion !== 'DEFAULT' ? currentRegion : undefined;
    const selectedProperty = currentProperty && currentProperty !== 'DEFAULT' ? currentProperty : undefined;
    await autonomyRobotStore.getRobots(selectedCountry, selectedRegion, selectedProperty, robotUseCase);
    if (selectAllRobots) {
      setSelectedRobots(autonomyRobotStore.robots.map((robot) => robot.serial_number));
    }
    if (!selectAllRobots && !valuesUpdated) {
      setSelectedRobots([]);
    }
    if (!selectAllRobots && valuesUpdated) {
      setSelectedRobots(autonomyRobotStore.robots.map((robot) => robot.serial_number));
    }
  };

  /** Update dictionary/object with previously selected robots */
  const updatePreviousRobotSelection = (region, property, prevSelectedRobots) => {
    setPreviousSelectedRobots((prev) => ({
      ...prev,
      [region]: {
        ...(prev[region] || {}),
        [property]: prevSelectedRobots
      }
    }));
  };

  /** Returns true if all robots are currently selected */
  const allRobotsAreSelected = (currentSelectedRobots) => {
    const allRobotsInRegion = autonomyRobotStore.robots.map((robot) => robot.serial_number);
    return allRobotsInRegion.length === currentSelectedRobots.length;
  };
  // On mobile devices, collapse the filter by default
  useEffect(() => {
    setOpenFilter(isDesktop || auditReportsStore.records?.length === 0);
    setDefaultStartDate(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    setDefaultEndDate(set(new Date(), { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 }));
    getRobots();
  }, [isDesktop, autonomyRobotStore, regionsStore, auditReportsStore, chaperonePropertyStore, countriesStore]);

  useEffect(() => {
    countriesStore.getCountries();
    auditReportsStore.clearReports();
  }, []);

  useEffect(() => {
    getRobots();
  }, [currentCountry, currentRegion, currentProperty, robotUseCase]);

  useEffect(() => {
    if (currentCountry && currentCountry !== 'DEFAULT') {
      reset({ ...getValues(), property: DEFAULT_VALUE });
      regionsStore.clearRegions();
      setCurrentRegion('DEFAULT');
      setCurrentProperty('DEFAULT');
      chaperonePropertyStore.clearProperties();
      regionsStore.getRegions(currentCountry);
    }
  }, [currentCountry]);

  useEffect(() => {
    if (currentRegion && currentRegion !== 'DEFAULT') {
      reset({ ...getValues(), property: DEFAULT_VALUE });
      chaperonePropertyStore.getProperties(currentRegion);
    }
  }, [regionsStore, currentRegion]);

  useEffect(() => {
    props.setSelectedRobots(selectedRobots);
  }, [selectedRobots]);

  const onSubmit = async (data) => {
    // Toggle button doesn't play with RHF as nicely as the others, so grab manually
    const dataView = auditReportsStore.getCurrentDataView();
    const date = set(data.startDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const startTime = date;
    const endTimeTmp = set(data.endDate, { hours: 0, minutes: 0, seconds: 0, milliseconds: 0 });
    const endTime = add(endTimeTmp, { hours: 23, minutes: 59, seconds: 59, milliseconds: 999 });

    auditReportsStore.setFilter({
      ...data,
      // Can't use null on the control itself, so swap it out here
      // robot: data.robot === DEFAULT_VALUE ? null : data.robot,
      robotList: selectedRobots,
      country: currentCountry === DEFAULT_VALUE ? null : currentCountry,
      region: currentRegion === DEFAULT_VALUE ? null : currentRegion,
      property: currentProperty === DEFAULT_VALUE ? null : currentProperty,
      startTime,
      endTime,
      isVideoFeed,
      dataView
    });
    await auditReportsStore.getReports().then(() => {
      setOpenFilter(auditReportsStore.records.length === 0);
    });

    // This loads all of the data, rather than just the first few hundred records
    // To only load the a few hundred records, check if the view is set to 'table'
    // while (auditReportsStore.hasMoreResults) await auditReportsStore.getReports();
    setLoadingData(false);
  };

  const generateReports = (data) => {
    setLoadingData(true);
    setFirstLoad(false);
    props.setFirstLoad(false);
    setValuesUpdated(false);
    props.setValuesUpdated(false);
    auditReportsStore.setDataView('table');
    onSubmit(data);
    props.getData(data);
  };

  const handleRobotUseCase = (event) => {
    setSelectedRobots([]);
    setValuesUpdated(true);
    props.setValuesUpdated(true);
    setRobotUseCase(event.target.value);
  };

  const handleChangeRegion = async (event) => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);
    const newRegion = event.target.value;

    setCurrentRegion(newRegion);

    if (newRegion in previousSelectedRobots && currentProperty in previousSelectedRobots[newRegion]) {
      const currentSelectedRobots = previousSelectedRobots[newRegion][currentProperty];
      setSelectedRobots(currentSelectedRobots);
      setSelectAllRobots(allRobotsAreSelected(currentSelectedRobots));
    } else {
      setSelectedRobots([]);
      setSelectAllRobots(false);
    }
  };

  const handleChangeCountry = (event) => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);
    if (event.target.value) {
      setCurrentCountry(event.target.value);
      if (!selectAllRobots) setSelectedRobots([]);
    }
  };

  const handleChangeProperty = (event) => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);

    const newProperty = event.target.value;
    setCurrentProperty(newProperty);
    if (currentRegion in previousSelectedRobots && newProperty in previousSelectedRobots[currentRegion]) {
      const currentSelectedRobots = previousSelectedRobots[currentRegion][newProperty];
      setSelectedRobots(currentSelectedRobots);
      setSelectAllRobots(allRobotsAreSelected(currentSelectedRobots));
    } else {
      setSelectedRobots([]);
      setSelectAllRobots(false);
    }
  };

  const handleChangeSelectedRobots = (event) => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);
    const selectedValues = event.target.value;
    setSelectedRobots(selectedValues);
    // Update selected robots with previous selected robots for region
    const allRobotsInRegion = autonomyRobotStore.robots.map((robot) => robot.serial_number);
    const allRobotsSelected = allRobotsInRegion.length === selectedValues.length;
    setSelectAllRobots(allRobotsSelected);
    updatePreviousRobotSelection(currentRegion, currentProperty, selectedValues);
  };

  const handleChangeFeedType = (v) => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);
    setIsVideoFeed(v === 'video');
  };

  const handleChangeRobotAndDate = () => {
    setValuesUpdated(true);
    props.setValuesUpdated(true);
  };

  const compare = (a, b) => {
    const firstSerialNumber = a.serial_number.toLowerCase();
    const secondSerialNumber = b.serial_number.toLowerCase();
    if (firstSerialNumber < secondSerialNumber) {
      return -1;
    }
    if (firstSerialNumber > secondSerialNumber) {
      return 1;
    }
    return 0;
  };

  /* Handler for Checking Select All Robots */
  const handleSelectAllRobots = () => {
    if (currentRegion) {
      const allRobotsInRegion = autonomyRobotStore.robots.map((robot) => robot.serial_number);
      setSelectedRobots(allRobotsInRegion);
      setSelectAllRobots(true);
      updatePreviousRobotSelection(currentRegion, currentProperty, allRobotsInRegion);
    }
  };

  /** Handler for Un-checking Select All Robots */
  const handleDeselectAllRobots = () => {
    if (currentRegion) {
      setSelectedRobots([]);
      setSelectAllRobots(false);
      updatePreviousRobotSelection(currentRegion, currentProperty, []);
    }
  };

  /** Updates selected robots based on change in region & property */
  useEffect(() => {
    getRobots().then(() => {
      if (currentRegion in previousSelectedRobots && currentProperty in previousSelectedRobots[currentRegion]) {
        const currentSelectedRobots = previousSelectedRobots[currentRegion][currentProperty];
        setSelectedRobots(currentSelectedRobots);
        setSelectAllRobots(allRobotsAreSelected(currentSelectedRobots));
      }
    });
  }, [currentRegion, currentProperty]);

  return useObserver(() => (
    <Grid item xs className={classes.accordion}>
      <Accordion expanded={openFilter} onChange={(e, isExpanded) => setOpenFilter(isExpanded)}>
        <AccordionSummary expandIcon={<ExpandMore />} classes={{ content: classes.accordionSummary }}>
          <Typography component="p" variant="h4">
            Operations Audit Report Filters
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Paper component="form" color="inverted" onSubmit={handleSubmit(generateReports)} elevation={0} className={classes.form}>
            <Grid container direction="column">
              <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start">
                <Grid container item direction="column" xs={12} sm={6} md={4} alignItems="center">
                  <Grid item>
                    <Typography style={{ fonSize: 'inherit' }}>Start Date</Typography>
                    <ControlledDatePicker
                      name="startDate"
                      defaultValue={defaultStartDate}
                      style={{ fontSize: 40 }}
                      control={control}
                      required
                      updateDate={handleChangeRobotAndDate}
                    />
                  </Grid>
                  <Grid item>
                    <Typography style={{ fonSize: 'inherit' }}>End Date</Typography>
                    <ControlledDatePicker
                      name="endDate"
                      defaultValue={defaultEndDate}
                      control={control}
                      required
                      updateDate={handleChangeRobotAndDate}
                    />
                  </Grid>
                  <Grid item style={{ marginTop: '10px', marginRight: '70px' }}>
                    <Typography style={{ fonSize: 'inherit' }}>Audit Feed Type</Typography>
                    <RadioGroup row defaultValue="image" name="feedType" onChange={(_, v) => handleChangeFeedType(v)}>
                      <FormControlLabel value="image" control={<Radio />} label="Images" />
                      <FormControlLabel value="video" control={<Radio />} label="Videos" />
                    </RadioGroup>
                  </Grid>
                </Grid>

                <Grid container item direction="column" xs={12} sm={6} md={4} alignItems="center">
                  <Grid>
                    <FormControl className={classes.formControl}>
                      <Typography style={{ fonSize: 'inherit' }}>Robot&apos;s Country</Typography>
                      <Select
                        labelId="country-select-label"
                        id="country-select-id"
                        onChange={handleChangeCountry}
                        value={currentCountry}
                        fullWidth
                      >
                        <MenuItem value="DEFAULT">No Selection</MenuItem>
                        {countriesStore.countries.map((country) => (
                          <MenuItem key={country.id} value={country.id}>
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid>
                    <FormControl className={classes.formControl}>
                      <Typography style={{ fonSize: 'inherit' }}>Robot&apos;s Region</Typography>
                      <Select
                        labelId="region-select-label"
                        id="region-select-id"
                        onChange={handleChangeRegion}
                        value={currentRegion}
                        fullWidth
                      >
                        <MenuItem value="DEFAULT">No Selection</MenuItem>
                        {regionsStore.regions.map((region) => (
                          <MenuItem key={region.id} value={region.id}>
                            {region.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Typography style={{ fonSize: 'inherit' }}>Robot&apos;s Property</Typography>
                      <Select
                        labelId="property-select-label"
                        id="property-select-id"
                        onChange={handleChangeProperty}
                        value={currentProperty}
                        fullWidth
                      >
                        <MenuItem value="DEFAULT">No Selection</MenuItem>
                        {chaperonePropertyStore.properties.map((property) => (
                          <MenuItem key={property.id} value={property.id}>
                            {property.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container item direction="column" xs={12} sm={6} md={4}>
                  <Grid item style={{ marginTop: '20px' }}>
                    <Typography style={{ fonSize: 'inherit' }}>Robot&apos;s Use-Case</Typography>
                    <RadioGroup row defaultValue="SOLAR_LAWN_MOWING" name="robotType" onChange={handleRobotUseCase}>
                      <FormControlLabel value="SOLAR_LAWN_MOWING" control={<Radio />} label="Solar" />
                      {/* <FormControlLabel value="SNOW_PLOWING" control={<Radio />} label="Snow" /> */}
                    </RadioGroup>
                  </Grid>

                  <Grid item>
                    <FormControl className={classes.formControl}>
                      <Typography style={{ fonSize: 'inherit' }}>Robots</Typography>
                      <Select
                        labelId="robots-select-label"
                        id="robots-select-id"
                        onChange={handleChangeSelectedRobots}
                        value={selectedRobots}
                        fullWidth
                        label="Robots"
                        multiple
                        renderValue={(selected) => (
                          <div className={classes.chips}>
                            {selected.map((value) => (
                              <Chip key={value} label={value} className={classes.chip} />
                            ))}
                          </div>
                        )}
                      >
                        <FormControlLabel
                          style={{ margin: '3px' }}
                          control={
                            // eslint-disable-next-line react/jsx-wrap-multilines
                            <Checkbox
                              checked={selectAllRobots}
                              onChange={(event) => (event.target.checked ? handleSelectAllRobots() : handleDeselectAllRobots())}
                            />
                          }
                          label="Select All"
                        />
                        {autonomyRobotStore.robots
                          .slice()
                          .sort(compare)
                          .map((robot) => (
                            <MenuItem key={robot.serial_number} value={robot.serial_number}>
                              {robot.serial_number}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item container direction="row" justifyContent="flex-end" alignItems="center" className={classes.fullWidth}>
                <Grid container item direction="row" justifyContent="space-between" alignItems="flex-start">
                  <Hidden mdDown>
                    <Grid item xs />
                  </Hidden>
                  <Hidden smDown>
                    <Grid item xs />
                  </Hidden>
                  <Hidden smDown>
                    <Grid item xs />
                  </Hidden>
                  <Grid item xs>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleSubmit(generateReports)}
                      color="secondary"
                      disabled={(!firstLoad && !valuesUpdated) || loadingData}
                      fullWidth
                      style={{ marginTop: '16px', marginLeft: '10px' }}
                    >
                      {loadingData ? 'Loading...' : 'Generate'}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </AccordionDetails>
      </Accordion>
    </Grid>
  ));
};
