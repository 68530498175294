import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { makeStyles } from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import { useStores } from '../../store/root/root.store';
import { TEMP_CHART_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  chart: {
    maxHeight: TEMP_CHART_HEIGHT
  }
}));

export default function AuditCumulativeDeviationChart() {
  const classes = useStyles();
  const { autonomyAuditReportsStore } = useStores();

  ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

  let bins = [];
  let binCount = 0;
  let interval = Math.max(...(autonomyAuditReportsStore?.selectedRecord?.deviationArray || 0)) / 30;
  let numOfBuckets = 1;
  let points = [];

  // Setup Bins
  for (let i = 0; i < numOfBuckets; i += interval) {
    bins.push({
      binNum: binCount,
      minNum: i,
      maxNum: i + interval,
      avgNum: (i + (i + interval)) / 2,
      count: 0
    });
    binCount++;
  }

  // Loop through data and add to bin's count
  for (let i = 0; i < autonomyAuditReportsStore?.selectedRecord?.deviationArray.length; i++) {
    let item = autonomyAuditReportsStore?.selectedRecord?.deviationArray[i];
    for (let j = 0; j < bins.length; j++) {
      let bin = bins[j];
      if (item > bin.minNum && item <= bin.maxNum) {
        bin.count++;
        break; // An item can only be in one bin.
      }
    }
  }

  // Create points from all bins
  for (let i = 0; i < bins.length; i++) {
    points.push({
      x: bins[i].avgNum,
      y: bins[i].count
    });
  }

  const data = {
    datasets: [
      {
        label: '# of Occurrences',
        data: points,
        backgroundColor: ['rgba(234, 40, 64, 0.5)'],
        borderColor: ['rgba(234, 40, 64, 1)'],
        borderWidth: 1,
        barPercentage: 1,
        categoryPercentage: 1
      }
    ]
  };

  const options = {
    responsive: true,
    normalized: true,
    spanGaps: true,
    scales: {
      x: {
        type: 'linear',
        offset: false,
        grid: {
          offset: false
        },
        title: {
          display: true,
          text: 'Deviation (m)'
        }
      },
      y: {
        title: {
          display: true,
          text: '# of Occurrences'
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        callbacks: {
          title: (items) => {
            if (!items.length) return '';
            const item = items[0];
            const x = item.parsed.x;
            const min = x - interval / 2;
            const max = x + interval / 2;
            return `Range: ${min.toPrecision(2)}m - ${max.toPrecision(2)}m`;
          }
        }
      }
    }
  };

  return <Bar className={classes.chart} options={options} data={data} />;
}
