import React from 'react';
import { Dialog, DialogTitle } from '@material-ui/core';

/**
 * Displays confirmation message when an infraction email is sent.
 * @param {Boolean} open - Boolean value to show or hide confirmation message
 * @param {String} message - A message stating the results of email send attempt
 *
 */
const EmailConfirmationDialog = ({ message, open }) => (
  <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
    <DialogTitle id="alert-dialog-title">{message}</DialogTitle>
  </Dialog>
);

export default EmailConfirmationDialog;
