import { types } from 'mobx-state-tree';

const ActiveClient = types.model({
  user: types.maybeNull(types.string)
});

export const NetworkState = types.model({
  portal_client_count: types.maybeNull(types.number),
  iot_robot_latency: types.maybeNull(types.number),
  download_speed: types.maybeNull(types.number),
  upload_speed: types.maybeNull(types.number),
  interface_type: types.maybeNull(types.string),
  active_client: types.maybeNull(ActiveClient)
});
