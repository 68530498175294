import React from 'react';
import { Dialog, CircularProgress, DialogContent, DialogTitle, Typography, makeStyles, Button } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  dialogContent: { textAlign: 'center', paddingTop: theme.spacing(2), paddingBottom: theme.spacing(1) },
  message: { marginTop: theme.spacing(1) },
  icon: { fontSize: 60, transition: 'transform 0.4s ease-in-out', color: 'red' }
}));

/**
 * Dialog to show speed test data
 * @param {Boolean} open - dialog rendering control
 * @param {String} status - speed test status fail/success
 * @param {String} message - Contains an object with values for downloadSpeed, uploadSpeed, and latency
 * @returns
 */
const SpeedTestDialog = ({ open, status, data, close }) => {
  const classes = useStyles();
  const renderContent = () => {
    switch (status) {
      case 'loading':
        return <CircularProgress />;
      case 'error':
        return (
          <>
            <Typography variant="h5" align="center">
              Error Running Speed Test
            </Typography>
            <Typography variant="h6" gutterBottom align="center">
              Please Contact Autonomy
            </Typography>
            <HighlightOffIcon className={classes.icon} />
          </>
        );
      case '':
        return null;
      default:
        return null;
    }
  };

  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h4" align="center">
          {status === 'loading' ? 'Running Speed Test' : 'Result'}
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        {Object.keys(data).length !== 0 && status === '' ? (
          <>
            <Typography variant="h6">
              <b>Download Speed: </b> {`${data.download}`}
            </Typography>
            <Typography variant="h6">
              <b>Upload Speed: </b> {`${data.upload}`}
            </Typography>
            <Typography variant="h6">
              <b>Latency: </b> {`${data.latency}`}
            </Typography>
            <Button variant="contained" color="secondary" style={{ marginTop: '7px ' }} onClick={() => close()}>
              Close
            </Button>
          </>
        ) : (
          renderContent()
        )}
      </DialogContent>
    </Dialog>
  );
};

export default SpeedTestDialog;
