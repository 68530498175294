import React, { useEffect, useRef } from 'react';
import { useSolarMap } from '../../hooks/useSolarMap';

const AppleMapComponent = ({ mapData, robotLat, robotLng, robotHeading, selectedSubRows, resetRobot, height, width, customStyle }) => {
  const mapRef = useRef(null);
  const mapInstance = useRef(null);
  const initialized = useRef(false);
  const wheelInitializationTimeoutRef = useRef(null);

  const { progress, center, setDragged, setCenter, robotLatRef, robotLngRef } = useSolarMap({
    mapData,
    robotLat,
    robotLng,
    resetRobot
  });
  const lastProgressPolylineRef = useRef(null);
  const lastAnnotationRef = useRef(null);
  /**
   * Creates an SVG icon as a string based on provided fill color.
   * @param {string} fillColor - The color to fill the icon.
   * @returns {string} - SVG markup for the icon.
   */
  const icon = (fillColor) => `
  <svg width="15" height="15" viewBox="-0.5 -0.5 599.5 599.5" fill="${fillColor}" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid meet">
    <path d="M 299.5,-0.5 C 299.833,-0.5 300.167,-0.5 300.5,-0.5 C 400.455,198.41 500.122,397.41 599.5,596.5 C 599.5,597.5 599.5,598.5 599.5,599.5 C 598.833,599.5 598.167,599.5 597.5,599.5 C 498.256,556.551 398.923,513.551 299.5,470.5 C 200.481,513.719 101.481,556.719 2.5,599.5 C 1.5,599.5 0.5,599.5 -0.5,599.5 C -0.5,598.5 -0.5,597.5 -0.5,596.5 C 99.2117,397.41 199.212,198.41 299.5,-0.5 Z" />
  </svg>
`;

  /**
   * Factory function to create an SVG annotation element with specified options.
   * @param {Object} options - Options for SVG styling including rotation, fill color, and opacity.
   * @returns {SVGElement} - Configured SVG element for the annotation.
   */
  const factory = function (_, options) {
    const { rotation, fillColor = '#FF0000', opacity = 1 } = options.data;
    const svgContent = icon(fillColor);

    // Create an SVG element from the string
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    // Apply styles directly to the SVG
    svgElement.style.transform = `rotate(${rotation}deg)`;
    svgElement.style.opacity = opacity;

    // Return the SVG element directly
    return svgElement;
  };

  /**
   * Handler for the start of a scroll event. Sets dragged state to true and updates the center state.
   */
  const handleScrollStart = () => {
    setDragged(true);
    setCenter({});
  };

  /**
   * Handler for the end of a scroll event. Sets dragged state to false and re-centers the map to the robot's coordinates.
   */
  const handleScrollEnd = () => {
    setDragged(false);
    if (mapInstance.current) {
      mapInstance.current.region = new mapkit.CoordinateRegion(
        new mapkit.Coordinate(
          robotLatRef && robotLatRef.current ? robotLatRef.current : center.lat,
          robotLngRef && robotLngRef.current ? robotLngRef.current : center.lng
        ),
        new mapkit.CoordinateSpan(0.0001, 0.0001)
      );
    }
  };

  /**
   * Custom zoom control using the mouse wheel. Adjusts the zoom level based on the scroll direction.
   * @param {WheelEvent} event - The wheel event triggered by mouse scroll.
   */
  const handleWheel = (event) => {
    event?.preventDefault();
    if (mapInstance?.current && center && Number.isFinite(center.lat) && Number.isFinite(center.lng) && typeof event?.deltaY === 'number') {
      const { region } = mapInstance.current;
      const zoomFactor = event.deltaY > 0 ? 1.1 : 0.9;
      if (typeof region?.span?.latitudeDelta === 'number' && typeof region?.span?.longitudeDelta === 'number') {
        const newSpan = new mapkit.CoordinateSpan(region.span.latitudeDelta * zoomFactor, region.span.longitudeDelta * zoomFactor);
        mapInstance.current.region = new mapkit.CoordinateRegion(new mapkit.Coordinate(center.lat, center.lng), newSpan);
      }
    }
  };

  /**
   * Initializes the map instance with a center region, adds event listeners for scroll and wheel events.
   */
  useEffect(() => {
    if (mapRef.current && !mapInstance.current && center && typeof center.lat === 'number' && typeof center.lng === 'number') {
      try {
        mapInstance.current = new mapkit.Map(mapRef.current, {
          region: new mapkit.CoordinateRegion(new mapkit.Coordinate(center.lat, center.lng), new mapkit.CoordinateSpan(0.0001, 0.0001)),
          showsCompass: 'hidden',
          showsUserLocationControl: false,
          mapType: 'hybrid',
          showsUserLocation: false,
          showsZoomControl: false,
          showsMapTypeControl: false,
          showsPointsOfInterest: true,
          showsScale: 'hidden'
        });

        mapInstance.current.addEventListener('scroll-start', handleScrollStart);

        mapInstance.current.addEventListener('scroll-end', handleScrollEnd);
        // Setting timeout for the wheel listener to avoid crashing when zooming once component is loaded
        wheelInitializationTimeoutRef.current = setTimeout(() => {
          // Custom zoom control with mouse wheel
          if (mapRef.current) {
            mapRef.current.addEventListener('wheel', handleWheel);
          }
        }, 2000); // Delay in milliseconds
        initialized.current = true;
      } catch (error) {
        console.error('Error initializing map:', error);
      }
    }
    return () => {
      if (wheelInitializationTimeoutRef.current) {
        clearTimeout(wheelInitializationTimeoutRef.current);
      }
      if (mapInstance.current) {
        mapInstance.current.removeEventListener('scroll-start', handleScrollStart);
        mapInstance.current.removeEventListener('scroll-end', handleScrollEnd);
      }

      if (mapRef.current) {
        mapRef.current.removeEventListener('wheel', handleWheel);
      }
    };
  }, [center]);

  /**
   * Updates the map with the latest progress and annotations based on the robot's path.
   */
  useEffect(() => {
    if (mapInstance.current && progress.length > 0) {
      const progressCoordinates = progress.map((point) => new mapkit.Coordinate(point.lat, point.lng));
      const progressStyle = new mapkit.Style({
        lineWidth: 5,
        strokeColor: '#FF0000'
      });

      const polyline = new mapkit.PolylineOverlay(progressCoordinates, {
        style: progressStyle
      });
      mapInstance.current.addOverlay(polyline);
      if (lastProgressPolylineRef.current) {
        mapInstance.current.removeOverlay(lastProgressPolylineRef.current);
      }
      lastProgressPolylineRef.current = polyline;
      const lastPoint = progress[progress.length - 1];
      if (lastPoint.lat && lastPoint.lng) {
        const options = {
          data: {
            rotation: 90 - (robotHeading * 180) / Math.PI,
            fillColor: '#FF0000'
          }
        };
        const annotation = new mapkit.Annotation(new mapkit.Coordinate(lastPoint.lat, lastPoint.lng), factory, options);
        mapInstance.current.addAnnotation(annotation);
        if (lastAnnotationRef.current) {
          mapInstance.current.removeAnnotation(lastAnnotationRef.current);
        }
        lastAnnotationRef.current = annotation;
      }
    }
  }, [progress, robotHeading]);

  /**
   * Updates map data, rendering polylines and annotations for each line in `mapData`.
   */
  useEffect(() => {
    if (mapInstance.current && mapData) {
      mapInstance.current.overlays.forEach((overlay) => mapInstance.current.removeOverlay(overlay));
      mapData.forEach((line) => {
        const isCurrentSubRow = line?.[0]?.key && selectedSubRows?.[0] && line?.[0]?.key === selectedSubRows?.[0];
        const isSelectedSubRow = selectedSubRows?.length > 0 && line?.[0]?.key && selectedSubRows.includes(line?.[0]?.key);
        const strokeColor = isCurrentSubRow ? '#00FF00' : '#00FFFF';
        const transparency = isSelectedSubRow ? 1 : 0;
        const rotation = 90 - (line[0].angle * 180) / Math.PI;

        const style = new mapkit.Style({
          lineWidth: 5,
          strokeColor
        });
        const path = line.map((point) => new mapkit.Coordinate(point.lat, point.lng));

        const pairedPathCoordinatesArray = [];
        // Use pairs of consecutive coordinates for each polyline segment instead of
        // all coordinates in one polyline to avoid incorrect drawing on the map in MapKit
        for (let i = 0; i < path.length - 1; i += 1) {
          pairedPathCoordinatesArray.push([path[i], path[i + 1]]);
        }
        pairedPathCoordinatesArray.map((coordinates) => {
          const polyline = new mapkit.PolylineOverlay(coordinates, {
            style
          });
          return mapInstance.current.addOverlay(polyline);
        });

        const options = {
          data: {
            rotation,
            fillColor: 'yellow',
            opacity: transparency
          }
        };
        const annotation = new mapkit.Annotation(new mapkit.Coordinate(line[0].lat, line[0].lng), factory, options);
        mapInstance.current.addAnnotation(annotation);
      });
    }
  }, [mapData, selectedSubRows.length]);

  return <div ref={mapRef} style={{ width: width || '100%', height, cursor: 'grab' }} className={customStyle} />;
};

export default AppleMapComponent;
