import React from 'react';
import { FormControl, Grid, Typography, FormHelperText, TextField } from '@material-ui/core';

const CustomTextField = ({ label, id, value, setValue, classes, formError, required, customErrorMessage }) => {
  return (
    <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
      <Typography variant="h6" component="span" className={classes.label}>
        {label}:
      </Typography>
      <FormControl className={classes.formControl}>
        <TextField
          id={id}
          placeholder={label}
          className={classes.input}
          variant="outlined"
          value={value}
          onChange={(event) => setValue(event.target.value)}
        />
        {!value && required && formError && <FormHelperText className={classes.helperText}>This is required!</FormHelperText>}
        {customErrorMessage && <FormHelperText className={classes.helperText}>{customErrorMessage}</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default CustomTextField;
