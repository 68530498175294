import { Grid } from '@material-ui/core';
import React from 'react';
import { AuditReportsDataDetails } from './audit-reports-data-details.component';
import { AuditReportsDataMap } from './audit-reports-data-map';

export const AuditReportsMapView = () => {
  return (
    <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={4}>
      <Grid item xs={12} lg={9}>
        <AuditReportsDataMap />
      </Grid>
      <Grid item xs={12} lg={3}>
        <AuditReportsDataDetails />
      </Grid>
    </Grid>
  );
};
