import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import CustomTextField from '../inputs/CustomTextField';
import CustomSelectField from '../inputs/CustomSelectField';
import { ROLES_LIST } from '../../utils/constants';
import { guardedClient } from '../../utils/axios-instance';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.main,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  },
  helperText: {
    color: 'red'
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  }
}));

export const EditUserDialog = ({ handleEdit, handleClose, open, user }) => {
  const classes = useStyles();
  const [formError, setFormError] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [region, setRegion] = useState('');
  const [regions, setRegions] = useState([]);
  const [property, setProperty] = useState('');
  const [properties, setProperties] = useState([]);

  const handleClick = () => {
    if (!username || !role) {
      setFormError(true);
      return;
    }
    const updatedUser = {
      ...user,
      firstName,
      lastName,
      username,
      role,
      country: String(country) || ' ',
      region: String(region) || ' ',
      properties: String(property) || ' ',
      profilePicPath: user['custom:profilePicPath']
    };
    handleEdit(updatedUser);
  };

  useEffect(() => {
    setFirstName(user['custom:firstName'] || '');
    setLastName(user['custom:lastName'] || '');
    setUsername(user['custom:username'] || '');
    setRole(user['custom:Role'] || '');
    setCountry(user['custom:country']?.trim() || '');
    setRegion(user['custom:region']?.trim() || '');
    setProperty(user['custom:properties']?.trim() || '');
  }, [user]);

  useEffect(() => {
    if (role === 'client') {
      guardedClient
        .get('/countries')
        .then((res) => setCountries(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [role]);

  useEffect(() => {
    if (country) {
      guardedClient
        .get('/regions', { params: { country } })
        .then((res) => setRegions(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [country]);

  useEffect(() => {
    if (region) {
      guardedClient
        .get('/properties', { params: { region } })
        .then((res) => setProperties(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [region]);

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
      <DialogTitle onClose={handleClose} id="alert-dialog-title">
        Edit User
      </DialogTitle>
      <DialogContent>
        Email: {user.email}
        {/* Fields goes here */}
        <CustomTextField
          label="Username"
          id="username"
          value={username}
          setValue={setUsername}
          classes={classes}
          formError={formError}
          required={true}
        />
        <CustomTextField
          label="First Name"
          id="first-name"
          value={firstName}
          setValue={setFirstName}
          classes={classes}
          formError={formError}
          required={false}
        />
        <CustomTextField
          label="Last Name"
          id="last-name"
          value={lastName}
          setValue={setLastName}
          classes={classes}
          formError={formError}
          required={false}
        />
        <CustomSelectField
          label="Role"
          id="role"
          value={role}
          setValue={setRole}
          options={ROLES_LIST}
          classes={classes}
          formError={formError}
          required={true}
        />
        {role === 'client' && (
          <>
            <CustomSelectField
              label="Country"
              id="country"
              value={country}
              setValue={setCountry}
              options={countries}
              classes={classes}
              formError={formError}
              required={true}
            />
            <CustomSelectField
              label="Region"
              id="region"
              value={region}
              setValue={setRegion}
              options={regions}
              classes={classes}
              formError={formError}
              required={true}
            />
            <CustomSelectField
              label="Property"
              id="property"
              value={property}
              setValue={setProperty}
              options={properties}
              classes={classes}
              formError={formError}
              required={true}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={handleClick} variant="contained" color="secondary">
          Edit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
