import React from 'react';
import {
  Grid,
  Paper,
  makeStyles,
  Typography,
  Button,
  FormControl,
  OutlinedInput,
  FormHelperText,
  CircularProgress,
  Fade
} from '@material-ui/core';
import { Email, Lock } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { useStores } from '../store/root/root.store';
import { useObserver } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import { ROUTE_HOME } from './routes';

const useStyles = makeStyles((theme) => ({
  loginForm: {
    padding: theme.spacing(4)
  },
  form: {
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  progress: {
    color: theme.palette.inverted.main
  },
  background: {
    height: '100%',
    paddingTop: theme.spacing(2)
  }
}));

export const LoginPage = () => {
  const classes = useStyles();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const { authenticationStore } = useStores();
  const { push } = useHistory();

  const validateNewPassword = (password) => {
    const validationRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{7,14}$/;
    return validationRegex.test(password);
  };

  const onSubmit = async (data) => {
    if (authenticationStore.isRequireNewPassword()) {
      if (validateNewPassword(data.newPassword)) {
        await authenticationStore.setNewPassword(data.newPassword);
      } else {
        return;
      }
    } else {
      await authenticationStore.login(data.email, data.password);
    }
    if (authenticationStore.isAuthenticated()) {
      push(ROUTE_HOME);
    }
  };

  return useObserver(() => (
    <Fade in={true}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.background}>
        <Grid item xs={12} lg={4}>
          <Paper component="form" elevation={0} className={classes.loginForm} color="inverted" onSubmit={handleSubmit(onSubmit)}>
            <Grid container direction="column" justifyContent="center" alignItems="center" spacing={2}>
              <Grid container item xs={12}>
                <Typography variant="h4">Welcome</Typography>
              </Grid>
              {authenticationStore.error ? (
                <Grid container item xs={12}>
                  <Typography color="error">Unable to log in. Please try again.</Typography>
                </Grid>
              ) : null}
              {authenticationStore.isRequireNewPassword() ? (
                <Grid container item xs={12}>
                  <Typography color="error">
                    Please set new password to continue. (Should be minimum 8 characters and contain uppercase and lowercase letters)
                  </Typography>
                </Grid>
              ) : null}
              <Grid container item xs={12} direction="row" spacing={2} alignItems="flex-end">
                <Email />
                <Typography>Email Address</Typography>
              </Grid>
              <Grid container item xs={12}>
                <FormControl variant="outlined" fullWidth error={!!errors.email}>
                  <OutlinedInput
                    aria-describedby="email-error-text"
                    fullWidth
                    name="email"
                    placeholder="Email"
                    inputRef={register({ required: true, pattern: /\S+@\S+\.\S+/ })}
                  />
                  <FormHelperText id="email-error-text">
                    {errors.email?.type === 'required' && 'Required'}
                    {errors.email?.type === 'pattern' && 'Invalid email'}
                  </FormHelperText>
                </FormControl>
              </Grid>
              <Grid container item xs={12}></Grid>
              <Grid container item xs={12} direction="row" spacing={2} alignItems="flex-end">
                <Lock />
                <Typography>Password</Typography>
              </Grid>
              <Grid container item xs={12}>
                <FormControl variant="outlined" fullWidth error={!!errors.password}>
                  <OutlinedInput
                    aria-describedby="password-error-text"
                    fullWidth
                    name="password"
                    placeholder="Password"
                    type="password"
                    inputRef={register({ required: true })}
                  />
                  <FormHelperText id="email-error-text">{errors.password?.type === 'required' && 'Required'}</FormHelperText>
                </FormControl>
              </Grid>

              {authenticationStore.isRequireNewPassword() && (
                <>
                  <Grid container item xs={12}></Grid>
                  <Grid container item xs={12} direction="row" spacing={2} alignItems="flex-end">
                    <Lock />
                    <Typography>New password</Typography>
                  </Grid>
                  <Grid container item xs={12}>
                    <FormControl variant="outlined" fullWidth error={!!errors.newPassword}>
                      <OutlinedInput
                        aria-describedby="new-password-error-text"
                        fullWidth
                        name="newPassword"
                        placeholder="New password"
                        type="password"
                        inputRef={register({ required: true })}
                      />
                      <FormHelperText id="new-password-error-text">{errors.newPassword?.type === 'required' && 'Required'}</FormHelperText>
                    </FormControl>
                  </Grid>
                </>
              )}

              <Grid container item direction="column" xs={12}>
                <Typography variant="caption" component="div">
                  All fields are required
                </Typography>
                <Typography variant="caption" component="p">
                  By signing in, you accept Swap Robotics' terms and conditions of use.
                </Typography>
              </Grid>
              <Grid container item xs={12} direction="row" spacing={2}>
                <Button size="large" fullWidth variant="contained" color="secondary" type="submit" disableElevation>
                  {authenticationStore.isLoading() ? (
                    <CircularProgress size="1.6rem" className={classes.progress} />
                  ) : authenticationStore.isRequireNewPassword() ? (
                    'Set new password and login'
                  ) : (
                    'Login'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Fade>
  ));
};
