import { guardedClient } from '../../utils/axios-instance';

export const addUser = async (user) => {
  const response = await guardedClient.post('/users', user);
  return response.data;
};

export const getAllUsers = async () => {
  const response = await guardedClient.get('/users');
  return response.data;
};

export const deleteUser = async (user) => {
  const response = await guardedClient.delete(`/users/${user.email}`);
  return response.data;
};

export const editUser = async (user) => {
  const response = await guardedClient.patch(`/users/${user.email}`, user);
  return response.data;
};
