/* eslint-disable no-param-reassign */
import { types, flow, getEnv } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { Section } from './models/chaperone-section.model';
import { alphaNumericOrder } from '../../utils/ui.utils';

const SectionStoreInternal = types
  .model('SectionStore', {
    sections: types.array(Section),
    selectedSectionId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedSectionId,
    getById: (id) => self.sections.find((r) => r.id === id),
    getSelectedSection: () => self.sections.find((r) => r.id === self.selectedSectionId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.fetchSections(filter.property);
    }),
    getApiCreate: flow(function* f(section) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.addSection(section);
    }),
    getApiUpdate: flow(function* f(section) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.updateSection(section);
    }),
    onFetchSuccess(results) {
      self.sections = results.toSorted(alphaNumericOrder((s) => s.name));
    },
    onCreateSuccess(created) {
      //  self.sections.push(created);
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load sections');
    },
    addSection: flow(function* createSection(section) {
      return yield self.create(section);
    }),
    updateSection: flow(function* updateSection(section) {
      return yield self.update(section);
    }),
    getSections: flow(function* getSections(region, property) {
      yield self.fetch({ region, property });
    }),
    getSectionById({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.fetchSectionById(id);
    },
    deleteSection({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.deleteSection(id);
    },
    onCreateError(error) {
      self.throwError(error?.message || 'Failed to save sections');
    },
    onUpdateSuccess(data) {
      console.log('onUpdateSuccess', data);
    },
    onSaveError(error) {
      self.throwError(error?.message || 'Failed to delete sections');
    },
    setSelectedSection(sectionId) {
      self.selectedSectionId = sectionId;
    },
    clearSelectedSection() {
      self.selectedSectionId = null;
    },
    clearSections() {
      self.sections = [];
    },
    setError(msg) {
      self.throwError(msg);
    },
    checkDuplicates(name, propertyId) {
      return self.sections.some((item) => item.name === name && item.property_id === propertyId);
    }
  }));

export const SectionStore = types.compose(SectionStoreInternal, ResourceStore).named('SectionStore');
