import React, { useEffect, useRef, useCallback, useState } from 'react';
import axios from 'axios';
import { Box, Button, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { autorun } from 'mobx';
import { observer } from 'mobx-react-lite';
import { getDistance } from 'geolib';
import { ROUTE_REMOTE_GUARDIAN, ROUTE_TEACH_SUBSECTION } from './routes';
import { ControlImageCapture } from '../components/control/control-image-capture.component';
import { BatteryIndicator } from '../components/control/control-battery-indicator.component';
import { ToolIndicator } from '../components/control/control-tool-indicator.component';
import { MapSwitch } from '../components/control/switch-map-visualization';
import { ControlSlider } from '../components/control/control-slider.component';
import { ControlSwitch } from '../components/control/control-switch.component';
import { MotorStatus } from '../components/control/motor-status.component';
import { useStores } from '../store/root/root.store';
import { areClose } from '../utils/compare-util';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { ChaperoneRobotConnectionService } from '../services/chaperone/robot-connection.service';
import { RosChaperoneService } from '../services/chaperone/ros.service';
import { AutonomyVideo } from '../components/control/autonomy-video.component';
import { Joystick } from '../input';
import {
  NOT_STARTED,
  IN_PROGRESS,
  COMPLETED,
  SOLAR_LAWN_MOWING,
  NON_SOLAR_LAWN_MOWING,
  DECK_AND_FRAME_OFFSET_SLIDER_MARKS
} from '../utils/constants';
import { isDevMode } from '../utils/ui.utils';
import RepeatControls from '../components/control/repeat-controls.component';
import Map from '../components/maps/chaperone-map';
import GridMap from '../components/gridMaps/GridMap';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import ActionsDialog from '../components/dialogs/actions.dialog';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { useSwitchTab } from '../utils/switch-tab.hook';

/* eslint-disable no-unused-expressions */

const analogDeadZone = 0.1;
const maxSpeed = 2.429512; // maximum speed in metres/second
const maxRotationRate = 2.25; // maximum angular speed in radians/second
const maxSpeedLimit = 3; // max level of the speed limit control
let gamepadIndex = 0;
let previousButtons = Array(16).fill(0);
let previousAxes = Array(8).fill(0.0);

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1600,
    minHeight: 800
  },
  controlAreaLeftPanel: {
    paddingRight: theme.spacing(1)
  },
  controlAreaMainPanel: {
    position: 'relative',
    marginTop: '35px'
  },
  controlAreaRightPanel: {
    padding: theme.spacing(1)
  },
  controlAreaControlPanel: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: '35px'
  },
  joystick: {
    overflow: 'visible',
    position: 'relative'
  },
  PS4: {
    overflow: 'visible',
    position: 'relative'
  },
  mapTitle: {
    background: 'rgba(32, 32, 32, 1.0)',
    color: theme.palette.inverted.main,
    height: '3.5%',
    padding: theme.spacing(1)
  },
  imgPanelShort: {
    height: '25%'
  },
  imgPanelTall: {
    height: '50%'
  },
  progress: {
    color: theme.palette.inverted.main
  },
  robotControl: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: 3,
    borderRadius: theme.spacing(1)
  },
  robotControlImg: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  toggleMapSty: {
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  robotControlAlt: {
    width: '100%',
    lineHeight: '0px',
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1)
  },
  map: {
    position: 'absolute',
    right: '0',
    bottom: 0,
    zIndex: '3',
    border: '3px solid gray'
  }
}));

export const RemoteGuardianRepeatPage = observer(() => {
  const classes = useStyles();
  const { applicationStore, controlStore, countriesStore, autonomyRobotStore, subsectionStore } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const frontVideoStream = useRef(null);
  const [connecting, setConnecting] = useState(false);
  const [controlMode, setControlMode] = useState('manual');
  const [start, setStart] = useState(true);
  const [pause, setPause] = useState(false);
  const [skip, setSkip] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const [userRobot, setUserRobot] = useState(false);
  const [isSuspendedTeachingDialogOpen, setIsSuspendedTeachingDialogOpen] = useState(false);
  const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
  const keepSelectedRobot = useRef(false);
  const userName = localStorage.getItem('username');

  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [subsection, setSubsection] = useState('');
  const [nextSubsection, setNextSubsection] = useState('');
  const [googleMapData, setGoogleMapData] = useState([]);
  const [gridMapData, setGridMapData] = useState([]);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [saltRate, setSaltRate] = useState(0);
  const [lightsState, setLightsState] = useState(0);
  const [beeperState, setBeeperState] = useState(0);
  const [lookAheadDistanceError, setLookAheadDistanceError] = useState(false);
  const [mapType, setMapType] = useState('gridMap');
  const [teleopsDrivingEnabled, setTeleopsDrivingEnabled] = useState(false);
  const { push } = useHistory();
  const [referencePoint, setReferencePoint] = useState();
  const [positionError, setPositionError] = useState(false);
  const [openAutonomousDialog, setOpenAutonomousDialog] = useState(false);
  const [subsectionMeta, setSubsectionMeta] = useState({});
  const [frameOffset, setFrameOffset] = useState(0);
  const [deckOffset, setDeckOffset] = useState(0);
  const isGrassCutting = [SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING].includes(autonomyRobotStore.getSelectedRobot().use_case);
  const toolType = isGrassCutting ? 'Lawn-Mower Active' : 'Salter Active';
  const isToolActive = isGrassCutting ? controlStore.isLawnMowing : controlStore.isSalting;
  const isSimMode = autonomyRobotStore.getSelectedRobot().serial_number?.includes('sim');

  if (!isSimMode) useSwitchTab(ROUTE_REMOTE_GUARDIAN);
  const username = localStorage.getItem('username');

  const isMountedRef = useRef(null);

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const getMapData = (region, property, section, subsectionId) => {
    axios
      .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region,
        property,
        section,
        subsectionId
      })
      .then((result) => {
        setGridMapData(result.data.data);
        const resultingCords = result.data.data.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.long) }));
        setGoogleMapData(resultingCords);
      });
  };

  const fetchSubsectionMeta = async (subsectionId) => {
    const response = await axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`);
    setSubsectionMeta(response.data.results);
    return response.data.results;
  };

  const handleMapsData = async (newSubsection = '') => {
    if (newSubsection) {
      setSubsection(newSubsection);
      // setGotNewSubsection(true);
    }
    setResetRobot(true);
    controlStore.resetRobotPosition();

    const res = await axios.post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
      region: state.region || undefined,
      property: state.property || undefined,
      section: state.section || undefined,
      subsection: newSubsection || state.subsection
    });
    setReferencePoint({
      lat: res.data.data[0].lat,
      lng: res.data.data[0].long,
      angle: res.data.data[0].angle
    });
    setGridMapData(res.data.data);
    setCurrentPositionIndex(20);
    const cords = res.data.data.map((el) => ({ lat: Number(el.lat), lng: Number(el.long) }));
    setGoogleMapData(cords);
  };

  const changeDriveState = useCallback(
    (nextControlMode) => {
      if (nextSubsection !== '') {
        robotConnection?.current?.ros.setControlMode(nextControlMode);
      } else if (nextControlMode !== null && !(start && nextControlMode === 'autonomous')) {
        robotConnection?.current?.ros.setControlMode(nextControlMode);
      }
    },
    [start, nextSubsection]
  );

  const sendRepeatCmdToRobot = async (action, customCommand, currentSubsectionMeta = {}) => {
    setUserRobot(true);
    let subsectionId = subsection ?? autonomyRobotStore.getSelectedRobot()?.operating_subsection_id;
    if (!subsectionId && !state.subsection) {
      return;
    }
    const command = customCommand ?? 'REPEAT';
    let argsList = `${action + (subsectionMeta?.section?.property?.region?.id || state.region)}_${
      subsectionMeta?.section?.property?.id || state.property
    }_${subsectionMeta?.section?.id || state.section}_${subsectionId}.csv,${localStorage.getItem('username')}`;
    if (Object.keys(currentSubsectionMeta).length) {
      subsectionId = autonomyRobotStore.getSelectedRobot()?.operating_subsection_id;
      argsList = `${action + currentSubsectionMeta?.section?.property?.region?.id}_${currentSubsectionMeta?.section?.property?.id}_${
        currentSubsectionMeta?.section?.id
      }_${subsectionId}.csv,${localStorage.getItem('username')}`;
    }

    console.log({ argsList });
    robotConnection?.current?.ros?.cmdRobotService(command, [argsList], (result) => {
      setLoading(false);
      if (result.error_message === '') return;
      console.log(`There was the following error with the repeat action: ${result.error_message}`);
      applicationStore.pushError(
        'Error',
        'The robot encountered an error with this repeat action, please report this to the autonomy team if the issue persists'
      );
    });
  };

  // let params_str = `${LINEAR_SPEED.default},${LOOK_AHEAD.default},${ANGULAR_SPEED.default},${GAMMA.default},${CONTRAST.default}`;
  const startNextSubsection = async () => {
    await handleMapsData(nextSubsection);
    changeDriveState('autonomous');
    subsectionStore.setRepeatState(
      state.region,
      state.property,
      state.section,
      nextSubsection,
      IN_PROGRESS,
      autonomyRobotStore.getSelectedRobot().serial_number
    );
    sendRepeatCmdToRobot('START,swapautonomy');
  };

  const openNextSubsectionDialog = async () => {
    setOpenDialog(false);
    startNextSubsection();
  };

  const closeDialogue = () => {
    setOpenDialog(false);
    push(ROUTE_REMOTE_GUARDIAN);
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  useEffect(() => {
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedRepeatingDialogOpen(true);
    } else if (autonomyRobotStore.getSelectedRobot().status === 'WPS_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedTeachingDialogOpen(true);
    }
    keepSelectedRobot.current = true;
  }, [autonomyRobotStore]);

  useEffect(() => {
    setSaltRate(controlStore.salter_level);
  }, [controlStore.salter_level]);

  useEffect(() => {
    setLightsState(controlStore.lightsState);
  }, [controlStore.lightsState]);

  useEffect(() => {
    setBeeperState(controlStore.beeperState);
  }, [controlStore.beeperState]);

  const handleSaltRate = (value) => {
    if (robotConnection?.current?.ros) {
      robotConnection.current.ros.cmdSalterSpeed(value);
    }
  };

  const handlesetTeleopsDrivingEnabled = (value) => {
    setTeleopsDrivingEnabled(value);
  };

  const fetchCurrentSubsectionMeta = async (subsectionId) => {
    const response = await axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`);
    return response.data.results;
  };

  const skipCurrentSubsection = () => {
    if (!start) {
      setSkip(true);
      if (pause) {
        setPause(false);
      } else {
        setPause(true);
      }
      setStart(true);
      subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
      sendRepeatCmdToRobot('SKIP,swapautonomy');
      controlStore.resetRobotPosition();
      setResetRobot(true);
      changeDriveState('manual');
    }
  };

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };
  useEffect(() => {
    if (controlStore.previousControlMode === 'teleops') {
      setTeleopsDrivingEnabled(false);
    }
  }, [controlStore.previousControlMode]);

  useEffect(
    () =>
      autorun(() => {
        console.log('[controlMode]', controlStore.getControlMode());
        if (controlStore.controlMode !== controlStore.previousControlMode) {
          setTeleopsDrivingEnabled(false);
          if (!start) {
            if (!skip && controlStore.controlMode === 'autonomous' && controlStore.previousControlMode !== 'autonomous' && !pause) {
              setPause(true);
              sendRepeatCmdToRobot('RESUME,swapautonomy');
            } else if (
              !skip &&
              controlStore.controlMode !== 'autonomous' &&
              controlStore.previousControlMode !== controlStore.controlMode &&
              pause
            ) {
              setPause(false);
              sendRepeatCmdToRobot('PAUSE,swapautonomy');
            }
          }
          setControlMode(controlStore.controlMode);
        }
        // else {
        //   console.log('[setControlMode:manual)]');
        //   setControlMode('manual');
        // }
      }),
    [start, pause, skip]
  );

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  useEffect(() => {
    if (state.region && state.property && state.section && state.subsection) {
      subsectionStore.getSubsections(countriesStore.selectedCountryId, state.region, state.property, state.section);
      setSubsection(state.subsection);
    }
  }, []);

  const handleReleaseRobot = async () => {
    const cmd = 'CANCEL,swapautonomy';
    const currentSubsectionMeta = await fetchCurrentSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      sendRepeatCmdToRobot(cmd, null, currentSubsectionMeta);
      setIsSuspendedRepeatingDialogOpen(false);
    } else {
      sendRepeatCmdToRobot(cmd, 'TEACH', currentSubsectionMeta);
      setIsSuspendedTeachingDialogOpen(false);
    }
  };

  const handleSuspendedTeachingDialogGoBack = () => {
    setIsSuspendedTeachingDialogOpen(false);
    const robotState = autonomyRobotStore.getSelectedRobot()?.status;
    push({ pathname: ROUTE_TEACH_SUBSECTION, loc: { ...state, state: robotState } });
  };

  const handleSuspendedRepeatingDialogGoBack = async () => {
    const subsectionInfo = await fetchSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    setSubsection(subsectionInfo?.id);
    getMapData(
      subsectionInfo?.section?.property?.region?.id,
      subsectionInfo?.section?.property?.id,
      subsectionInfo?.section?.id,
      subsectionInfo?.id
    );
    setIsSuspendedRepeatingDialogOpen(false);
    // Call the last wps_index service
    robotConnection?.current?.ros?.getLastWpsIndexService((wpsInexStr) => {
      console.log({ wpsInexStr });
      setCurrentPositionIndex(parseInt(wpsInexStr));
    });
  };

  const startLoadingMsg = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    showLoadingDialog('We are provisioning the robot now, it will begin the path in a few seconds.');
  };

  const isValidPosition = () => {
    if (isDevMode) {
      return true;
    }
    if (!(referencePoint?.lat && referencePoint?.lng && referencePoint?.angle)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage('The location data is not valid.');
      return false;
    }
    const distance = getDistance(
      { latitude: Number(referencePoint?.lat), longitude: Number(referencePoint?.lng) },
      { latitude: controlStore.lat, longitude: controlStore.lng },
      0.01
    );
    if (Math.fround(distance) > Math.fround(1)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subsection, the robot is not close to the starting point.");
      return false;
    }
    if (Math.abs(controlStore.current_heading_rad - Number(referencePoint?.angle)) > 0.35) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subsection, the robot's heading is not close to the initial recorded orientation.");
      return false;
    }
    return true;
  };

  const changeRepeatingState = (newSubsection = '') => {
    if (newSubsection) setSubsection(newSubsection);
    if (start) {
      if (!referencePoint) {
        applicationStore.pushError('Error', 'No subsection is selected');
        return;
      }

      if (isDevMode) {
        console.log('Developing mode...');
      } else if (controlStore.lat && controlStore.lng) {
        if (!controlStore.gpsFixStatus.includes('RTK')) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
          return;
        }
        if (!controlStore.stableLocalization) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
          return;
        }
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        setConnectionError(true);
        setErrorMessage('RTK Error, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }

      if (!isValidPosition()) {
        return;
      }
      controlStore.resetRobotPosition();
      setResetRobot(true);
      if (controlMode !== 'autonomous') {
        robotConnection?.current?.ros.setControlMode('autonomous');
      }
      subsectionStore.setRepeatState(
        state.region,
        state.property,
        state.section,
        subsection,
        IN_PROGRESS,
        autonomyRobotStore.getSelectedRobot().serial_number
      );
      axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/robots/${autonomyRobotStore.selectedRobotId}/status`)
        .then((res) => {
          if (res.data.results.status === 'AVAILABLE' || userRobot) {
            console.log('(((((((( [wpsState]', controlStore.wpsState);
            sendRepeatCmdToRobot('START,swapautonomy');
            startLoadingMsg();
          } else {
            setConnectionError(true);
            setErrorMessage('Robot is already in-use, check it again later or select another robot!');
          }
        })
        .catch((err) => {
          console.log(err);
          setConnectionError(true);
          setErrorMessage('Robot is already in-use, check it again later or select another robot!');
        });
    } else if (!pause) {
      if (!controlStore.gpsFixStatus.includes('RTK')) {
        changeDriveState('teleops');
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
        return;
      }
      if (!controlStore.stableLocalization) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
        return;
      }
      console.log('GPS data is good to allow the robot to goooo!!');
      changeDriveState('autonomous');
    } else if (pause) {
      changeDriveState('teleops');
    }
  };

  const confirmWarningDialog = () => {
    changeDriveState('autonomous');
    setOpenAutonomousDialog(false);
  };
  const closeAutonomousDialogue = () => setOpenAutonomousDialog(false);

  useEffect(() => {
    function handleSpace(e) {
      if (!start && e.key === ' ') {
        e.preventDefault();
        e.stopPropagation();
        if (controlMode !== 'teleops') {
          changeDriveState('teleops');
        } else if (controlMode !== 'autonomous') {
          setOpenAutonomousDialog(true);
        }
      }
    }
    document.addEventListener('keyup', handleSpace);
    return () => {
      document.removeEventListener('keyup', handleSpace);
    };
  }, [controlMode, pause]);

  const cancelRepeatingTask = async (done = false) => {
    if (!start) {
      setStart(true);
      if (pause) {
        setPause(false);
      } else {
        setPause(true);
      }
      if (done && controlMode === 'autonomous') {
        changeDriveState('manual');
      }
      if (!done) {
        subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
        await sendRepeatCmdToRobot('CANCEL,swapautonomy');
      } else {
        await sendRepeatCmdToRobot('FINISH,swapautonomy');
      }
    }
    if (!done) {
      push(ROUTE_REMOTE_GUARDIAN);
    } else {
      controlStore.resetDoneRepeating();
    }
  };

  const getDistanceBetweenSubscetions = async () => {
    let cords1 = [];
    let cords2 = [];
    let nextSubsectionId = '';
    try {
      await axios
        .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
          region: state.region,
          property: state.property,
          section: state.section,
          subsection
        })
        .then((result) => {
          cords1 = result.data.data.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.long) }));
        });
    } catch (e) {
      console.log(e);
    }
    // cords1.push({ lat: controlStore.lat, lng: controlStore.lng });
    try {
      await axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/next`, {
          params: {
            section_id: state.section,
            order_index: subsectionStore.getById(subsection)?.order_index,
            operation_robot: subsectionStore.getById(subsection)?.operation_robot
          }
        })
        .then((result) => {
          console.log(result.data.results);
          nextSubsectionId = result.data.results.id;
        })
        .catch((e) => {
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
    if (nextSubsectionId !== '') {
      try {
        await axios
          .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
            region: state.region,
            property: state.property,
            section: state.section,
            subsection: nextSubsectionId || ''
          })
          .then((result) => {
            cords2 = result.data.data.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.long) }));
          });
      } catch (e) {
        console.log(e);
      }
      let distance = 0;
      if (cords1.length > 0 && cords2.length > 0) {
        distance = getDistance(
          { latitude: cords1[cords1.length - 1].lat, longitude: cords1[cords1.length - 1].lng },
          { latitude: cords2[0].lat, longitude: cords2[0].lng },
          0.01
        );
      }
      return { nextSubsectionId, distance };
    }
    return { nextSubsectionId: undefined, distance: -1 };
  };

  useEffect(
    () =>
      autorun(async () => {
        if (robotConnectionService.current !== null) {
          // check for idle robot state data
          if ((Date.now() - controlStore.lastRobotStateReceived) / 1000 > 10 && controlStore.getControlMode() === 'autonomous') {
            // in seconds
            setPositionError(true);
            setConnectionError(true);
            if (pause) {
              setErrorMessage(
                "RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !"
              );
              // setPause(!pause);
              changeDriveState('teleops');
              sendRepeatCmdToRobot('PAUSE,swapautonomy');
            } else {
              setErrorMessage('RTK Error: No sensory data has been recieved, the robot does not have an RTK GPS data !');
            }
          }
          // Check for change in other Waypoints state
          if (Number(controlStore.wpsState) === 4) {
            setStart(false);
            setPause(true);
          } else if (!start && controlStore.isRepeatingDone) {
            subsectionStore.setRepeatState(
              state.region,
              state.property,
              state.section,
              subsection,
              COMPLETED,
              autonomyRobotStore.getSelectedRobot().serial_number
            );
            // Repeating is Done
            console.log('WAYPOINTS REPEATING IS DONE');
            cancelRepeatingTask(true);
            const { nextSubsectionId, distance } = await getDistanceBetweenSubscetions();
            if (Math.fround(distance) <= Math.fround(0.8) && Math.fround(distance) > Math.fround(-0.0001)) {
              console.log('SHOULD START NEXT SUBSECTION');
              setNextSubsection(nextSubsectionId);
              setSubsection(nextSubsectionId);
              // setGotNewSubsection(true);
              setOpenDialog(true);
            }
            robotConnection?.current?.ros.setControlMode('manual');
          }
        }
      }),
    [robotConnectionService, start, subsection]
  );

  useEffect(() => {
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (autonomyRobotStore.selectedRobotId) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            console.debug('Connecting to robot ', autonomyRobotStore.selectedRobotId);
            setConnecting(true);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) =>
                  controlStore.updateRobotState(robotState)
                );
                robotConnectionService?.current?.ros.isRightOffsetDeck((isRightOffset) => {
                  if (isRightOffset !== null) {
                    if (isRightOffset) {
                      setDeckOffset(parseInt(controlStore.deckOffset));
                      setFrameOffset(parseInt(controlStore.frame_offset));
                    } else {
                      setDeckOffset(parseInt(controlStore.deckOffset * -1));
                      setFrameOffset(parseInt(controlStore.frame_offset * -1));
                    }
                  }
                });
                robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission((message) => controlStore.setDoneRepeating(message.data));
                // To trigger the janus ML if it wasn't active
                robotConnection.current = robotConnectionService.current;
                robotConnection?.current?.ros.setControlMode('manual');
                controlStore.setSpeedLimit(0);
                setConnecting(false);
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                if (robotConnectionService.current !== null) {
                  if (!start) {
                    subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
                    // sendRepeatCmdToRobot(`CANCEL,swapautonomy`);
                  }
                  robotConnection?.current?.ros.setControlMode('manual');
                  robotConnectionService?.current?.retryConnection();
                }
                handleConnectionError();
                robotConnection.current = null;
              },
              autonomyRobotStore.getSelectedRobot().serial_number,
              userName,
              'remote_guardian_repeat'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      } else {
        applicationStore.pushError('Error', 'No robot is selected');
        push(ROUTE_REMOTE_GUARDIAN);
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        if (!start) {
          subsectionStore.setRepeatState(state.region, state.property, state.section, subsection, NOT_STARTED);
        }
        robotConnection?.current?.ros.setControlMode('manual');
        // sendRepeatCmdToRobot(`CANCEL,swapautonomy`);
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
        const selectedRobotIdAtCleanup = autonomyRobotStore.selectedRobotId;
      }
      if (!keepSelectedRobot.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const asyncHandleMapsData = async () => {
      await handleMapsData();
    };
    asyncHandleMapsData();
    return () => {
      setResetRobot(true);
      controlStore.resetRobotPosition();
    };
  }, []);

  useEffect(() => {
    if (!controlStore.gpsFixStatus.includes('RTK') && controlStore.getControlMode() === 'autonomous') {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !");
      if (pause) {
        setPause(!pause);
        changeDriveState('teleops');
        sendRepeatCmdToRobot('PAUSE,swapautonomy');
      }
    }
  }, [controlStore.gpsFixStatus]);

  /**
   * Handles regular calls with the current joystick position by translating position to movement
   * commands (if it indicates the robot should not be moving).
   * @param linear linear movement in the range [-1,1], converted to metres/second
   * @param angular angular movement in the range [-1,1], converted to radians/second
   */
  const handleJoystickPosition = (angular, linear) => {
    const robot = robotConnection?.current?.ros;
    if (!robot) {
      return;
    }

    // Generate a cmdVel message if joystick handle moved. Joystick linear values are in the interval [-1, 1]
    // with negative values for moving forward. Reverse the sign when calculating the cmdVel linear property value.
    // Angular values are also in the [-1,0] interval with negative values for turning left. Leave the angular
    // value sign as-is. Treat values in the range (-0.1, 0.1) as 0 as the joystick does not generate 0-values
    // often when at rest on some OS's.
    if (controlStore.speedLimit > 0) {
      const cmdVel = {
        angular: ((areClose(angular, 0) ? 0 : angular) * -1 * maxRotationRate * controlStore.speedLimit) / maxSpeedLimit,
        linear: ((areClose(linear, 0) ? 0 : linear) * -1 * maxSpeed * controlStore.speedLimit) / maxSpeedLimit
      };
      if (teleopsDrivingEnabled) robot.publishVelocity(cmdVel);
    }
  };

  /**
   * Handles regular calls with the current joystick twist  by translating position to movement
   * commands (if it indicates the robot should not be moving).
   * @param twist twist speed for auto-turn in the range [-1,1], converted to metres/second
   */
  const handleJoystickTwist = (twist) => {
    const robot = robotConnection?.current?.ros;
    if (!robot) {
      return;
    }

    // Generate a cmdVel message if joystick handle moved. Joystick linear values are in the interval [-1, 1]
    // with negative values for moving forward. Reverse the sign when calculating the cmdVel linear property value.
    // Angular values are also in the [-1,0] interval with negative values for turning left. Leave the angular
    // value sign as-is. Treat values in the range (-0.1, 0.1) as 0 as the joystick does not generate 0-values
    // often when at rest on some OS's.
    if (controlMode === 'teleops' && controlStore.speedLimit > 0) {
      const autoTurnCmd = areClose(twist, 0) ? 0 : -1 * twist;
      if (teleopsDrivingEnabled) robot.publishTeleopsTurn(autoTurnCmd);
    }
  };

  // By JohnBurtt
  // Handles input from Logitech Extreme 3D and PS4 controllers
  const handleJoystickState = (state) => {
    const ros = robotConnection?.current?.ros;
    if (!ros) {
      return;
    }
    let interval;
    function pollGamepads() {
      let gamepads;

      if (navigator.getGamepads) {
        gamepads = navigator.getGamepads();
      } else if (navigator.webkitGetGamepads) {
        gamepads = navigator.webkitGetGamepads();
      } else {
        gamepads = [];
      }
      gamepads.forEach((gamepad) => {
        const gp = gamepad;
        // If there are any gamepads connected
        if (gp && controlMode === 'teleops') {
          clearInterval(interval);
          // PS4
          if (gp.id.includes('Wireless Controller')) {
            gamepadIndex = gp.index;
            const buttons = Array(16).fill(0);
            const axes = Array(8).fill(0.0);
            // PERIPHERALS
            buttons[0] = gp.buttons[2].value;
            buttons[1] = gp.buttons[0].value;
            buttons[2] = gp.buttons[1].value;
            buttons[3] = gp.buttons[3].value;
            buttons[4] = gp.buttons[4].value;
            buttons[5] = gp.buttons[5].value;
            buttons[8] = gp.buttons[8].value;
            buttons[9] = gp.buttons[9].value;
            buttons[10] = gp.buttons[10].value;
            buttons[11] = gp.buttons[11].value;
            buttons[12] = gp.buttons[16].value;
            // DRIVING
            if (teleopsDrivingEnabled) {
              if (gp.buttons[6].value > analogDeadZone) buttons[6] = 1;
              if (gp.buttons[7].value > analogDeadZone) buttons[7] = 1;
              axes[0] = Math.abs(gp.axes[0]) > analogDeadZone ? gp.axes[0] * -1.0 : axes[0];
              axes[1] = Math.abs(gp.axes[1]) > analogDeadZone ? gp.axes[1] * -1.0 : axes[1];
              axes[2] = Math.abs(gp.axes[2]) > analogDeadZone ? gp.axes[2] * -1.0 : axes[2];
              axes[5] = Math.abs(gp.axes[3]) > analogDeadZone ? gp.axes[3] * -1.0 : axes[5];
              if (gp.buttons[15].value) axes[6] = -1.0;
              if (gp.buttons[14].value) axes[6] = 1.0;
              if (gp.buttons[12].value) axes[7] = 1.0;
              if (gp.buttons[13].value) axes[7] = -1.0;
            }
            robotConnection?.current?.ros.publishJoy(buttons, axes, controlStore.robotStateStamp);
            previousButtons = buttons;
            previousAxes = axes;
          } else if (gp.id.includes('Logitech') && !gp.id.includes('Wireless')) {
            gamepadIndex = gp.index;
            /**
             * Handles regular calls with the current joystick. Joystick position is translated to movement commands
             * (including stop).  Thumbstick and related buttons are used to control the plow.
             * Trigger is used to control salter on/off.
             *
             * Handles changes from the Joystick component and issue command to the robot
             * @param state current joystick state
             */

            // Handle the hatstick on top of the joystick handle (the thumb stick). It control moving the plow up and down.
            // Joystick values are -1 when pushed forword (move plow down) and 1 when pulled backward (move plow up).
            if ('thumbLinear' in state) {
              switch (state.thumbLinear) {
                case -1:
                  ros.cmdPlow(RosChaperoneService.PLOW_DOWN);
                  break;
                case 1:
                  ros.cmdPlow(RosChaperoneService.PLOW_UP);
                  break;
                default:
              }
            }

            // Handle the other buttons for plow commands. For each, 1 means pressed and 0 is released.
            if (state.button5 === 1) {
              ros.cmdPlow(RosChaperoneService.PLOW_LEFT_FORWARD);
            }
            if (state.button3 === 1) {
              ros.cmdPlow(RosChaperoneService.PLOW_LEFT_BACKWARD);
            }
            if (state.button6 === 1) {
              ros.cmdPlow(RosChaperoneService.PLOW_RIGHT_FORWARD);
            }
            if (state.button4 === 1) {
              ros.cmdPlow(RosChaperoneService.PLOW_RIGHT_BACKWARD);
            }

            // The trigger controls the salter on/off (rate is set with a separate control)
            if ('trigger' in state) {
              if (state.trigger === 1) {
                ros.cmdSalterOnOff(true);
              }
              const isSalting = state.trigger === 1 && controlStore.salt_level > 0;
              if (controlStore.isSalting !== isSalting) {
                controlStore.setSalting(isSalting);
              }
            }
            console.log(state);
            if (state.mainAngular < 0.01 && state.mainLinear < 0.01) {
              handleJoystickTwist(state.mainTwist);
            }
            handleJoystickPosition(state.mainAngular, state.mainLinear);
          }
        }
      });
    }

    if (!('ongamepadconnected' in window)) {
      // No gamepad events available, poll instead.
      interval = setInterval(pollGamepads, 50);
    }
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
    if (positionError) {
      setPositionError(false);
      return;
    }
    push(ROUTE_REMOTE_GUARDIAN);
  };

  const handleCloseLookAheadErrorDialog = () => {
    setLookAheadDistanceError(false);
  };

  const handleRestartController = () => {
    showLoadingDialog('Restarting the robot now, the robot will be ready in less than 1.5 minutes.', 70);
    changeDriveState('manual');
    robotConnection?.current?.ros?.restartRobotService('robot');
    setLoading(false);
  };

  const handleRestartVideo = () => {
    showLoadingDialog("Restarting the robot's video streaming system now, the video will be ready in less than 10 seconds.", 10);
    changeDriveState('teleops');
    robotConnection?.current?.ros?.restartRobotService('video');
    setLoading(false);
  };

  const updateNavParams = (params) => {
    robotConnection?.current?.ros.updateNavParams(params);
  };

  return (
    // During connection, show loader
    connecting ? (
      <>
        <LinearProgress className={classes.progress} />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ marginTop: '16px' }}
          onClick={() => {
            setConnecting(false);
          }}
        >
          Cancel Connection remote guardian
        </Button>
      </>
    ) : (
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.root}>
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <RepeatControls
          changeRepeatingState={changeRepeatingState}
          changeDriveState={changeDriveState}
          cancelRepeatingTask={cancelRepeatingTask}
          start={start}
          pause={pause}
          controlMode={controlMode}
          robot={state.robot}
          subsection={subsection}
          nextSubsection={nextSubsection}
          handleMapsData={handleMapsData}
          skipCurrentSubsection={skipCurrentSubsection}
          handleRestartController={handleRestartController}
          handleRestartVideo={handleRestartVideo}
          updateNavParams={updateNavParams}
          subsectionMeta={subsectionMeta}
          fetchSubsectionMeta={fetchSubsectionMeta}
        />
        {/* Main Control area: Main video feed, Joystick controls */}
        <Grid item container xs={11} justifyContent="flex-end" alignItems="flex-start" className={classes.controlAreaMainPanel}>
          {!isDevMode && (
            <AutonomyVideo stream={frontVideoStream.current} serialNumber={autonomyRobotStore.getSelectedRobot().serial_number} />
          )}
          {!isDevMode && (
            <EStopIndicator eStopEngaged={controlStore.estopState || controlStore.swEstopState} videoStream width={650} height={650} />
          )}
          {mapType === 'googleMap' ? (
            <Map
              customStyle={classes.map}
              width="25%"
              height="423px"
              robotLat={controlStore.lat}
              resetRobot={resetRobot}
              currentPositionIndex={currentPositionIndex}
              googleMapData={googleMapData}
              robotLng={controlStore.lng}
            />
          ) : (
            <GridMap
              customStyle
              currentPositionIndex={currentPositionIndex}
              gridMapData={gridMapData}
              robotEnabled
              resetRobot={resetRobot}
              robotLng={controlStore.lng}
              robotLat={controlStore.lat}
              robotHeadingRad={controlStore.current_heading_rad}
            />
          )}
        </Grid>
        {/* Far Right Panel: Controls, Motor status */}
        <Grid
          item
          container
          xs={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.controlAreaControlPanel}
        >
          <Grid container direction="column" justifyContent="flex-start" className={classes.controlAreaRightPanel}>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                value="lights"
                label="Lamps"
                defaultValue={lightsState}
                checked={lightsState}
                onClick={() => robotConnection?.current?.ros?.cmdLights(!lightsState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                value="beeper"
                label="Beeper"
                defaultValue={beeperState}
                checked={beeperState}
                onClick={() => robotConnection?.current?.ros?.cmdBeeper(!beeperState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                value="swestop"
                label="Swestop"
                defaultValue={controlStore.swEstopState}
                checked={controlStore.swEstopState}
                onClick={() => {
                  robotConnection?.current?.ros?.cmdSwEstop(!controlStore.swEstopState);
                }}
              />
            </Grid>
            {!isGrassCutting && (
              <Grid item className={classes.robotControl}>
                <ControlSlider
                  label="Salter Rate"
                  defaultValue={saltRate}
                  value={saltRate}
                  valueLabelDisplay="off"
                  marks
                  min={0}
                  max={4}
                  step={1}
                  onChange={(value) => handleSaltRate(value)}
                  robotControlMode={controlStore.controlMode}
                />
              </Grid>
            )}
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Wiper Speed"
                defaultValue={0}
                valueLabelDisplay="off"
                marks
                min={0}
                max={2}
                step={1}
                onChange={(value) => robotConnection?.current?.ros?.cmdWiper(value)}
                robotControlMode={controlStore.controlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Deck Offset"
                defaultValue={deckOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.deckOffset}
                min={-76}
                max={76}
                step={1}
                key="deckOffset"
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Carriage Offset"
                defaultValue={frameOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.frameOffset}
                min={-65}
                max={65}
                step={1}
                key="frameOffset"
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ToolIndicator toolType={toolType} isActive={isToolActive} />
            </Grid>
            <Grid item className={classes.toggleMapSty}>
              <MapSwitch mapType={mapType} handleToggleMap={setMapType} />
            </Grid>

            <Grid item align="center" className={classes.robotControlImg}>
              <ControlImageCapture service={robotConnection.current} />
            </Grid>
            <Grid item className={classes.robotControlAlt}>
              <MotorStatus />
            </Grid>
            <Grid item className={classes.robotControl}>
              <BatteryIndicator />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box className={classes.joystick}>
                <Joystick
                  gamepadIndex={gamepadIndex}
                  cwidth={175}
                  cheight={250}
                  visualized={true}
                  handleJoystickState={handleJoystickState}
                />
              </Box>
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                defaultValue={teleopsDrivingEnabled}
                label="Enable Teleops Driving"
                checked={teleopsDrivingEnabled}
                onClick={() => handlesetTeleopsDrivingEnabled(!teleopsDrivingEnabled)}
              />
            </Grid>
          </Grid>
        </Grid>
        <ActionsDialog
          dialogTitle="Next subsection is less than 50cm from this subsection, do you want to start it?"
          open={openDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeDialogue },
            { color: 'secondary', name: 'Start next subsection', variant: 'contained', handler: openNextSubsectionDialog }
          ]}
        />
        <ActionsDialog
          dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
          open={openAutonomousDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeAutonomousDialogue },
            { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmWarningDialog }
          ]}
        />
        <ActionsDialog
          dialogTitle="The robot is currently suspended and there is a pending teaching operation, do you want to release the robot and cancel the current teaching operation?"
          open={isSuspendedTeachingDialogOpen}
          actions={[
            { color: 'primary', name: 'Cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
            { color: 'secondary', name: 'Continue teaching', variant: 'contained', handler: handleSuspendedTeachingDialogGoBack }
          ]}
        />
        <ActionsDialog
          dialogTitle="The robot is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current repeating operation?"
          open={isSuspendedRepeatingDialogOpen}
          actions={[
            { color: 'primary', name: 'Cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
            { color: 'secondary', name: 'Continue repeating', variant: 'contained', handler: handleSuspendedRepeatingDialogGoBack }
          ]}
        />
        <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
        <ActionsDialog
          open={lookAheadDistanceError}
          dialogTitle="ERROR: The lookahead distance must be greater than both turning thresholds"
          actions={[{ color: 'secondary', name: 'Ok', variant: 'contained', handler: handleCloseLookAheadErrorDialog }]}
        />
      </Grid>
    )
  );
});
