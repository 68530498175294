/**
 * Draws a polygon given a list of points
 * @param {*} ctx
 * @param {*} points
 * @param {*} fillStyle
 */
export const drawPolygon = (ctx, points, center, fillStyle, borderWidth = 0, text = null) => {
  const [startX, startY] = points.shift();

  ctx.beginPath();

  ctx.fillStyle = fillStyle;
  ctx.moveTo(startX, startY);
  for (const [x, y] of points) {
    ctx.lineTo(x, y);
  }

  // Add border if provided
  if (borderWidth > 0) {
    ctx.strokeStyle = '#333';
    ctx.lineWidth = borderWidth;
    ctx.stroke();
  }

  ctx.fill();

  // Add text if provided
  if (text) {
    ctx.font = '24px "Roboto", sans-serif ';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.fillStyle = '#000';
    ctx.fillText(text, center[0], center[1]);
  }
  ctx.closePath();
};

/**
 * Given a Canvas 2D context, draw a circle
 * @param {*} ctx
 * @param {*} xPos
 * @param {*} yPos
 * @param {*} radius
 * @param {*} fillStyle
 * @param {*} borderWidth
 */
export const drawCircle = (ctx, xPos, yPos, radius, fillStyle, borderWidth) => {
  ctx.beginPath();
  ctx.fillStyle = fillStyle;
  ctx.arc(xPos, yPos, radius, 0, Math.PI * 2, true);
  ctx.strokeStyle = '#666';
  ctx.lineWidth = borderWidth;
  ctx.stroke();
  ctx.fill();
  ctx.closePath();
};

/**
 * Draws an equilateral triangle given a direction and starting point
 * @param {} ctx
 * @param {*} x
 * @param {*} y
 * @param {*} direction
 * @param {*} color
 */
export const drawTriangle = (ctx, x, y, direction = 0, color = '#000000') => {
  const len = 20;
  const height = len * Math.cos(Math.PI / 6);

  if (direction === 0) {
    x = x - len / 2;
    y = y - len;
  }

  if (direction === 1) {
    x = x + len * 2;
  }

  if (direction === 2) {
    x = x + len / 2;
    y = y + len;
  }

  if (direction === 3) {
    x = x - len * 2;
  }

  const directions = [
    [
      [x, y],
      [x + len, y],
      [x + len / 2, y - height],
    ],
    [
      [x, y],
      [x - height, y - len / 2],
      [x - height, y + len / 2],
    ],
    [
      [x, y],
      [x - len, y],
      [x - len / 2, y + height],
    ],
    [
      [x, y],
      [x + height, y - len / 2],
      [x + height, y + len / 2],
    ],
  ];
  drawPolygon(ctx, directions[direction], [0, 0], color);
};

/**
 * Draws a Rectangle
 * @param {*} ctx
 * @param {*} text
 * @param {*} x
 * @param {*} y
 * @param {*} l
 * @param {*} w
 * @param {*} fillStyle
 */
export const drawRectangle = (ctx, text, x, y, l, w, fillStyle) => {
  const points = [
    [x, y],
    [x + w, y],
    [x + w, y + l],
    [x, y + l],
    [x, y],
  ];
  const center = [x + w / 2, y + l / 2];
  drawPolygon(ctx, points, center, fillStyle, 2, text);
};

/**
 * Draws a line
 * @param {Object} Parameters:
 * @param {*} ctx
 * @param {*} points
 * @param {*} color
 * @param {*} lineWidth
 * @param {*} segments
 * @param {*} offset
 */
export const drawLine = ({ ctx, points, color = '#000000', lineWidth = 2, segments = [], offset = 0 } = {}) => {
  const [startX, startY] = points.shift();

  ctx.beginPath();
  if (Array.isArray(segments) && segments.length > 0) ctx.setLineDash(segments);
  ctx.lineWidth = lineWidth;
  ctx.lineDashOffset = offset;
  ctx.strokeStyle = color;
  ctx.moveTo(startX, startY);
  for (const [x, y] of points) {
    ctx.lineTo(x, y);
  }
  ctx.stroke();
};

/**
 * Checks whether Circle [x, y, innerRadius] is completely contained within Circle [center, outerRadius]
 * @param {*} x
 * @param {*} y
 * @param {*} center
 * @param {*} outerRadius
 * @param {*} innerRadius
 */
export const isWithinBounds = (x, y, center, outerRadius, innerRadius) =>
  Math.pow(x - center.x, 2) + Math.pow(y - center.y, 2) < Math.pow(outerRadius - innerRadius, 2);
