import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

export default function SaveRouteConfirmation({ open, handleContinue, handleExit, confirmationMessage }) {
  return (
    <Dialog open={open} aria-describedby="connection-error-description">
      <DialogContent>
        <DialogContentText id="connection-error-description">{confirmationMessage}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" size="large" onClick={handleContinue} color="secondary">
          Continue
        </Button>
        <Button variant="contained" size="large" onClick={handleExit} color="secondary">
          Exit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
