import React from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade } from '@material-ui/core';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));

export default function ControllerDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();

  function decodeLabel(string) {
    if (string.indexOf(':') <= -1) return string;
    const macAddressPattern = /MAC ADDRESS/;
    const RSSIPattern = /RSSI/;
    const batteryPattern = /BATTERY/;
    if (macAddressPattern.test(string)) return 'MAC Address: ';
    if (RSSIPattern.test(string)) return 'RSSI: ';
    if (batteryPattern.test(string)) return 'Battery Percentage: ';
    return string;
  }

  function decodeData(string) {
    if (string.indexOf(':') <= -1) return '';
    return string.substr(string.indexOf(':') + 1);
  }

  return (
    <Fade in={true}>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Box m={1}>
            <Typography align="center" variant="subtitle1">
              Connected:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.connected !== null
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.connected
                    ? 'YES'
                    : 'NO'
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Valid Command:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.valid_command !== null
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.valid_command
                    ? 'YES'
                    : 'NO'
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Connection Strength:{' '}
              <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.connection_strength || DEFAULT_NO_DATA}</b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Controller Type:{' '}
              <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.controller_type || DEFAULT_NO_DATA}</b>
            </Typography>
            {autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.info.map((item, i) => (
              <Typography align="center" variant="subtitle1">
                {decodeLabel(item)}
                <b>{decodeData(item)}</b>
              </Typography>
            ))}
          </Box>
        </Grid>
      </Paper>
    </Fade>
  );
}
