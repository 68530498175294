import { Grid, makeStyles, Typography, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  cameraImages: {
    maxHeight: '50%',
    overflowY: 'auto'
  },
  cameraLabel: {
    textTransform: 'capitalize'
  }
}));

export const AuditVideoList = ({ videos = [] }) => {
  const classes = useStyles();
  const { auditReportsStore } = useStores();
  const [videoSources, setVideoSources] = useState([]);
  const isLoading = !videoSources.length;

  useEffect(() => {
    if (videos.length) {
      (async () => {
        const res = await Promise.all(videos.map(({ filename }) => auditReportsStore.getReportMedia(filename, true)));
        setVideoSources(res.map((r) => r.data));
      })();
    }
  }, [videos]);

  return (
    <Grid item container xs={12} className={classes.cameraImages} spacing={2}>
      {videoSources.map((videoSrc, i) => (
        <Grid key={videoSrc} item xs={12}>
          <Typography className={classes.cameraLabel} component="p">
            {videos[i].camera?.replace(/_/g, ' ')}
          </Typography>
          {isLoading ? (
            <CircularProgress color="secondary" />
          ) : (
            <video width="400" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support HTML video.
            </video>
          )}
        </Grid>
      ))}
    </Grid>
  );
};
