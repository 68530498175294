import { Grid, makeStyles, Paper, Typography, Button, Divider, createTheme, ThemeProvider, TextField, Fade } from '@material-ui/core';
import GoogleMapReact from 'google-map-react';
import { useObserver } from 'mobx-react-lite';
import React, { useRef, useState } from 'react';
import { useEnvironment } from '../environment';
import { useHistory } from 'react-router-dom';
import { ROUTE_ROUTE_SELECTION } from './routes';
import '../App.css';
import euler_to_quaternion from 'euler-to-quaternion';
import { uploadRouteFile } from '../services/api/routes.service';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import SaveRouteConfirmation from '../components/dialogs/save-route-confirmation.dialog';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';

const utmObj = require('utm-latlng');

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(2),
    height: '100%'
  },
  innerPanel: {
    height: '100%'
  },
  map: {
    // Have to define a height for the map
    height: '700px'
  },
  noPrint: {
    '@media print': {
      display: 'none'
    }
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  }
}));

const theme = createTheme({
  typography: {
    fontSize: 20
  }
});

let poly = null;
let pathDistance = 0;
let csvArray = [];

export const TeachRoutePage = () => {
  const classes = useStyles();
  const { googleMaps } = useEnvironment();
  const { push } = useHistory();
  const rootRef = useRef();
  const detailRef = useRef();
  const utm = new utmObj();
  const [connectionError, setConnectionError] = useState(false);
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [textFieldValue, setTextFieldValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const renderPolylines = (map, maps) => {
    poly = new google.maps.Polyline({
      strokeColor: '#EA2840',
      strokeOpacity: 1,
      strokeWeight: 3,
      editable: true,
      map: map
    });

    google.maps.event.addListener(map, 'click', (event) => {
      addLatLngToPoly(event.latLng);
      calculateDistance();
    });

    google.maps.event.addListener(poly, 'contextmenu', (e) => {
      if (e.vertex == undefined) {
        return;
      }

      poly.getPath().removeAt(e.vertex);
      calculateDistance();
    });

    google.maps.event.addListener(poly.getPath(), 'set_at', (e) => {
      calculateDistance();
    });

    google.maps.event.addListener(poly.getPath(), 'insert_at', (e) => {
      calculateDistance();
    });

    function addLatLngToPoly(latLng) {
      const path = poly.getPath();

      path.push(latLng);
    }

    function calculateDistance() {
      pathDistance = google.maps.geometry?.spherical.computeLength(poly.getPath());
      document.getElementById('pathDistance').innerHTML = pathDistance.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  };

  function clearClicked() {
    poly?.getPath().clear();
    pathDistance = 0;
    document.getElementById('pathDistance').innerHTML = pathDistance.toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  async function saveCSV() {
    const vertices = poly.getPath();
    const length = vertices.getLength();
    let csvData = new Array();
    let heading = 0;

    for (let i = 0; i < length; i++) {
      const coordinate = vertices.getAt(i).toString().slice(1, -1).split(', ');
      const lat = coordinate[0];
      const lng = coordinate[1];
      const utmValues = utm.convertLatLngToUtm(lat, lng, 8);
      if (i == 0) {
        const nextCoordinate = vertices
          .getAt(i + 1)
          .toString()
          .slice(1, -1)
          .split(', ');
        const nextLat = nextCoordinate[0];
        const nextLng = nextCoordinate[1];
        const nextUtmValues = utm.convertLatLngToUtm(nextLat, nextLng, 8);
        heading = Math.atan2(nextUtmValues.Northing - utmValues.Northing, nextUtmValues.Easting - utmValues.Easting);
      } else {
        const prvsCoordinate = vertices
          .getAt(i - 1)
          .toString()
          .slice(1, -1)
          .split(', ');
        const prvsLat = prvsCoordinate[0];
        const prvsLng = prvsCoordinate[1];
        const prvsUtmValues = utm.convertLatLngToUtm(prvsLat, prvsLng, 8);
        heading = Math.atan2(utmValues.Northing - prvsUtmValues.Northing, utmValues.Easting - prvsUtmValues.Easting);
      }

      const euler = [0, 0, heading];
      const quaternion = euler_to_quaternion(euler);

      csvData.push([
        i,
        0,
        utmValues.Easting,
        utmValues.Northing,
        0,
        quaternion[0],
        quaternion[1],
        quaternion[2],
        quaternion[3],
        heading,
        lng,
        lat,
        0,
        1,
        0,
        0
      ]);
    }

    csvArray = csvData;
  }

  async function handleLocalDownload() {
    await saveCSV();
    let csvContent = 'data:text/csv;charset=utf-8,' + csvArray.map((e) => e.join(',')).join('\n');

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  function handleCancel() {
    push(ROUTE_ROUTE_SELECTION);
  }

  const handleShowDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const handleShowConfirmation = (message) => {
    setConfirmationPopup(true);
    setConfirmationMessage(message);
  };

  const handleUploadRoute = async () => {
    await saveCSV();
    let csvContent = csvArray.map((e) => e.join(',')).join('\n');
    const file_name = textFieldValue;

    if (file_name === '') {
      setConnectionError(true);
      setErrorMessage('No route name has been entered, please enter a route name');
    } else {
      handleShowDialog('Uploading the route csv file in progress ...');
      await uploadRouteFile(csvContent, file_name);
      setLoading(false);
      handleShowConfirmation('The CSV has successfully been saved, would you like to continue?');
    }
  };

  return useObserver(() => {
    const center = { lat: 43.4627424614825, lng: -80.4755951623591 };

    if (rootRef.current) {
      // Timeout just forces the scroll to the iteration after the render, so the scroll places properly
      setTimeout(() => rootRef.current.scrollIntoView({ behavior: 'smooth' }));
    }

    return (
      <Fade in={true}>
        <div ref={rootRef}>
          <Paper color="inverted" ref={detailRef} className={classes.detailPanel} elevation={0}>
            <Grid
              item
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              className={classes.innerPanel}
            >
              <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
              <Grid item xs={12} lg={12} className={`${classes.map} ${classes.noPrint}`}>
                <div id="container">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyDUGf8FJu1Jm7oY4OrEQ4MnaHLwJOLNtHc', libraries: 'geometry' }}
                    center={center}
                    defaultZoom={18.56 || googleMaps.defaultZoom}
                    options={{
                      streetViewControl: true,
                      overviewMapControl: true,
                      disableDefaultUI: false,
                      zoomControl: true,
                      mapTypeId: 'hybrid',
                      draggable: true,
                      tilt: 0
                    }}
                    onGoogleApiLoaded={({ map, maps }) => renderPolylines(map, maps)}
                    yesIWantToUseGoogleMapApiInternals
                  ></GoogleMapReact>
                  <div id="sidebar">
                    <ThemeProvider theme={theme}>
                      <Typography style={{ fontWeight: 'bold' }}>Path Distance (m)</Typography>
                      <Divider />
                      <Typography id="pathDistance" style={{ fontSize: 25 }}>
                        0.00
                      </Typography>
                      <Divider />
                    </ThemeProvider>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={clearClicked}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Clear
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleUploadRoute}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleLocalDownload}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Download Locally
                    </Button>
                    <TextField
                      id="outlined-basic"
                      label="Route Name"
                      variant="outlined"
                      fullWidth
                      style={{ marginTop: '15px' }}
                      onChange={(e) => setTextFieldValue(e.target.value)}
                    />

                    <Button
                      variant="contained"
                      size="large"
                      type="submit"
                      onClick={handleCancel}
                      color="secondary"
                      fullWidth
                      style={{ marginTop: 15 }}
                    >
                      Exit
                    </Button>
                  </div>
                </div>
              </Grid>
              <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
              <SaveRouteConfirmation
                open={confirmationPopup}
                handleContinue={() => setConfirmationPopup(false)}
                handleExit={() => push(ROUTE_ROUTE_SELECTION)}
                confirmationMessage={confirmationMessage}
              />
            </Grid>
          </Paper>
        </div>
      </Fade>
    );
  });
};
