import { TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  row: ({ hover, selectable }) => ({
    cursor: selectable ? 'pointer' : 'auto',
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.grey[300],
      '&:hover': {
        backgroundColor: hover ? theme.palette.grey[400] : null
      }
    },
    '&:nth-of-type(even)': {
      '&:hover': {
        backgroundColor: hover ? theme.palette.grey[400] : null
      }
    }
  }),
  selected: {
    backgroundColor: theme.palette.secondary.main
  }
}));

export const StripedTableRow = ({
  isSelected = false,
  hover = false,
  selectable = false,
  onClick = () => {},
  children,
  backgroundColor
}) => {
  const classes = useStyles({ hover, selectable });
  return (
    <TableRow className={isSelected ? classes.selected : classes.row} onClick={onClick} style={{ background: backgroundColor }}>
      {children}
    </TableRow>
  );
};
