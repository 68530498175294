import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend } from 'chart.js';
import { makeStyles } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import { useStores } from '../../store/root/root.store';
import { TEMP_CHART_HEIGHT } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  chart: {
    maxHeight: TEMP_CHART_HEIGHT
  }
}));

export default function AuditTimeDeviationChart() {
  const classes = useStyles();
  const { autonomyAuditReportsStore } = useStores();

  let deviationObject = [];
  // Convert array into object of x, y coordinates
  for (let i = 0; i < autonomyAuditReportsStore?.selectedRecord?.deviationArray?.length; i++) {
    deviationObject.push({ x: i, y: autonomyAuditReportsStore?.selectedRecord?.deviationArray[i] });
  }

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend);

  const data = {
    datasets: [
      {
        label: 'Deviation (m)',
        data: deviationObject,
        borderColor: 'rgb(234, 40, 64)'
      }
    ]
  };

  const options = {
    responsive: true,
    normalized: true,
    spanGaps: true,
    scales: {
      x: {
        type: 'linear',
        title: {
          display: true,
          text: 'Index'
        }
      },
      y: {
        title: {
          display: true,
          text: 'Deviation (m)'
        }
      }
    },
    elements: {
      point: {
        radius: 0
      }
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };

  return <Line className={classes.chart} options={options} data={data} />;
}
