import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  FormHelperText,
  FormGroup,
  FormControlLabel,
  Checkbox,
  CircularProgress,
  Fade,
  Backdrop
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ROUTE_HOME } from './routes';
import { useStores } from '../store/root/root.store';
import axios from 'axios';
import { SOLAR_LAWN_MOWING, USE_CASES } from '../utils/constants';
import CustomSelectField from '../components/inputs/CustomSelectField';
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  noRegionNote: {
    color: '#ddd',
    fontWeight: 'bold',
    letterSpacing: '1px'
  },
  button: {
    margin: theme.spacing(1),
    height: 40,
    width: 150,
    backgroundColor: '##0000FF'
  },
  helperText: {
    color: 'red'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  circularProgress: {
    color: theme.palette.inverted.main
  },
  formControlLabel: {
    color: theme.palette.primary.contrastText
  }
}));

export const DeclareEventPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { applicationStore, countriesStore, regionsStore, sectionStore, chaperonePropertyStore } = useStores();

  const [useCase, setUseCase] = useState('');
  const [formError, setFormError] = useState([]);
  const [regions, setRegions] = useState([]);
  const [query, setQuery] = React.useState('idle');
  const [formReady, setFormReady] = useState(false);
  const [country, setCountry] = useState('');
  const [region, setRegion] = useState('');
  const [property, setProperty] = useState('');
  const [block, setBlock] = useState('');

  useEffect(() => {
    axios
      .get(`${applicationStore.getChaperoneBaseUrl()}/regions/`)
      .then((res) => {
        const data = res.data.results.map((el) => ({ regionName: el.name, selected: false, id: el.id }));
        setRegions(data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    if (useCase === SOLAR_LAWN_MOWING && !countriesStore.countries.length) {
      countriesStore.getCountries();
    }
  }, [useCase]);

  useEffect(() => {
    if (country) {
      regionsStore.getRegions(country);
      setFormReady(false);
    }
  }, [country]);

  useEffect(() => {
    if (country && region) {
      chaperonePropertyStore.getProperties(region);
      setFormReady(false);
    }
  }, [country, region]);

  useEffect(() => {
    if (country && region && property) {
      sectionStore.getSections(region, property, true);
      setFormReady(true);
    }
  }, [country, region, property]);

  const handleSubmitDeclareEvent = () => {
    if (useCase === SOLAR_LAWN_MOWING) {
      axios
        .post(`${applicationStore.getChaperoneBaseUrl()}/subrows/declare-event/`, { property, block })
        .then((res) => {
          setQuery('success');
          push(ROUTE_HOME);
        })
        .catch((err) => {
          applicationStore.pushError('Error', 'Error Declaring an Event!');
          setQuery('error');
        });
    } else {
      const errors = [];
      const selectedRegions = regions.filter((region) => region.selected).map((region) => region.id);
      if (!selectedRegions.length) errors.push('region');
      if (!useCase) errors.push('useCase');
      if (errors.length) {
        setFormError(errors);
        return;
      }
      setQuery('progress');

      axios
        .post(`${applicationStore.getChaperoneBaseUrl()}/subsections/declare-event/`, { useCase, selectedRegions })
        .then((res) => {
          setQuery('success');
          push(ROUTE_HOME);
        })
        .catch((err) => {
          applicationStore.pushError('Error', 'Error Declaring an Event!');
          setQuery('error');
        });
    }
  };

  const handleAddSelectedRegion = (event) => {
    setFormError((prevState) => prevState.filter((err) => err !== 'region'));
    const newRegions = regions.map((el) => ({
      ...el,
      selected: el.regionName === event.target.name ? event.target.checked : el.selected
    }));
    setRegions(newRegions);
    if (newRegions.filter((rg) => rg.selected).length) {
      setFormReady(true);
    } else {
      setFormReady(false);
    }
  };

  return useObserver(() => {
    return (
      <Fade in={true}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
          <Grid item xs={4}>
            <Typography variant="h3" className={classes.titleLable}>
              Declare Event
            </Typography>
          </Grid>

          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography variant="h6" component="span" className={classes.label}>
              Use Case:
            </Typography>
            <FormControl className={classes.formControl}>
              <Select
                value={useCase}
                className={classes.select}
                onChange={(e) => {
                  setFormError((prevState) => prevState.filter((err) => err !== 'useCase'));
                  setUseCase(e.target.value);
                }}
              >
                {Object.entries(USE_CASES).map(([key, name]) => (
                  <MenuItem key={key} value={key}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              {formError.includes('useCase') && <FormHelperText className={classes.helperText}>This is required!</FormHelperText>}
            </FormControl>
          </Grid>

          {!useCase ? null : useCase === 'SOLAR_LAWN_MOWING' ? (
            <>
              <CustomSelectField
                label="Country"
                id="country"
                value={country}
                setValue={setCountry}
                options={countriesStore.countries.map(({ id, name }) => ({ id, name }))}
                classes={classes}
                formError={formError}
                required={true}
              />
              <CustomSelectField
                label="Region"
                id="region"
                value={region}
                setValue={setRegion}
                options={regionsStore.regions.map(({ id, name }) => ({ id, name }))}
                classes={classes}
                formError={formError}
                required={true}
              />
              <CustomSelectField
                label="Property"
                id="property"
                value={property}
                setValue={setProperty}
                options={chaperonePropertyStore.properties.map(({ id, name }) => ({ id, name }))}
                classes={classes}
                formError={formError}
                required={true}
              />
              <CustomSelectField
                label="Block"
                id="block"
                value={block}
                setValue={setBlock}
                options={sectionStore.sections.map(({ id, name }) => ({ id, name }))}
                classes={classes}
                formError={formError}
              />
            </>
          ) : (
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                Available Regions:
              </Typography>
              <FormControl
                required
                error={formError.includes('region')}
                component="fieldset"
                className={classes.formControl}
                style={{ height: 410, overflow: 'auto' }}
              >
                <FormGroup>
                  {regions.map(({ regionName, selected }) => (
                    <FormControlLabel
                      className={classes.formControlLabel}
                      key={regionName}
                      control={
                        <Checkbox style={{ color: 'white' }} checked={selected} onChange={handleAddSelectedRegion} name={regionName} />
                      }
                      label={regionName}
                    />
                  ))}
                </FormGroup>
                <FormHelperText className={classes.noRegionNote}>You need to select at least one region!</FormHelperText>
              </FormControl>
            </Grid>
          )}

          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Button onClick={() => push(ROUTE_HOME)} className={classes.button} variant="contained" color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubmitDeclareEvent}
              className={classes.button}
              variant="contained"
              color="secondary"
              disabled={!formReady}
            >
              Save
            </Button>
          </Grid>
          <Backdrop className={classes.backdrop} open={query === 'progress'}>
            {query === 'success' ? (
              <Typography>Success!</Typography>
            ) : query === 'error' ? (
              <Typography>Error!</Typography>
            ) : (
              <Fade in={query === 'progress'} style={{ transitionDelay: '300ms' }} unmountOnExit>
                <CircularProgress className={classes.circularProgress} />
              </Fade>
            )}
          </Backdrop>
        </Grid>
      </Fade>
    );
  });
};
