/* eslint-disable no-param-reassign */
// TODO: need to refactor this file, remove all caches, and setter, depend on the new way of fetching data from the API
import { types, flow, getParent } from 'mobx-state-tree';
import { ResourceStore } from '../resources/resource.store';
import { AutonomyAuditReports } from './autonomy-audit-reports.model';
import { fetchReports, fetchS3 } from '../../services/api/autonomy-audit-reports.service';
import { AutonomyAuditFilter } from './autonomy-audit-filter.model';
import { SectionsCache, SubsectionsCache, SubrowsCache } from './autonomy-audit-caches.model';
import { getSubrowName, getPathType } from '../../utils/audits.utils';
import { getSubBlockPathTypes } from '../../services/api/subrows.service';

const AutonomyAuditReportsStoreInternal = types
  .model('AutonomyAuditReportsStore', {
    loading: types.optional(types.boolean, true),
    firstLoad: true,
    filter: types.optional(AutonomyAuditFilter, {}),
    records: types.optional(types.array(AutonomyAuditReports), []),
    selectedRecord: types.maybeNull(types.reference(AutonomyAuditReports)),
    hasPrevRecord: types.optional(types.boolean, false),
    hasNextRecord: types.optional(types.boolean, false),
    showFilter: types.optional(types.boolean, true),
    showFullScreenDialog: types.optional(types.boolean, false),
    sectionsCache: types.optional(types.array(SectionsCache), []),
    subsectionsCache: types.optional(types.array(SubsectionsCache), []),
    subrowsCache: types.optional(types.array(SubrowsCache), []),
    clearRecords: types.optional(types.boolean, true),
    pathTypes: types.optional(types.array(types.string), [])
  })
  .actions((self) => ({
    setRecords(data) {
      self.records = data;
    },
    setFilter(filterOptions) {
      self.filter = filterOptions;
    },
    setClearRecords(bool) {
      self.clearRecords = bool;
    },
    setNextToken(nextToken) {
      self.filter.nextToken = nextToken;
    },
    setLoading(value) {
      self.loading = value;
    },
    setFirstLoad(value) {
      self.firstLoad = value;
    },
    setRepeatedCsvData(data) {
      self.records[self.records.indexOf(self.selectedRecord)].repeatedCsvData = this.parseToFloat(data);
    },
    setTaughtCsvData(data) {
      self.records[self.records.indexOf(self.selectedRecord)].taughtCsvData = this.parseToFloat(data);
    },
    setDeviationArray(data) {
      self.records[self.records.indexOf(self.selectedRecord)].deviationArray = data;
    },
    setCsvLoading(data) {
      self.records[self.records.indexOf(self.selectedRecord)].loading = data;
      this.setArrowBools();
    },
    setHasNoData(data) {
      self.records[self.records.indexOf(self.selectedRecord)].hasNoData = data;
      this.setCsvLoading(false);
    },
    setIsSolar(index, bool) {
      self.records[index].isSolar = bool;
    },
    setCountryName(countryName) {
      self.selectedRecord.countryName = countryName;
    },
    setRegionName(regionName) {
      self.selectedRecord.regionName = regionName;
    },
    setPropertyName(propertyName) {
      self.selectedRecord.propertyName = propertyName;
    },
    setSectionName(index, sectionName) {
      self.records[index].sectionName = sectionName;
    },
    setSubsectionName(index, subsectionName) {
      self.records[index].subsectionName = subsectionName;
    },
    setShowFilter: (value) => {
      self.showFilter = value;
    },
    setShowFullScreenDialog: (value) => {
      self.showFullScreenDialog = value;
    },
    setArrowBools() {
      if (self.records.indexOf(self.selectedRecord) !== 0 && self.selectedRecord !== null && !self.selectedRecord.loading) {
        self.hasPrevRecord = true;
      } else self.hasPrevRecord = false;
      if (
        self.records.indexOf(self.selectedRecord) !== self.records.length - 1 &&
        self.selectedRecord !== null &&
        !self.selectedRecord.loading
      ) {
        self.hasNextRecord = true;
      } else self.hasNextRecord = false;
    },
    setSectionsCache(obj) {
      self.sectionsCache.push(obj);
    },
    setSubsectionsCache(obj) {
      self.subsectionsCache.push(obj);
    },
    setSubrowsCache(obj) {
      self.subrowsCache.push(obj);
    },
    async getCountryDetails(countryId) {
      return getParent(self).countriesStore.getById(countryId);
    },
    async getRegionDetails(countryId, regionId) {
      await getParent(self).regionsStore.getRegions(countryId, regionId);
      return getParent(self).regionsStore.getById(regionId);
    },
    async getPropertyDetails(regionId, propertyId) {
      await getParent(self).chaperonePropertyStore.getProperties(regionId);
      return getParent(self).chaperonePropertyStore.getById(propertyId);
    },
    async getSectionDetails(sectionId) {
      const sectionInCache = self.sectionsCache.find(({ id }) => id === sectionId);
      if (sectionInCache) {
        return sectionInCache;
      }
      const result = await getParent(self).sectionStore.getSectionById({ id: sectionId });
      this.setSectionsCache({ id: sectionId, name: result.name, isSolar: result.isSolar });
      return result.results;
    },
    getPathTypes: flow(function* f(subBlockId) {
      try {
        const pathTypes = yield getSubBlockPathTypes(subBlockId);
        self.pathTypes = pathTypes;
      } catch (e) {
        console.error(e);
      }
    }),
    async getSubrowDetails(regionId, propertyId, sectionId, subBlockId, pathType) {
      const subrowInCache = self.subrowsCache.find(({ id }) => id === subBlockId);
      if (subrowInCache) {
        return subrowInCache;
      }
      await getParent(self).subrowStore.getSubrows(regionId, propertyId, subBlockId, pathType);
      getParent(self).subrowStore.subrows.forEach((subrow) => {
        this.setSubrowsCache({ id: subrow.id, name: subrow.name });
      });
      return getParent(self).subrowStore.getById(subBlockId);
    },
    async getSubsectionDetails(countryId, regionId, propertyId, sectionId, subsectionId) {
      const subsectionInCache = self.subsectionsCache.find(({ id }) => id === subsectionId);
      if (subsectionInCache) {
        return subsectionInCache;
      }
      await getParent(self).subsectionStore.getSubsections(countryId, regionId, propertyId, sectionId);
      getParent(self).subsectionStore.subsections.forEach((subsection) => {
        this.setSubsectionsCache({ id: subsection.id, name: subsection.name });
      });
      return getParent(self).subsectionStore.getById(subsectionId);
    },

    async getAllLocationDetails() {
      try {
        if (!self.selectedRecord.countryName) {
          const { name } = await this.getCountryDetails(self.selectedRecord.countryId);
          this.setCountryName(name);
        }
      } catch (e) {
        console.error('Unable to fetch country data', e);
      }
      try {
        if (!self.selectedRecord.regionName) {
          const { name } = await this.getRegionDetails(self.selectedRecord.countryId, self.selectedRecord.regionId);
          this.setRegionName(name);
        }
      } catch (e) {
        console.error('Unable to fetch region data', e);
      }
      try {
        if (!self.selectedRecord.propertyName) {
          const { name } = await this.getPropertyDetails(self.selectedRecord.regionId, self.selectedRecord.propertyId);
          this.setPropertyName(name);
        }
      } catch (e) {
        console.error('Unable to fetch property data', e);
      }
    },
    async getCardNames() {
      // Goes through all records and and finds their corresponding subsection/subrow names
      for (let i = 0; i < self.records.length; i += 1) {
        try {
          const { name, isSolar } = await this.getSectionDetails(self.records[i].sectionId);
          this.setSectionName(i, name);
          this.setIsSolar(i, isSolar);
        } catch (e) {
          console.error('Unable to fetch section data:', e);
        }

        if (self.records[i].s3URI.search('/subrows/') !== -1) {
          try {
            const { name } = await this.getSubrowDetails(
              self.records[i].regionId,
              self.records[i].propertyId,
              self.records[i].sectionId,
              self.records[i].subBlockId,
              getPathType(self.records[i].s3URI)
            );
            if (name) this.setSubsectionName(i, getSubrowName(name));
          } catch (e) {
            console.error('Unable to fetch subrow data', e);
          }
        } else {
          try {
            const { name } = await this.getSubsectionDetails(
              self.records[i].countryId,
              self.records[i].regionId,
              self.records[i].propertyId,
              self.records[i].sectionId,
              self.records[i].subsectionId,
              getPathType(self.records[i].s3URI)
            );
            this.setSubsectionName(i, name);
          } catch (e) {
            console.error('Unable to fetch subsection data', e);
          }
        }
      }
    },
    getRecords: flow(function* getRecords() {
      if (self.loading !== undefined) self.setLoading(true);
      yield self.fetch();
    }),
    getApiFetch: flow(function* f() {
      return yield fetchReports(self.filter);
    }),
    async onFetchSuccess(response, nextToken) {
      const { records: results, resolvedIdsNames } = response;
      this.selectRecord(null);

      if (self.clearRecords) this.setRecords([]);
      // Sort by date (newest to oldest)
      self.records = self.records.concat(results).sort((a, b) => b.timestamp - a.timestamp);

      self.mergeRecordswithResolvedIdsNames(resolvedIdsNames);
      if (self.filter.useCase === 'true') this.setRecords(self.records.filter((result) => result.isSolar === 'true'));
      else if (self.filter.useCase === false) this.setRecords(self.records.filter((result) => result.isSolar === false));

      if (nextToken) {
        this.setNextToken(nextToken);
        this.setClearRecords(false);
        this.getRecords();
      } else {
        this.setNextToken(null);
        this.setClearRecords(true);
        if (!self.firstLoad) this.setShowFilter(false);
        this.setFirstLoad(false);
        if (this?.setLoading !== undefined) this.setLoading(false);
      }
    },
    onFetchError(error) {
      if (self.loading !== undefined) self.setLoading(false);
      console.log(error);
      self.throwError(error?.message || 'Unable to load records');
    },
    mergeRecordswithResolvedIdsNames(resolvedIdsNames) {
      for (let i = 0; i < self.records.length; i += 1) {
        if (self.records[i].countryName) continue; //Skip existing data
        self.records[i].countryName = resolvedIdsNames.countries.find((el) => el.id === self.records[i].countryId)?.name;
        self.records[i].regionName = resolvedIdsNames.regions.find((el) => el.id === self.records[i].regionId)?.name;
        self.records[i].propertyName = resolvedIdsNames.properties.find((el) => el.id === self.records[i].propertyId)?.name;
        if (self.records[i].s3URI.includes('/subrows/')) {
          self.records[i].sectionName = resolvedIdsNames.blocks.find((el) => el.id === self.records[i].sectionId)?.name;
          self.records[i].subBlockName = resolvedIdsNames.subblocks.find((el) => el.id === self.records[i].subBlockId)?.name;
          self.records[i].subsectionName = resolvedIdsNames.subrows.find((el) => el.id === self.records[i].subsectionId)?.name;
        } else {
          self.records[i].sectionName = resolvedIdsNames.sections.find((el) => el.id === self.records[i].sectionId)?.name;
          self.records[i].subsectionName = resolvedIdsNames.subsections.find((el) => el.id === self.records[i].subsectionId)?.name;
        }
      }
    },
    parseToFloat(data) {
      return data.map((obj) => ({
        ...obj,
        angle: parseFloat(obj.angle),
        lat: parseFloat(obj.lat),
        long: parseFloat(obj.long),
        timestamp: parseFloat(obj.timestamp),
        x: parseFloat(obj.x),
        y: parseFloat(obj.y)
      }));
    },
    async fetchCsvData() {
      if (self?.selectedRecord?.s3URI) {
        if (!self.selectedRecord.repeatedCsvData?.length && !self.selectedRecord.taughtCsvData?.length) {
          this.setHasNoData(false);
          try {
            this.setCsvLoading(true);
            const response = await fetchS3(self?.selectedRecord?.s3URI);
            this.setRepeatedCsvData(response.repeatedCsvData);
            this.setTaughtCsvData(response.taughtCsvData);
            this.setDeviationArray(response.deviationArray);
            this.setCsvLoading(false);
            return;
          } catch (e) {
            console.error(e);
            this.setHasNoData(true);
          }
        }
      } else this.setHasNoData(true);
    },
    selectRecord(record) {
      self.selectedRecord = record;
      if (self?.selectedRecord) this.fetchCsvData();
      this.setArrowBools();
    },
    selectPrevRecord() {
      this.selectRecord(self.records[self.records.indexOf(self.selectedRecord) - 1]);
    },
    selectNextRecord() {
      this.selectRecord(self.records[self.records.indexOf(self.selectedRecord) + 1]);
    }
  }));

export const AutonomyAuditReportsStore = types.compose(AutonomyAuditReportsStoreInternal, ResourceStore).named('AutonomyAuditReportsStore');
