import React, { useState } from 'react';
import { makeStyles, TableCell, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import {
  PlayArrow,
  ArrowUpwardOutlined,
  ArrowDownwardOutlined,
  DesktopWindowsRounded,
  EditRounded,
  InfoRounded,
  DeleteRounded
} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import { ROUTE_EDIT_WAY_POINTS } from '../../pages/routes';
import { formatDate } from '../../utils/ui.utils';

import ConfirmActionDialog from '../dialogs/confirm-action.dialog';
import { MapPathDialog } from '../dialogs/map-path.dialog';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { SubSectionInfoDialog } from '../dialogs/sub-section-info.dialog';
import ConnectionErrorDialog from '../dialogs/connection-error.dialog';
import ActionsDialog from '../dialogs/actions.dialog';
import { NOT_STARTED, IN_PROGRESS } from '../../utils/constants';
import { sortTable } from '../../utils/table-sort';

const useStyles = makeStyles((theme) => ({
  marker: {
    margin: theme.spacing(1.25)
  },
  icon: {
    color: 'black',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

const SubsectionsTable = observer(({ repeatPath }) => {
  const { authenticationStore, countriesStore, regionsStore, chaperonePropertyStore, sectionStore, subsectionStore, autonomyRobotStore } =
    useStores();
  const useCase = localStorage.getItem('useCase');
  const { selectedCountryId } = countriesStore;
  const { selectedRegionId } = regionsStore;
  const { selectedPropertyId } = chaperonePropertyStore;
  const { selectedSectionId } = sectionStore;
  const { selectedSubsectionId } = subsectionStore;
  const { role } = authenticationStore;

  const classes = useStyles();
  const { push } = useHistory();
  const [showMapPathDialog, setShowMapPathDialog] = useState(false);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);
  const [showSubsectionInfoDialog, setShowSubsectionInfoDialog] = useState(false);
  const [openReorderDialog, setOpenReorderDialog] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [reorderSubsectionAction, setReorderSubsectionAction] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [selectedSortParam, setSelectedSortParam] = useState('name');
  const [selectedSortType, setSelectedSortType] = useState('asc');

  const headers = [
    { name: 'Actions', id: 'actions', isSortable: false },
    { name: 'Name', id: 'name', isSortable: true },
    { name: 'Repeat Status', id: 'repeat_status', isSortable: true },
    { name: 'Created At', id: 'date:created_at', isSortable: true },
    { name: 'Address', id: 'street_address', isSortable: true }
  ];

  const handleOpenConfirmDialog = (subSectionInfo) => {
    subsectionStore.setSelectedSubsection(subSectionInfo.subsection.id);
    setShowConfirmDeleteDialog(true);
  };

  const handleDeleteSubSection = async () => {
    const { id } = subsectionStore.getSelectedSubsection();
    await subsectionStore.deleteSubsection({ id });
    await subsectionStore.getSubsections(selectedCountryId, selectedRegionId, selectedPropertyId, selectedSectionId);
    setShowConfirmDeleteDialog(false);
  };

  const handleCloseDialog = () => setShowConfirmDeleteDialog(false);

  const handleReorderSubSection = () => {
    if (reorderSubsectionAction === 'UP') {
      subsectionStore.moveUp(subsectionStore.getSelectedSubsection()?.order_index);
    } else if (reorderSubsectionAction === 'DOWN') {
      subsectionStore.moveDown(subsectionStore.getSelectedSubsection()?.order_index);
    }
    setOpenReorderDialog(false);
  };

  const handleCloseReorderDialog = () => {
    setOpenReorderDialog(false);
  };

  return (
    <>
      <StripedTable
        headers={headers}
        selectedSortParam={selectedSortParam}
        selectedSortType={selectedSortType}
        setSelectedSortParam={setSelectedSortParam}
        setSelectedSortType={setSelectedSortType}
      >
        {sortTable(subsectionStore.subsections, selectedSortParam, selectedSortType).map((subsection, index) => {
          let backgroundColorRepeatStatus;
          const repeatStatus = subsection.repeat_status;
          if (repeatStatus === IN_PROGRESS) {
            backgroundColorRepeatStatus = '#03a9f4';
          } else if (repeatStatus === NOT_STARTED) {
            backgroundColorRepeatStatus = '#a9a9a9';
          } else {
            backgroundColorRepeatStatus = '#2eb885';
          }
          return (
            <StripedTableRow key={subsection}>
              <TableCell align="left">
                <Grid container spacing={1}>
                  <Grid item>
                    <Tooltip title={<Typography>Repeat</Typography>}>
                      <IconButton
                        size="small"
                        className={classes.icon}
                        onClick={() => {
                          if (autonomyRobotStore.selectedRobotId) {
                            push({
                              pathname: repeatPath,
                              state: {
                                region: subsection.region_id,
                                property: subsection.property_id,
                                section: subsection.section_id,
                                subsection: subsection.id,
                                useCase,
                                role,
                                robot: autonomyRobotStore.selectedRobotId
                              }
                            });
                          } else {
                            setConnectionError(true);
                            setErrorMessage('No Robots Selected, please select Robot!');
                          }
                        }}
                      >
                        <PlayArrow />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Tooltip title={<Typography>Visualize</Typography>}>
                      <IconButton
                        size="small"
                        className={classes.icon}
                        onClick={() => {
                          subsectionStore.setSelectedSubsection(subsection.id);
                          return setShowMapPathDialog(true);
                        }}
                      >
                        <DesktopWindowsRounded />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {(role === 'manager' || role === 'admin') && (
                    <Grid item>
                      <Tooltip title={<Typography>Edit</Typography>}>
                        <IconButton
                          size="small"
                          className={classes.icon}
                          onClick={() => {
                            // subsectionStore.clearSubsection();
                            push({
                              pathname: ROUTE_EDIT_WAY_POINTS,
                              state: {
                                region: subsection.region_id,
                                property: subsection.property_id,
                                section: subsection.section_id,
                                subsection: subsection.id,
                                useCase,
                                role
                              }
                            });
                          }}
                        >
                          <EditRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}
                  <Grid item>
                    <Tooltip title={<Typography>Details</Typography>}>
                      <IconButton
                        size="small"
                        className={classes.icon}
                        onClick={() => {
                          subsectionStore.setSelectedSubsection(subsection.id);
                          return setShowSubsectionInfoDialog(true);
                        }}
                      >
                        <InfoRounded />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  {(role === 'manager' || role === 'admin') && (
                    <Grid item>
                      <Tooltip title={<Typography>Delete</Typography>}>
                        <IconButton
                          size="small"
                          className={classes.icon}
                          onClick={() =>
                            handleOpenConfirmDialog({
                              region: subsection.region_id,
                              property: subsection.property_id,
                              section: subsection.section_id,
                              subsection
                            })
                          }
                        >
                          <DeleteRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  )}

                  {role === 'admin' && selectedSectionId && index !== 0 ? (
                    <Grid item>
                      <Tooltip title={<Typography>Move Up</Typography>}>
                        <IconButton
                          size="small"
                          className={classes.icon}
                          onClick={() => {
                            setReorderSubsectionAction('UP');
                            subsectionStore.setSelectedSubsection(subsection.id);
                            setOpenReorderDialog(true);
                          }}
                        >
                          <ArrowUpwardOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                  {role === 'admin' && selectedSectionId && index !== subsectionStore.subsections.length - 1 ? (
                    <Grid item>
                      <Tooltip title={<Typography>Move Down</Typography>}>
                        <IconButton
                          size="small"
                          className={classes.icon}
                          onClick={() => {
                            setReorderSubsectionAction('DOWN');
                            subsectionStore.setSelectedSubsection(subsection.id);
                            setOpenReorderDialog(true);
                          }}
                        >
                          <ArrowDownwardOutlined />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  ) : null}
                </Grid>
              </TableCell>
              <TableCell align="left">{subsection.name}</TableCell>
              <TableCell style={{ backgroundColor: backgroundColorRepeatStatus }} align="left">
                {subsection.current_robot_serial_number && subsection.repeat_status !== NOT_STARTED
                  ? `${subsection.repeat_status} using: ${subsection.current_robot_serial_number}`
                  : subsection.repeat_status}
              </TableCell>
              <TableCell align="left">{formatDate(subsection.last_edit_date)}</TableCell>
              <TableCell align="left">{subsection.street_address}</TableCell>
            </StripedTableRow>
          );
        })}
      </StripedTable>
      <SubSectionInfoDialog
        subsection={subsectionStore.getSelectedSubsection()}
        show={showSubsectionInfoDialog}
        onClose={() => setShowSubsectionInfoDialog(false)}
      />
      <ActionsDialog
        dialogTitle="WARNING: Moving a sub-section that starts more than 50 cm away from the next sub-section may cause problems. Are you sure?"
        open={openReorderDialog}
        actions={[
          { color: 'primary', name: 'Cancel', variant: 'outlined', handler: handleCloseReorderDialog },
          { color: 'secondary', name: 'Confirm', variant: 'contained', handler: handleReorderSubSection }
        ]}
      />
      {showMapPathDialog && (
        <MapPathDialog
          state={{
            region: selectedRegionId,
            property: selectedPropertyId,
            section: selectedSectionId,
            subsection: selectedSubsectionId,
            teachPage: false
          }}
          show={showMapPathDialog}
          onClose={() => setShowMapPathDialog(false)}
        />
      )}
      <ConfirmActionDialog
        handleAction={handleDeleteSubSection}
        handleClose={handleCloseDialog}
        title={`Delete ${subsectionStore?.getSelectedSubsection()?.name ? subsectionStore?.getSelectedSubsection()?.name : 'Robot'}`}
        body={`Are you sure you want to delete ${
          subsectionStore?.getSelectedSubsection()?.name ? subsectionStore?.getSelectedSubsection()?.name : 'this robot'
        }?`}
        open={showConfirmDeleteDialog}
      />
      <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
    </>
  );
});

export default SubsectionsTable;
