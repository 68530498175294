import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  Paper
} from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStores } from '../../store/root/root.store';
import UnsavedChangesDialog from './unsaved-changes.dialog';

const useStyles = makeStyles((theme) => ({
  root: {},

  formControl: {
    marginBottom: theme.spacing(2)
  },
  uppercase: {
    '& > input': {
      textTransform: 'uppercase'
    },
    '& > input::placeholder': {
      textTransform: 'none'
    }
  }
}));

export const AddCountry = ({ show, property = null, onClose = () => {} }) => {
  const classes = useStyles();
  const { countriesStore } = useStores();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);
  const [country, setCountry] = useState('');

  const onSubmit = async (data) => {
    const { name } = data;
    var exist = countriesStore.checkDuplicates({ name });
    if (!exist) {
      const data = await countriesStore.createCountry({ name });
      await countriesStore.getCountries();
      onClose(data.results.id);
    } else {
      countriesStore.setError('This country already exists');
      onClose();
    }
  };

  function handleClose() {
    if (country) setOpenUnsavedChangesDialog(true);
    else onClose();
  }

  function handleConfirmClose(doCloseFully) {
    setOpenUnsavedChangesDialog(false);
    if (doCloseFully) onClose();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return useObserver(() => (
    <Dialog onClose={() => handleClose()} open={show} className={classes.root} fullWidth>
      <DialogTitle onClose={() => handleClose()}>Add Country</DialogTitle>
      <DialogContent>
        <Paper component="form" elevation={0} color="inverted">
          <FormControl variant="outlined" fullWidth error={!!errors.name} className={classes.formControl}>
            <OutlinedInput
              autoFocus
              aria-describedby="name-error-text"
              fullWidth
              name="name"
              placeholder="Country Name"
              inputRef={register({ required: true, maxLength: 250 })}
              onChange={(event) => {
                setCountry(event.target.value);
              }}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="name-error-text">{errors.name?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="name-error-text">{errors.name?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => handleClose()} variant="outlined" color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
            Save
          </Button>
        </>
      </DialogActions>
      <UnsavedChangesDialog show={openUnsavedChangesDialog} onClose={(doCloseFully) => handleConfirmClose(doCloseFully)} />
    </Dialog>
  ));
};
