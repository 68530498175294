import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useObserver } from 'mobx-react-lite';
import { DialogContentText, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  title: {
    color: '#FF0000',
    textAlign: 'center'
  },
  text: {
    textAlign: 'center',
    margin: '2px'
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const AlertDialog = ({ state, show, property = null, containerRef, onClose = () => {} }) => {
  const classes = useStyles();

  const handleClose = () => {
    onClose();
  };
  return useObserver(() => (
    <Dialog
      open={show}
      fullWidth
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      container={containerRef === undefined || !containerRef ? null : containerRef.current}
    >
      <DialogTitle className={classes.title} id="alert-dialog-title">
        {state.error}
      </DialogTitle>
      {state.message && <DialogContentText className={classes.text}>{state.message}</DialogContentText>}
      <DialogActions className={classes.actions}>
        <Button variant="contained" color="secondary" onClick={handleClose} autoFocus>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  ));
};
