export const PORTAL_ATTRIBUTES = {
  title: 'Portal Configurations',
  body: 'Configurations to manage how the robot gets sorted on the web portal. Does not impact the code on the robot.'
};

export const ROBOT_ATTRIBUTES = {
  title: 'Robot Configurations',
  body: `Changes to the Robot Configurations affect the software deployment on the robot.
The Software branch configuration is based on the 
the dev, stage, production workflow which involves sequentially progressing through development, 
staging, and production stages, allowing developers to iteratively create and test code before 
deploying it to a stable and reliable production environment for end-user access and optimal performance.
`
};
