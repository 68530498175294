import React from 'react';
import { FormControlLabel, Grid, Tooltip, Switch, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    width: '100%',
    border: '1px solid rgb(212, 212, 212)',
    borderRadius: '4px',
    boxSizing: 'border-box',
    marginTop: '7px',
    padding: '5px',
    '&:hover': {
      border: '1px solid grey'
    }
  }
}));

/**
 * AutonomyConfiguration is responsible for rendering and handling user input for Autonmous Robot configurations
 * on the edit robot dialog
 * @param {Boolean} isDevBot -if True and no container is running on the robot, dev image is deployed, otherwise stage image is deployed
 * @param {Boolean} isAutonomousManualBladeEnabled - When enabled, blades will be in manual mode during autonomous runs
 * @param {Boolean} isAutonomousAutomaticlBladeEnabled - When enabled, blades will be in automatic/autonomous mode during autonomous runs
 * @param {Boolean} isAutonomousAttachmentControlEnabled - Check for mounted attachments
 * @param {Boolean} isZedDiagnosticDisabled - When true, ZED warnings will not be sent to the portal.
 * @param {Object} setIsDevBot - useState setter for isDevBot
 * @param {Object} setisAutonomousManualBladeEnabled - useState setter for isAutonomousManualBladeEnabled.
 * @param {Object} setisAutonomousBladeEnabled -  useState setter for isAutonomousAutomaticlBladeEnabled.
 * @param {Object} setisAutonomousAttachmentControlEnabled - useState setter for isAutonomousAttachmentControlEnabled.
 * @param {Object} handleShowDisableZedDiagnosticWarning - Handler for rendering diable ZED diagnositc warning and useState setter for isZedDiagnosticDisabled
 *
 */
export const AutonomyConfiguration = observer(
  ({
    uiParameters,
    handleShowDisableZedDiagnosticWarning,
    isSelectedRobotConnectedROS,
    isSelectedRobotInUse,
    handleUiParametersChange,
    setUiParameters
  }) => {
    const classes = useStyles();
    return (
      <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="start" className={classes.mainContainer}>
        {/* Enable Manual Blade  */}
        <Grid item xs={3} style={{ padding: '3px', borderRight: '2px solid #c9c9c7' }}>
          <Box display="flex" justifyContent="space-between">
            <Tooltip title="When enabled, blades operate in manual mode during autonomy operations">
              <FormControlLabel
                control={
                  // eslint-disable-next-line react/jsx-wrap-multilines
                  <Switch
                    name="enableManualBladesDuringAutonomy"
                    checked={uiParameters.enableManualBladesDuringAutonomy}
                    onChange={(event) => {
                      handleUiParametersChange(event, true);
                    }}
                    color="primary"
                    style={{ marginLeft: 'auto' }}
                  />
                }
                labelPlacement="start"
                label="Enable Manual Blades"
                disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
                style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
              />
            </Tooltip>
          </Box>
          {/* Enable Autonomous Blade  */}

          <Tooltip title="When enabled, blades operate in autonomous mode during autonomy operations.">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableAutonomousBladesDuringAutonomy"
                  checked={uiParameters.enableAutonomousBladesDuringAutonomy}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Autonomous Blades"
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse || !uiParameters.enableManualBladesDuringAutonomy}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} style={{ padding: '3px', borderRight: '2px solid #c9c9c7' }}>
          {/* Enable autonomous attachment control */}
          <Tooltip title="When enabled, attachment control will be autonomous.">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableAutonomousAttachmentControl"
                  checked={uiParameters.enableAutonomousAttachmentControl}
                  onChange={(event) => {
                    const { name, checked } = event.target;
                    const formattedValue = checked ? 1 : 0;
                    setUiParameters((previous) => ({
                      ...previous,
                      [name]: formattedValue
                    }));
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Autonomous Attachment Control"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
          {/* Enable Zed Diagnostics  */}
          <Tooltip title="When enabled, ZED diagnositc system will be online.">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="acceptableZedLevel"
                  checked={uiParameters.acceptableZedLevel === 1}
                  onChange={(event) => {
                    handleShowDisableZedDiagnosticWarning(event.target.checked);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Disable ZED Camera Diagnostic"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} style={{ padding: '3px', borderRight: '2px solid #c9c9c7' }}>
          {/* Enable is dev bot  */}
          <Tooltip title="When enabled, Robot wil be swithced to dev mode.">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableDevBot"
                  checked={uiParameters.enableDevBot}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable IsDev Bot "
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
          {/* Enable inter-row hopping */}
          <Tooltip title="When enabled, robot will execute inter-row hopping">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="enableInterRowHopping"
                  checked={uiParameters.enableInterRowHopping}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Inter-Row Hopping"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
        </Grid>
        <Grid item xs={3} style={{ padding: '3px' }}>
          {/* Enable Teleops Streaming */}
          <Tooltip title="When enabled, robot will have video streaming on">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="isTeleopsStreamingEnabled"
                  checked={uiParameters.isTeleopsStreamingEnabled}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable TeleOps Streaming"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
          {/* Enable low bandwidth mode */}
          <Tooltip title="When enabled, robot will be on low bandwidth mode">
            <FormControlLabel
              control={
                // eslint-disable-next-line react/jsx-wrap-multilines
                <Switch
                  name="isLowStreamingEnabled"
                  checked={uiParameters.isLowStreamingEnabled}
                  onChange={(event) => {
                    handleUiParametersChange(event, true);
                  }}
                  color="primary"
                  style={{ marginLeft: 'auto' }}
                />
              }
              labelPlacement="start"
              label="Enable Low Bandwidth"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse || !uiParameters.isTeleopsStreamingEnabled}
              style={{ opacity: '0.5', width: '100%', justifyContent: 'space-between', marginLeft: '4px' }}
            />
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);
