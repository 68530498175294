import { types } from 'mobx-state-tree';

const BatteryInfo = types.model({
  timestamp: types.maybeNull(types.number),
  connected: types.maybeNull(types.boolean),
  id: types.maybeNull(types.number),
  type: types.maybeNull(types.string),
  operation_mode: types.maybeNull(types.string),
  percentage: types.maybeNull(types.number),
  voltage: types.maybeNull(types.number),
  current: types.maybeNull(types.number),
  temperature: types.maybeNull(types.number),
  time_remaining_min: types.maybeNull(types.number),
  capacity: types.maybeNull(types.number),
  serial_number: types.maybeNull(types.string),
  state_of_health: types.maybeNull(types.number),
  faults: types.maybeNull(types.array(types.string)),
  errors: types.maybeNull(types.array(types.string)),
  warnings: types.maybeNull(types.array(types.string))
});

export const Battery = types.model({
  number_of: types.maybeNull(types.number),
  percentage: types.maybeNull(types.number),
  battery_info: types.maybeNull(types.array(BatteryInfo))
});
