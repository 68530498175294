import React, { useState } from 'react';
import {
  makeStyles,
  withStyles,
  Typography,
  Grid,
  Tooltip,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  Icon,
  SvgIcon
} from '@material-ui/core';
import PropTypes from 'prop-types';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import WarningIcon from '@material-ui/icons/Warning';
import BatteryIcon from './battery-icon.component';
import { useStores } from '../../store/root/root.store';
import { celsiusToFahrenheit, convertMinutesToHoursMinutes, DEFAULT_NO_DATA } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  containerScrollable: {
    height: '100%',
    maxHeight: 262,
    width: '135%',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    }
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold'
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#e0e0e0'
    },
    '&:nth-of-type(even)': {
      backgroundColor: '#ffffff'
    }
  }
}))(TableRow);

function Row(props) {
  const { row } = props;
  const { autonomyRobotStore } = useStores();
  const name = autonomyRobotStore.getSelectedRobot()?.name;
  const [showAllStatus, setShowAllStatus] = useState(false);
  const [showBatteryFaults, setShowBatteryFaults] = useState(false);
  const [showBatteryErrors, setShowBatteryErrors] = useState(false);
  const [showBatteryWarnings, setShowBatteryWarnings] = useState(false);
  const vanguardBatteryUsed = row.type === 'VANGUARD';
  const isFaultyBattery =
    !row?.connected || ((row?.errors?.length !== 0 || row?.faults?.length !== 0) && (Math.abs(row.current) < 0.2 && row?.connected));
  const noStatusMessages = row?.errors?.length === 0 && row?.warnings?.length === 0 && row?.faults?.length === 0;

  const checkAnyStatusOpen = () => {
    if (showBatteryFaults || showBatteryErrors || showBatteryWarnings) {
      return true;
    }
    return false;
  };

  const setAllStatus = (status) => {
    if (status) {
      setShowBatteryErrors(true);
      setShowBatteryFaults(true);
      setShowBatteryWarnings(true);
      setShowAllStatus(true);
    } else {
      setShowBatteryErrors(false);
      setShowBatteryFaults(false);
      setShowBatteryWarnings(false);
      setShowAllStatus(false);
    }
  };

  return (
    <>
      {!vanguardBatteryUsed && (
        <StyledTableRow
          style={{
            '& > *': {
              borderBottom: 'unset'
            },
            background: isFaultyBattery ? '#9c7575' : '#ede8e8'
          }}
        >
          <StyledTableCell>
            <Tooltip title={showAllStatus ? 'Hide All Status' : 'Show All Status'}>
              <IconButton
                aria-label="expand row"
                size="small"
                disabled={noStatusMessages}
                onClick={() => {
                  if (checkAnyStatusOpen()) setAllStatus(false);
                  else setAllStatus(true);
                  setShowAllStatus(true);
                }}
              >
                {showAllStatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell component="th" scope="row">
            {!row?.connected && (
              <Tooltip
                title={
                  <Typography>
                    <b>BAD Battery connected</b>
                  </Typography>
                }
              >
                <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                  <WarningIcon />
                </SvgIcon>
              </Tooltip>
            )}{' '}
            {row?.connected ? 'Yes' : 'No'}
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Box>
                <BatteryIcon percentage={row.percentage} />
              </Box>
              {`${row.percentage}%`}
              {row.percentage <= 10 && vanguardBatteryUsed && (
                <Tooltip
                  title={`A VANGUARD battery should never be fully discharged. Power off ${name} until the battery is able to be swapped.`}
                >
                  <Icon
                    aria-label="expand row"
                    size="small"
                    style={{
                      position: 'relative',
                      top: '-4px',
                      right: '-5px',
                      color: `${row.percentage > 5 ? '#eed202' : 'red'}`
                    }}
                  >
                    <WarningOutlinedIcon />
                  </Icon>
                </Tooltip>
              )}
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            {row.temperature >= 65 ? (
              <Tooltip
                title={
                  <Typography>
                    <b>Battery temperature is TOO high, contact electrical team</b>
                  </Typography>
                }
              >
                <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                  <WarningIcon />
                </SvgIcon>
              </Tooltip>
            ) : (
              row.temperature >= 60 && (
                <Tooltip
                  title={
                    <Typography>
                      <b>Battery temperature is high, keep an eye on it</b>
                    </Typography>
                  }
                >
                  <SvgIcon style={{ color: '#eed202', marginRight: '5px' }}>
                    <WarningIcon />
                  </SvgIcon>
                </Tooltip>
              )
            )}{' '}
            {` ${row.temperature.toFixed(0)}°C | ${celsiusToFahrenheit(row.temperature).toFixed(0)}°F`}
          </StyledTableCell>
          <StyledTableCell align="center">{`${row.voltage.toFixed(1)}V`}</StyledTableCell>
          <StyledTableCell align="center">{`${row.current.toFixed(1)}A`}</StyledTableCell>
          <StyledTableCell align="center">{((Date.now() - row.timestamp * 1000) / 60000).toFixed(1)}m</StyledTableCell>
          <StyledTableCell align="center">{convertMinutesToHoursMinutes(row?.time_remaining_min)}</StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.errors?.length ? (
                    <Typography>
                      {showBatteryErrors ? 'Hide ' : 'Show '}
                      Errors
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.errors?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.errors?.length ? 'red' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryErrors(!showBatteryErrors);
                  }}
                >
                  {row?.errors?.length ? showBatteryErrors ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.errors?.length !== undefined ? row?.errors?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.faults?.length ? (
                    <Typography>
                      {showBatteryFaults ? 'Hide ' : 'Show '}
                      Faults
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.faults?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.faults?.length ? 'orange' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryFaults(!showBatteryFaults);
                  }}
                >
                  {row?.faults?.length ? showBatteryFaults ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.faults?.length !== undefined ? row?.faults?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.warnings?.length ? (
                    <Typography>
                      {showBatteryWarnings ? 'Hide ' : 'Show '}
                      Warnings
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.warnings?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.warnings?.length ? '#eed202' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryWarnings(!showBatteryWarnings);
                  }}
                >
                  {row?.warnings?.length ? showBatteryWarnings ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.warnings?.length !== undefined ? row?.warnings?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">{row?.serial_number ? row?.serial_number : DEFAULT_NO_DATA}</StyledTableCell>
          <StyledTableCell align="center">{row.id}</StyledTableCell>
          <StyledTableCell align="center">{row.type}</StyledTableCell>
        </StyledTableRow>
      )}
      {vanguardBatteryUsed && (
        <StyledTableRow
          style={{
            '& > *': {
              borderBottom: 'unset'
            },
            background: isFaultyBattery ? '#9c7575' : '#ede8e8'
          }}
        >
          <StyledTableCell>
            <Tooltip title={showAllStatus ? 'Hide All Status' : 'Show All Status'}>
              <IconButton
                aria-label="expand row"
                size="small"
                disabled={noStatusMessages}
                onClick={() => {
                  if (checkAnyStatusOpen()) setAllStatus(false);
                  else setAllStatus(true);
                  setShowAllStatus(true);
                }}
              >
                {showAllStatus ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Box>
                <BatteryIcon percentage={row.percentage} />
              </Box>
              {`${row.percentage}%`}
              {row.percentage <= 10 && vanguardBatteryUsed && (
                <Tooltip
                  title={`A VANGUARD battery should never be fully discharged. Power off ${name} until the battery is able to be swapped.`}
                >
                  <Icon
                    aria-label="expand row"
                    size="small"
                    style={{
                      position: 'relative',
                      top: '-4px',
                      right: '-5px',
                      color: `${row.percentage > 5 ? '#eed202' : 'red'}`
                    }}
                  >
                    <WarningOutlinedIcon />
                  </Icon>
                </Tooltip>
              )}
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            {row.temperature >= 65 ? (
              <Tooltip
                title={
                  <Typography>
                    <b>Battery temperature is TOO high, contact electrical team</b>
                  </Typography>
                }
              >
                <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                  <WarningIcon />
                </SvgIcon>
              </Tooltip>
            ) : (
              row.temperature >= 60 && (
                <Tooltip
                  title={
                    <Typography>
                      <b>Battery temperature is high, keep an eye on it</b>
                    </Typography>
                  }
                >
                  <SvgIcon style={{ color: '#eed202', marginRight: '5px' }}>
                    <WarningIcon />
                  </SvgIcon>
                </Tooltip>
              )
            )}{' '}
            {` ${row.temperature.toFixed(0)}°C | ${celsiusToFahrenheit(row.temperature).toFixed(0)}°F`}
          </StyledTableCell>
          <StyledTableCell align="center">{`${row.voltage.toFixed(1)}V`}</StyledTableCell>
          <StyledTableCell align="center">{`${row.current.toFixed(1)}A`}</StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.errors?.length ? (
                    <Typography>
                      {showBatteryErrors ? 'Hide ' : 'Show '}
                      Errors
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.errors?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.errors?.length ? 'red' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryErrors(!showBatteryErrors);
                  }}
                >
                  {row?.errors?.length ? showBatteryErrors ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.errors?.length !== undefined ? row?.errors?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.faults?.length ? (
                    <Typography>
                      {showBatteryFaults ? 'Hide ' : 'Show '}
                      Faults
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.faults?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.faults?.length ? 'orange' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryFaults(!showBatteryFaults);
                  }}
                >
                  {row?.faults?.length ? showBatteryFaults ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.faults?.length !== undefined ? row?.faults?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Tooltip
                title={
                  row?.warnings?.length ? (
                    <Typography>
                      {showBatteryWarnings ? 'Hide ' : 'Show '}
                      Warnings
                    </Typography>
                  ) : (
                    ''
                  )
                }
              >
                <IconButton
                  disabled={!row?.warnings?.length}
                  aria-label="expand row"
                  size="small"
                  style={{ color: `${row?.warnings?.length ? '#eed202' : '#414042'}` }}
                  onClick={() => {
                    setAllStatus(false);
                    setShowBatteryWarnings(!showBatteryWarnings);
                  }}
                >
                  {row?.warnings?.length ? showBatteryWarnings ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                  <Typography variant="body1">{row?.warnings?.length !== undefined ? row?.warnings?.length : DEFAULT_NO_DATA}</Typography>
                </IconButton>
              </Tooltip>
            </Grid>
          </StyledTableCell>
          <StyledTableCell align="center">{row.type}</StyledTableCell>
        </StyledTableRow>
      )}
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={!!(showBatteryErrors && row?.errors?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Errors</Typography>
            </Box>
            {row?.errors?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
          <Collapse in={!!(showBatteryFaults && row?.faults?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Faults</Typography>
            </Box>
            {row?.faults?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
          <Collapse in={!!(showBatteryWarnings && row?.warnings?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Warnings</Typography>
            </Box>
            {row?.warnings?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    percentage: PropTypes.number.isRequired,
    temperature: PropTypes.number.isRequired,
    voltage: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    battery_barcodes: PropTypes.arrayOf(PropTypes.string).isRequired,
    inv_fault_state: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};

export default function BatteryDetails({ componentRef }) {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();
  // Sort the batteryInfo array based on the current usage
  const sortedBatteryInfo = autonomyRobotStore
    .getSelectedRobot()
    ?.robot_state?.control_state?.battery_state?.battery_info?.slice()
    .sort((b, a) => a?.faults?.length - b?.faults?.length)
    .sort((b, a) => a?.errors?.length - b?.errors?.length);
  const vanguardBatteryUsed = sortedBatteryInfo[0].type === 'VANGUARD';
  return (
    <Fade in ref={componentRef}>
      <TableContainer component={Paper} className={classes.containerScrollable}>
        <Table stickyHeader aria-label="collapsible table">
          {!vanguardBatteryUsed && (
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell>
                  <Typography variant="subtitle1">Connected</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Percentage</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Temperature</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Voltage</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Current</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Last Updated</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Time Remaining</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Errors</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Faults</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Warnings</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Serial #</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">ID</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Type</Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
          )}
          {vanguardBatteryUsed && (
            <TableHead>
              <StyledTableRow>
                <StyledTableCell />
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Percentage</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Temperature</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Voltage</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Current</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Errors</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Faults</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Warnings</Typography>
                </StyledTableCell>
                <StyledTableCell align="center">
                  <Typography variant="subtitle1">Type</Typography>
                </StyledTableCell>
              </StyledTableRow>
            </TableHead>
          )}
          <TableBody>
            {sortedBatteryInfo?.map((battery, i) => (
              <Row key={i} row={battery} index={i + 1} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
}
