import React from 'react';
import { IconButton, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';

import FullscreenIcon from '@material-ui/icons/Fullscreen';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useStores } from '../../../store/root/root.store';

const useStyles = makeStyles(() => ({
  fullScreenIconButton: {
    padding: '0px',
    margin: '0px',
    '&:hover': {
      color: '#faaa98',
      backgroundColor: '#202020'
    },
    backgroundColor: '#202020'
  },
  deleteIconButton: {
    padding: '0px',
    margin: '0px',
    '&:hover': {
      color: '#faaa98',
      backgroundColor: '#202020'
    },
    backgroundColor: '#202020'
  },
  displayViewControlContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    margin: '3px'
  }
}));

/**
 * Renders and handles control view for multi-robot view panel.
 * Current functionalty include:
 *  - Resizing multi-robot-view panel into full screen mode (Expand view)
 *  - Removing multi-robot-view panel or robot from multi-robots page (Close or Remove Robot)
 * @param {Function} handleFullScreen Handles expanding multi-robot view into full screen RG view
 * @param {String} serialNumber Serial number of robot corresponding to the current robot in view
 */
const MultiRobotsViewControls = observer(({ handleFullScreen, serialNumber }) => {
  const classes = useStyles();
  const { MultiRobotsStore } = useStores();
  return (
    <div className={classes.displayViewControlContainer}>
      <IconButton aria-label="fullscreen" className={classes.fullScreenIconButton} color="secondary" onClick={() => handleFullScreen()}>
        <FullscreenIcon fontSize="medium" />
      </IconButton>
      <IconButton
        aria-label="delete"
        className={classes.deleteIconButton}
        color="secondary"
        onClick={() => MultiRobotsStore.removeRobot(serialNumber)}
      >
        <HighlightOffIcon fontSize="medium" />
      </IconButton>
    </div>
  );
});

export default MultiRobotsViewControls;
