import { types, getEnv } from 'mobx-state-tree';
import { ApplicationError } from './application-error.model';

export const ApplicationStore = types
  .model('ApplicationStore', {
    errors: types.array(ApplicationError),
    infos: types.array(ApplicationError)
  })
  .views((self) => ({
    getEnvironment() {
      return getEnv(self);
    },
    isDebugLogging() {
      return getEnv(self).debugLogging;
    },
    hasErrors() {
      return !!self.errors?.length > 0;
    },
    getChaperoneBaseUrl() {
      // return "http://localhost:4000";
      return getEnv(self).ChaperoneBaseUrl;
    }
  }))
  .actions((self) => ({
    /**
     * Add errors to this array.  The error-popup component observes changes to this array
     * and will show/deqeue errors as they are added
     * @param {*} title
     * @param {*} message
     */
    pushError(title, message) {
      self.errors.push(ApplicationError.create({ title, message }));
    },
    pushInfo(title, message) {
      self.infos.push(ApplicationError.create({ title, message }));
    },
    removeError(id) {
      self.errors = self.errors.filter((e) => e.id !== id);
    },
    removeInfo(id) {
      self.infos = self.infos.filter((e) => e.id !== id);
    }
  }));
