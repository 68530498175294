import { useEffect } from 'react';
import { ChaperoneRobotConnectionService } from '../services/chaperone/robot-connection.service';

/**
 * Custom hook to manage robot connection services.
 * @param {string} serialNumber - Serial number of the robot.
 * @param {string} userName - Name of the user initiating the connection.
 * @param {Object} isMountedRef - React ref object for tracking if the component is mounted.
 * @param {Object} robotConnectionService - Service for managing robot connections.
 * @param {Object} robotConnection - Current robot connection object.
 * @param {Object} classes - Style classes for UI elements.
 * @param {Object} MultiRobotsStore - Store for handling multiple robot states.
 * @param {Object} applicationStore - Store for managing application-level states and actions.
 * @param {Function} handleConnectionError - Function to handle connection errors.
 * @param {Function} setMainContainerStyleClass - Function to set the main container's style class.
 * @param {Function} setConnectionError - Function to set the connection error state.
 * @param {Function} setIsRightOffset - Function to set the state indicating if the robot is right offset.
 * @param {Function} setIsCurrentPathComplete - Function to set the state indicating if the current path is complete.
 * @param {Function} setIsNextPathInterRow - Function to set the state indicating if the next path is inter-row.
 * @param {boolean} showInterRowConfirmationDialog - Flag to show the inter-row confirmation dialog.
 * @param {Function} setshowInterRowConfirmationDialog - Function to set the visibility of the inter-row confirmation dialog.
 *
 * This hook establishes and manages a WebSocket connection to a robot, handling connection, disconnection, and error states.
 * It also subscribes to various robot state updates and notifications, and updates the application's UI and store accordingly.
 */

export const useRobotConnectionService = async (
  serialNumber,
  userName,
  isMountedRef,
  robotConnectionService,
  robotConnection,
  classes,
  MultiRobotsStore,
  applicationStore,
  handleConnectionError,
  setMainContainerStyleClass,
  setConnectionError,
  setIsNextPathInterRow,
  showInterRowConfirmationDialog,
  setshowInterRowConfirmationDialog,
  setConnectionStatusMessageColor,
  setSelectedRegionId,
  setSelectedPropertyId,
  setSelectedBlockId,
  setSelectedSubBlockId,
  setSelectedPathType
) => {
  useEffect(() => {
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (serialNumber) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onReConnected
                setMainContainerStyleClass(`${classes.container} ${classes.solidGreen}`);
                setConnectionError(false);
                setConnectionStatusMessageColor({ color: '#30E206', message: 'Connected' });
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) => {
                  MultiRobotsStore.updateState(serialNumber, robotState);
                  const pathName = robotState?.robot_state?.navigation_state?.current_path_state?.path_name;
                  const pathType = robotState?.robot_state?.navigation_state?.current_path_state?.path_type;
                  // Monitors for changes in path paramaters and updates them accordingly
                  if (pathName) {
                    const [, pathData] = pathName.split('__');
                    const [regionId, propertyId, blockId, subBlockId] = pathData.split('_');
                    setSelectedRegionId(regionId);
                    setSelectedPropertyId(propertyId);
                    setSelectedBlockId(blockId);
                    setSelectedSubBlockId(subBlockId);
                    setSelectedPathType(pathType);
                  }
                });
                robotConnectionService?.current?.ros.subscribeToRobotNotification((message) =>
                  MultiRobotsStore.updateNotificationMessages(serialNumber, message)
                );
                robotConnectionService?.current?.ros.getUpdatedStaticConfig((updatedStaticParameters) => {
                  if (updatedStaticParameters !== null) {
                    MultiRobotsStore.updateStaticConfigParameters(serialNumber, updatedStaticParameters);
                  }
                });
                robotConnectionService?.current?.ros.getUpdatedDynamicConfig((updatedDynamicParameters) => {
                  if (updatedDynamicParameters !== null) {
                    MultiRobotsStore.updateDynamicConfigParameters(serialNumber, updatedDynamicParameters);
                  }
                });
                robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission((message) =>
                  MultiRobotsStore.updateDoneRepeatingMission(serialNumber, message.data)
                );
                robotConnectionService?.current?.ros.subscribeToAutonomyHealthcheckLockout((message) =>
                  MultiRobotsStore.updateAutonomyHealthcheckLockout(serialNumber, message.data)
                );
                robotConnectionService?.current?.ros.subscribeToIsPathDoneTopic((message) => {
                  if (message !== null) {
                    MultiRobotsStore.updateDoneRepeatingPath(serialNumber, message.data);
                  }
                });
                robotConnectionService?.current?.ros.subscribeToNextPathIsInterRow((message) => {
                  if (message !== null) {
                    setIsNextPathInterRow(message.data);
                  }
                });
                robotConnectionService?.current?.ros.isWebSocketDisconnected((isWebSocketDisconnected) => {
                  if (isWebSocketDisconnected) {
                    applicationStore.pushError(
                      'Error',
                      'You have been disconnected from ' +
                        `${MultiRobotsStore.robots.get(serialNumber)?.name}` +
                        ' by a different remote guardian'
                    );
                    MultiRobotsStore.removeRobot(serialNumber);
                  }
                });
                // To trigger the janus ML if it wasn't active
                MultiRobotsStore.updateSpeedLimit(serialNumber, 0);
                robotConnection.current = robotConnectionService.current;
              },
              () => {
                // onDisconnect
                console.error('Lost connection to robot');
                if (robotConnectionService.current !== null) {
                  setMainContainerStyleClass(`${classes.container} ${classes.flashYellow}`);
                  setConnectionStatusMessageColor({ color: 'yellow', message: 'Re-Connecting' });

                  robotConnectionService?.current?.retryConnection();
                }
                handleConnectionError();
                robotConnection.current = null;
                if (showInterRowConfirmationDialog) {
                  setshowInterRowConfirmationDialog(false);
                }
              },
              serialNumber,
              userName,
              'multi_robot_single_repeat'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        handleConnectionError();
        robotConnectionService.current = null;
        robotConnection.current = null;
        setMainContainerStyleClass(`${classes.container} ${classes.flashRed}`);
        setConnectionStatusMessageColor({ color: 'red', message: 'Disconnected' });
      }
    };
    // eslint-disable-next-line
  }, []);
};
export default useRobotConnectionService;
