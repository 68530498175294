import React from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';

const ReloadIcon = ({ title, handler, style, isLoading }) => (
  <Button
    title={title}
    startIcon={isLoading ? <CircularProgress color="secondary" /> : <CachedRoundedIcon />}
    onClick={handler}
    style={style}
  />
);

export default ReloadIcon;
