import React, { useState } from 'react';
import { Button, Grid, makeStyles, TableCell, Typography, Fade, IconButton, Tooltip } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { ROUTE_ADMIN_NEW_USER } from '../routes';
import { StripedTable } from '../../components/core/striped-table.component';
import { useUsers } from './useUsers';
import { StripedTableRow } from '../../components/core/striped-table-row.component';
import { Link } from 'react-router-dom';
import { EditRounded, InfoRounded, DeleteRounded } from '@material-ui/icons';
import { UserInfoDialog } from '../../components/dialogs/UserInfoDialog';
import ConfirmActionDialog from '../../components/dialogs/confirm-action.dialog';
import { EditUserDialog } from '../../components/dialogs/EditUserDialog';
import { deleteUser, editUser } from '../../services/api/users.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  titleLabel: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  table: {
    marginTop: theme.spacing(2)
  },
  icon: {
    color: 'black',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

export const ManageUsersPage = observer(() => {
  const [selectedUser, setSelectedUser] = useState(null);
  const [showInfoDialog, setShowInfoDialog] = useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const classes = useStyles();
  const [users, refetchUsers] = useUsers();
  const headers = ['Actions', 'User Name', 'Role', 'Email'];

  const handleDelete = async (user) => {
    setSelectedUser(user);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    await deleteUser(selectedUser);
    setShowDeleteDialog(false);
    refetchUsers();
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditDialog(true);
  };

  const confirmEdit = async (user) => {
    await editUser(user);
    setShowEditDialog(false);
    refetchUsers();
  };

  const handleShow = (user) => {
    setSelectedUser(user);
    setShowInfoDialog(true);
  };

  return (
    <Fade in={true}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item xs={10}>
          <Typography variant="h3" className={classes.titleLabel}>
            Manage Users
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button size="large" className={classes.button} component={Link} to={ROUTE_ADMIN_NEW_USER} variant="contained">
            Add new user
          </Button>
        </Grid>
        <br />
        <Grid item xs={12} className={classes.table}>
          <StripedTable headers={headers} scrollable>
            {users.map((user) => (
              <StripedTableRow key={user.sub}>
                <TableCell>
                  <Grid container spacing={1}>
                    <Grid item>
                      <Tooltip title={<Typography>Details</Typography>}>
                        <IconButton size="small" className={classes.icon} onClick={(_) => handleShow(user)}>
                          <InfoRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<Typography>Delete</Typography>}>
                        <IconButton size="small" className={classes.icon} onClick={(_) => handleDelete(user)}>
                          <DeleteRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={<Typography>Edit</Typography>}>
                        <IconButton size="small" className={classes.icon} onClick={(_) => handleEdit(user)}>
                          <EditRounded />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>{user['custom:username']}</TableCell>
                <TableCell>{user['custom:Role']}</TableCell>
                <TableCell>{user.email}</TableCell>
              </StripedTableRow>
            ))}
          </StripedTable>
        </Grid>
        {showInfoDialog && <UserInfoDialog user={selectedUser} show={showInfoDialog} onClose={() => setShowInfoDialog(false)} />}
        {showDeleteDialog && (
          <ConfirmActionDialog
            handleAction={confirmDelete}
            handleClose={() => setShowDeleteDialog(false)}
            title="Delete User"
            body="Are you sure you want to delete this user?"
            open={showDeleteDialog}
          />
        )}
        {showEditDialog && (
          <EditUserDialog handleEdit={confirmEdit} handleClose={() => setShowEditDialog(false)} open={showEditDialog} user={selectedUser} />
        )}
      </Grid>
    </Fade>
  );
});
