import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'Monospace',
    fontSize: 'h5',
    fontWeight: 'bold'
  },
  dialogContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: theme.spacing(2)
  },
  dialogActions: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  confirmButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark
    }
  }
}));

/**
 * @Component
 *
 * Modal component for alerts
 * @param {Boolean} open controls component rendering
 * @param {String} title modal title
 * @param {String} body modal message body
 * @param {Function} onClose handler for closing alert
 * @returns Component
 */
const AlertModal = ({ open, title, body, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} className={classes.dialog} hideBackdrop style={{ position: 'absolute' }}>
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      <DialogContent className={classes.dialogContent}>{body}</DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={() => onClose()} className={classes.confirmButton}>
          {' '}
          Close{' '}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
export default AlertModal;
