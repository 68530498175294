/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Button, Menu, MenuItem, Fade } from '@material-ui/core';
import { Link, useHistory } from 'react-router-dom';
import RobotsTable from '../components/tables/RobotsTable';
import { USE_CASES } from '../utils/constants';
import {
  ROUTE_MANAGE_ROBOTS,
  ROUTE_AUDIT,
  ROUTE_AUTONOMY_AUDIT_REPORTS,
  ROUTE_AUDIT_OFFICER,
  ROUTE_CHAPERONE,
  ROUTE_DECLARE_EVENT,
  ROUTE_DIAGNOSTICS,
  ROUTE_HOME,
  ROUTE_REMOTE_GUARDIAN,
  ROUTE_SOLAR_CHAPERONE,
  ROUTE_SOLAR_REMOTE,
  ROUTE_ROUTE_SELECTION,
  ROUTE_CHAPERONE_ESTOP
} from './routes';
import { useStores } from '../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.inverted.main
  },
  button: {
    color: theme.palette.inverted.main,
    backgroundColor: '#394043',
    padding: '12px 32px',
    fontSize: '15px',
    fontWeight: 'bold',
    opacity: '.7',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      opacity: '1'
    }
  },
  menuItem: {
    padding: '12px 32px',
    justifyContent: 'center',
    fontSize: '15px',
    fontWeight: 'bold',
    color: '#394043',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main
    }
  },
  background: {
    height: '100%',
    padding: theme.spacing(4)
  }
}));

export const HomePage = () => {
  const { regionsStore, chaperonePropertyStore, sectionStore, subsectionStore, autonomyRobotStore, authenticationStore } = useStores();
  const isAdmin = authenticationStore.role === 'admin';
  const isManager = authenticationStore.role === 'manager';
  const classes = useStyles();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [auditOfficerAnchorEl, setAuditOfficerAnchorEl] = useState(null);
  const [auditReportsAnchorEl, setAuditReportsAnchorEl] = useState(null);
  const [selectedUseCase, setSelectedUseCase] = React.useState(null);
  const open = Boolean(anchorEl);
  const [openAuditOfficer, setOpenAuditOfficer] = useState(false);
  const [openAuditReports, setOpenAuditReports] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedUseCase(null);
  };

  const AUDIT_NAME = 'audit reports';

  const useCases = [
    /* {
      name: USE_CASES.SNOW_PLOWING,
      url: [
        { url: ROUTE_CHAPERONE, name: 'Chaperone' },
        { url: ROUTE_REMOTE_GUARDIAN, name: 'Remote Guardian' }
      ]
    }, */
    {
      name: USE_CASES.SOLAR_LAWN_MOWING,
      url: [
        { url: ROUTE_SOLAR_CHAPERONE, name: 'Chaperone' },
        { url: ROUTE_SOLAR_REMOTE, name: 'Remote Guardian' }
      ]
    }
  ];
  const services = [
    {
      name: 'manage robots',
      url: ROUTE_MANAGE_ROBOTS,
      permission: 'admin'
    },
    {
      name: 'audit officer',
      url: ROUTE_AUDIT_OFFICER,
      permission: 'admin'
      /* [
        { url: ROUTE_AUDIT_OFFICER, name: 'Snow-Auditing', permission: 'all' },
        { url: ROUTE_AUDIT_OFFICER, name: 'Solar-Auditing', permission: 'admin' }
      ] */
    },
    {
      name: AUDIT_NAME,
      url: [
        { url: ROUTE_AUDIT, name: 'Operations Audit Reports' },
        { url: ROUTE_AUTONOMY_AUDIT_REPORTS, name: 'Autonomy Audit Reports' }
      ],
      permission: 'admin'
    },
    /* {
      name: 'declare event',
      url: ROUTE_DECLARE_EVENT,
      permission: 'manager'
    }, */
    {
      name: 'diagnostics',
      url: ROUTE_DIAGNOSTICS,
      permission: 'all'
    }
  ];

  const handleUseCase = (useCase) => {
    setSelectedUseCase(useCase);
    const selectedUseCase = Object.keys(USE_CASES).find((key) => USE_CASES[key] === useCase);
    subsectionStore.setUseCase(selectedUseCase);
    localStorage.setItem('useCase', selectedUseCase);
  };
  /**
   * Renders Manage Robot button if user is admin
   * @param {*} service
   * @returns Button component with the service name and url
   */
  const renderManageRobotsButton = (service) => {
    if (isAdmin || service?.permission !== 'admin') {
      return (
        <Grid item key={service.name}>
          <Button size="large" className={classes.button} component={Link} to={service.url} variant="contained">
            {service.name}
          </Button>
        </Grid>
      );
    }
    return null;
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    bgBox.classList.remove('bg-non-solar', 'bg-snow');
    bgBox.classList.add('bg-solar');
    fetchRobots();
    const hasReloaded = sessionStorage.getItem('hasReloaded');
    
    // Trigger a hard reload only if it hasn't already been done
    if (!hasReloaded) {
      sessionStorage.setItem('hasReloaded', 'true');
      window.location.reload(true);
    }

    // Clean up session storage when the component is unmounted
    return () => {
      sessionStorage.removeItem('hasReloaded');
    };
  }, []);

  const fetchRobots = async () => {
    autonomyRobotStore.clearSelectedRobot();
    await autonomyRobotStore.getRobots();
  };
  
  return (
    <Fade in={true}>
      <Grid container direction="row" justifyContent="center" alignItems="flex-start" className={classes.background}>
        <Grid container direction="column" justifyContent="center" alignItems="center" spacing={10}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              Swap Systems
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {useCases.map((useCase) =>
              typeof useCase.url !== 'string' ? (
                <Grid item key={useCase.name}>
                  <Button
                    size="large"
                    className={classes.button}
                    onClick={(e) => {
                      handleClick(e);
                      handleUseCase(useCase.name);
                    }}
                    variant="contained"
                  >
                    {useCase.name}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open && selectedUseCase === useCase.name}
                    onClose={handleClose}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {useCase.url.map((element) => (
                      <MenuItem
                        key={useCase.name + element.name}
                        className={classes.menuItem}
                        onClick={() => {
                          regionsStore.clearSelectedRegion();
                          chaperonePropertyStore.clearSelectedProperty();
                          sectionStore.clearSelectedSection();
                          history.push(element.url);
                        }}
                      >
                        {element.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              ) : (
                <Grid item key={useCase.name}>
                  <Button
                    size="large"
                    className={classes.button}
                    onClick={() => handleUseCase(useCase.name)}
                    component={Link}
                    to={useCase.url}
                    variant="contained"
                  >
                    {useCase.name}
                  </Button>
                </Grid>
              )
            )}
            <Grid item>
              {/* <Button
                size="large"
                className={classes.button}
                onClick={(e) => {
                  handleClick(e);
                  history.push(ROUTE_ROUTE_SELECTION);
                }}
                variant="contained"
              >
                Route Following
              </Button> */}
            </Grid>
            <Grid item>
              <Button
                size="large"
                className={classes.button}
                onClick={(e) => {
                  handleClick(e);
                  history.push(ROUTE_CHAPERONE_ESTOP);
                }}
                variant="contained"
              >
                Chaperone E-stop
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              Swap Services
            </Typography>
          </Grid>
          <Grid container direction="row" justifyContent="center" alignItems="center" spacing={4}>
            {services.map((service) =>
              typeof service.url !== 'string' ? (
                <Grid item key={service.name}>
                  <Button
                    size="large"
                    className={classes.button}
                    onClick={(e) => {
                      if (service.name === AUDIT_NAME) {
                        setOpenAuditReports(true);
                        setAuditReportsAnchorEl(e.currentTarget);
                      } else {
                        setOpenAuditOfficer(true);
                        setAuditOfficerAnchorEl(e.currentTarget);
                      }
                    }}
                    variant="contained"
                  >
                    {service.name}
                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={service.name === AUDIT_NAME ? auditReportsAnchorEl : auditOfficerAnchorEl}
                    open={service.name === AUDIT_NAME ? openAuditReports : openAuditOfficer}
                    onClose={() => {
                      if (service.name === AUDIT_NAME) {
                        setOpenAuditReports(false);
                        setAuditReportsAnchorEl(null);
                      } else {
                        setOpenAuditOfficer(false);
                        setAuditOfficerAnchorEl(null);
                      }
                    }}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button'
                    }}
                  >
                    {service.url.map((element) => (
                      <MenuItem
                        key={service.name + element.name}
                        className={classes.menuItem}
                        onClick={() => {
                          if (service.name !== AUDIT_NAME) {
                            const useCase =
                              element.name.includes('snow') || element.name.includes('Snow')
                                ? USE_CASES.SNOW_PLOWING
                                : USE_CASES.SOLAR_LAWN_MOWING;
                            handleUseCase(useCase);
                          }
                          history.push(element.url);
                        }}
                      >
                        {element.name}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              ) : (
                renderManageRobotsButton(service)
              )
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.title}>
              Swap Robots
            </Typography>
          </Grid>
          <RobotsTable selectable location={ROUTE_HOME} realTimeUpdate />
        </Grid>
      </Grid>
    </Fade>
  );
};
