import { Card, CardActions, CardContent, CardHeader, Drawer, Hidden, Icon, IconButton, makeStyles, Popper } from '@material-ui/core';
import { Close, CloseOutlined, OpenInNew, PhotoLibrary } from '@material-ui/icons';
import { format } from 'date-fns';
import React, { useState } from 'react';
import { useEnvironment } from '../../environment';
import { useStores } from '../../store/root/root.store';
import { LabelledValue } from '../core/labelled-value.component';
import { AuditImageList } from './audit-image-list.component';
import MapMarkerIcon from '../../assets/images/audit-map-marker.png';

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: '15px',
    border: `1px solid ${theme.palette.grey[500]}`
  },
  marker: (props) => ({
    width: props?.width || '16px',
    height: props?.height || '16px',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  }),
  info: {
    padding: theme.spacing(2),
    maxWidth: '300px'
  },
  intoTitle: {
    fontWeight: theme.typography.fontWeightBold
  },
  actions: {
    marginLeft: 'auto'
  }
}));

export const AuditMapMarker = ({ reportRecord, ...props }) => {
  const classes = useStyles();

  const { auditReportsStore } = useStores();
  const { defaultDateTimeFormat } = useEnvironment();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleSelection = (e) => {
    auditReportsStore.selectRecord(reportRecord);
    e.stopPropagation();
  };

  const popperOpen = (e) => {
    setAnchorEl(e.currentTarget);
    e.stopPropagation();
  };

  const popperClose = (e) => {
    setAnchorEl(null);
    e.stopPropagation();
  };

  return (
    <>
      <Icon
        className={classes.marker}
        onClick={handleSelection}
        onMouseEnter={popperOpen}
        onTouchEnd={popperOpen} // Mobile
        onMouseLeave={popperClose}
      >
        <img alt="Audit marker" src={MapMarkerIcon} height={10} width={10} />
      </Icon>
      {anchorEl !== null ? (
        <Drawer anchor="bottom" open={drawerOpen}>
          <IconButton onClick={() => setDrawerOpen(false)}>
            <CloseOutlined />
          </IconButton>
          <AuditImageList images={reportRecord.mediaSources} />
        </Drawer>
      ) : null}
    </>
  );
};
