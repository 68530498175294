import React from 'react';
import { observer } from 'mobx-react-lite';
import { Typography, Box, makeStyles } from '@material-ui/core';
import { celsiusToFahrenheit, convertMinutesToHoursMinutes } from '../../utils/ui.utils';

const useStyle = makeStyles(() => ({
  mainContainer: {
    border: '1px solid grey',
    margin: '8px 5px',
    boxSizing: 'border-box',
    paddingLeft: '4px'
  },
  subContainer: {
    display: 'flex'
  },
  batteryDataText: {
    fontWeight: 'bolder',
    marginLeft: '2px',
    fontSize: '1.2em'
  },
  faultText: {
    fontWeight: 'bold',
    marginLeft: '2px',
    fontSize: '1.1em'
  }
}));

/**
 * Component responsible for showing battery state for individiaul battery in robot state on Ops Audit
 * @param {Object} battery Data for a single battery
 */
const OperationsAuditBatteryDetails = observer(({ battery }) => {
  const classes = useStyle();

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.subContainer}>
        Serial #:
        <Typography className={classes.batteryDataText}>{battery?.serial_number || 'No Data'}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Percentage (SOC):
        <Typography className={classes.batteryDataText}>{`${battery?.percentage}%` || 'No Data'}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Temperature:
        <Typography className={classes.batteryDataText}>
          {`${battery?.temperature?.toFixed(1)}°C | ${celsiusToFahrenheit(battery?.temperature).toFixed(1)}°F` || 'No Data'}
        </Typography>
      </Box>
      <Box className={classes.subContainer}>
        Voltage:
        <Typography className={classes.batteryDataText}>{`${battery?.voltage?.toFixed(1)}V` || 'No Data'}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Current:
        <Typography className={classes.batteryDataText}>{`${battery?.current?.toFixed(1)}A` || 'No Data'}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Type:
        <Typography className={classes.batteryDataText}>{battery?.type || 'No Data'}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Time Remaining:
        <Typography className={classes.batteryDataText}>{convertMinutesToHoursMinutes(battery?.time_remaining_min)}</Typography>
      </Box>
      <Box className={classes.subContainer}>
        Errors, Faults, or Warnings:
        {battery?.errors.length > 0 || battery?.faults.length > 0 || battery?.warnings.length > 0 ? (
          <Box>
            <Box className={classes.subContainer}>
              {battery?.errors.length > 0 &&
                battery?.errors?.map((err, i) => (
                  <Typography className={classes.batteryDataText} key={`error-${i + 1}`}>
                    {err}
                  </Typography>
                ))}
            </Box>
            <Box className={classes.subContainer}>
              {battery?.faults.length > 0 &&
                battery?.faults?.map((falult, i) => (
                  <Typography className={classes.batteryDataText} key={`error-${i + 1}`}>
                    {falult}
                  </Typography>
                ))}
            </Box>
            <Box className={classes.subContainer}>
              {battery?.warnings.length > 0 &&
                battery?.warnings?.map((warning, i) => (
                  <Typography className={classes.batteryDataText} key={`error-${i + 1}`}>
                    {warning}
                  </Typography>
                ))}
            </Box>
          </Box>
        ) : (
          'No data'
        )}
      </Box>
    </Box>
  );
});

export default OperationsAuditBatteryDetails;
