import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormControl, Grid, makeStyles, MenuItem, Select, Typography, Button, Fade, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useStores } from '../../store/root/root.store';
// import LocationsTable from '../components/tables/locations.table';
import LocationsTable, {
  LOCATION_REGION,
  LOCATION_PROPERTY,
  LOCATION_SECTION,
  LOCATION_BLOCK,
  LOCATION_SUBBLOCK
  // LOCATION_SUBSECTION
} from '../../components/tables/LocationsTable';
import { ROUTE_ADMIN_NEW_LOCATION } from '../routes';
import { resetBlockSubrows } from '../../services/api/blocks.service';
import ConfirmActionDialog from '../../components/dialogs/confirm-action.dialog';
import { resetPropertySubrows } from '../../services/api/property.service';
import { SUBROW_RESET_TYPE } from '../../utils/constants';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250,
    marginTop: theme.spacing(4)
  },
  tabelLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle'
  },
  teachButton: {
    marginTop: theme.spacing(4),
    marginDown: theme.spacing(0),
    height: 80,
    width: 200,
    backgroundColor: '##0000FF',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  },
  titleLabelWithButton: {
    color: theme.palette.primary.contrastText
  },
  titleLabelAndButtonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
    justifyContent: 'space-between'
  },
  actionButton: {
    maxWidth: 'max-content',
    margin: '5px 0px',
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: '#cf716d'
    },
    fontWeight: 'bold'
  },
  resetButtonsContainer: {
    width: '31%',
    maxWidth: '31%',
    display: 'flex',
    justifyContent: 'space-between'
  }
}));

export const ManageLocationsPage = observer(() => {
  const { regionsStore, sectionStore, chaperonePropertyStore, countriesStore, blocksStore, subBlocksStore, applicationStore } = useStores();
  const classes = useStyles();

  const { selectedCountryId } = countriesStore;
  const { selectedRegionId } = regionsStore;
  const { selectedPropertyId } = chaperonePropertyStore;
  const { selectedBlockId } = blocksStore;
  const selectedBlockName = blocksStore?.getSelectedBlock()?.name;
  const selectedPropertyName = chaperonePropertyStore?.getSelectedProperty()?.name;
  const isSolarBlockSelected = selectedBlockId !== null;
  const isSolarPropertySelected = selectedPropertyId !== null;
  const [showResetBlocCutStatusWarningDialog, setShowResetBlocCutStatusWarningDialog] = useState(false);
  const [showResetPropertyWarningDialog, setShowResetPropertyWarningDialog] = useState(false);
  const [showResetBlockCleanupStatusDialog, setShowResetBlockCleanupDialog] = useState(false);
  const [showResetPropertyCleanupStatusDialog, setShowResetPropertyCleanupDialog] = useState(false);

  useEffect(() => {
    async function init() {
      countriesStore.clearSelectedCountry();
      regionsStore.clearRegions();
      regionsStore.clearSelectedRegion();
      chaperonePropertyStore.clearProperties();
      chaperonePropertyStore.setSelectedProperty(null);
      sectionStore.clearSections();
      sectionStore.setSelectedSection(null);
      blocksStore.clearBlocks();
      blocksStore.setSelectedBlock(null);
      subBlocksStore.clearSubBlocks();
      subBlocksStore.setSelectedSubBlock(null);
      await countriesStore.getCountries();
    }
    init();
  }, []);

  useEffect(() => {
    async function getRegionsByCountry() {
      if (Number(selectedCountryId)) {
        await regionsStore.getRegions(selectedCountryId);
      } else {
        regionsStore.clearRegions();
        chaperonePropertyStore.clearProperties();
        sectionStore.clearSections();
      }
    }
    getRegionsByCountry();
  }, [selectedCountryId]);

  useEffect(() => {
    async function getRegions() {
      if (Number(selectedRegionId)) {
        await chaperonePropertyStore.getProperties(selectedRegionId);
      } else {
        chaperonePropertyStore.clearProperties();
        sectionStore.clearSections();
      }
    }
    getRegions();
  }, [selectedRegionId]);

  useEffect(() => {
    async function getSections() {
      if (Number(selectedPropertyId)) {
        await sectionStore.getSections(selectedRegionId, selectedPropertyId);
        await blocksStore.getBlocks(selectedPropertyId);
      } else {
        sectionStore.clearSections();
        blocksStore.clearBlocks();
      }
    }
    getSections();
  }, [selectedPropertyId]);

  useEffect(() => {
    async function getSubBlocks() {
      if (Number(selectedBlockId)) {
        await subBlocksStore.getSubBlocks(selectedBlockId);
      } else {
        subBlocksStore.clearSubBlocks();
      }
    }
    getSubBlocks();
  }, [selectedBlockId]);

  /**
   * Function handles reseting cut status or clean up status for an entire property or entire block
   * @param {*} resetType Fields to reset, ie cut status or clean up status
   */
  const handleResetSubrowsStatus = async (resetType = '') => {
    try {
      if (selectedPropertyId && !selectedBlockId) {
        const formattedProperyId = parseInt(selectedPropertyId);
        const result = await resetPropertySubrows(formattedProperyId, resetType);
        if (result?.status === 'success') {
          applicationStore.pushInfo('Success', `All subrows in ${selectedPropertyName?.toUpperCase()} have been reset`);
        } else {
          applicationStore.pushError(
            'Error',
            `There was an error reseting ${selectedBlockName?.toUpperCase()}. Please report this to the Autonomy Team if the issue persists`
          );
        }
        setShowResetPropertyWarningDialog(false);
        setShowResetPropertyCleanupDialog(false);
      }
      if (selectedBlockId && selectedPropertyId) {
        const formattedBlockId = parseInt(selectedBlockId);
        const result = await resetBlockSubrows(formattedBlockId, resetType);
        if (result?.data?.status === 'success') {
          applicationStore.pushInfo('Success', `All subrows in ${selectedBlockName?.toUpperCase()} have been reset`);
        } else {
          applicationStore.pushError(
            'Error',
            `There was an error reseting ${selectedBlockName?.toUpperCase()}. Please report this to the Autonomy Team if the issue persists`
          );
        }
        setShowResetBlocCutStatusWarningDialog(false);
        setShowResetBlockCleanupDialog(false);
      }
    } catch (e) {
      console.error(`Encountered the following error while reseting subrows: ${e}`);
      applicationStore.pushError('Error', 'Encountered an error with this operation. Please report this to the Autonomy Team.');
    }
  };

  return (
    <Fade in>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        <ConfirmActionDialog
          title="Reset Block Cut Status?"
          open={showResetBlocCutStatusWarningDialog}
          key="reset-block"
          action="Confirm"
          body={`You are about to reset all subrows within ${selectedBlockName?.toUpperCase()} to NOT-STARTED. Are you sure you want to proceed?`}
          handleClose={() => {
            setShowResetBlocCutStatusWarningDialog(false);
          }}
          handleAction={() => {
            handleResetSubrowsStatus(SUBROW_RESET_TYPE.CUT_STATUS);
          }}
          backdropProps={{ style: { position: 'absolute' } }}
        />
        <ConfirmActionDialog
          title="Reset Property Cut Status?"
          open={showResetPropertyWarningDialog}
          key="reset-property"
          action="Confirm"
          body={`You are about to reset all subrows within ${selectedPropertyName?.toUpperCase()} to NOT-STARTED. Are you sure you want to proceed?`}
          handleClose={() => {
            setShowResetPropertyWarningDialog(false);
          }}
          handleAction={() => {
            handleResetSubrowsStatus(SUBROW_RESET_TYPE.CUT_STATUS);
          }}
          backdropProps={{ style: { position: 'absolute' } }}
        />
        <ConfirmActionDialog
          title="Reset Block Cleaned up Status?"
          open={showResetBlockCleanupStatusDialog}
          key="reset-block-cleanup"
          action="Confirm"
          body={`You are about to reset CLEANED UP status of all subrows within ${selectedBlockName?.toUpperCase()}. Are you sure you want to proceed?`}
          handleClose={() => {
            setShowResetBlockCleanupDialog(false);
          }}
          handleAction={() => {
            handleResetSubrowsStatus(SUBROW_RESET_TYPE.CLEANED_UP);
          }}
          backdropProps={{ style: { position: 'absolute' } }}
        />
        <ConfirmActionDialog
          title="Reset Property Cleaned up Status?"
          open={showResetPropertyCleanupStatusDialog}
          key="reset-property-cleanup"
          action="Confirm"
          body={`You are about to reset CLEANED UP status of all subrows within  ${selectedPropertyName?.toUpperCase()}. Are you sure you want to proceed?`}
          handleClose={() => {
            setShowResetPropertyCleanupDialog(false);
          }}
          handleAction={() => {
            handleResetSubrowsStatus(SUBROW_RESET_TYPE.CLEANED_UP);
          }}
          backdropProps={{ style: { position: 'absolute' } }}
        />
        {/* locations */}
        <Grid item xs={3}>
          <Grid item>
            <Typography variant="h3" className={classes.titleLable}>
              Manage Locations
            </Typography>
          </Grid>
        </Grid>

        <Grid item xs={9}>
          <Grid container justifyContent="flex-end">
            <Button className={classes.teachButton} variant="contained" component={Link} to={ROUTE_ADMIN_NEW_LOCATION} color="secondary">
              Add Location
            </Button>
          </Grid>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <FormControl className={classes.formControl}>
            <Typography variant="h4" className={classes.tabelLable}>
              Country:
            </Typography>
            <Select
              className={classes.select}
              value={countriesStore.getSelectedCountry().id || 'NONE'}
              onClick={(e) => {
                if (e.target.value && selectedCountryId !== e.target.value) {
                  countriesStore.setSelectedCountry(Number(e.target.value) || null);
                  regionsStore.setSelectedRegion(null);
                  chaperonePropertyStore.setSelectedProperty(null);
                  sectionStore.setSelectedSection(null);
                }
              }}
            >
              <MenuItem key="NONE" value="NONE">
                <em>NONE</em>
              </MenuItem>
              {countriesStore.countries.map((location) => (
                <MenuItem key={location.id} value={location.id}>
                  {location.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        {/* Regions */}
        {selectedCountryId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.titleLable}>
                Regions:
              </Typography>
            </Grid>
            <LocationsTable locationType={LOCATION_REGION} />
          </Grid>
        )}

        {/* Properties */}
        {selectedRegionId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Box className={classes.titleLabelAndButtonsContainer}>
              <Typography className={classes.titleLabelWithButton} variant="h4">
                Properties:
              </Typography>
              <Box className={classes.resetButtonsContainer}>
                <Button
                  onClick={() => setShowResetPropertyWarningDialog(true)}
                  disabled={!isSolarPropertySelected}
                  className={classes.actionButton}
                >
                  Reset Solar Property Cut Status
                </Button>
                <Button
                  onClick={() => setShowResetPropertyCleanupDialog(true)}
                  disabled={!isSolarPropertySelected}
                  className={classes.actionButton}
                >
                  Reset Solar Property Cleanup Status
                </Button>
              </Box>
            </Box>
            <LocationsTable locationType={LOCATION_PROPERTY} />
          </Grid>
        )}

        {/* Sections & Blocks */}
        {selectedPropertyId && (
          <>
            <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
              <Grid item xs={12}>
                <Typography variant="h4" className={classes.titleLable}>
                  Snow Sections:
                </Typography>
              </Grid>
              <LocationsTable locationType={LOCATION_SECTION} />
            </Grid>
            <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
              <Box className={classes.titleLabelAndButtonsContainer}>
                <Typography className={classes.titleLabelWithButton} variant="h4">
                  Solar Blocks:
                </Typography>
                <Box className={classes.resetButtonsContainer}>
                  <Button
                    onClick={() => setShowResetBlocCutStatusWarningDialog(true)}
                    disabled={!isSolarBlockSelected}
                    className={classes.actionButton}
                  >
                    Reset Solar Block Cut Status
                  </Button>
                  <Button
                    onClick={() => setShowResetBlockCleanupDialog(true)}
                    disabled={!isSolarBlockSelected}
                    className={classes.actionButton}
                  >
                    Reset Solar Block Cleanup Status
                  </Button>
                </Box>
              </Box>

              <LocationsTable locationType={LOCATION_BLOCK} />
            </Grid>
          </>
        )}
        {/* Sub Blocks */}
        {selectedBlockId && (
          <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
            <Grid item xs={12}>
              <Typography variant="h4" className={classes.titleLable}>
                Sub Blocks:
              </Typography>
            </Grid>
            <LocationsTable locationType={LOCATION_SUBBLOCK} />
          </Grid>
        )}
      </Grid>
    </Fade>
  );
});
