// import { makeStyles } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';

import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import InputLabel from '@material-ui/core/InputLabel';

export const MapSwitch = ({ mapType, handleToggleMap }) => {
  return useObserver(() => (
    <ToggleButtonGroup
      style={{ width: '100%' }}
      exclusive
      value={mapType}
      onChange={(_, selectedMapType) => {
        if (selectedMapType !== null) {
          handleToggleMap(selectedMapType);
        }
      }}
      aria-label="text formatting"
    >
      {/* Note: No longer used for now */}
      {/* <ToggleButton color="primary" value="gridMap" style={{ width: '100%' }}>
        <InputLabel style={{ fontWeight: 'bold', fontSize: '0.78rem' }}> Grid</InputLabel>
      </ToggleButton> */}
      <ToggleButton color="primary" value="googleMap" style={{ width: '100%' }}>
        <InputLabel style={{ fontWeight: 'bold', fontSize: '0.78rem' }}> Google</InputLabel>
      </ToggleButton>
      <ToggleButton color="primary" value="mapkit" style={{ width: '100%' }}>
        <InputLabel style={{ fontWeight: 'bold', fontSize: '0.78rem' }}> Apple</InputLabel>
      </ToggleButton>
    </ToggleButtonGroup>
  ));
};
