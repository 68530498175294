import React from 'react';
import { CircularProgress, Dialog, DialogTitle, DialogContent } from '@material-ui/core';

const LoadingDialog = ({ show, style, message, maxWidth, containerRef, backdropProps }) => (
  <Dialog
    open={show}
    style={style}
    BackdropProps={backdropProps}
    container={containerRef === undefined || !containerRef ? null : containerRef.current}
    maxWidth={maxWidth}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    {message && <DialogTitle style={{ alignSelf: 'center' }}>{message}</DialogTitle>}
    <DialogContent style={{ alignSelf: 'center' }}>
      <CircularProgress color="secondary" />
    </DialogContent>
  </Dialog>
);
export default LoadingDialog;
