import { types } from 'mobx-state-tree';

export const MotorState = types.model({
  id: types.maybeNull(types.string),
  motor_type: types.maybeNull(types.string),
  rpm: types.maybeNull(types.number),
  current: types.maybeNull(types.number),
  motor_temp: types.maybeNull(types.number),
  status: types.maybeNull(types.number),
  fault: types.maybeNull(types.string)
});
