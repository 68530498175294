/* eslint-disable no-confusing-arrow */
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Typography, Fade, Button, Box } from '@material-ui/core';
import RobotsTable from '../components/tables/RobotsTable';
import { ROUTE_CHAPERONE_ESTOP } from './routes';
import { useStores } from '../store/root/root.store';
import { EStopControl } from '../components/control/e-stop-control';
import { INTERNET_CONNECTION_ERROR_MESSAGE, OFFLINE } from '../utils/constants';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(2)
  },
  title: {
    color: theme.palette.inverted.main,
    marginBottom: theme.spacing(2)
  },
  control: {
    width: '20%',
    marginBottom: theme.spacing(2)
  },
  btn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(2)
  },
  propertyText: {
    color: 'black'
  },
  propertySelectionBox: {
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  propertySelect: {
    color: 'black'
  }
}));

export const ChaperoneEstopPage = observer(() => {
  const { autonomyRobotStore, MultiRobotsStore, chaperonePropertyStore } = useStores();
  const classes = useStyles();
  const { robots, selectedRobots } = autonomyRobotStore;
  const selectedPropertiesIds = localStorage.getItem('selected-property-filter');
  const formattedSelectedPropertiesIds = selectedPropertiesIds?.split(',').map((property) => parseInt(property));
  const [allEstop, setAllEstop] = useState(false);
  const [isOffline, setIsOffline] = useState(false);
  const userEmail = localStorage.getItem('userEmail');

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    bgBox.classList.remove('bg-non-solar', 'bg-snow');
    bgBox.classList.add('bg-solar');
  }, []);

  const fetchRobots = async () => {
    autonomyRobotStore.clearSelectedRobot();
    await autonomyRobotStore.getRobots();
    robots.forEach((robot) => {
      if (
        robot.status !== OFFLINE &&
        formattedSelectedPropertiesIds?.includes(robot.current_property_id) &&
        !selectedRobots.includes(robot.serial_number)
      ) {
        autonomyRobotStore.setSelectedRobots(robot.serial_number);
      }
    });
  };
  useEffect(() => {
    fetchRobots();
    chaperonePropertyStore.getProperties('all');
  }, []);

  const getSelectedRobots = () => {
    MultiRobotsStore.resetRobots();
    robots.forEach((robot) => {
      const isRobotSelected = selectedRobots.find((robotSN) => robot.serial_number === robotSN);
      if (isRobotSelected) {
        MultiRobotsStore.addRobot({
          id: robot.id,
          serialNumber: robot.serial_number,
          status: robot.status,
          useCase: robot.use_case,
          name: robot.name
        });
      }
    });
  };

  useEffect(() => {
    getSelectedRobots();
    return () => {
      autonomyRobotStore.resetSelectedRobots();
      MultiRobotsStore.resetRobots();
    };
  }, []);

  useEffect(() => {
    if (selectedRobots.length) {
      getSelectedRobots();
    } else {
      MultiRobotsStore.resetRobots();
    }
  }, [selectedRobots.length]);

  const handleAllEstop = () => {
    setAllEstop(!allEstop);
  };

  // Monitors internet connection and displays connection error
  useEffect(() => {
    const handleOnline = () => {
      setIsOffline(false);
    };
    const handleOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return (
    <Fade in>
      <div className={classes.root}>
        <ConnectionErrorDialog open={isOffline} handleClose={() => setIsOffline(false)} errorMessage={INTERNET_CONNECTION_ERROR_MESSAGE} />
        <div>
          <Typography variant="h4" className={classes.title}>
            Robots
          </Typography>
          <div>
            <RobotsTable location={ROUTE_CHAPERONE_ESTOP} realTimeUpdate propertyDropDown showOnlineOnly />
          </div>
        </div>

        {[...MultiRobotsStore.robots.keys()].length > 0 && (
          <div>
            <Typography variant="h4" className={classes.title}>
              E-Stop Control
            </Typography>
            <Box my={2}>
              <Button variant="contained" color={allEstop ? 'success' : 'secondary'} onClick={handleAllEstop}>
                {allEstop ? 'UNESTOP ALL' : 'ESTOP ALL'}
              </Button>
            </Box>
            <Grid xl={3} lg={3} md={3} sm={12} xs={12}>
              {[...MultiRobotsStore.robots.keys()].map((robotSerialNumber) => (
                <EStopControl
                  key={robotSerialNumber}
                  serialNumber={robotSerialNumber}
                  allEstop={allEstop}
                  userEmail={userEmail}
                  realTimeUpdate
                />
              ))}
            </Grid>
          </div>
        )}
      </div>
    </Fade>
  );
});
