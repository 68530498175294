import React, { useEffect, useState } from 'react';
import { Button, Dialog, DialogContent, DialogActions, Typography, CircularProgress, Box } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';

/**
 * Dialogue to prompt Remote Guardian to confirm in order to resume operations
 * If Remote Guardian does not confirm within 10 seconds, dialogue changes to prompt RG to Activate inorder to continue operations
 * @param {String} handleAction - Dialogue action handler callback
 * @param {Boolean} handleClose - Close dialogue callback
 * @param {Function} title - Title
 * @param {Function} open - Open prop for dialogue
 * @param {Number} action - Action button name. Confirm is the default value
 * @param {Object} operationsPaused - Boolean to indicate RG failed to confirm dialogue and all operations have then been paused
 * @param {Object} timeInactive - Time elapsed since operation was paused
 *
 */
const ConfirmActiveRgDialog = ({ handleAction, handleClose, title, open, action = 'Confirm', operationsPaused, timeInactive }) => {
  const [progressInterval, setProgressInterval] = useState(100);

  // 10 seconds countdown for RG to confirm before operations are paused
  useEffect(() => {
    let countdownTimer;

    if (open) {
      setProgressInterval(100);
      countdownTimer = setInterval(() => {
        setProgressInterval((prevProgress) => (prevProgress <= 0 ? 0 : prevProgress - 10));
      }, 1000);
    }

    return () => {
      clearInterval(countdownTimer);
    };
  }, [open]);

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
        <DialogTitle onClose={handleClose} id="alert-dialog-title" style={{ padding: '6px' }}>
          <b>{title}</b>
        </DialogTitle>
      </Box>
      <DialogContent
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '8px'
        }}
      >
        {!operationsPaused ? (
          <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={progressInterval} />
            <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
              <Typography variant="caption" component="div" color="textSecondary">{`${progressInterval / 10}s`}</Typography>
            </Box>
          </Box>
        ) : (
          <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <Typography display="block" gutterBottom>
              {`Time Elapsed: ${timeInactive}s`}
            </Typography>
            <Typography display="block" gutterBottom>
              Activate To Resume Operations
            </Typography>
          </Box>
        )}
      </DialogContent>
      <DialogActions style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Button onClick={handleAction} variant="contained" color="secondary">
          {operationsPaused ? 'Activate' : action}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmActiveRgDialog;
