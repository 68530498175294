import { Dialog, DialogContent, Grid, makeStyles, Typography, TableCell } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import React from 'react';
import { StripedTableRow } from '../core/striped-table-row.component';
import { StripedTable } from '../core/striped-table.component';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  closeButton: {
    color: theme.palette.inverted.main,
    minwidth: 100
  },
  TableCell: {
    width: 50
  }
}));

export const UserInfoDialog = ({ user, show = false, onClose = () => {} }) => {
  const classes = useStyles();

  return (
    <Dialog open={show} onClose={onClose} className={classes.root} fullWidth>
      <DialogTitle onClose={onClose} className={classes.title}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="p">
            User Info
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <StripedTable>
          <StripedTableRow className={classes.TableCell}>
            <TableCell className={classes.TableCell} align="left">
              Username
            </TableCell>
            <TableCell className={classes.TableCell} align="left">
              {user['custom:username']}
            </TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Email</TableCell>
            <TableCell align="left">
              {user.email} {user.email_verified ? '(Verified)' : '(Not verified)'}
            </TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">First name</TableCell>
            <TableCell align="left">{user['custom:firstName']}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Last name</TableCell>
            <TableCell align="left">{user['custom:lastName']}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Role</TableCell>
            <TableCell align="left">{user['custom:Role']}</TableCell>
          </StripedTableRow>
        </StripedTable>
      </DialogContent>
    </Dialog>
  );
};
