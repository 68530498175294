import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const useStyles = makeStyles(() => ({
  lineChartContainer: { backgroundColor: 'rgba(255,255,255,1)', width: '99%', '& > canvas': { maxHeight: '80vh' } }
}));

export const colorForLineChartArray = [
  { borderColor: 'rgba(160,160,160,1)', backgroundColor: 'rgba(160,160,160,0.5)' },
  { borderColor: 'rgb(100, 100, 235)', backgroundColor: 'rgba(100, 100, 235, 0.5)' },
  { borderColor: 'rgba(128, 59, 71,1)', backgroundColor: 'rgba(128, 59, 71,0.5)' },
  { borderColor: 'rgba(139, 171, 128,1)', backgroundColor: 'rgba(139, 171, 128,0.5)' },
  { borderColor: 'rgba(102,102,102,1)', backgroundColor: 'rgba(102,102,102,0.5)' },
  { borderColor: 'rgba(181, 167, 127,1)', backgroundColor: 'rgba(181, 167, 127,0.5)' }
];

function OperationLineChart({
  chartLabel,
  chartData,
  chartExtraData,
  diagnostics,
  yAxisTitle,
  yTickStepSize,
  yAxisMinMax = {},
  tooltipLabelCallback,
  lineBorderDashes
}) {
  const classes = useStyles();
  let data;
  let options;
  if (diagnostics) {
    data = {
      labels: chartLabel.map((time) => format(new Date(time), "MM-dd' - 'HH:mm")),
      datasets: Object.keys(chartData).map((field, ind) => ({
        label: field.split('_').join(' '),
        data: chartData[field],
        yAxisID: field,
        color: '#fff',
        ...colorForLineChartArray[1],
        cubicInterpolationMode: ind % 2 === 1 ? 'monotone' : null,
        tension: ind % 2 === 1 ? 0.4 : 0.3,
        extraData: chartExtraData
      }))
    };
    options = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: false,
      plugins: {
        title: {
          display: true,
          text: 'diagnostics'
        },
        legend: {
          display: false,
          labels: {
            color: '#000',
            font: {
              size: 20
            }
          }
        }
      },
      scales: Object.keys(chartData).reduce(
        (acc, field) => ({
          ...acc,
          [field]: {
            type: 'linear',
            display: true,
            position: 'left',
            grid: {
              drawOnChartArea: true
            },
            min: 0,
            max: 100
          }
        }),
        {}
      )
    };
  } else {
    data = {
      labels: chartLabel.map((time) => format(new Date(time), "MM-dd' - 'HH:mm")),
      datasets: Object.keys(chartData).map((field, ind) => ({
        label: field.split('_').join(' '),
        data: chartData[field],
        yAxisID: 'yAxis',
        color: '#fff',
        ...colorForLineChartArray[ind],
        borderDash: lineBorderDashes?.[ind] ?? [],
        cubicInterpolationMode: ind % 2 === 1 ? 'monotone' : null,
        tension: ind % 2 === 1 ? 0.4 : 0.3,
        extraData: chartExtraData
      }))
    };
    options = {
      responsive: true,
      interaction: {
        mode: 'index',
        intersect: false
      },
      stacked: false,
      plugins: {
        title: {
          display: false
        },
        legend: {
          display: true,
          labels: {
            color: '#000',
            font: {
              size: 20
            }
          }
        },
        tooltip: {
          callbacks: {
            label: tooltipLabelCallback
              ? (context) => tooltipLabelCallback(context.dataset.label, context.raw, context.formattedValue, context)
              : undefined
          }
        }
      },
      scales: {
        yAxis: {
          type: 'linear',
          title: {
            display: !!yAxisTitle,
            text: yAxisTitle,
            font: {
              size: 20
            }
          },
          min: yAxisMinMax.min,
          max: yAxisMinMax.max,
          ticks: {
            stepSize: yTickStepSize
          }
        }
      }
    };
  }
  return (
    <div className={classes.lineChartContainer}>
      <Line options={options} data={data} />
    </div>
  );
}

export default OperationLineChart;
