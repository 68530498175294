import React, { useState, useEffect } from 'react';
import { Switch, makeStyles, Typography, Grid } from '@material-ui/core';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase'
  }
}));

export const ControlSwitch = ({
  value = '',
  label = '',
  defaultState = false,
  onChange = () => {},
  skipControlStore = false,
  disabled = false,
  robotControlMode,
  darkBackground = false,
  ...props
}) => {
  const [state, setState] = useState(false);
  const { controlStore } = useStores();
  const classes = useStyles();
  const shouldDisabledSwitch = robotControlMode
    ? robotControlMode === 'manual' || robotControlMode === 'autonomous'
    : controlStore.getControlMode() === 'manual' || controlStore.getControlMode() === 'autonomous';

  useEffect(() => {
    setState(defaultState);
  }, [defaultState]);

  return (
    <Grid
      item
      container
      direction="row"
      justifyContent="space-between"
      alignItems="baseline"
      style={darkBackground ? { backgroundColor: '#FFFFFF', borderRadius: '0.1em', padding: '0.45em' } : {}}
    >
      <Grid item>
        <Typography className={classes.root} gutterBottom>
          {label}
        </Typography>
      </Grid>
      <Grid item>
        <Switch
          color="secondary"
          {...props}
          disabled={skipControlStore ? disabled : shouldDisabledSwitch}
          onChange={() => {
            onChange();
          }}
        />
      </Grid>
    </Grid>
  );
};
