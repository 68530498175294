import { client } from '../../utils/axios-instance';

export const uploadRouteFile = async (fileData, fileName) => {
  const formdata = new FormData();
  formdata.append('fileData', fileData);
  formdata.append('file_name', fileName);
  const response = await client.post('/file-manager/routes', formdata);
  return response.data;
};

export const listRoutes = async () => {
  const response = await client.get('/file-manager/routes');
  return response.data;
};

export const getRouteCsvData = async (csvName) => {
  const response = await client.get('/file-manager/csv-data', { params: { csv_name: csvName } });
  return response.data;
};
