import React from 'react';
import SolarMapKitComponent from './solar-mapkit.component';
import SolarGoogleMapComponent from './solar-google-map.component';

/**
 * SolarMap - Parent component for Google and Apple maps
 *
 * This component is responsible for toggling between Google and Apple maps
 * All states and functionalities are handled by the child components themseleves
 *
 * @param {String} mapType - google map or apple map. Used to toggle between the two
 * @param {Array} mapData - Processed map data containing subrows data and lat+lng values
 * @param {Number} robotLat - Current robot latitude
 * @param {Number} robotLng - Current robot longitude
 * @param {Array} selectedSubRows - Currently selected subrows (or selected sub-block)
 * @param {Boolean} resetRobot - Used to set and reset snail trail progress
 * @param {Number} robotHeading - Current robot heading in degrees
 * @param {Number} height - map component height
 * @param {Number} width - map component width
 * @param {Object} customStyle - custom css style. Can overwrite component default styling
 * @param {Number} mapKey - key value used to force rerender if map parameters change
 * @returns
 */
const SolarMap = ({ mapType, mapData, robotLat, robotLng, selectedSubRows, resetRobot, robotHeading, height, width, customStyle }) => {
  const isAppleMaps = mapType === 'mapkit';
  return isAppleMaps ? (
    <SolarMapKitComponent
      key="mapkit"
      mapData={mapData}
      robotLat={robotLat}
      robotLng={robotLng}
      robotHeading={robotHeading}
      selectedSubRows={selectedSubRows}
      resetRobot={resetRobot}
      customStyle={customStyle}
      height={height}
      width={width}
    />
  ) : (
    <SolarGoogleMapComponent
      key="googleMaps"
      googleMapData={mapData}
      robotLat={robotLat}
      robotLng={robotLng}
      robotHeading={robotHeading}
      selectedSubRows={selectedSubRows}
      resetRobot={resetRobot}
      customStyle={customStyle}
      height={height}
      width={width}
    />
  );
};

export default SolarMap;
