import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  Container,
  Input,
  Chip,
  Tooltip,
  IconButton
} from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import SettingsIcon from '@material-ui/icons/Settings';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { FiberManualRecord } from '@material-ui/icons';
import { useStores } from '../../store/root/root.store';
import RepeatSettingsDialog from '../dialogs/repeat-settings.dialog';
import ActionsDialog from '../dialogs/actions.dialog';
import { COMPLETED, IN_PROGRESS } from '../../utils/constants';
import { LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD, GAMMA, CONTRAST } from '../../services/chaperone/robot-connection.service';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    // paddingRight: theme.spacing(2),
    minWidth: 1600
  },
  grid: {
    zIndex: 100,
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1),
    marginBottom: 10,
    borderRadius: theme.spacing(1),
    position: 'absolute',
    top: '1%',
    left: '410px',
    width: '83.5%'
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold
  },
  PlayCircleOutlineIcon: {
    backgroundColor: '',
    color: 'white',
    height: 35,
    width: 35
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[200],
    Width: '100%',
    height: 35
  },
  ToggleButton: {
    padding: theme.spacing(1)
  },
  Button: {
    marginLeft: theme.spacing(1),
    height: 35,
    width: 176,
    backgroundColor: '#FF0000'
  },
  Stepper: {
    backgroundColor: theme.palette.grey[500],
    padding: theme.spacing(1)
  },
  Notifications: {
    backgroundColor: theme.palette.grey[100],
    fontSize: '1.5em',
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1),
    overflow: 'auto'
  },
  Notes: {
    backgroundColor: theme.palette.grey[100],
    width: '100%',
    height: '75%',
    borderRadius: 4,
    marginRight: theme.spacing(1)
  },
  formControl: {
    width: '100%',
    marginRight: theme.spacing(1)
  },
  RestartButtton: {
    padding: '5px 16px'
  },
  ButtonGroup: {
    height: '63px'
  },
  select: {
    backgroundColor: theme.palette.grey[100],
    padding: theme.spacing(1),
    height: '63px',
    borderRadius: '4px'
  },
  chips: {
    display: 'flex'
  },
  chip: {
    margin: 2
  },
  multiSelect: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  selected: {
    backgroundColor: 'gray !important',
    color: 'white'
  },
  disabledCursor: {
    cursor: 'not-allowed'
  },
  selectedSolarRow: {
    padding: theme.spacing(2),
    backgroundColor: '#fff',
    borderRadius: '5px'
  },
  completedSolarRow: {
    backgroundColor: '#2eb885',
    color: '#fff'
  },
  inProgressSolarRow: {
    backgroundColor: '#03a9f4',
    color: '#fff'
  },
  connectionStatusIcon: {
    width: 35,
    height: 35
  }
}));

const RepeatControls = withRouter(
  ({
    readOnlyMode,
    localDataStore,
    changeRepeatingState,
    changeDriveState,
    cancelRepeatingTask,
    isSolarAuditOfficer,
    start,
    controlMode,
    subsection,
    nextSubsection,
    solarSubRows,
    setSelectedSolarSubRows,
    selectedSolarSubRows,
    robot,
    region,
    property,
    block,
    subBlock,
    handleMapsData,
    skipCurrentSubsection,
    handleRestartController,
    handleRestartVideo,
    updateNavParams,
    subsectionMeta,
    fetchSubsectionMeta,
    setIsVideoStreamingRefreshed,
    isVideoStreamingRefreshed,
    connectionStatusMessageColor
  }) => {
    const { regionsStore, chaperonePropertyStore, blocksStore, subBlocksStore, controlStore, subsectionStore, autonomyRobotStore } =
      useStores();
    const classes = useStyles();
    const [selectedSubsection, setSelectedSubsection] = useState(subsection);
    const [showRepeatSettingsDialog, setShowRepeatSettingsDialog] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const [selectControlMode, setSelectControlMode] = useState('');
    const [linearSpeed, setLinearSpeed] = useState(LINEAR_SPEED.default);
    const [lookAheadDistance, setLookAheadDistance] = useState(LOOK_AHEAD.default);
    const [angularSpeed, setAngularSpeed] = useState(ANGULAR_SPEED.default);
    const [Gamma, setGamma] = useState(GAMMA.default);
    const [Contrast, setContrast] = useState(CONTRAST.default);
    const regionName = regionsStore.getById(region)?.name;
    const propertyName = chaperonePropertyStore.getById(property)?.name;
    const blockName = blocksStore.getById(block)?.name;
    const subBlockName = subBlocksStore.getById(subBlock)?.name;
    const currentRobot = autonomyRobotStore.getById(robot);
    const currentSubsection = subsectionStore.getById(selectedSubsection);

    useEffect(() => {
      const subsectionId = currentSubsection?.id || localDataStore?.subsection_id;
      if (subsectionId) {
        fetchSubsectionMeta(subsectionId);
      }
    }, []);

    useEffect(() => {
      if (nextSubsection) {
        setSelectedSubsection(nextSubsection);
      }
    }, [nextSubsection]);

    let confirmWarningDialog = () => {
      changeDriveState(selectControlMode);
      setOpenDialog(false);
    };
    const closeDialogue = () => setOpenDialog(false);

    const handleChangeToggleButton = (_, toggleControlMode) => {
      if (toggleControlMode !== null) {
        if (controlStore.wpsState === 40 && toggleControlMode === 'autonomous') {
          setOpenDialog(true);
          setSelectControlMode(toggleControlMode);
        } else {
          changeDriveState(toggleControlMode);
        }
      }
    };

    const handleChangeSolarSubRows = (event) => {
      setSelectedSolarSubRows(event.target.value);
    };

    const handleSelectSubsection = async (event) => {
      if (event?.target?.value) {
        if (!start) {
          skipCurrentSubsection();
        }
        setSelectedSubsection(event.target.value);
        // let newIndex = subsectionStore.getByName(event.target.value).Index;
        subsectionStore.setSelectedSubsection(event.target.value);
        await handleMapsData(event.target.value);
        fetchSubsectionMeta(event.target.value);
      }
    };

    useEffect(() => {
      updateNavParams(`${linearSpeed},${lookAheadDistance},${angularSpeed},${Gamma},${Contrast}`);
    }, [linearSpeed, lookAheadDistance, angularSpeed, Gamma, Contrast]);

    const closeSettingDialog = () => {
      setShowRepeatSettingsDialog(false);
    };

    const getStatusClass = (status) => {
      if (status === COMPLETED) return classes.completedSolarRow;
      else if (status === IN_PROGRESS) return classes.inProgressSolarRow;
    };

    return useObserver(() => (
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.grid}>
        <ActionsDialog
          dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
          open={openDialog}
          actions={[
            { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeDialogue },
            { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmWarningDialog }
          ]}
        />
        {showRepeatSettingsDialog && (
          <RepeatSettingsDialog
            readOnlyMode={readOnlyMode}
            handleClose={closeSettingDialog}
            currentLinearSpeed={linearSpeed}
            setLinearSpeed={setLinearSpeed}
            currentLookAheadDistance={lookAheadDistance}
            setLookAheadDistance={setLookAheadDistance}
            currentAngularSpeed={angularSpeed}
            setAngularSpeed={setAngularSpeed}
            currentGamma={Gamma}
            setGamma={setGamma}
            currentContrast={Contrast}
            setContrast={setContrast}
          />
        )}
        <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
          <Typography>
            Serial Number: <b>{currentRobot?.serial_number || localDataStore?.serial_number}</b>
          </Typography>
          <Typography>
            Robot Name: <b>{currentRobot?.name || localDataStore?.name}</b>
          </Typography>
          <Typography>
            Home Region: <b>{currentRobot?.home_region_name || localDataStore?.home_region_name}</b>
          </Typography>
          <Typography>
            Home Property: <b>{currentRobot?.home_property_name || localDataStore?.home_property_name}</b>
          </Typography>
        </Grid>
        <Grid item container direction="column" justifyContent="flex-start" alignItems="flex-start" xs={2}>
          <Typography>
            Region Name: <b>{solarSubRows ? localDataStore?.region_name || regionName : subsectionMeta?.section?.property?.region?.name}</b>
          </Typography>
          <Typography>
            Property Name: <b>{solarSubRows ? localDataStore?.property_name || propertyName : subsectionMeta?.section?.property?.name}</b>
          </Typography>
          <Typography>
            {solarSubRows ? 'Block Name: ' : 'Section Name: '}
            <b>{solarSubRows ? localDataStore?.block_name || blockName : subsectionMeta?.section?.name}</b>
          </Typography>
          {solarSubRows && (
            <Typography>
              Sub Block Name:
              <b>{localDataStore?.sub_block_name || subBlockName}</b>
            </Typography>
          )}
          {!solarSubRows && (
            <Typography>
              subsection Name: <b>{subsectionMeta?.name}</b>
            </Typography>
          )}
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={2}>
          <Typography>Notifications:</Typography>
          <Container className={classes.Notifications} justifycontent="center">
            {controlStore.notification}
          </Container>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={2}>
          <Typography>Notes (Points of Interest):</Typography>
          <Container className={classes.Notes} justifycontent="center" />
        </Grid>
        {solarSubRows ? (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs={1}>
            <FormControl className={classes.formControl}>
              {isSolarAuditOfficer ? <Typography>Selected Solar SubRows:</Typography> : <Typography>Select Solar SubRows:</Typography>}
              {isSolarAuditOfficer ? (
                <div className={classes.selectedSolarRow}>
                  <Typography>{selectedSolarSubRows}</Typography>
                </div>
              ) : (
                <Select
                  multiple
                  disabled={readOnlyMode}
                  value={selectedSolarSubRows}
                  className={classes.multiSelect}
                  style={{}}
                  onChange={handleChangeSolarSubRows}
                  input={<Input />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value) => {
                        return <Chip key={value} label={value.substring(0, value.indexOf('__'))} className={classes.chip} />;
                      })}
                    </div>
                  )}
                >
                  {solarSubRows.map((subrow) => (
                    <MenuItem
                      selected
                      disabled={subrow.status === IN_PROGRESS}
                      classes={{ selected: subrow.status !== IN_PROGRESS ? classes.selected : getStatusClass(IN_PROGRESS) }}
                      className={getStatusClass(subrow.status)}
                      key={subrow.id}
                      value={subrow.value}
                    >
                      {subrow.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            </FormControl>
          </Grid>
        ) : (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={1}>
            <Typography>Select Subsection:</Typography>
            <FormControl className={classes.formControl}>
              <Select value={selectedSubsection} disabled={readOnlyMode} className={classes.select} onClick={handleSelectSubsection}>
                {subsectionStore.subsections.map((subsection) => (
                  <MenuItem key={subsection.id} value={subsection.id}>
                    {subsection.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}

        <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={1}>
          <Typography>Restart:</Typography>
          <FormControl className={classes.formControl}>
            <ButtonGroup
              orientation="vertical"
              aria-label="vertical outlined button group"
              variant="contained"
              className={classes.ButtonGroup}
            >
              <Button key="resetController" disabled={readOnlyMode} onClick={handleRestartController} className={classes.RestartButtton}>
                Robot
              </Button>
              <Button key="resetVideo" onClick={handleRestartVideo} className={classes.RestartButtton}>
                Video
              </Button>
            </ButtonGroup>
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs={2}>
          <Grid item container direction="row" justifyContent="flex-end" alignItems="center">
            <Tooltip title={<Typography fontSize={25}> {`${connectionStatusMessageColor?.message}`} </Typography>}>
              <FiberManualRecord style={{ color: connectionStatusMessageColor?.color }} className={classes.connectionStatusIcon} />
            </Tooltip>
            <Tooltip title={<Typography fontSize={25}> Refresh Video Streaming </Typography>}>
              <IconButton className={classes.PlayCircleOutlineIcon}>
                <RefreshIcon
                  className={classes.PlayCircleOutlineIcon}
                  color="action"
                  disabled={readOnlyMode}
                  onClick={() => {
                    setIsVideoStreamingRefreshed(!isVideoStreamingRefreshed);
                  }}
                />
              </IconButton>
            </Tooltip>
            <SettingsIcon
              className={classes.PlayCircleOutlineIcon}
              color="action"
              disabled={readOnlyMode}
              onClick={() => {
                setShowRepeatSettingsDialog(true);
                if (!readOnlyMode) {
                  changeDriveState('manual');
                }
              }}
            />
            <PlayCircleOutlineIcon
              disabled={readOnlyMode}
              className={classes.PlayCircleOutlineIcon}
              color="action"
              style={{
                display: controlStore.wpsState !== 4 ? 'inline' : 'none'
              }}
              onClick={() => changeRepeatingState?.(selectedSubsection)}
            />
            <PauseCircleOutlineIcon
              className={classes.PlayCircleOutlineIcon}
              color="action"
              style={{
                display: controlStore.wpsState === 4 ? 'inline' : 'none'
              }}
              onClick={() => changeRepeatingState?.(selectedSubsection)}
            />
            <Button disabled={readOnlyMode} onClick={() => cancelRepeatingTask(false)} className={classes.Button} variant="contained">
              Cancel Path
            </Button>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-end" alignItems="center" style={{ marginTop: '10px' }}>
            <ToggleButtonGroup
              className={classes.toggleGroup}
              exclusive
              value={controlMode}
              onChange={handleChangeToggleButton}
              aria-label="text formatting"
            >
              <ToggleButton disabled={readOnlyMode} color="primary" value="manual" aria-label="bold" className={classes.ToggleButton}>
                Manual
              </ToggleButton>
              <ToggleButton disabled={readOnlyMode} color="secondary" value="teleops" aria-label="italic" className={classes.ToggleButton}>
                TeleOps
              </ToggleButton>
              <ToggleButton
                disabled={readOnlyMode}
                color="secondary"
                value="autonomous"
                aria-label="italic"
                className={classes.ToggleButton}
              >
                Autonomous
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        </Grid>
      </Grid>
    ));
  }
);

export default RepeatControls;
