import React from 'react';
import { FormControl, Grid, Typography, FormHelperText, Select, MenuItem } from '@material-ui/core';

const CustomSelectField = ({ label, id, value, setValue, options, classes, formError, required }) => {
  return (
    <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
      <Typography variant="h6" component="span" className={classes.label}>
        {label}:
      </Typography>
      <FormControl className={classes.formControl}>
        <Select
          id={id}
          value={value || ''}
          className={classes.select}
          onChange={(e) => {
            setValue(e.target.value);
          }}
        >
          {options.map(({ id, name }) => (
            <MenuItem key={id} value={id}>
              {name}
            </MenuItem>
          ))}
        </Select>
        {!value && required && formError && <FormHelperText className={classes.helperText}>This is required!</FormHelperText>}
      </FormControl>
    </Grid>
  );
};

export default CustomSelectField;
