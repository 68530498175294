/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  makeStyles,
  TableCell,
  FormControl,
  Select,
  Chip,
  Input,
  MenuItem,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useLocation, useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Replay from '@material-ui/icons/Replay';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { getDistance } from 'geolib';
import { format } from 'date-fns';
import { toJpeg } from 'html-to-image';
import { useStores } from '../store/root/root.store';
import { ROUTE_SOLAR_CHAPERONE } from './routes';
import { ChaperoneRobotConnectionService, LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD } from '../services/chaperone/robot-connection.service';
import { StripedTableRow } from '../components/core/striped-table-row.component';
import { StripedTable } from '../components/core/striped-table.component';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { CustomSlider } from '../components/core/custom-slider.component';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import ActionsDialog from '../components/dialogs/actions.dialog';
import { COMPLETED, IN_PROGRESS, NOT_STARTED, IN_USE, STATUS_MAP } from '../utils/constants';
import RepeatSettingsDialog from '../components/dialogs/repeat-settings.dialog';
import SolarGridMap from '../components/gridMaps/SolarGridMap';
import SolarMap from '../components/maps/solar-map';
import { guardedClient } from '../utils/axios-instance';
import { formatSubrows } from '../utils/format-solar-row';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { isDevMode } from '../utils/ui.utils';
import { ControlSwitch } from '../components/control/control-switch.component';
import { updateSubrow } from '../services/api/subrows.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  PlayCircleOutlineIcon: {
    color: 'white',
    height: 55,
    width: 55
  },
  disable: {
    opacity: 0.3
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  cancelButton: {
    margin: theme.spacing(2),
    height: 50,
    width: 160,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: theme.spacing(1),
    minWidth: 200
  },
  Slider: {
    margin: theme.spacing(1),
    color: '#0178FF',
    width: 120
  },
  sliderLabel: {
    color: '#fff',
    padding: '10px',
    backgroundColor: '#4472c4',
    borderRadius: '5px',
    marginLeft: '7px'
  },
  toggle: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    Width: 30,
    height: 50
  },
  toggleGroup: {
    Width: '100%',
    height: '100%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  chip: {
    margin: 2
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  selected: {
    background: 'gray !important',
    color: 'white',
    '&:hover': {
      background: 'red !important',
      opacity: '0.4'
    }
  },
  completedSolarRow: {
    backgroundColor: '#2eb885',
    color: '#fff'
  },
  inProgressSolarRow: {
    backgroundColor: '#03a9f4',
    color: '#fff'
  }
}));

export const RepeatSolarSubRowsPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const [start, setStart] = useState(true);
  const [pause, setPause] = useState(false);

  const [googleMap, setGoogleMap] = useState(true);
  const [gridMap, setGridMap] = useState(false);
  const [solarSubRowsData, setSolarSubRowsData] = useState({});
  const [resume, setResume] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const {
    applicationStore,
    controlStore,
    autonomyRobotStore,
    regionsStore,
    chaperonePropertyStore,
    blocksStore,
    subBlocksStore,
    subrowStore
  } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const keepSelectedRobot = useRef(false);
  const currentSubrow = useRef(null);
  const [linearSpeed, setLinearSpeed] = useState(LINEAR_SPEED.default);
  const [lookAheadDistance, setLookAheadDistance] = useState(LOOK_AHEAD.default);
  const [angularSpeed, setAngularSpeed] = useState(ANGULAR_SPEED.default);
  const [manager, setManager] = useState('false');
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [cords, setCords] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [openDialog, setOpenDialog] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [solarSubRows, setSolarSubRows] = useState([]);
  const [selectedSolarSubRows, setSelectedSolarSubRows] = useState([]);
  const [openConfirmRestartRobotDialog, setOpenConfirmRestartRobotDialog] = useState(false);
  const [toBeSelectedControlMode, setToBeSelectedControlMode] = useState('');
  const [userRobot, setUserRobot] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
  const [currentPositionIndex, setCurrentPositionIndex] = useState(0);
  const [currentSplittedRowName, setCurrentSplittedRowName] = useState('');
  const regionName = regionsStore.getById(state?.region)?.name;
  const propertyName = chaperonePropertyStore.getById(state?.property)?.name;
  const blockName = blocksStore.getById(state?.block)?.name;
  const subBlockName = subBlocksStore.getById(state?.subBlock)?.name;
  const selectedPathType = state?.selectedPathType;
  const isSelectedSolarRows = !!selectedSolarSubRows.length;
  const currentDate = format(new Date(), "yy-MM-dd'_'HH_mm");
  const componentRef = useRef(null);
  const autonomyRepeatingIsDone = controlStore.isRepeatingDone;
  const currentWpsState = controlStore.wpsState;
  const { previousWpsState } = controlStore;
  const username = localStorage.getItem('username');
  const currentSubrowId = controlStore.currentRow;
  const { auotnomyStoppedDeviated } = controlStore;
  const currentWps = controlStore.wpsState;

  const handleScreenShot = useCallback(() => {
    if (componentRef.current === null) {
      return;
    }
    toJpeg(componentRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement('a');
        link.download = `${currentDate}-${autonomyRobotStore.getSelectedRobot()?.serial_number}-${
          selectedSolarSubRows?.[0]
        }-${blockName}.jpg`;
        link.href = dataUrl;
        link.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }, [componentRef]);

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const handleChangeSolarSubRows = (event) => {
    console.log('handleChangeSolarSubRows', event.target.value);
    setSelectedSolarSubRows(event.target.value);
  };

  const sendRepeatCmdToRobot = async (action, useTeach = false) => {
    console.log('send Repeat CMD', [
      `${action},${currentSubrow.current},${subrowStore.currentPathType},${localStorage.getItem('username')}`
    ]);
    setUserRobot(true);
    const command = useTeach ? 'TEACH' : 'REPEAT';
    await robotConnection?.current?.ros?.cmdRobotService(
      command,
      [`${action},${currentSubrow.current},${localStorage.getItem('username')},${subrowStore.currentPathType}`],
      (result) => {
        setLoading(false);
        if (result.error_message === '') return;
        console.log(`There was the following error with the repeat action: ${result.error_message}`);
        applicationStore.pushError(
          'Error',
          'The robot encountered an error with this repeat action, please report this to the autonomy team if the issue persists'
        );
      }
    );
  };

  useEffect(() => {
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      setUserRobot(true);
      setIsSuspendedRepeatingDialogOpen(true);
    }
    keepSelectedRobot.current = true;
  }, [autonomyRobotStore]);

  const changeLinearSpeed = (event, newValue) => {
    setLinearSpeed(newValue);
  };

  const handleSolarSubRows = (results) => {
    const solarSubRowsList = results.map((row) => {
      const splittedRow = row.name.split('/').at(-1);
      const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
      return {
        ...row,
        name: splittedRowName,
        value: splittedRow
      };
    });
    setSolarSubRows(solarSubRowsList);
  };

  useEffect(() => {
    if (state?.subBlock !== 'NONE') {
      guardedClient.get('/subrows', { params: { subblockId: state?.subBlock, pathType: selectedPathType } }).then((res) => {
        handleSolarSubRows(formatSubrows(res.data.results, state.region, state.property, state?.block, state?.subBlock));
      });
    }
  }, [state]);

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const isValidPosition = (subrowReferencePoint) => {
    if (isDevMode) {
      return true;
    }
    const distance = getDistance(
      { latitude: Number(subrowReferencePoint.lat), longitude: Number(subrowReferencePoint.lng) },
      { latitude: controlStore.lat, longitude: controlStore.lng },
      0.01
    );
    if (Math.fround(distance) > Math.fround(1)) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subrow, the robot is not close to the starting point.");
      return false;
    }
    let diffAngle = controlStore.current_heading_rad - subrowReferencePoint.angle;
    if (diffAngle > 3.14) {
      diffAngle -= 6.28;
    } else if (diffAngle < -3.14) {
      diffAngle += 6.28;
    }
    if (Math.abs(diffAngle) > 0.75) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("Can't start repeating this subrow, the robot's heading is not close to the initial recorded orientation.");
      return false;
    }
    return true;
  };

  const startLoadingMsg = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    showLoadingDialog('We are provisioning the robot now, it will begin the path in a few seconds.');
  };

  const switchToAutonomousControl = () => {
    robotConnection?.current?.ros.setControlMode('autonomous');
  };

  const currentParams = () => `${linearSpeed},${lookAheadDistance},${angularSpeed}`;

  const startNextSolarRow = () => {
    controlStore.resetDoneRepeating();
    if (selectedSolarSubRows.length > 0) {
      const subrowReferencePoint = {
        lat: solarSubRowsData[selectedSolarSubRows[0]][0].lat,
        lng: solarSubRowsData[selectedSolarSubRows[0]][0].long,
        angle: solarSubRowsData[selectedSolarSubRows[0]][0].angle
      };
      currentSubrow.current = selectedSolarSubRows[0];
      console.log('nxt::currentSubrow.current:: ', currentSubrow.current);
      if (isValidPosition(subrowReferencePoint)) {
        switchToAutonomousControl();
        const robotParams = currentParams();
        sendRepeatCmdToRobot('SUBROWS_START');
        startLoadingMsg();
        robotConnection?.current?.ros.updateNavParams(robotParams);
      }
    }
  };

  const openNewSubrow = async () => {
    setOpenDialog(false);
    startNextSolarRow();
  };

  const closeDialogue = () => {
    setOpenDialog(false);
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleTabClosing = () => {
    if (!start) {
      sendRepeatCmdToRobot('PAUSE');
    }
  };

  useEffect(() => {
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  const switchToManualControl = async () => {
    await robotConnection?.current?.ros.setControlMode('manual');
  };

  useEffect(() => {
    if (!controlStore.gpsFixStatus.includes('RTK') && controlStore.getControlMode() === 'autonomous') {
      setPositionError(true);
      setConnectionError(true);

      if (pause) {
        setErrorMessage("RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !");
        setPause(!pause);
        setResume(!resume);
        switchToManualControl();
        sendRepeatCmdToRobot('PAUSE');
      }
    }
  }, [controlStore.gpsFixStatus]);

  const handleSolarRowsStatus = (updatedStatus) => {
    const updatedSubrows = solarSubRows.map((subrow) => {
      if (subrow.value.includes(selectedSolarSubRows[0])) {
        return { ...subrow, status: updatedStatus };
      }
      return subrow;
    });
    setSolarSubRows(updatedSubrows);
    const updatedSubrow = updatedSubrows.find((subrow) => subrow.value.includes(selectedSolarSubRows[0]));
    if (updatedSubrow) {
      guardedClient.patch(`/subrows/${updatedSubrow.id}`, {
        status: updatedStatus
      });
    }
  };

  const handleReleaseRobot = async () => {
    const cmd = 'CANCEL';
    if (currentWpsState > 0) {
      setIsSuspendedRepeatingDialogOpen(false);
      await sendRepeatCmdToRobot(cmd);
      await updateSubrow(currentSubrowId, { status: NOT_STARTED });
      const operatingSubrowId = autonomyRobotStore.getSelectedRobot()?.operating_subrow_id;
      setSolarSubRows(solarSubRows.map((subrow) => (subrow.id === operatingSubrowId ? { ...subrow, status: NOT_STARTED } : subrow)));
    }
  };

  // const cancelRepeatingTask = async (done = false) => {
  //   if (!done) {
  //     handleReleaseRobot();
  //     controlStore.resetStore();
  //   }
  //   switchToManualControl();
  //   if (!start) {
  //     setStart(!start);
  //     if (resume) {
  //       setResume(!resume);
  //     } else if (pause) {
  //       setPause(!pause);
  //     }
  //     await sendRepeatCmdToRobot('FINISH');
  //     controlStore.resetDoneRepeating();
  //   }
  // };

  const cancelRepeatingTask = async (done = false) => {
    switchToManualControl();
    if (!done) {
      handleReleaseRobot();
      controlStore.resetStore();
    } else {
      await sendRepeatCmdToRobot('FINISH');
    }
    if (!start) {
      setStart(!start);
      if (resume) {
        setResume(!resume);
      } else if (pause) {
        setPause(!pause);
      }
      controlStore.resetDoneRepeating();
    }
  };

  const changeRepeatingState = () => {
    if (resume) {
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (!controlStore.gpsFixStatus.includes('RTK')) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
        return;
      } else if (!controlStore.stableLocalization) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
        return;
      }
      console.log('GPS data is good to allow the robot to goooo!!');
      setPause(!pause);
      setResume(!resume);
      switchToAutonomousControl();
      sendRepeatCmdToRobot('RESUME');
    } else if (pause) {
      setPause(!pause);
      setResume(!resume);
      switchToManualControl();
      sendRepeatCmdToRobot('PAUSE');
    } else if (start) {
      controlStore.resetDoneRepeating();
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (controlStore.lat && controlStore.lng) {
        if (!controlStore.gpsFixStatus.includes('RTK')) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
          return;
        }

        if (!controlStore.stableLocalization) {
          setPositionError(true);
          setConnectionError(true);
          setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
          return;
        }
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('GPS  Error, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }
      currentSubrow.current = selectedSolarSubRows[0];
      const subrowReferencePoint = {
        lat: solarSubRowsData[currentSubrow.current][0].lat,
        lng: solarSubRowsData[currentSubrow.current][0].long,
        angle: solarSubRowsData[currentSubrow.current][0].angle
      };

      if (!isValidPosition(subrowReferencePoint)) {
        return;
      }
      setStart(!start);
      setPause(!pause);
      if (controlStore.getControlMode() !== 'autonomous') {
        switchToAutonomousControl();
        setResetRobot(true);
      }
      setResetRobot(true);
      const paramsStr = currentParams();
      guardedClient
        .get(`/robots/${autonomyRobotStore.selectedRobotId}/status`)
        .then((res) => {
          if (res.data.results.status === 'AVAILABLE' || userRobot) {
            sendRepeatCmdToRobot('SUBROWS_START');
            startLoadingMsg();
            robotConnection?.current?.ros.updateNavParams(paramsStr);
          } else {
            setConnectionError(true);
            cancelRepeatingTask();
            setErrorMessage('Robot is already in-use, check it again later or select another robot!');
          }
        })
        .catch((err) => {
          console.log(err);
          setConnectionError(true);
          cancelRepeatingTask();
          setErrorMessage('Robot is already in-use, check it again later or select another robot!');
        });
    }
  };

  const restartRobot = () => {
    setOpenConfirmRestartRobotDialog(true);
  };

  const restart = () => {
    robotConnection?.current?.ros?.restartRobotService('robot');
  };

  const changeDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (!start) {
        if (controlStore.getControlMode() !== 'autonomous' && resume) {
          setPause(!pause);
          setResume(!resume);
          switchToAutonomousControl();
          sendRepeatCmdToRobot('RESUME');
        } else if (controlStore.getControlMode() === 'autonomous' && pause) {
          setPause(!pause);
          setResume(!resume);
          switchToManualControl();
          sendRepeatCmdToRobot('PAUSE');
        }
      }
    }
  };

  const confirmChangeDriveState = () => {
    changeDriveState({}, toBeSelectedControlMode);
    setOpenWarningDialog(false);
  };

  const toggleDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (currentWpsState === 0) {
        console.log('discarded');
        return;
      }

      if (currentWpsState === 5 && toggleManualControl === false) {
        setOpenWarningDialog(true);
        setToBeSelectedControlMode(toggleManualControl);
      } else {
        changeDriveState({}, toggleManualControl);
      }
    }
  };

  function valuetext(value) {
    return `${value}°C`;
  }

  useEffect(() => {
    if (robotConnectionService.current !== null && previousWpsState !== currentWpsState) {
      // check for idle robot state data
      console.log('njjnj', (Date.now() - controlStore.lastRobotStateReceived) / 1000);

      if ((Date.now() - controlStore.lastRobotStateReceived) / 1000 > 10 && controlStore.getControlMode() === 'autonomous') {
        // in seconds
        setPositionError(true);
        setConnectionError(true);
        if (pause) {
          setErrorMessage("RTK Error: Can't continue repeating this subsection autonomously, the robot does not have an RTK GPS data !");
          setResume(!resume);
          switchToManualControl();
          sendRepeatCmdToRobot('PAUSE');
        } else {
          setErrorMessage('RTK Error: No sensory data has been recieved, the robot does not have an RTK GPS data !');
        }
      }
      // Check for change in other Waypoints state
      if (!start && autonomyRepeatingIsDone) {
        console.log('WAYPOINTS REPEATING IS DONE', selectedSolarSubRows);
        handleScreenShot();
        handleSolarRowsStatus(COMPLETED);
        setSelectedSolarSubRows(selectedSolarSubRows.slice(1));
        selectedSolarSubRows.shift();
        cancelRepeatingTask(true);
        if (selectedSolarSubRows.length > 0) {
          setOpenDialog(true);
        }
      } else if (Number(currentWpsState) === 0) {
        setStart(true);
        setPause(false);
        setResume(false);
      } else if (Number(currentWpsState) === 4) {
        setStart(false);
        setPause(true);
        handleSolarRowsStatus(IN_PROGRESS);
      } else if (!start && Number(currentWpsState) === 40) {
        setResume(true);
        setPause(false);
        // switchToManualControl();
      }
      controlStore.setPreviousWpsState(currentWpsState);
    }
  }, [cancelRepeatingTask, robotConnectionService, start, currentWpsState]);

  useEffect(() => {
    const paramsStr = currentParams();
    robotConnection?.current?.ros.updateNavParams(paramsStr);
  }, [linearSpeed, lookAheadDistance, angularSpeed]);

  useEffect(() => {
    setManager(state?.manager);
    guardedClient
      .get('/robots/solar-map/', {
        params: {
          regionId: state.region,
          propertyId: state.property,
          blockId: state?.block,
          subblockId: state?.subBlock,
          pathType: selectedPathType
        }
      })
      .then((res) => {
        setSolarSubRowsData(res.data.data);
        const coordinates = Object.entries(res.data.data).map(([key, points]) =>
          points.map((sample) => ({
            key,
            lat: Number(sample.lat),
            lng: Number(sample.long),
            angle: Number(sample.angle)
          }))
        );
        setCords(coordinates);
      })
      .catch((err) => console.log(err));

    return () => {
      controlStore.resetRobotPosition();
      controlStore.resetStore();
    };
  }, []);

  useEffect(() => {
    if (state) {
      robotConnectionService.current = new ChaperoneRobotConnectionService(
        () => {
          // onConnected
          robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) => controlStore.updateRobotState(robotState));
          robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission((msg) => controlStore.setDoneRepeating(msg.data));
          robotConnection.current = robotConnectionService.current;
          robotConnectionService?.current?.ros.subscribeToRobotNotification((message) => {
            controlStore.setNotificationMessage(message.data);
          });
          robotConnectionService?.current?.ros.getUpdatedStaticConfig((updatedParams) => {
            if (updatedParams) {
              controlStore.updateStaticParameters(updatedParams);
            }
          });
          robotConnectionService?.current?.ros.getUpdatedDynamicConfig((updatedParams) => {
            if (updatedParams) {
              controlStore.updateDynamicParameters(updatedParams);
            }
          });
          robotConnectionService?.current?.ros.isWebSocketDisconnected((isWebSocketDisconnected) => {
            if (isWebSocketDisconnected) {
              applicationStore.pushError(
                'Error',
                `You have been disconnected from ${autonomyRobotStore.getSelectedRobot().name} by a different remote guardian`
              );
              push(ROUTE_SOLAR_CHAPERONE);
            }
          });
        },
        () => {
          // onDisconnect
          console.log('Lost connection to robot');
          if (!start) {
            sendRepeatCmdToRobot('PAUSE');
          } else {
            handleReleaseRobot();
          }
          robotConnectionService?.current?.retryConnection();
          handleConnectionError();
          robotConnection.current = null;
        },
        autonomyRobotStore.getSelectedRobot().serial_number,
        username,
        'chaperone_solar_repeat'
      );
      robotConnectionService?.current?.connectToRobot(handleConnectionError);
    }
    return () => {
      if (robotConnectionService.current !== null) {
        if (!start) {
          sendRepeatCmdToRobot('PAUSE');
        } else {
          handleReleaseRobot();
        }
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
      if (!keepSelectedRobot.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
    };
  }, []);

  useEffect(() => {
    if (!controlStore.safetyEnabled) {
      setErrorMessage(`The robot's safety system is down. ${controlStore.safetyErrorMessage}`);
      setPositionError(true);
      setConnectionError(true);
      switchToManualControl();
    }
  }, [controlStore.safetyEnabled, controlStore.getControlMode()]);

  // DEV TAG NEEDED
  window.onload = () => {
    push({
      pathname: ROUTE_SOLAR_CHAPERONE,
      loc: {
        region: state?.region,
        property: state?.property,
        block: state?.block,
        subBlock: state?.subBlock,
        useCase: state?.useCase
      }
    });
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
  };

  const closeSettingDialog = () => {
    setShowSettingsDialog(false);
  };

  const getStatusClass = (status) => {
    if (status === COMPLETED) return classes.completedSolarRow;
    if (status === IN_PROGRESS) return classes.inProgressSolarRow;
    return undefined;
  };

  const fetchSubrowMeta = async (subrowId) => {
    const response = await guardedClient.get(`/subrows/${subrowId}`);
    return response.data.results;
  };

  const handleSuspendedRepeatingDialogGoBack = async () => {
    const subrowInfo = fetchSubrowMeta(currentSubrowId);
    const formattedSubrow = formatSubrows([subrowInfo], state.region, state.property, state?.block, state?.subBlock)[0];
    const splittedRow = formattedSubrow.name.split('/').at(-1);
    setCurrentSplittedRowName(splittedRow);
    const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
    formattedSubrow.name = splittedRowName;
    formattedSubrow.value = splittedRow;
    setSelectedSolarSubRows([formattedSubrow.value]);
    setIsSuspendedRepeatingDialogOpen(false);
    setResume(true);
    setStart(false);
    // Call the last wps_index service
    robotConnection?.current?.ros?.getLastWpsIndexService((wpsInexStr) => {
      console.log({ wpsInexStr });
      setCurrentPositionIndex(parseInt(wpsInexStr));
    });
  };

  /** Handler for pausing autonomy operations */
  const pauseAutonomyOperation = async () => {
    if ((!start && (!resume || pause)) || STATUS_MAP[currentWpsState] === IN_USE) {
      sendRepeatCmdToRobot('PAUSE');
      // if (isBladeRunning) toggleAutonomousBlades();
      await switchToManualControl();
      setResume(true);
      setPause(false);
    }
  };

  useEffect(() => {
    if (currentWps === 4) {
      if (auotnomyStoppedDeviated) {
        setErrorMessage('Robot  is experiencing a high autonomy path deviation, please drive it manually back to the path!');
        setConnectionError(true);
        pauseAutonomyOperation();
      }
    }
  }, [auotnomyStoppedDeviated, currentWps]);

  useEffect(() => {
    if (currentWps !== 4) {
      setResume(true);
      setPause(false);
    }
  }, [currentWps]);

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
      <ActionsDialog
        dialogTitle="Robot is not autonomous now, do you want it in autonomous mode?"
        open={openWarningDialog}
        actions={[
          { color: 'primary', name: 'Cancel', variant: 'outlined', handler: () => setOpenWarningDialog(false) },
          { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmChangeDriveState }
        ]}
      />
      {showSettingsDialog && (
        <RepeatSettingsDialog
          isChaperoneRepeat
          manager={manager}
          handleClose={closeSettingDialog}
          currentLinearSpeed={linearSpeed}
          setLinearSpeed={setLinearSpeed}
          currentLookAheadDistance={lookAheadDistance}
          setLookAheadDistance={setLookAheadDistance}
          currentAngularSpeed={angularSpeed}
          setAngularSpeed={setAngularSpeed}
        />
      )}
      <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
      <Grid item>
        <StripedTable headers={['Robot Name', 'Region Name', 'Property Name', 'Block Name', 'Sub Block Name']} align="center">
          <StripedTableRow>
            <TableCell align="center">{autonomyRobotStore.getSelectedRobot()?.serial_number}</TableCell>
            <TableCell align="center">{regionName}</TableCell>
            <TableCell align="center">{propertyName}</TableCell>
            <TableCell align="center">{blockName}</TableCell>
            <TableCell align="center">{subBlockName}</TableCell>
          </StripedTableRow>
        </StripedTable>
      </Grid>
      <Grid item>
        <StripedTable headers={['Notifications']} align="center">
          <StripedTableRow>
            <TableCell align="center">{controlStore.getNotificationMessage()}</TableCell>
          </StripedTableRow>
        </StripedTable>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
        <SettingsIcon
          className={`${classes.PlayCircleOutlineIcon} ${!isSelectedSolarRows && classes.disable}`}
          color="action"
          onClick={() => {
            isSelectedSolarRows && setShowSettingsDialog(true);
          }}
        />
        <PlayCircleOutlineIcon
          className={`${classes.PlayCircleOutlineIcon} ${!isSelectedSolarRows && classes.disable}`}
          color="action"
          style={{
            display: start || resume ? 'inline' : 'none'
          }}
          onClick={isSelectedSolarRows && changeRepeatingState}
        />
        <PauseCircleOutlineIcon
          className={`${classes.PlayCircleOutlineIcon} ${!isSelectedSolarRows && classes.disable}`}
          color="action"
          style={{
            display: !start && !resume && pause ? 'inline' : 'none'
          }}
          onClick={isSelectedSolarRows && changeRepeatingState}
        />
        <Replay
          className={classes.PlayCircleOutlineIcon}
          color="action"
          style={{
            display: 'inline'
          }}
          onClick={restartRobot}
        />
        {!start && Number(currentWpsState) > 0 && (
          <Button onClick={() => cancelRepeatingTask(false)} className={classes.cancelButton} variant="contained">
            Cancel Path
          </Button>
        )}
        <EStopIndicator
          eStopEngaged={controlStore.estopState || controlStore.swEstopState}
          videoStream={false}
          width={65}
          height={65}
          marginLeft={0}
          marginTop={10}
          marginRight={5}
          marginBottom={0}
        />
        <div className={classes.toggle}>
          <ToggleButtonGroup
            className={classes.toggleGroup}
            exclusive
            value={controlStore.getControlMode() !== 'autonomous'}
            onChange={toggleDriveState}
            aria-label="text formatting"
          >
            <ToggleButton color="primary" value aria-label="bold">
              Manual
            </ToggleButton>
            <ToggleButton color="secondary" value={false} aria-label="italic">
              Autonomous
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <Grid item className={classes.robotControl}>
          <ControlSwitch
            label="SW ESTOP"
            defaultValue={controlStore.swEstopState}
            value={controlStore.swEstopState}
            checked={controlStore.swEstopState}
            skipControlStore
            darkBackground
            onChange={async () => {
              await robotConnection?.current?.ros?.cmdSwEstop(!controlStore.swEstopState);
              await pauseAutonomyOperation();
            }}
          />
        </Grid>
      </Grid>

      <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
        {width <= 768 ? (
          <Typography id="input-slider" className={classes.label} style={{ minWidth: 300 }}>
            Maximum Linear Speed Limit (Km/hr):
          </Typography>
        ) : (
          <Typography id="input-slider" className={classes.label}>
            Maximum Linear Speed Limit (Km/hr):
          </Typography>
        )}
        <CustomSlider
          onMinusClick={() => {
            linearSpeed > LINEAR_SPEED.min && changeLinearSpeed({}, parseFloat((linearSpeed - LINEAR_SPEED.step).toFixed(2)));
          }}
          onAddClick={() => {
            linearSpeed < LINEAR_SPEED.max && changeLinearSpeed({}, parseFloat((linearSpeed + LINEAR_SPEED.step).toFixed(2)));
          }}
        >
          <Slider
            className={classes.Slider}
            defaultValue={LINEAR_SPEED.default}
            value={linearSpeed}
            onChange={changeLinearSpeed}
            getAriaValueText={valuetext}
            aria-labelledby="continuous-slider"
            min={LINEAR_SPEED.min}
            max={LINEAR_SPEED.max}
            step={LINEAR_SPEED.step}
            marks
          />
        </CustomSlider>
        <div className={classes.sliderLabel}>{linearSpeed}</div>
      </Grid>

      <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
        <FormControl className={classes.formControl}>
          <Typography variant="h6" component="span" className={classes.label}>
            Select Solar SubRows:
          </Typography>
          <Select
            multiple
            value={selectedSolarSubRows}
            className={classes.select}
            style={{}}
            onChange={handleChangeSolarSubRows}
            input={<Input />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value.substring(0, value.indexOf('__'))} className={classes.chip} />
                ))}
              </div>
            )}
          >
            {solarSubRows.map((subrow) => (
              <MenuItem
                selected
                disabled={subrow.status === IN_PROGRESS}
                classes={{ selected: subrow.status !== IN_PROGRESS ? classes.selected : getStatusClass(IN_PROGRESS) }}
                className={getStatusClass(subrow.status)}
                key={subrow.id}
                value={subrow.value}
              >
                {subrow.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      <FormControlLabel
        style={{ color: 'white' }}
        checked
        control={<Checkbox style={{ color: 'white' }} checked={gridMap} />}
        onChange={(event) => {
          setGridMap(event.target.checked);
        }}
        label="Grid Map"
      />

      <FormControlLabel
        style={{ color: 'white' }}
        control={
          <Checkbox
            style={{ color: 'white' }}
            checked={googleMap}
            onChange={(event) => {
              setGoogleMap(event.target.checked);
            }}
          />
        }
        label="Google Map"
      />
      <ActionsDialog
        dialogTitle="Next sub-row is ready to go. Drive the robot to its starting point, start Next Sub-row?"
        open={openDialog}
        actions={[
          { color: 'primary', name: 'Cancel', variant: 'outlined', handler: closeDialogue },
          { color: 'secondary', name: 'Start', variant: 'contained', handler: openNewSubrow }
        ]}
      />
      <Button onClick={handleScreenShot} variant="contained" disableElevation color="secondary">
        take screenshot
      </Button>
      <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
      <div ref={componentRef} style={{ width: '100%', height: '100%' }}>
        {googleMap && (
          <SolarMap
            key={controlStore.lat !== 0 ? 'loaded' : 'loading'}
            robotLat={controlStore.lat}
            resetRobot={resetRobot}
            mapData={cords}
            selectedSubRows={selectedSolarSubRows}
            robotLng={controlStore.lng}
            robotHeading={controlStore.current_heading_rad}
            currentPositionIndex={currentPositionIndex}
            currentSplittedRowName={currentSplittedRowName}
            height="450px"
            className={false}
          />
        )}
        {gridMap && (
          <SolarGridMap
            solarRows={solarSubRowsData}
            robotEnabled
            selectedSolarRows={selectedSolarSubRows}
            resetRobot={resetRobot}
            isZoomEnabled
            robotLng={controlStore.lng}
            robotLat={controlStore.lat}
            robotHeadingRad={controlStore.current_heading_rad}
            currentPositionIndex={currentPositionIndex}
            currentSplittedRowName={currentSplittedRowName}
          />
        )}
      </div>
      <ActionsDialog
        actions={[
          { color: 'primary', name: 'Cancel', variant: 'outlined', handler: () => setOpenConfirmRestartRobotDialog(false) },
          { color: 'secondary', name: 'Restart', variant: 'contained', handler: restart }
        ]}
        dialogTitle="Are you sure you want to restart the robot?"
        open={openConfirmRestartRobotDialog}
      />
      <ActionsDialog
        dialogTitle="The robot is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current repeating operation?"
        open={isSuspendedRepeatingDialogOpen}
        actions={[
          { color: 'primary', name: 'Cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
          { color: 'secondary', name: 'Continue Repeating', variant: 'contained', handler: handleSuspendedRepeatingDialogGoBack }
        ]}
      />
    </Grid>
  );
});
