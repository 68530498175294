import { types } from 'mobx-state-tree';

export const Motor = types.model('Motor', {
  status: types.optional(types.number, 0),
  rpm: types.optional(types.number, 0),
  motor_temp: types.optional(types.number, 0),
  current: types.optional(types.number, 0),
  id: types.optional(types.string, ''),
  fault: types.optional(types.string, ''),
  motor_type: types.optional(types.string, '')
});
/* Example data from ROS message:
  motors:
    -
      current: -2.28351640701
      temp: 22.0
      id: "FR"
    -
      current: -1.9571428299
      temp: 22.5
      id: "MR"
    -
      current: -2.02967023849
      temp: 21.5
      id: "BR"
    -
      current: -1.99340653419
      temp: 23.5
      id: "FL"
    -
      current: -1.9208791256
      temp: 21.5
      id: "ML"
    -
      current: -1.73956048489
      temp: 22.5
      id: "BL"
  battery_state: 75
*/
