import { types } from 'mobx-state-tree';
import { v4 as uuidv4 } from 'uuid';

const CsvData = types.model({
  timestamp: types.maybeNull(types.number),
  x: types.maybeNull(types.number),
  y: types.maybeNull(types.number),
  angle: types.maybeNull(types.number),
  long: types.maybeNull(types.number),
  lat: types.maybeNull(types.number)
});

export const AutonomyAuditReports = types.model({
  id: types.optional(types.identifier, () => uuidv4()),
  completedSuccessfully: types.maybeNull(types.boolean),
  countryId: types.maybeNull(types.number),
  countryName: types.maybeNull(types.string),
  regionId: types.maybeNull(types.number),
  regionName: types.maybeNull(types.string),
  propertyId: types.maybeNull(types.number),
  propertyName: types.maybeNull(types.string),
  sectionId: types.maybeNull(types.number),
  sectionName: types.maybeNull(types.string),
  subsectionId: types.maybeNull(types.number),
  subsectionName: types.maybeNull(types.string),
  maxDeviation: types.maybeNull(types.number),
  averageDeviation: types.maybeNull(types.number),
  robotSerialNumber: types.maybeNull(types.string),
  s3URI: types.maybeNull(types.string),
  timestamp: types.maybeNull(types.number),
  isSolar: types.maybeNull(types.boolean),
  subBlockId: types.maybeNull(types.number),
  subBlockName: types.maybeNull(types.string),
  pathType: types.maybeNull(types.string),

  // CSV data
  repeatedCsvData: types.optional(types.array(CsvData), []),
  taughtCsvData: types.optional(types.array(CsvData), []),
  deviationArray: types.maybeNull(types.array(types.number)),
  loading: types.optional(types.boolean, true),
  hasNoData: types.optional(types.boolean, false)
});
