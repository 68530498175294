/* eslint comma-dangle: ["error", "always-multiline"] */
const linux = {
  axes: {
    main: {
      angular: {
        index: 0,
        name: 'Main Stick Angular',
      },
      linear: {
        index: 1,
        name: 'Main Stick Linear',
      },
      //doesn't do anything for PS4 controller
      rotation: {
        index: 2,
        name: 'Main Stick Rotation',
      },
    },
    secondary: {
      angular: {
        index: 2,
        name: 'Secondary Stick Angular',
      },
      linear: {
        index: 3,
        name: 'Secondary Stick Linear',
      },
      rotation: {
        index: 4,
        name: 'Secondary Stick Rotation',
      },
    },
  },
  buttons: {
    trigger: {
      index: 0,
      name: 'Trigger',
    },
    button2: {
      index: 1,
      name: 'Button 2',
    },
    button3: {
      index: 2,
      name: 'Button 3',
    },
    button4: {
      index: 3,
      name: 'Button 4',
    },
    button5: {
      index: 4,
      name: 'Button 5',
    },
    button6: {
      index: 5,
      name: 'Button 6',
    },
    button7: {
      index: 6,
      name: 'Button 7',
    },
    button8: {
      index: 7,
      name: 'Button 8',
    },
    button9: {
      index: 8,
      name: 'Button 9',
    },
    button10: {
      index: 9,
      name: 'Button 10',
    },
    button11: {
      index: 10,
      name: 'Button 11',
    },
    button12: {
      index: 11,
      name: 'Button 12',
    },
  },
};

const mac = {
  axes: {
    main: {
      angular: {
        index: 0,
        name: 'Main Stick Angular',
      },
      linear: {
        index: 1,
        name: 'Main Stick Linear',
      },
      // this is an arbitrary index value as the rotation axis doesn't
      // do anything for the PS4 controller
      rotation: {
        index: 2,
        name: 'Main Stick Rotation',
      },
    },
    secondary: {
      angular: {
        index: 2,
        name: 'Secondary Stick Angular',
      },
      linear: {
        index: 3,
        name: 'Secondary Stick Linear',
      },
      rotation: {
        index: 4,
        name: 'Secondary Stick Rotation',
      },
    },
  },
  // can go through and redo naming
  buttons: {
    trigger: {
      index: 0,
      name: 'Trigger',
    },
    button2: {
      index: 1,
      name: 'Button 2',
    },
    button3: {
      index: 2,
      name: 'Button 3',
    },
    button4: {
      index: 3,
      name: 'Button 4',
    },
    button5: {
      index: 4,
      name: 'Button 5',
    },
    button6: {
      index: 5,
      name: 'Button 6',
    },
    button7: {
      index: 6,
      name: 'Button 7',
    },
    button8: {
      index: 7,
      name: 'Button 8',
    },
    button9: {
      index: 8,
      name: 'Button 9',
    },
    button10: {
      index: 9,
      name: 'Button 10',
    },
    button11: {
      index: 10,
      name: 'Button 11',
    },
    button12: {
      index: 11,
      name: 'Button 12',
    },
    button13: {
      index: 12,
      name: 'Button 13',
    },
    button14: {
      index: 13,
      name: 'Button 14',
    },
    button15: {
      index: 14,
      name: 'Button 15',
    },
    button16: {
      index: 15,
      name: 'Button 16',
    },
  },
};

const windows = {
  axes: {
    main: {
      angular: {
        index: 0,
        name: 'Main Stick Angular',
      },
      linear: {
        index: 1,
        name: 'Main Stick Linear',
      },
      rotation: {
        index: 5,
        name: 'Main Stick Rotation',
      },
    },
    secondary: {
      angular: {
        index: 2,
        name: 'Secondary Stick Angular',
      },
      linear: {
        index: 3,
        name: 'Secondary Stick Linear',
      },
      rotation: {
        index: 1,
        name: 'Secondary Stick Rotation',
      },
    },
  },
  buttons: {
    trigger: {
      index: 0,
      name: 'Trigger',
    },
    button2: {
      index: 1,
      name: 'Button 2',
    },
    button3: {
      index: 2,
      name: 'Button 3',
    },
    button4: {
      index: 3,
      name: 'Button 4',
    },
    button5: {
      index: 4,
      name: 'Button 5',
    },
    button6: {
      index: 5,
      name: 'Button 6',
    },
    button7: {
      index: 6,
      name: 'Button 7',
    },
    button8: {
      index: 7,
      name: 'Button 8',
    },
    button9: {
      index: 8,
      name: 'Button 9',
    },
    button10: {
      index: 9,
      name: 'Button 10',
    },
    button11: {
      index: 10,
      name: 'Button 11',
    },
    button12: {
      index: 11,
      name: 'Button 12',
    },
    button13: {
      index: 12,
      name: 'Button 13',
    },
    button14: {
      index: 13,
      name: 'Button 14',
    },
    button15: {
      index: 14,
      name: 'Button 15',
    },
    button16: {
      index: 15,
      name: 'Button 16',
    },
  },
};

// Define thumstick values. On Linux it works analagous to the main joystick handle.
// On Mac and Windows, however, a single axis is used to track both fwd/back and left/right
// directions, with non-obvious values (not -1, 0 and 1).
linux.axes.thumb = {
  angular: {
    index: 4,
    name: 'Thumb Stick Angular',
  },
  linear: {
    index: 5,
    name: 'Thumb Stick Linear',
  },
};
// Define a nest array to map raw joystick values to values used by callers of Joystick.js.
// This is done to provide a consistent value despite actual joystick values varying by OS.
let map = [
  [-1, -1], // left/forward
  [1, 1], // right/backward
];
linux.axes.thumb.angular.valueMap = map;
linux.axes.thumb.linear.valueMap = map;

mac.axes.thumb = {
  angular: {
    index: 9,
    name: 'Thumb Stick Angular',
  },
  linear: {
    index: 9,
    name: 'Thumb Stick Linear',
  },
};
map = [
  [0.71429, -1], // left
  [-0.42857, 1], // right
];
mac.axes.thumb.angular.valueMap = map;

map = [
  [-1, -1], // forward
  [0.14286, 1], // backward
];
mac.axes.thumb.linear.valueMap = map;

windows.axes.thumb = {
  angular: {
    index: 9,
    name: 'Thumb Stick Angular',
  },
  linear: {
    index: 9,
    name: 'Thumb Stick Linear',
  },
};
map = [
  [0.71429, -1], // left
  [-0.42857, 1], // right
];
windows.axes.thumb.angular.valueMap = map;

map = [
  [-1, -1], // forward
  [0.14286, 1], // backward
];
windows.axes.thumb.linear.valueMap = map;

export default {
  Linux: mac,
  Mac: mac,
  Windows: windows, // Mac and Windows have the same mapping
};
