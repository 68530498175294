import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  Paper,
  InputLabel
} from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStores } from '../../store/root/root.store';
import UnsavedChangesDialog from './unsaved-changes.dialog';

const useStyles = makeStyles((theme) => ({
  root: {},

  formControl: {
    marginBottom: theme.spacing(2)
  },
  uppercase: {
    '& > input': {
      textTransform: 'uppercase'
    },
    '& > input::placeholder': {
      textTransform: 'none'
    }
  }
}));

export const AddChaperoneProperty = ({ show, property = null, onClose = () => {}, view = 'add', propertyId = null }) => {
  const classes = useStyles();
  const { regionsStore, chaperonePropertyStore } = useStores();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [name, setName] = useState('');
  const [street, setStreet] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [province, setProvince] = useState('');
  const [city, setCity] = useState('');
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);

  useEffect(() => {
    async function checkPropertyView() {
      if (view === 'edit') {
        const result = await chaperonePropertyStore.getPropertyById({ id: propertyId });
        setName(result.results.name);
        setStreet(result.results.street_address);
        setCity(result.results.city);
        setPostalCode(result.results.postal_code);
        setProvince(result.results.province);
        regionsStore.setSelectedRegion(result.results.region_id);
      }
    }
    checkPropertyView();
  }, []);

  const onSubmit = async (data) => {
    const { route, name, ...address } = data;
    const region = regionsStore.selectedRegionId;
    try {
      if (view === 'edit') {
        const response = await chaperonePropertyStore.updateProperty({ propertyId, region, name, address });
        await chaperonePropertyStore.getProperties(region);
        onClose(response.results.id);
      } else {
        var exist = chaperonePropertyStore.checkDuplicates(name);
        if (!exist) {
          const response = await chaperonePropertyStore.createProperty({ region, name, address });
          await chaperonePropertyStore.getProperties(region);
          onClose(response.results.id);
        } else {
          chaperonePropertyStore.setError('This property already exists');
          onClose();
        }
      }
    } catch (error) {
      onClose();
    }
  };

  function handleClose() {
    if (name || street || city || province || postalCode) setOpenUnsavedChangesDialog(true);
    else onClose();
  }

  function handleConfirmClose(doCloseFully) {
    setOpenUnsavedChangesDialog(false);
    if (doCloseFully) onClose();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return useObserver(() => (
    <Dialog onClose={() => handleClose()} open={show} className={classes.root} fullWidth>
      {view === 'edit' ? (
        <DialogTitle onClose={() => handleClose()}>Edit Property</DialogTitle>
      ) : (
        <DialogTitle onClose={() => handleClose()}>Add Property</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>{`Region: ${regionsStore.getSelectedRegion().name}`}</DialogContentText>
        <Paper component="form" elevation={0} color="inverted">
          <FormControl variant="outlined" fullWidth error={!!errors.name} className={classes.formControl}>
            <InputLabel id="name">Name</InputLabel>
            <OutlinedInput
              autoFocus
              aria-describedby="name-error-text"
              fullWidth
              name="name"
              label="Name"
              placeholder="Name"
              onChange={(event) => {
                setName(event.target.value);
              }}
              inputRef={register({ required: true, maxLength: 250 })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="name-error-text">{errors.name?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="name-error-text">{errors.name?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth error={!!errors.street} className={classes.formControl}>
            <InputLabel id="street">Street</InputLabel>
            <OutlinedInput
              aria-describedby="street-error-text"
              fullWidth
              name="street"
              label="Street"
              placeholder="Street Address"
              onChange={(event) => {
                setStreet(event.target.value);
              }}
              inputRef={register({ required: true, maxLength: 250 })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="street-error-text">{errors.street?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="street-error-text">{errors.street?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth error={!!errors.city} className={classes.formControl}>
            <InputLabel id="city">City</InputLabel>
            <OutlinedInput
              aria-describedby="city-error-text"
              fullWidth
              name="city"
              placeholder="City"
              label="City"
              onChange={(event) => {
                setCity(event.target.value);
              }}
              inputRef={register({ required: true, maxLength: 250 })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="city-error-text">{errors.city?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="city-error-text">{errors.city?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth error={!!errors.province} className={classes.formControl}>
            <InputLabel id="province">Province</InputLabel>
            <OutlinedInput
              aria-describedby="province-error-text"
              className={classes.uppercase}
              fullWidth
              name="province"
              label="province"
              placeholder="Province"
              onChange={(event) => {
                setProvince(event.target.value);
              }}
              inputRef={register({ required: true })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="province-error-text">{errors.province?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="province-error-text">{errors.province?.type === 'pattern' && 'Please use the format `XX`'}</FormHelperText>
          </FormControl>
          <FormControl variant="outlined" fullWidth error={!!errors.postalCode} className={classes.formControl}>
            <InputLabel id="postalCode">Postal Code</InputLabel>
            <OutlinedInput
              aria-describedby="postalCode-error-text"
              fullWidth
              name="postalCode"
              placeholder="Postal Code"
              label="postalCode"
              className={classes.uppercase}
              onChange={(event) => {
                setPostalCode(event.target.value);
              }}
              inputRef={register({ required: true })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="postalCode-error-text">{errors.postalCode?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="postalCode-error-text">
              {errors.postalCode?.type === 'pattern' && 'Must be a valid Canadian Postal Code'}
            </FormHelperText>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => handleClose()} variant="outlined" color="primary">
            Cancel
          </Button>
          {view === 'edit' ? (
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
              Update
            </Button>
          ) : (
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
              Save
            </Button>
          )}
        </>
      </DialogActions>
      <UnsavedChangesDialog show={openUnsavedChangesDialog} onClose={(doCloseFully) => handleConfirmClose(doCloseFully)} />
    </Dialog>
  ));
};
