import { guardedClient } from '../../utils/axios-instance';

/**
 * This service function makes an API request to reset the status of all subrows
 * belonging to property with {propertyId} to NOT_STARTED
 * @param {Number} propertyId Block ID where subrows belonging to it will be reset
 * @returns request result
 */
export const resetPropertySubrows = async (propertyId, resetType) => {
  try {
    if (propertyId) {
      const result = await guardedClient.patch('/properties/reset-property', {
        propertyId,
        resetType
      });
      if (result) return result.data;
    }
  } catch (e) {
    console.error(e);
  }

  return null;
};
