import { types } from 'mobx-state-tree';

export const SectionsCache = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string),
  isSolar: types.maybeNull(types.boolean)
});

export const SubsectionsCache = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string)
});

export const SubrowsCache = types.model({
  id: types.maybeNull(types.number),
  name: types.maybeNull(types.string)
});
