import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Dialog } from '@material-ui/core';
import { ROUTE_DIAGNOSTICS } from '../../pages/routes';
import { useStores } from '../../store/root/root.store';

/**
 * Table to display serial numbers of robots in various status like:
 *  - Operational Robots
 *  - Idle Robots
 *  - Robots with blades running
 * Clicking on the serial number redirects user to diagnostic page
 * @param {Array} tableData data containing an array of objects
 * @param {Boolean} show controls dialog rendering
 * @param {Function} handleClose function to handle dialog closing
 * @param {Object} container React Ref object to anchor dialog to parent container
 * @returns React component
 */
const CurrentRobotStatusTable = ({ tableData, show, onClose }) => {
  const { push } = useHistory();
  const { autonomyRobotStore } = useStores();

  /**
   * Function handles moving to diagnositics page with selected robot passed as parameter
   * @param {*} serialNumber
   * @returns {} None
   */
  const handleDiagnosticPage = (serialNumber) => {
    const currentRobot = autonomyRobotStore.getBySerialNumber(serialNumber);
    autonomyRobotStore.setSelectedRobot(currentRobot.id);
    const currentRobot1 = autonomyRobotStore.getSelectedRobot();
    push({ pathname: ROUTE_DIAGNOSTICS, state: currentRobot1 });
  };

  /**
   * Function handles closing MUI dialog and updating the show state properly
   */
  const handleClose = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      onClose();
    }
  };
  return (
    <Dialog open={show} onClose={(event, reason) => handleClose(event, reason)} style={{ position: 'absolute' }}>
      <TableContainer component={Paper} style={{ minWidth: '150px' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow style={{ backgroundColor: '#e3e3de', textAlign: 'center' }}>
              <TableCell>Serial Number</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData.map((row) => (
              <TableRow key={row.serialNumber} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Button value={row.serialNumber} onClick={(e) => handleDiagnosticPage(e.currentTarget.value)}>
                    {' '}
                    {row.serialNumber}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Dialog>
  );
};

export default CurrentRobotStatusTable;
