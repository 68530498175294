import React from 'react';
import { Grid, Paper, Typography, Button } from '@material-ui/core';

/**
 * Error Boundaries must be class components
 */
export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error('Error Caught', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Grid container direction='row' justifyContent='center' alignItems='center' style={{ margin: '20px' }}>
          <Grid item xs={4}>
            <Paper elevation={0} color='inverted'>
              <Grid
                container
                direction='column'
                style={{ padding: '10px' }}
                justifyContent='center'
                alignItems='center'
                spacing={2}
              >
                <Grid container item xs={12}>
                  <Typography variant='h4'>Error</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Typography variant='body1'>We have experienced an unexpected problem.</Typography>
                </Grid>
                <Grid container item xs={12}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='secondary'
                    disableElevation
                    onClick={() => window.location.reload(false)}
                  >
                    Reload
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      );
    }

    return this.props.children;
  }
}
