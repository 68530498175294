import React from 'react';
import {
  makeStyles,
  withStyles,
  Typography,
  Grid,
  Tooltip,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Fade,
  SvgIcon
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import WarningIcon from '@material-ui/icons/Warning';
import PropTypes from 'prop-types';
import { useStores } from '../../store/root/root.store';
import { celsiusToFahrenheit, statusEnum, motorErrorDecoder, DEFAULT_NO_DATA } from '../../utils/ui.utils';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset'
    }
  }
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  body: {
    fontSize: 14,
    fontWeight: 'bold'
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(4n+1)': {
      backgroundColor: '#e0e0e0'
    },
    '&:nth-of-type(4n+2)': {
      backgroundColor: '#e0e0e0'
    }
  }
}))(TableRow);

function Row(props) {
  const { row } = props;
  const [allOpen, setAllOpen] = React.useState(false);
  const [errorsOpen, setErrorsOpen] = React.useState(false);
  const [faultsOpen, setFaultsOpen] = React.useState(false);
  const [warningsOpen, setWarningsOpen] = React.useState(false);
  const classes = useRowStyles();

  function openAll() {
    setAllOpen(true);
    setErrorsOpen(true);
    setFaultsOpen(true);
    setWarningsOpen(true);
  }

  function closeAll() {
    setAllOpen(false);
    setErrorsOpen(false);
    setFaultsOpen(false);
    setWarningsOpen(false);
  }

  function checkAnyOpen() {
    if (allOpen || errorsOpen || faultsOpen || warningsOpen) return true;
    return false;
  }

  let motorMsg = motorErrorDecoder(row.fault);
  const hasMsg = !Object.keys(motorMsg).every((key) => motorMsg[key].length === 0);
  const { autonomyRobotStore } = useStores();
  const hoseCoolingRunning = autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.cooling_mode_enabled;

  return (
    <>
      <StyledTableRow className={classes.root}>
        <StyledTableCell>
          <Tooltip title={hasMsg ? <Typography>{checkAnyOpen() ? 'Collapse All' : 'Show All'}</Typography> : ''}>
            <IconButton
              disabled={!hasMsg}
              aria-label="expand row"
              size="small"
              onClick={() => {
                if (checkAnyOpen()) closeAll();
                else openAll();
              }}
            >
              {checkAnyOpen() ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </Tooltip>
        </StyledTableCell>
        <StyledTableCell component="th" scope="row">
          <b>{row.id}</b>
        </StyledTableCell>
        <StyledTableCell align="right">{row.motor_type !== null ? row.motor_type : DEFAULT_NO_DATA}</StyledTableCell>
        <StyledTableCell align="right">
          {row.not_operational && (
            <Tooltip title={<Typography>Motors Are Not Operational. Contact Autonomy Team Immediately.</Typography>}>
              <SvgIcon style={{ color: '#de2b18', marginRight: '5px' }}>
                <ReportProblemIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {row.status !== null && !row?.not_operational ? statusEnum[row.status] : ''}
        </StyledTableCell>
        <StyledTableCell align="right">
          {row?.motor_temp > 90 && row?.motor_temp < 105 && (
            <Tooltip title={<Typography>The motor temperature is high. You continue running it but keep an eye.</Typography>}>
              <SvgIcon style={{ color: '#eed202', marginRight: '5px' }}>
                <WarningIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {row?.motor_temp >= 105 && (
            <Tooltip
              title={
                <Typography>
                  {' '}
                  <b> Motor Tempreture is TOO high. Stop operations to cool down robot </b>
                </Typography>
              }
            >
              <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                <WarningIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {`${row.motor_temp !== null ? row.motor_temp : DEFAULT_NO_DATA}°C | ${
            row.motor_temp !== null ? celsiusToFahrenheit(row.motor_temp) : DEFAULT_NO_DATA
          }°F`}
        </StyledTableCell>
        <StyledTableCell align="right">
          {row?.mc_temp >= 80 && (
            <Tooltip
              title={
                <Typography>
                  {' '}
                  <b>Motor Controller Tempreture is TOO high. Stop operations to cool down robot</b>
                </Typography>
              }
            >
              <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                <ReportProblemIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {row?.mc_temp > 71 && row?.mc_temp < 80 && (
            <Tooltip
              title={
                <Typography>
                  {' '}
                  <b>Motor Controller Tempreture is high. You can continue but keep an eye</b>
                </Typography>
              }
            >
              <SvgIcon style={{ color: '#eed202', marginRight: '5px' }}>
                <WarningIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {`${row.mc_temp !== null ? row.mc_temp : DEFAULT_NO_DATA}°C | ${
            row.mc_temp !== null ? celsiusToFahrenheit(row.mc_temp) : DEFAULT_NO_DATA
          }°F`}
        </StyledTableCell>
        <StyledTableCell align="right">
          {row.id === 'HYDRAULIC' && row.current <= 15 && row.rpm >= 800 && (
            <Tooltip
              title={
                <Typography>
                  {' '}
                  <b>Stop running the blades, Hyd current should be higher</b>
                </Typography>
              }
            >
              <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                <WarningIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {`${row.current !== null ? row.current : DEFAULT_NO_DATA}A`}
        </StyledTableCell>
        <StyledTableCell align="right">
          {row.id === 'HYDRAULIC' && row.current > 15 && row.rpm < 800 && !hoseCoolingRunning && (
            <Tooltip
              title={
                <Typography>
                  {' '}
                  <b>Stop running the blades, Hyd current should be higher</b>
                </Typography>
              }
            >
              <SvgIcon style={{ color: 'red', marginRight: '5px' }}>
                <WarningIcon />
              </SvgIcon>
            </Tooltip>
          )}
          {row.rpm !== null ? row.rpm : DEFAULT_NO_DATA}
        </StyledTableCell>
        <StyledTableCell align="right">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={motorMsg?.errors?.length ? <Typography>{errorsOpen ? 'Hide ' : 'Show '}Errors</Typography> : ''}>
              <IconButton
                disabled={!motorMsg?.errors?.length}
                aria-label="expand row"
                size="small"
                style={{ color: `${motorMsg?.errors?.length ? 'red' : '#414042'}` }}
                onClick={() => {
                  closeAll();
                  setErrorsOpen(!errorsOpen);
                }}
              >
                {motorMsg?.errors?.length ? errorsOpen ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                <Typography variant="body1">{motorMsg?.errors?.length}</Typography>
              </IconButton>
            </Tooltip>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={motorMsg?.allFaults?.length ? <Typography>{faultsOpen ? 'Hide ' : 'Show '}Faults</Typography> : ''}>
              <IconButton
                disabled={!motorMsg?.allFaults?.length}
                aria-label="expand row"
                size="small"
                style={{ color: `${motorMsg?.allFaults?.length ? 'orange' : '#414042'}` }}
                onClick={() => {
                  closeAll();
                  setFaultsOpen(!faultsOpen);
                }}
              >
                {motorMsg?.allFaults?.length ? faultsOpen ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                <Typography variant="body1">{motorMsg?.allFaults?.length}</Typography>
              </IconButton>
            </Tooltip>
          </Grid>
        </StyledTableCell>
        <StyledTableCell align="right">
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Tooltip title={motorMsg?.warnings?.length ? <Typography>{warningsOpen ? 'Hide ' : 'Show '}Warnings</Typography> : ''}>
              <IconButton
                disabled={!motorMsg?.warnings?.length}
                aria-label="expand row"
                size="small"
                style={{ color: `${motorMsg?.warnings?.length ? '#eed202' : '#414042'}` }}
                onClick={() => {
                  closeAll();
                  setWarningsOpen(!warningsOpen);
                }}
              >
                {motorMsg?.warnings?.length ? warningsOpen ? <ReportProblemOutlinedIcon /> : <WarningOutlinedIcon /> : ''}
                <Typography variant="body1">{motorMsg?.warnings?.length}</Typography>
              </IconButton>
            </Tooltip>
          </Grid>
        </StyledTableCell>
      </StyledTableRow>
      <StyledTableRow>
        <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={!!(errorsOpen && motorMsg?.errors?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Errors</Typography>
            </Box>
            {motorMsg?.errors?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
          <Collapse in={!!(faultsOpen && motorMsg?.allFaults?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Faults</Typography>
            </Box>
            {motorMsg?.allFaults?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
          <Collapse in={!!(warningsOpen && motorMsg?.warnings?.length)} timeout="auto" unmountOnExit>
            <Box mb={1}>
              <Typography variant="h4">Warnings</Typography>
            </Box>
            {motorMsg?.warnings?.map((item, i) => (
              <Typography key={i}>{item}</Typography>
            ))}
            <Box mb={1} />
          </Collapse>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string.isRequired,
    motor_type: PropTypes.string.isRequired,
    rpm: PropTypes.number.isRequired,
    current: PropTypes.number.isRequired,
    motor_temp: PropTypes.number.isRequired,
    mc_temp: PropTypes.number.isRequired,
    status: PropTypes.number.isRequired,
    fault: PropTypes.string.isRequired
  }).isRequired
};

export default function MotorDetails() {
  const { autonomyRobotStore } = useStores();
  return (
    <Fade in={true}>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="collapsible table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell />
              <StyledTableCell>
                <Typography variant="subtitle1">Motor</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Motor Type</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Status</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Motor Temperature</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Motor Controller Temperature</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Current</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">RPM</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Errors</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Faults</Typography>
              </StyledTableCell>
              <StyledTableCell align="right">
                <Typography variant="subtitle1">Warnings</Typography>
              </StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {autonomyRobotStore.getSelectedRobot()?.robot_state?.control_state?.motors?.map((motor, i) => (
              <Row key={i} row={motor} index={i} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
}
