/* eslint-disable no-param-reassign */
import { types, flow, getEnv } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { Subsection } from './models/subsection.model';

const SubsectionStoreInternal = types
  .model('SubsectionStore', {
    subsections: types.array(Subsection),
    selectedSubsectionIdx: types.maybeNull(types.number, -1),
    loading: types.boolean,
    useCase: types.maybeNull(types.string),
    selectedSubsectionId: types.maybeNull(types.integer)
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedSubsectionId,
    getById: (id) => self.subsections.find((r) => r.id === id),
    getByName: (name) => self.subsections.find((el) => el.name === name),
    getSelectedSubsection: () => self.subsections.find((r) => r.id === self.selectedSubsectionId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.fetchSubsections(filter.country, filter.region, filter.property, filter.section);
    }),
    getApiCreate: flow(function* f(subsection) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.addSubsection(subsection);
    }),
    getApiDelete: flow(function* f(subsection) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.deleteSubsection(subsection);
    }),
    onFetchSuccess(results) {
      let filteredResults = results;
      filteredResults = results.filter((r) => r.finished_teaching);
      if (self.useCase) {
        filteredResults = filteredResults?.filter((r) => r.operation_robot === self.useCase);
      }
      filteredResults.sort((a, b) => {
        const p1 = a.order_index;
        const p2 = b.order_index;
        if (p1 < p2) return -1;
        if (p1 > p2) return 1;
        return 0;
      });
      self.subsections = filteredResults;
    },
    onCreateSuccess(created) {
      console.log('success', created);
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load Subsections');
    },
    addSubsection: flow(function* createSubSection(subsection) {
      return yield self.create(subsection);
    }),
    deleteSubsection({ id }) {
      self.subsections = self.subsections
        .filter((sub) => sub.id !== id)
        .sort((a, b) => {
          const p1 = a.order_index;
          const p2 = b.order_index;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        });
      const { RegionsService } = getEnv(self);
      return RegionsService.deleteSubsection(id);
    },
    getSubsections: flow(function* getSubSections(country, region, property, section) {
      yield self.fetch({ country, region, property, section });
    }),
    onCreateError(error) {
      self.throwError(error?.message || 'Failed to create subsection');
    },
    onSaveError(error) {
      // self.throwError(error?.message || 'Failed to delete region');
    },
    filterOnRobot(robot) {
      self.subsections = self.subsections?.filter((r) => r.operation_robot?.includes(robot));
    },
    setSelectedSubsectionIdx(selection) {
      self.selectedSubsectionIdx = selection;
    },
    setSelectedSubsection(id) {
      self.selectedSubsectionId = id;
    },
    setUseCase(useCase) {
      if (useCase !== 'NONE') {
        self.useCase = useCase;
      }
    },
    clearSubsection() {
      console.log('clearSubsection called');
      self.subsections = [];
    },
    clearFilters() {
      self.useCase = null;
    },
    moveUp: flow(function* moveUp(sourceOrderIndex) {
      const sourceIndex = self.subsections.findIndex((el) => el.order_index === sourceOrderIndex);
      const targetIndex = sourceIndex - 1 < 0 ? self.subsections.length - 1 : sourceIndex - 1;
      const sourceSubsection = self.subsections[sourceIndex];
      const targetSubsection = self.subsections[targetIndex];
      const targetOrderIndex = targetSubsection.order_index;

      const { RegionsService } = getEnv(self);
      yield RegionsService.swapSubsections({
        firstId: sourceSubsection.id,
        firstOrderIndex: sourceSubsection.order_index,
        secondId: targetSubsection.id,
        secondOrderIndex: targetSubsection.order_index
      });

      const updatedSubs = self.subsections.map((s) => {
        if (s.id === sourceSubsection.id) {
          return { ...targetSubsection, order_index: sourceOrderIndex };
        }
        if (s.id === targetSubsection.id) {
          return { ...sourceSubsection, order_index: targetOrderIndex };
        }
        return s;
      });
      self.subsections = updatedSubs;
    }),
    moveDown: flow(function* moveDown(sourceOrderIndex) {
      const sourceIndex = self.subsections.findIndex((el) => el.order_index === sourceOrderIndex);
      const targetIndex = sourceIndex + 1 > self.subsections.length - 1 ? 0 : sourceIndex + 1;
      const sourceSubsection = self.subsections[sourceIndex];
      const targetSubsection = self.subsections[targetIndex];
      const targetOrderIndex = targetSubsection.order_index;

      const { RegionsService } = getEnv(self);
      yield RegionsService.swapSubsections({
        firstId: sourceSubsection.id,
        firstOrderIndex: sourceSubsection.order_index,
        secondId: targetSubsection.id,
        secondOrderIndex: targetSubsection.order_index
      });

      const updatedSubs = self.subsections.map((s) => {
        if (s.id === sourceSubsection.id) {
          return { ...targetSubsection, order_index: sourceOrderIndex };
        }
        if (s.id === targetSubsection.id) {
          return { ...sourceSubsection, order_index: targetOrderIndex };
        }
        return s;
      });
      self.subsections = updatedSubs;
    }),
    setRepeatState(region, property, section, subsection, repeat_status, current_robot_serial_number = null) {
      let arr = [...self.subsections];
      let objIndex = arr.findIndex((el) => el.id === subsection);
      arr[objIndex].repeat_status = repeat_status;
      self.subsections = arr;
      let subsectionInfo = { id: arr[objIndex].id, repeat_status, current_robot_serial_number };
      const { RegionsService } = getEnv(self);
      return RegionsService.modifySubsections(subsectionInfo);
    },
    updateSubsection: flow(function* updateSubsection(subsectionInfo) {
      const { RegionsService } = getEnv(self);
      const data = yield RegionsService.modifySubsections(subsectionInfo);
      const newSubsection = {
        ...data.results,
        property_id: subsectionInfo.property_id || self.subsections[0].property_id,
        region_id: subsectionInfo.region_id || self.subsections[0].region_id,
        street_address: self.subsections[0]?.street_address
      };
      self.subsections = [...self.subsections, newSubsection];
    }),
    setError(msg) {
      self.throwError(msg);
    },
    checkDuplicates(name) {
      var exist = self.subsections.some((item) => item.name === name);
      if (exist) return true;
      return false;
    },
    sort() {
      self.subsections.replace(
        self.subsections.slice().sort(function (a, b) {
          var p1 = a.order_index,
            p2 = b.order_index;
          if (p1 < p2) return -1;
          if (p1 > p2) return 1;
          return 0;
        })
      );
    }
  }));

export const SubsectionStore = types.compose(SubsectionStoreInternal, ResourceStore).named('SectionStore');
// @remaining: moveUp moveDown setRepeatState
