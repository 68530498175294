import { Grid } from '@material-ui/core';
import React from 'react';
import { AuditReportsDataDetails } from './audit-reports-data-details.component';
import { AuditReportsDataTable } from './audit-reports-data-table.component';

export const AuditReportsTableView = () => {
  return (
    <Grid item container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={4}>
      <Grid item xs={12} lg={6}>
        <AuditReportsDataTable />
      </Grid>
      <Grid item xs={12} lg={6}>
        <AuditReportsDataDetails />
      </Grid>
    </Grid>
  );
};
