/**
 * Converts a string of format "lat, long" to a position object
 * @param {string} str
 */
export const stringToPosition = (str) => {
  var latstr = str.substring(0, str.indexOf(','));
  var lngstr = str.substring(str.indexOf(',') + 1);
  return {
    lat: parseFloat(latstr),
    lng: parseFloat(lngstr),
  };
};

/**
 * Finds the center of a set of gps coordinates.
 * (Assumes a small enough area that we can assume the Earth is Flat)
 * @param {*} points
 */
export const findCenter = (points = []) => {
  const sum = points.reduce(
    (prev, curr, i) => {
      return { latitude: prev.latitude + curr.latitude, longitude: prev.longitude + curr.longitude };
    },
    { latitude: 0, longitude: 0 }
  );

  return {
    latitude: sum.latitude / points.length,
    longitude: sum.longitude / points.length,
  };
};
