import { Button, IconButton, makeStyles } from '@material-ui/core';
import React from 'react';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import { useStores } from '../../store/root/root.store';
import { useObserver } from 'mobx-react-lite';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  table: {
    overflowY: 'auto',
    height: '100%',
    maxHeight: '100%'
  },
  actions: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5)
  },
  paginationIcon: {
    color: theme.palette.inverted.main
  },
  pagintion: {
    color: theme.palette.inverted.main
  },
  textInput: {
    backgroundColor: theme.palette.inverted.main,
    borderRadius: 5
  },
  tableControls: {
    marginBottom: theme.spacing(2)
  },
  moreButton: {
    textTransform: 'none',
    color: theme.palette.primary.contrastText
  }
}));

export const TablePaginationActions = ({ count, page, rowsPerPage, onChangePage }) => {
  const classes = useStyles();
  const { auditReportsStore } = useStores();

  const handleFirstPageButtonClick = (event) => {
    onChangePage(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onChangePage(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onChangePage(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onChangePage(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return useObserver(() => (
    <div className={classes.actions}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0}>
        <FirstPageIcon className={classes.paginationIcon} />
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0}>
        <KeyboardArrowLeft className={classes.paginationIcon} />
      </IconButton>
      <IconButton onClick={handleNextButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <KeyboardArrowRight className={classes.paginationIcon} />
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={page >= Math.ceil(count / rowsPerPage) - 1}>
        <LastPageIcon className={classes.paginationIcon} />
      </IconButton>
      {auditReportsStore.hasMoreResults ? (
        <Button onClick={() => auditReportsStore.getReports()} className={classes.moreButton}>
          Load More
        </Button>
      ) : null}
    </div>
  ));
};
