import { Grid, InputAdornment, makeStyles, OutlinedInput, TableCell, TablePagination, useMediaQuery, useTheme } from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { format, isBefore } from 'date-fns';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useEnvironment } from '../../environment';
import { useStores } from '../../store/root/root.store';
import { sortTable } from '../../utils/table-sort';
import { StripedTableRow } from '../core/striped-table-row.component';
import { StripedTable } from '../core/striped-table.component';
import { TablePaginationActions } from '../core/table-pagination-actions.component';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%'
  },
  table: {
    overflowY: 'hidden',
    minWidth: '500px'
  },
  tableBody: {},
  tableCell: {
    whiteSpace: 'nowrap'
  },
  pagintion: {
    color: theme.palette.inverted.main
  },
  textInput: {
    backgroundColor: theme.palette.inverted.main,
    borderRadius: 5
  },
  tableControls: {
    marginBottom: theme.spacing(2)
  },
  selected: {
    backgroundColor: theme.palette.secondary.light
  }
}));

const applyFilter = (arr, filter = '') => {
  return arr.filter((r) => r.serial_number?.includes(filter) || r.operator?.includes(filter));
};

const sortRows = (a, b) => {
  return isBefore(new Date(a.created_at), new Date(b.created_at)) ? -1 : 0;
};

export const AuditReportsDataTable = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(16);
  const [tableFilter, setTableFilter] = useState('');
  const [selectedSortParam, setSelectedSortParam] = useState('date:timestamp');
  const [selectedSortType, setSelectedSortType] = useState('asc');
  const { auditReportsStore } = useStores();
  const { defaultDateFormat } = useEnvironment();

  const headers = [
    { name: 'Date', id: 'date:timestamp', isSortable: true },
    { name: 'Time', id: 'date:timestamp', isSortable: true },
    { name: 'Robot', id: 'robot', isSortable: true }
  ];

  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return useObserver(() => {
    const filtered = applyFilter(auditReportsStore.records, tableFilter);
    const rows = filtered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).sort(sortRows);

    return (
      <>
        <Grid item container direction="row" justifyContent="space-between" alignItems="flex-end" className={classes.tableControls}>
          <Grid item xs>
            <OutlinedInput
              id="table-filter"
              placeholder="Filter table results"
              value={tableFilter}
              onChange={(e) => {
                setTableFilter(e.target.value);
                setPage(0);
              }}
              className={classes.textInput}
              fullWidth
              endAdornment={
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              }
            />
          </Grid>
          {isMdUp ? (
            <Grid item xs>
              <TablePagination
                rowsPerPageOptions={[]}
                colSpan={3}
                component="div"
                count={filtered.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                classes={{ caption: classes.pagintion }}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item className={classes.container}>
          <StripedTable
            headers={headers}
            selectedSortParam={selectedSortParam}
            selectedSortType={selectedSortType}
            setSelectedSortParam={setSelectedSortParam}
            setSelectedSortType={setSelectedSortType}
          >
            {sortTable(rows, selectedSortParam, selectedSortType).map((row, i) => (
              <StripedTableRow
                selectable
                hover
                key={i}
                onClick={() => auditReportsStore.selectRecord(row)}
                isSelected={row.id === auditReportsStore.selectedRecord?.id}
              >
                <TableCell>{format(new Date(row.created_at), defaultDateFormat)}</TableCell>
                <TableCell>{format(new Date(row.created_at), 'hh:mm aa')}</TableCell>
                <TableCell>{row.serial_number}</TableCell>
              </StripedTableRow>
            ))}
          </StripedTable>
        </Grid>
        {!isMdUp ? (
          <Grid item xs>
            <TablePagination
              rowsPerPageOptions={[]}
              colSpan={6}
              component="div"
              count={filtered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
              classes={{ caption: classes.pagintion }}
            />
          </Grid>
        ) : null}
      </>
    );
  });
};
