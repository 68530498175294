import React from 'react';
import { Button, Dialog, DialogActions, DialogTitle, DialogContent, Grid, Typography, Slider } from '@material-ui/core';
import { CustomSlider } from '../core/custom-slider.component';
import { LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD, GAMMA, CONTRAST } from '../../services/chaperone/robot-connection.service';

const RepeatSettingsDialog = ({
  isChaperoneRepeat,
  handleClose,
  readOnlyMode,
  setLinearSpeed,
  setLookAheadDistance,
  setAngularSpeed,
  currentLinearSpeed,
  currentLookAheadDistance,
  currentAngularSpeed,
  setGamma,
  currentGamma,
  setContrast,
  currentContrast
}) => {
  const valuetext = (value) => `${value}°C`;

  const changeLinearSpeed = (event, newValue) => {
    setLinearSpeed(newValue);
  };

  const changeLookAheadDistance = (event, newValue) => {
    setLookAheadDistance(newValue);
  };

  const changeAngularSpeed = (event, newValue) => {
    setAngularSpeed(newValue);
  };

  const changeGamma = (event, newValue) => {
    setGamma(newValue);
  };

  const changeContrast = (event, newValue) => {
    setContrast(newValue);
  };

  return (
    <Dialog open fullWidth>
      <DialogTitle>Settings</DialogTitle>
      <DialogTitle>Autonomy</DialogTitle>
      <DialogContent>
        {!isChaperoneRepeat ? (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            {window.innerWidth <= 768 ? (
              <Typography id="input-slider" style={{ minWidth: 300 }}>
                Maximum Linear Speed Limit (Km/hr):
              </Typography>
            ) : (
              <Typography id="input-slider">Maximum Linear Speed Limit (Km/hr):</Typography>
            )}
            <CustomSlider
              disabled={readOnlyMode}
              onMinusClick={() => {
                currentLinearSpeed > LINEAR_SPEED.min &&
                  changeLinearSpeed({}, parseFloat((currentLinearSpeed - LINEAR_SPEED.step).toFixed(2)));
              }}
              onAddClick={() => {
                currentLinearSpeed < LINEAR_SPEED.max &&
                  changeLinearSpeed({}, parseFloat((currentLinearSpeed + LINEAR_SPEED.step).toFixed(2)));
              }}
            >
              <Slider
                disabled={readOnlyMode}
                defaultValue={LINEAR_SPEED.default}
                value={currentLinearSpeed}
                onChange={changeLinearSpeed}
                getAriaValueText={valuetext}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="on"
                min={LINEAR_SPEED.min}
                max={LINEAR_SPEED.max}
                step={LINEAR_SPEED.step}
                style={{ width: 120, margin: '8px' }}
                marks
              />
            </CustomSlider>
          </Grid>
        ) : null}

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography id="input-slider">Maximum Angular Speed Limit (deg/s):</Typography>
          <CustomSlider
            disabled={readOnlyMode}
            onMinusClick={() => {
              currentAngularSpeed >= ANGULAR_SPEED.min &&
                changeAngularSpeed({}, parseFloat((currentAngularSpeed - ANGULAR_SPEED.step).toFixed(2)));
            }}
            onAddClick={() => {
              currentAngularSpeed <= ANGULAR_SPEED.max &&
                changeAngularSpeed({}, parseFloat((currentAngularSpeed + ANGULAR_SPEED.step).toFixed(2)));
            }}
          >
            <Slider
              disabled={readOnlyMode}
              defaultValue={ANGULAR_SPEED.default}
              value={currentAngularSpeed}
              onChange={changeAngularSpeed}
              getAriaValueText={valuetext}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="on"
              min={ANGULAR_SPEED.min}
              max={ANGULAR_SPEED.max}
              step={ANGULAR_SPEED.step}
              style={{ width: 120, margin: '8px' }}
              marks
            />
          </CustomSlider>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography id="input-slider">Lookahead distance(M) [Greater value -> More stable/Less accurate]:</Typography>
          <CustomSlider
            disabled={readOnlyMode}
            onMinusClick={() => {
              currentLookAheadDistance > LOOK_AHEAD.min &&
                changeLookAheadDistance({}, parseFloat((currentLookAheadDistance - LOOK_AHEAD.step).toFixed(2)));
            }}
            onAddClick={() => {
              currentLookAheadDistance < LOOK_AHEAD.max &&
                changeLookAheadDistance({}, parseFloat((currentLookAheadDistance + LOOK_AHEAD.step).toFixed(2)));
            }}
          >
            <Slider
              disabled={readOnlyMode}
              defaultValue={LOOK_AHEAD.default}
              getAriaValueText={valuetext}
              value={currentLookAheadDistance}
              onChange={changeLookAheadDistance}
              aria-labelledby="continuous-slider"
              valueLabelDisplay="on"
              min={LOOK_AHEAD.min}
              max={LOOK_AHEAD.max}
              step={LOOK_AHEAD.step}
              style={{ width: 120, margin: '8px' }}
              marks
            />
          </CustomSlider>
        </Grid>
      </DialogContent>

      {!isChaperoneRepeat ? <DialogTitle>Video Streaming</DialogTitle> : null}

      {!isChaperoneRepeat && (
        <DialogContent>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            {window.innerWidth <= 2868 ? (
              <Typography id="input-slider">Brightness [Lower -> shadows looks brighter & harder to see brighter highlights]:</Typography>
            ) : null}
            <CustomSlider
              onMinusClick={() => {
                currentGamma >= GAMMA.min && changeGamma({}, parseFloat((currentGamma - GAMMA.step).toFixed(2)));
              }}
              onAddClick={() => {
                currentGamma <= GAMMA.max && changeGamma({}, parseFloat((currentGamma + GAMMA.step).toFixed(2)));
              }}
            >
              <Slider
                defaultValue={GAMMA.default}
                value={currentGamma}
                onChange={changeGamma}
                getAriaValueText={valuetext}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="on"
                min={GAMMA.min}
                max={GAMMA.max}
                step={GAMMA.step}
                style={{ width: 120, margin: '8px' }}
                marks
              />
            </CustomSlider>
          </Grid>

          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography id="input-slider">Contrast:</Typography>
            <CustomSlider
              onMinusClick={() => {
                currentContrast >= CONTRAST.min && changeContrast({}, parseFloat((currentContrast - 1).toFixed(2)));
              }}
              onAddClick={() => {
                currentContrast <= CONTRAST.max && changeContrast({}, parseFloat((currentContrast + 1).toFixed(2)));
              }}
            >
              <Slider
                defaultValue={CONTRAST.default}
                value={currentContrast}
                onChange={changeContrast}
                getAriaValueText={valuetext}
                aria-labelledby="continuous-slider"
                valueLabelDisplay="on"
                min={CONTRAST.min}
                max={CONTRAST.max}
                step={CONTRAST.step}
                style={{ width: 120, margin: '8px' }}
                marks
              />
            </CustomSlider>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RepeatSettingsDialog;
