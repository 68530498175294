import React, { useEffect } from 'react';
import { Grid, Box, Typography, FormControl, Select, MenuItem, Chip, makeStyles } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles(() => ({
  propertyText: {
    color: 'black'
  },
  propertySelectionBox: {
    borderTopLeftRadius: '0.5rem',
    borderTopRightRadius: '0.5rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  propertySelect: {
    color: 'black'
  }
}));

export const PropertyDropdown = observer(({ selectedProperties, setSelectedProperties, isEstopPage }) => {
  const { chaperonePropertyStore, autonomyRobotStore, MultiRobotsStore } = useStores();
  const classes = useStyles();

  useEffect(() => {
    chaperonePropertyStore.getProperties('all');
  }, []);

  return (
    <Grid container>
      <Grid item xs={5} container direction="row" justifyContent="flex-start">
        <Box mt={2} p={1} className={classes.propertySelectionBox}>
          <Typography align="left" variant="h6" className={classes.propertyText}>
            Property
          </Typography>
          <Box mx={1}>
            <FormControl sx={{ m: 1, minWidth: 120, backgroundColor: 'white' }}>
              <Select
                labelId="property"
                id="property"
                value={selectedProperties}
                onChange={(event) => {
                  const propertyId = event.target.value.pop();
                  if (propertyId === 'DEFAULT' || propertyId === undefined) {
                    setSelectedProperties([]);
                    localStorage.removeItem('selected-property-filter');
                    autonomyRobotStore.resetSelectedRobots();
                    MultiRobotsStore.resetRobots();
                  } else {
                    setSelectedProperties([...event.target.value, propertyId]);
                    localStorage.setItem('selected-property-filter', [...event.target.value, propertyId]);
                  }
                }}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => {
                      const property = chaperonePropertyStore.getById(value);
                      return <Chip key={property?.id} label={property?.name} className={classes.chip} />;
                    })}
                  </div>
                )}
                className={classes.propertySelect}
                multiple
              >
                <MenuItem value="DEFAULT">No Selection</MenuItem>
                {chaperonePropertyStore.properties.map((p) => (
                  <MenuItem key={p.id} id={p.id} value={p.id}>
                    {p.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
});
