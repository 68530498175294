import { types, flow, getEnv } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { ChaperoneProperty } from './models/chaperone-property.model';
import { alphaNumericOrder } from '../../utils/ui.utils';

const ChaperonePropertyStoreInternal = types
  .model('chaperonePropertyStore', {
    properties: types.array(ChaperoneProperty),
    selectedPropertyId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    getById: (id) => self.properties.find((r) => r.id === parseInt(id)),
    getPropertyAddress: (id) => self.properties.find((r) => r.id === id).street_address,
    getSelectedProperty: () => self.properties.find((r) => r.id === self.selectedPropertyId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(region) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.fetchProperties(region);
    }),
    getApiCreate: flow(function* f(property) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.addProperty(property);
    }),
    getApiUpdate: flow(function* f(property) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.updateProperty(property);
    }),
    deleteProperty({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.deleteProperty(id);
    },
    onFetchSuccess(results) {
      self.properties = results.sort(alphaNumericOrder((p) => p.name));
    },
    onCreateSuccess(created) {
      console.log('sucess');
      // self.properties.push(created);
    },
    onUpdateSuccess(data) {
      console.log('Update Successful', data);
    },
    onFetchError(error) {
      self.throwError(error?.message || 'Unable to load Properties');
    },
    onCreateError(error) {
      self.throwError(error?.message || 'Unable to save Property');
    },
    setError(msg) {
      self.throwError(msg);
    },
    getProperties: flow(function* getProperties(region) {
      return yield self.fetch(region);
    }),
    createProperty: flow(function* createProperty(property) {
      console.log(property);
      return yield self.create(property);
    }),
    updateProperty: flow(function* updateProperty(property) {
      return yield self.update(property);
    }),
    getPropertyById({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.fetchPropertyById(id);
    },
    setSelectedProperty(propertyId) {
      self.selectedPropertyId = parseInt(propertyId) || null;
    },
    clearSelectedProperty() {
      self.selectedPropertyId = null;
    },
    clearProperties() {
      self.properties = [];
    },
    setSection(Section, index) {
      self.properties = self.properties[index].Sections.push[Section];
      console.log(self.properties);
    },
    checkDuplicates(name) {
      var exist = self.properties.some((item) => item.name === name);
      if (exist) return true;
      else return false;
    }
  }));

export const chaperonePropertyStore = types.compose(ChaperonePropertyStoreInternal, ResourceStore).named('chaperonePropertyStore');
