import { types } from 'mobx-state-tree';

export const SafetyState = types.model({
  safety_enabled: types.maybeNull(types.boolean),
  safety_error_message: types.maybeNull(types.string),
  estop_is_on: types.maybeNull(types.boolean),
  sensing_edge_is_on: types.maybeNull(types.boolean),
  safety_stop: types.maybeNull(types.number),
  sw_estop_is_on: types.maybeNull(types.boolean),
  baseboard_is_active: types.maybeNull(types.boolean),
  lockoutState: types.maybeNull(types.boolean),
  is_joy_stale: types.maybeNull(types.boolean)
});
