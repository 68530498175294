/* eslint-disable comma-dangle */
/* eslint-disable no-shadow */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint comma-dangle: ["error", "always-multiline"] */
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { lineAngle, angleToRadians } from 'geometric';
import { makeStyles, Tooltip, Typography, useTheme, withStyles } from '@material-ui/core';
import { drawCircle, drawRectangle, drawTriangle, isWithinBounds } from '../utils/canvas.utils';
import { areClose } from '../utils/compare-util';
import PS4 from './layouts/PS4';
import { getCurrentTime } from '../utils/time.utils';

/**
 * Constants
 */
const CANVAS_BUFFER = 10;
const CANVAS_BUFFER_AXIS = CANVAS_BUFFER / 2;
const INNER_RADIUS_FACTOR = 0.4;
const OUTER_BORDER_WIDTH = 0;
const INNER_BORDER_WIDTH = 2;
const JOYSTICK_INTERVAL_TIME = 10; // in milliseconds

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9'
  }
}))(Tooltip);

const Joystick = ({
  layout = PS4,
  gamepadIndex = 0,
  cwidth = 175,
  cheight = 250,
  // onChangeDelayMsec = 50, // ms
  style = {
    primaryColor: '#667b89',
    secondaryColor: '#000000',
    thumbOnColor: '#ffffff',
    thumbOffColor: '#000000',
    buttonOnColor: '#ffffff',
    buttonOffColor: '#a1a1a1'
  },
  visualized=true,
  handleJoystickState = () => {}
}) => {
  const [canvasContext, setCanvasContext] = useState(null);
  const isMountedRef = useRef(null);

  // Joystick configuration type values
  const agent = window.navigator.userAgent;
  let osName = 'Linux'; // Default to Linux
  if (agent.indexOf('Windows') >= 0) {
    osName = 'Windows';
  } else if (agent.indexOf('Macintosh') >= 0) {
    osName = 'Mac';
  }
  const osLayout = layout[osName];
  const mainyAxisIdx = useRef(osLayout.axes.main.linear.index);
  const mainxAxisIdx = useRef(osLayout.axes.main.angular.index);
  const secondaryyAxisIdx = useRef(osLayout.axes.secondary.linear.index);
  const secondaryxAxisIdx = useRef(osLayout.axes.secondary.angular.index);
  const thumbAngular = useRef(osLayout.axes.thumb.angular);
  const thumbLinear = useRef(osLayout.axes.thumb.linear);

  const buttonLayoutRef = useRef(osLayout.buttons);
  // const rotationAxisIdx = useRef(osLayout.axes.secondary.rotation.index);

  const theme = useTheme();
  const useStyles = makeStyles((theme) => ({
    bold: {
      fontWeight: theme.typography.fontWeightBold
    }
  }));
  const classes = useStyles();

  // Compare the two states and return an object with any changed properties.
  const joystickChanged = (lastJoystick, currentJoystick) =>
    !lastJoystick ||
    !areClose(lastJoystick.mainLinear, currentJoystick.mainLinear) ||
    !areClose(lastJoystick.mainAngular, currentJoystick.mainAngular) ||
    !areClose(lastJoystick.thumbLinear, currentJoystick.thumbLinear) ||
    !areClose(lastJoystick.secondaryTwist, currentJoystick.secondaryTwist) ||
    !areClose(lastJoystick.secondaryLinear, currentJoystick.secondaryLinear) ||
    !areClose(lastJoystick.secondaryAngular, currentJoystick.secondaryAngular) ||
    !areClose(lastJoystick.secondaryTwist, currentJoystick.secondaryTwist) ||
    lastJoystick.trigger !== currentJoystick.trigger ||
    lastJoystick.button2 !== currentJoystick.button2 ||
    lastJoystick.button3 !== currentJoystick.button3 ||
    lastJoystick.button4 !== currentJoystick.button4 ||
    lastJoystick.button5 !== currentJoystick.button5 ||
    lastJoystick.button6 !== currentJoystick.button6 ||
    lastJoystick.button7 !== currentJoystick.button7 ||
    lastJoystick.button8 !== currentJoystick.button8 ||
    lastJoystick.button9 !== currentJoystick.button9 ||
    lastJoystick.button10 !== currentJoystick.button10 ||
    lastJoystick.button11 !== currentJoystick.button11 ||
    lastJoystick.button12 !== currentJoystick.button12 ||
    lastJoystick.button13 !== currentJoystick.button13 ||
    lastJoystick.button14 !== currentJoystick.button14 ||
    lastJoystick.button15 !== currentJoystick.button15 ||
    lastJoystick.button16 !== currentJoystick.button16;

  // Return an object with relevant Joystick control values (handle position and buttions)
  const joystickState = useCallback(() => {
    let currentJoystick = null;
    let gamepads;
    if (navigator.getGamepads) {
      gamepads = navigator.getGamepads();
    } else if (navigator.webkitGetGamepads) {
      gamepads = navigator.webkitGetGamepads();
    } else {
      gamepads = [];
    }
    for (let i = 0; i < gamepads.length; i++) {
      const gp = gamepads[i];
      // If there are any gamepads connected
      if (gp) {
        // PS4
        if (gp.id.includes('Wireless Controller')) {
          gamepadIndex = gp.index;
        } else if (gp.id.includes('Logitech') && !gp.id.includes('Wireless')) {
          gamepadIndex = gp.index;
        }
      }
    }
    const gamepad = navigator.getGamepads()[gamepadIndex];
    if (gamepad) {
      // Capture current state of Joystack handle and button positions
      currentJoystick = {};
      currentJoystick.mainTwist = gamepad.axes[osLayout.axes.main.rotation.index];
      currentJoystick.mainAngular = gamepad.axes[mainxAxisIdx.current];
      currentJoystick.mainX = currentJoystick.mainAngular * 100;
      currentJoystick.mainLinear = gamepad.axes[mainyAxisIdx.current];
      currentJoystick.mainY = currentJoystick.mainLinear * 100;

      currentJoystick.secondaryTwist = gamepad.axes[osLayout.axes.secondary.rotation.index];
      currentJoystick.secondaryAngular = gamepad.axes[secondaryxAxisIdx.current];
      currentJoystick.secondaryX = currentJoystick.secondaryAngular * 100;
      currentJoystick.secondaryLinear = gamepad.axes[secondaryyAxisIdx.current];
      currentJoystick.secondaryY = currentJoystick.secondaryLinear * 100;

      const angularObject = thumbAngular.current;
      let rawValue = gamepad.axes[angularObject.index];
      let value = 0;
      for (const entry of angularObject.valueMap) {
        if (areClose(entry[0], rawValue)) {
          value = entry[1];
          break;
        }
      }
      currentJoystick.thumbAngular = value;

      const linearObject = thumbLinear.current;
      rawValue = gamepad.axes[linearObject.index];
      value = 0;
      for (const entry of linearObject.valueMap) {
        if (areClose(entry[0], rawValue)) {
          value = entry[1];
          break;
        }
      }
      currentJoystick.thumbLinear = value;

      currentJoystick.trigger = gamepad.buttons[buttonLayoutRef.current.trigger.index]?.value;
      currentJoystick.button2 = gamepad.buttons[buttonLayoutRef.current.button2.index]?.value;
      currentJoystick.button3 = gamepad.buttons[buttonLayoutRef.current.button3.index]?.value;
      currentJoystick.button4 = gamepad.buttons[buttonLayoutRef.current.button4.index]?.value;
      currentJoystick.button5 = gamepad.buttons[buttonLayoutRef.current.button5.index]?.value;
      currentJoystick.button6 = gamepad.buttons[buttonLayoutRef.current.button6.index]?.value;
      currentJoystick.button7 = gamepad.buttons[buttonLayoutRef.current.button7.index]?.value;
      currentJoystick.button8 = gamepad.buttons[buttonLayoutRef.current.button8.index]?.value;
      currentJoystick.button9 = gamepad.buttons[buttonLayoutRef.current.button9.index]?.value;
      currentJoystick.button10 = gamepad.buttons[buttonLayoutRef.current.button10.index]?.value;
      currentJoystick.button11 = gamepad.buttons[buttonLayoutRef.current.button11.index]?.value;
      currentJoystick.button12 = gamepad.buttons[buttonLayoutRef.current.button12.index]?.value;
      currentJoystick.button13 = gamepad.buttons[buttonLayoutRef.current.button13.index]?.value;
      currentJoystick.button14 = gamepad.buttons[buttonLayoutRef.current.button14.index]?.value;
      currentJoystick.button15 = gamepad.buttons[buttonLayoutRef.current.button15.index]?.value;
      currentJoystick.button16 = gamepad.buttons[buttonLayoutRef.current.button16.index]?.value;
    }
    return currentJoystick;
  }, [thumbAngular, thumbLinear]);

  // Values for drawing the joystick and buttons
  const [radius] = useState(cwidth / 2 - CANVAS_BUFFER);
  const [center] = useState({ x: cwidth / 2 + CANVAS_BUFFER_AXIS, y: cheight / 3 + CANVAS_BUFFER_AXIS });
  const [innerRadius] = useState(radius * INNER_RADIUS_FACTOR);
  const canvasRef = useRef();

  // Handle drawing and canvas calculations
  // @param joystick object of relevant joystick control values (handle position and button states)
  const draw = useCallback(
    (joystick) => {
      if (!canvasContext) return;
      canvasContext.clearRect(0, 0, canvasRef.current.height, canvasRef.current.width);
      const gradient = canvasContext.createRadialGradient(center.x, center.y, 5, center.x, center.y, radius * 0.7);
      gradient.addColorStop(0, style.secondaryColor);
      gradient.addColorStop(1, style.primaryColor);

      // Background circle
      drawCircle(canvasContext, center.x, center.y, radius, gradient, OUTER_BORDER_WIDTH);

      let [xPos, yPos] = [center.x + joystick.mainX, center.y + joystick.secondaryY];
      if (!isWithinBounds(xPos, yPos, center, radius, innerRadius)) {
        // Constrain the inner circle to the bounds of the outer circle
        const angle = lineAngle([
          [center.x, center.y],
          [xPos, yPos]
        ]);
        const edgeX = center.x + (radius - innerRadius) * Math.cos(angleToRadians(angle));
        const edgeY = center.y + (radius - innerRadius) * Math.sin(angleToRadians(angle));

        xPos = edgeX;
        yPos = edgeY;
      }

      // Joystick
      drawCircle(canvasContext, xPos, yPos, innerRadius, style.primaryColor, INNER_BORDER_WIDTH);

      // Draw the thumb stick controls
      drawTriangle(canvasContext, xPos, yPos + 10, 0, joystick.thumbLinear < 0 ? style.thumbOnColor : style.thumbOffColor);
      drawTriangle(canvasContext, xPos, yPos - 10, 2, joystick.thumbLinear > 0 ? style.thumbOnColor : style.thumbOffColor);

      // No longer using thumb X axis - show as disabled
      drawTriangle(canvasContext, xPos - 10, yPos, 1, theme.palette.grey[800]);
      drawTriangle(canvasContext, xPos + 10, yPos, 3, theme.palette.grey[800]);

      // Draw the additional thumbButtons and trigger
      const smallBtnWidth = 20;
      const smallBtnLength = 30;

      const largeBtnWidth = 30;
      const largeBtnLength = 40;
      // 5 & 3
      drawRectangle(
        canvasContext,
        '5',
        center.x - radius,
        center.y + radius + CANVAS_BUFFER,
        smallBtnLength,
        smallBtnWidth,
        joystick.button5 > 0 ? style.buttonOnColor : style.buttonOffColor
      );
      drawRectangle(
        canvasContext,
        '3',
        center.x - radius + 8,
        center.y + radius + CANVAS_BUFFER + smallBtnLength + 8,
        largeBtnLength,
        largeBtnWidth,
        joystick.button3 > 0 ? style.buttonOnColor : style.buttonOffColor
      );

      // Trigger
      const triggerWidth = 30;
      const triggerLength = 70;
      drawRectangle(
        canvasContext,
        '1',
        center.x - triggerWidth / 2,
        center.y + radius + CANVAS_BUFFER,
        triggerLength,
        triggerWidth,
        joystick.trigger > 0 ? style.buttonOnColor : style.buttonOffColor
      );

      // 4 & 6
      drawRectangle(
        canvasContext,
        '6',
        center.x + radius - smallBtnWidth,
        center.y + radius + CANVAS_BUFFER,
        smallBtnLength,
        smallBtnWidth,
        joystick.button6 > 0 ? style.buttonOnColor : style.buttonOffColor
      );
      drawRectangle(
        canvasContext,
        '4',
        center.x + radius - largeBtnWidth - 8,
        center.y + radius + CANVAS_BUFFER + smallBtnLength + 8,
        largeBtnLength,
        largeBtnWidth,
        joystick.button4 > 0 ? style.buttonOnColor : style.buttonOffColor
      );
    },
    [canvasContext, center, innerRadius, radius, style, theme]
  );

  // Previous joystick state
  const lastJoystick = useRef(null);

  // Regularly check the Joystick handle and button positions for changes. This function is called using an interval timer.
  // The "lastJoystick" useRef tracks the previous state. If it has changed, two things are done:
  //    - redraw the joystick
  //    - calculate the differences by calling stateDiff, and pass that to the parent's onChange function. This is used to generated robot commands.
  const monitorJoystick = useCallback(() => {
    const currentJoystick = joystickState();
    if (!currentJoystick) return;

    // Send joystick state to handler
    handleJoystickState(currentJoystick);

    // Check for change in other joystick state
    if (joystickChanged(lastJoystick.current, currentJoystick)) {
      lastJoystick.current = currentJoystick;
      if (visualized) {
        draw(currentJoystick);
      }
    }
  }, [draw, joystickState, handleJoystickState]);

  /**
   * Set up component, and kick off animation loop
   */
  useEffect(() => {
    isMountedRef.current = true;
    const renderContext = canvasRef.current.getContext('2d');

    if (renderContext && isMountedRef.current) {
      setCanvasContext(renderContext);
    }

    // Animation loop using requestAnimationFrame
    let animationFrameId;
    const animate = () => {
      if (!isMountedRef.current) {
        return;
      }

      // Your animation logic here

      // Call animate again on the next frame
      animationFrameId = requestAnimationFrame(animate);
    };

    // Start the animation loop
    animationFrameId = requestAnimationFrame(animate);

    // Set interval timer for checking Joystick state
    const joystickTimer = setInterval(monitorJoystick, JOYSTICK_INTERVAL_TIME);

    return () => {
      isMountedRef.current = false;
      clearInterval(joystickTimer);
      cancelAnimationFrame(animationFrameId);
    };
  }, [monitorJoystick, JOYSTICK_INTERVAL_TIME]);

  const joystickTooltipTitle = (
    <>
      <Typography className={classes.bold} color="inherit">
        PS4 Controller Help
      </Typography>
      <dl>
        <dt>
          <b>Robot Movement</b>
        </dt>
        <dd>
          Move the <em>right thumbstick</em> up and down to move the robot forwards and backwards and move the <em>left thumbstick </em>
          left and right to turn.
        </dd>
        <dt>
          <br />
          <b>Raise/Lower Plow</b>
        </dt>
        <dd>
          Raise the plow by pressing the <em>up arrow</em> on the d-pad and lower the plow by pressing the <em>down arrow</em> on the d-pad.
        </dd>
        <dt>
          <br />
          <b>Plow Blades</b>
        </dt>
        <dd>
          Move the left plow forwards by pressing the <em>left arow</em> on the d-pad and move the left plow backwards by pressing the{' '}
          <em>left arrow</em> on the d-pad and the <em>L2</em> button.
          <br />
          <br />
          Move the right plow forwards by pressing the <em>right arrow</em> on the d-pad and move the right plow backwards by pressing the{' '}
          <em>right arrow</em> on the d-pad and the <em>L2</em> button.
        </dd>
        <dt>
          <br />
          <b>Salter, Lamp, Beeper and Wiper</b>
        </dt>
        <dd>
          Turn the salter on by pressing the <em>X</em> button. Rate of salt is adjusted using the control on the right sidebar of page.
          Turn the lamp on by pressing the <em>square</em> button. Turn on the beeper by pressing the <em>triangle</em> button. Turn on the
          wiper by pressing the <em>circle</em> button.
        </dd>
      </dl>
      <p>
        <em>Note this graphic mimcs the right PS4 thumbstick movement, but cannot be used to control movement.</em>
      </p>
    </>
  );

  return (
    <HtmlTooltip enterDelay={250} title={joystickTooltipTitle}>
      <div style={{ width: '100%', textAlign: 'center' }}>
        <canvas id="canvas" style={{ display: 'inline' }} ref={canvasRef} width={cwidth} height={cheight} />
      </div>
    </HtmlTooltip>
  );
};

export default Joystick;
