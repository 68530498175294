import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import {
  Grid,
  makeStyles,
  TableCell,
  IconButton,
  Checkbox,
  Tooltip,
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { PlayArrow, DeleteRounded, Settings, SportsEsports } from '@material-ui/icons';
import UpdateIcon from '@material-ui/icons/Update';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { EditRobot } from '../dialogs/edit-robot-dialog';
import ConfirmActionDialog from '../dialogs/confirm-action.dialog';
import { useStores } from '../../store/root/root.store';
import {
  ROUTE_HOME,
  ROUTE_REMOTE_GUARDIAN_REPEAT,
  ROUTE_SOLAR_REMOTE_GUARDIAN_REPEAT,
  ROUTE_DIAGNOSTICS,
  ROUTE_MANAGE_ROBOTS,
  ROUTE_AUDIT_OFFICER,
  ROUTE_CHAPERONE_ESTOP,
  ROUTE_SOLAR_REMOTE
} from '../../pages/routes';
import {
  AVAILABLE,
  IN_USE,
  OFFLINE,
  SOLAR_LAWN_MOWING,
  WPS_SUSPENDED,
  EXEC_SUSPENDED,
  PROVISIONING,
  UNINTIALIZED,
  SENSING_EDGES_BYPASSED,
  TELEOPS,
  CONTROL_LOCKOUT
} from '../../utils/constants';
import { MqttClient } from '../../realtimeClient';
import { sortTable } from '../../utils/table-sort';
import { formatStringForReadbility, formatDateTime, alphaNumericOrder } from '../../utils/ui.utils';
import { getAllUsers } from '../../services/api/users.service';
import { PropertyDropdown } from '../core/property-dropdown.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  marker: {
    margin: theme.spacing(1.25)
  },
  icon: {
    color: 'black',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  form: {
    marginTop: theme.spacing(4)
  },
  checkbox: {
    backgroundColor: '#ddd'
  },
  actionsCell: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  propertyText: {
    color: 'black'
  },
  propertySelectionBox: {
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  propertySelect: {
    color: 'black'
  }
}));

/**
 * @param showPlay Optional boolean to decide whether the play button is displayed or not
 * @param showManageActions Optional boolean to decide whether the manage action buttons are displayed or not
 * @param sortRobots Optional sorting comparison fn that's responsible for robots order in the table
 * @param onSelectRobot An optional callback fired on selecting robot from the table
 * if it's not passed the selected robot id will will be set in the autonomyRobotStore
 */
const RobotsTable = observer(
  ({
    showPlay,
    showManageActions,
    sortRobots,
    onSelectRobot,
    location,
    selectable,
    realTimeUpdate,
    multipleSelect,
    propertyDropDown,
    showOnlineOnly = false
  }) => {
    const classes = useStyles();
    const { push } = useHistory();
    const {
      authenticationStore,
      countriesStore,
      autonomyRobotStore,
      regionsStore,
      chaperonePropertyStore,
      sectionStore,
      blocksStore,
      subBlocksStore,
      subsectionStore,
      subrowStore,
      applicationStore,
      controlStore
    } = useStores();
    const userRole = authenticationStore.role.toLowerCase();
    const areManageActionsDisplayed = ['manager', 'admin'].includes(userRole) && showManageActions;
    const areActionsColumnDisplayed = showPlay || areManageActionsDisplayed;
    const { robots, selectedRobots, selectedAuditRobots } = autonomyRobotStore;
    const useCase = localStorage.getItem('useCase');
    const { selectedRegionId } = regionsStore;
    const { selectedPropertyId } = chaperonePropertyStore;
    const { selectedBlockId } = blocksStore;
    const { selectedSubBlockId } = subBlocksStore;
    const { selectedSectionId } = sectionStore;
    const subsection = subsectionStore.getSelectedSubsection();
    const isHomePage = location === ROUTE_HOME;
    const isDiagnosticsPage = location === ROUTE_DIAGNOSTICS;
    const isEstopPage = location === ROUTE_CHAPERONE_ESTOP;
    const isFullScreen = location === ROUTE_MANAGE_ROBOTS || location === ROUTE_AUDIT_OFFICER;
    const isManageRobotsPage = location === ROUTE_MANAGE_ROBOTS;
    const pagesWithoutPropertyFilter = [ROUTE_SOLAR_REMOTE, ROUTE_HOME];
    const isAdmin = authenticationStore.role === 'admin';
    let robotOptions = robots.filter((r) => r);
    if (!isHomePage && !isDiagnosticsPage && !isManageRobotsPage && !isEstopPage) {
      robotOptions = robotOptions.filter((r) => r.use_case === useCase);
    }
    if (sortRobots) robotOptions = robotOptions.sort(sortRobots);
    const isSolarUseCase = useCase === SOLAR_LAWN_MOWING;
    const selectedPropertiesIds = localStorage.getItem('selected-property-filter');
    let formattedSelectedPropertiesIds = selectedPropertiesIds?.split(',').map((property) => parseInt(property));
    if (pagesWithoutPropertyFilter.includes(location)) {
      formattedSelectedPropertiesIds = [];
    }
    const [selectedProperties, setSelectedProperties] = useState(formattedSelectedPropertiesIds ?? []);
    const [showEditDialog, setShowEditDialog] = useState(false);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedSortParam, setSelectedSortParam] = useState('status');
    const [selectedSortType, setSelectedSortType] = useState('asc');
    const [targetRobot, setTargetRobot] = useState({});
    const [userList, setUserList] = useState([]);
    const [showRemoveCurrentActiveClientDialog, setShowRemoveCurrentActiveClientDialog] = useState(false);
    const [removeCurrentActiveClientBody, setRemoveCurrentActiveClientBody] = useState('');
    const [robotToBeAdded, setRobotToBeAdded] = useState(null);
    const mqttClient = useRef(null);

    useEffect(() => {
      if (realTimeUpdate) {
        (async () => {
          mqttClient.current = new MqttClient(autonomyRobotStore.updateRobotStatus);
          await mqttClient.current.connect();
        })();
      }
      countriesStore.getCountries();
      return () => {
        if (mqttClient.current) {
          mqttClient.current.disconnect();
        }
      };
    }, []);

    const fetchAllUsers = async () => {
      try {
        const data = await getAllUsers();
        setUserList(data.results);
      } catch (e) {
        console.log(e);
      }
    };
    useEffect(() => {
      fetchAllUsers();
    }, []);

    useEffect(() => {
      if (selectedProperties.length > 0) {
        if (isEstopPage) {
          autonomyRobotStore.resetSelectedRobots();
          robots.forEach((robot) => {
            if (
              robot.status !== OFFLINE &&
              selectedProperties?.includes(robot.current_property_id) &&
              !selectedRobots.includes(robot.serial_number)
            ) {
              autonomyRobotStore.setSelectedRobots(robot.serial_number);
            }
          });
        } else {
          const selectedRoboptPropertyId = autonomyRobotStore.getSelectedRobot()?.current_property_id;
          if (selectedRoboptPropertyId && !selectedProperties.includes(selectedRoboptPropertyId)) {
            autonomyRobotStore.clearSelectedRobot();
          }
        }
      }
    }, [selectedProperties.length]);

    const handleShowDeleteRobotDialogue = (robot, event) => {
      event.stopPropagation();
      if (robot.status !== IN_USE) {
        setTargetRobot(robot);
        setShowDeleteDialog(true);
      }
    };
    const handleShowEditRobotDialogue = (robot, event) => {
      event.stopPropagation();
      setTargetRobot(robot);
      setShowEditDialog(true);
      autonomyRobotStore.setSelectedRobot(robot.id);
    };

    const goToRepeatPage = () => {
      push({
        pathname: isSolarUseCase ? ROUTE_SOLAR_REMOTE_GUARDIAN_REPEAT : ROUTE_REMOTE_GUARDIAN_REPEAT,
        state: {
          region: selectedRegionId || subsection.region_id || '',
          property: selectedPropertyId || subsection.property_id || '',
          ...(isSolarUseCase
            ? { block: selectedBlockId, subBlock: selectedSubBlockId }
            : { section: selectedSectionId || subsection.section_id || '' }),
          subsection: subsection.id || '',
          useCase,
          userRole,
          robot: autonomyRobotStore.selectedRobotId,
          pathType: subrowStore.currentPathType
        }
      });
    };

    const handleRemoveCurrentActiveClient = () => {
      controlStore.removeCurrentActiveClient(
        robotToBeAdded,
        () => {
          // onSuccess()
          console.debug('succeeded in disconnecting the active client from ', robotToBeAdded.name);
          autonomyRobotStore.setSelectedRobot(robotToBeAdded.id);
          goToRepeatPage();
        },
        () => {
          // onFailure()
          console.debug('failed to disconnect the active client from ', robotToBeAdded.name);
          applicationStore.pushError(
            'Error',
            'There is still a remote guardian connected to this robot, please report this to the autonomy team if the issue persists'
          );
        }
      );
    };

    const handleGoToRepeatPage = (robot, event) => {
      event.stopPropagation();

      if (robot.status !== IN_USE) {
        setRobotToBeAdded(robot);
        controlStore.getCurrentActiveClient(
          robot,
          (activeClient) => {
            // onSuccess()
            if (activeClient?.uuid !== '') {
              console.debug(`user: ${activeClient?.user} is already connected to ${robot.name}`);
              setRemoveCurrentActiveClientBody(
                `${activeClient.user} is already the remote guardian of ${robot.name}, would you like to disconnect them?`
              );
              setShowRemoveCurrentActiveClientDialog(true);
            } else {
              autonomyRobotStore.setSelectedRobot(robot.id);
              goToRepeatPage();
            }
          },
          () => {
            applicationStore.pushError(
              'Error',
              `An error was encountered while getting ${robot.name}'s current remote guardian, please report this to the autonomy team if the issue persists`
            );
            console.debug('The get_current_active_client service was unsuccessful');
          }
        );
      }
    };

    const handleDeleteRobot = async () => {
      try {
        await autonomyRobotStore.deleteRobot(targetRobot.id);
        setShowDeleteDialog(false);
      } catch (error) {
        console.log(error);
      }
    };

    const handleSelectRobot = (robot) => {
      if (onSelectRobot) {
        onSelectRobot(robot.id);
      } else {
        autonomyRobotStore.setSelectedRobot(robot.id);
      }
    };

    const handleOpenDiagnostics = (robot) => {
      autonomyRobotStore.setSelectedRobot(robot.id);
      push({
        pathname: ROUTE_DIAGNOSTICS
      });
    };

    const handleSelectAction = (robot, action) => {
      autonomyRobotStore.setSelectedAction(robot.serial_number, action);
      handleSelectRobot(robot);
    };

    /**
     * Updates the assignee of a robot based on the selected value from an event target.
     *
     * @param {Event} event - The event object triggered by the selection.
     * @param {Object} robot - The robot object to be updated.
     * @returns {Promise<void>} - A promise that resolves when the robot assignee has been updated successfully.
     */
    const handleSelectedAssignee = async (event, robot) => {
      const selectedAssignee = event.target.value;
      const updatedRobot = { ...robot, assignee: selectedAssignee };
      try {
        await autonomyRobotStore.updateRobotAssignee(updatedRobot);
      } catch (error) {
        console.error(`Error updating robot assignee: ${error}`);
      }
    };
    let headers;
    if (areActionsColumnDisplayed && !isFullScreen) {
      headers = [
        { name: 'Actions', id: 'actions', isSortable: false },
        // { name: 'Name', id: 'name', isSortable: true },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: true },
        { name: 'Control Mode', id: 'control_mode', isSortable: true },
        { name: 'Last Operation Date', id: 'date:last_operation_date', isSortable: true },
        { name: 'Battery Level', id: 'battery_level', isSortable: false },
        { name: 'Last Battery Update', id: 'last_batter_update', isSortable: false }
      ];
    } else if (isDiagnosticsPage) {
      headers = [
        // { name: 'Name', id: 'name', isSortable: true },
        { name: 'Actions', id: 'git_update_state', isSortable: true },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: true },
        { name: 'Control Mode', id: 'control_mode', isSortable: true },
        { name: 'Last Operation Date', id: 'date:last_operation_date', isSortable: true },
        { name: 'Battery Level', id: 'battery_level', isSortable: false }
      ];
    } else if (isEstopPage) {
      headers = [
        { name: 'Actions', id: 'actions', isSortable: false },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: true }
      ];
    } else if (isHomePage) {
      headers = [
        // { name: 'Name', id: 'name', isSortable: true },
        { name: 'Assigned User', id: 'assigned_to', isSortable: false },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: true },
        { name: 'Control Mode', id: 'control_mode', isSortable: true },
        { name: 'Last Operation Date', id: 'date:last_operation_date', isSortable: true },
        { name: 'Battery Level', id: 'battery_level', isSortable: false },
        { name: 'Last Battery Update', id: 'last_batter_update', isSortable: false },
        // { name: 'Use Case', id: 'use_case', isSortable: true },
        { name: 'Current Region', id: 'current_region_name', isSortable: true },
        { name: 'Current Property', id: 'current_property_name', isSortable: true }
      ];
    } else if (isManageRobotsPage) {
      headers = [
        { name: 'Actions', id: 'git_update_state', isSortable: true },
        // { name: 'Name', id: 'name', isSortable: true },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: true },
        { name: 'Control Mode', id: 'control_mode', isSortable: true },
        { name: 'Last Operation Date', id: 'date:last_operation_date', isSortable: true },
        { name: 'Battery Level', id: 'battery_level', isSortable: false },
        { name: 'Last Battery Update', id: 'last_batter_update', isSortable: false },
        // { name: 'Use Case', id: 'use_case', isSortable: true },
        { name: 'Home Region', id: 'home_region', isSortable: false },
        { name: 'Home Property', id: 'home_property', isSortable: false },
        { name: 'Current Region', id: 'current_region_name', isSortable: true },
        { name: 'Current Property', id: 'current_property_name', isSortable: true }
      ];
    } else {
      headers = [
        // { name: 'Name', id: 'name', isSortable: true },
        { name: 'Serial Number', id: 'serial_number', isSortable: true },
        { name: 'Connection Status', id: 'status', isSortable: !isDiagnosticsPage },
        { name: 'Control Mode', id: 'control_mode', isSortable: true },
        { name: 'Last Operation Date', id: 'date:last_operation_date', isSortable: true },
        { name: 'Battery Level', id: 'battery_level', isSortable: false },
        { name: 'Last Battery Update', id: 'last_batter_update', isSortable: false }
      ];
    }

    // Sorts robots by default param, ie serial number, in diagnostics page
    useEffect(() => {
      if (isDiagnosticsPage) {
        setSelectedSortParam('');
      }
    }, [isDiagnosticsPage]);

    return (
      <>
        {propertyDropDown ? (
          <PropertyDropdown selectedProperties={selectedProperties} setSelectedProperties={setSelectedProperties} isEstopPage />
        ) : null}
        <StripedTable
          selectedSortParam={selectedSortParam}
          selectedSortType={selectedSortType}
          setSelectedSortParam={setSelectedSortParam}
          setSelectedSortType={setSelectedSortType}
          headers={headers}
          scrollable
          isFullScreen={isFullScreen}
        >
          {sortTable(robotOptions, selectedSortParam, selectedSortType, isEstopPage).map((robot) => {
            let robotStatusColor;
            let robotUseCaseColor;
            let diasbleActions;
            const currentStatus = robot.status;
            const currentUseCase = robot.use_case;
            if (currentStatus === OFFLINE && showOnlineOnly && (isDiagnosticsPage || isEstopPage)) return null;
            // If selected properties is defined then exclude robots in non selected properties
            if (selectedProperties && selectedProperties.length !== 0 && !selectedProperties.includes(robot.current_property_id)) {
              return null;
            }
            if (currentStatus === AVAILABLE) {
              robotStatusColor = '#2eb885';
              diasbleActions = false;
            } else if (currentStatus === OFFLINE) {
              robotStatusColor = '#4b4b4b';
              diasbleActions = false;
            } else if (currentStatus === WPS_SUSPENDED) {
              robotStatusColor = '#ffd101';
              diasbleActions = false;
            } else if (currentStatus === EXEC_SUSPENDED) {
              robotStatusColor = '#ffd109';
              diasbleActions = false;
            } else if (currentStatus === TELEOPS) {
              robotStatusColor = '#18b1bd';
              diasbleActions = false;
            } else if (currentStatus === PROVISIONING) {
              robotStatusColor = '#c96908';
              diasbleActions = false;
            } else if (currentStatus === UNINTIALIZED) {
              robotStatusColor = '#c93908';
              diasbleActions = false;
            } else if (currentStatus === SENSING_EDGES_BYPASSED) {
              robotStatusColor = '#cc0000';
              diasbleActions = false;
            } else if (currentStatus === CONTROL_LOCKOUT) {
              robotStatusColor = '#fc5151';
              diasbleActions = false;
            } else {
              robotStatusColor = '#03a9f4';
              diasbleActions = true;
            }
            if (currentUseCase === 'SNOW_PLOWING') {
              robotUseCaseColor = '#AEC6CF';
            } else if (currentUseCase === 'SOLAR_LAWN_MOWING') {
              robotUseCaseColor = '#77DD77';
            } else {
              robotUseCaseColor = '#FDFD96';
            }
            return (
              <StripedTableRow
                key={robot.id}
                hover={selectable}
                selectable={selectable}
                isSelected={onSelectRobot ? '' : autonomyRobotStore.selectedRobotId === robot.id}
                onClick={() =>
                  (selectable && !isHomePage && handleSelectRobot(robot)) ||
                  (isHomePage && currentStatus !== OFFLINE && handleOpenDiagnostics(robot))
                }
              >
                {isEstopPage && (
                  <TableCell align="left">
                    <Checkbox
                      className={classes.checkbox}
                      checked={selectedRobots.some((selectedRobot) => selectedRobot === robot.serial_number)}
                      onChange={() => autonomyRobotStore.setSelectedRobots(robot.serial_number)}
                    />
                  </TableCell>
                )}
                {areActionsColumnDisplayed ? (
                  <TableCell align="left">
                    {currentStatus !== OFFLINE && (
                      <>
                        {multipleSelect && currentStatus !== IN_USE && currentStatus !== SENSING_EDGES_BYPASSED && (
                          <Checkbox
                            className={classes.checkbox}
                            onChange={() => autonomyRobotStore.setSelectedRobots(robot.serial_number)}
                            checked={selectedRobots.some((selectedRobot) => selectedRobot === robot.serial_number)}
                            disabled={currentStatus === IN_USE || currentStatus === SENSING_EDGES_BYPASSED}
                            style={{ margin: '3px' }}
                          />
                        )}
                        {isAdmin && !isManageRobotsPage && (
                          <Checkbox
                            className={classes.checkbox}
                            checked={selectedAuditRobots.some((selectedAuditRobot) => selectedAuditRobot === robot.serial_number)}
                            onChange={() => autonomyRobotStore.setSelectedAuditRobots(robot.serial_number)}
                            style={{ margin: '3px ' }}
                          />
                        )}
                      </>
                    )}

                    {areManageActionsDisplayed && (
                      <>
                        <Tooltip title={<Typography>Delete</Typography>}>
                          <span>
                            <IconButton
                              className={classes.icon}
                              disabled={diasbleActions}
                              onClick={(event) => handleShowDeleteRobotDialogue(robot, event)}
                            >
                              <DeleteRounded />
                            </IconButton>
                          </span>
                        </Tooltip>
                        <Tooltip title={<Typography>Edit</Typography>}>
                          <span>
                            <IconButton className={classes.icon} onClick={(event) => handleShowEditRobotDialogue(robot, event)}>
                              <Settings />
                            </IconButton>
                          </span>
                        </Tooltip>
                      </>
                    )}
                    {showPlay &&
                      currentStatus !== OFFLINE &&
                      currentStatus !== IN_USE &&
                      currentStatus !== SENSING_EDGES_BYPASSED &&
                      currentStatus !== CONTROL_LOCKOUT && (
                        <div>
                          <Tooltip title={<Typography>Connect</Typography>}>
                            <span>
                              <IconButton
                                className={classes.icon}
                                disabled={diasbleActions}
                                onClick={(event) => handleGoToRepeatPage(robot, event)}
                              >
                                <PlayArrow />
                              </IconButton>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                  </TableCell>
                ) : null}
                {/* {!isEstopPage && <TableCell align="left">{`${robot.name}`}</TableCell>} */}

                {isHomePage && (
                  <TableCell align="left">
                    <FormControl className={classes.formControl}>
                      <Box onClick={(event) => event.stopPropagation()}>
                        <Select
                          value={robot.assignee || 'None'}
                          className={classes.select}
                          disabled={!isAdmin}
                          onChange={(event) => {
                            event.stopPropagation(); // Stop the event from bubbling up
                            handleSelectedAssignee(event, robot);
                          }}
                          onClick={(event) => {
                            event.stopPropagation(); // Stop the event from bubbling up when clicking on the select component
                          }}
                        >
                          <MenuItem value="None">None</MenuItem>
                          {userList.toSorted(alphaNumericOrder((u) => u['custom:firstName'])).map((user) => (
                            <MenuItem key={user.sub} value={user.email}>
                              {`${user['custom:firstName']} ${user['custom:lastName']}`}
                            </MenuItem>
                          ))}
                        </Select>
                      </Box>
                    </FormControl>
                  </TableCell>
                )}
                {isDiagnosticsPage && (
                  <TableCell align="left">
                    <Grid container spacing={1}>
                      <Grid item>
                        <Tooltip title={<Typography>Config Robot</Typography>}>
                          <IconButton size="small" className={classes.icon} onClick={(event) => handleShowEditRobotDialogue(robot, event)}>
                            <Settings />
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                )}

                {!isEstopPage && (
                  <>
                    <TableCell align="left">{robot.serial_number}</TableCell>
                    <TableCell style={{ backgroundColor: robotStatusColor }} align="left">
                      {robot.status === IN_USE
                        ? formatStringForReadbility(`${IN_USE}  by ${robot.current_user}`)
                        : robot.status === CONTROL_LOCKOUT && robot.lockoutUser
                        ? formatStringForReadbility(`${CONTROL_LOCKOUT} by ${robot.lockoutUser || ''}`)
                        : robot.status === PROVISIONING
                        ? 'NOT-OPERATIONAL'
                        : formatStringForReadbility(robot.status)}
                    </TableCell>
                    <TableCell align="left" justify="center">
                      {robot?.robot_state?.control_state?.control_mode &&
                      robot.robot_state.control_state.control_mode !== 'N/A' &&
                      robot.robot_state.control_state.control_mode !== 'manual' &&
                      robot.robot_state.control_state.control_mode !== 'idle' ? (
                        <>
                          {formatStringForReadbility(robot.robot_state.control_state.control_mode)}
                          {robot.current_user && <>&nbsp; by &nbsp;</>}
                          {robot.current_user ? robot.current_user : ''}
                        </>
                      ) : (
                        formatStringForReadbility(robot?.robot_state?.control_state?.control_mode)
                      )}
                    </TableCell>
                    <TableCell align="left">{formatDateTime(robot.last_operation_date)}</TableCell>
                    <TableCell align="left">
                      {`${robot?.robot_state?.control_state?.battery_state?.percentage || robot.battery_level || 0.0}%`}
                    </TableCell>
                  </>
                )}
                {!isDiagnosticsPage && !isEstopPage && <TableCell align="left">{formatDateTime(robot.last_battery_update_date)}</TableCell>}
                {!isHomePage && !isDiagnosticsPage && !isEstopPage && isManageRobotsPage && (
                  <>
                    <TableCell align="left">{formatStringForReadbility(robot.home_region_name)}</TableCell>
                    <TableCell align="left">{formatStringForReadbility(robot.home_property_name)}</TableCell>
                    <TableCell align="left">{formatStringForReadbility(robot.current_region_name)}</TableCell>
                    <TableCell align="left">{formatStringForReadbility(robot.current_property_name)}</TableCell>
                  </>
                )}
                {isEstopPage && (
                  <>
                    <TableCell align="left">{robot.serial_number}</TableCell>
                    <TableCell style={{ backgroundColor: robotStatusColor }} align="left">
                      {robot.status === IN_USE
                        ? `${IN_USE} by ${robot.current_user}`
                        : robot.status === PROVISIONING
                        ? 'NOT-OPERATIONAL'
                        : formatStringForReadbility(robot.status)}
                    </TableCell>
                  </>
                )}
                {isHomePage && (
                  <>
                    {/* <TableCell style={{ backgroundColor: robotUseCaseColor }} align="left">
                      {robot.use_case}
                    </TableCell> */}
                    <TableCell align="left">{formatStringForReadbility(robot.current_region_name)}</TableCell>
                    <TableCell align="left">{formatStringForReadbility(robot.current_property_name)}</TableCell>
                  </>
                )}
              </StripedTableRow>
            );
          })}
        </StripedTable>
        {showEditDialog ? <EditRobot robot={targetRobot} onClose={() => setShowEditDialog(false)} /> : null}
        <ConfirmActionDialog
          handleAction={handleDeleteRobot}
          handleClose={() => setShowDeleteDialog(false)}
          title={`Delete ${targetRobot?.name ? targetRobot?.name : 'Robot'}`}
          body={`Are you sure you want to delete ${targetRobot?.name ? targetRobot?.name : 'this robot'}?`}
          open={showDeleteDialog}
        />
        <ConfirmActionDialog
          handleAction={() => handleRemoveCurrentActiveClient()}
          handleClose={() => setShowRemoveCurrentActiveClientDialog(false)}
          title={`Boot ${robotToBeAdded?.name}'s Remote Guardian?`}
          body={removeCurrentActiveClientBody}
          open={showRemoveCurrentActiveClientDialog}
          action="Proceed"
        />
      </>
    );
  }
);

export default RobotsTable;
