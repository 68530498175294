import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { isDevMode } from '../utils/ui.utils';

export const useSwitchTab = (fallbackRoute) => {
  const { push } = useHistory();
  useEffect(() => {
    if(isDevMode) return;
    const handleVisibilityChange = () => {
      push(fallbackRoute);
    };
    window.addEventListener('blur', handleVisibilityChange);
    return () => {
      window.removeEventListener('blur', handleVisibilityChange);
    };
  }, []);
};
