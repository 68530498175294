import React from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade } from '@material-ui/core';
import { useStores } from '../../store/root/root.store';
import { DEFAULT_NO_DATA, convertKilobits } from '../../utils/ui.utils';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));

export default function NetworkDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();

  return (
    <Fade in>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Box m={1}>
            <Typography align="center" variant="subtitle1">
              Portal Client Count:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.portal_client_count !== null
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.portal_client_count
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Latency:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.iot_robot_latency !== null
                  ? autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.iot_robot_latency?.toFixed(2)
                  : DEFAULT_NO_DATA}
                ms
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Download Speed:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.download_speed !== null
                  ? convertKilobits(autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.download_speed)
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Upload Speed:{' '}
              <b>
                {autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.upload_speed !== null
                  ? convertKilobits(autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.upload_speed)
                  : DEFAULT_NO_DATA}
              </b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Interface Type: <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.interface_type || DEFAULT_NO_DATA}</b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Last Speed Test:{' '}
              <b>{autonomyRobotStore.getSelectedRobot()?.robot_state?.network_state?.last_speed_test || DEFAULT_NO_DATA}</b>
            </Typography>
          </Box>
        </Grid>
      </Paper>
    </Fade>
  );
}
