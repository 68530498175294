import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { observer } from 'mobx-react-lite';
import { Dialog, DialogTitle, Fab, List, ListItem, ListItemText } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useStores } from '../../../store/root/root.store';
import { OFFLINE } from '../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'absolute',
    bottom: theme.spacing(4),
    right: theme.spacing(4)
  }
}));

/**
 * This component enables audit officers to add robots to the multi-robot audit officer view on the multi-robot audit officer page. ]
 * While similar to the AddRobotToMultiViewComponent, this component does not perform checks for active clients and is property-agnostic.
 * Audit officers can add additional robots as long as their status is not "Offline."
 */
export const AddRobotToMultiAuditView = observer(() => {
  const classes = useStyles();
  const { MultiRobotsStore, autonomyRobotStore } = useStores();
  const [openRobotDialog, setOpenRobotDialog] = useState(false);

  // Adds robot to multi-robots store robots array.
  // The array is mapped into audit-officer component
  const addRobot = (robot) => {
    MultiRobotsStore.addRobot({
      id: robot.id,
      serialNumber: robot.serial_number,
      status: robot.status,
      useCase: robot.use_case,
      operatingSubsectionId: robot.operating_subsection_id,
      name: robot.name,
      batteryLevel: robot.battery_level,
      siteName: robot.current_property_name,
      currentRow: robot.operating_subrow_id
    });
  };

  // Handler for adding robots
  const handleAddRobot = (robot) => {
    setOpenRobotDialog(false);
    addRobot(robot);
  };

  return (
    <>
      <Fab
        color="secondary"
        aria-label="add"
        className={classes.fab}
        onClick={async () => {
          console.log('axiom add button clicked');
          await autonomyRobotStore.getRobots();
          setOpenRobotDialog(true);
        }}
      >
        <Add />
      </Fab>
      {openRobotDialog && (
        <Dialog onClose={() => setOpenRobotDialog(false)} aria-labelledby="simple-dialog-title" open={openRobotDialog}>
          <DialogTitle id="simple-dialog-title">Add Robot</DialogTitle>
          <List>
            {autonomyRobotStore.robots
              .filter((robot) => robot.status !== OFFLINE && !MultiRobotsStore.robots.has(robot.serial_number))
              .map((robot) => (
                <ListItem button onClick={() => handleAddRobot(robot)} key={robot.serial_number}>
                  <ListItemText primary={`${robot.name} - ${robot.serial_number}`} />
                </ListItem>
              ))}
          </List>
        </Dialog>
      )}
    </>
  );
});
