import React from 'react';
import { withStyles, Button, Dialog, Typography, IconButton } from '@material-ui/core';
import { DialogTitle } from '../core/dialog.component';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  }
}))(MuiDialogActions);

export default function UnsavedChangesDialog({ show, onClose = () => {} }) {
  return (
    <div>
      <Dialog open={show} fullWidth maxWidth={'xs'}>
        <DialogTitle>
          <div style={{ color: 'red' }}>You have unsaved changes!</div>
        </DialogTitle>
        <DialogContent>
          <Typography gutterBottom>Are you sure you want to discard your changes?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => onClose()} variant="outlined" color="primary">
            Keep Editing
          </Button>
          <Button autoFocus onClick={() => onClose(true)} variant="contained" color="secondary">
            Discard Changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
