/* eslint-disable no-useless-escape */
import { Button, makeStyles, Grid, Checkbox, FormControlLabel, Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useStores } from '../../store/root/root.store';
import CustomSelectField from '../../components/inputs/CustomSelectField';
import CustomTextField from '../../components/inputs/CustomTextField';
import { ROUTE_ADMIN_LOCATIONS } from '../routes';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  button: {
    margin: theme.spacing(1),
    height: 40,
    width: 150,
    backgroundColor: '##0000FF'
  },
  imageButton: {
    height: 40,
    width: 220,
    backgroundColor: '##0000FF'
  },
  helperText: {
    color: 'red'
  },
  checkbox: {
    color: theme.palette.primary.contrastText
  }
}));

export const AddLocationPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const { countriesStore, regionsStore, chaperonePropertyStore, sectionStore, blocksStore } = useStores();
  const [selectedLocationType, setSelectedLocationType] = useState('');
  const [countryName, setCountryName] = useState('');
  const [regionName, setRegionName] = useState('');
  const [propertyName, setPropertyName] = useState('');
  const [sectionName, setSectionName] = useState('');
  const [solarBlock, setSolarBlock] = useState(false);
  const [streetAddress, setStreetAddress] = useState('');
  const [city, setCity] = useState('');
  const [provinance, setProvinance] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [formError, setFormError] = useState('');
  const { selectedCountryId } = countriesStore;
  const { selectedRegionId } = regionsStore;
  const { selectedPropertyId } = chaperonePropertyStore;

  const LocationTypes = [
    {
      id: 'LOCATION_COUNTRY',
      name: 'Country'
    },
    {
      id: 'LOCATION_REGION',
      name: 'Region'
    },
    {
      id: 'LOCATION_PROPERTY',
      name: 'Property'
    },
    {
      id: 'LOCATION_SECTION',
      name: 'Snnow-Section / Solar-Block'
    }
  ];
  useEffect(() => {
    countriesStore.clearSelectedCountry();
    regionsStore.clearRegions();
    regionsStore.clearSelectedRegion();
    chaperonePropertyStore.clearProperties();
    chaperonePropertyStore.setSelectedProperty(null);
    sectionStore.clearSections();
    sectionStore.setSelectedSection(null);
  }, []);

  const handleSubmit = async () => {
    let success = false;
    // Add Country
    if (selectedLocationType === 'LOCATION_COUNTRY') {
      if (!countryName) {
        setFormError('country');
        return;
      }
      try {
        const exist = countriesStore.checkDuplicates({ name: countryName });
        if (!exist) {
          await countriesStore.createCountry({ name: countryName });
          await countriesStore.getCountries();
          success = true;
        } else {
          regionsStore.setError('This country already exists');
        }
      } catch (e) {
        regionsStore.setError('This country already exists');
      }
    }
    // Add Region
    else if (selectedLocationType === 'LOCATION_REGION') {
      if (!regionName || !selectedCountryId) {
        setFormError('region');
        return;
      }
      try {
        const exist = regionsStore.checkDuplicates({ name: regionName });
        if (!exist) {
          await regionsStore.createRegion({ name: regionName, countryId: selectedCountryId });
          await regionsStore.getRegions(selectedCountryId);
          // chaperonePropertyStore.clearProperties();
          // sectionStore.clearSections();
          success = true;
        } else {
          regionsStore.setError('This region already exists');
        }
      } catch (e) {
        regionsStore.setError('This region already exists');
      }
    }
    // Add Property
    else if (selectedLocationType === 'LOCATION_PROPERTY') {
      if (!propertyName || !streetAddress || !city || !provinance || !postalCode || !selectedRegionId) {
        setFormError('property');
        return;
      }
      try {
        const exist = chaperonePropertyStore.checkDuplicates({ name: propertyName });
        if (!exist) {
          await chaperonePropertyStore.createProperty({
            region: selectedRegionId,
            name: propertyName,
            address: {
              city,
              postalCode,
              province: provinance,
              street: streetAddress
            }
          });
          await chaperonePropertyStore.getProperties(selectedRegionId);
          success = true;
        } else {
          chaperonePropertyStore.setError('This property already exists');
        }
      } catch (e) {
        chaperonePropertyStore.setError('This property already exists');
      }
    }
    // Add Section
    else if (selectedLocationType === 'LOCATION_SECTION') {
      if (!sectionName || !selectedRegionId || !selectedPropertyId) {
        setFormError('section');
        return;
      }
      try {
        let exist = sectionStore.checkDuplicates({ name: sectionName });
        if (solarBlock) exist = blocksStore.checkDuplicates({ name: sectionName });
        if (!exist) {
          if (solarBlock) {
            await blocksStore.addBlock({ region: selectedRegionId, propertyId: selectedPropertyId, name: sectionName });
            await blocksStore.getBlocks(selectedPropertyId);
          } else {
            await sectionStore.addSection({ region: selectedRegionId, propertyId: selectedPropertyId, name: sectionName });
            await sectionStore.getSections(selectedRegionId, selectedPropertyId);
          }
          success = true;
        } else {
          sectionStore.setError('This section already exists');
        }
      } catch (e) {
        sectionStore.setError('This section already exists');
      }
    }
    if (success) push(ROUTE_ADMIN_LOCATIONS);
  };

  const changeSelectedLocationType = async (value) => {
    if (['LOCATION_REGION', 'LOCATION_PROPERTY', 'LOCATION_SECTION'].includes(value)) {
      await countriesStore.getCountries();
    }
    setSelectedLocationType(value);
  };

  const changeSelectedCountry = async (countryId) => {
    if (Number(countryId)) {
      countriesStore.setSelectedCountry(countryId);
      await regionsStore.getRegions(countryId);
    }
  };
  const changeSelectedRegion = async (regionId) => {
    if (regionId) {
      regionsStore.setSelectedRegion(regionId);
      await chaperonePropertyStore.getProperties(regionId);
    }
  };

  return (
    <Fade in>
      <Grid container justifyContent="flex-start" alignItems="flex-start" direction="column" className={classes.root}>
        <CustomSelectField
          label="Location Type"
          id="lcation-type"
          value={selectedLocationType}
          setValue={changeSelectedLocationType}
          options={LocationTypes}
          classes={classes}
          required
        />
        {selectedLocationType === 'LOCATION_COUNTRY' && (
          <CustomTextField
            label="Country"
            id="country"
            value={countryName}
            setValue={setCountryName}
            classes={classes}
            formError={formError}
            required
          />
        )}
        {selectedLocationType === 'LOCATION_REGION' && (
          <>
            <CustomSelectField
              label="Country"
              id="country"
              value={selectedCountryId}
              setValue={changeSelectedCountry}
              options={countriesStore.countries}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField
              label="Region Name"
              id="region-name"
              value={regionName}
              setValue={setRegionName}
              classes={classes}
              formError={formError}
              required
            />
          </>
        )}
        {selectedLocationType === 'LOCATION_PROPERTY' && (
          <>
            <CustomSelectField
              label="Country"
              id="country"
              value={selectedCountryId}
              setValue={changeSelectedCountry}
              options={countriesStore.countries}
              classes={classes}
              formError={formError}
              required
            />
            <CustomSelectField
              label="Region"
              id="region"
              value={selectedRegionId}
              setValue={changeSelectedRegion}
              options={regionsStore.regions.map((rg) => rg)}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField
              label="Property Name"
              id="property-name"
              value={propertyName}
              setValue={setPropertyName}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField
              label="Street Address"
              id="street-address"
              value={streetAddress}
              setValue={setStreetAddress}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField label="City" id="city" value={city} setValue={setCity} classes={classes} formError={formError} required />
            <CustomTextField
              label="Province"
              id="province"
              value={provinance}
              setValue={setProvinance}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField
              label="Postal Code"
              id="postal-code"
              value={postalCode}
              setValue={setPostalCode}
              classes={classes}
              formError={formError}
              required
            />
          </>
        )}
        {selectedLocationType === 'LOCATION_SECTION' && (
          <>
            <CustomSelectField
              label="Country"
              id="country"
              value={selectedCountryId}
              setValue={changeSelectedCountry}
              options={countriesStore.countries}
              classes={classes}
              formError={formError}
              required
            />
            <CustomSelectField
              label="Region"
              id="section-region"
              value={selectedRegionId}
              setValue={changeSelectedRegion}
              options={regionsStore.regions.map((rg) => rg)}
              classes={classes}
              formError={formError}
              required
            />
            <CustomSelectField
              label="Property"
              id="property"
              value={selectedPropertyId}
              setValue={chaperonePropertyStore.setSelectedProperty}
              options={chaperonePropertyStore.properties.map((pr) => pr)}
              classes={classes}
              formError={formError}
              required
            />
            <CustomTextField
              label="Section Name"
              id="section-name"
              value={sectionName}
              setValue={setSectionName}
              classes={classes}
              formError={formError}
              required
            />
            <FormControlLabel
              label="Solar Block?"
              control={
                <Checkbox checked={solarBlock} onChange={(e) => setSolarBlock(Boolean(e.target.checked))} className={classes.checkbox} />
              }
              className={classes.checkbox}
            />
          </>
        )}
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Button onClick={() => push(ROUTE_ADMIN_LOCATIONS)} className={classes.button} variant="contained" color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} className={classes.button} variant="contained">
            Save
          </Button>
        </Grid>
      </Grid>
    </Fade>
  );
});
