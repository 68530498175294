import { Button, Grid, makeStyles, Typography, Fade } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_REPEAT, ROUTE_TEACH_SUBSECTION } from './routes';
import { useStores } from '../store/root/root.store';
import ReloadIcon from '../components/reload/reload-icon';
import ManageLocations from '../components/ManageLocations';
import SubsectionsTable from '../components/tables/SubsectionsTable';
import RealtimeClient from '../realtimeClient';
import { SNOW_CH_PREFIX } from '../utils/localstorage.controller';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150,
    fontSize: '36px'
  },
  teachButton: {
    marginTop: theme.spacing(1),
    marginDown: theme.spacing(1),
    height: 80,
    width: 200,
    border: '1px solid #ddd',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  }
}));

export const ChaperonePage = observer(() => {
  const { countriesStore, regionsStore, sectionStore, chaperonePropertyStore, subsectionStore, authenticationStore, autonomyRobotStore } =
    useStores();
  const classes = useStyles();
  const { push } = useHistory();
  const country = countriesStore.getSelectedCountry()?.id;
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const section = sectionStore.getSelectedSection()?.id;
  const selectedRobot = autonomyRobotStore.getSelectedRobot()?.id;
  const useCase = localStorage.getItem('useCase');
  const isManager = !!(authenticationStore.role === 'manager' || authenticationStore.role === 'admin');

  const getSubsections = async () => {
    const selectedCountry = country && country !== 'NONE' ? country : undefined;
    const selectedProperty = property && property !== 'NONE' ? property : undefined;
    const selectedSection = section && section !== 'NONE' ? section : undefined;
    const selectedRegion = region && region !== 'NONE' ? region : undefined;
    await subsectionStore.getSubsections(selectedCountry, selectedRegion, selectedProperty, selectedSection);
  };

  const handleTeachSubsection = () => {
    autonomyRobotStore.getRobots();
    const state = autonomyRobotStore.getSelectedRobot()?.status;
    push({ pathname: ROUTE_TEACH_SUBSECTION, loc: { state, region, property, section, useCase } });
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  useEffect(() => {
    const client = RealtimeClient();
    client.connect().then(() => {
      client.onMessageReceived((robotSerialNumber, status, user, currentSubsection, _, timestamp) => {
        autonomyRobotStore.updateRobotStatus(robotSerialNumber, status, user, currentSubsection, _, timestamp);
      });
    });
    return () => {
      if (client) {
        client.closeConnection();
      }
    };
  }, []);

  useEffect(() => {
    getSubsections();
  }, [country, region, property, section]);

  const canEnterTeachSection = () => region && property && section && selectedRobot;
  return (
    <Fade in={true}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <ManageLocations pagePrefix={SNOW_CH_PREFIX} />
        {isManager && (
          <Button
            className={classes.teachButton}
            onClick={handleTeachSubsection}
            variant="contained"
            disableElevation
            color="secondary"
            disabled={!canEnterTeachSection()}
          >
            Teach SUB-SECTION
          </Button>
        )}
        <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.label}>
              Subsections
            </Typography>
            <ReloadIcon title="Reload subsections" handler={getSubsections} style={{ color: 'white' }} />
          </Grid>
          <SubsectionsTable repeatPath={ROUTE_REPEAT} />
        </Grid>
      </Grid>
    </Fade>
  );
});
