import React, { useEffect, useState } from 'react';
import { Button, FormControl, Grid, makeStyles, Typography, CardMedia, Fade } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { ROUTE_ADMIN_USERS } from '../routes';
import CustomTextField from '../../components/inputs/CustomTextField';
import { ROLES_LIST } from '../../utils/constants';
import CustomSelectField from '../../components/inputs/CustomSelectField';
import { guardedClient } from '../../utils/axios-instance';
import { addUser } from '../../services/api/users.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  button: {
    margin: theme.spacing(1),
    height: 40,
    width: 150,
    backgroundColor: '##0000FF'
  },
  imageButton: {
    height: 40,
    width: 220,
    backgroundColor: '##0000FF'
  },
  helperText: {
    color: 'red'
  }
}));

export const AddUserPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const [formError, setFormError] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState('');
  const [country, setCountry] = useState('');
  const [countries, setCountries] = useState([]);
  const [region, setRegion] = useState('');
  const [regions, setRegions] = useState([]);
  const [property, setProperty] = useState('');
  const [properties, setProperties] = useState([]);
  const [fileInput, setFileInput] = useState(null);
  const [fileSrc, setFileSrc] = useState(null);
  const [duplicateUser, setDuplicateUser] = useState(false);

  useEffect(() => {
    console.log(role);
    if (role === 'client') {
      guardedClient
        .get('/countries')
        .then((res) => setCountries(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [role]);

  useEffect(() => {
    if (country) {
      guardedClient
        .get('/regions', { params: { country } })
        .then((res) => setRegions(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [country]);

  useEffect(() => {
    if (region) {
      guardedClient
        .get('/properties', { params: { region } })
        .then((res) => setProperties(res.data.results || []))
        .catch((err) => console.log(err));
    }
  }, [region]);

  const handleAddUser = async () => {
    if (!username || !email || !role) {
      setFormError(true);
      return;
    }
    try {
      let profilePicPath = ' ';
      if (fileInput) {
        const formdata = new FormData();
        formdata.append('file', fileInput, fileInput.name);
        let result = await guardedClient.post('/file-manager/profile', formdata);
        profilePicPath = result.data?.data?.Location;
        console.log(profilePicPath);
      }
      const data = {
        firstName,
        lastName,
        email,
        username,
        role,
        country: String(country) || ' ',
        region: String(region) || ' ',
        properties: String(property) || ' ',
        profilePicPath
      };
      if (role === 'client') {
        data.sendEmailConfirmatin = false;
        data.password = 'Test@1234';
      } else {
        data.sendEmailConfirmatin = true;
      }
      await addUser(data);
      push(ROUTE_ADMIN_USERS);
    } catch (error) {
      if (error.response.status === 409) {
        setDuplicateUser(true);
      }
      console.log('error', error);
    }
    console.log('handleAddUser');
  };

  const getImageSrc = (file) => {
    if (file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        console.log(e);
        setFileSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSelectImage = (file) => {
    setFileInput(file);
    getImageSrc(file);
  };

  return (
    <Fade in={true}>
      <Grid container justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <Grid item xs={9}>
          <Typography variant="h3" className={classes.titleLable}>
            New User Details
          </Typography>
          {/* user info goes here */}
          <CustomTextField
            label="Username"
            id="username"
            value={username}
            setValue={setUsername}
            classes={classes}
            formError={formError}
            required={true}
          />
          <CustomTextField
            label="Email"
            id="user-email"
            value={email}
            setValue={setEmail}
            classes={classes}
            formError={formError}
            required={true}
            customErrorMessage={duplicateUser ? 'A user with this email already exists' : null}
          />
          <CustomTextField
            label="First Name"
            id="first-name"
            value={firstName}
            setValue={setFirstName}
            classes={classes}
            formError={formError}
            required={false}
          />
          <CustomTextField
            label="Last Name"
            id="last-name"
            value={lastName}
            setValue={setLastName}
            classes={classes}
            formError={formError}
            required={false}
          />
          <CustomSelectField
            label="Role"
            id="role"
            value={role}
            setValue={setRole}
            options={ROLES_LIST}
            classes={classes}
            formError={formError}
            required={true}
          />
          {role === 'client' && (
            <>
              <CustomSelectField
                label="Country"
                id="country"
                value={country}
                setValue={setCountry}
                options={countries}
                classes={classes}
                formError={formError}
                required={true}
              />
              <CustomSelectField
                label="Region"
                id="region"
                value={region}
                setValue={setRegion}
                options={regions}
                classes={classes}
                formError={formError}
                required={true}
              />
              <CustomSelectField
                label="Property"
                id="property"
                value={property}
                setValue={setProperty}
                options={properties}
                classes={classes}
                formError={formError}
                required={true}
              />
            </>
          )}
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h4" className={classes.titleLable}>
            Profile Picture
          </Typography>
          {/* Profile Pic here */}
          {fileSrc && (
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs={12}>
              <CardMedia component="img" alt="Profile Picture" src={fileSrc} height="250" width="250" className={classes.cardMedia} />
            </Grid>
          )}
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <FormControl className={classes.formControlTextField}>
              <input
                className={classes.input}
                style={{ display: 'none' }}
                onChange={(e) => handleSelectImage(e.target.files[0])}
                id="raised-button-file"
                type="file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" color="secondary" component="span" className={classes.imageButton}>
                  Select Image
                </Button>
              </label>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Button onClick={() => handleAddUser()} className={classes.button} variant="contained" color="secondary">
            Save
          </Button>
          <Button onClick={() => push(ROUTE_ADMIN_USERS)} className={classes.button} variant="contained">
            cancel
          </Button>
        </Grid>
      </Grid>
    </Fade>
  );
});
