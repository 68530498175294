/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  Fade,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import OperationLineChart from '../../components/AdminDashboard/LineChart';
import InfoTile from '../../components/AdminDashboard/InfoTile';
import { extendedTimeoutClient } from '../../utils/axios-instance';
import OperationPieChart, { colorForPieChartArray } from '../../components/AdminDashboard/PieChart';
import ReloadIcon from '../../components/reload/reload-icon';
import { DistanceCategory, MetricsCategory, TimeCategory, DownTimeCategory, robotCurrentStatus } from '../../utils/constants';
import StackedVerticalBarChart from '../../components/AdminDashboard/StackedVerticalBarChart';
import ExportIcon from '../../components/export/export-icon';
import { constructExcel, autoWidth, mergeEqualAdjacentCellsInColumn, saveExcelWorkook } from '../../utils/excel.utils';
import { formatDateTime, formatStringForReadbility } from '../../utils/ui.utils';
import { convertDistanceInKmToAcres, getOperationsExcelColumns } from '../../utils/operations-kpis.utils';
import CurrentRobotStatusTable from '../../components/tables/CurrentRobotStatusTable';

const useStyles = makeStyles((theme) => ({
  settingButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    '& .MuiIconButton-label': {
      '& svg': {
        fontSize: '3rem',

        color: theme.palette.inverted.main
      }
    }
  },
  toggleContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(1)
  },
  toggleGroup: {
    backgroundColor: theme.palette.inverted.main
  },
  dialog: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  chartContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: '500px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.inverted.main,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: 300
  },
  modalCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    '& > button': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  pieChartContainer: {
    margin: '50px 10px',
    padding: '5px 10px 15px 10px',
    color: '#FFF',
    border: '1px solid #fff',
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: '8px'
  },
  pieChartHeader: { color: '#fff', textAlign: 'center' },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  select: {
    width: '70%'
  },
  inputLabel: {
    marginTop: '6px'
  },
  tileDataRefreshContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    maxHeight: '40px',
    boxSizing: 'border-box',
    marginBottom: '5px'
  },
  lastUpdated: {
    marginRight: '1em'
  }
}));

const aggregateHistoryDataOnTime = (historyData) => {
  const dataGroupedByTime = historyData.reduce((groups, dataPoint) => {
    // eslint-disable-next-line no-param-reassign
    groups[dataPoint.time] = groups[dataPoint.time] ?? [];
    groups[dataPoint.time].push({ ...dataPoint });
    return groups;
  }, {});

  const res = {};
  Object.keys(dataGroupedByTime).forEach((time) => {
    res[time] = dataGroupedByTime[time].reduce((acc, dataPoint, idx) => {
      if (idx === 0) return dataPoint;

      acc.time = dataPoint.time;

      const totalNumberOfSamples = acc['Number of speed samples'] + dataPoint['Number of speed samples'];
      acc['Avg Speeds'] =
        totalNumberOfSamples > 0
          ? (acc['Avg Speeds'] * acc['Number of speed samples'] + dataPoint['Avg Speeds'] * dataPoint['Number of speed samples']) /
            totalNumberOfSamples
          : 0;
      acc['Avg Speeds on-blades'] =
        totalNumberOfSamples > 0
          ? (acc['Avg Speeds on-blades'] * acc['Number of speed samples'] +
              dataPoint['Avg Speeds on-blades'] * dataPoint['Number of speed samples']) /
            totalNumberOfSamples
          : 0;
      acc['Number of speed samples'] = totalNumberOfSamples;

      acc['Robots Turned-on'] += dataPoint['Robots Turned-on'];
      acc['Robots on-blades'] += dataPoint['Robots on-blades'];

      acc['Total Autonomous Distance'] += dataPoint['Total Autonomous Distance'];
      acc['Total Teleops Distance'] += dataPoint['Total Teleops Distance'];
      acc['Total Chaperone Distance'] += dataPoint['Total Chaperone Distance'];

      acc['Total Autonomous Mowing Distance'] += dataPoint['Total Autonomous Mowing Distance'];
      acc['Total Teleops Mowing Distance'] += dataPoint['Total Teleops Mowing Distance'];
      acc['Total Chaperone Mowing Distance'] += dataPoint['Total Chaperone Mowing Distance'];

      acc['Total Autonomous Time'] += dataPoint['Total Autonomous Time'];
      acc['Total Teleops Time'] += dataPoint['Total Teleops Time'];
      acc['Total Chaperone Time'] += dataPoint['Total Chaperone Time'];

      acc['Total Autonomous Mowing Time'] += dataPoint['Total Autonomous Mowing Time'];
      acc['Total Teleops Mowing Time'] += dataPoint['Total Teleops Mowing Time'];
      acc['Total Chaperone Mowing Time'] += dataPoint['Total Chaperone Mowing Time'];

      acc['Total Autonomous Mowing Down Time'] += dataPoint['Total Autonomous Mowing Down Time'];
      acc['Total Teleops Mowing Down Time'] += dataPoint['Total Teleops Mowing Down Time'];
      acc['Total Chaperone Mowing Down Time'] += dataPoint['Total Chaperone Mowing Down Time'];

      return acc;
    }, {});
  });
  return Object.values(res).sort((a, b) => new Date(a.time) - new Date(b.time));
};

const aggregateHistoryDataOnRobot = (historyData) => {
  const dataGroupedByRobot = historyData.reduce((groups, dataPoint) => {
    // eslint-disable-next-line no-param-reassign
    groups[dataPoint.serial_number] = groups[dataPoint.serial_number] ?? [];
    groups[dataPoint.serial_number].push({ ...dataPoint });
    return groups;
  }, {});

  const res = {};
  Object.keys(dataGroupedByRobot).forEach((serialNumber) => {
    res[serialNumber] = dataGroupedByRobot[serialNumber].reduce((acc, dataPoint, idx) => {
      if (idx === 0) return dataPoint;

      acc.serial_number = dataPoint.serial_number;

      acc['Total Autonomous Distance'] += dataPoint['Total Autonomous Distance'];
      acc['Total Teleops Distance'] += dataPoint['Total Teleops Distance'];
      acc['Total Chaperone Distance'] += dataPoint['Total Chaperone Distance'];

      acc['Total Autonomous Mowing Distance'] += dataPoint['Total Autonomous Mowing Distance'];
      acc['Total Teleops Mowing Distance'] += dataPoint['Total Teleops Mowing Distance'];
      acc['Total Chaperone Mowing Distance'] += dataPoint['Total Chaperone Mowing Distance'];

      acc['Total Autonomous Time'] += dataPoint['Total Autonomous Time'];
      acc['Total Teleops Time'] += dataPoint['Total Teleops Time'];
      acc['Total Chaperone Time'] += dataPoint['Total Chaperone Time'];

      acc['Total Autonomous Mowing Time'] += dataPoint['Total Autonomous Mowing Time'];
      acc['Total Teleops Mowing Time'] += dataPoint['Total Teleops Mowing Time'];
      acc['Total Chaperone Mowing Time'] += dataPoint['Total Chaperone Mowing Time'];
      return acc;
    }, {});
  });
  // filter robots with zero distances
  Object.keys(res).forEach((serialNumber) => {
    if (
      res[serialNumber]['Total Autonomous Distance'] === 0 &&
      res[serialNumber]['Total Teleops Distance'] === 0 &&
      res[serialNumber]['Total Chaperone Distance'] === 0
    ) {
      delete res[serialNumber];
    }
  });
  return Object.values(res) || [];
};

const aggregateDownTimeHistoryDataOnRobot = (historyData) => {
  const dataGroupedByRobot = historyData.reduce((groups, dataPoint) => {
    // eslint-disable-next-line no-param-reassign
    groups[dataPoint.serial_number] = groups[dataPoint.serial_number] ?? [];
    groups[dataPoint.serial_number].push({ ...dataPoint });
    return groups;
  }, {});

  const res = {};
  Object.keys(dataGroupedByRobot).forEach((serialNumber) => {
    res[serialNumber] = dataGroupedByRobot[serialNumber].reduce((acc, dataPoint, idx) => {
      if (idx === 0) return dataPoint;

      acc.serial_number = dataPoint.serial_number;

      acc['Total Autonomous Mowing Down Time'] += dataPoint['Total Autonomous Mowing Down Time'];
      acc['Total Teleops Mowing Down Time'] += dataPoint['Total Teleops Mowing Down Time'];
      acc['Total Chaperone Mowing Down Time'] += dataPoint['Total Chaperone Mowing Down Time'];
      return acc;
    }, {});
  });
  // filter robots with zero chaperone downtime (that means robots were offline)
  Object.keys(res).forEach((serialNumber) => {
    if (res[serialNumber]['Total Chaperone Mowing Down Time'] === 0) {
      delete res[serialNumber];
    }
  });
  return Object.values(res) || [];
};

const getHistoryChartDataArrays = (initialForm) => {
  if (!initialForm?.length) return null;
  const finalForm = Object.keys(initialForm[0]).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
  initialForm.forEach((element) => {
    Object.keys(finalForm).forEach((key) => finalForm[key].push(element[key]));
  });
  return finalForm;
};

const getPieChartDataArrays = (initialForm) => {
  if (!initialForm) return [];

  const finalForm = Object.keys(initialForm).map((field) => {
    const mainData = { data: [], backgroundColor: [], labels: [], types: [], numbers: [], borderWidth: 0 };
    let colorCounter = 0;
    initialForm[field].forEach((dataSet) => {
      mainData.data.push(dataSet.value ? Number.parseFloat(dataSet.value.toFixed(2)) : dataSet.value);
      mainData.labels.push(dataSet.title);
      mainData.types.push(dataSet.type);
      mainData.numbers.push(dataSet.number);
      mainData.backgroundColor.push(colorForPieChartArray[colorCounter].borderColor);
      colorCounter += 1;
    });
    mainData.borderColor = mainData.backgroundColor;
    return { title: field, dataSet: [mainData] };
  });
  return finalForm;
};

const OperationsPage = observer(() => {
  const { autonomyRobotStore, chaperonePropertyStore } = useStores();

  const [opsTilesData, setOpsTilesData] = useState([]);
  const [opsHistoryDataResponse, setOpsHistoryDataResponse] = useState();
  const [opsHistoryData, setOpsHistoryData] = useState();
  const [opsAcreagesByRobotData, setOpsAcreagesByRobotData] = useState();
  const [mowingDownTimeByRobotData, setMowingDownTimeByRobotData] = useState();
  const [opsPieData, setOpsPieData] = useState([]);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [startTime, setStartTime] = useState(() => {
    const now = new Date();

    // Get current UTC time
    const utcNow = new Date(now.getTime() + now.getTimezoneOffset() * 60000);

    // Convert UTC time to EST time (UTC-5)
    const estOffset = -5 * 60 * 60 * 1000;
    const estNow = new Date(utcNow.getTime() + estOffset);

    // Get today's date in EST and set the time to 6 AM
    const estToday = new Date(estNow);
    estToday.setHours(6, 0, 0, 0);

    // If current EST time is after 6 AM, use today 6 AM
    // If before 6 AM, use 6 AM of the previous day
    if (estNow.getHours() >= 6) {
      return estToday.getTime();
    } else {
      estToday.setDate(estToday.getDate() - 1);
      return estToday.getTime();
    }
  });
  const [endTime, setEndTime] = useState(new Date().getTime());
  const [endTimeChanged, setEndTimeChanged] = useState(false);
  const upToDateEndTime = useMemo(() => (endTimeChanged ? endTime : new Date().getTime()), [endTime, endTimeChanged]);
  const [rangeUnit, setRangeUnit] = useState('hours');
  const [rangeValue, setRangeValue] = useState(1);
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [settingsUpdated, setSettingsUpdated] = useState(true);
  const [metricsCategory, setMetricsCategory] = useState(MetricsCategory.Acreages);
  const [distancesCategory, setDistancesCategory] = useState(DistanceCategory.Acreages);
  const [timeCategory, setTimeCategory] = useState(TimeCategory.Ops);
  const [downTimeCategory, setDownTimeCategory] = useState(DownTimeCategory.Mowing);
  const [showCurrentOperatingRobots, setshowCurrentOperatingRobots] = useState(false);
  const [robotWithCurrentStatus, setrobotWithCurrentStatus] = useState([]);
  const [selectedToolTypes, setSelectedToolTypes] = useState([]);
  const [refreshTileData, setRefreshTileData] = useState(false);
  const [isLoading, setLoadingList] = useState({});
  const [lastUpdated, setLastUpdated] = useState({});
  const [selectedProperties, setSelectedProperties] = useState([]);

  const DEFAULT_NO_DATA = '-';

  const handleMetricsCategoryChange = useCallback((_, category) => category && setMetricsCategory(category), []);
  const handleDistancesCategoryChange = useCallback((_, category) => category && setDistancesCategory(category), []);
  const handleTimeCategoryChange = useCallback((_, category) => category && setTimeCategory(category), []);
  const handleDownTimeCategoryChange = useCallback((_, category) => category && setDownTimeCategory(category), []);

  const classes = useStyles();

  // Sets state of loadingItem in isLoading
  const setLoading = (name, state) => {
    const timestamp = new Date();
    isLoading[name] = { state, timestamp };
    setLoadingList({ ...isLoading });
  };

  useEffect(() => {
    setLoading('TileData', true);
    extendedTimeoutClient.get('/dashboard/instant-data').then(({ data: { results } }) => {
      setOpsTilesData(results);
      setLoading('TileData', false);
    });
  }, [setOpsTilesData, refreshTileData]);

  useEffect(() => {
    const initialSetup = async () => {
      await autonomyRobotStore.getRobots();
      await chaperonePropertyStore.getProperties('all');
    };
    initialSetup();
  }, []);

  /**
   * Applies property filter to unselected robots
   *
   * @param {String} propertyId - the id of the country
   * @returns {None} - None
   */
  const filteredRobots = () => {
    const filters = [];

    // Add filters to filter list
    if (selectedProperties.length !== 0) filters.push((r) => selectedProperties.includes(r.current_property_id));
    if (selectedToolTypes.length !== 0) filters.push((r) => selectedToolTypes.includes(r.tool_type));

    // Apply filters on selected robot list
    return [...autonomyRobotStore.robots.filter((r) => filters.map((f) => f(r)).reduce((a, b) => a && b, true))];
  };
  useEffect(() => {
    // Show nothing if nothing is selected
    if (selectedProperties.length === 0 && selectedToolTypes.length === 0) {
      setSelectedRobots([]);
    }
  }, [selectedProperties, selectedToolTypes]);

  // Calculates time since last update ever 1s
  const lastUpdatedInterval = useRef(null);
  const RECALCULATE_INTERVAL_IN_MS = 1000;
  useEffect(() => {
    if (lastUpdatedInterval.current) {
      clearInterval(lastUpdatedInterval.current);
    }

    lastUpdatedInterval.current = setInterval(() => {
      Object.keys(isLoading).forEach((name) => {
        const ping = new Date();
        const lastPing = isLoading[name].timestamp;

        let lastPingFormatted = '';
        const millisecondsSince = ping && lastPing ? ping.getTime() - lastPing.getTime() : 0;
        const secondsSince = (millisecondsSince / 1000).toFixed(2);
        if (secondsSince > 0) {
          lastPingFormatted = `${String(lastPing?.getHours()).padStart(2, '0')}:${String(lastPing?.getMinutes()).padStart(2, '0')}:${String(
            lastPing?.getSeconds()
          ).padStart(2, '0')} (${secondsSince}s)`;
        }

        lastUpdated[name] = lastPingFormatted;
      });
      setLastUpdated({ ...lastUpdated });
    }, RECALCULATE_INTERVAL_IN_MS);

    return () => clearInterval(lastUpdatedInterval.current);
  }, [isLoading]);

  useEffect(() => {
    if (settingsUpdated) {
      setLoading('OpsHistoryData', true);
      extendedTimeoutClient
        .get('/dashboard/history-data', {
          params: {
            startTime,
            endTime: upToDateEndTime,
            rangeUnit,
            rangeValue,
            serialNumber: selectedRobots.length === 0 ? filteredRobots().map((r) => r.serial_number) : selectedRobots,
            endBox: true
          }
        })
        .then(({ data: { results } }) => {
          if (filteredRobots().map((r) => r.serial_number).length === 0) {
            setOpsHistoryDataResponse(null);
            setOpsHistoryData(null);
            setLoading('OpsHistoryData', false);
            return;
          }

          setOpsHistoryDataResponse(results);
          let aggregatedDataByTime = aggregateHistoryDataOnTime(results);
          const aggregatedDataByRobot = aggregateHistoryDataOnRobot(results);
          const aggregatedDownTimeDataByRobot = aggregateDownTimeHistoryDataOnRobot(results);
          // Adds an zero item at the start of list unless no data
          if (results.length > 0) {
            aggregatedDataByTime = [{ ...aggregatedDataByTime[0] }, ...aggregatedDataByTime];
            aggregatedDataByTime[0].time = new Date(startTime).toISOString();
          }

          // Removes timezone from dates
          if (rangeUnit.includes('years') || rangeUnit.includes('months') || rangeUnit.includes('weeks') || rangeUnit.includes('days')) {
            aggregatedDataByTime.forEach((item, i) => {
              aggregatedDataByTime[i].time = aggregatedDataByTime[i].time.replace('+00:00', '');
            });
          }
          // @TODO: move into a separate function
          let accumulatedDistance = 0;
          let accumulatedAutonomousDistance = 0;
          let accumulatedTeleopsDistance = 0;
          let accumulatedChaperoneDistance = 0;

          let accumulatedMowingDistance = 0;
          let accumulatedAutonomousMowingDistance = 0;
          let accumulatedTeleopsMowingDistance = 0;
          let accumulatedChaperoneMowingDistance = 0;
          const formattedResults = aggregatedDataByTime.map((item, index) => {
            if (index !== 0) {
              accumulatedDistance += item['Total Autonomous Distance'] + item['Total Teleops Distance'] + item['Total Chaperone Distance'];
              accumulatedAutonomousDistance += item['Total Autonomous Distance'];
              accumulatedTeleopsDistance += item['Total Teleops Distance'];
              accumulatedChaperoneDistance += item['Total Chaperone Distance'];

              accumulatedMowingDistance +=
                item['Total Autonomous Mowing Distance'] + item['Total Teleops Mowing Distance'] + item['Total Chaperone Mowing Distance'];
              accumulatedAutonomousMowingDistance += item['Total Autonomous Mowing Distance'];
              accumulatedTeleopsMowingDistance += item['Total Teleops Mowing Distance'];
              accumulatedChaperoneMowingDistance += item['Total Chaperone Mowing Distance'];
            }
            return {
              ...item,
              'Total Distance': accumulatedDistance,
              'Total Autonomous Distance': accumulatedAutonomousDistance,
              'Total Teleops Distance': accumulatedTeleopsDistance,
              'Total Chaperone Distance': accumulatedChaperoneDistance,
              'Total Mowing Distance': accumulatedMowingDistance,
              'Total Autonomous Mowing Distance': accumulatedAutonomousMowingDistance,
              'Total Teleops Mowing Distance': accumulatedTeleopsMowingDistance,
              'Total Chaperone Mowing Distance': accumulatedChaperoneMowingDistance
            };
          });
          setOpsHistoryData(getHistoryChartDataArrays(formattedResults));
          setOpsAcreagesByRobotData(getHistoryChartDataArrays(aggregatedDataByRobot));
          setMowingDownTimeByRobotData(getHistoryChartDataArrays(aggregatedDownTimeDataByRobot));
          setLoading('OpsHistoryData', false);
        });
      setSettingsUpdated(false);
    }
  }, [startTime, endTime, endTimeChanged, rangeUnit, rangeValue, settingsUpdated]);

  useEffect(() => {
    if (settingsUpdated) {
      setLoading('PieData', true);
      extendedTimeoutClient
        .get('/dashboard/pie-data', {
          params: {
            startTime,
            endTime: upToDateEndTime,
            serialNumber: selectedRobots.length === 0 ? filteredRobots().map((r) => r.serial_number) : selectedRobots
          }
        })
        .then(({ data: { results } }) => {
          setOpsPieData(getPieChartDataArrays(results));
          setLoading('PieData', false);
        });
      setSettingsUpdated(false);
    }
  }, [startTime, endTime, endTimeChanged, settingsUpdated]);

  useEffect(() => {
    autonomyRobotStore.getRobotToolTypes();
  }, []);

  const compare = (a, b) => {
    const firstSerialNumber = a.serial_number.toLowerCase();
    const secondSerialNumber = b.serial_number.toLowerCase();
    if (firstSerialNumber < secondSerialNumber) {
      return -1;
    }
    if (firstSerialNumber > secondSerialNumber) {
      return 1;
    }
    return 0;
  };

  const sortSerialNumber = (a, b) => {
    const firstSerialNumber = a.serialNumber.toLowerCase();
    const secondSerialNumber = b.serialNumber.toLowerCase();
    if (firstSerialNumber < secondSerialNumber) {
      return -1;
    }
    if (firstSerialNumber > secondSerialNumber) {
      return 1;
    }
    return 0;
  };

  const { time: chartLabel, ...chartData } = opsHistoryData || {};
  const { serial_number: chartLabelAcreagesByRobot } = opsAcreagesByRobotData || {};
  const { serial_number: chartLabelMowingTimeByRobot } = opsAcreagesByRobotData || {};
  const { serial_number: chartLabelDistancesByRobot } = opsAcreagesByRobotData || {};
  const { serial_number: chartLabelTimeByRobot } = opsAcreagesByRobotData || {};
  const { serial_number: chartLabelMowingDownTimeByRobot } = mowingDownTimeByRobotData || {};

  const operationsExcelData = useMemo(
    () =>
      opsHistoryDataResponse?.map((dataPoint) => ({
        serial_number: dataPoint.serial_number,
        time: dataPoint.time,
        total_distance:
          dataPoint['Total Autonomous Distance'] + dataPoint['Total Teleops Distance'] + dataPoint['Total Chaperone Distance'],
        speed: dataPoint['Avg Speeds'],
        speed_with_blades: dataPoint['Avg Speeds on-blades'],
        numberOfChargingInstances: '',
        total_acres: convertDistanceInKmToAcres(
          dataPoint['Total Autonomous Mowing Distance'] +
            dataPoint['Total Teleops Mowing Distance'] +
            dataPoint['Total Chaperone Mowing Distance']
        )
      })),
    [opsHistoryDataResponse]
  );

  const handleOperationsHistoryExport = useCallback(async () => {
    const sheetName = 'Operations Report';
    const workbook = constructExcel(
      operationsExcelData
        .sort((a, b) => a.serial_number.localeCompare(b.serial_number) || new Date(a.time) - new Date(b.time))
        .map((dataPoint) => ({ ...dataPoint, time: formatDateTime(dataPoint.time) })),
      getOperationsExcelColumns(metricsCategory),
      sheetName
    );
    const worksheet = workbook.worksheets[0];
    autoWidth(worksheet);
    mergeEqualAdjacentCellsInColumn(worksheet, 1, 1);
    await saveExcelWorkook(workbook, sheetName);
  }, [opsHistoryDataResponse, operationsExcelData, metricsCategory]);

  const handleShowCurrentRobotStatusTable = (status) => {
    if (opsTilesData.length > 0) {
      if (status === robotCurrentStatus.OPERATIONAL) {
        setrobotWithCurrentStatus(opsTilesData[0].robots.slice().sort(sortSerialNumber));
        setshowCurrentOperatingRobots(!showCurrentOperatingRobots);
      }
      if (status === robotCurrentStatus.IDLE) {
        setrobotWithCurrentStatus(opsTilesData[1].robots.slice().sort(sortSerialNumber));
        setshowCurrentOperatingRobots(!showCurrentOperatingRobots);
      }
      if (status === robotCurrentStatus.BLADE_RUNNING) {
        setrobotWithCurrentStatus(opsTilesData[2].robots.slice().sort(sortSerialNumber));
        setshowCurrentOperatingRobots(!showCurrentOperatingRobots);
      }
    }
  };

  const handleChangeProperty = (event) => {
    const propertyId = event.target.value.pop();
    if (propertyId === 'DEFAULT' || propertyId === undefined) {
      setSelectedProperties([]);
    } else {
      setSelectedProperties([...event.target.value, propertyId]);
    }
  };
  /**
   * Function toggles the value of {refreshTileData} between true and false.
   * {refreshTileData} is a dependency for a useEffect hook repoinsible for
   * fetching tile data.
   * Note: The boolean value of refreshTileData is arbitrary. It is purely used to
   * trigger the useEffect.
   * @param {None} None
   * @returns {None} - None
   */
  const handleRefreshTileData = () => {
    setRefreshTileData(!refreshTileData);
  };

  return (
    <Fade in>
      <div>
        <Box className={classes.tileDataRefreshContainer}>
          <Typography align="left" variant="h5" className={classes.lastUpdated} style={{ color: 'white' }}>
            Last Updated:
            {lastUpdated.TileData || DEFAULT_NO_DATA}
          </Typography>
          <ReloadIcon
            title="Refresh Tile Data"
            style={{ color: 'white', width: 'max-content', borderRadius: '50%' }}
            isLoading={isLoading.TileData ? isLoading.TileData.state : false}
            handler={() => handleRefreshTileData()}
          />
        </Box>
        {showCurrentOperatingRobots && (
          <CurrentRobotStatusTable
            tableData={robotWithCurrentStatus}
            show={showCurrentOperatingRobots}
            onClose={() => setshowCurrentOperatingRobots(false)}
          />
        )}
        <Grid container alignItems="stretch">
          {opsTilesData.map((tile) => (
            <Grid item key={tile.title} md={3} xs={6}>
              <InfoTile tile={tile} handleShowRobotStatusTable={(stat) => handleShowCurrentRobotStatusTable(stat)} />
            </Grid>
          ))}
        </Grid>

        <div className={classes.settingButtonContainer}>
          <Typography align="left" variant="h5" className={classes.lastUpdated} style={{ color: 'white' }}>
            Last Updated:
            {lastUpdated.OpsHistoryData || DEFAULT_NO_DATA}
          </Typography>
          <ExportIcon
            title="Export data"
            isLoading={isLoading.ExportData ? isLoading.ExportData.state : false}
            handler={async () => {
              setLoading('ExportData', true);
              await handleOperationsHistoryExport();
              setLoading('ExportData', false);
            }}
            style={{ color: 'white' }}
          />
          <ReloadIcon
            title="Reload Ops History"
            handler={() => {
              setEndTime(new Date().getTime());
              setEndTimeChanged(true);
              setSettingsUpdated(true);
            }}
            isLoading={isLoading.OpsHistoryData ? isLoading.OpsHistoryData.state : false}
            style={{ color: 'white' }}
          />
          <IconButton onClick={() => setShowSettingModal(true)}>
            <SettingsIcon />
          </IconButton>
        </div>
        {!(opsHistoryData || isLoading?.OpsHistoryData?.state) && (
          <h1 style={{ color: '#fff' }}>
            {`There is not enough data in selected time range between ${format(new Date(startTime), "yyyy-MM-dd' - 'HH:mm")} 
          and ${format(new Date(upToDateEndTime), "yyyy-MM-dd' - 'HH:mm")}. Please change the time ranges settings`}
          </h1>
        )}
        {opsHistoryData && (
          <Grid>
            <div className={classes.toggleContainer}>
              <ToggleButtonGroup
                value={metricsCategory}
                exclusive
                onChange={handleMetricsCategoryChange}
                aria-label="Metrics Category"
                className={classes.toggleGroup}
              >
                <ToggleButton value={MetricsCategory.Distances} aria-label={MetricsCategory.Distances}>
                  {MetricsCategory.Distances}
                </ToggleButton>
                <ToggleButton value={MetricsCategory.Acreages} aria-label={MetricsCategory.Acreages}>
                  {MetricsCategory.Acreages}
                </ToggleButton>
                <ToggleButton value={MetricsCategory.Speeds} aria-label={MetricsCategory.Speeds}>
                  {MetricsCategory.Speeds}
                </ToggleButton>
                <ToggleButton value={MetricsCategory.Robots} aria-label={MetricsCategory.Robots}>
                  {MetricsCategory.Robots}
                </ToggleButton>
              </ToggleButtonGroup>
            </div>

            <Fade
              in={metricsCategory === MetricsCategory.Distances}
              style={{ display: metricsCategory === MetricsCategory.Distances ? 'block' : 'none' }}
            >
              <div>
                <OperationLineChart
                  chartLabel={chartLabel}
                  chartData={{
                    'Total Distance': chartData['Total Distance'],
                    'Total Autonomous Distance': chartData['Total Autonomous Distance'],
                    'Total Teleops Distance': chartData['Total Teleops Distance'],
                    'Total Chaperone Distance': chartData['Total Chaperone Distance']
                  }}
                  yAxisTitle="Distance (km)"
                  tooltipLabelCallback={(label, value, formattedValue) => `${label}: ${formattedValue} km`}
                />
              </div>
            </Fade>
            <Fade
              in={metricsCategory === MetricsCategory.Acreages}
              style={{ display: metricsCategory === MetricsCategory.Acreages ? 'block' : 'none' }}
            >
              <div>
                <OperationLineChart
                  chartLabel={chartLabel}
                  chartData={{
                    'Total Mowing Acres': chartData['Total Mowing Distance'].map(convertDistanceInKmToAcres),
                    'Total Autonomous Mowing Acres': chartData['Total Autonomous Mowing Distance'].map(convertDistanceInKmToAcres),
                    'Total Teleops Mowing Acres': chartData['Total Teleops Mowing Distance'].map(convertDistanceInKmToAcres),
                    'Total Chaperone Mowing Acres': chartData['Total Chaperone Mowing Distance'].map(convertDistanceInKmToAcres)
                  }}
                  lineBorderDashes={[
                    [10, 5],
                    [10, 5],
                    [10, 5],
                    [10, 5]
                  ]}
                  yAxisTitle="Acres"
                  tooltipLabelCallback={(label, value, formattedValue) => `${label}: ${formattedValue} acres`}
                />
              </div>
            </Fade>
            <Fade
              in={metricsCategory === MetricsCategory.Speeds}
              style={{ display: metricsCategory === MetricsCategory.Speeds ? 'block' : 'none' }}
            >
              <div>
                <OperationLineChart
                  chartLabel={chartLabel}
                  chartData={{
                    'Avg Speeds': chartData['Avg Speeds'],
                    'Avg Speeds on-blades': chartData['Avg Speeds on-blades']
                  }}
                  yAxisTitle="Speed (Km/h)"
                  tooltipLabelCallback={(label, value, formattedValue) => `${label}: ${formattedValue} Km/h`}
                />
              </div>
            </Fade>
            <Fade
              in={metricsCategory === MetricsCategory.Robots}
              style={{ display: metricsCategory === MetricsCategory.Robots ? 'block' : 'none' }}
            >
              <div>
                <OperationLineChart
                  chartLabel={chartLabel}
                  chartData={{
                    'Robots Turned-on': chartData['Robots Turned-on'],
                    'Robots on-blades': chartData['Robots on-blades']
                  }}
                  yAxisTitle="Number of robots"
                  yTickStepSize={1}
                  tooltipLabelCallback={(label, value, formattedValue) => {
                    if (value === 1) return `${label}: ${formattedValue} robot`;
                    return `${label}: ${formattedValue} robots`;
                  }}
                />
              </div>
            </Fade>

            {opsHistoryDataResponse && (
              <>
                <Grid>
                  <div className={classes.toggleContainer}>
                    <ToggleButtonGroup
                      value={distancesCategory}
                      exclusive
                      onChange={handleDistancesCategoryChange}
                      aria-label="Distance Category"
                      className={classes.toggleGroup}
                    >
                      <ToggleButton value={DistanceCategory.Distances} aria-label={DistanceCategory.Distances}>
                        {DistanceCategory.Distances}
                      </ToggleButton>
                      <ToggleButton value={DistanceCategory.DistancesPerRobot} aria-label={DistanceCategory.DistancesPerRobot}>
                        {DistanceCategory.DistancesPerRobot}
                      </ToggleButton>
                      <ToggleButton value={DistanceCategory.Acreages} aria-label={DistanceCategory.Acreages}>
                        {DistanceCategory.Acreages}
                      </ToggleButton>
                      <ToggleButton value={DistanceCategory.AcreagesPerRobot} aria-label={DistanceCategory.AcreagesPerRobot}>
                        {DistanceCategory.AcreagesPerRobot}
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </div>

                  <Fade
                    in={distancesCategory === DistanceCategory.Distances}
                    style={{ display: distancesCategory === DistanceCategory.Distances ? 'block' : 'none' }}
                  >
                    <Grid className={classes.chartContainer}>
                      <StackedVerticalBarChart
                        chartTitle="Ops Distance"
                        chartLabel={chartLabel}
                        chartData={{
                          Autonomous:
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.['Total Autonomous Distance'] ??
                            [],
                          Teleops:
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.['Total Teleops Distance'] ?? [],
                          Chaperone:
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.['Total Chaperone Distance'] ??
                            []
                        }}
                        yAxisTitle="Distance (km)"
                        valueUnit="km"
                        showPercentage
                      />
                    </Grid>
                  </Fade>

                  <Fade
                    in={distancesCategory === DistanceCategory.DistancesPerRobot}
                    style={{ display: distancesCategory === DistanceCategory.DistancesPerRobot ? 'block' : 'none' }}
                  >
                    <Grid className={classes.chartContainer}>
                      <StackedVerticalBarChart
                        chartTitle="Km/Robot"
                        chartLabel={chartLabelDistancesByRobot}
                        chartLabelType="serial_number"
                        chartData={{
                          Autonomous: opsAcreagesByRobotData?.['Total Autonomous Distance'] ?? [],
                          Teleops: opsAcreagesByRobotData?.['Total Teleops Distance'] ?? [],
                          Chaperone: opsAcreagesByRobotData?.['Total Chaperone Distance'] ?? []
                        }}
                        yAxisTitle="Km"
                        valueUnit="Km"
                        showPercentage
                        yStepSize={0.01}
                      />
                    </Grid>
                  </Fade>

                  <Fade
                    in={distancesCategory === DistanceCategory.Acreages}
                    style={{ display: distancesCategory === DistanceCategory.Acreages ? 'block' : 'none' }}
                  >
                    <Grid className={classes.chartContainer}>
                      <StackedVerticalBarChart
                        chartTitle="Ops Acres"
                        chartLabel={chartLabel}
                        chartData={{
                          Autonomous: (
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                              'Total Autonomous Mowing Distance'
                            ] ?? []
                          ).map(convertDistanceInKmToAcres),
                          Teleops: (
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                              'Total Teleops Mowing Distance'
                            ] ?? []
                          ).map(convertDistanceInKmToAcres),
                          Chaperone: (
                            getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                              'Total Chaperone Mowing Distance'
                            ] ?? []
                          ).map(convertDistanceInKmToAcres)
                        }}
                        yAxisTitle="Acres"
                        valueUnit="acres"
                        showPercentage
                        yStepSize={0.01}
                      />
                    </Grid>
                  </Fade>

                  <Fade
                    in={distancesCategory === DistanceCategory.AcreagesPerRobot}
                    style={{ display: distancesCategory === DistanceCategory.AcreagesPerRobot ? 'block' : 'none' }}
                  >
                    <Grid className={classes.chartContainer}>
                      <StackedVerticalBarChart
                        chartTitle="Acres/Robot"
                        chartLabel={chartLabelAcreagesByRobot}
                        chartLabelType="serial_number"
                        chartData={{
                          Autonomous: (opsAcreagesByRobotData?.['Total Autonomous Mowing Distance'] ?? []).map(convertDistanceInKmToAcres),
                          Teleops: (opsAcreagesByRobotData?.['Total Teleops Mowing Distance'] ?? []).map(convertDistanceInKmToAcres),
                          Chaperone: (opsAcreagesByRobotData?.['Total Chaperone Mowing Distance'] ?? []).map(convertDistanceInKmToAcres)
                        }}
                        yAxisTitle="Acres"
                        valueUnit="acres"
                        showPercentage
                        yStepSize={0.01}
                      />
                    </Grid>
                  </Fade>
                </Grid>

                <div className={classes.toggleContainer}>
                  <ToggleButtonGroup
                    value={timeCategory}
                    exclusive
                    onChange={handleTimeCategoryChange}
                    aria-label="Time Category"
                    className={classes.toggleGroup}
                  >
                    <ToggleButton value={TimeCategory.Ops} aria-label={TimeCategory.Ops}>
                      {TimeCategory.Ops}
                    </ToggleButton>
                    <ToggleButton value={TimeCategory.OpsPerRobot} aria-label={TimeCategory.OpsPerRobot}>
                      {TimeCategory.OpsPerRobot}
                    </ToggleButton>
                    <ToggleButton value={TimeCategory.Mowing} aria-label={TimeCategory.Mowing}>
                      {TimeCategory.Mowing}
                    </ToggleButton>
                    <ToggleButton value={TimeCategory.MowingPerRobot} aria-label={TimeCategory.MowingPerRobot}>
                      {TimeCategory.MowingPerRobot}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <Fade in={timeCategory === TimeCategory.Ops} style={{ display: timeCategory === TimeCategory.Ops ? 'block' : 'none' }}>
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Ops Time"
                      chartLabel={chartLabel}
                      chartData={{
                        Autonomous: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Autonomous Time'
                        ],
                        Teleops: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.['Total Teleops Time'],
                        Chaperone: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.['Total Chaperone Time']
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentage
                    />
                  </Grid>
                </Fade>

                <Fade
                  in={timeCategory === TimeCategory.OpsPerRobot}
                  style={{ display: timeCategory === TimeCategory.OpsPerRobot ? 'block' : 'none' }}
                >
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Ops Time / Robot"
                      chartLabel={chartLabelTimeByRobot}
                      chartLabelType="serial_number"
                      chartData={{
                        Autonomous: opsAcreagesByRobotData?.['Total Autonomous Time'],
                        Teleops: opsAcreagesByRobotData?.['Total Teleops Time'],
                        Chaperone: opsAcreagesByRobotData?.['Total Chaperone Time']
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentagevalueUnit="hours"
                      showPercentage
                      yStepSize={0.01}
                    />
                  </Grid>
                </Fade>

                <Fade
                  in={timeCategory === TimeCategory.Mowing}
                  style={{ display: timeCategory === TimeCategory.Mowing ? 'block' : 'none' }}
                >
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Mowing Time"
                      chartLabel={chartLabel}
                      chartData={{
                        Autonomous: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Autonomous Mowing Time'
                        ],
                        Teleops: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Teleops Mowing Time'
                        ],
                        Chaperone: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Chaperone Mowing Time'
                        ]
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentage
                    />
                  </Grid>
                </Fade>
                <Fade
                  in={timeCategory === TimeCategory.MowingPerRobot}
                  style={{ display: timeCategory === TimeCategory.MowingPerRobot ? 'block' : 'none' }}
                >
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Mowing Time / Robot"
                      chartLabel={chartLabelMowingTimeByRobot}
                      chartLabelType="serial_number"
                      chartData={{
                        Autonomous: opsAcreagesByRobotData?.['Total Autonomous Mowing Time'],
                        Teleops: opsAcreagesByRobotData?.['Total Teleops Mowing Time'],
                        Chaperone: opsAcreagesByRobotData?.['Total Chaperone Mowing Time']
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentagevalueUnit="hours"
                      showPercentage
                      yStepSize={0.01}
                    />
                  </Grid>
                </Fade>
                <div className={classes.toggleContainer}>
                  <ToggleButtonGroup
                    value={downTimeCategory}
                    exclusive
                    onChange={handleDownTimeCategoryChange}
                    aria-label="Down Time Category"
                    className={classes.toggleGroup}
                  >
                    <ToggleButton value={DownTimeCategory.Mowing} aria-label={DownTimeCategory.Mowing}>
                      {DownTimeCategory.Mowing}
                    </ToggleButton>
                    <ToggleButton value={DownTimeCategory.MowingPerRobot} aria-label={DownTimeCategory.MowingPerRobot}>
                      {DownTimeCategory.MowingPerRobot}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>

                <Fade
                  in={downTimeCategory === DownTimeCategory.Mowing}
                  style={{ display: downTimeCategory === DownTimeCategory.Mowing ? 'block' : 'none' }}
                >
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Down Time"
                      chartLabel={chartLabel}
                      chartData={{
                        Autonomous: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Autonomous Mowing Down Time'
                        ],
                        Teleops: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Teleops Mowing Down Time'
                        ],
                        Chaperone: getHistoryChartDataArrays(aggregateHistoryDataOnTime(opsHistoryDataResponse))?.[
                          'Total Chaperone Mowing Down Time'
                        ]
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentage
                    />
                  </Grid>
                </Fade>
                <Fade
                  in={downTimeCategory === DownTimeCategory.MowingPerRobot}
                  style={{ display: downTimeCategory === DownTimeCategory.MowingPerRobot ? 'block' : 'none' }}
                >
                  <Grid className={classes.chartContainer}>
                    <StackedVerticalBarChart
                      chartTitle="Mowing Down Time / Robot"
                      chartLabel={chartLabelMowingDownTimeByRobot}
                      chartLabelType="serial_number"
                      chartData={{
                        Autonomous: mowingDownTimeByRobotData?.['Total Autonomous Mowing Down Time'],
                        Teleops: mowingDownTimeByRobotData?.['Total Teleops Mowing Down Time'],
                        Chaperone: mowingDownTimeByRobotData?.['Total Chaperone Mowing Down Time']
                      }}
                      type="time"
                      yAxisTitle="Time (h)"
                      showPercentagevalueUnit="hours"
                      showPercentage
                      yStepSize={0.01}
                    />
                  </Grid>
                </Fade>
              </>
            )}
          </Grid>
        )}

        <Grid container alignItems="stretch">
          {opsHistoryData &&
            opsPieData.map(
              ({ title, dataSet }) =>
                dataSet &&
                dataSet[0]?.data?.filter(Boolean).length > 0 && (
                  <Grid item key={title} md={3} sm={6}>
                    <div className={classes.pieChartContainer}>
                      <h1 className={classes.pieChartHeader}>{title}</h1>
                      <OperationPieChart dataSets={dataSet} useTypedTitle />
                    </div>
                  </Grid>
                )
            )}
        </Grid>
        <Modal
          open={showSettingModal}
          onClose={() => setShowSettingModal(false)}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={showSettingModal}>
            <div className={classes.paper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="start time"
                    value={startTime}
                    onChange={(date) => setStartTime(new Date(date).getTime())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="end time"
                    value={upToDateEndTime}
                    onChange={(date) => {
                      setEndTime(new Date(date).getTime());
                      setEndTimeChanged(true);
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <InputLabel className={classes.inputLabel} id="range-nit-select-label">
                    Resolution Unit
                  </InputLabel>
                  <Select
                    labelId="range-nit-select-label"
                    id="range-nit-select"
                    value={rangeUnit}
                    onChange={(event) => {
                      setRangeUnit(event.target.value);
                    }}
                  >
                    <MenuItem value="years">Years</MenuItem>
                    <MenuItem value="months">Months</MenuItem>
                    <MenuItem value="weeks">Weeks</MenuItem>
                    <MenuItem value="days">Days</MenuItem>
                    <MenuItem value="hours">Hours</MenuItem>
                    <MenuItem value="minutes">Minutes</MenuItem>
                  </Select>
                </div>
                <div>
                  <TextField
                    id="range-value"
                    label="Resolution Value"
                    type="number"
                    onChange={({ target: { value } }) => {
                      if (!Number.isNaN(value) && value > 0) setRangeValue(value);
                    }}
                    value={rangeValue}
                  />
                </div>
                {/* {changes start here} */}
                <div>
                  <InputLabel className={classes.inputLabel} id="property">
                    Property
                  </InputLabel>
                  <Select
                    labelId="property"
                    id="property"
                    value={selectedProperties}
                    onChange={handleChangeProperty}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => {
                          const property = chaperonePropertyStore.getById(value);
                          return <Chip key={property.id} label={property.name} className={classes.chip} />;
                        })}
                      </div>
                    )}
                    className={classes.propertySelect}
                    multiple
                  >
                    <MenuItem value="DEFAULT">No Selection</MenuItem>
                    {chaperonePropertyStore.properties.map((p) => (
                      <MenuItem key={p.id} id={p.id} value={p.id}>
                        {p.name}
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div>
                  <InputLabel className={classes.inputLabel} id="robots-select-label">
                    Robots
                  </InputLabel>
                  <Select
                    multiple
                    labelId="robots-select-label"
                    id="select-select"
                    value={selectedRobots}
                    onChange={(event) => {
                      setSelectedRobots(event.target.value);
                    }}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                    style={{ width: '100%' }}
                  >
                    {filteredRobots()
                      .sort(compare)
                      .map((robot) => (
                        <MenuItem key={robot.serial_number} value={robot.serial_number}>
                          {robot.serial_number}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <Box sx={{ display: 'flex' }}>
                  <FormControl>
                    <FormLabel className={classes.inputLabel} component="legend">
                      {' '}
                      Tool Types
                    </FormLabel>
                    <FormGroup>
                      {autonomyRobotStore.getToolTypes().map((tool) => (
                        <FormControlLabel
                          key={tool}
                          value={tool.tool_type}
                          control={<Checkbox />}
                          label={`${formatStringForReadbility(tool.tool_type)}`}
                          onChange={(event) => {
                            if (event.target.checked) setSelectedToolTypes([...selectedToolTypes, event.target.value]);
                            else setSelectedToolTypes([...selectedToolTypes.filter((v) => v !== event.target.value)]);
                          }}
                        />
                      ))}
                    </FormGroup>
                  </FormControl>
                </Box>
                <div className={classes.modalCloseButtonContainer}>
                  <Button
                    onClick={() => {
                      setShowSettingModal(false);
                      setSettingsUpdated(true);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </Fade>
        </Modal>
      </div>
    </Fade>
  );
});

export default OperationsPage;
