import { Box, Grid, makeStyles, Tooltip, Typography } from '@material-ui/core';
import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { Warning } from '@material-ui/icons';
import { ROUTE_SOLAR_AUDIT_OFFICER_DETAILS } from './routes';
import solarBackgroundImage from '../assets/effiel-solar-bg.jpeg';
import { ControlImageCapture } from '../components/control/control-image-capture.component';
import { BatteryIndicator } from '../components/control/control-battery-indicator.component';
import { ToolIndicator } from '../components/control/control-tool-indicator.component';
import { MapSwitch } from '../components/control/switch-map-visualization';
import { ControlSlider } from '../components/control/control-slider.component';
import { ControlSwitch } from '../components/control/control-switch.component';
import { MotorStatus } from '../components/control/motor-status.component';
import { useStores } from '../store/root/root.store';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import ActionsDialog from '../components/dialogs/actions.dialog';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { Joystick } from '../input';
import { SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING, DECK_AND_FRAME_OFFSET_SLIDER_MARKS, ROBOT_CONTROL_MODES } from '../utils/constants';
import SolarGridMap from '../components/gridMaps/SolarGridMap';
import SolarMap from '../components/maps/solar-map';
import { buildTopicAndPayloadForMqttEstop, isDevMode } from '../utils/ui.utils';
import MultiRobotsRepeatControls from '../components/control/multi-robots/multi-robots-repeat-control';
import VideoStreamMultiPageView from '../components/control/video-stream-multi-page-view.component';
import { switchControlMode } from '../utils/multi-robots.utils';
import SolarRowsTable from '../components/tables/solar-rows.table';
/* eslint-disable no-unused-expressions */

let gamepadIndex = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1600,
    minHeight: 800,
    backgroundColor: '#414042',
    backgroundImage: `url(${solarBackgroundImage})`,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
    backgroundRepeat: 'noRepeat',
    backgroundAttachment: 'fixed',
    backgroundBlendMode: 'multiply'
  },
  controlAreaLeftPanel: {
    paddingRight: theme.spacing(1)
  },
  controlAreaMainPanel: {
    position: 'relative',
    marginTop: '35px',
    height: '1090px',
    overflowY: 'hidden'
  },
  controlAreaRightPanel: {
    padding: theme.spacing(1)
  },
  controlAreaControlPanel: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: '35px'
  },
  joystick: {
    overflow: 'visible',
    position: 'relative'
  },
  PS4: {
    overflow: 'visible',
    position: 'relative'
  },
  mapTitle: {
    background: 'rgba(32, 32, 32, 1.0)',
    color: theme.palette.inverted.main,
    height: '3.5%',
    padding: theme.spacing(1)
  },
  imgPanelShort: {
    height: '25%'
  },
  imgPanelTall: {
    height: '50%'
  },
  progress: {
    color: theme.palette.inverted.main
  },
  robotControl: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: 3,
    borderRadius: theme.spacing(1)
  },
  robotControlImg: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  toggleMapSty: {
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  robotControlAlt: {
    width: '100%',
    lineHeight: '0px',
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1)
  },
  map: {
    position: 'absolute',
    right: '0',
    bottom: '38px',
    zIndex: '3',
    border: '3px solid gray'
  },
  webComponent: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    // video3 -> left, video1 -> rear, video0 -> front, video2 -> right
    '& .webrtc-video3': {
      position: 'absolute',
      left: '0%',
      width: '25.0%'
    },
    '& .webrtc-video1': {
      position: 'absolute',
      top: '400px',
      left: '0%',
      width: '25.0%',
      zIndex: '1'
    },
    '& .webrtc-video0': {
      position: 'absolute',
      left: '25.0%',
      width: '50.0%'
    },
    '& .webrtc-video2': {
      position: 'absolute',
      left: '75.0%',
      width: '25.0%',
      zindex: '1'
    }
  },
  subRowsTableContainer: {
    zIndex: '1000',
    position: 'absolute'
  },
  coolDownIndicator: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: 5,
    borderRadius: theme.spacing(1)
  },
  toolTip: {
    fontSize: '1.1em'
  }
}));

export const MultiRobotsRemoteGuardianRepeat = observer(
  ({
    serialNumber,
    robotConnection,
    solarSubRowsData,
    changeRepeatingState,
    changeDriveState,
    openNewSubrow,
    connectionError,
    handleCloseErrorDialog,
    errorMessage,
    currentPositionIndex,
    currentSplittedRowName,
    closeDialogue,
    openDialog,
    isAutonomyAndTeleopsDisabled,
    setIsStarted,
    cords,
    resetRobot,
    solarSubRows,
    cancelRepeatingTask,
    controlViewLoading,
    controlViewLoadingMessage,
    isRightOffset,
    isTeleopsStreamingEnabled,
    selectedBlockId,
    selectedSubBlockId,
    selectedPathType,
    teleopsDrivingEnabled,
    setTeleopsDrivingEnabled,
    setEnableAutonmousBladesDialog,
    toggleAutonomousBlades,
    isBladeRunning,
    handleJoystickState,
    connectionStatusMessageColor,
    setShowRestartRobotDialog,
    setShowRestartVideoDialog,
    robotSensingEdgeState,
    isVideoStreamingRefreshed,
    setIsVideoStreamingRefreshed,
    updateSolarSubRows,
    robotGpsState,
    robotInsState,
    robotInsStatus,
    eStopType,
    userEmail,
    mqttClient,
    coolDownMessage,
    coolDownWarningColour
  }) => {
    const classes = useStyles();
    const { chaperonePropertyStore, regionsStore, MultiRobotsStore } = useStores();

    const { pathname } = useLocation();
    const [loading, setLoading] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const [lightsState, setLightsState] = useState(0);
    const [lookAheadDistanceError, setLookAheadDistanceError] = useState(false);
    const [openAIDialog, setOpenAIDialog] = useState(false);
    const [mapType, setMapType] = useState('googleMap');
    const [openAutonomousDialog, setOpenAutonomousDialog] = useState(false);
    const robotUseCase = MultiRobotsStore.robots.get(serialNumber)?.useCase;
    const isGrassCutting = [SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING].includes(robotUseCase);
    const toolType = isGrassCutting ? 'Lawn-Mower Active' : 'Salter Active';
    const isSolarAuditOfficer = pathname === ROUTE_SOLAR_AUDIT_OFFICER_DETAILS;
    const robotLat = MultiRobotsStore.robots.get(serialNumber)?.lat;
    const robotLng = MultiRobotsStore.robots.get(serialNumber)?.lng;
    const robotCurrentHeadingRad = MultiRobotsStore.robots.get(serialNumber)?.currentHeadingRad;
    const robotControlMode = MultiRobotsStore.robots.get(serialNumber)?.controlMode;
    const robotEstopState = MultiRobotsStore.robots.get(serialNumber)?.estopState;
    const robotSwEstopState = MultiRobotsStore.robots.get(serialNumber)?.swEstopState;
    const robotId = MultiRobotsStore.robots.get(serialNumber)?.id;
    const selectedPropertyId = chaperonePropertyStore.getSelectedProperty()?.id;
    const selectedRegionId = regionsStore.getSelectedRegion()?.id;
    const robotLightsState = MultiRobotsStore.robots.get(serialNumber)?.lightsState;
    const robotPreviousControlMode = MultiRobotsStore.robots.get(serialNumber)?.previousControlMode;
    const robotWpsState = MultiRobotsStore.robots.get(serialNumber)?.wpsState;
    const robotIsLawnMowing = MultiRobotsStore.robots.get(serialNumber)?.isLawnMowing;
    const robotMotors = MultiRobotsStore.robots.get(serialNumber)?.motors;
    const robotBatteryPercentage = MultiRobotsStore.robots.get(serialNumber)?.batteryLevel;
    const robotNumberOfBatteries = MultiRobotsStore.robots.get(serialNumber)?.numberOfBatteries;
    const isToolActive = isGrassCutting ? robotIsLawnMowing : false;
    const linearSpeed = MultiRobotsStore.robots.get(serialNumber)?.linearSpeed;
    const lookAheadDistance = MultiRobotsStore.robots.get(serialNumber)?.lookAheadDistance;
    const angularSpeed = MultiRobotsStore.robots.get(serialNumber)?.angularSpeed;
    const interRowDirection = MultiRobotsStore.robots.get(serialNumber)?.interRowDirection;
    const videoGamma = MultiRobotsStore.robots.get(serialNumber)?.videoGamma;
    const videoContrast = MultiRobotsStore.robots.get(serialNumber)?.videoContrast;
    const selectedSolarSubRows = MultiRobotsStore.robots.get(serialNumber)?.selectedSolarSubRows;
    const [showSubRowsTable, setShowSubRowsTable] = useState(false);
    const [topic, payload] = buildTopicAndPayloadForMqttEstop(!robotSwEstopState, userEmail, serialNumber);
    const deckOffset = isRightOffset
      ? parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.deckOffset)
      : parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.deckOffset) * -1;
    const frameOffset = isRightOffset
      ? parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.frameOffset)
      : parseInt(MultiRobotsStore?.robots?.get(serialNumber)?.frameOffset) * -1;

    const spaceBarToPauseTimeout = useRef(null);
    const isSpaceBarLongPressed = useRef(null);
    const isSpaceBarPressed = useRef(null);

    useEffect(() => {
      const bgBox = document.querySelector('#bg-box');
      if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
        bgBox.classList.remove('bg-solar', 'bg-snow');
        bgBox.classList.add('bg-non-solar');
      } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
        bgBox.classList.remove('bg-solar', 'bg-non-solar');
        bgBox.classList.add('bg-snow');
      }
    }, []);

    useEffect(() => {
      setLightsState(robotLightsState);
    }, [robotLightsState]);

    useEffect(() => {
      setTeleopsDrivingEnabled(false);
    }, [robotPreviousControlMode]);

    const handlesetTeleopsDrivingEnabled = (value) => {
      setTeleopsDrivingEnabled(value);
      if (value) MultiRobotsStore.setTeleopsMode(serialNumber, value);
    };

    const showLoadingDialog = (message) => {
      setLoading(true);
      setLoadingMessage(message);
    };

    const confirmWarningDialog = () => {
      switchControlMode(ROBOT_CONTROL_MODES.AUTONOMOUS, robotControlMode, robotConnection);
      setOpenAutonomousDialog(false);
    };
    const closeAutonomousDialogue = () => setOpenAutonomousDialog(false);

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.code === 'Space' || e.key === ' ') {
          if (!isSpaceBarPressed.current) {
            isSpaceBarPressed.current = true;

            // Start a timeout to detect long press space bar
            // Trigger pause all robots if space key pressed for > 2s
            spaceBarToPauseTimeout.current = setTimeout(() => {
              e.preventDefault();
              e.stopPropagation();

              // reset space bar pressed
              // set long press to true
              isSpaceBarPressed.current = false;
              isSpaceBarLongPressed.current = true;
              clearTimeout(spaceBarToPauseTimeout.current);
            }, 2000);
          }
        }
      };

      const handleKeyUp = (e) => {
        if ((e.code === 'Space' || e.key === ' ') && isSpaceBarPressed.current) {
          clearTimeout(spaceBarToPauseTimeout.current);
          // trigger SW estop on key release if key has not been pressed for 2 or more seconds
          if (!isSpaceBarLongPressed.current) {
            e.preventDefault();
            e.stopPropagation();
          }
          isSpaceBarPressed.current = false;
          isSpaceBarLongPressed.current = false;
        }
      };

      window.addEventListener('keydown', handleKeyDown);
      window.addEventListener('keyup', handleKeyUp);

      return () => {
        window.removeEventListener('keydown', handleKeyDown);
        window.removeEventListener('keyup', handleKeyUp);
      };
    }, [robotWpsState]);
    // }, [start, controlMode, robotControlMode, robotConnection, setOpenAutonomousDialog]);

    const handleCloseLookAheadErrorDialog = () => {
      setLookAheadDistanceError(false);
    };

    useEffect(() => {
      const navParams = `${linearSpeed},${lookAheadDistance},${angularSpeed},${interRowDirection},${videoGamma},${videoContrast}`;
      robotConnection?.current?.ros.updateNavParams(navParams);
    }, [linearSpeed, lookAheadDistance, angularSpeed, videoGamma, videoContrast, interRowDirection]);

    useEffect(() => {
      if (teleopsDrivingEnabled) {
        MultiRobotsStore.setTeleopsMode(serialNumber, true);
      } else {
        MultiRobotsStore.setTeleopsMode(serialNumber, false);
      }
    }, [teleopsDrivingEnabled]);

    return (
      // During connection, show loader
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.root}>
        <LoadingDialog show={loading || controlViewLoading} message={loadingMessage || controlViewLoadingMessage} maxWidth="md" />
        <LoadingDialog show={controlViewLoading} message={controlViewLoadingMessage} maxWidth="md" />
        <MultiRobotsRepeatControls
          changeRepeatingState={changeRepeatingState}
          changeDriveState={changeDriveState}
          cancelRepeatingTask={cancelRepeatingTask}
          solarSubRows={solarSubRows}
          readOnlyMode={isSolarAuditOfficer}
          setIsStarted={setIsStarted}
          controlMode={robotControlMode}
          robot={robotId}
          wpsState={robotWpsState}
          region={selectedRegionId}
          property={selectedPropertyId}
          block={selectedBlockId}
          subBlock={selectedSubBlockId}
          pathType={selectedPathType}
          serialNumber={serialNumber}
          linearSpeed={linearSpeed}
          lookAheadDistance={lookAheadDistance}
          angularSpeed={angularSpeed}
          videoGamma={videoGamma}
          videoContrast={videoContrast}
          interRowDirection={interRowDirection}
          isTeleopsStreamingEnabled={isTeleopsStreamingEnabled}
          connectionStatusMessageColor={connectionStatusMessageColor}
          setShowRestartRobotDialog={setShowRestartRobotDialog}
          setShowRestartVideoDialog={setShowRestartVideoDialog}
          isVideoStreamingRefreshed={isVideoStreamingRefreshed}
          setIsVideoStreamingRefreshed={setIsVideoStreamingRefreshed}
          setShowSubRowsTable={setShowSubRowsTable}
          showSubRowsTable={showSubRowsTable}
          updateSolarSubRows={updateSolarSubRows}
          robotGpsState={robotGpsState}
          robotInsState={robotInsState}
          robotInsStatus={robotInsStatus}
          isAutonomyAndTeleopsDisabled={isAutonomyAndTeleopsDisabled}
        />
        {/* Main Control area: Main video feed, Joystick controls */}
        <Grid item container xs={11} justifyContent="flex-end" alignItems="flex-start" className={classes.controlAreaMainPanel}>
          {showSubRowsTable && (
            <Box className={classes.subRowsTableContainer}>
              <SolarRowsTable tableSize={15} isRepeatPage selectedSubBlock={selectedSubBlockId} selectedPathType={selectedPathType} />
            </Box>
          )}
          <div style={{ height: '100%', width: '100%' }}>
            <VideoStreamMultiPageView key={isVideoStreamingRefreshed} serialNumber={serialNumber} remoteGuardianView />
            {!isDevMode && (
              <EStopIndicator
                eStopEngaged={robotEstopState || robotSwEstopState || robotSensingEdgeState}
                videoStream
                width="60%"
                height="85%"
                maxWidth="60%"
                maxHeight="85%"
                eStopType={eStopType}
              />
            )}
          </div>
          {mapType === 'gridMap' ? (
            <SolarGridMap
              customStyle
              solarRows={solarSubRowsData}
              robotEnabled
              selectedSolarRows={selectedSolarSubRows}
              resetRobot={resetRobot}
              robotLng={robotLng}
              robotLat={robotLat}
              robotHeadingRad={robotCurrentHeadingRad}
              currentPositionIndex={currentPositionIndex}
              currentSplittedRowName={currentSplittedRowName}
            />
          ) : (
            <SolarMap
              mapType={mapType}
              customStyle={classes.map}
              width="25%"
              height="523px"
              robotLat={robotLat}
              robotLng={robotLng}
              robotHeading={robotCurrentHeadingRad}
              resetRobot={resetRobot}
              mapData={cords}
              currentPositionIndex={currentPositionIndex}
              currentSplittedRowName={currentSplittedRowName}
              selectedSubRows={selectedSolarSubRows}
            />
          )}
        </Grid>
        {/* Far Right Panel: Controls, Motor status */}
        <Grid
          item
          container
          xs={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.controlAreaControlPanel}
        >
          <Grid container direction="column" justifyContent="flex-start" className={classes.controlAreaRightPanel}>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="Lights"
                robotControlMode={robotControlMode}
                defaultValue={lightsState}
                checked={lightsState}
                onClick={() => robotConnection?.current?.ros?.cmdLights(!lightsState)}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="SW ESTOP"
                skipControlStore={true} // To keep it enabled all the time except when estoped
                // disabled={robotEstopState}
                defaultValue={robotSwEstopState}
                checked={robotSwEstopState}
                onClick={() => {
                  robotConnection?.current?.ros?.cmdSwEstop(!robotSwEstopState);
                  mqttClient.current.publish(topic, payload);
                }}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="AUTONOMOUS BLADES"
                robotControlMode={robotControlMode === 'autonomous' ? 'teleops' : 'manual'}
                defaultValue={isBladeRunning && robotControlMode === 'autonomous'}
                checked={isBladeRunning && robotControlMode === 'autonomous'}
                onClick={() => {
                  if (!isBladeRunning) {
                    setEnableAutonmousBladesDialog(true);
                  } else {
                    toggleAutonomousBlades();
                  }
                }}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Wiper Speed"
                defaultValue={0}
                valueLabelDisplay="off"
                marks
                min={0}
                max={2}
                step={1}
                onChange={(value) => robotConnection?.current?.ros?.cmdWiper(value)}
                key="wiperSpeed"
                robotControlMode={robotControlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Deck Offset"
                defaultValue={deckOffset}
                valueLabelDisplay="off"
                min={-76}
                max={76}
                step={1}
                key="deckOffset"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.deckOffset}
                robotControlMode={robotControlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Carriage Offset"
                defaultValue={frameOffset}
                valueLabelDisplay="off"
                min={-65}
                max={65}
                step={1}
                key="frameOffset"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.frameOffset}
                robotControlMode={robotControlMode}
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ToolIndicator toolType={toolType} isActive={isToolActive} />
            </Grid>
            <Grid item className={classes.toggleMapSty}>
              <MapSwitch mapType={mapType} handleToggleMap={setMapType} />
            </Grid>

            <Grid item align="center" className={classes.robotControlImg}>
              <ControlImageCapture service={robotConnection.current} />
            </Grid>
            <Grid item className={classes.coolDownIndicator}>
              <Typography variant="h6">Cool Down:</Typography>
              <Tooltip classes={{ tooltip: classes.toolTip }} title={coolDownMessage}>
                <Warning fontSize="medium" key="oil" style={{ color: `${coolDownWarningColour}`, marginLeft: '5px' }} />
              </Tooltip>
            </Grid>
            <Grid item className={classes.robotControlAlt}>
              <MotorStatus motors={robotMotors} />
            </Grid>
            <Grid item className={classes.robotControl}>
              <BatteryIndicator robotNumberOfBatteries={robotNumberOfBatteries} robotBatteryPercentage={robotBatteryPercentage} />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box className={classes.joystick}>
                {robotControlMode === 'teleops' && (
                  <Joystick gamepadIndex={gamepadIndex} cwidth={175} cheight={250} visualized handleJoystickState={handleJoystickState} />
                )}
              </Box>
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch
                label="Enable Teleops Driving"
                defaultValue={teleopsDrivingEnabled}
                checked={teleopsDrivingEnabled}
                robotControlMode={robotControlMode}
                onClick={() => handlesetTeleopsDrivingEnabled(!teleopsDrivingEnabled)}
              />
            </Grid>
          </Grid>
        </Grid>
        <ActionsDialog
          dialogTitle={`Next sub-row is ready to go. Drive the robot ${serialNumber} to its starting point, start Next Sub-row?`}
          open={openDialog}
          actions={[
            { color: '#ed5063', name: 'Start', handler: openNewSubrow },
            { color: '#555555', name: 'Cancel', handler: closeDialogue }
          ]}
        />

        <ActionsDialog
          dialogTitle="Robot is not in autonomous mode now, do you want it in autonomous?"
          open={openAutonomousDialog}
          actions={[
            { color: '#ed5063', name: 'Confirm', handler: confirmWarningDialog },
            { color: '#555555', name: 'Cancel', handler: closeAutonomousDialogue }
          ]}
        />
        <ActionsDialog
          dialogTitle="You need to pause the robot before enabling AI obstacle."
          open={openAIDialog}
          actions={[{ color: '#00FFFF', name: 'OK', handler: () => setOpenAIDialog(false) }]}
        />
        <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
        <ActionsDialog
          open={lookAheadDistanceError}
          dialogTitle="ERROR: The lookahead distance must be greater than both turning thresholds"
          actions={[{ color: '#FF0000', name: 'Ok', handler: handleCloseLookAheadErrorDialog }]}
        />
      </Grid>
    );
  }
);
