import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React from 'react';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  active: {
    color: theme.palette.success.light,
    textShadow: `0 0 5px 10px ${theme.palette.secondary.main}`
  },
  inactive: { color: theme.palette.grey[300] },
  label: {
    textTransform: 'uppercase'
  }
}));

export const BatteryIndicator = ({ robotBatteryPercentage, robotNumberOfBatteries }) => {
  const classes = useStyles();
  const { controlStore } = useStores();

  return useObserver(() => (
    <>
      <Grid item container direction="row" justifyContent="space-between" alignItems="start">
        <Grid item>
          <Typography className={classes.label} gutterBottom>
            BATTERIES <br></br>#: <b>{`${robotNumberOfBatteries ?? controlStore.getNumberOfBatteries()}`}</b> &nbsp;&nbsp;&nbsp;&nbsp;
            Avg-%: <b>{`${robotBatteryPercentage ?? controlStore.getBatteryPercentage()}%`}</b>
          </Typography>
        </Grid>
      </Grid>
    </>
  ));
};
