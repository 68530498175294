import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  FormHelperText,
  makeStyles,
  OutlinedInput,
  Paper,
  InputLabel
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import { useForm } from 'react-hook-form';
import { DialogTitle } from '../core/dialog.component';
import { useStores } from '../../store/root/root.store';
import UnsavedChangesDialog from './unsaved-changes.dialog';

const useStyles = makeStyles((theme) => ({
  root: {},

  formControl: {
    marginBottom: theme.spacing(2)
  },
  uppercase: {
    '& > input': {
      textTransform: 'uppercase'
    },
    '& > input::placeholder': {
      textTransform: 'none'
    }
  }
}));

export const AddSubBlock = ({ show, onClose = () => {}, view = 'add', subBlockId = null }) => {
  const classes = useStyles();
  const { regionsStore, chaperonePropertyStore, blocksStore, subBlocksStore } = useStores();
  const { register, handleSubmit, errors } = useForm({ mode: 'onBlur' });
  const [name, setName] = useState('');
  const [openUnsavedChangesDialog, setOpenUnsavedChangesDialog] = useState(false);

  const onSubmit = async (data) => {
    const { name } = data;
    const blockId = blocksStore.selectedBlockId;
    const exist = subBlocksStore.checkDuplicates(name, blockId);
    if (!exist) {
      try {
        if (view === 'edit') {
          const response = await subBlocksStore.updateSubBlock({ subBlockId, name });
          await subBlocksStore.getSubBlocks(blockId);
          onClose(response.results.id);
        } else {
          const response = await subBlocksStore.createSubBlock({ blockId, name });
          await subBlocksStore.getSubBlocks(blockId);
          onClose(response.results.id);
        }
      } catch (error) {
        onClose();
      }
    } else {
      subBlocksStore.setError('This block already exists');
      onClose();
    }
  };

  useEffect(() => {
    async function checkSubBlockView() {
      if (view === 'edit') {
        const result = await subBlocksStore.getSubBlockById({ id: subBlockId });
        blocksStore.setSelectedBlock(result.results.block_id);
        chaperonePropertyStore.setSelectedProperty(result.results.property_id);
        regionsStore.setSelectedRegion(chaperonePropertyStore.getSelectedProperty().region_id);
        setName(result.results.name);
      }
    }
    checkSubBlockView();
  }, []);

  function handleClose() {
    if (name) setOpenUnsavedChangesDialog(true);
    else onClose();
  }

  function handleConfirmClose(doCloseFully) {
    setOpenUnsavedChangesDialog(false);
    if (doCloseFully) onClose();
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit(onSubmit)();
    }
  };

  return useObserver(() => (
    <Dialog onClose={() => handleClose()} open={show} className={classes.root} fullWidth>
      {view === 'edit' ? (
        <DialogTitle onClose={() => handleClose()}>Edit Sub Block</DialogTitle>
      ) : (
        <DialogTitle onClose={() => handleClose()}>Add Sub Block</DialogTitle>
      )}
      <DialogContent>
        <DialogContentText>{`Region: ${regionsStore.getSelectedRegion().name} `}</DialogContentText>
        <DialogContentText>{` Property: ${chaperonePropertyStore.getSelectedProperty().name}`}</DialogContentText>
        <DialogContentText>{` Block: ${blocksStore.getSelectedBlock().name}`}</DialogContentText>
        <Paper component="form" elevation={0} color="inverted">
          <FormControl variant="outlined" fullWidth error={!!errors.name} className={classes.formControl}>
            <InputLabel id="name">Name</InputLabel>
            <OutlinedInput
              aria-describedby="name-error-text"
              fullWidth
              name="name"
              label="name"
              placeholder="Name"
              onChange={(event) => {
                setName(event.target.value);
              }}
              inputRef={register({ required: true, maxLength: 250 })}
              onKeyDown={(event) => {
                handleKeyDown(event);
              }}
            />
            <FormHelperText id="name-error-text">{errors.name?.type === 'required' && 'Required'}</FormHelperText>
            <FormHelperText id="name-error-text">{errors.name?.type === 'maxLength' && 'Max Length: 250 characters'}</FormHelperText>
          </FormControl>
        </Paper>
      </DialogContent>
      <DialogActions>
        <>
          <Button onClick={() => handleClose()} variant="outlined" color="primary">
            Cancel
          </Button>
          {view === 'edit' ? (
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
              Update
            </Button>
          ) : (
            <Button onClick={handleSubmit(onSubmit)} variant="contained" color="secondary">
              Save
            </Button>
          )}
        </>
      </DialogActions>
      <UnsavedChangesDialog show={openUnsavedChangesDialog} onClose={(doCloseFully) => handleConfirmClose(doCloseFully)} />
    </Dialog>
  ));
};
