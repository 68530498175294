import { Button, Typography } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import { useObserver } from 'mobx-react-lite';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useStores } from '../../store/root/root.store';

export const ControlImageCapture = ({ service, disabled }) => {
  const { controlStore } = useStores();
  const { enqueueSnackbar } = useSnackbar();

  return useObserver(() => (
    <Button
      endIcon={<CameraAlt disabled={disabled} style={{ fontSize: '25px' }} />}
      onClick={() => {
        controlStore.setImageRequested(true);
        console.debug('Triggering manual audit');
        if (service?.ros) {
          service.ros.triggerAudit();
        }
        enqueueSnackbar('Manual audit requested', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right'
          },
          autoHideDuration: 3000
        });
        controlStore.setImageRequested(false);
      }}
      disabled={controlStore.imageRequested || disabled}
    >
      <Typography>Audit</Typography>
    </Button>
  ));
};
