import React from 'react';
import { Table, TableBody, TableContainer, TableHead, Paper, makeStyles, withStyles, TableRow, TableCell, Fade } from '@material-ui/core';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles(() => ({
  containerScrollable: {
    height: '100%',
    maxHeight: 262,
    width: '100%'
  }
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.grey[600],
    color: theme.palette.primary.contrastText
  },
  body: {
    fontSize: 14
  }
}))(TableCell);

const StyledTableRow = withStyles(() => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#e0e0e0'
    }
  }
}))(TableRow);

export default function BluetoothAdapterDetails() {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();

  return (
    <Fade in={true}>
      <TableContainer component={Paper} className={classes.containerScrollable}>
        <Table aria-label="simple table">
          <TableHead>
            <StyledTableRow>
              <StyledTableCell>ID</StyledTableCell>
              <StyledTableCell align="right">MAC Address</StyledTableCell>
              <StyledTableCell align="right">Manufacturer</StyledTableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {autonomyRobotStore.getSelectedRobot()?.robot_state?.joystick_state?.bluetooth_adapter?.map((adapter, i) => (
              <StyledTableRow key={i}>
                <StyledTableCell component="th" scope="row">
                  {adapter.id}
                </StyledTableCell>
                <StyledTableCell align="right">{adapter.mac_address}</StyledTableCell>
                <StyledTableCell align="right">{adapter.manufacturer}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Fade>
  );
}
