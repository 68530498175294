import React, { useState } from 'react';
import PropTypes from 'prop-types';

const STROKE = 1;
// const zoomFactor = 10;
let mouseInside = false;
// let mouseClicked = false;
// let initalZoomLevel;
let cursorPoint = {
  x: 0,
  y: 0
};

let touchPoint = {
  x: 0,
  y: 0
};

const LineChart = ({ data, robotLocation, onlineRobotPath, height, width, highlightedPoints, customStyle = false, isTeach }) => {
  const [movement, setMovement] = useState({ x: -width / 2, y: -height / 2 });
  const [zoomFactor] = useState(10);
  const [mouseClicked, setMouseClicked] = useState(false);

  const referencePoint = {
    lat: data?.[0]?.lat ?? 0,
    long: data?.[0]?.long ?? 0,
    angle: data?.[0]?.angle ?? 0
  };

  const onlineReferencePoint = isTeach && {
    lat: onlineRobotPath?.[0]?.lat ?? 0,
    long: onlineRobotPath?.[0]?.lng ?? 0,
    angle: onlineRobotPath?.[0]?.angle ?? 0
  };

  const radians = (angle) => {
    // convert degree to radian
    return angle * (Math.PI / 180);
  };

  const mdeglat = (lat0) => {
    // help method for lat calculation
    let lat0rad = radians(lat0);
    return 111132.09 - 566.05 * Math.cos(2.0 * lat0rad) + 1.2 * Math.cos(4.0 * lat0rad) - 0.002 * Math.cos(6.0 * lat0rad);
  };

  const mdeglon = (lat0) => {
    // help method for long calculation
    let lat0rad = radians(lat0);
    // There is a conflict in the sign of 0.12 in the last term,
    // I used the sign in the original paper
    return 111415.13 * Math.cos(lat0rad) - 94.55 * Math.cos(3.0 * lat0rad) - 0.12 * Math.cos(5.0 * lat0rad);
  };

  const latlon2xy = (lat, lon, lat0, lon0) => {
    // convert long, lat tp x,y local Coordinates
    // lat0, lon0 are the latitude and longitude at x = 0, y = 0 (origin of the local frame)
    let x = (lon - lon0) * mdeglon(lat0);
    let y = (lat - lat0) * mdeglat(lat0);
    return [x, y];
  };

  const rotatePoint = (x, y, theta0) => {
    // function to rotate points to align with local frame
    let theta_rad = theta0;
    let x_new = x * Math.cos(theta_rad) + y * Math.sin(theta_rad);
    let y_new = -1 * x * Math.sin(theta_rad) + y * Math.cos(theta_rad);
    return [x_new, -y_new];
  };

  if (data.length) {
    data = data.map((el) => {
      let [x, y] = latlon2xy(el.lat, el.long, referencePoint.lat, referencePoint.long);
      [x, y] = rotatePoint(x, y, referencePoint.angle);
      el.x = x;
      el.y = y;
      return el;
    });
  }

  let maximumXFromData;
  let minimumXFromData;
  let maximumYFromData;
  let minimumYFromData;
  const setBorders = (arr) => {
    maximumXFromData = Math.max(...arr.map((e) => e.x));
    minimumXFromData = Math.min(...arr.map((e) => e.x));
    maximumYFromData = Math.max(...arr.map((e) => e.y));
    minimumYFromData = Math.min(...arr.map((e) => e.y));
  };

  let highlightedPointsPath;
  let points;
  if (highlightedPoints && highlightedPoints.firstIndex !== '' && highlightedPoints.lastIndex !== '') {
    let yOffset = highlightedPoints.waypointUpDown;
    let xOffset = highlightedPoints.waypointLeftRight;
    let modifiedData = [];

    highlightedPointsPath = [];
    points = data
      .map((element, index) => {
        let x = parseFloat(element.x);
        let zoomed_x = element.x * zoomFactor;
        let y = parseFloat(element.y);
        let zoomed_y = element.y * zoomFactor;
        if (index >= highlightedPoints.firstIndex && index <= highlightedPoints.lastIndex) {
          x = parseFloat(element.x) + xOffset;
          zoomed_x = (parseFloat(element.x) + xOffset) * zoomFactor;
          y = parseFloat(element.y) + yOffset;
          zoomed_y = (parseFloat(element.y) + yOffset) * zoomFactor;
          highlightedPointsPath.push(`${zoomed_x},${zoomed_y}`);
        }
        modifiedData.push({ x, y });
        return `${zoomed_x},${zoomed_y}`;
      })
      .join(' ');

    setBorders(modifiedData);
    highlightedPointsPath = highlightedPointsPath.join(' ');
  } else if (data) {
    setBorders(data);
    points = data
      .map((element) => {
        const x = element.x * zoomFactor;
        const y = element.y * zoomFactor;
        return `${x},${y}`;
      })
      .join(' ');
  }

  let numberOfVerticalGuides = 0;
  let numberOfVerticalGuidesNegDir = 0;
  let numberOfHorizontalGuides = 0;
  let numberOfHorizontalGuidesNegDir = 0;

  const setGuides = () => {
    numberOfVerticalGuides = Math.ceil(maximumXFromData);
    numberOfVerticalGuidesNegDir = Math.floor(minimumXFromData);
    numberOfHorizontalGuides = Math.ceil(maximumYFromData);
    numberOfHorizontalGuidesNegDir = Math.floor(minimumYFromData);
  };

  if (data.length > 0) {
    setGuides();
    width = 2.07 * (numberOfVerticalGuides + numberOfHorizontalGuides);
  }

  let onlineRobotPathPoints;
  let pointRobotX;
  let pointRobotY;
  if (onlineRobotPath && onlineRobotPath.length) {
    let ModifiedOnlineRobotPathPoints = [];
    let currentRefrencePoint = onlineReferencePoint ?? referencePoint;
    onlineRobotPathPoints = onlineRobotPath
      .map((el) => {
        let [x, y] = latlon2xy(el.lat, el.lng, currentRefrencePoint.lat, currentRefrencePoint.long);
        [x, y] = rotatePoint(x, y, currentRefrencePoint.angle);
        if (robotLocation !== undefined) {
          pointRobotX = x * zoomFactor;
          pointRobotY = y * zoomFactor;
        }
        ModifiedOnlineRobotPathPoints.push({ x, y });
        return `${x * zoomFactor},${y * zoomFactor}`;
      })
      .join(' ');

    let modifiedData = data.concat(ModifiedOnlineRobotPathPoints);
    setBorders(modifiedData);
    setGuides();
  }

  if (onlineRobotPath && onlineRobotPath.length > 0) {
    setGuides();
    width = 2.07 * (numberOfVerticalGuides + numberOfHorizontalGuides) * 1;
  }

  // vertical lines drawn in the horizontal direction
  const VerticalGuides = () => {
    const guideCount = numberOfVerticalGuides - numberOfVerticalGuidesNegDir || data.length - 1;
    const startY = (numberOfHorizontalGuidesNegDir - 4) * zoomFactor + 2 * zoomFactor;
    const endY = numberOfHorizontalGuides * zoomFactor + 2 * zoomFactor;
    let i = -5 + numberOfVerticalGuidesNegDir;
    return new Array(guideCount + 7).fill(0).map((_) => {
      const xCoordinate = (i + 1) * zoomFactor;
      const fragment = (
        <React.Fragment key={i}>
          <polyline fill="none" stroke="#ccc" strokeWidth=".5" points={`${xCoordinate},${startY} ${xCoordinate},${endY}`} />
        </React.Fragment>
      );
      i = i + 1;
      return fragment;
    });
  };

  // horizontal lines drawn in the vertical direction
  const HorizontalGuides = () => {
    const startX = (numberOfVerticalGuidesNegDir - 2) * zoomFactor - 2 * zoomFactor;
    const endX = numberOfVerticalGuides * zoomFactor + 2 * zoomFactor;
    let i = -3 + numberOfHorizontalGuidesNegDir;
    let size = numberOfHorizontalGuides - numberOfHorizontalGuidesNegDir - 3;
    const arr = new Array(size + 8).fill(0).map((_) => {
      const yCoordinate = (i + 1) * zoomFactor;
      const fragment = (
        <React.Fragment key={i}>
          <polyline fill="none" stroke={'#ccc'} strokeWidth=".5" points={`${startX},${yCoordinate} ${endX},${yCoordinate}`} />
        </React.Fragment>
      );
      i = i + 1;
      return fragment;
    });
    return arr;
  };

  let angle, offlineAngle;
  offlineAngle = 43; // (referencePoint.angle * 57.2958) + 13; //39
  if (robotLocation && robotLocation.angle) {
    angle = 43 - robotLocation.angle;
  } else {
    if (robotLocation) angle = 43;
  }

  const preventDefault = (e) => {
    if (e.cancelable && e.target.localName === 'svg') {
      e.preventDefault();
    }
  };

  // modern Chrome requires { passive: false } when adding event
  var supportsPassive = false;
  try {
    window.addEventListener(
      'test',
      null,
      Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassive = true;
        }
      })
    );
  } catch (e) {}

  var wheelOpt = supportsPassive ? { passive: false } : false;

  // call this to Disable
  const disableScroll = () => {
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
  };

  // call this to Enable
  const enableScroll = () => {
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
  };

  return (
    <svg
      onTouchStart={(e) => {
        disableScroll();
        touchPoint = {
          x: e.targetTouches[0].clientX,
          y: e.targetTouches[0].clientY
        };
        setMouseClicked(true);
      }}
      onTouchEnd={(e) => {
        enableScroll();
        setMouseClicked(false);
      }}
      onTouchMove={(e) => {
        disableScroll();
        const deltaX = touchPoint.x - e.targetTouches[0].clientX,
          deltaY = touchPoint.y - e.targetTouches[0].clientY;
        setMovement({ x: movement.x + deltaX, y: movement.y + deltaY });
        touchPoint.x = e.targetTouches[0].clientX;
        touchPoint.y = e.targetTouches[0].clientY;
      }}
      onMouseDown={(e) => {
        cursorPoint = {
          x: e.pageX,
          y: e.pageY
        };
        setMouseClicked(true);
      }}
      onMouseEnter={(e) => {
        if (!mouseInside) mouseInside = true;
      }}
      onMouseLeave={(e) => {
        if (mouseInside) mouseInside = false;
      }}
      onMouseMove={(e) => {
        if (mouseClicked) {
          // setMovement({x: movement.x - e.movementX, y: movement.y - e.movementY}); // x and y at same time
          const deltaX = cursorPoint.x - e.pageX,
            deltaY = cursorPoint.y - e.pageY;

          //check which direction had the highest amplitude and then figure out direction by checking if the value is greater or less than zero
          if ((Math.abs(deltaX) > Math.abs(deltaY) && deltaX > 0) || (Math.abs(deltaX) > Math.abs(deltaY) && deltaX < 0)) {
            //left or right
            setMovement({ x: movement.x - e.movementX, y: movement.y });
          } else if ((Math.abs(deltaY) > Math.abs(deltaX) && deltaY > 0) || (Math.abs(deltaY) > Math.abs(deltaX) && deltaY < 0)) {
            setMovement({ x: movement.x, y: movement.y - e.movementY });
            //up or down
          }
          cursorPoint.x = e.pageX;
          cursorPoint.y = e.pageY;
        }
      }}
      onMouseUp={(e) => {
        setMouseClicked(false);
      }}
      style={
        customStyle
          ? {
              marginTop: '0vh',
              marginBottom: '0vh',
              overflow: 'auto',
              backgroundColor: 'white',
              cursor: mouseClicked ? 'grabbing' : 'grab'
            }
          : {
              marginTop: '2vh',
              marginBottom: '2vh',
              overflow: 'auto',
              backgroundColor: 'white',
              cursor: mouseClicked ? 'grabbing' : 'grab'
            }
      }
      height={customStyle ? '423px' : '50vh'}
      width="100%"
      viewBox={`${movement.x} ${movement.y} ${width} ${height}`}
      // viewBox={`0 0 ${width} ${height}`}
    >
      <defs>
        <symbol id="icon-compass" style={{ overflow: 'visible' }}>
          {angle && (
            <path
              style={{ transformBox: 'fill-box', transformOrigin: 'center' }}
              transform={`scale(0.5, 0.5) rotate(${angle})`}
              d="M17 32c-0.072 0-0.144-0.008-0.217-0.024-0.458-0.102-0.783-0.507-0.783-0.976v-15h-15c-0.469 0-0.875-0.326-0.976-0.783s0.129-0.925 0.553-1.123l30-14c0.381-0.178 0.833-0.098 1.13 0.199s0.377 0.749 0.199 1.13l-14 30c-0.167 0.358-0.524 0.577-0.906 0.577zM5.508 14h11.492c0.552 0 1 0.448 1 1v11.492l10.931-23.423-23.423 10.931z"
            ></path>
          )}
        </symbol>
        <symbol id="icon-compassOnline" style={{ overflow: 'visible' }}>
          <path
            style={{ transformBox: 'fill-box', transformOrigin: 'center' }}
            transform={`scale(0.5, 0.5) rotate(${offlineAngle})`}
            d="M17 32c-0.072 0-0.144-0.008-0.217-0.024-0.458-0.102-0.783-0.507-0.783-0.976v-15h-15c-0.469 0-0.875-0.326-0.976-0.783s0.129-0.925 0.553-1.123l30-14c0.381-0.178 0.833-0.098 1.13 0.199s0.377 0.749 0.199 1.13l-14 30c-0.167 0.358-0.524 0.577-0.906 0.577zM5.508 14h11.492c0.552 0 1 0.448 1 1v11.492l10.931-23.423-23.423 10.931z"
          ></path>
        </symbol>

        <g id="arrow2">
          <marker id="arrow" viewBox="0 0 10 10" refX="5" refY="5" markerWidth="6" markerHeight="6" orient="250">
            <path d="M 0 0 L 10 5 L 0 10 z" />
          </marker>
        </g>
      </defs>
      {numberOfVerticalGuides - numberOfVerticalGuidesNegDir && <VerticalGuides />}
      <HorizontalGuides />

      <polyline fill="none" stroke="#0074d9" strokeWidth={STROKE} points={points} />
      {pointRobotX !== undefined && pointRobotY !== undefined && (
        <use x={pointRobotX - 17} y={pointRobotY - 17} xlinkHref="#icon-compass" stroke="black" />
      )}

      {onlineRobotPathPoints && <polyline fill="none" stroke="red" strokeWidth={STROKE} points={onlineRobotPathPoints} />}

      {!isTeach && <use x={data?.[0]?.x || 0 - 17} y={data?.[0]?.y || 0 - 17} xlinkHref="#icon-compassOnline" stroke="green" />}

      {highlightedPointsPath && <polyline fill="none" stroke="orange" strokeWidth={STROKE} points={highlightedPointsPath} />}
    </svg>
  );
};

LineChart.defaultProps = {
  height: 500,
  width: 800,
  horizontalGuides: 4,
  verticalGuides: null,
  precision: 2
};

LineChart.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.number,
      label: PropTypes.string
    })
  ).isRequired,
  height: PropTypes.number,
  width: PropTypes.number,
  horizontalGuides: PropTypes.number,
  verticalGuides: PropTypes.number,
  precision: PropTypes.number
};

export default LineChart;
