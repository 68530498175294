import React, { useEffect, useState } from 'react';
import { FormControl, FormControlLabel, Grid, Tooltip, Switch, Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { observer } from 'mobx-react-lite';
import { radToDegreeConverter } from '../../utils/ui.utils';

/**
 * React Component for deviation settings. Component is responsible for rendering values and assigning new
 * values to all deviation setting values
 *
 * @param {Boolean} isSelectedRobotConnectedROS Returns true if robot has ROS connection
 * @param {Boolean} isSelectedRobotInUse Returns True if robot's status is IN_USE
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @returns None
 */
const DeviationConfiguration = observer(
  ({ isSelectedRobotConnectedROS, isSelectedRobotInUse, uiParameters, handleUiParametersChange, setUiParameters }) => {
    const [headingSlowThresholdInDegree, setHeadingSlowThresholdInDegree] = useState(0);
    const [headingStopThresholdInDegree, setHeadingStopThresholdInDegree] = useState(0);

    useEffect(() => {
      const newHeadingSlowThreshold = radToDegreeConverter(uiParameters.deviationHeadingSlowThreshold);
      const newHeadingStopThreshold = radToDegreeConverter(uiParameters.deviationHeadingStopThreshold);
      setHeadingSlowThresholdInDegree(newHeadingSlowThreshold);
      setHeadingStopThresholdInDegree(newHeadingStopThreshold);
    }, [uiParameters.deviationHeadingStopThreshold, uiParameters.deviationHeadingSlowThreshold]);
    return (
      <Grid
        item
        xs={12}
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{
          width: '100%',
          border: `1px solid ${uiParameters.autonomyNavigationDeviationCheck ? 'grey' : 'rgb(212, 212, 212)'}`,
          borderRadius: '4px',
          boxSizing: 'border-box',
          margin: '0',
          padding: '5px'
        }}
      >
        {/* Deviation Check Switch */}
        <Grid item xs={2}>
          <Tooltip title="When enabled, the robot will stop if it deviates to far from its autonomous path.">
            <FormControlLabel
              control={
                <Switch
                  name="autonomyNavigationDeviationCheck"
                  checked={uiParameters.autonomyNavigationDeviationCheck}
                  onChange={(event) => {
                    const { name, checked } = event.target;
                    const formattedValue = checked ? 1 : 0;
                    setUiParameters((previous) => ({
                      ...previous,
                      [name]: formattedValue
                    }));
                  }}
                  color="primary"
                />
              }
              labelPlacement="start"
              label="Deviation Check"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
            />
          </Tooltip>
        </Grid>
        {/* Slow Threshold Input */}
        <Grid item xs={2}>
          <Tooltip title="This determines the distance from the path that the robot needs to deviate in order to start moving at the slow speed.">
            <FormControl variant="outlined">
              <TextField
                name="deviationPositionSlowThreshold"
                required
                value={uiParameters.deviationPositionSlowThreshold}
                id="slow-threshold"
                label="Slow Threshold (m)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* Stop Threshold Input */}
        <Grid item xs={2}>
          <Tooltip title="This determines the distance from the path that the robot needs to deviate in order to stop.">
            <FormControl variant="outlined">
              <TextField
                name="deviationPositionStopThreshold"
                required
                value={uiParameters.deviationPositionStopThreshold}
                id="stop-threshold"
                label="Stop Threshold (m)"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.01' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                fullWidth
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* heading slow threshold */}
        <Grid item xs={2}>
          <Tooltip title={<Typography fontSize={30}>{`In Degrees: ${headingSlowThresholdInDegree}`}</Typography>}>
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationHeadingSlowThreshold"
                value={uiParameters.deviationHeadingSlowThreshold}
                id="heading-slow-threshold"
                label="Heading Slow Threshold"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        {/* heading stop threshold  */}
        <Grid item xs={2}>
          <Tooltip title={<Typography fontSize={30}>{`In Degrees: ${headingStopThresholdInDegree}`}</Typography>}>
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationHeadingStopThreshold"
                value={uiParameters.deviationHeadingStopThreshold}
                id="heading-stop-threshold"
                label="Heading Stop Threshold"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
        <Grid item xs={2}>
          <Tooltip title="This determines the speed at which the robot will move once the slow threshold has been reached">
            <FormControl variant="outlined">
              <TextField
                required
                name="deviationPositionSlowSpeed"
                value={uiParameters.deviationPositionSlowSpeed}
                id="slow-speed"
                label="Slow Speed"
                variant="outlined"
                type="number"
                inputProps={{ step: '0.1' }}
                disabled={!isSelectedRobotConnectedROS || !uiParameters.autonomyNavigationDeviationCheck || isSelectedRobotInUse}
                style={{ opacity: !isSelectedRobotConnectedROS && !uiParameters.autonomyNavigationDeviationCheck && '0.5' }}
                onChange={(event) => {
                  handleUiParametersChange(event, false, true);
                }}
              />
            </FormControl>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
);

export default DeviationConfiguration;
