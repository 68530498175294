import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react-lite';
import {
  Button,
  FormControl,
  Grid,
  makeStyles,
  MenuItem,
  Select,
  Typography,
  TextField,
  Slider,
  createTheme,
  TableCell,
  Box,
  Tooltip,
  Fade
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { useStores } from '../store/root/root.store';
import GridMap from '../components/gridMaps/GridMap';
import { ROUTE_CHAPERONE } from './routes';
import { getSubsectionCsvData, uploadEditedSubsection } from '../services/api/subsections.service';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { StripedTableRow } from '../components/core/striped-table-row.component';
import { StripedTable } from '../components/core/striped-table.component';
import '../App.css';
import { generateUniqueId } from '../utils/ui.utils';
import { guardedClient } from '../utils/axios-instance';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    color: theme.palette.inverted.main
  },
  TextField: {
    backgroundColor: 'white'
  },
  formControlTextField: {
    paddingLeft: theme.spacing(18.5),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 350
  },
  controls: {
    marginBottom: theme.spacing(1)
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  marker: {
    margin: theme.spacing(1)
  },
  Button: {
    margin: theme.spacing(1),
    height: 50,
    width: 120
  },

  Slider: {
    margin: theme.spacing(1),
    color: '#0178FF',
    width: 200
  },
  tableActions: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  },
  waypointDecrement: {
    height: '54px',
    borderRadius: 0,
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,
    fontSize: 64
  },
  waypointIncrement: {
    height: '54px',
    borderRadius: 0,
    borderTopRightRadius: 15,
    borderBottomRightRadius: 15,
    fontSize: 64
  }
}));

const utmObj = require('utm-latlng');

const muiTheme = createTheme({
  overrides: {
    MuiSlider: {
      thumb: {
        color: '#ea2840'
      },
      track: {
        color: '#ea2840'
      },
      rail: {
        color: 'grey'
      },
      mark: {
        color: '#ea2840'
      }
    }
  }
});

let csvArray = [];
let queueArray = [];
let unitPercent = 1;

export const EditWayPointsPage = () => {
  const { applicationStore } = useStores();
  const classes = useStyles();
  const [plowMode, setPlowMode] = useState(4);
  const [plowHeight, setPlowHeight] = useState(2);
  const [firstWaypoint, setFirstWaypoint] = useState(0);
  const [secondWaypoint, setSecondWaypoint] = useState('');
  const [waypointSliderUpDown, setWaypointSliderUpDown] = useState(0);
  const [waypointSliderLeftRight, setWaypointSliderLeftRight] = useState(0);
  const [subsectionData, setSubsectionData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [waypointRange, setWaypointRange] = useState([0, 100]);
  const [salterPercentage, setSalterPercentage] = useState('');
  const [totalLength, setTotalLength] = useState(0);
  const [plowStateArray, setPlowStateArray] = useState(Array(totalLength).fill('0'));
  const [plowHeightArray, setPlowHeightArray] = useState(Array(totalLength).fill('0'));
  const [salterArray, setSalterArray] = useState(Array(totalLength).fill('0'));
  const [fakeState, setFakeState] = useState(0);
  const [gridMapData, setGridMapData] = useState([]);
  const { state } = useLocation();
  const { push } = useHistory();
  const utm = new utmObj();

  let plow_height_enum = new Array();
  plow_height_enum[0] = 'Down';
  plow_height_enum[1] = 'Up';
  plow_height_enum[2] = 'No Change';

  let plow_state_enum = new Array();
  plow_state_enum[0] = 'V-Mode';
  plow_state_enum[1] = 'Scoop';
  plow_state_enum[2] = 'Angled Left';
  plow_state_enum[3] = 'Angled Right';
  plow_state_enum[4] = 'No Change';

  const headers = [
    '#',
    'First Index',
    'Last Index',
    'Move Waypoints Vertically',
    'Move Waypoints Horizontally',
    'Plow State',
    'Plow Height',
    'Salter Percentage',
    'Remove'
  ];

  const plowHeightEnum = [];
  plowHeightEnum[0] = 'Down';
  plowHeightEnum[1] = 'Up';
  plowHeightEnum[2] = 'No Change';

  const plowStateEnum = [];
  plowStateEnum[0] = 'V-Mode';
  plowStateEnum[1] = 'Scoop';
  plowStateEnum[2] = 'Angled Left';
  plowStateEnum[3] = 'Angled Right';
  plowStateEnum[4] = 'No Change';

  useEffect(() => {
    if (!state.region) {
      push(ROUTE_CHAPERONE);
    }
    guardedClient
      .post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region: state.region,
        property: state.property,
        section: state.section,
        // section: 'section1',
        subsection: state.subsection
      })
      .then((result) => {
        setGridMapData(result.data.data);
      })
      .catch((error) => {
        console.log(error);
        push(ROUTE_CHAPERONE);
      });
  }, []);

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  useEffect(() => {
    const getSubsectionData = async () => {
      const csvData = await getSubsectionCsvData(state.region, state.property, state.section, state.subsection);
      setSubsectionData(csvData.data);
      setFirstWaypoint(0);
      setSecondWaypoint(csvData.data.length - 1);

      unitPercent = (1.0 / csvData.data.length) * 100.0;
      setTotalLength(parseInt(csvData.data.length));
      convertToArray(csvData.data);
      applyChanges();
      updateVisualizationLines();
    };
    getSubsectionData();
  }, []);

  const updateVisualizationLines = () => {
    const tempHeightArray = [];
    const tempStateArray = [];
    const tempSalterArray = [];

    csvArray.forEach((coordinate) => {
      tempHeightArray.push(coordinate[13]);
      tempStateArray.push(coordinate[14]);
      tempSalterArray.push(coordinate[16]);
    });
    setPlowStateArray(tempStateArray);
    setPlowHeightArray(tempHeightArray);
    setSalterArray(tempSalterArray);
  };

  const changeFirstSliderValue = (event, newValue) => {
    setWaypointSliderUpDown(newValue);
  };

  const changeSecondSliderValue = (event, newValue) => {
    setWaypointSliderLeftRight(newValue);
  };

  const handleShowDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const handleFirstWaypointChange = (event, manualIndex) => {
    let newValue = 0;

    if (manualIndex === undefined) {
      newValue = event.target.value;
    } else {
      newValue = manualIndex;
    }

    if (newValue < 0) {
      setFirstWaypoint(0);
      setWaypointRange([0, secondWaypoint]);
    } else if (newValue >= subsectionData.length) {
      setFirstWaypoint(subsectionData.length - 1);
      setSecondWaypoint(subsectionData.length - 1);
      setWaypointRange([subsectionData.length - 1, subsectionData.length - 1]);
    } else if (newValue > secondWaypoint) {
      setFirstWaypoint(secondWaypoint);
      setWaypointRange([secondWaypoint, secondWaypoint]);
    } else {
      setFirstWaypoint(newValue);
      setWaypointRange([newValue, secondWaypoint]);
    }
  };

  const handleSecondWaypointChange = (event, manualIndex) => {
    let newValue = 0;

    if (manualIndex === undefined) {
      newValue = event.target.value;
    } else {
      newValue = manualIndex;
    }

    if (newValue < 0) {
      setFirstWaypoint(0);
      setSecondWaypoint(0);
      setWaypointRange([0, 0]);
    } else if (newValue >= subsectionData.length) {
      setSecondWaypoint(subsectionData.length - 1);
      setWaypointRange([firstWaypoint, subsectionData.length - 1]);
    } else if (newValue < firstWaypoint) {
      setSecondWaypoint(firstWaypoint);
      setWaypointRange([firstWaypoint, firstWaypoint]);
    } else {
      setSecondWaypoint(newValue);
      setWaypointRange([firstWaypoint, newValue]);
    }
  };

  const handleSalterChange = (event) => {
    if (event.target.value < 0) {
      setSalterPercentage(0);
    } else if (event.target.value > 100) {
      setSalterPercentage(100);
    } else {
      setSalterPercentage(event.target.value);
    }
  };

  const valuetext = (value) => `${value}`;

  const handleChange = (event, newValue) => {
    setWaypointRange(newValue);
    setFirstWaypoint(newValue[0]);
    setSecondWaypoint(newValue[1]);
  };

  const convertToArray = (array) => {
    csvArray = [];

    for (let i = 0; i < array.length; i++) {
      const coordinate = array[i];

      csvArray.push([
        i,
        coordinate.timestamp,
        coordinate.x,
        coordinate.y,
        coordinate.z,
        coordinate.x_quat,
        coordinate.y_quat,
        coordinate.z_quat,
        coordinate.w_quat,
        coordinate.heading,
        coordinate.lng,
        coordinate.lat,
        coordinate.speed,
        parseInt(coordinate.offset),
        parseInt(coordinate.plow_mode),
        coordinate.new_ps4_autoturn_cmd_direction,
        0
      ]);
    }
  };

  const addToQueue = () => {
    queueArray.push({
      firstIdx: firstWaypoint,
      secondIdx: secondWaypoint,
      verticalShift: waypointSliderUpDown,
      horizontalShift: waypointSliderLeftRight,
      plow_state: plowMode,
      plow_height: plowHeight,
      salter_level: salterPercentage
    });
    applyTempChanges();
    setFakeState(fakeState + 1);
  };

  const removeFromQueue = (index) => {
    queueArray.splice(index, 1);
    applyTempChanges();
  };

  const applyChanges = () => {
    queueArray.forEach((queue) => {
      for (let i = queue.firstIdx; i <= queue.secondIdx; i++) {
        // const new_x = parseInt(csvArray[i][2]) + parseInt(queue.horizontalShift);
        // const new_y = parseInt(csvArray[i][3]) + parseInt(queue.verticalShift);

        // csvArray[i][2] = new_x;
        // csvArray[i][3] = new_y;

        // const utmValues = utm.convertLatLngToUtm(csvArray[i][11], csvArray[i][10], 4);
        // const latlng = utm.convertUtmToLatLng(new_x, new_y, parseInt(utmValues.ZoneNumber), utmValues.ZoneLetter.toString());

        // csvArray[i][10] = latlng.lng;
        // csvArray[i][11] = latlng.lat;

        if (parseInt(queue.plow_state) !== 4) {
          csvArray[i][14] = parseInt(queue.plow_state);
        }

        if (parseInt(queue.plow_height) !== 2) {
          csvArray[i][13] = parseInt(queue.plow_height);
        }

        if (queue.salter_level !== '') {
          csvArray[i][16] = parseInt(queue.salter_level);
        }
      }
    });
  };

  const applyTempChanges = () => {
    convertToArray(subsectionData);
    applyChanges();
    updateVisualizationLines();
  };

  const handleEditWps = async () => {
    convertToArray(subsectionData);
    applyChanges();
    queueArray = [];

    const csvContent = csvArray.map((e) => e.join(',')).join('\n');
    handleShowDialog('Updating the subsection csv file in progress ...');
    await uploadEditedSubsection(csvContent, state.region, state.property, state.section, state.subsection);
    setLoading(false);
    push({
      pathname: ROUTE_CHAPERONE
    });
  };

  const salterColours = (index) => {
    const red = (salterArray[index] / 100) * (234 - 165) + 165;
    const green = (salterArray[index] / 100) * (40 - 173) + 173;
    const blue = (salterArray[index] / 100) * (64 - 181) + 181;
    return [red, green, blue];
  };

  return useObserver(() => (
    <Fade in={true}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <Grid item xs={4}>
          <Typography variant="h4" className={classes.label}>
            Edit group of waypoints
          </Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            First waypoints index:
          </Typography>
          <Button
            className={classes.waypointDecrement}
            onClick={() => {
              handleFirstWaypointChange(undefined, firstWaypoint - 1);
            }}
            variant="contained"
            color="secondary"
          >
            -
          </Button>
          <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            type="number"
            value={firstWaypoint}
            onChange={(event) => {
              handleFirstWaypointChange(event, undefined);
            }}
            style={{ backgroundColor: '#ffffff' }}
          />
          <Button
            className={classes.waypointIncrement}
            onClick={() => {
              handleFirstWaypointChange(undefined, firstWaypoint + 1);
            }}
            variant="contained"
            color="secondary"
          >
            +
          </Button>
          <Typography variant="h6" component="span" className={classes.label} style={{ marginLeft: '15px' }}>
            Last waypoints index:
          </Typography>
          <Button
            className={classes.waypointDecrement}
            onClick={() => {
              handleSecondWaypointChange(undefined, secondWaypoint - 1);
            }}
            variant="contained"
            color="secondary"
          >
            -
          </Button>
          <TextField
            hiddenLabel
            id="filled-hidden-label-normal"
            variant="filled"
            type="number"
            value={secondWaypoint}
            onChange={(event) => {
              handleSecondWaypointChange(event, undefined);
            }}
            style={{ backgroundColor: '#ffffff' }}
          />
          <Button
            className={classes.waypointIncrement}
            onClick={() => {
              handleSecondWaypointChange(undefined, secondWaypoint + 1);
            }}
            variant="contained"
            color="secondary"
          >
            +
          </Button>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <ThemeProvider theme={muiTheme}>
            <Slider
              getAriaLabel={() => 'Waypoints Range'}
              value={waypointRange}
              onChange={handleChange}
              valueLabelDisplay="auto"
              getAriaValueText={valuetext}
              max={subsectionData.length - 1}
              step={1}
              marks
            />
          </ThemeProvider>
        </Grid>
        <Grid item container direction="column" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Plow State
          </Typography>

          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs>
            {Array.from({ length: totalLength }, (v, i) => i).map((value, index) => (
              <Tooltip key={generateUniqueId()} title={<Typography>{plowStateEnum[plowStateArray[index]]}</Typography>} placement="top">
                <div
                  style={{
                    width: `${unitPercent}%`,
                    height: '10px',
                    borderTop: plowStateArray[index] === 1 || plowStateArray[index] === 2 ? '3px solid #ea2840' : '1px solid #a5adb5',
                    borderBottom: plowStateArray[index] === 1 || plowStateArray[index] === 3 ? '3px solid #ea2840' : '1px solid #a5adb5',
                    boxShadow:
                      plowStateArray[index] === 1
                        ? '0px -5px 5px #ea2840, 0px 5px 5px #ea2840'
                        : plowStateArray[index] === 2
                        ? '0px -5px 5px #ea2840'
                        : plowStateArray[index] === 3
                        ? '0px 5px 5px #ea2840'
                        : '0px 0px 0px #a5adb5',
                    backgroundColor: '#a5adb5'
                  }}
                />
              </Tooltip>
            ))}
          </Grid>
          <Typography variant="h6" component="span" className={classes.label}>
            Plow Height
          </Typography>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs>
            {Array.from({ length: totalLength }, (v, i) => i).map((value, index) => (
              <Tooltip key={generateUniqueId()} title={<Typography>{plowHeightEnum[plowHeightArray[index]]}</Typography>} placement="top">
                <div
                  style={{
                    width: `${unitPercent}%`,
                    height: '10px',
                    backgroundColor: plowHeightArray[index] === 1 ? '#ea2840' : '#a5adb5'
                  }}
                />
              </Tooltip>
            ))}
          </Grid>
          <Typography variant="h6" component="span" className={classes.label}>
            Salter Speed
          </Typography>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="flex-start" xs>
            {Array.from({ length: totalLength }, (v, i) => i).map((value, index) => (
              <Tooltip key={generateUniqueId()} title={<Typography>{`${salterArray[index]}%`}</Typography>} placement="top">
                <div
                  style={{
                    width: `${unitPercent}%`,
                    height: '10px',
                    backgroundColor: `rgb(${salterColours(index)[0]}, ${salterColours(index)[1]}, ${salterColours(index)[2]})`
                  }}
                />
              </Tooltip>
            ))}
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Grid item container direction="column" justifyContent="flex-start" alignItems="center" xs>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                Move all waypoints up/down &#40;m&#41;:
              </Typography>
              <Grid item xs>
                <ThemeProvider theme={muiTheme}>
                  <Slider
                    className={classes.Slider}
                    defaultValue={0}
                    value={waypointSliderUpDown}
                    onChange={changeFirstSliderValue}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={-2}
                    max={2}
                    disabled
                  />
                </ThemeProvider>
              </Grid>
            </Grid>

            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                Move all waypoints Left/Right &#40;m&#41;:
              </Typography>

              <Grid item xs>
                <ThemeProvider theme={muiTheme}>
                  <Slider
                    className={classes.Slider}
                    defaultValue={0}
                    value={waypointSliderLeftRight}
                    onChange={changeSecondSliderValue}
                    aria-labelledby="continuous-slider"
                    valueLabelDisplay="auto"
                    step={0.1}
                    min={-2}
                    max={2}
                    disabled
                  />
                </ThemeProvider>
              </Grid>
            </Grid>

            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                Plow State:
              </Typography>

              <FormControl className={classes.formControl}>
                <Select
                  value={plowMode}
                  className={classes.select}
                  onChange={(e) => {
                    setPlowMode(e.target.value);
                  }}
                >
                  <MenuItem value="4">
                    <em>No Change</em>
                  </MenuItem>
                  <MenuItem value="0">
                    <em>V</em>
                  </MenuItem>
                  <MenuItem value="1">
                    <em>Scoop</em>
                  </MenuItem>
                  <MenuItem value="2">
                    <em>Angled Left</em>
                  </MenuItem>
                  <MenuItem value="3">
                    <em>Angled Right</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                Plow Height:
              </Typography>

              <FormControl className={classes.formControl}>
                <Select
                  value={plowHeight}
                  className={classes.select}
                  onChange={(e) => {
                    setPlowHeight(e.target.value);
                  }}
                >
                  <MenuItem value="2">
                    <em>No Change</em>
                  </MenuItem>
                  <MenuItem value="1">
                    <em>Up</em>
                  </MenuItem>
                  <MenuItem value="0">
                    <em>Down</em>
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Typography variant="h6" component="span" className={classes.label}>
                {'Salter percentage (> 0% and <= 100%):'}
              </Typography>

              <FormControl className={classes.formControl}>
                <TextField
                  InputLabelProps={{
                    style: { color: '#FFFFFF' }
                  }}
                  hiddenLabel
                  type="number"
                  className={classes.TextField}
                  id="filled-hidden-label-normal"
                  variant="filled"
                  value={salterPercentage}
                  onChange={handleSalterChange}
                />
              </FormControl>
            </Grid>
            <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
              <Button
                className={classes.Button}
                onClick={() => {
                  addToQueue();
                }}
                variant="contained"
                color="secondary"
              >
                Apply
              </Button>
              <Button
                onClick={() => {
                  handleEditWps();
                }}
                className={classes.Button}
                variant="contained"
                color="secondary"
              >
                Save
              </Button>
              <Button
                onClick={() => {
                  setWaypointSliderLeftRight(0);
                  setWaypointSliderUpDown(0);
                  setPlowMode(4);
                  setPlowHeight(2);
                  setSalterPercentage('');
                }}
                className={classes.Button}
                variant="contained"
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <GridMap
              gridMapData={gridMapData}
              highlightedPoints={{
                firstIndex: firstWaypoint,
                lastIndex: secondWaypoint,
                waypointUpDown: waypointSliderUpDown,
                waypointLeftRight: waypointSliderLeftRight
              }}
            />
          </Grid>
          <Box style={{ maxHeight: '50vh', overflow: 'auto', marginTop: '15px' }}>
            {queueArray.length > 0 && (
              <StripedTable headers={headers}>
                {queueArray.map((queue, index) => (
                  <StripedTableRow key={index}>
                    <TableCell align="center">{index + 1}</TableCell>
                    <TableCell align="center">{queue.firstIdx}</TableCell>
                    <TableCell align="center">{queue.secondIdx}</TableCell>
                    <TableCell align="center">{queue.verticalShift}m</TableCell>
                    <TableCell align="center">{queue.horizontalShift}m</TableCell>
                    <TableCell align="center">{plow_state_enum[queue.plow_state]}</TableCell>
                    <TableCell align="center">{plow_height_enum[queue.plow_height]}</TableCell>
                    <TableCell align="center">{queue.salter_level !== '' ? queue.salter_level + '%' : 'No Change'}</TableCell>
                    <TableCell align="center">
                      {
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          onClick={() => {
                            removeFromQueue(index);
                          }}
                        >
                          Remove
                        </Button>
                      }
                    </TableCell>
                  </StripedTableRow>
                ))}
              </StripedTable>
            )}
          </Box>
        </Grid>
        <Box style={{ maxHeight: '50vh', overflow: 'auto', marginTop: '15px' }}>
          {queueArray.length > 0 && (
            <StripedTable headers={headers}>
              {queueArray.map((queue, index) => (
                <StripedTableRow key={generateUniqueId()}>
                  <TableCell align="center">{index + 1}</TableCell>
                  <TableCell align="center">{queue.firstIdx}</TableCell>
                  <TableCell align="center">{queue.secondIdx}</TableCell>
                  <TableCell align="center">{`${queue.verticalShift}m`}</TableCell>
                  <TableCell align="center">{`${queue.horizontalShift}m`}</TableCell>
                  <TableCell align="center">{plowStateEnum[queue.plow_state]}</TableCell>
                  <TableCell align="center">{plowHeightEnum[queue.plow_height]}</TableCell>
                  <TableCell align="center">{queue.salter_level !== '' ? `${queue.salter_level}%` : 'No Change'}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => {
                        removeFromQueue(index);
                      }}
                    >
                      Remove
                    </Button>
                  </TableCell>
                </StripedTableRow>
              ))}
            </StripedTable>
          )}
        </Box>
      </Grid>
    </Fade>
  ));
};
