import { types, flow, getEnv } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { ChaperoneRegion } from './models/chaperone-region.model';
import { alphaNumericOrder } from '../../utils/ui.utils';

const RegionStoreInternal = types
  .model('RegionsStore', {
    regions: types.array(ChaperoneRegion),
    selectedRegionId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedRegionId,
    getById: (id) => self.regions.find((r) => r.id === parseInt(id)),
    getByName: (name) => self.regions.find((r) => r.name === name),
    getSelectedRegion: () => self.regions.find((r) => r.id === self.selectedRegionId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.fetchRegions(filter);
    }),
    getApiCreate: flow(function* f(region) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.addRegion(region);
    }),
    getApiUpdate: flow(function* f(region) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.updateRegion(region);
    }),
    deleteRegion({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.deleteRegion(id);
    },
    getRegionById({ id }) {
      const { RegionsService } = getEnv(self);
      return RegionsService.fetchRegionById(id);
    },
    onFetchSuccess(results) {
      self.regions = results.toSorted(alphaNumericOrder((r) => r.name));
    },
    onCreateSuccess(created) {
      console.log('sucess');
      //  self.regions.push(created);
    },
    onUpdateSuccess(data) {
      console.log('Update Successful');
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load Regions');
    },
    createRegion: flow(function* createRegion(region) {
      console.log(region);
      return yield self.create(region);
    }),
    getRegions: flow(function* getRegions(country) {
      yield self.fetch(country);
    }),
    updateRegion: flow(function* updateRegion(region) {
      return yield self.update(region);
    }),
    onCreateError(error) {
      self.throwError(error?.message || 'Failed to save region');
    },
    onSaveError(error) {
      self.throwError(error?.message || 'Region already exists');
    },
    setSelectedRegion(id) {
      self.selectedRegionId = id;
    },
    clearSelectedRegion() {
      self.selectedRegionId = null;
    },
    setError(msg) {
      self.throwError(msg);
    },
    clearRegions() {
      self.regions = [];
    },
    checkDuplicates(region) {
      var exist = self.regions.some((item) => region.name === item.name);
      if (exist) return true;
      else return false;
    }
  }));

export const RegionsStore = types.compose(RegionStoreInternal, ResourceStore).named('RegionsStore');
