import { types } from 'mobx-state-tree';

export const ChaperoneProperty = types.model({
  id: types.integer,
  name: types.string,
  city: types.string,
  postal_code: types.string,
  province: types.string,
  street_address: types.string,
  created_at: types.string,
  updated_at: types.string,
  region_id: types.integer,
});
