import React, { useRef } from 'react';
import { useObserver } from 'mobx-react-lite';

import { useSnackbar } from 'notistack';
import { IconButton, makeStyles } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useStores } from '../../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  dismiss: {
    color: theme.palette.primary.contrastText
  }
}));

export const ErrorPopup = () => {
  const { applicationStore } = useStores();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const classes = useStyles();
  const snackbarId = useRef(null); // Variable to track the current snackbar ID
  const showErrorTimeout = useRef(null);

  const action = (key) => (
    <IconButton
      onClick={() => {
        closeSnackbar(key);
      }}
    >
      <Close className={classes.dismiss} />
    </IconButton>
  );

  /**
   * Have to remove this from the main observe to prevent state updates in a render cycle
   * @param {*} message
   */
  const showError = (message) => {
    // Clear previous timeout to avoid overlapping timeouts
    clearTimeout(showErrorTimeout.current);

    // Set a new timeout to show the error message
    showErrorTimeout.current = setTimeout(() => {
      const errorID = enqueueSnackbar(message, {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: 10000,
        onClick: () => closeSnackbar(errorID)
      });
      snackbarId.current = errorID;
    }, 0); // Use 0 to put the function in the event queue and allow other synchronous code to execute first
  };

  const showInfo = (message) => {
    if (snackbarId.current !== null) {
      // If a snackbar is already visible, close it
      closeSnackbar(snackbarId.current);
    }

    if (message !== '') {
      const infoID = enqueueSnackbar(message, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right'
        },
        autoHideDuration: 10000,
        onClick: () => closeSnackbar(infoID)
      });
      snackbarId.current = infoID;
    }
  };

  useObserver(() => {
    applicationStore.errors.forEach((error) => {
      showError(error.message, () => action);
      applicationStore.removeError(error.id);
    });
    applicationStore.infos.forEach((info) => {
      showInfo(info.message);
      applicationStore.removeInfo(info.id);
    });
  });

  return null;
};
