import React from 'react';
import { makeStyles, Grid, Typography, Card, CardContent, CardActionArea } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import { formatTimestamp, formatDeviation } from '../../utils/audits.utils';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  selected: {
    background: theme.palette.secondary.main
  },
  loading: {
    background: '#e0e0e0'
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    fontSize: 14
  }
}));

const AuditCard = observer(
  ({ record, robotSerialNumber, subsection, timestamp, completedSuccessfully, maxDeviation, averageDeviation, isLoading }) => {
    const classes = useStyles();
    const bull = <span className={classes.bullet}>•</span>;
    const { autonomyAuditReportsStore } = useStores();

    return (
      <Card
        className={autonomyAuditReportsStore.selectedRecord === record ? classes.selected : isLoading ? classes.loading : null}
        variant="outlined"
      >
        <CardActionArea
          disabled={autonomyAuditReportsStore?.selectedRecord?.loading}
          onClick={() => autonomyAuditReportsStore.selectRecord(record)}
        >
          <CardContent>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs>
                <Grid item container direction="row" justifyContent="space-between" alignContent="flex-start">
                  <Grid item>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      <b>
                        {completedSuccessfully !== null ? (completedSuccessfully === true ? 'Complete' : 'Incomplete') : DEFAULT_NO_DATA}
                      </b>
                      {bull}
                      {`${averageDeviation ? formatDeviation(averageDeviation) : DEFAULT_NO_DATA} avg`}
                      {bull}
                      {`${maxDeviation ? formatDeviation(maxDeviation) : DEFAULT_NO_DATA} max`}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {timestamp ? formatTimestamp(timestamp, true).time : DEFAULT_NO_DATA}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container direction="row" justifyContent="space-between" alignContent="center">
                  <Grid item>
                    <Typography variant="h5" component="h2" noWrap>
                      {robotSerialNumber ? robotSerialNumber : DEFAULT_NO_DATA}
                      {bull}
                      {subsection ? subsection : DEFAULT_NO_DATA}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                      {timestamp ? formatTimestamp(timestamp, true).date : DEFAULT_NO_DATA}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    );
  }
);

export default AuditCard;
