import { Box, Button, Grid, LinearProgress, makeStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import axios from 'axios';
import { ROUTE_AUDIT_OFFICER, ROUTE_SOLAR_AUDIT_OFFICER_DETAILS } from '../routes';
import { ControlImageCapture } from '../../components/control/control-image-capture.component';
import { BatteryIndicator } from '../../components/control/control-battery-indicator.component';
import { ToolIndicator } from '../../components/control/control-tool-indicator.component';
import { MapSwitch } from '../../components/control/switch-map-visualization';
import { ControlSlider } from '../../components/control/control-slider.component';
import { ControlSwitch } from '../../components/control/control-switch.component';
import { MotorStatus } from '../../components/control/motor-status.component';
import { useStores } from '../../store/root/root.store';
import RepeatControls from '../../components/control/repeat-controls.component';
import Map from '../../components/maps/chaperone-map';
import GridMap from '../../components/gridMaps/GridMap';
import { AutonomyVideo } from '../../components/control/autonomy-video.component';
import ActionsDialog from '../../components/dialogs/actions.dialog';
import ConnectionErrorDialog from '../../components/dialogs/connection-error.dialog';
import { ChaperoneRobotConnectionService } from '../../services/chaperone/robot-connection.service';
import { useRobotMetadata } from './useRobotMetadata';
import { NON_SOLAR_LAWN_MOWING, SOLAR_LAWN_MOWING, DECK_AND_FRAME_OFFSET_SLIDER_MARKS } from '../../utils/constants';
import LoadingDialog from '../../components/dialogs/loading-dialog.dialog';
import { EStopIndicator } from '../../components/control/e-stop-indicator';
import { isDevMode } from '../../utils/ui.utils';

const maxSpeedLimit = 3; // max level of the speed limit control

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 1600,
    minHeight: 800
  },
  controlAreaLeftPanel: {
    paddingRight: theme.spacing(1)
  },
  controlAreaMainPanel: {
    position: 'relative',
    marginTop: '35px'
  },
  controlAreaRightPanel: {
    paddingLeft: theme.spacing(1)
  },
  controlAreaControlPanel: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: '35px'
  },
  auditOfficerBox: {
    textAlign: 'center',
    width: 175,
    height: 250,
    fontSize: 50,
    color: 'white'
  },
  mapTitle: {
    background: 'rgba(32, 32, 32, 1.0)',
    color: theme.palette.inverted.main,
    height: '3.5%',
    padding: theme.spacing(1)
  },
  imgPanelShort: {
    height: '25%'
  },
  imgPanelTall: {
    height: '50%'
  },
  progress: {
    color: theme.palette.inverted.main
  },
  robotControl: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    paddingTop: theme.spacing(0),
    paddingBottom: theme.spacing(0),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    marginBottom: 3,
    borderRadius: theme.spacing(1)
  },
  robotControlImg: {
    width: '100%',
    backgroundColor: theme.palette.grey[500],
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  toggleMapSty: {
    backgroundColor: theme.palette.grey[500],
    width: '100%',
    marginBottom: theme.spacing(1),
    borderRadius: theme.spacing(1)
  },
  robotControlAlt: {
    width: '100%',
    lineHeight: '0px',
    paddingBottom: theme.spacing(0.5),
    borderRadius: theme.spacing(1)
  },
  map: {
    position: 'absolute',
    right: '0',
    bottom: '10px',
    zIndex: '3'
  }
}));

export const AuditOfficerRepeatPage = observer(() => {
  const classes = useStyles();
  const { applicationStore, autonomyRobotStore, controlStore, subsectionStore } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const frontVideoStream = useRef(null);
  const [connecting, setConnecting] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const isGrassCutting = [SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING].includes(autonomyRobotStore.getSelectedRobot().use_case);
  const [subsectionMeta, setSubsectionMeta] = useState({});
  const userName = localStorage.getItem('username');

  const toolType = isGrassCutting ? 'Lawn-Mower Active' : 'Salter Active';
  const isToolActive = isGrassCutting ? controlStore.isLawnMowing : controlStore.isSalting;

  const { state, pathname } = useLocation();
  const selectedRobotId = state?.robotId;
  const currentSelectedRobot = autonomyRobotStore.robots.find((r) => r.id === selectedRobotId);
  const subsectionsBucket = applicationStore.getEnvironment().SubsectionsBucket;
  const isSolar = pathname === ROUTE_SOLAR_AUDIT_OFFICER_DETAILS;

  const robotAddressMetadata = useRobotMetadata(selectedRobotId, isSolar);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');

  const localDataStore = {
    serial_number: currentSelectedRobot?.serial_number || '',
    robot_name: currentSelectedRobot?.name || '',
    home_region_name: robotAddressMetadata?.home_region_name || '',
    home_property_name: robotAddressMetadata?.home_property_name || '',
    region_name: robotAddressMetadata?.region_name || '',
    property_name: robotAddressMetadata?.property_name || '',
    section_name: robotAddressMetadata?.section_name || '',
    subsection_name: robotAddressMetadata?.subsection_name || '',
    subsection_id: robotAddressMetadata?.subsection_id || ''
  };

  const [errorMessage, setErrorMessage] = useState('');
  const [isWspStateReceived, setIsWspStateReceived] = useState(false);

  const [googleMapData, setGoogleMapData] = useState([]);
  const [gridMapData, setGridMapData] = useState([]);
  const [lookAheadDistanceError, setLookAheadDistanceError] = useState(false);
  const [mapType, setMapType] = useState('gridMap');
  const deckOffset = controlStore.deckOffset * -1;
  const frameOffset = controlStore.frameOffset * -1;
  const { push } = useHistory();

  const handleConnectionError = () => {
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const fetchSubsectionMeta = (subsectionId) => {
    axios
      .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`)
      .then((res) => setSubsectionMeta(res.data.results));
  };

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const handleRestartVideo = () => {
    showLoadingDialog("Restarting the robot's video streaming system now, the video will be ready in less than 10 seconds.", 10);
    robotConnection?.current?.ros?.restartRobotService('video');
    setLoading(false);
  };

  const updateNavParams = (params) => {
    robotConnection?.current?.ros.updateNavParams(params);
  };

  useEffect(() => {
    if (controlStore.error && currentSelectedRobot.use_case === 'SNOW_PLOWING') {
      if (controlStore.error === 'localization') {
        setErrorMessage(`${controlStore.error} Error occurred, Cancel this subsection, then Restart restart it again!`);
      } else {
        setErrorMessage(
          `${controlStore.error} Error occurred, reconnect the ${controlStore.error} and if the issue presists, try to reboot the robot!`
        );
      }
    }
  }, [controlStore.error]);

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  const cancelRobotConnection = async () => {
    if (robotAddressMetadata) {
      await robotConnection?.current?.ros.sendNavCmd(
        `CANCEL,swapautonomy` +
          robotAddressMetadata?.region_id +
          '_' +
          robotAddressMetadata?.property_id +
          '_' +
          robotAddressMetadata?.section_id +
          '_' +
          robotAddressMetadata?.subsection_id +
          '.csv' +
          ',' +
          localStorage.getItem('username')
      );
    }
  };

  useEffect(() => {
    console.log('In audit officer page useEffect');
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (currentSelectedRobot) {
        try {
          // On first load, initiate the robot connection
          if (robotConnectionService.current === null) {
            console.debug('Connecting to robot ', currentSelectedRobot.id);
            setConnecting(true);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onConnected
                robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robot_state) =>
                  controlStore.updateRobotState(robot_state)
                );
                robotConnection.current = robotConnectionService.current;
                controlStore.setSpeedLimit(0);
                setConnecting(false);
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                handleConnectionError();
                robotConnection.current = null;
              },
              currentSelectedRobot.serial_number,
              userName,
              'audit_officer'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
        }
      } else {
        // There is no selected robot
        push(ROUTE_AUDIT_OFFICER);
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);

    return () => {
      if (robotConnectionService.current !== null) {
        cancelRobotConnection();
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
      autonomyRobotStore.clearSelectedRobot();
    };
  }, [currentSelectedRobot]);

  useEffect(() => {
    // wait for robots metadata to load
    if (
      !currentSelectedRobot ||
      !robotAddressMetadata?.region_id ||
      !robotAddressMetadata?.property_id ||
      !robotAddressMetadata?.section_id ||
      !robotAddressMetadata?.subsection_id ||
      controlStore.isInTeachingSession()
    ) {
      return;
    }

    // Get and set the robot map data
    (async () => {
      setResetRobot(true);
      controlStore.resetRobotPosition();
      await subsectionStore.getSubsections(
        undefined,
        robotAddressMetadata.region_id,
        robotAddressMetadata.property_id,
        robotAddressMetadata.section_id
      );
      let res = await axios.post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region: robotAddressMetadata.region_id,
        property: robotAddressMetadata.property_id,
        section: robotAddressMetadata.section_id,
        subsection: robotAddressMetadata.subsection_id
      });
      setGridMapData(res.data.data);
      let cords = res.data.data.map((el) => {
        return { lat: Number(el.lat), lng: Number(el.long) };
      });
      setGoogleMapData(cords);
    })();

    return () => {
      controlStore.resetRobotPosition();
    };
  }, [currentSelectedRobot, robotAddressMetadata, isWspStateReceived]);

  const handleCloseErrorDialog = () => {
    setErrorMessage('');
    push(ROUTE_AUDIT_OFFICER);
  };

  const handleCloseLookAheadErrorDialog = () => {
    setLookAheadDistanceError(false);
  };

  return (
    // During connection, show loader
    connecting ? (
      <>
        <LinearProgress className={classes.progress} />
        <Button
          variant="contained"
          color="secondary"
          size="large"
          style={{ marginTop: '16px' }}
          onClick={() => {
            setConnecting(false);
          }}
        >
          Cancel Connection audit officer
        </Button>
      </>
    ) : (
      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" className={classes.root}>
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <RepeatControls
          readOnlyMode
          localDataStore={localDataStore}
          controlMode={controlStore.robotControlMode}
          robot={selectedRobotId}
          subsection={localDataStore.subsection_id}
          handleRestartVideo={handleRestartVideo}
          updateNavParams={updateNavParams}
          subsectionMeta={subsectionMeta}
          fetchSubsectionMeta={fetchSubsectionMeta}
        />
        {/* Main Control area: Main video feed, Joystick controls */}
        <Grid item container xs={11} justifyContent="flex-end" alignItems="flex-start" className={classes.controlAreaMainPanel}>
          {!isDevMode && (
            <AutonomyVideo stream={frontVideoStream.current} serialNumber={autonomyRobotStore.getSelectedRobot().serial_number} />
          )}
          {!isDevMode && (
            <EStopIndicator
              eStopEngaged={controlStore.estopState || controlStore.swEstopState}
              videoStream={true}
              width={650}
              height={650}
            />
          )}
          {mapType === 'googleMap' ? (
            <Map
              customStyle={classes.map}
              width="25%"
              height="423px"
              robotLat={controlStore.lat}
              resetRobot={resetRobot}
              googleMapData={googleMapData}
              robotLng={controlStore.lng}
            />
          ) : (
            <GridMap
              robotEnabled
              customStyle={true}
              gridMapData={gridMapData}
              resetRobot={resetRobot}
              robotLng={controlStore.lng}
              robotLat={controlStore.lat}
              robotHeadingRad={controlStore.current_heading_rad}
            />
          )}
        </Grid>
        {/* Far Right Panel: Controls, Motor status */}
        <Grid
          item
          container
          xs={1}
          direction="row"
          justifyContent="flex-start"
          alignItems="flex-end"
          className={classes.controlAreaControlPanel}
        >
          <Grid container direction="column" justifyContent="flex-start" className={classes.controlAreaRightPanel}>
            <Grid item className={classes.robotControl}>
              <ControlSwitch disabled value="lights" label="Lamps" />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSwitch disabled value="beeper" label="Beeper" />
            </Grid>
            {!isGrassCutting && (
              <Grid item className={classes.robotControl}>
                <ControlSlider
                  label="Salter Rate"
                  defaultValue={controlStore.saltLevel}
                  disabled
                  valueLabelDisplay="off"
                  marks
                  min={0}
                  max={3}
                  step={1}
                />
              </Grid>
            )}
            <Grid item className={classes.robotControl}>
              <ControlSlider label="Wiper Speed" defaultValue={0} disabled valueLabelDisplay="off" marks min={0} max={2} step={1} />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Deck Offset"
                defaultValue={deckOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.deckOffset}
                min={-76}
                max={76}
                step={1}
                key="deckOffset"
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Carriage Offset"
                defaultValue={frameOffset}
                valueLabelDisplay="off"
                marks={DECK_AND_FRAME_OFFSET_SLIDER_MARKS.frameOffset}
                min={-76}
                max={76}
                step={1}
                key="frameOffset"
              />
            </Grid>
            <Grid item className={classes.robotControl}>
              <ToolIndicator toolType={toolType} isActive={isToolActive} />
            </Grid>
            <Grid item className={classes.toggleMapSty}>
              <MapSwitch mapType={mapType} handleToggleMap={setMapType} />
            </Grid>

            <Grid item align="center" className={classes.robotControlImg}>
              <ControlImageCapture disabled={true} service={robotConnection.current} />
            </Grid>
            <Grid item className={classes.robotControlAlt}>
              <MotorStatus />
            </Grid>
            <Grid item className={classes.robotControl}>
              <BatteryIndicator />
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Box className={classes.auditOfficerBox}>Audit officer Mode</Box>
            </Grid>
            <Grid item className={classes.robotControl}>
              <ControlSlider
                label="Speed Limit"
                defaultValue={controlStore.speedLimit}
                valueLabelDisplay="off"
                marks
                min={0}
                max={maxSpeedLimit}
                step={1}
                disabled
              />
            </Grid>
          </Grid>
        </Grid>
        <ConnectionErrorDialog open={!!errorMessage} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
        <ActionsDialog
          open={lookAheadDistanceError}
          dialogTitle="ERROR: The lookahead distance must be greater than both turning thresholds"
          actions={[{ color: 'secondary', name: 'Ok', variant: 'contained', handler: handleCloseLookAheadErrorDialog }]}
        />
      </Grid>
    )
  );
});
