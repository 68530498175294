import { useEffect, useState } from 'react';
import { fetchRobotAddressMetadata } from '../../services/api/robots.service';

export const useRobotMetadata = (robotId, solarMode) => {
  const [localDataStore, setLocalDataStore] = useState();

  useEffect(() => {
    if (!robotId) return;

    (async () => {
      try {
        const res = await fetchRobotAddressMetadata(robotId, solarMode);
        setLocalDataStore(res.results);
      } catch {
        setLocalDataStore(null);
      }
    })();
  }, [robotId]);

  return localDataStore;
};
