export const ensureWebComponentIsLoaded = (deviceId, callback, multiRobots = false) => {
  const userId = 'swap';
  if (multiRobots || (deviceId && !document.getElementById('webrtc-web-component-video'))) {
    const script = document.createElement('script');
    script.id = 'webrtc-web-component-video';
    const host = 'https://portal.transitiverobotics.com';
    const path = 'running/@transitive-robotics/webrtc-video/dist/webrtc-video-device.js';
    const params = `userId=${userId}&deviceId=${deviceId}`;
    script.setAttribute('src', `${host}/${path}?${params}`);
    document.head.appendChild(script);
    script.onload = callback();
  }
};
