import axios from 'axios';
import Amplify from 'aws-amplify';
import { createContext, useContext } from 'react';
import { RegionsService } from './services/api/regions.service';
import { AuditReportsService } from './services/api/audit-reports.service';

import { HTTP_ENDPOINT_NAME } from './services/http.service';

// Expose environment config, services to be injected into MST operations
export const Environment = () => {
  axios.defaults.baseURL = process.env.REACT_APP_REST_BASE_URL;
  const envName = process.env.REACT_APP_ENV;
  axios.defaults.timeout = 30000;

  axios.defaults.headers.get['Content-Type'] = 'application/json';
  axios.defaults.headers.post['Content-Type'] = 'application/json';

  Amplify.configure({
    region: process.env.REACT_APP_AMPLIFY_REGION,
    userPoolId: process.env.REACT_APP_AMPLIFY_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AMPLIFY_USER_POOL_WEB_CLIENT_ID,
    API: {
      endpoints: [{ name: HTTP_ENDPOINT_NAME, endpoint: process.env.REACT_APP_REST_BASE_URL }]
    }
  });

  const env = {
    // config, http services, etc
    debugLogging: Boolean(process.env.REACT_APP_LOG_DEBUG),
    ChaperoneBaseUrl: String(process.env.REACT_APP_CHAPERONE_BASE_URL),
    RegionsService: new RegionsService(),
    AuditReportsService: new AuditReportsService(),
    SubsectionsBucket: `${process.env.REACT_APP_AWS_SUBSECTIONS_BUCKET_NAME}-${envName}`,
    IotTopic: String(process.env.REACT_APP_AWS_IOT_EVENT_TOPIC),
    webRtcConfig: {
      iceCandidatePoolSize: process.env.REACT_APP_WEBRTC_ICE_CANDIDATE_POOL_SIZE,
      iceServers: [...JSON.parse(process.env.REACT_APP_WEBRTC_STUN_SERVERS), ...JSON.parse(process.env.REACT_APP_WEBRTC_TURN_SERVERS)]
    },
    googleMaps: {
      apiKey: String(process.env.REACT_APP_GOOGLE_MAPS_API_KEY),
      defaultCenter: JSON.parse(String(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_CENTER)),
      defaultZoom: Number(process.env.REACT_APP_GOOGLE_MAPS_DEFAULT_ZOOM)
    },
    mapkitToken: String(process.env.REACT_APP_MAPKIT_TOKEN),
    defaultDateTimeFormat: 'MMM dd, yyyy hh:mm aa',
    defaultDateFormat: 'MMM dd, yyyy',
    defaultTimeFormat: 'hh:mm aa',
    lazyCacheTimeout: process.env.REACT_APP_RESOURCE_LAZY_CACHE_TIMEOUT || 60000,
    defaultQueryMaxRecords: process.env.REACT_APP_RESOURCE_QUERY_MAX_RECORDS || 100,

    robotSerialFormat: process.env.REACT_APP_ROBOT_SERIAL_FORMAT || /^V[0-9]{2}S[0-9]{4}$/i,
    robotSerialExample: process.env.REACT_APP_ROBOT_SERIAL_EXAMPLE || 'V##S####',
    envName
  };

  console.debug('Environment', env);

  return env;
};

// Expose a context for React components that are not wired up to the Tree
export const EnvironmentContext = createContext();

// Expose as a hook
export const useEnvironment = () => useContext(EnvironmentContext).state;
