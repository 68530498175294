import { guardedClient } from '../../utils/axios-instance';
import { generateDeviationArray } from '../../utils/audits.utils';
import { PATH_TYPES } from '../../utils/constants';

const subsectionsBucket = process.env.REACT_APP_AWS_SUBSECTIONS_BUCKET_NAME;

const resolveIdsNames = async (toBeResolvedIds) => {
  const { countries, regions, properties, sections, subsections, blocks, subblocks, subrows } = toBeResolvedIds;
  const response = await guardedClient.post('/audits/autonomy/resolve', {
    // applying filter to filter out falsy values
    countries: [...countries].filter((el) => el),
    regions: [...regions].filter((el) => el),
    properties: [...properties].filter((el) => el),
    sections: [...sections].filter((el) => el),
    subsections: [...subsections].filter((el) => el),
    blocks: [...blocks].filter((el) => el),
    subblocks: [...subblocks].filter((el) => el),
    subrows: [...subrows].filter((el) => el)
  });
  return response.data.results;
};

const getSubrowNameFromS3Uri = (s3Uri) => {
  try {
    return s3Uri.split('/').at(-2).split('__')[0];
  } catch (error) {
    console.error(error);
    return '';
  }
};

export const fetchReports = async (query) => {
  const {
    countryId,
    regionId,
    propertyId,
    sectionId,
    subsectionIds,
    completedSuccessfully,
    robots,
    startTime,
    endTime,
    averageDeviationStart,
    averageDeviationEnd,
    maxDeviationStart,
    maxDeviationEnd,
    nextToken
  } = query;
  const response = await guardedClient.get('/audits/autonomy', {
    params: {
      countryId,
      regionId,
      propertyId,
      sectionId,
      subsectionIds,
      completedSuccessfully,
      robots,
      startTime,
      endTime,
      averageDeviationStart,
      averageDeviationEnd,
      maxDeviationStart,
      maxDeviationEnd,
      nextToken
    }
  });
  const { data } = response;
  const toBeResolvedIds = {
    countries: new Set(),
    regions: new Set(),
    properties: new Set(),
    sections: new Set(),
    subsections: new Set(),
    blocks: new Set(),
    subblocks: new Set(),
    subrows: new Set()
  };
  data.results.forEach((result) => {
    toBeResolvedIds.countries.add(result.countryId);
    toBeResolvedIds.regions.add(result.regionId);
    toBeResolvedIds.properties.add(result.propertyId);
    if (result.s3URI.includes('/subrows/')) {
      toBeResolvedIds.blocks.add(result.sectionId);
      toBeResolvedIds.subblocks.add(result.subBlockId);
      toBeResolvedIds.subrows.add(result.subsectionId);
    } else {
      toBeResolvedIds.sections.add(result.sectionId);
      toBeResolvedIds.subsections.add(result.subsectionId);
    }
  });
  const resolvedIdsNames = await resolveIdsNames(toBeResolvedIds);
  resolvedIdsNames.subrows = [];
  data.results.forEach((result) => {
    if (result.s3URI.includes('/subrows/')) {
      resolvedIdsNames.subrows.push({ id: result.subsectionId, name: getSubrowNameFromS3Uri(result.s3URI) });
    }
  });
  return { ...data, results: { records: data.results, resolvedIdsNames } };
};

const parseSolarUri = (uri) => {
  let parsed = null;
  let matches = null;
  let hasPathType = true;
  const regexWithPathType = new RegExp(`${subsectionsBucket}(\\d+)\\/(\\d+)\\/(\\d+)\\/(\\d+)\\/(.+)\\/subrows\\/(.+)\\/(.+)\\.csv`);
  const regexWithoutPathType = new RegExp(`${subsectionsBucket}(\\d+)\\/(\\d+)\\/(\\d+)\\/(\\d+)\\/subrows\\/(.+)\\/(.+)\\.csv`);

  if (uri.match(regexWithPathType)) {
    matches = uri.match(regexWithPathType);
  } else {
    matches = uri.match(regexWithoutPathType);
    hasPathType = false;
  }

  if (matches[4] === 'None') {
    matches[4] = PATH_TYPES.LEGACY;
  }
  parsed = {
    region: matches[1],
    property: matches[2],
    block: matches[3],
    subblock: matches[4],
    pathType: hasPathType ? matches[5] : PATH_TYPES.LEGACY,
    fileName: hasPathType ? matches[6] : matches[5],
    timestamp: hasPathType ? matches[7] : matches[6],
    repeatedPath: [],
    taughtPath: []
  };

  parsed.repeatedPath = [`${parsed.fileName}/${parsed.timestamp}.csv`];
  parsed.taughtPath = [`${parsed.fileName}.csv`];

  return parsed;
};

const fetchSolarData = (regionId, propertyId, blockId, subblockId, fileNames, pathType) =>
  guardedClient.get('/robots/solar-map/', {
    params: { regionId, propertyId, blockId, subblockId, fileNames, pathType }
  });

const parseSnowUri = (uri) => {
  const regex = new RegExp(`${subsectionsBucket}(\\d+)\\/(\\d+)\\/(\\d+)\\/${subsectionsBucket}(\\d+)_(\\d+)_(\\d+)_(\\d+)\\/(.+).csv`);
  const matches = uri.match(regex);

  const parsed = {
    region: matches[1],
    property: matches[2],
    section: matches[3],
    subsection: matches[7],
    timestamp: matches[8],
    subsectionWithTimestamp: ''
  };

  parsed.subsectionWithTimestamp = `${parsed.subsection}/${parsed.timestamp}`;

  return parsed;
};

const fetchSnowData = (region, property, section, subsection) =>
  guardedClient.post('/api/robot/getMap/', { region, property, section, subsection });

export const fetchS3 = async (uri) => {
  const isSolar = uri.includes('subrows');
  let { repeatedCsvData, taughtCsvData } = [];

  if (isSolar) {
    const { region, property, block, subblock, repeatedPath, pathType, taughtPath } = parseSolarUri(uri);
    repeatedCsvData = await fetchSolarData(region, property, block, subblock, repeatedPath, pathType);
    taughtCsvData = await fetchSolarData(region, property, block, subblock, taughtPath, pathType);
  } else {
    const { region, property, section, subsection, subsectionWithTimestamp } = parseSnowUri(uri);
    repeatedCsvData = await fetchSnowData(region, property, section, subsectionWithTimestamp);
    taughtCsvData = await fetchSnowData(region, property, section, subsection);
  }
  if (repeatedCsvData.data.data.length && taughtCsvData.data.data.length) {
    const deviationArray = await generateDeviationArray(taughtCsvData.data.data, repeatedCsvData.data.data);
    const response = {
      repeatedCsvData: repeatedCsvData.data.data,
      taughtCsvData: taughtCsvData.data.data,
      deviationArray
    };
    return response;
  }
};
