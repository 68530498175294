import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function ConnectionErrorDialog({ open, backdropProps, style, containerRef, handleClose, errorMessage }) {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      style={style}
      BackdropProps={backdropProps}
      container={containerRef === undefined || !containerRef ? null : containerRef.current}
      aria-describedby="connection-error-description"
    >
      <DialogContent>
        <DialogTitle id="connection-error-description" style={{ alignSelf: 'center' }}>{errorMessage}</DialogTitle>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}
