import axios from 'axios';
import { API, Auth } from 'aws-amplify';

export const HTTP_ENDPOINT_NAME = 'TeleopsUI';
export const globalAxios = axios;

export class HttpService {
  constructor() {
    /**
     * JS does not support 'abstract' classes.
     * This pattern approximates the behaviour at runtime
     */
    if (this.constructor === HttpService) {
      throw new Error('Abstract Classes cannot be instantiated');
    }
  }

  async getHeaders() {
    const session = await Auth.currentSession();
    return { Authorization: session.idToken.jwtToken };
  }

  async get(resourceUrl, headers = {}, queryStringParameters = {}) {
    const defaultHeaders = await this.getHeaders();
    const res = await API.get(HTTP_ENDPOINT_NAME, resourceUrl, {
      headers: { ...defaultHeaders, ...headers },
      queryStringParameters
    });
    return res;
  }

  async post(resourceUrl, body = {}, headers = {}) {
    const defaultHeaders = await this.getHeaders();
    return await API.post(HTTP_ENDPOINT_NAME, resourceUrl, { body, headers: { ...defaultHeaders, ...headers } });
  }

  async put(resourceUrl, body = {}, headers = {}) {
    const defaultHeaders = await this.getHeaders();
    return await API.put(HTTP_ENDPOINT_NAME, resourceUrl, { body, headers: { ...defaultHeaders, ...headers } });
  }

  async delete(resourceUrl, entity, headers = {}) {
    const defaultHeaders = await this.getHeaders();
    return await API.del(HTTP_ENDPOINT_NAME, `${resourceUrl}/${entity.id}`, {
      headers: { ...defaultHeaders, ...headers }
    });
  }
}
