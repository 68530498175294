import React from 'react';
import { Container, useTheme, makeStyles } from '@material-ui/core';

import { ErrorBoundary } from './error-boundary.component';

const useStyles = makeStyles((theme) => ({
  bodyRoot: {
    backgroundColor: 'transparent',
    height: '100%',
    // overflow: 'scroll'
  },
}));

export const Body = ({ children }) => {
  const classes = useStyles(useTheme());
  return (
    <ErrorBoundary>
      <Container maxWidth={false} className={classes.bodyRoot}>
        {children}
      </Container>
    </ErrorBoundary>
  );
};
