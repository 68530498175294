import { makeStyles, TableCell, Grid, Tooltip, Typography, IconButton } from '@material-ui/core';
import { PlayArrow, DesktopWindowsRounded, EditRounded, InfoRounded, DeleteRounded } from '@material-ui/icons';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { formatDate } from '../../utils/ui.utils';
import { ROUTE_ROUTE_REPEAT } from '../../pages/routes';

const useStyles = makeStyles((theme) => ({
  marker: {
    margin: theme.spacing(1.25)
  },
  icon: {
    color: 'black',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  }
}));

const RoutesTable = observer(({ routes }) => {
  const classes = useStyles();
  const { push } = useHistory();
  const routesList = [];

  for (let i = 0; i < routes.length; i += 1) {
    const route = routes[i].toString().split('/').at(-1);
    const routeName = route.slice(0, route.lastIndexOf('_')).substring(24);
    const unixTime = parseInt(route.split('_').pop().slice(0, -4));
    const timestamp = formatDate(new Date(unixTime));

    routesList.push({ name: routeName, index: i, created_at: timestamp, full_name: route, unix: unixTime });
  }

  routesList.sort((a, b) => (a.unix < b.unix ? 1 : -1));

  return (
    <StripedTable headers={['Actions', 'Name', 'Created']}>
      {routesList.map((route) => (
        <StripedTableRow key={route.index}>
          <TableCell align="left">
            <Grid container spacing={1}>
              <Grid item>
                <Tooltip title={<Typography>Repeat</Typography>}>
                  <IconButton
                    size="small"
                    className={classes.icon}
                    onClick={() => {
                      push({
                        pathname: ROUTE_ROUTE_REPEAT,
                        state: {
                          route
                        }
                      });
                    }}
                  >
                    <PlayArrow />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography>Visualize</Typography>}>
                  <IconButton
                    size="small"
                    className={classes.icon}
                    onClick={() => {
                      console.log('Visualization button clicked');
                    }}
                  >
                    <DesktopWindowsRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography>Edit</Typography>}>
                  <IconButton
                    size="small"
                    className={classes.icon}
                    onClick={() => {
                      console.log('Edit button clicked');
                    }}
                  >
                    <EditRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography>Details</Typography>}>
                  <IconButton
                    size="small"
                    className={classes.icon}
                    onClick={() => {
                      console.log('Info button clicked');
                    }}
                  >
                    <InfoRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={<Typography>Delete</Typography>}>
                  <IconButton size="small" className={classes.icon} onClick={() => console.log('Delete button clicked')}>
                    <DeleteRounded />
                  </IconButton>
                </Tooltip>
              </Grid>
            </Grid>
          </TableCell>
          <TableCell align="left">{route.name}</TableCell>
          <TableCell align="left">{route.created_at}</TableCell>
        </StripedTableRow>
      ))}
    </StripedTable>
  );
});

export default RoutesTable;
