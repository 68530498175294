import { LinearProgress, makeStyles, Typography, Grid, Fade, Tooltip, Box } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { useState } from 'react';
import { AuditReportsMapView } from '../components/audit/audit-reports-mapview.component';
import { AuditReportsTableView } from '../components/audit/audit-reports-tableview.component';
import { AuditReportsSnailTrails } from '../components/audit/audit-snail-trails.component';
import { AuditFilter } from '../components/search/audit-filter.component';
import { useStores } from '../store/root/root.store';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { DEFAULT_VALUE } from '../components/inputs/controlled-select.component';

const useStyles = makeStyles((theme) => ({
  noRecords: {
    color: theme.palette.inverted.main
  },
  toggleGroup: {
    backgroundColor: theme.palette.grey[200],
    Width: '100%',
    height: 40,
    marginBottom: theme.spacing(2)
  },
  ToggleButton: {
    padding: theme.spacing(1)
  },
  detailPanel: {
    padding: theme.spacing(2),
    height: '100%',
    width: '50%'
  }
}));

let data;

export const OperationsAuditReportsPage = () => {
  const classes = useStyles();
  const { auditReportsStore } = useStores();

  const [currentView, setCurrentView] = React.useState('table');
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [firstLoad, setFirstLoad] = useState(true);
  const [valuesUpdated, setValuesUpdated] = useState(false);
  const [selectedRobots, setSelectedRobots] = useState([]);

  const updateFirstLoad = (value) => {
    setFirstLoad(value);
  };

  const updateValues = (value) => {
    setValuesUpdated(value);
  };

  const getData = (passedData) => {
    data = passedData;
    setCurrentView('table');
  };

  const updateSelectedRobots = (value) => {
    setSelectedRobots(value);
  };

  const reportsViewHandler = (newView) => {
    if (data?.robot === DEFAULT_VALUE && newView === 'snail') {
      setConnectionError(true);
      setErrorMessage('No robot is selected, select a robot and try again!');
    } else {
      newView ? setCurrentView(newView) : setCurrentView(currentView);
      newView ? auditReportsStore.setDataView(newView) : auditReportsStore.setDataView(currentView);
    }
  };

  return useObserver(() => (
    <Fade in={true}>
      <div>
        <AuditFilter
          getData={getData}
          setFirstLoad={updateFirstLoad}
          setValuesUpdated={updateValues}
          setSelectedRobots={updateSelectedRobots}
        />
        <div className={classes.toggle}>
          <Grid item container direction="row" justifyContent="center" alignItems="center" style={{ marginTop: '10px' }}>
            <Tooltip title={selectedRobots.length !== 0 ? '' : 'Select a robot'}>
              <ToggleButtonGroup
                className={classes.toggleGroup}
                exclusive
                value={currentView}
                onChange={(event, newView) => {
                  if (data?.robot === DEFAULT_VALUE && newView === 'snail') {
                    setConnectionError(true);
                    setErrorMessage('No robot is selected, select a robot and try again!');
                  } else {
                    newView ? setCurrentView(newView) : setCurrentView(currentView);
                    newView ? auditReportsStore.setDataView(newView) : auditReportsStore.setDataView(currentView);
                  }
                }}
                aria-label="text formatting"
              >
                <ToggleButton color="primary" value={'table'} aria-label="bold">
                  <h3>Reports</h3>
                </ToggleButton>
                <ToggleButton color="secondary" value={'snail'} aria-label="italic" disabled={selectedRobots.length === 0}>
                  <h3>Snail Trails</h3>
                </ToggleButton>
              </ToggleButtonGroup>
            </Tooltip>
          </Grid>
        </div>
        {auditReportsStore.loading ? (
          <LinearProgress color="secondary" variant="indeterminate" />
        ) : (
          <>
            {valuesUpdated && !firstLoad ? (
              <Typography variant="h6" component="p" className={classes.noRecords}>
                Filter updates have been detected. Please regenerate the results.
              </Typography>
            ) : !auditReportsStore.loading && auditReportsStore.records.length === 0 ? (
              <Typography variant="h6" component="p" className={classes.noRecords}>
                No Records to display
              </Typography>
            ) : (
              <>
                {auditReportsStore.getCurrentDataView() === 'table' ? (
                  <AuditReportsTableView />
                ) : (
                  <>{auditReportsStore.filter.dataView === 'snail' ? <AuditReportsSnailTrails /> : <AuditReportsMapView />}</>
                )}
              </>
            )}
          </>
        )}
        <ConnectionErrorDialog open={connectionError} handleClose={() => setConnectionError(false)} errorMessage={errorMessage} />
      </div>
    </Fade>
  ));
};
