import React, { useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core';

import DateFnsUtils from '@date-io/date-fns';
import { isDate } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(0),
    minWidth: '250px'
  }
}));

export const ControlledDatePicker = ({
  name,
  label = '',
  defaultValue = null,
  onChange = () => {},
  control,
  required = false,
  ...props
}) => {
  const [date, setDate] = useState(new Date());
  const classes = useStyles();

  const isRequiredError = control.errorsRef?.current[name]?.type === 'required';
  const isFormatError = control.errorsRef?.current[name]?.type === 'format';

  useEffect(() => {
    if (defaultValue) {
      setDate(defaultValue);
    }
  }, [defaultValue]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Controller
        as={
          <KeyboardDatePicker
            className={classes.root}
            disableToolbar
            autoOk={true}
            variant="inline"
            format="MM/dd/yyyy"
            margin="normal"
            id={`${name}-date-picker`}
            label={label}
            onChange={(d) => setDate(d)}
            KeyboardButtonProps={{
              'aria-label': 'change start date'
            }}
            error={isRequiredError || isFormatError}
            helperText={isRequiredError || isFormatError ? 'Please provide a valid date' : ''}
          />
        }
        onClick={() => {
          props.updateDate();
        }}
        name={name}
        control={control}
        value={date}
        defaultValue={date}
        rules={{ required: required, validate: { format: (value) => isDate(value) } }}
      />
    </MuiPickersUtilsProvider>
  );
};
