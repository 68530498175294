import { types } from 'mobx-state-tree';
import { createContext, useContext } from 'react';
import { AuthenticationStore } from '../authentication/authentication.store';
import { Environment } from '../../environment';
import { ApplicationStore } from '../application/application.store';
import { ControlStore } from '../control/control.store';
import { RegionsStore } from '../resources/regions.store';
import { BlocksStore } from '../resources/blocks.store';
import { SubBlocksStore } from '../resources/sub-blocks.store';
import { AuditReportsStore } from '../audit-reports/audit-reports.store';
import { AutonomyAuditReportsStore } from '../audit-reports/autonomy-audit-reports.store';
import { SectionStore } from '../resources/section.store';
import { SubsectionStore } from '../resources/subsection.store';
import { SubrowStore } from '../resources/solar-subrows.store';
import { AutonomyRobotStore } from '../resources/autonomy-robot.store';
import { chaperonePropertyStore } from '../resources/chaperone-property.store';
import { CountriesStore } from '../resources/countries.store';
import { MultiRobotsStore } from '../multi-robots/multi-robots.store';

export const RootStore = types
  .model('RootStore', {
    applicationStore: types.optional(ApplicationStore, () => ApplicationStore.create({ errors: [] })),
    authenticationStore: types.optional(AuthenticationStore, () =>
      AuthenticationStore.create({ loading: false, authenticated: false, role: '' })
    ),
    controlStore: types.optional(ControlStore, () => ControlStore.create({})),
    auditReportsStore: types.optional(AuditReportsStore, () => AuditReportsStore.create({})),
    autonomyAuditReportsStore: types.optional(AutonomyAuditReportsStore, () => AutonomyAuditReportsStore.create({})),
    // Resources
    countriesStore: types.optional(CountriesStore, () => CountriesStore.create({})),
    regionsStore: types.optional(RegionsStore, () => RegionsStore.create({})),
    chaperonePropertyStore: types.optional(chaperonePropertyStore, () => chaperonePropertyStore.create({})),
    sectionStore: types.optional(SectionStore, () => SectionStore.create({})),
    blocksStore: types.optional(BlocksStore, () => BlocksStore.create({})),
    subBlocksStore: types.optional(SubBlocksStore, () => SubBlocksStore.create({})),
    subsectionStore: types.optional(SubsectionStore, () => SubsectionStore.create({})),
    subrowStore: types.optional(SubrowStore, () => SubrowStore.create({})),
    autonomyRobotStore: types.optional(AutonomyRobotStore, () => AutonomyRobotStore.create({})),
    MultiRobotsStore: types.optional(MultiRobotsStore, () => MultiRobotsStore.create({}))
  })
  .views((self) => ({}))
  .actions((self) => ({
    afterCreate() {}
  }));

export const setupRootStore = () => {
  const env = Environment();
  const rootStore = RootStore.create({}, env);
  mapkit.init({
    authorizationCallback(done) {
      done(env.mapkitToken);
    }
  });
  return rootStore;
};

const RootStoreContext = createContext({});

export const RootStoreProvider = RootStoreContext.Provider;

/**
 * Hook to provide the MST tree to React components
 */
export const useStores = () => useContext(RootStoreContext);
