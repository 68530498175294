import { React, useEffect, useState } from 'react';
import { Grid, makeStyles, Typography, Box, Paper, Fade } from '@material-ui/core';
import { guardedClient } from '../../utils/axios-instance';

const useStyles = makeStyles(() => ({
  paper: {
    backgroundColor: 'white'
  }
}));
function toHoursAndMinutes(totalSeconds) {
  const totalMinutes = Math.floor(Number(totalSeconds) / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  const result = `${hours}h ${minutes}m`;
  return result;
}
export default function MotorsOpsHours({ serialNumber }) {
  const classes = useStyles();
  const [robotMotorsOpsHours, setRobotMotorsOpsHours] = useState({
    left_motor_ops_time: '',
    right_motor_ops_time: '',
    hydrolic_motor_ops_time: ''
  });

  const getMotorsOpsHours = () => {
    guardedClient
      .get('/diagnostics/motors-ops-time', {
        params: {
          serialNumber
        }
      })
      .then(({ data: { results } }) => {
        setRobotMotorsOpsHours({
          left_motor_ops_time: toHoursAndMinutes(results[0].left_motor_ops_time),
          right_motor_ops_time: toHoursAndMinutes(results[0].right_motor_ops_time),
          hydrolic_motor_ops_time: toHoursAndMinutes(results[0].hydrolic_motor_ops_time)
        });
      });
  };
  useEffect(() => {
    if (serialNumber) {
      getMotorsOpsHours();
    }
  }, [serialNumber]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (serialNumber) {
        getMotorsOpsHours();
      }
    }, 60000); // update the motors ops hours every 1 minute
    return () => {
      clearInterval(intervalId);
    };
  }, [serialNumber]);

  return (
    <Fade in>
      <Paper elevation={10} className={classes.paper}>
        <Grid container direction="column" justifyContent="center" alignContent="center">
          <Box m={1}>
            <Typography align="center" variant="subtitle1">
              L-Motor Driving Hours: <b>{robotMotorsOpsHours?.left_motor_ops_time}</b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              R-Motor Driving Hours: <b>{robotMotorsOpsHours?.right_motor_ops_time}</b>
            </Typography>
            <Typography align="center" variant="subtitle1">
              Hydraulics Ops Hours: <b>{robotMotorsOpsHours?.hydrolic_motor_ops_time}</b>
            </Typography>
          </Box>
        </Grid>
      </Paper>
    </Fade>
  );
}
