/* eslint-disable comma-dangle */
/* eslint comma-dangle: ["error", "always-multiline"] */
import { RosChaperoneService } from './ros.service';
import { timeWrap } from '../../utils/logging.utils';

// Hold instance for use by nested event handlers
let robotConnection = null;

// Indicate the intent to maintain a connection. Set to false when the operator navigates
//  away from the Control page
let autoReconnect = true;

// Caller's onConnected method

/*
 * Called when ROS connection is successful.
 */

export class ChaperoneRobotConnectionService {
  // Member variables
  pc = null;

  ws = null;

  ros = null;

  hasConnected = false;

  timeoutLock = false;

  time = null;
  constructor(onConnected = () => {}, onDisconnect = () => {}, robotSerial = '', user = '', webpage = '') {
    this.callersOnConnected = onConnected;
    this.onDisconnect = onDisconnect;
    this.robotSerial = robotSerial;
    this.user = user;
    this.webpage = webpage;
    robotConnection = this;
    autoReconnect = true;
    this.ros = new RosChaperoneService(this.robotSerial, this.user, this.webpage);
  }

  /**
   * Initiates the connection to the given robot
   * @param {string} robotId
   */
  async connectToRobot(error_callback = () => {}) {
    // await this.createWebSocket(robotId, authToken)
    await this.connectROS(error_callback);
  }

  /**
   * Once we have a Websocket, initiate the RTC/ROS connections
   */
  async connectROS(error_callback = () => {}) {
    try {
      this.hasConnected = false;
      console.info(timeWrap(`ROS connection started!!!!!!, ${this.robotSerial}`));
      await this.ros.connect(this.callersOnConnected, this.onDisconnect);
      console.info(timeWrap('ROS connection complete'));
      this.hasConnected = true;
      this.time = null;
    } catch (e) {
      console.error(timeWrap('Unable to connect to robot'), e.toString());
      const d = new Date();
      if (!this.time) {
        console.log('Would normally get connection error here');
        this.time = d.getTime();
      }
      // error_callback();
    }
  }

  retryConnection() {
    // Cancel any prior timer
    if (autoReconnect && !this.timeoutLock) {
      this.timeoutLock = true;
  
      console.info(timeWrap('Scheduling reconnection in 5 seconds'));
  
      setTimeout(async () => {
        try {
          await this.connectROS();
          console.info(timeWrap('Successfully re-established ROS connection'));
        } catch (error) {
          console.error(timeWrap('Error reconnecting to ROS:', error));
        } finally {
          this.timeoutLock = false;
        }
      }, 5000);
    }
  }

  closeWebSocket() {
    this.ws.close();
  }

  /**
   * Create a websocket connection to the AWS Signalling/Relay
   * @param {string} robotId
   */
  createWebSocket(robotId, authToken) {
    const url = `${process.env.REACT_APP_WEB_SOCKET_URL}?id=${robotId}&type=operator&authorization=${authToken}`;
    // const url = `${process.env.REACT_APP_WEB_SOCKET_URL}?id=${robotId}&type=operator`

    return new Promise((resolve, reject) => {
      try {
        console.debug(timeWrap('Creating WebSocket with url: '), url);
        this.ws = new WebSocket(url);
        this.ws.onerror = (e) => {
          console.error(timeWrap('WebSocket error observed: '), e);
          reject(e);
        };
        this.ws.onclose = () => {
          // Auto reconnect the WebSocket
          if (autoReconnect) {
            console.warn(timeWrap('WebSocket closed - reconnecting'));
            this.createWebSocket(robotId, authToken);
          }
        };
        this.ws.onmessage = (signalEvent) => {
          this.receiveSignal(signalEvent);
        };
        this.ws.onopen = () => {
          console.info(timeWrap('WebSocket opened'));
          resolve(this.ws);
        };
      } catch (e) {
        console.error(timeWrap('Exception thrown creating WebSocket: '), e);
        reject(e);
      }
    });
  }

  /**
   * Called to break down the connections when the control page is unmounted
   */
  destroy() {
    console.debug(timeWrap('Cleaning up robot connection'));
    autoReconnect = false;
    if (this.ros) {
      this.ros.close();
    }
    if (this.ws) {
      this.closeWebSocket();
    }
  }
}

export const LINEAR_SPEED = {
  min: 0.5,
  max: 4.0,
  step: 0.2,
  default: 2.6
};
export const SALT_RATE = {
  min: 0,
  max: 4,
  step: 1
};
export const ANGULAR_SPEED = {
  min: 30,
  max: 150,
  step: 5,
  default: 110
};
export const LOOK_AHEAD = {
  min: 1,
  max: 6,
  step: 0.1,
  default: 2.6
};
export const GAMMA = {
  min: 72,
  max: 500,
  step: 25,
  default: 75
};
export const CONTRAST = {
  min: 0,
  max: 64,
  step: 5,
  default: 38
};
