import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import {
  makeStyles,
  Paper,
  Grid,
  Box,
  Typography,
  Fade,
  IconButton,
  CircularProgress,
  Tooltip,
  Dialog,
  DialogContent
} from '@material-ui/core';
import NavigateBeforeRoundedIcon from '@material-ui/icons/NavigateBeforeRounded';
import NavigateNextRoundedIcon from '@material-ui/icons/NavigateNextRounded';
import FullscreenRoundedIcon from '@material-ui/icons/FullscreenRounded';
import FullscreenExitRoundedIcon from '@material-ui/icons/FullscreenExitRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { useReactToPrint } from 'react-to-print';
import { useStores } from '../../store/root/root.store';
import AuditTimeDeviationChart from './audit-time-deviation-chart.component';
import AuditCumulativeDeviationChart from './audit-cumulative-deviation-chart.component';
import AuditDetails from './audit-details.component';
import { formatTimestamp, formatDeviation } from '../../utils/audits.utils';
import SolarGridMap from '../gridMaps/SolarGridMap';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';
import { DialogTitle } from '../core/dialog.component';

const useStyles = makeStyles((theme) => ({
  paperMain: {
    padding: theme.spacing(2)
  },
  paper: {
    padding: theme.spacing(2)
  },
  title: {
    fontSize: 14
  },
  noConnection: {
    color: theme.palette.inverted.main
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  favourited: {
    color: theme.palette.secondary.main
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const AuditDataDisplay = observer(() => {
  const classes = useStyles();
  const bull = <span className={classes.bullet}>•</span>;
  const [isDetailsDialogOpen, setIsDetailsDialogOpen] = useState(false);
  const { autonomyAuditReportsStore } = useStores();

  const handleFullScreenDialog = () => {
    if (autonomyAuditReportsStore.showFullScreenDialog) autonomyAuditReportsStore.setShowFullScreenDialog(false);
    else autonomyAuditReportsStore.setShowFullScreenDialog(true);
  };

  const handleDetailsButton = async () => {
    await autonomyAuditReportsStore.getAllLocationDetails();
    setIsDetailsDialogOpen(true);
  };

  const downloadRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => downloadRef.current
  });

  const components = [
    {
      name: 'Deviation Over Time',
      component: <AuditTimeDeviationChart className={classes.paper} />
    },
    {
      name: 'Cumulative Deviation',
      component: <AuditCumulativeDeviationChart className={classes.paper} />
    },
    {
      name: 'Route Overlay',
      component: (
        <SolarGridMap
          isZoomEnabled
          isAutonomyAudits
          solarRows={{
            0: autonomyAuditReportsStore?.selectedRecord?.taughtCsvData,
            1: autonomyAuditReportsStore?.selectedRecord?.repeatedCsvData
          }}
          selectedSolarRows={['1']}
        />
      )
    }
  ];

  return (
    <>
      <Fade in>
        <Paper ref={downloadRef} className={classes.paperMain}>
          <Grid container direction="row" justifyContent="flex-end" alignContent="flex-end">
            <Box mb={1}>
              <IconButton
                aria-label="previous"
                size="medium"
                disabled={!autonomyAuditReportsStore.hasPrevRecord}
                onClick={autonomyAuditReportsStore.selectPrevRecord}
              >
                <NavigateBeforeRoundedIcon />
              </IconButton>
              <IconButton
                aria-label="next"
                size="medium"
                disabled={!autonomyAuditReportsStore.hasNextRecord}
                onClick={autonomyAuditReportsStore.selectNextRecord}
              >
                <NavigateNextRoundedIcon />
              </IconButton>
              <IconButton
                aria-label="next"
                size="medium"
                disabled={autonomyAuditReportsStore.selectedRecord === null}
                onClick={() => handleFullScreenDialog()}
              >
                {autonomyAuditReportsStore.showFullScreenDialog ? <FullscreenExitRoundedIcon /> : <FullscreenRoundedIcon />}
              </IconButton>
            </Box>
          </Grid>
          {autonomyAuditReportsStore.selectedRecord !== null ? (
            <>
              <Grid container spacing={3} direction="row" justifyContent="center" alignContent="center">
                {components.map((component) => (
                  <Grid key={component.name} item xs={12} sm={6}>
                    <Paper elevation={3} className={classes.paper}>
                      <Typography align="left" variant="h5">
                        {component.name}
                      </Typography>
                      {autonomyAuditReportsStore?.selectedRecord?.hasNoData ? (
                        <Box mt={3} mb={3}>
                          <Grid className={classes.loading} container justifyContent="center" alignItems="center">
                            <Typography align="center" variant="h4">
                              No Data
                            </Typography>
                            <Typography align="center" variant="h6">
                              {component.name} could not be loaded for{' '}
                              <b>
                                {autonomyAuditReportsStore.selectedRecord.subsectionName
                                  ? autonomyAuditReportsStore.selectedRecord.subsectionName
                                  : DEFAULT_NO_DATA}
                              </b>
                              .
                            </Typography>
                          </Grid>
                        </Box>
                      ) : autonomyAuditReportsStore?.selectedRecord?.loading ? (
                        <Box mt={3} mb={3}>
                          <Grid className={classes.loading} container justifyContent="center" alignItems="center">
                            <CircularProgress color="secondary" />
                          </Grid>
                        </Box>
                      ) : (
                        component.component
                      )}
                    </Paper>
                  </Grid>
                ))}
              </Grid>
              <Grid container direction="row" justifyContent="space-between" alignItems="flex-end">
                <Grid item xs={8} sm>
                  <Box mt={3}>
                    <Typography variant="h5" component="h2">
                      {autonomyAuditReportsStore.selectedRecord.robotSerialNumber
                        ? autonomyAuditReportsStore.selectedRecord.robotSerialNumber
                        : DEFAULT_NO_DATA}
                      {bull}
                      {autonomyAuditReportsStore.selectedRecord.subsectionName
                        ? autonomyAuditReportsStore.selectedRecord.subsectionName
                        : DEFAULT_NO_DATA}
                    </Typography>
                    <Typography className={classes.title} gutterBottom>
                      <b>
                        {autonomyAuditReportsStore.selectedRecord.completedSuccessfully !== null
                          ? autonomyAuditReportsStore.selectedRecord.completedSuccessfully === true
                            ? 'Complete'
                            : 'Incomplete'
                          : DEFAULT_NO_DATA}
                      </b>
                      {bull}
                      {`${
                        autonomyAuditReportsStore.selectedRecord.averageDeviation
                          ? formatDeviation(autonomyAuditReportsStore.selectedRecord.averageDeviation)
                          : DEFAULT_NO_DATA
                      } avg`}
                      {bull}
                      {`${
                        autonomyAuditReportsStore.selectedRecord.maxDeviation
                          ? formatDeviation(autonomyAuditReportsStore.selectedRecord.maxDeviation)
                          : DEFAULT_NO_DATA
                      } max`}
                      {bull}
                      {autonomyAuditReportsStore.selectedRecord.timestamp
                        ? formatTimestamp(autonomyAuditReportsStore.selectedRecord.timestamp)
                        : DEFAULT_NO_DATA}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={4} sm container direction="row" justifyContent="flex-end">
                  <Tooltip title={<Typography>Details</Typography>}>
                    <span>
                      <IconButton aria-label="Details" onClick={handleDetailsButton}>
                        <InfoOutlinedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={<Typography>Download report</Typography>}>
                    <span>
                      <IconButton aria-label="download" size="medium" onClick={handlePrint}>
                        <GetAppRoundedIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12}>
              <Box mb={1}>
                <Typography align="center" variant="h3">
                  No Record Selected
                </Typography>
              </Box>
              <Box mb={4}>
                <Typography align="center" variant="h5">
                  Please select a record.
                </Typography>
              </Box>
            </Grid>
          )}
        </Paper>
      </Fade>
      <Dialog open={isDetailsDialogOpen} onClose={() => setIsDetailsDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogTitle onClose={() => setIsDetailsDialogOpen(false)}>Details</DialogTitle>
        <DialogContent>
          <AuditDetails />
        </DialogContent>
      </Dialog>
    </>
  );
});

export default AuditDataDisplay;
