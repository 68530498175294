import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Typography, Fade } from '@material-ui/core';
import { useStores } from '../store/root/root.store';
import SubsectionsTable from '../components/tables/SubsectionsTable';
import RobotsTable from '../components/tables/RobotsTable';
import ReloadIcon from '../components/reload/reload-icon';
import { ROUTE_REMOTE_GUARDIAN_REPEAT } from './routes';
import { AVAILABLE, IN_USE, OFFLINE, WPS_SUSPENDED, EXEC_SUSPENDED } from '../utils/constants';
import { SNOW_RG_PREFIX } from '../utils/localstorage.controller';
import ManageLocations from '../components/ManageLocations';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4)
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  }
}));

export const RemoteGuardianPage = observer(() => {
  const { countriesStore, regionsStore, chaperonePropertyStore, sectionStore, subsectionStore, autonomyRobotStore } = useStores();
  const classes = useStyles();
  const country = countriesStore.getSelectedCountry()?.id;
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const section = sectionStore.getSelectedSection()?.id;
  const useCase = localStorage.getItem('useCase');

  const sortRobots = () => {
    const sortByAvailability = (_robots) => [
      ..._robots.filter((r) => r.status === IN_USE),
      ..._robots.filter((r) => r.status === WPS_SUSPENDED),
      ..._robots.filter((r) => r.status === EXEC_SUSPENDED),
      ..._robots.filter((r) => r.status === AVAILABLE),
      ..._robots.filter((r) => r.status === OFFLINE)
    ];
    const filteredRobots = autonomyRobotStore.robots
      .filter((r) => (useCase ? r.use_case === useCase : true))
      .filter((r) => (property ? r.current_property_id === property : true))
      .filter((r) => (region ? r.current_region_id === region : true));
    const notFilteredRobots = autonomyRobotStore.robots.filter((r) => !filteredRobots.find((fR) => fR.id === r.id));
    autonomyRobotStore.setSortedRobots([...sortByAvailability(filteredRobots), ...sortByAvailability(notFilteredRobots)]);
  };

  useEffect(() => {
    const bgBox = document.querySelector('#bg-box');
    if (localStorage.getItem('useCase') === 'NON_SOLAR_LAWN_MOWING') {
      bgBox.classList.remove('bg-solar', 'bg-snow');
      bgBox.classList.add('bg-non-solar');
    } else if (localStorage.getItem('useCase') === 'SNOW_PLOWING') {
      bgBox.classList.remove('bg-solar', 'bg-non-solar');
      bgBox.classList.add('bg-snow');
    }
  }, []);

  const getRobots = async () => {
    const selectedCountry = country && country !== 'NONE' ? country : undefined;
    const selectedRegion = region && region !== 'NONE' ? region : undefined;
    const selectedProperty = property && property !== 'NONE' ? property : undefined;
    const selectedUseCase = useCase && useCase !== 'NONE' ? useCase : undefined;
    await autonomyRobotStore.getRobots(selectedCountry, selectedRegion, selectedProperty, selectedUseCase);
    sortRobots();
  };

  useEffect(() => {
    getRobots();
  }, [country, region, property]);

  const getSubsections = async () => {
    const selectedCountry = country && country !== 'NONE' ? country : undefined;
    const selectedProperty = property && property !== 'NONE' ? property : undefined;
    const selectedSection = section && section !== 'NONE' ? section : undefined;
    const selectedRegion = region && region !== 'NONE' ? region : undefined;
    await subsectionStore.getSubsections(selectedCountry, selectedRegion, selectedProperty, selectedSection);
  };

  useEffect(() => {
    getSubsections();
  }, [country, region, property, section]);

  return (
    <Fade in={true}>
      <Grid container justifyContent="flex-start" alignItems="flex-start">
        {/* locations */}
        <Grid item xs={3}>
          <ManageLocations pagePrefix={SNOW_RG_PREFIX} />
        </Grid>
        {/* Robots */}
        <Grid item xs={9}>
          <Grid item>
            <Typography variant="h3" className={classes.titleLable}>
              Manage Robots
            </Typography>
            <br />
          </Grid>
          <RobotsTable selectable showPlay realTimeUpdate />
        </Grid>
        {/* subsections */}
        <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h3" className={classes.titleLable}>
              Subsections
            </Typography>
            <ReloadIcon title="Reload subsections" handler={getSubsections} style={{ color: 'white', float: 'right' }} />
          </Grid>
          <SubsectionsTable repeatPath={ROUTE_REMOTE_GUARDIAN_REPEAT} />
        </Grid>
      </Grid>
    </Fade>
  );
});
