import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { Grid, makeStyles } from '@material-ui/core';
import { secondsToElapsedTime } from '../../utils/time.utils';

ChartJS.register(ArcElement, Tooltip, Legend);
const useStyles = makeStyles(() => ({
  colorTile: { width: '20px', height: '20px', marginRight: '5px' },
  chartPadding: { padding: '15px 30%' }
}));

export const colorForPieChartArray = [
  { borderColor: 'rgb(100, 100, 235)', backgroundColor: 'rgba(100, 100, 235, 0.5)' },
  { borderColor: 'rgba(139, 171, 128,1)', backgroundColor: 'rgba(139, 171, 128,0.5)' },
  { borderColor: 'rgba(128, 59, 71,1)', backgroundColor: 'rgba(128, 59, 71,0.5)' },
  { borderColor: 'rgba(160,160,160,1)', backgroundColor: 'rgba(160,160,160,0.5)' },
  { borderColor: 'rgba(102,102,102,1)', backgroundColor: 'rgba(102,102,102,0.5)' },
  { borderColor: 'rgba(181, 167, 127,1)', backgroundColor: 'rgba(181, 167, 127,0.5)' }
];
const options = {
  responsive: true,
  cutout: '30%',
  plugins: {
    legend: {
      display: false,
      labels: {
        title: { display: false },
        display: false
      },
      onClick(mouseEvent, legendItem, legend) {
        // toggle the visibility of the dataset from what it currently is
        // eslint-disable-next-line no-param-reassign
        legend.chart.getDatasetMeta(legendItem.datasetIndex).hidden = legend.chart.isDatasetVisible(legendItem.datasetIndex);
        legend.chart.update();
      }
    },
    tooltip: {
      callbacks: {
        label(context) {
          const labelIndex = context.datasetIndex * 2 + context.dataIndex;
          return `${context.chart.data.labels[labelIndex].split(' (')[0]}: ${context.formattedValue}% `;
        }
      }
    }
  }
};

const generateNumberedTitle = (value, type) => {
  if (type === 'distance') return `${Number.parseFloat(value.toFixed(2))}Km`;
  if (type === 'speed') return `${Number.parseFloat(value.toFixed(2))}Km/h`;
  if (type === 'time') return secondsToElapsedTime(value);
  return `${Number.parseFloat(value.toFixed(2))}`;
};

function OperationPieChart({ dataSets, useTypedTitle }) {
  let labels = dataSets.reduce((acc, set) => [...acc, ...set.labels], []);
  if (useTypedTitle) {
    const types = dataSets.reduce((acc, set) => [...acc, ...set.types], []);
    const numbers = dataSets.reduce((acc, set) => [...acc, ...set.numbers], []);
    labels = labels.map((label, index) => `${label} (${generateNumberedTitle(numbers[index], types[index])})`);
  }
  const classes = useStyles();

  const data = {
    labels,
    datasets: dataSets.map((ds) => ({ ...ds, borderColor: '#414042', spacing: 0 }))
  };
  return (
    <div>
      <Grid container justifyContent="space-around">
        {labels.map((label, ind) => (
          <Grid item key={label}>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item style={{ backgroundColor: colorForPieChartArray[ind].borderColor }} className={classes.colorTile} />
              <Grid item>{label}</Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <div className={classes.chartPadding}>
        <Doughnut options={options} data={data} />
      </div>
    </div>
  );
}

export default OperationPieChart;
