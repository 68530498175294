import { getDistance } from 'geolib';
import { getSubBlockSubrows, getSubrowMetaData } from '../services/api/subrows.service';
import { formatSubrows } from './format-solar-row';
import { alphaNumericOrder, isDevMode } from './ui.utils';
import { useStores } from '../store/root/root.store';

/** Utility file for Robot-Control-View and other Multi-robot component */

/** Calculates if current robot position is valid
 *  Returns false and sets error message if robot is not
 *  within the expected range
 * @param {String} serialNumber  Robot serial number
 * @param {Boolean} isDevMode Flag for Dev mode environment
 * @param {Number} robotCurrentHeadingRad current radiant
 * @param {Boolean} auotnomyStoppedDeviated Flag for deviation
 * @param {Number} subrowReferencePoint Refernce for current subrow in relation to robot position
 * @param {Function} setMessageAndConnectionError Function to set error and error message
 */
export const isValidRobotPosition = (
  { serialNumber, robotLat, robotLng, robotCurrentHeadingRad, auotnomyStoppedDeviated },
  subrowReferencePoint
) => {
  if (isDevMode) {
    return { isValidPosition: true, message: '' };
  }
  const distance = getDistance(
    { latitude: Number(subrowReferencePoint.lat), longitude: Number(subrowReferencePoint.lng) },
    { latitude: robotLat, longitude: robotLng },
    0.01
  );
  if (Math.fround(distance) > Math.fround(0.5) || auotnomyStoppedDeviated) {
    return {
      isValidPosition: false,
      message: `Can't start repeating this subrow, the robot ${serialNumber} is not close to the starting point.`
    };
  }
  let diffAngle = robotCurrentHeadingRad - subrowReferencePoint.angle;
  if (diffAngle > 3.14) {
    diffAngle -= 6.28;
  } else if (diffAngle < -3.14) {
    diffAngle += 6.28;
  }
  if (Math.abs(diffAngle) > 0.25) {
    return {
      isValidPosition: false,
      message: `Can't start repeating this subrow, the robot ${serialNumber} heading is not close to the initial recorded orientation.`
    };
  }
  return { isValidPosition: true, message: '' };
};

/** Checks robot readiness for autonomous paths before starting paths.
 *  Returns false and sets error message if robot is not autonomy ready
 *  @param {Object} isAutonomyReady
 *  @param {Function} pauseAutonomyOperation
 */
export const isRobotAutonomyReady = (
  {
    robotConnectionService,
    robotLat,
    robotLng,
    auotnomyStoppedDeviated,
    safetyEnabled,
    safetyErrorMessage,
    highLatency,
    latency,
    robotGpsFixStatus,
    robotStableLocalization,
    serialNumber,
    connectionError,
    hoseCoolingEnabled,
    staleTeleopsJoy
  },
  pauseAutonomyOperation
) => {
  if (robotConnectionService?.current && !robotConnectionService.current?.ros?.ros?.isConnected) {
    return { isAutonomyReady: false, statusMessage: `NO CONNECTION TO THE ROBOT ${serialNumber} NOW!` };
  }
  if (hoseCoolingEnabled) {
    return { isAutonomyReady: false, statusMessage: `COOLING MODE IS STILL RUNNING ON ROBOT ${serialNumber} NOW!` };
  }
  if (isDevMode) {
    return { isAutonomyReady: true, statusMessage: '' };
  }
  if (robotLat && robotLng) {
    if (auotnomyStoppedDeviated) {
      return {
        isAutonomyReady: false,
        statusMessage: `Robot ${serialNumber} is experiencing a high autonomy path deviation, please drive it manually back to the path!`
      };
    }
    if (!safetyEnabled) {
      return {
        isAutonomyReady: false,
        statusMessage: `${
          safetyErrorMessage.includes('motors')
            ? `Robot ${serialNumber}'s safety system is down because of [ ${safetyErrorMessage} ]. Please sw-estop then unestop to re-enable it!`
            : `Robot ${serialNumber}'s safety system is down because of [ ${safetyErrorMessage} ]`
        }`
      };
    }
    if (highLatency) {
      return {
        isAutonomyReady: false,
        statusMessage: `Robot ${serialNumber} is experiencing a high video streaming latency of ${(latency * 1000).toFixed(2)} ms`
      };
    }
    if (staleTeleopsJoy) {
      return {
        isAutonomyReady: false,
        statusMessage: `Robot ${serialNumber} is recieving stale Controller commands. Try re-connecting PS4 controller. Contact Autonomy if issue persists`
      };
    }
    if (!robotGpsFixStatus.includes('Fixed')) {
      return {
        isAutonomyReady: false,
        statusMessage: `RTK Error: Can't continue repeating this subrow autonomously, the robot ${serialNumber} does not have a Fixed RTK GPS data !`
      };
    }
    if (!robotStableLocalization) {
      return {
        isAutonomyReady: false,
        statusMessage: `INS Error, INS solution has not initialized. Try driving the robot ${serialNumber} forwards and back 10m and checking again`
      };
    }
  } else {
    return {
      isAutonomyReady: false,
      statusMessage: `GPS  Error, check the GPS and if the issue occurs again, try to reboot the robot ${serialNumber}!`
    };
  }
  if (
    connectionError ||
    !robotStableLocalization ||
    !robotGpsFixStatus.includes('Fixed') ||
    highLatency ||
    auotnomyStoppedDeviated ||
    !safetyEnabled
  ) {
    pauseAutonomyOperation();
    return { isAutonomyReady: false, statusMessage: 'Error Repeating' };
  }
  return { isAutonomyReady: true, message: '' };
};

/**
 * Allows toggling control mode. Robot control mode can be Manual, Autonomous, or Teleops.
 * @param {String} robotControl Control mode to be set
 * @param {String} robotControlMode Current robot control mode
 * @param {Class} robotConnection Robot connection instance
 */
export const switchControlMode = async (robotControl, robotControlMode, robotConnection) => {
  const mode = robotControl.toString();
  if (robotControlMode !== mode) {
    await robotConnection?.current?.ros.setControlMode(mode);
  }
};

/**
 * Fetches the sub-rows for the current/selected sub-block and
 * sets them as current sub-rows.
 * Function also formats sub-row names for better readability in subrows the options dropdown.
 * @param {Number} selectedSubBlockId Current sub-block ID
 * @param {String} selectedPathType   Curent path type
 * @param {Number} selectedRegionId   Current Region ID
 * @param {Number} selectedPropertyId Current property ID
 * @param {Number} selectedBlockId    Current Block ID
 * @param {Function} setSolarSubRows  State setter for current solar-subrows.
 */
export const fetchSubBlockSubRows = async (selectedSubBlockId, selectedPathType, selectedRegionId, selectedPropertyId, selectedBlockId) => {
  try {
    const results = await getSubBlockSubrows(selectedSubBlockId, selectedPathType);
    const solarSubRowsList = formatSubrows(results.results, selectedRegionId, selectedPropertyId, selectedBlockId, selectedSubBlockId)
      .map((row) => {
        const splittedRow = row.name.split('/').at(-1);
        const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
        return {
          ...row,
          name: splittedRowName,
          value: splittedRow
        };
      })
      .toSorted(alphaNumericOrder((r) => r.name));
    return solarSubRowsList;
  } catch (e) {
    console.error(`Error: ${e}`);
  }
};

export const verifySelectedSubrow = async (currentOperatingSubrow, blocksStore, subBlocksStore, chaperonePropertyStore, regionsStore) => {
  if (currentOperatingSubrow === -1) {
    return 'No subrow selected';
  }
  const subRowInfo = await getSubrowMetaData(currentOperatingSubrow);
  const pathType = subRowInfo.path_type;
  const subBlockId = subRowInfo.subblock_id;
  const subBlockName = subBlocksStore.getById(subBlockId).name;
  const blockId = subBlocksStore.getById(subBlockId).block_id;
  const blockName = blocksStore.getById(blockId).name;
  const propertyId = blocksStore.getById(blockId)?.property_id;
  const propertyName = chaperonePropertyStore.getById(propertyId)?.name;
  const regionId = chaperonePropertyStore.getById(propertyId)?.region_id;
  const regionName = regionsStore.getById(regionId)?.name;
  return `${regionName}/
  ${propertyName}/
  ${blockName}/
  ${subBlockName}/
  ${pathType}`;
};
