import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  labelledValue: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  value: {
    textOverflow: 'ellipsis',
  },
}));

export const LabelledValue = ({ label, value }) => {
  const classes = useStyles();
  return (
    <div className={classes.labelledValue}>
      <Typography component='span' variant='caption'>
        {label}
      </Typography>
      <Typography className={classes.value}>{value}</Typography>
    </div>
  );
};
