import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { makeStyles, TableCell, Typography } from '@material-ui/core';
import { Delete, Edit } from '@material-ui/icons';
import { useStores } from '../../store/root/root.store';
import ConfirmActionDialog from '../dialogs/confirm-action.dialog';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { AddRegion } from '../dialogs/add-region.dialog';
import { AddChaperoneProperty } from '../dialogs/add-chaperone-property.dialog';
import { AddSection } from '../dialogs/add-section.dialog';
import { AddBlock } from '../dialogs/add-block.dialog';
import { AddSubBlock } from '../dialogs/add-sub-block.dialog';

const useStyles = makeStyles((theme) => ({
  tableRow: {
    backgroundColor: '#ffffff00',
    color: 'black',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.secondary.dark
    }
  },
  icon: {
    backgroundColor: '#ffffff00',
    color: 'black',
    height: 20,
    width: 20,
    marginLeft: theme.spacing(1.25),
    cursor: 'pointer'
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  }
}));

export const LOCATION_REGION = 'region';
export const LOCATION_PROPERTY = 'property';
export const LOCATION_SECTION = 'section';
export const LOCATION_SUBSECTION = 'subsection';
export const LOCATION_BLOCK = 'block';
export const LOCATION_SUBBLOCK = 'subsubblock';
export const LOCATION_SUBROW = 'subrow';

const LocationsTable = observer(({ locationType }) => {
  const { countriesStore, regionsStore, chaperonePropertyStore, sectionStore, blocksStore, subBlocksStore, subsectionStore, subrowStore } =
    useStores();
  const classes = useStyles();
  const [showEditDialog, setShowEditDialog] = useState(false);
  const [locationToBeDeleted, setLocationToBeDeleted] = useState(0);
  const [showConfirmDeleteDialog, setShowConfirmDeleteDialog] = useState(false);

  let locations = [];
  if (locationType === LOCATION_REGION) {
    locations = regionsStore.regions;
  } else if (locationType === LOCATION_PROPERTY) {
    locations = chaperonePropertyStore.properties;
  } else if (locationType === LOCATION_BLOCK) {
    locations = blocksStore.blocks;
  } else if (locationType === LOCATION_SECTION) {
    locations = sectionStore.sections;
  } else if (locationType === LOCATION_SUBSECTION) {
    locations = subsectionStore.subsections;
  } else if (locationType === LOCATION_SUBROW) {
    locations = subrowStore.subrows;
  } else if (locationType === LOCATION_SUBBLOCK) {
    locations = subBlocksStore.subBlocks;
  }

  const handleDeleteLocation = async () => {
    if (locationType === LOCATION_REGION) {
      await regionsStore.deleteRegion({
        id: locationToBeDeleted
      });
      await regionsStore.getRegions(countriesStore.getSelectedCountry().id);
      chaperonePropertyStore.clearProperties();
      sectionStore.clearSections();
    } else if (locationType === LOCATION_PROPERTY) {
      await chaperonePropertyStore.deleteProperty({
        id: locationToBeDeleted
      });
      await chaperonePropertyStore.getProperties(regionsStore.selectedRegionId);
      sectionStore.clearSections();
    } else if (locationType === LOCATION_SECTION) {
      await sectionStore.deleteSection({ id: locationToBeDeleted });
      await sectionStore.getSections(regionsStore.selectedRegionId, chaperonePropertyStore.selectedPropertyId);
    } else if (locationType === LOCATION_BLOCK) {
      await blocksStore.deleteBlock({ id: locationToBeDeleted });
      await blocksStore.getBlocks(chaperonePropertyStore.selectedPropertyId);
    } else if (locationType === LOCATION_SUBBLOCK) {
      await subBlocksStore.deleteSubBlock({ id: locationToBeDeleted });
      await subBlocksStore.getSubBlocks(blocksStore.selectedBlockId);
    } else {
      await subsectionStore.deleteSubsection({
        id: locationToBeDeleted
      });
      await subsectionStore.getSubsections(
        regionsStore.selectedRegionId,
        chaperonePropertyStore.selectedPropertyId,
        sectionStore.selectedSectionId
      );
    }

    setShowConfirmDeleteDialog(false);
  };

  const openConfirmDeleteDialog = (locationInfo) => {
    setLocationToBeDeleted(locationInfo.id);
    setShowConfirmDeleteDialog(true);
  };

  const closeConfirmDeleteDialog = () => setShowConfirmDeleteDialog(false);

  const handleLocationSelection = (location) => {
    // clear ables after
    if (locationType === LOCATION_REGION && regionsStore.selectedRegionId !== location.id) {
      regionsStore.setSelectedRegion(location.id);
      chaperonePropertyStore.clearProperties();
      sectionStore.clearSections();
    } else if (locationType === LOCATION_PROPERTY && chaperonePropertyStore.selectedPropertyId !== location.id) {
      chaperonePropertyStore.setSelectedProperty(location.id);
      sectionStore.clearSections();
    } else if (locationType === LOCATION_SECTION && sectionStore.selectedSectionId !== location.id) {
      sectionStore.setSelectedSection(location.id);
    } else if (locationType === LOCATION_BLOCK && blocksStore.selectedBlockId !== location.id) {
      blocksStore.setSelectedBlock(location.id);
    } else if (locationType === LOCATION_SUBBLOCK) {
      subBlocksStore.setSelectedSubBlock(location.id);
    } else if (locationType === LOCATION_SUBSECTION) {
      subsectionStore.setSelectedSubsection(location.id);
    } else if (locationType === LOCATION_SUBROW) {
      subrowStore.setSelectedSubrow(location.id);
    }
  };

  const openEditLocationDialog = () => setShowEditDialog(true);

  const closeEditDialog = () => setShowEditDialog(false);

  const isSelected = (id) => {
    if (locationType === LOCATION_REGION) {
      return id === regionsStore.selectedRegionId;
    }
    if (locationType === LOCATION_PROPERTY) {
      return id === chaperonePropertyStore.selectedPropertyId;
    }
    if (locationType === LOCATION_SECTION) {
      return id === sectionStore.selectedSectionId;
    }
    if (locationType === LOCATION_BLOCK) {
      return id === blocksStore.selectedBlockId;
    }
    if (locationType === LOCATION_SUBBLOCK) {
      return id === subBlocksStore.selectedSubBlockId;
    }
    return false;
  };

  return locations.length === 0 ? (
    <Typography variant="h6" component="span" className={classes.label}>
      No Locations Found
    </Typography>
  ) : (
    <>
      <StripedTable headers={['Actions', 'Name']}>
        {locations.map((location) => (
          <StripedTableRow
            key={location.id}
            selectable
            hover
            isSelected={isSelected(location.id)}
            className={classes.tableRow}
            onClick={() => handleLocationSelection(location)}
          >
            <TableCell align="left">
              <Edit onClick={() => openEditLocationDialog(location.id)} className={classes.icon} />
              <Delete className={classes.icon} onClick={() => openConfirmDeleteDialog(location)} />
            </TableCell>
            <TableCell align="left">{location.name}</TableCell>
          </StripedTableRow>
        ))}
      </StripedTable>
      {showEditDialog && locationType === LOCATION_REGION && (
        <AddRegion
          show={showEditDialog}
          view="edit"
          countryId={countriesStore.selectedCountryId}
          regionId={regionsStore.selectedRegionId}
          onClose={closeEditDialog}
        />
      )}
      {showEditDialog && locationType === LOCATION_PROPERTY && (
        <AddChaperoneProperty
          show={showEditDialog}
          view="edit"
          propertyId={chaperonePropertyStore.selectedPropertyId}
          onClose={closeEditDialog}
        />
      )}
      {showEditDialog && locationType === LOCATION_SECTION && (
        <AddSection show={showEditDialog} view="edit" sectionId={sectionStore.selectedSectionId} onClose={closeEditDialog} />
      )}
      {showEditDialog && locationType === LOCATION_BLOCK && (
        <AddBlock show={showEditDialog} view="edit" blockId={blocksStore.selectedBlockId} onClose={closeEditDialog} />
      )}
      {showEditDialog && locationType === LOCATION_SUBBLOCK && (
        <AddSubBlock show={showEditDialog} view="edit" subBlockId={subBlocksStore.selectedSubBlockId} onClose={closeEditDialog} />
      )}

      <ConfirmActionDialog
        handleAction={handleDeleteLocation}
        handleClose={closeConfirmDeleteDialog}
        title="Delete Location"
        body="Are you sure you want to delete this location?"
        open={showConfirmDeleteDialog}
      />
    </>
  );
});

export default LocationsTable;
