import { FormControl, Grid, makeStyles, MenuItem, Select, Typography, Fade } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from '../store/root/root.store';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    color: theme.palette.inverted.main
  },
  TextField: {
    backgroundColor: 'white',
    height: 45,
    width: 240
  },
  formControlTextField: {
    paddingLeft: theme.spacing(18.5),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 300
  },
  controls: {
    marginBottom: theme.spacing(1)
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  marker: {
    margin: theme.spacing(1)
  },
  Button: {
    margin: theme.spacing(2)
  },

  Slider: {
    margin: theme.spacing(2),
    color: '#FFFFFF',
    width: 200
  },
  tableActions: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export const VisualizePage = () => {
  const { robotStore, podStore } = useStores();
  const classes = useStyles();
  const [pod, setPod] = useState('');
  // const [firstSliderValue, setFirstSliderValue] = React.useState(30);
  // const [secondSliderValue, setSecondSliderValue] = React.useState(30);

  useEffect(() => {
    robotStore.getAllRobots();
    podStore.getPods();
  }, [robotStore, podStore]);

  // const changeFirstSliderValue= (event, newValue) => {
  //   setFirstSliderValue(newValue);
  // };

  // const changeSecondSliderValue = (event, newValue) => {
  //   setSecondSliderValue(newValue);
  // };

  return useObserver(() => {
    return (
      <Fade in={true}>
        <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography variant="h6" component="span" className={classes.label}>
              View Type:
            </Typography>

            <FormControl className={classes.formControl}>
              <Select
                value={pod}
                className={classes.select}
                onChange={(e) => {
                  setPod(e.target.value);
                }}
              >
                <MenuItem value="" disabled>
                  <em>Map Path </em>
                </MenuItem>
                <MenuItem value="" disabled>
                  <em>No Map Path </em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Fade>
    );
  });
};
