import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import { ControlledSelectField } from '../inputs/ControlledSelectField';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  selectButton: {
    height: '100%',
    width: '100%',
    backgroundColor: '##0000FF'
  },
  helperText: {
    color: 'red'
  }
}));

const RouteRobotFilter = observer(() => {
  const { autonomyRobotStore } = useStores();
  const classes = useStyles();
  const [fieldUsed, setFieldUsed] = useState({});
  const robot = autonomyRobotStore.getSelectedRobot()?.id;

  const updateUsedField = (fieldName) => {
    setFieldUsed({ ...fieldUsed, [fieldName]: true });
  };
  const getRobots = async () => {
    await autonomyRobotStore.getRobots(undefined, undefined, undefined, undefined);
  };

  useEffect(() => {
    getRobots();
  }, []);

  const formattedRobotOptions = autonomyRobotStore.robots?.map(({ id, serial_number: serialNumber, name }) => ({
    id,
    name: `${name} - ${serialNumber}`
  }));

  return (
    <>
      <Grid item xs={4}>
        <Typography variant="h3" className={classes.titleLable}>
          Route Following
        </Typography>
      </Grid>
      <ControlledSelectField
        label="Robots"
        id="robot"
        value={robot || 0}
        handleSelectOption={(e) => {
          if (typeof e.target.value === 'number') {
            autonomyRobotStore.setSelectedRobot(e.target.value);
            updateUsedField('robot');
          }
        }}
        options={formattedRobotOptions}
        classes={classes}
        formError={fieldUsed.robot}
        required
        includeNone
      />
    </>
  );
});

export default RouteRobotFilter;
