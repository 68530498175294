/* eslint-disable no-unused-expressions */
import { Button, Grid, makeStyles, TableCell } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleFilled';
import axios from 'axios';
import Replay from '@material-ui/icons/Replay';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import SettingsIcon from '@material-ui/icons/Settings';
import { ChaperoneRobotConnectionService, LINEAR_SPEED, ANGULAR_SPEED, LOOK_AHEAD } from '../services/chaperone/robot-connection.service';
import Map from '../components/maps/chaperone-map';
import RouteMap from '../components/gridMaps/RouteMap';
import { StripedTableRow } from '../components/core/striped-table-row.component';
import { StripedTable } from '../components/core/striped-table.component';
import { useStores } from '../store/root/root.store';
import { ROUTE_ROUTE_SELECTION } from './routes';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import { CustomSlider } from '../components/core/custom-slider.component';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import ActionsDialog from '../components/dialogs/actions.dialog';
import RepeatSettingsDialog from '../components/dialogs/repeat-settings.dialog';
import RestartRobotDialog from '../components/dialogs/actions.dialog';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { getRouteCsvData } from '../services/api/routes.service';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    color: theme.palette.inverted.main
  },
  PlayCircleOutlineIcon: {
    color: 'white',
    height: 55,
    width: 55
  },
  formControlTextField: {
    paddingLeft: theme.spacing(18.5),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  cancelButton: {
    margin: theme.spacing(2),
    height: 50,
    width: 160,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    marginTop: theme.spacing(1),
    minWidth: 200
  },
  Slider: {
    margin: theme.spacing(1),
    color: '#0178FF',
    width: 120
  },
  sliderLabel: {
    color: '#fff',
    padding: '10px',
    backgroundColor: '#4472c4',
    borderRadius: '5px',
    marginLeft: '7px'
  },
  toggle: {
    margin: theme.spacing(1),
    backgroundColor: 'white',
    Width: 30,
    height: 50
  },
  toggleGroup: {
    Width: '100%',
    height: '100%'
  },
  toggleLabel: {
    color: 'Black',
    Width: 30
  }
}));

const RobotDataIntervalTime = 400; // in milliseconds

export const RouteRepeatPage = observer(() => {
  const classes = useStyles();
  const { push } = useHistory();
  const [start, setStart] = useState('true');
  const [pause, setPause] = useState(false);

  const [googleMap, setGoogleMap] = useState(false);
  const [gridMap, setGridMap] = useState(true);
  const [resume, setResume] = useState(false);
  const [resetRobot, setResetRobot] = useState(false);
  const { applicationStore, controlStore, autonomyRobotStore } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const keepSelectedRobot = useRef(false);
  const [linearSpeed, setLinearSpeed] = useState(LINEAR_SPEED.default);
  const [lookAheadDistance, setLookAheadDistance] = useState(LOOK_AHEAD.default);
  const [angularSpeed, setAngularSpeed] = useState(ANGULAR_SPEED.default);
  const [showSettingsDialog, setShowSettingsDialog] = useState(false);
  const [cords, setCords] = useState([]);
  const { state } = useLocation();
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [width, setWidth] = useState(window.innerWidth);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openConfirmRestartRobotDialog, setOpenConfirmRestartRobotDialog] = useState(false);
  const [toBeSelectedControlMode, setToBeSelectedControlMode] = useState('');
  const [userRobot, setUserRobot] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [gridMapData, setGridMapData] = useState([]);
  const username = localStorage.getItem('username');
  const isMountedRef = useRef(null);

  const handleConnectionError = () => {
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  const sendRepeatCmdToRobot = async (action) => {
    setUserRobot(true);
    if (state.route) {
      const command = 'REPEAT';
      robotConnection?.current?.ros?.cmdRobotService(
        command,
        [`${action},${state.route.full_name},${localStorage.getItem('username')}`],
        (result) => {
          setLoading(false);
          if (result.error_message === '') return;
          console.log(`There was the following error with the repeat action: ${result.error_message}`);
          applicationStore.pushError(
            'Error',
            'The robot encountered an error with this repeat action, please report this to the autonomy team if the issue persists'
          );
        }
      );
    }
  };

  const changeLinearSpeed = (event, newValue) => {
    setLinearSpeed(newValue);
  };

  useEffect(() => {
    if (autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED') {
      keepSelectedRobot.current = true;
    }
  }, [autonomyRobotStore]);

  const showLoadingDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const startLoadingMsg = () => {
    showLoadingDialog('We are provisioning the robot now, it will begin the path in a few seconds.');
  };

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    };
  }, []);

  const handleTabClosing = () => {
    if (!start) {
      sendRepeatCmdToRobot('CANCEL');
    }
  };

  const alertUser = async (ev) => {
    if (!start) {
      ev.preventDefault();
      ev.returnValue = 'Are you sure you want to close?';
      const exit = window.confirm('Are you sure you want to close?');
      console.log(exit);
    }
    return 'Are you sure you want to close?';
  };

  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    window.addEventListener('unload', handleTabClosing);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
      window.removeEventListener('unload', handleTabClosing);
    };
  });

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  const currentParams = () => `${linearSpeed},${lookAheadDistance},${angularSpeed},0`;

  const isValidPosition = () => true;

  const switchToManualControl = () => {
    if (controlStore.getControlMode() !== 'manual') robotConnection?.current?.ros.setControlMode('manual');
  };

  const switchToAutonomousControl = () => {
    if (controlStore.getControlMode() !== 'autonomous') robotConnection?.current?.ros.setControlMode('autonomous');
  };

  const changeRepeatingState = () => {
    if (resume) {
      setPause(!pause);
      setResume(!resume);
      switchToAutonomousControl();
      sendRepeatCmdToRobot('RESUME');
    } else if (pause) {
      setPause(!pause);
      setResume(!resume);
      switchToManualControl();
      sendRepeatCmdToRobot('PAUSE');
    } else if (start) {
      if (controlStore.lat && controlStore.lng) {
        if (!controlStore.gpsFixStatus.includes('RTK')) {
          setConnectionError(true);
          setErrorMessage('GPS Error is found, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
          return;
        }
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        setConnectionError(true);
        setErrorMessage('GPS Error is found, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }

      if (!isValidPosition()) {
        return;
      }
      setStart(!start);
      setPause(!pause);
      if (controlStore.getControlMode() !== 'autonomous') {
        switchToAutonomousControl();
        setResetRobot(true);
      }
      controlStore.resetRobotPosition();
      setResetRobot(true);
      const paramsStr = currentParams();

      axios
        .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/robots/${autonomyRobotStore.selectedRobotId}/status`)
        .then((res) => {
          if (res.data.results.status === 'AVAILABLE' || userRobot) {
            sendRepeatCmdToRobot('START');
            startLoadingMsg();
            robotConnection?.current?.ros.updateNavParams(paramsStr);
          } else {
            setConnectionError(true);
            setErrorMessage('Robot is already in-use, check it again later or select another robot!');
          }
        })
        .catch((err) => {
          console.log(err);
          setConnectionError(true);
          setErrorMessage('Robot is already in-use, check it again later or select another robot!');
        });
    }
  };

  const restartRobot = () => {
    setOpenConfirmRestartRobotDialog(true);
  };

  const restart = () => {
    robotConnection?.current?.ros?.restartRobotService('robot');
    setOpenConfirmRestartRobotDialog(false);
  };

  const changeDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (!start) {
        if (controlStore.getControlMode() !== 'autonomous' && resume) {
          setPause(!pause);
          setResume(!resume);
          switchToAutonomousControl();
          sendRepeatCmdToRobot('RESUME');
        } else if (controlStore.getControlMode() === 'autonomous' && pause) {
          setPause(!pause);
          setResume(!resume);
          switchToManualControl();
          sendRepeatCmdToRobot('PAUSE');
        }
      }
    }
  };

  const confirmChangeDriveState = () => {
    changeDriveState({}, toBeSelectedControlMode);
    setOpenWarningDialog(false);
  };

  const toggleDriveState = (_, toggleManualControl) => {
    if (toggleManualControl !== null) {
      if (controlStore.wpsState === 0) {
        console.log('discarded');
      } else if (controlStore.wpsState === 5 && toggleManualControl === false) {
        setOpenWarningDialog(true);
        setToBeSelectedControlMode(toggleManualControl);
      } else {
        changeDriveState({}, toggleManualControl);
      }
    }
  };

  const cancelRepeatingTask = async (done = false) => {
    if (!start) {
      setStart(!start);
      if (resume) {
        setResume(!resume);
      } else if (pause) {
        setPause(!pause);
      }
      switchToManualControl();
      if (!done) {
        await sendRepeatCmdToRobot('CANCEL');
      } else {
        await sendRepeatCmdToRobot('FINISH');
      }
    }
    if (!done) {
      controlStore.resetStore();
      push(ROUTE_ROUTE_SELECTION);
    } else {
      controlStore.resetDoneRepeating();
    }
  };
  function valuetext(value) {
    return `${value}°C`;
  }

  const monitorRobotData = useCallback(async () => {
    if (robotConnectionService.current !== null) {
      // Check for change in other Waypoints state
      if (Number(controlStore.wpsState) === 4) {
        setStart(false);
        setPause(true);
      } else if (!start && controlStore.isRepeatingDone) {
        // Repeating is Done
        console.log('WAYPOINTS REPEATING IS DONE');
        cancelRepeatingTask(true);
      }
    }
  }, [cancelRepeatingTask, robotConnectionService, start, setStart]);

  useEffect(() => {
    const paramsStr = currentParams();
    robotConnection?.current?.ros.updateNavParams(paramsStr);
  }, [linearSpeed, lookAheadDistance, angularSpeed]);

  useEffect(() => {
    const getRouteCoords = async () => {
      const csvData = await getRouteCsvData(state.route.full_name);
      const routeArray = csvData.data;
      console.log(routeArray);
      setGridMapData(routeArray);
      const newCords = routeArray.map((coordinates) => ({ lat: Number(coordinates.lat), lng: Number(coordinates.lng) }));
      setCords(newCords);
    };
    getRouteCoords();
    return () => {
      controlStore.resetRobotPosition();
      controlStore.resetStore();
    };
  }, []);

  useEffect(() => {
    if (state) {
      isMountedRef.current = true;
      robotConnectionService.current = new ChaperoneRobotConnectionService(
        () => {
          // onConnected
          robotConnectionService?.current?.ros.subscribeToRobotStateStamped((robotState) => controlStore.updateRobotState(robotState));
          robotConnectionService?.current?.ros.subscribeToDoneRepeatingMission(() => controlStore.setDoneRepeating());
          robotConnection.current = robotConnectionService.current;
        },
        () => {
          // onDisconnect
          console.log('Lost connection to robot');
          if (robotConnectionService.current !== null) {
            sendRepeatCmdToRobot('CANCEL');
          }
          handleConnectionError();
          robotConnection.current = null;
        },
        autonomyRobotStore.getSelectedRobot().serial_number,
        username,
        'route_repeat'
      );
      robotConnectionService?.current?.connectToRobot(handleConnectionError);
    }
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        sendRepeatCmdToRobot('CANCEL');
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
        const selectedRobotIdAtCleanup = autonomyRobotStore.selectedRobotId;
      }
      if (!keepSelectedRobot.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
    };
  }, []);

  useEffect(() => {
    // Set interval timer for checking Joystick state
    const timer = setInterval(monitorRobotData, RobotDataIntervalTime);
    clearInterval(timer);
  }, [monitorRobotData]);

  window.onload = () => {
    push(ROUTE_ROUTE_SELECTION);
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
    if (positionError) {
      setPositionError(false);
      return;
    }
    push(ROUTE_ROUTE_SELECTION);
  };

  const closeSettingDialog = () => {
    setShowSettingsDialog(false);
  };

  return (
    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
      <ActionsDialog
        dialogTitle="Robot is not autonomous now, do you want it in autonomous mode?"
        open={openWarningDialog}
        actions={[
          { color: 'primary', name: 'Cancel', variant: 'outlined', handler: () => setOpenWarningDialog(false) },
          { color: 'secondary', name: 'Confirm', variant: 'contained', handler: confirmChangeDriveState }
        ]}
      />
      {showSettingsDialog && (
        <RepeatSettingsDialog
          isChaperoneRepeat
          manager={false}
          handleClose={closeSettingDialog}
          currentLinearSpeed={linearSpeed}
          setLinearSpeed={setLinearSpeed}
          currentLookAheadDistance={lookAheadDistance}
          setLookAheadDistance={setLookAheadDistance}
          currentAngularSpeed={angularSpeed}
          setAngularSpeed={setAngularSpeed}
        />
      )}
      <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
      <Grid item>
        <StripedTable headers={['Route Name']} align="center">
          <StripedTableRow>
            <TableCell align="center">{state.route.name}</TableCell>
          </StripedTableRow>
        </StripedTable>
      </Grid>
      <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
        <SettingsIcon
          className={classes.PlayCircleOutlineIcon}
          color="action"
          onClick={() => {
            setShowSettingsDialog(true);
          }}
        />
        <PlayCircleOutlineIcon
          className={classes.PlayCircleOutlineIcon}
          color="action"
          style={{
            display: start || resume ? 'inline' : 'none'
          }}
          onClick={changeRepeatingState}
        />
        <PauseCircleOutlineIcon
          className={classes.PlayCircleOutlineIcon}
          color="action"
          style={{
            display: !start && !resume && pause ? 'inline' : 'none'
          }}
          onClick={changeRepeatingState}
        />
        <Replay
          className={classes.PlayCircleOutlineIcon}
          color="action"
          style={{
            display: 'inline'
          }}
          onClick={restartRobot}
        />
        <Button onClick={() => cancelRepeatingTask(false)} className={classes.cancelButton} variant="contained">
          Cancel Route
        </Button>
        <EStopIndicator
          eStopEngaged={controlStore.estopState || controlStore.swEstopState}
          videoStream={false}
          width={65}
          height={65}
          marginLeft={0}
          marginTop={10}
          marginRight={5}
          marginBottom={0}
        />
        <div className={classes.toggle}>
          <ToggleButtonGroup
            className={classes.toggleGroup}
            exclusive
            value={controlStore.getControlMode() !== 'autonomous'}
            onChange={toggleDriveState}
            aria-label="text formatting"
          >
            <ToggleButton color="primary" value aria-label="bold">
              Manual
            </ToggleButton>
            <ToggleButton color="secondary" value={false} aria-label="italic">
              Autonomous
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </Grid>

      <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
        {width <= 768 ? (
          <Typography id="input-slider" className={classes.label} style={{ minWidth: 300 }}>
            Maximum Linear Speed Limit (Km/hr):
          </Typography>
        ) : (
          <Typography id="input-slider" className={classes.label}>
            Maximum Linear Speed Limit (Km/hr):
          </Typography>
        )}
        <CustomSlider
          onMinusClick={() => {
            linearSpeed > LINEAR_SPEED.min && changeLinearSpeed({}, parseFloat((linearSpeed - LINEAR_SPEED.step).toFixed(2)));
          }}
          onAddClick={() => {
            linearSpeed < LINEAR_SPEED.max && changeLinearSpeed({}, parseFloat((linearSpeed + LINEAR_SPEED.step).toFixed(2)));
          }}
        >
          <Slider
            className={classes.Slider}
            defaultValue={LINEAR_SPEED.default}
            value={linearSpeed}
            onChange={changeLinearSpeed}
            getAriaValueText={valuetext}
            aria-labelledby="continuous-slider"
            min={LINEAR_SPEED.min}
            max={LINEAR_SPEED.max}
            step={LINEAR_SPEED.step}
            marks
          />
        </CustomSlider>
        <div className={classes.sliderLabel}>{linearSpeed}</div>
      </Grid>

      <FormControlLabel
        style={{ color: 'white' }}
        checked
        control={<Checkbox style={{ color: 'white' }} checked={gridMap} />}
        onChange={(event) => {
          setGridMap(event.target.checked);
        }}
        label="Grid Map"
      />

      <FormControlLabel
        style={{ color: 'white' }}
        control={(
          <Checkbox
            style={{ color: 'white' }}
            checked={googleMap}
            onChange={(event) => {
              setGoogleMap(event.target.checked);
            }}
          />
        )}
        label="Google Map"
      />
      <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
      {googleMap && <Map robotLat={controlStore.lat} resetRobot={resetRobot} googleMapData={cords} robotLng={controlStore.lng} />}
      {gridMap && (
        <RouteMap
          gridMapData={gridMapData}
          robotEnabled
          resetRobot={resetRobot}
          robotLng={controlStore.lng}
          robotLat={controlStore.lat}
          robotHeadingRad={controlStore.current_heading_rad}
        />
      )}
      <RestartRobotDialog
        actions={[
          { name: 'Restart', color: '#EA2840', textColor: 'white', handler: restart },
          { name: 'Cancel', handler: () => setOpenConfirmRestartRobotDialog(false) }
        ]}
        dialogTitle="Are you sure you want to restart the robot?"
        open={openConfirmRestartRobotDialog}
      />
    </Grid>
  );
});
