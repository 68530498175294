/* eslint-disable no-await-in-loop */
import { HttpService, globalAxios } from '../http.service';
import { guardedClient, extendedTimeoutClient } from '../../utils/axios-instance';

export class AuditReportsService extends HttpService {
  resourceUrl = '/auditimage';

  imageUrl = `${this.resourceUrl}/image`;

  env = process.env.REACT_APP_ENV;

  async fetchReports(queryString = '', { isVideoFeed = false, robotList = [] } = {}) {
    const isVideoFeedQueryParam = isVideoFeed ? '&isVideoFeed=true' : '';
    return this.get(`${this.resourceUrl}?${queryString}${isVideoFeedQueryParam}`, {}, { robotList, env: this.env });
  }

  async fetchReportsParallel(startDate, endDate, queryString = '', { isVideoFeed = false, robotList = [] } = {}) {
    const promises = [];
    const date = new Date(startDate);
    date.setUTCHours(0, 0, 0, 0);
    const rl = robotList.join(',');
    while (date <= endDate) {
      const newEndDate = new Date(date);
      newEndDate.setUTCHours(23, 59, 59, 999);
      promises.push(this.fetchAllDayReports(date.toISOString(), newEndDate.toISOString(), queryString, { isVideoFeed, robotList: rl }));
      date.setDate(date.getDate() + 1);
    }
    const data = await Promise.all(promises);
    const results = [];
    data.forEach((d) => {
      results.push(...d.results);
    });
    return { results };
  }

  async fetchAllDayReports(startDate, endDate, queryString = '', { isVideoFeed = false, robotList = [] } = {}) {
    const isVideoFeedQueryParam = isVideoFeed ? '&isVideoFeed=true' : '';
    const results = [];
    let hasMoreResults = true;
    let lastResult = null;
    while (hasMoreResults) {
      let data = null;
      if (lastResult) {
        data = await this.get(
          `${this.resourceUrl}?${queryString}${isVideoFeedQueryParam}`,
          {},
          { robotList, env: this.env, start: startDate, end: endDate, _lastResult: lastResult }
        );
      } else {
        data = await this.get(
          `${this.resourceUrl}?${queryString}${isVideoFeedQueryParam}`,
          {},
          { robotList, env: this.env, start: startDate, end: endDate }
        );
      }
      results.push(...data.results);
      if (data.hasMoreResults) {
        hasMoreResults = true;
        ({ lastResult } = data);
      } else {
        hasMoreResults = false;
      }
    }
    return { results };
  }

  async fetchReportMedia(filename, isVideoFeed) {
    const headers = await this.getHeaders();
    const envParam = `env=${this.env}`;
    // const envParam = 'env=dev';
    const isVideoFeedQueryParam = isVideoFeed ? `?isVideoFeed=true&${envParam}` : `?${envParam}`;
    return await globalAxios.get(`${this.imageUrl}/${filename}${isVideoFeedQueryParam}`, { headers });
  }
}

export const fetchOpsAudit = async (params) => {
  const { formattedStarDate, formattedEndDate, country, property, region, robotList } = params;
  const result = extendedTimeoutClient.get('/audits/ops-audit', {
    params: {
      formattedStarDate,
      formattedEndDate,
      country,
      region,
      property,
      robotList
    }
  });
  return result;
};

/**
 * Fetches front & rear camera audit images
 * @param {String} frontImage File name for forward camera image
 * @param {String} rearImage File name for rear camera image
 * @returns
 */
export const fetchOpsAuditImages = async (frontImage, rearImage) => {
  try {
    const result = guardedClient.get('/audits/audit-images', {
      params: {
        frontImageKey: frontImage,
        rearImageKey: rearImage
      }
    });
    return result;
  } catch (e) {
    console.error(`Failed to fetch audit images: ${e}`);
    return null;
  }
};
