import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Checkbox, Grid, makeStyles, TableCell, Typography, Box } from '@material-ui/core';
import { StripedTable } from '../core/striped-table.component';
import { StripedTableRow } from '../core/striped-table-row.component';
import { COMPLETED, SUBROW_STATUS_MAP, SUBROW_RELATION_COLUMNS_MAP } from '../../utils/constants';
import { useStores } from '../../store/root/root.store';
import { updateSolarSubRow, updateSubrowAllPaths } from '../../services/api/subrows.service';

const useStyles = makeStyles((theme) => ({
  solarTable: {
    width: '40%'
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 290,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(2)
  },
  checkbox: {
    color: '#000000',
    backgroundColor: '#858585',
    '&:hover': {
      color: '#656564',
      backgroundColor: 'white'
    },
    marginLeft: '-45px'
  }
}));

/**
 * SolarRowsTable - Table for subrows in a sub-block
 *
 * This component is responsible for listing subrows in a sub-block in tabular format.
 * Component is also resposible for managing component state and subrows related API calls
 *
 * @param {Number}  tableSize - component size control for grid layout
 * @param {Boolean} isRepeatPage - true indicates table is being rendered in repeat pages
 * @param {Number} selectedSubBlock - selected sub-block
 * @param {String} selectedPathType - selected path type
 * @param {Boolean} isSolarRgPage - true indicates table is being rendered in solar remote page
 */
const SolarRowsTable = observer(({ tableSize, isRepeatPage, selectedSubBlock, selectedPathType, isSolarRgPage }) => {
  const { subrowStore, subBlocksStore, regionsStore, chaperonePropertyStore } = useStores();
  const headers = [
    { name: 'Name', field: 'name' },
    { name: 'Robotic Cut', field: 'status' },
    { name: 'Cleaned Up', field: 'is_cleaned_up' },
    { name: 'No-Go', field: 'is_restricted' },
    { name: 'Keep Out', field: 'is_keep_out_zone' }
  ];
  const classes = useStyles();
  const tableWidth = typeof tableSize !== 'number' ? 5 : tableSize;
  const [markAllRowsNoGo, setMarkAllRowsNoGo] = useState(false);
  const [markAllRowsCompleted, setMarkAllRowsCompleted] = useState(false);
  const [markAllRowsKeepOutZone, setMarkAllRowsKeepOutZone] = useState(false);
  const [markAllRowsCleanedUp, setMarkAllRowsCleanedUp] = useState(false);
  const subBlock = isSolarRgPage ? subBlocksStore.getSelectedSubBlock()?.id : selectedSubBlock;
  const subBlockIsCompleted = subrowStore.isSubBlockCompleted();
  const subBlockIsRestricted = subrowStore.isSubBlockRestricted();
  const region = regionsStore.getSelectedRegion()?.id;
  const property = chaperonePropertyStore.getSelectedProperty()?.id;
  const currentPathType = isSolarRgPage ? subrowStore.currentPathType : selectedPathType;
  const isSolarRowTable = true;

  const getSubrows = async () => {
    if (region && property && subBlock && currentPathType) {
      await subrowStore.getSubrows(region, property, subBlock, currentPathType);
    }
  };
  useEffect(() => {
    getSubrows();
  }, [subBlock, currentPathType]);

  /**
   * Updates subrow and all its paths variants with new column data
   * @param {*} id subrow id
   * @param {*} field Column to be updated on subrows relation
   * @param {*} event dom events for mouse actions
   */
  const updateSolarRowStatus = async (id, field, event, subrowName) => {
    const [orderIndex, direction] = subrowName ? subrowName.split('_') : '';
    try {
      if (field === SUBROW_RELATION_COLUMNS_MAP.STATUS) {
        const status = event.target.checked ? 'COMPLETED' : 'NOT_STARTED';
        await updateSubrowAllPaths(subBlock, direction, orderIndex, field, status);
        await getSubrows();
      } else {
        const data = !!event.target.checked;
        await updateSubrowAllPaths(subBlock, direction, orderIndex, field, data);
        await getSubrows();
      }
    } catch (e) {
      console.error(e);
    }
  };

  const updateSubrow = async (event, field) => {
    const value = field === 'status' ? SUBROW_STATUS_MAP[event.target.checked] : event.target.checked;
    await updateSolarSubRow(subBlock, field, value);
    if (field === SUBROW_RELATION_COLUMNS_MAP.STATUS) {
      setMarkAllRowsCompleted(value === 'COMPLETED');
    } else if (field === SUBROW_RELATION_COLUMNS_MAP.IS_NO_GO) {
      setMarkAllRowsNoGo(value);
    } else if (field === SUBROW_RELATION_COLUMNS_MAP.KEEP_OUT) {
      setMarkAllRowsKeepOutZone(value);
    } else {
      setMarkAllRowsCleanedUp(value);
    }
    getSubrows();
  };

  useEffect(() => {
    setMarkAllRowsCompleted(subBlockIsCompleted);
    setMarkAllRowsNoGo(subBlockIsRestricted);
  }, [subBlock, currentPathType, subBlockIsCompleted, subBlockIsRestricted]);

  return (
    <Grid item xs={12} md={tableWidth}>
      {!isRepeatPage && (
        <Box display="flex" justifyContent="space-between" alignContent="center" alignItems="center">
          <Typography variant="h4" className={classes.titleLable}>
            Solar Sub-Rows:
          </Typography>
        </Box>
      )}
      <StripedTable
        headers={headers}
        scrollable
        updateSubrow={(event, field) => updateSubrow(event, field)}
        markAllRowsNoGo={markAllRowsNoGo}
        markAllRowsCompleted={markAllRowsCompleted}
        markAllRowsKeepOutZone={markAllRowsKeepOutZone}
        markAllRowsCleanedUp={markAllRowsCleanedUp}
        isSolarRowTable={isSolarRowTable}
      >
        {subrowStore.subrows.map((solarRow) => {
          const splittedRow = solarRow.name.split('/').at(-1);
          const splittedRowName = splittedRow.substring(0, splittedRow.indexOf('__'));
          return (
            <StripedTableRow key={solarRow.id} hover>
              <TableCell align="left">{splittedRowName}</TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={solarRow.status === COMPLETED}
                  name="roboticallyCut"
                  onChange={(event) => {
                    const field = SUBROW_RELATION_COLUMNS_MAP.STATUS;
                    updateSolarRowStatus(solarRow.id, field, event, splittedRowName);
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  checked={solarRow.is_cleaned_up === true}
                  name="cleanedUp"
                  onChange={(event) => {
                    const field = SUBROW_RELATION_COLUMNS_MAP.CLEANED_UP;
                    updateSolarRowStatus(solarRow.id, field, event, splittedRowName);
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  name="isNoGo"
                  checked={solarRow.is_restricted === true}
                  onChange={(event) => {
                    const field = SUBROW_RELATION_COLUMNS_MAP.IS_NO_GO;
                    updateSolarRowStatus(solarRow.id, field, event, splittedRowName);
                  }}
                />
              </TableCell>
              <TableCell align="left">
                <Checkbox
                  name="keepOut"
                  checked={solarRow.is_keep_out_zone === true}
                  onChange={(event) => {
                    const field = SUBROW_RELATION_COLUMNS_MAP.KEEP_OUT;
                    updateSolarRowStatus(solarRow.id, field, event, splittedRowName);
                  }}
                />
              </TableCell>
            </StripedTableRow>
          );
        })}
      </StripedTable>
    </Grid>
  );
});
export default SolarRowsTable;
