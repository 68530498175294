import React from 'react';
import SwapLogo from '../assets/Swap-Logo-Red.png';

export const LoadingPage = () => {
  return (
    <div style={{ display: 'flex', height: '100vh', width: '100vw', alignItems: 'center', justifyContent: 'center' }}>
      <img style={{ scale: '0.4' }} src={SwapLogo} alt="SWAP Logo" />
    </div>
  );
};
