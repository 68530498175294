/* eslint-disable no-unused-expressions */
import { Button, FormControl, Grid, makeStyles, Typography, TextField, Checkbox, TableCell, Icon, Fade } from '@material-ui/core';
import { useObserver } from 'mobx-react-lite';
import React, { useEffect, useState, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { ROUTE_EDIT_WAY_POINTS, ROUTE_CHAPERONE, ROUTE_REPEAT } from './routes';
import { useStores } from '../store/root/root.store';
import { MapPathDialog } from '../components/dialogs/map-path.dialog';
import LoadingDialog from '../components/dialogs/loading-dialog.dialog';
import ActionsDialog from '../components/dialogs/actions.dialog';
import RestartRobotDialog from '../components/dialogs/actions.dialog';
import ConnectionErrorDialog from '../components/dialogs/connection-error.dialog';
import { ChaperoneRobotConnectionService } from '../services/chaperone/robot-connection.service';
import { AddSpeed } from '../components/dialogs/add-slow-speed.dialog';
import axios from 'axios';
import { NON_SOLAR_LAWN_MOWING, SOLAR_LAWN_MOWING } from '../utils/constants';
import GridMap from '../components/gridMaps/GridMap';
import { EStopIndicator } from '../components/control/e-stop-indicator';
import { isDevMode } from '../utils/ui.utils';
import FirstState from '../assets/images/state_0.png';
import SecondState from '../assets/images/state_1.png';
import ThirdState from '../assets/images/state_2.png';
import ForthState from '../assets/images/state_3.png';
import FifthState from '../assets/images/unknown_state.png';
import { StripedTableRow } from '../components/core/striped-table-row.component';
import { StripedTable } from '../components/core/striped-table.component';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  loader: {
    color: theme.palette.inverted.main
  },
  TextField: {
    backgroundColor: '#C0C0C0',
    height: 45,
    width: 150
  },
  formControlTextField: {
    paddingLeft: theme.spacing(10),
    margin: theme.spacing(1)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 240
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1),
    width: 150
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 300
  },
  controls: {
    marginBottom: theme.spacing(1)
  },
  uppercase: {
    textTransform: 'uppercase'
  },
  marker: {
    margin: theme.spacing(1)
  },
  Button: {
    margin: theme.spacing(2),
    minWidth: 100
  },
  tableActions: {
    '& > * + *': {
      marginLeft: theme.spacing(2)
    }
  }
}));

export const TeachSubSectionPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const [showMapPathDialog, setShowMapPathDialog] = useState(false);
  const [finishTeaching, setFinishTeaching] = useState(false);
  const [teachingState, setTeachingState] = useState(1);
  const { loc } = useLocation();

  const {
    countriesStore,
    regionsStore,
    applicationStore,
    controlStore,
    sectionStore,
    subsectionStore,
    chaperonePropertyStore,
    autonomyRobotStore
  } = useStores();
  const robotConnection = useRef(null);
  const robotConnectionService = useRef(null);
  const willContinueRepeating = useRef(false);
  const finishedTeachingNotSavedSubsectionId = useRef(null);
  const [frequency, setFrequency] = useState('7');
  const [threshold, setThreshold] = useState('10');
  const [subSectionName, setSubSectionName] = useState('');
  const [subsectionId, setSubsectionId] = useState(-1);
  const [subSectionNameError, setSubSectionNameError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('');
  const [executionPeriod, setExecutionPeriod] = useState(0);
  const [finishDateTime, setFinishDateTime] = useState(0);
  const [kickOut, setKickOut] = useState(false);
  const [startWithKickout, setStartWithKickout] = useState(false);
  const [userRobot, setUserRobot] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [moveslower, setSlowMove] = useState(false);
  const [openAddSpeedDialog, setOpenAddSpeedDialog] = useState(false);
  const [openConfirmRestartRobotDialog, setOpenConfirmRestartRobotDialog] = useState(false);
  const [openEmptyWayPointsDialog, setOpenEmptyWayPointsDialog] = useState(false);
  const [isRecordPerimeter, setIsRecordPerimeter] = useState(false);
  const [positionError, setPositionError] = useState(false);
  const [connectionError, setConnectionError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isSuspendedTeachingDialogOpen, setIsSuspendedTeachingDialogOpen] = useState(false);
  const [isSuspendedRepeatingDialogOpen, setIsSuspendedRepeatingDialogOpen] = useState(false);
  const subsectionsBucket = applicationStore.getEnvironment().SubsectionsBucket;
  const [resetRobot, setResetRobot] = useState(false);
  const [subsectionMeta, setSubsectionMeta] = useState({});
  const userName = localStorage.getItem('username');

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
      return;
    }
    setConnectionError(true);
    setErrorMessage("An error occurred, check the robot's internet connection and if the issue occurs again, try to reboot the robot.");
  };

  useEffect(
    () => () => {
      if (finishedTeachingNotSavedSubsectionId.current) {
        subsectionStore.deleteSubsection({ id: finishedTeachingNotSavedSubsectionId.current });
      }
    },
    []
  );

  const isGrassCutting = [SOLAR_LAWN_MOWING, NON_SOLAR_LAWN_MOWING].includes(autonomyRobotStore.getSelectedRobot().use_case);

  const fetchCurrentSubsectionMeta = async (subsectionId) => {
    const response = await axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`);
    return response.data.results;
  };

  const handleReleaseRobot = async () => {
    const currentSubsectionMeta = await fetchCurrentSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    setSubsectionMeta(currentSubsectionMeta);
    console.log('ReleaseCmdToRobot: ', `CANCEL,swapautonomy`, null, 'REPEAT');
    sendTeachCmdToRobot(`CANCEL,swapautonomy`, null, 'REPEAT');
    setSubsectionId(-1);
    setSubSectionName('');
    setIsSuspendedRepeatingDialogOpen(false);
  };

  const handleSuspendedRepeatingDialogGoBack = async () => {
    // const robotState = autonomyRobotStore.getSelectedRobot()?.status;
    willContinueRepeating.current = true;
    const currentSubsectionMeta = await fetchCurrentSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
    push({
      pathname: ROUTE_REPEAT,
      state: {
        region: currentSubsectionMeta?.section?.property?.region?.id,
        property: currentSubsectionMeta?.section?.property?.id,
        section: currentSubsectionMeta?.section?.id,
        subsection: currentSubsectionMeta?.id,
        robot: autonomyRobotStore.selectedRobotId
      }
    });
  };

  useEffect(() => {
    if (
      autonomyRobotStore.getSelectedRobot().status === 'WPS_SUSPENDED' ||
      autonomyRobotStore.getSelectedRobot().status === 'EXEC_SUSPENDED'
    ) {
      axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/robots/${autonomyRobotStore.selectedRobotId}/status`).then((res) => {
        if (res.data.results.status === 'EXEC_SUSPENDED') {
          fetchSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id, false);
          setIsSuspendedRepeatingDialogOpen(true);
        } else if (res.data.results.status === 'WPS_SUSPENDED') {
          fetchSubsectionMeta(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id, true);
          setIsSuspendedTeachingDialogOpen(true);
          setTeachingState(3);
        }
      });
    }
  }, [autonomyRobotStore]);

  useEffect(() => {
    // setConnecting(true)
    if (loc) {
      robotConnectionService.current = new ChaperoneRobotConnectionService(
        () => {
          // onConnected
          robotConnectionService.current?.ros.subscribeToRobotStateStamped((robot_state) => controlStore.updateRobotState(robot_state));

          robotConnection.current = robotConnectionService.current;
          // setConnecting(false)
        },
        () => {
          // onDisconnect
          console.log('Lost connection to robot');
          if (robotConnectionService.current !== null) sendTeachCmdToRobot(`SUSPEND,swapautonomy`);
          handleConnectionError();
          robotConnection.current = null;
        },
        autonomyRobotStore.getSelectedRobot().serial_number,
        userName,
        'chaperone_teach'
      );
      robotConnectionService.current.connectToRobot(handleConnectionError);
    }
    return () => {
      if (robotConnectionService.current !== null) {
        if (teachingState > 1) {
          sendTeachCmdToRobot(`SUSPEND,swapautonomy`);
        }
        robotConnectionService.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
      if (!willContinueRepeating.current) {
        autonomyRobotStore.clearSelectedRobot();
      }
    };
  }, []);

  useEffect(() => {
    if (resetRobot) {
      setResetRobot(false);
    }
  }, [resetRobot]);

  useEffect(() => {
    if (!loc) {
      push({
        pathname: ROUTE_CHAPERONE,
        loc: {
          region: localStorage.getItem('region'),
          property: localStorage.getItem('property'),
          section: localStorage.getItem('section'),
          useCase: localStorage.getItem('useCase')
        }
      });
    }
    return () => {
      controlStore.resetRobotPosition();
      setResetRobot(true);
    };
  }, []);

  useEffect(() => {
    if (!controlStore.gpsFixStatus.includes('RTK') && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
      setPositionError(true);
      setConnectionError(true);
      setErrorMessage("RTK Error: Can't continue teaching this subsection, the robot does not have an RTK GPS data !");
      if (teachingState === 2) {
        let state = teachingState + 1;
        if (state > 3) state = 2;
        setTeachingState(state);
        sendTeachCmdToRobot(`PAUSE,swapautonomy`);
      }
    }
  }, [controlStore.gpsFixStatus]);

  const handleChangeSubSectionName = (event) => {
    const newSubSectionName = event.target.value;
    setSubSectionName(newSubSectionName);
    const regex = new RegExp(/^[a-z0-9\-]+$/);
    if (regex.test(newSubSectionName)) {
      setSubSectionNameError(false);
    } else {
      setSubSectionNameError(true);
    }
  };

  const handleShowDialog = (message) => {
    setLoading(true);
    setLoadingMessage(message);
  };

  const fetchSubsectionMeta = (subsectionId, changeSubsectionName = false) => {
    axios.get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/subsections/${subsectionId}`).then((res) => {
      setSubsectionMeta(res.data.results);
      if (changeSubsectionName) setSubSectionName(res.data.results.name);
    });
  };

  const sendTeachCmdToRobot = (action, currentSubsectionId, customCommand = null) => {
    const currentRobotSubsection = autonomyRobotStore.getSelectedRobot()?.operating_subsection_id ?? subsectionId;
    const subsection = currentSubsectionId ?? currentRobotSubsection;
    const command = customCommand ?? 'TEACH';
    fetchSubsectionMeta(subsection);
    console.log({ action });
    if (subsection) {
      const argsList =
        action +
        (subsectionMeta?.section?.property?.region?.id || loc?.region) +
        '_' +
        (subsectionMeta?.section?.property?.id || loc?.property) +
        '_' +
        (subsectionMeta?.section?.id || loc?.section) +
        '_' +
        subsection +
        '.csv,' +
        frequency +
        ',' +
        threshold +
        ',' +
        localStorage.getItem('username') +
        ',' +
        isRecordPerimeter.toString();
      console.log({ argsList });
      robotConnection?.current?.ros?.cmdRobotService(command, [argsList], (result) => {
        setLoading(false);
        console.log(result);
        if (result.error_message == '') return;
        console.log('There was the following error with the teach action: ' + result.error_message);
        applicationStore.pushError(
          'Error',
          'The robot encountered an error with this teach action, please report this to the autonomy team if the issue persists'
        );
      });
    }
  };

  const sendCmdToRobot = (action) => {
    robotConnectionService.current.ros.sendSlowZoneCmd(action);
  };

  const changeTeachingState = () => {
    console.log(teachingState);
    if (teachingState === 3) {
      if (!controlStore.gpsFixStatus.includes('RTK') && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
        return;
      } else if (!controlStore.stableLocalization && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
        setPositionError(true);
        setConnectionError(true);
        setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
        return;
      }
      let state = teachingState + 1;
      if (state > 3) state = 2;
      setSubsectionId(autonomyRobotStore.getSelectedRobot()?.operating_subsection_id);
      setTeachingState(state);
      sendTeachCmdToRobot(`RESUME,swapautonomy`);
    } else if (teachingState === 2) {
      let state = teachingState + 1;
      if (state > 3) state = 2;
      setTeachingState(state);
      sendTeachCmdToRobot(`PAUSE,swapautonomy`);
    } else if (teachingState === 1) {
      if (subSectionName && !subSectionNameError) {
        axios
          .get(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/robots/${autonomyRobotStore.selectedRobotId}/status`)
          .then((res) => {
            if (res.data.results.status === 'AVAILABLE' || (res.data.results.status !== 'OFFLINE' && userRobot)) {
              // create new subsection
              return subsectionStore.addSubsection({
                chaperone_name: localStorage.getItem('username'),
                name: subSectionName,
                operation_robot: loc?.useCase,
                section: loc?.section,
                execution_period: Math.floor(executionPeriod),
                recording_date: new Date().toISOString(),
                order_index: subsectionStore.subsections.length + 1,
                finished_teaching: false
              });
            } else {
              throw new Error('robot_in_use');
            }
          })
          .then((res) => {
            if (res?.results?.id) {
              const currentSubsectionId = res.results.id;
              setSubsectionId(currentSubsectionId);
              console.log(res);
              if (startWithKickout) {
                setStartWithKickout(false);
                sendTeachCmdToRobot(`FINISHKICKOUT,swapautonomy`, currentSubsectionId);
                handleShowDialog('We are saving your kicked-out path, you can start teaching in a few seconds.');
              } else {
                if (isDevMode) {
                  console.log('Developing mode...');
                } else if (controlStore.lat && controlStore.lng) {
                  if (
                    !controlStore.gpsFixStatus.includes('RTK') &&
                    Number(controlStore.wpsState) >= 0 &&
                    Number(controlStore.wpsState) < 4
                  ) {
                    setPositionError(true);
                    setConnectionError(true);
                    setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
                    return;
                  } else if (!controlStore.stableLocalization && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
                    setPositionError(true);
                    setConnectionError(true);
                    setErrorMessage(
                      'INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again'
                    );
                    return;
                  }
                  setExecutionPeriod(Date.now());
                  console.log('GPS data is good to allow the robot to goooo!!');
                } else {
                  setConnectionError(true);
                  setErrorMessage(
                    'GPS Error occurred, check the GPS check the GPS and if the issue occurs again, try to reboot the robot!'
                  );
                  return;
                }
                sendTeachCmdToRobot(`START,swapautonomy`, currentSubsectionId);
                if (isDevMode) {
                  console.log('Developing mode...');
                } else {
                  handleShowDialog('We are provisioning the robot now, you can start teaching in a few seconds.');
                }
              }

              let state = teachingState + 1;
              if (state > 3) state = 2;
              setTeachingState(state);
            } else {
              throw new Error('no_subsection');
            }
          })
          .catch((err) => {
            console.log(err);
            if (isDevMode) {
              console.log('Developing mode...');
              return;
            }
            if (err.message === 'robot_in_use') {
              setConnectionError(true);
              setErrorMessage('Robot is already in-use, check it again later or select another robot!');
            } else if (err.message === 'no_subsection') {
              setErrorMessage("The subsection can't be created, please try again!");
            } else {
              setErrorMessage(err.message);
              setConnectionError(true);
            }
          });
      } else {
        if (!subSectionName) applicationStore.pushError('Error', 'The Subsection Name is required');
        else applicationStore.pushError('Error', 'The Subsection Name can only be small characters and numbers');
        return;
      }
    }
    if (errorMessage) {
      setErrorMessage('');
      return;
    }
  };

  const handleVisualize = () => {
    if (!showMapPathDialog) setShowMapPathDialog(true);
  };

  const handleSave = async () => {
    try {
      const mapResponse = await axios.post(`${applicationStore.getEnvironment().ChaperoneBaseUrl}/api/robot/getMap/`, {
        region: loc?.region,
        property: loc?.property,
        section: loc?.section,
        subsection: autonomyRobotStore.getSelectedRobot()?.operating_subsection_id ?? subsectionId
      });

      if (mapResponse.data.data.length >= 5) {
        setOpenEmptyWayPointsDialog(false);
        await subsectionStore.getSubsections(
          countriesStore.selectedCountryId,
          regionsStore.selectedRegionId,
          chaperonePropertyStore.selectedPropertyId,
          sectionStore.selectedSectionId
        );
        finishedTeachingNotSavedSubsectionId.current = null;
        const exist = await subsectionStore.updateSubsection({
          id: subsectionId,
          region_id: loc?.region,
          property_id: loc?.property,
          execution_period: Math.floor(executionPeriod),
          last_edit_date: new Date(finishDateTime).toISOString(),
          recording_date: new Date().toISOString(),
          finished_teaching: true,
          section: loc?.section
        });
        if (!exist) {
          setUserRobot(true);
          setOpenDialog(true);
        }
      } else {
        setOpenEmptyWayPointsDialog(true);
      }
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const handleFinishOrEdit = finishTeaching
    ? async () => {
        await handleSave();
        push({
          pathname: ROUTE_EDIT_WAY_POINTS,
          state: {
            region: loc?.region,
            property: loc?.property,
            section: loc?.section,
            subsection: subsectionId
          }
        });
      }
    : () => {
        finishedTeachingNotSavedSubsectionId.current = subsectionId;
        setTeachingState();
        setFinishTeaching(true);
        sendTeachCmdToRobot(`FINISH,swapautonomy`);
        setExecutionPeriod((Date.now() - executionPeriod) / 1000);
        setFinishDateTime(Date.now());
        handleShowDialog('We are saving the subsection...');
      };

  const handleCancel = () => {
    sendTeachCmdToRobot(`CANCEL,swapautonomy`);
    resetState();
    controlStore.resetStore();
  };

  const handleRestartRobot = () => {
    setOpenConfirmRestartRobotDialog(true);
  };

  const restart = () => {
    robotConnection?.current?.ros?.restartRobotService('robot');
    setOpenConfirmRestartRobotDialog(false);
  };

  const handleTeachNewSubSection = () => {
    resetState();
    setUserRobot(true);
    setOpenEmptyWayPointsDialog(false);
  };

  const handleToggleKick = () => {
    if (kickOut) {
      sendTeachCmdToRobot(`KICKIN,swapautonomy`);
    } else {
      sendTeachCmdToRobot(`KICKOUT,swapautonomy`);
    }
    setKickOut((prevState) => !prevState);
  };

  const resetState = (withKickout) => {
    setStartWithKickout(Boolean(withKickout));
    setFinishTeaching(false);
    setSubSectionName('');
    setExecutionPeriod(0);
    setFinishDateTime(0);
    setLoadingMessage('');
    setTeachingState(1);
    setUserRobot(true);
  };

  const handleStartNew = () => {
    setOpenDialog(false);
    resetState(false);
  };

  const handleKickoutNew = async () => {
    setOpenDialog(false);
    resetState(true);
    sendTeachCmdToRobot(`KICKOUTNEW,swapautonomy`);
    handleShowDialog('We are preparing the kick-out path, you can start teaching in a few seconds.');
    setTimeout(() => {
      if (isDevMode) {
        console.log('Developing mode...');
      } else if (controlStore.lat && controlStore.lng) {
        if (!controlStore.gpsFixStatus.includes('RTK') && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
          setConnectionError(true);
          setErrorMessage('RTK Error, GPS is not Fixed RTK. Try to reboot the robot or check the base-station !');
          return;
        } else if (!controlStore.stableLocalization && Number(controlStore.wpsState) >= 0 && Number(controlStore.wpsState) < 4) {
          setConnectionError(true);
          setErrorMessage('INS Error, INS solution has not initialized. Try driving the robot forwards and back 10m and checking again');
          return;
        }
        setExecutionPeriod(Date.now());
        console.log('GPS data is good to allow the robot to goooo!!');
      } else {
        sendTeachCmdToRobot(`CANCEL,swapautonomy`);
        setConnectionError(true);
        setErrorMessage('GPS  Error, check the GPS and if the issue occurs again, try to reboot the robot!');
        return;
      }
    });
    setTeachingState(1);
    await subsectionStore.getSubsections(
      countriesStore.selectedCountryId,
      regionsStore.selectedRegionId,
      chaperonePropertyStore.selectedPropertyId,
      sectionStore.selectedSectionId
    );
  };

  const handleEndSection = () => {
    setUserRobot(false);
    sendTeachCmdToRobot(`DONE,swapautonomy`);
    push({
      pathname: ROUTE_CHAPERONE,
      loc: {
        region: localStorage.getItem('region'),
        property: localStorage.getItem('property'),
        section: localStorage.getItem('section'),
        useCase: localStorage.getItem('useCase')
      }
    });
  };

  const startStopButtonName = () => {
    if (teachingState === 1 && startWithKickout) return 'I’m finished the kick-out path. Kick-in and start new subsection';
    else if (teachingState === 1 || teachingState === undefined) return 'start';
    else if (teachingState === 2) return 'pause';
    else return 'resume';
  };
  const handleToggleSlow = () => {
    setSlowMove((prevState) => !prevState);
    setOpenAddSpeedDialog(!moveslower);
    if (!openAddSpeedDialog && moveslower) {
      // setSpeed("1.0");
      sendCmdToRobot('SPEED,0.0');
    }
  };

  const handleSubmitSpeedDialog = async (data) => {
    const { speedValue } = data;
    if (speedValue) {
      // setSpeed(speedValue);
      sendCmdToRobot('SLOW,' + speedValue.toString());
    } else {
      // setSpeed("1.0");
      sendCmdToRobot('SLOW,1.0');
    }
    setOpenAddSpeedDialog(false);
  };

  const handleCloseSpeedDialog = () => {
    setOpenAddSpeedDialog(false);
    setSlowMove((prevState) => !prevState);
  };

  const handleCloseErrorDialog = () => {
    setConnectionError(false);
    if (positionError) {
      setPositionError(false);
      return;
    }
    push(ROUTE_CHAPERONE);
  };

  const getStateIcon = (state) => {
    const icons = [FirstState, SecondState, ThirdState, ForthState, FifthState];
    return icons[state];
  };

  return useObserver(() => (
    <Fade in={true}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" className={classes.root}>
        <LoadingDialog show={loading} message={loadingMessage} maxWidth="md" />
        <Grid item xs={4}>
          <Typography variant="h3" className={classes.label}>
            Teach Subsection
          </Typography>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Region:
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              placeholder="Region"
              defaultValue={regionsStore.getById(loc?.region)?.name}
              InputProps={{
                readOnly: true
              }}
              id="filled-basic"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Property:
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              placeholder="Property"
              defaultValue={chaperonePropertyStore.getById(loc?.property)?.name}
              InputProps={{
                readOnly: true
              }}
              id="filled-basic"
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Section:
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              InputProps={{
                readOnly: true
              }}
              defaultValue={sectionStore.getById(loc?.section)?.name}
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              id="filled-basic"
              placeholder="Section"
              variant="outlined"
            />
          </FormControl>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            Sub-section Name:
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              onChange={handleChangeSubSectionName}
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              placeholder="Sub-section Name"
              id="filled-basic"
              variant="outlined"
              value={subSectionName}
            />
          </FormControl>
        </Grid>

        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            WayPoints Separation Threshold(cm):
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              onChange={(e) => {
                setThreshold(e.target.value);
              }}
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              placeholder="10"
              id="filled-basic"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
          <Typography variant="h6" component="span" className={classes.label}>
            WayPoints Recording Frequency(Hz):
          </Typography>

          <FormControl className={classes.formControlTextField}>
            <TextField
              onChange={(e) => {
                setFrequency(e.target.value);
              }}
              InputLabelProps={{
                style: { color: '#FFFFFF' }
              }}
              className={classes.TextField}
              placeholder="7"
              id="filled-basic"
              variant="outlined"
            />
          </FormControl>
        </Grid>
        {isGrassCutting && (
          <Grid item container direction="row" justifyContent="flex-start" alignItems="center" xs>
            <Typography variant="h6" component="span" className={classes.label}>
              Field Perimeter Teaching
            </Typography>

            <FormControl className={classes.formControlTextField}>
              <Checkbox onChange={(e) => setIsRecordPerimeter(e.target.checked)} style={{ color: '#fff' }} value={isRecordPerimeter} />
            </FormControl>
          </Grid>
        )}
        <EStopIndicator
          eStopEngaged={controlStore.estopState || controlStore.swEstopState}
          videoStream={false}
          width={65}
          height={65}
          marginLeft={0}
          marginTop={10}
          marginRight={0}
          marginBottom={-50}
        />
        {!isGrassCutting && (
          <Grid item style={{ marginLeft: '90px' }}>
            <StripedTable headers={['Height', 'Mode']} align="center">
              <StripedTableRow>
                <TableCell align="center">
                  {controlStore.plowHeight === 0 ? 'Active Down' : controlStore.plowHeight === 1 ? 'Up' : 'Float'}
                </TableCell>
                <TableCell align="center">
                  <Icon className={classes.marker}>
                    <img alt="Plow state" src={getStateIcon(controlStore.plowState)} height={30} width={45} />
                  </Icon>
                </TableCell>
              </StripedTableRow>
            </StripedTable>
          </Grid>
        )}
        <Grid container item xs direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
          <Grid container item xs direction="row" justifyContent="space-between" alignItems="flex-start" className={classes.form}>
            <Grid item>
              <Typography variant="h4" className={classes.label}>
                <em>Waypoints Recording</em>
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <Button
              onClick={changeTeachingState}
              style={{
                color: '#fff',
                backgroundColor:
                  teachingState === 1 || teachingState === undefined ? '#2db57b' : teachingState === 2 ? '#FFD101' : '#00FFFF',
                display: !finishTeaching ? 'inline' : 'none'
              }}
              className={classes.Button}
              variant="contained"
              disabled={teachingState === undefined}
            >
              {startStopButtonName()}
            </Button>
            <Button
              onClick={handleVisualize}
              style={{
                display: finishTeaching ? 'inline' : 'none',
                backgroundColor: '#5b83f1'
              }}
              className={classes.Button}
              variant="contained"
              disabled={!finishTeaching}
            >
              Visualize
            </Button>
            <Button
              onClick={handleSave}
              style={{
                display: finishTeaching ? 'inline' : 'none',
                backgroundColor: '#62e762'
              }}
              className={classes.Button}
              variant="contained"
              disabled={!finishTeaching}
            >
              Save
            </Button>
            {teachingState !== 1 && (
              <Button
                onClick={handleFinishOrEdit}
                style={{
                  backgroundColor: finishTeaching ? '#FFD101' : '#62e762'
                }}
                className={classes.Button}
                variant="contained"
              >
                {finishTeaching ? 'Edit' : 'finish'}
              </Button>
            )}
            {teachingState === 2 && (
              <Button
                onClick={handleToggleKick}
                style={{ backgroundColor: kickOut ? '#00FFFF' : '#61b0ec' }}
                className={classes.Button}
                variant="contained"
              >
                {kickOut ? 'Kick in' : 'Kick out'}
              </Button>
            )}

            {teachingState === 2 && (
              <Button
                onClick={handleToggleSlow}
                style={{ backgroundColor: moveslower ? '#0090FF' : '#da8bf1' }}
                className={classes.Button}
                variant="contained"
              >
                {moveslower ? 'End slow zone' : 'Start slow zone'}
              </Button>
            )}
            <Button
              onClick={handleRestartRobot}
              style={{ backgroundColor: '#0a2840', color: '#fff' }}
              className={classes.Button}
              variant="contained"
            >
              RESTART ROBOT
            </Button>
            {teachingState >= 2 && ( //  || finishTeaching when we delete the subsection from the handlecancel method
              <Button
                onClick={handleCancel}
                style={{ backgroundColor: '#ea2840', color: '#fff' }}
                className={classes.Button}
                variant="contained"
              >
                Cancel
              </Button>
            )}
            <ConnectionErrorDialog open={connectionError} handleClose={handleCloseErrorDialog} errorMessage={errorMessage} />
            {showMapPathDialog ? (
              <MapPathDialog
                state={{
                  region: loc?.region,
                  property: loc?.property,
                  section: loc?.section,
                  subsection: subsectionId,
                  teachPage: true
                }}
                show={showMapPathDialog}
                onClose={() => setShowMapPathDialog(false)}
              />
            ) : null}
            <ActionsDialog
              dialogTitle="The robot has been taught this sub-section successfully!"
              open={openDialog}
              actions={[
                { color: 'quaternary', name: 'Start teaching new subsection', variant: 'outlined', handler: handleStartNew },
                { color: 'tertiary', name: 'Kick-out (new subsection)', variant: 'outlined', handler: handleKickoutNew },
                { color: 'secondary', name: 'End section', variant: 'contained', handler: handleEndSection }
              ]}
            />
            <ActionsDialog
              dialogTitle="Invalid teaching process and the robot hasn't been taught this sub-section. If the robot moves and it occurs again, try to refresh the page. If it's presistent, try to reboot the robot!"
              open={openEmptyWayPointsDialog}
              actions={[
                { color: 'primary', name: 'Cancel', variant: 'outlined', handler: handleCancel },
                { color: 'secondary', name: 'Teach new subsection', variant: 'contained', handler: handleTeachNewSubSection }
              ]}
            />
            <ActionsDialog
              dialogTitle="Please note that this robot is currently in the middle of teaching operation and it's currently suspended!"
              open={isSuspendedTeachingDialogOpen}
              actions={[{ color: 'secondary', name: 'Ok', variant: 'contained', handler: () => setIsSuspendedTeachingDialogOpen(false) }]}
            />
            <ActionsDialog
              dialogTitle="The robot is currently suspended and there is a pending repeating operation, do you want to release the robot and cancel the current repeating operation?"
              open={isSuspendedRepeatingDialogOpen}
              actions={[
                { color: 'primary', name: 'Cancel and Release', variant: 'outlined', handler: handleReleaseRobot },
                { color: 'secondary', name: 'Continue repeating', variant: 'contained', handler: handleSuspendedRepeatingDialogGoBack }
              ]}
            />

            <RestartRobotDialog
              actions={[
                { name: 'Restart', color: '#EA2840', textColor: 'white', handler: restart },
                { name: 'Cancel', handler: () => setOpenConfirmRestartRobotDialog(false) }
              ]}
              dialogTitle="Are you sure you want to restart the robot?"
              open={openConfirmRestartRobotDialog}
            />
            <AddSpeed show={openAddSpeedDialog} onSubmit={handleSubmitSpeedDialog} onClose={handleCloseSpeedDialog} />
            {/* <Map robotLat={controlStore.lat} googleMapData={[]} robotLng={controlStore.lng} /> */}
            <GridMap
              gridMapData={[]}
              robotEnabled
              isTeach
              resetRobot={resetRobot}
              robotLng={controlStore.lng}
              robotLat={controlStore.lat}
              robotHeadingRad={controlStore.current_heading_rad}
            />
          </Grid>
        </Grid>
      </Grid>
    </Fade>
  ));
};
