/* eslint-disable no-param-reassign */
import { types, flow } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { SolarSubrow } from './models/solar-subrow.model';
import { getSubBlockSubrows, updateSubrow } from '../../services/api/subrows.service';
import { DIRECTIONS, PATH_TYPES } from '../../utils/constants';
import { alphaNumericOrder } from '../../utils/ui.utils';

const SolarSubrowStoreInternal = types
  .model('SubrowStore', {
    subrows: types.array(SolarSubrow),
    selectedSubrowId: types.maybeNull(types.integer),
    loading: types.boolean,
    currentRegion: types.maybeNull(types.integer),
    currentProperty: types.maybeNull(types.integer),
    currentPathType: types.optional(types.maybeNull(types.string), '')
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedSubrowId,
    getById: (id) => self.subrows.find((r) => r.id === id),
    getByName: (name) => self.subrows.find((el) => el.name === name),
    getSelectedSubrow: () => self.subrows.find((r) => r.id === self.selectedSubrowId) || {},
    getSelectedPathType: () => self.currentPathType,
    isSubBlockRestricted: () => self.subrows.every((subRow) => subRow.is_restricted),
    isSubBlockCompleted: () => self.subrows.every((subRow) => subRow.status === 'COMPLETED')
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(subBlock) {
      return yield getSubBlockSubrows(subBlock, self.currentPathType);
    }),
    getSubrows: flow(function* getSubrows(region, property, subBlock, pathType = self.currentPathType) {
      self.currentRegion = region;
      self.currentProperty = property;
      self.currentPathType = pathType;
      yield self.fetch(subBlock);
    }),
    onFetchSuccess(results) {
      if (self.currentPathType === PATH_TYPES.LEGACY) {
        self.subrows = results
          .map((subrow) => ({
            ...subrow,
            name: `${self.currentProperty}/${subrow.block_id}/subrows/${subrow.order_index}_to_${subrow.order_index + 1}_${
              DIRECTIONS[subrow.direction]
            }__${self.currentRegion}_${self.currentProperty}_${subrow.block_id}.csv`
          }))
          .toSorted(alphaNumericOrder((s) => s.name));
      } else {
        self.subrows = results
          .map((subrow) => ({
            ...subrow,
            name: `${self.currentProperty}/${subrow.block_id}/subrows/${subrow.order_index}_${DIRECTIONS[subrow.direction]}__${
              self.currentRegion
            }_${self.currentProperty}_${subrow.block_id}.csv`
          }))
          .toSorted(alphaNumericOrder((s) => s.name));
      }
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load Sub rows');
    },
    setSelectedSubrow(id) {
      self.selectedSubrowId = id;
    },
    setSelectedPathType(pathType) {
      self.currentPathType = pathType;
    },
    clearSubrows() {
      self.subrows = [];
    },
    clearPathType() {
      self.currentPathType = '';
    },
    updateSubrow: flow(function* updateSubsection(id, subrow) {
      const { results } = yield updateSubrow(id, subrow);
      const updatedSubrows = self.subrows.map((sr) => {
        if (sr.id === results.id) {
          return { ...sr, ...results };
        }
        return sr;
      });
      self.subrows = updatedSubrows;
    }),
    setError(msg) {
      self.throwError(msg);
    }
  }));

export const SubrowStore = types.compose(SolarSubrowStoreInternal, ResourceStore).named('SubrowStore');
