import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { format } from 'date-fns';
import { makeStyles } from '@material-ui/styles';
import { secondsToElapsedTime } from '../../utils/time.utils';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const useStyles = makeStyles(() => ({
  stackedVerticalBarChartContainer: {
    backgroundColor: 'rgba(255,255,255,1)',
    width: '99%',
    height: '100%',
    '& > canvas': { maxHeight: '100%' }
  }
}));

export const colorForStackedVerticalBarChartArray = [
  { borderColor: 'rgb(100, 100, 235)', backgroundColor: 'rgba(100, 100, 235, 0.5)' },
  { borderColor: 'rgba(128, 59, 71,1)', backgroundColor: 'rgba(128, 59, 71,0.5)' },
  { borderColor: 'rgba(139, 171, 128,1)', backgroundColor: 'rgba(139, 171, 128,0.5)' },
  { borderColor: 'rgba(160,160,160,1)', backgroundColor: 'rgba(160,160,160,0.5)' },
  { borderColor: 'rgba(102,102,102,1)', backgroundColor: 'rgba(102,102,102,0.5)' },
  { borderColor: 'rgba(181, 167, 127,1)', backgroundColor: 'rgba(181, 167, 127,0.5)' }
];

function StackedVerticalBarChart({
  chartTitle,
  chartLabel,
  chartLabelType = 'time',
  chartData,
  type,
  showPercentage,
  yAxisTitle,
  yStepSize,
  valueUnit
}) {
  const classes = useStyles();

  const data = {
    labels: chartLabelType === 'time' ? chartLabel.map((time) => format(new Date(time), "MM-dd' - 'HH:mm")) : chartLabel,
    datasets: Object.keys(chartData).map((field, ind) => ({
      label: field.split('_').join(' '),
      data: chartData[field],
      color: '#fff',
      ...colorForStackedVerticalBarChartArray[ind],
      cubicInterpolationMode: ind % 2 === 1 ? 'monotone' : null,
      tension: ind % 2 === 1 ? 0.4 : 0.3
    }))
  };

  const options = {
    responsive: true,
    interaction: {
      mode: 'index',
      intersect: false
    },
    stacked: false,
    plugins: {
      title: {
        display: !!chartTitle,
        text: chartTitle,
        font: {
          size: 20
        }
      },
      legend: {
        display: true,
        labels: {
          color: '#000',
          font: {
            size: 20
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            let value = 0;
            if (type === 'time') {
              value = secondsToElapsedTime(context.raw);
            } else {
              value = context.formattedValue;
            }

            let percentageSuffix = '';
            if (showPercentage) {
              const total = data.datasets.reduce((sum, dataset) => sum + dataset.data[context.dataIndex], 0);
              let percentage = 0;
              if (total > 0) {
                percentage = Math.round((context.raw / total) * 100 * 10) / 10;
              }
              percentageSuffix = ` (${percentage}%)`;
            }

            return `${context.dataset.label}: ${value}${valueUnit ?? ''}${percentageSuffix}`;
          }
        }
      }
    },
    scales: {
      x: {
        stacked: true
      },
      y: {
        stacked: true,
        title: {
          display: !!yAxisTitle,
          text: yAxisTitle,
          font: {
            size: 20
          }
        },
        ticks: {
          stepSize: type === 'time' ? 3600 : yStepSize,
          callback: type === 'time' ? (val) => `${val / 3600}h` : undefined
        }
      }
    }
  };

  return (
    <div className={classes.stackedVerticalBarChartContainer}>
      <Bar options={options} data={data} />
    </div>
  );
}

export default StackedVerticalBarChart;
