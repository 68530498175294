import { types, flow } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { SubBlock } from './models/sub-block.model';
import { addSubBlock, deleteSubBlock, fetchSubBlockById, fetchSubBlocks, updateSubBlock } from '../../services/api/subblocks.service';

const SubBlocksStoreInternal = types
  .model('SubBlocksStore', {
    subBlocks: types.array(SubBlock),
    selectedSubBlockId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedSubBlockId,
    getById: (id) => self.subBlocks.find((r) => r.id === parseInt(id)),
    getByName: (name) => self.subBlocks.find((r) => r.name === name),
    getSelectedSubBlock: () => self.subBlocks.find((r) => r.id === self.selectedSubBlockId) || {},
    getSubBlockIds: () => self.subBlocks.map((subBlock) => subBlock.id)
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      return yield fetchSubBlocks(filter);
    }),
    getApiCreate: flow(function* f(subBlock) {
      return yield addSubBlock(subBlock);
    }),
    getApiUpdate: flow(function* f(subBlock) {
      return yield updateSubBlock(subBlock);
    }),
    onFetchSuccess(results) {
      if (results) {
        const sortedResult = results.sort((a, b) => a.name - b.name);
        self.subBlocks = sortedResult;
      }
    },
    onCreateSuccess(subBlock) {
      return subBlock.results;
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load sub blocks');
    },
    onUpdateSuccess(data) {
      console.log(data);
    },
    onSaveError(error) {
      console.log(error);
    },
    createSubBlock: flow(function* createSubBlock(subBlock) {
      return yield self.create(subBlock);
    }),
    getSubBlocks: flow(function* getSubBlocks(blockId) {
      yield self.fetch(blockId);
    }),
    updateSubBlock: flow(function* updateSubBlock(subBlock) {
      return yield self.update(subBlock);
    }),
    getSubBlockById({ id }) {
      return fetchSubBlockById(id);
    },
    deleteSubBlock({ id }) {
      return deleteSubBlock(id);
    },
    setSelectedSubBlock(id) {
      self.selectedSubBlockId = id;
    },
    clearSelectedSubBlock() {
      self.selectedSubBlockId = null;
    },
    setError(msg) {
      self.throwError(msg);
    },
    clearSubBlocks() {
      self.subBlocks = [];
    },
    checkDuplicates(name, blockId) {
      return self.subBlocks.some((item) => item.name === name && item.block_id === blockId);
    }
  }));

export const SubBlocksStore = types.compose(SubBlocksStoreInternal, ResourceStore).named('SubBlocksStore');
