import React, { useEffect, useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import {
  Button,
  FormControl,
  Grid,
  Box,
  makeStyles,
  MenuItem,
  Typography,
  FormHelperText,
  TextField,
  CircularProgress,
  Fade,
  Backdrop,
  Paper,
  Tooltip,
  IconButton,
  useTheme,
  useMediaQuery
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ROUTE_MANAGE_ROBOTS } from './routes';
import { useStores } from '../store/root/root.store';
import axios from 'axios';
import { OFFLINE, USE_CASES, STATUSES } from '../utils/constants';
import { AddCountry } from '../components/dialogs/add-country.dialog';
import { AddRegion } from '../components/dialogs/add-region.dialog';
import { AddChaperoneProperty } from '../components/dialogs/add-chaperone-property.dialog';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ActionsDialog from '../components/dialogs/actions.dialog';
import { PORTAL_ATTRIBUTES, ROBOT_ATTRIBUTES } from '../utils/information-dialog-content';
import { capitalize } from '../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  normalScreen: {
    padding: theme.spacing(2)
  },
  smallScreen: {
    padding: theme.spacing(0),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 260
  },
  form: {
    marginTop: theme.spacing(4)
  },
  select: {
    backgroundColor: theme.palette.inverted.main,
    padding: theme.spacing(1)
  },
  input: {
    backgroundColor: theme.palette.inverted.main
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150
  },
  titleLable: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 250
  },
  button: {
    height: 40,
    width: 150,
    backgroundColor: '##0000FF'
  },
  anotherButton: {
    height: 40,
    width: 250,
    backgroundColor: '##0000FF'
  },
  helperText: {
    color: 'red'
  },
  placeholder: {
    height: 40
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff'
  },
  circularProgress: {
    color: theme.palette.inverted.main
  },
  backArrow: {
    color: theme.palette.primary.contrastText
  },
  buttonSpacing: {
    marginTop: theme.spacing(2)
  },
  stretch: {
    width: '100%',
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1)
  },
  addNew: {
    color: theme.palette.secondary.main
  }
}));

export const AddRobotPage = () => {
  const classes = useStyles();
  const { push } = useHistory();
  const { regionsStore, applicationStore, countriesStore, chaperonePropertyStore } = useStores();
  const [robots, setRobots] = useState([]);
  const [homeRegion, setHomeRegion] = useState('');
  const [currentRegion, setCurrentRegion] = useState('');
  const [homeProperty, setHomeProperty] = useState('');
  const [homePropertyOptions, setHomePropertyOptions] = useState([]);
  const [currentProperty, setCurrentProperty] = useState('');
  const [currentPropertyOptions, setCurrentPropertyOptions] = useState([]);
  const [useCase, setUseCase] = useState('');
  const [country, setCountry] = useState('');
  const [status, setStatus] = useState('');
  const [serialNumber, setSerialNumber] = useState('');
  const [robotName, setRobotName] = useState('');
  const [formError, setFormError] = useState(false);
  const [duplicates, setDuplicates] = useState([]);
  const [query, setQuery] = React.useState('idle');
  const selectedUseCase = useCase.length ? useCase : localStorage.getItem('useCase');
  const [openAddCountryDialog, setOpenAddCountryDialog] = useState(false);
  const [openAddRegionDialog, setOpenAddRegionDialog] = useState(false);
  const [openAddPropertyDialog, setOpenAddPropertyDialog] = useState(false);
  const [openPortalAttributesDialog, setOpenPortalAttributesDialog] = useState(false);
  const [openRobotAttributesDialog, setOpenRobotAttributesDialog] = useState(false);
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    axios
      .get(`${applicationStore.getChaperoneBaseUrl()}/robots/`)
      .then((res) => setRobots(res.data.results || []))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    countriesStore.getCountries();
  }, [useCase]);

  useEffect(() => {
    if (country) {
      regionsStore.getRegions(country);
    } else {
      regionsStore.getRegions();
    }
  }, [regionsStore, country]);

  useEffect(() => {
    if (homeRegion) chaperonePropertyStore.getProperties(homeRegion);
  }, [chaperonePropertyStore, homeRegion]);

  useEffect(() => {
    setHomeRegion('');
    setHomeProperty('');
    setCurrentRegion('');
    setCurrentProperty('');
    if (country) countriesStore.setSelectedCountry(country);
  }, [country]);

  useEffect(() => {
    setHomeProperty('');
    setCurrentProperty('');
    if (homeRegion) regionsStore.setSelectedRegion(homeRegion);
  }, [homeRegion]);

  const handleSelectHomeRegion = (event) => {
    const region = event.target.value;
    if (region) {
      setHomeRegion(region);
      if (!currentRegion) {
        setCurrentRegion(region);
      }
      axios
        .get(`${applicationStore.getChaperoneBaseUrl()}/properties/`, { params: { region } })
        .then((res) => {
          setHomePropertyOptions(res.data.results || []);
          setCurrentPropertyOptions(res.data.results || []);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleSelectCurrentRegion = (event) => {
    const region = event.target.value;
    if (region) {
      setCurrentRegion(region);
      axios
        .get(`${applicationStore.getChaperoneBaseUrl()}/properties/`, { params: { region } })
        .then((res) => {
          setCurrentProperty('');
          setCurrentPropertyOptions(res.data.results || []);
        })
        .catch((err) => console.log(err));
    }
  };

  const checkDuplicates = () => {
    let duplicates = [];
    const duplicateSerial = robots.find((robot) => robot.serial_number === serialNumber);
    if (duplicateSerial) duplicates.push('serialNumber');
    const duplicateName = robots.find((robot) => robot.name === robotName && robot.home_property_id === homeProperty);
    if (duplicateName) duplicates.push('robotName');
    return duplicates;
  };

  const handleAddRobot = (addAnother = false) => {
    const duplicateFields = checkDuplicates();
    if (duplicateFields.length) {
      setDuplicates(duplicateFields);
      return;
    } else {
      setDuplicates([]);
    }
    if (homeRegion && homeProperty && useCase && serialNumber && robotName && currentRegion && currentProperty && status) {
      setQuery('progress');
      const data = {
        name: robotName,
        serial_number: serialNumber,
        use_case: useCase,
        current_property_id: currentProperty,
        home_property_id: homeProperty,
        last_operation_date: null,
        current_user: localStorage.getItem('username'),
        status
        // operating_subsection_id: '',
      };
      axios
        .post(`${applicationStore.getChaperoneBaseUrl()}/robots/`, data)
        .then((_) => {
          setQuery('success');
          if (addAnother) {
            setRobots([...robots, data]);
            setHomeRegion('');
            setCurrentRegion('');
            setHomeProperty('');
            setHomePropertyOptions([]);
            setCurrentProperty('');
            setCurrentPropertyOptions([]);
            setUseCase('');
            setSerialNumber('');
            setRobotName('');
            setStatus(OFFLINE);
            setFormError(false);
            setDuplicates([]);
          } else {
            setTimeout(() => {
              push(ROUTE_MANAGE_ROBOTS);
            }, 1500);
          }
        })
        .catch((err) => {
          applicationStore.pushError('Error', 'Error creating robot!');
          setQuery('error');
        });
    } else {
      setFormError(true);
    }
  };

  const handleCloseCountryDialog = (countryId) => {
    if (countryId) {
      countriesStore.setSelectedCountry(countryId);
      setCountry(countryId);
    }
    setOpenAddCountryDialog(false);
  };

  const handleCloseRegionDialog = (regionId) => {
    if (regionId) {
      regionsStore.setSelectedRegion(regionId);
      setHomeRegion(regionId);
    }
    setOpenAddRegionDialog(false);
  };

  const handleClosePropertyDialog = (propertyId) => {
    if (propertyId) setHomeProperty(propertyId);
    setOpenAddPropertyDialog(false);
  };

  function requiredText(msg) {
    return <FormHelperText className={classes.helperText}>{msg}</FormHelperText>;
  }

  return useObserver(() => (
    <Fade in={true}>
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        className={isSmall ? classes.smallScreen : classes.normalScreen}
      >
        <Grid item xs={12} container direction="row" justifyContent="flex-start" alignItems="center">
          <Box mb={2} mr={1}>
            <Tooltip title={<Typography>Back</Typography>}>
              <IconButton size="small" className={classes.backArrow} onClick={() => push(ROUTE_MANAGE_ROBOTS)}>
                <KeyboardBackspaceIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box mb={2}>
            <Typography variant="h3" className={classes.titleLable}>
              Add New Robot
            </Typography>
          </Box>
        </Grid>

        <Paper component="form" elevation={0} color="inverted">
          <Box m={2}>
            <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12} container direction="row" justifyContent="center" alignItems="center">
                <Box mt={2} mb={1}>
                  <Typography variant="h4" align="center">
                    Portal Configurations
                  </Typography>
                </Box>
                <Box mt={1}>
                  <Tooltip title={<Typography>Show additional information</Typography>}>
                    <IconButton size="small" onClick={() => setOpenPortalAttributesDialog(true)}>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    id="use-case"
                    select
                    label="Use case"
                    variant="outlined"
                    value={useCase}
                    onChange={(event) => {
                      setUseCase(event.target.value);
                    }}
                    error={!useCase && formError}
                  >
                    {Object.entries(USE_CASES).map(([key, name]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!useCase && formError && requiredText('Required')}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    id="country"
                    select
                    label="Country"
                    variant="outlined"
                    value={country ?? ''}
                    onClick={(event) => {
                      setCountry(event.target.value);
                    }}
                    error={!country && formError}
                  >
                    {countriesStore.countries.map((country) => (
                      <MenuItem key={country.id} value={country.id}>
                        {country.name}
                      </MenuItem>
                    ))}
                    <MenuItem className={classes.addNew} onClick={() => setOpenAddCountryDialog(true)}>
                      Add New Country
                    </MenuItem>
                  </TextField>
                  {!country && formError && requiredText('Required!')}
                </FormControl>
                {openAddCountryDialog && <AddCountry show={openAddCountryDialog} onClose={handleCloseCountryDialog} />}
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    disabled={!country}
                    required
                    id="home-region"
                    select
                    label="Home region"
                    variant="outlined"
                    value={homeRegion ?? ''}
                    onClick={handleSelectHomeRegion}
                    error={!homeRegion && formError}
                  >
                    {regionsStore.regions.length ? (
                      regionsStore.regions.map((region) => (
                        <MenuItem key={region.id} value={region.id}>
                          {region.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No regions</MenuItem>
                    )}
                    <MenuItem className={classes.addNew} onClick={() => setOpenAddRegionDialog(true)}>
                      Add New Region
                    </MenuItem>
                  </TextField>
                  {!homeRegion && formError && requiredText('Required!')}
                </FormControl>
                {openAddRegionDialog && <AddRegion show={openAddRegionDialog} onClose={handleCloseRegionDialog} />}
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    disabled={!homeRegion}
                    required
                    id="home-property"
                    select
                    label="Home property"
                    variant="outlined"
                    value={homeProperty ?? ''}
                    onClick={(e) => {
                      if (e.target.value) {
                        setHomeProperty(e.target.value);
                        if (!currentProperty) setCurrentProperty(e.target.value);
                      }
                    }}
                    error={!homeProperty && formError}
                  >
                    {chaperonePropertyStore.properties.length ? (
                      chaperonePropertyStore.properties.map((property) => (
                        <MenuItem key={property.id} value={property.id}>
                          {property.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No properties</MenuItem>
                    )}
                    <MenuItem className={classes.addNew} onClick={() => setOpenAddPropertyDialog(true)}>
                      Add New Property
                    </MenuItem>
                  </TextField>
                  {!homeProperty && formError && requiredText('Required!')}
                </FormControl>
                {openAddPropertyDialog && <AddChaperoneProperty show={openAddPropertyDialog} onClose={handleClosePropertyDialog} />}
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    id="serial-number"
                    label="Serial number"
                    variant="outlined"
                    value={serialNumber}
                    onChange={(event) => {
                      setSerialNumber(event.target.value);
                      setDuplicates(duplicates.filter((dup) => dup !== 'serialNumber'));
                    }}
                    error={(!serialNumber && formError) || duplicates.includes('serialNumber')}
                  />
                  {!serialNumber && formError && requiredText('Required!')}
                  {duplicates.includes('serialNumber') && requiredText('This serial number already exists!')}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    id="robot-name"
                    label="Robot name"
                    variant="outlined"
                    value={robotName}
                    onChange={(event) => {
                      setRobotName(event.target.value);
                      setDuplicates(duplicates.filter((dup) => dup !== 'robotName'));
                    }}
                    error={(!robotName && formError) || duplicates.includes('robotName')}
                  />
                  {!robotName && formError && requiredText('Required!')}
                  {duplicates.includes('robotName') && requiredText('This robot name already exists!')}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    id="current-region"
                    select
                    label="Current region"
                    variant="outlined"
                    value={currentRegion}
                    onClick={handleSelectCurrentRegion}
                    error={!currentRegion && formError}
                  >
                    {regionsStore.regions.length ? (
                      regionsStore.regions.map((region) => (
                        <MenuItem key={region.id} value={region.id}>
                          {region.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No regions</MenuItem>
                    )}
                  </TextField>
                  {!currentRegion && formError && requiredText('Required!')}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    required
                    disabled={!currentRegion}
                    id="current-property"
                    select
                    label="Current property"
                    variant="outlined"
                    value={currentProperty}
                    onClick={(e) => {
                      if (e.target.value) {
                        setCurrentProperty(e.target.value);
                      }
                    }}
                    error={!currentProperty && formError}
                  >
                    {currentPropertyOptions.length ? (
                      currentPropertyOptions.map((property) => (
                        <MenuItem key={property.id} value={property.id}>
                          {property.name}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No properties</MenuItem>
                    )}
                  </TextField>
                  {!currentProperty && formError && requiredText('Required!')}
                </FormControl>
              </Grid>

              <Grid item xs={12} md={6} container direction="row" justifyContent="center" alignItems="center">
                <FormControl variant="outlined" fullWidth className={classes.formControl}>
                  <TextField
                    select
                    required
                    id="status"
                    label="Current status"
                    variant="outlined"
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                    }}
                    error={!status && formError}
                  >
                    {Object.entries(STATUSES).map(([key, name]) => (
                      <MenuItem key={key} value={key}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                  {!status && formError && requiredText('Required!')}
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                className={classes.buttonSpacing}
                container
                spacing={1}
                direction="row"
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid item>
                  <Button
                    onClick={() => push(ROUTE_MANAGE_ROBOTS)}
                    className={isExtraSmall ? ' ' : classes.button}
                    variant="outlined"
                    color="primary"
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleAddRobot(true)}
                    className={isExtraSmall ? ' ' : classes.anotherButton}
                    variant="outlined"
                    style={{ color: '#0182f5', borderColor: '#0182f5' }}
                  >
                    Save & add another
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    onClick={() => handleAddRobot()}
                    className={isExtraSmall ? '' : classes.button}
                    variant="contained"
                    color="secondary"
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>

              <Backdrop className={classes.backdrop} open={query === 'progress'}>
                {query === 'success' ? (
                  <Typography>Success!</Typography>
                ) : query === 'error' ? (
                  <Typography>Error!</Typography>
                ) : (
                  <Fade in={query === 'progress'} style={{ transitionDelay: '300ms' }} unmountOnExit>
                    <CircularProgress className={classes.circularProgress} />
                  </Fade>
                )}
              </Backdrop>
            </Grid>
          </Box>
        </Paper>
        <ActionsDialog
          open={openPortalAttributesDialog}
          dialogTitle={PORTAL_ATTRIBUTES.title}
          dialogBody={PORTAL_ATTRIBUTES.body}
          onClose={() => setOpenPortalAttributesDialog(false)}
        />
        <ActionsDialog
          open={openRobotAttributesDialog}
          dialogTitle={ROBOT_ATTRIBUTES.title}
          dialogBody={ROBOT_ATTRIBUTES.body}
          onClose={() => setOpenRobotAttributesDialog(false)}
        />
      </Grid>
    </Fade>
  ));
};
