import { client } from '../../utils/axios-instance';

export const getSubsectionCsvData = async (region, property, section, subsection) => {
  const response = await client.get('/file-manager/subsection', { params: { region, property, section, subsection } });
  return response.data;
};

export const uploadEditedSubsection = async (fileData, region, property, section, subsection) => {
  const formdata = new FormData();
  formdata.append('fileData', fileData);
  formdata.append('region', region);
  formdata.append('property', property);
  formdata.append('section', section);
  formdata.append('subsection', subsection);
  const response = await client.post('/file-manager/subsection', formdata);
  return response.data;
};
