import React from 'react';
import BatteryUnknownRoundedIcon from '@material-ui/icons/BatteryUnknownRounded';
import BatteryAlertRoundedIcon from '@material-ui/icons/BatteryAlertRounded';
import Battery20RoundedIcon from '@material-ui/icons/Battery20Rounded';
import Battery30RoundedIcon from '@material-ui/icons/Battery30Rounded';
import Battery50RoundedIcon from '@material-ui/icons/Battery50Rounded';
import Battery60RoundedIcon from '@material-ui/icons/Battery60Rounded';
import Battery80RoundedIcon from '@material-ui/icons/Battery80Rounded';
import Battery90RoundedIcon from '@material-ui/icons/Battery90Rounded';
import BatteryFullRoundedIcon from '@material-ui/icons/BatteryFullRounded';

function getBatteryIcon(percentage, size) {
  // 100 = full
  if (percentage === 100) return <BatteryFullRoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 90-99 = 90
  if (percentage >= 90) return <Battery90RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 80-89 = 90
  if (percentage >= 80) return <Battery80RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 60-79 = 60
  if (percentage >= 60) return <Battery60RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 50-59 = 50
  if (percentage >= 50) return <Battery50RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 30-49 = 30
  if (percentage >= 30) return <Battery30RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 1-29 = 20
  if (percentage >= 1) return <Battery20RoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  // 0 = alert
  if (percentage === 0) return <BatteryAlertRoundedIcon style={{ fontSize: size === 'title' ? '110%' : '' }} />;
  return <BatteryUnknownRoundedIcon />;
}

export default function BatteryIcon({ ...props }) {
  return <>{getBatteryIcon(props.percentage, props.size)}</>;
}
