import React, { useEffect, useState } from 'react';
import './GridMap.css';
import LineChart from './LineChart';

let index = 0;
const styles = {
  chartComponentsContainer: {
    marginTop: '16px',
    width: '100%',
    display: 'grid',
    alignItems: 'center'
  },
  remoteGuardianStyle: {
    bottom: 0,
    right: 0,
    position: 'absolute',
    width: '25%',
    display: 'grid',
    alignItems: 'end',
    border: '3px solid gray'
  },
  chartWrapper: { alignSelf: 'flex-start' }
};

function GridMap({
  gridMapData,
  robotEnabled,
  robotLng,
  robotLat,
  robotHeadingRad,
  resetRobot,
  highlightedPoints,
  customStyle = false,
  currentPositionIndex = 0,
  isTeach
}) {
  const [chartData, setChartData] = useState([]);
  const [robotLocation, setRobotLocation] = useState({ x: '0.0', y: '0.0', angle: 0 });
  const [onlineRobotPath, setOnlineRobotPath] = useState([]);

  useEffect(() => {
    const data = gridMapData
      .map((sample, idx) => ({
        label: `${idx}`,
        lat: sample.lat,
        long: sample.long,
        angle: sample.angle
      }))
      .filter((el) => parseInt(el.label) >= currentPositionIndex);
    setChartData(data);
  }, [gridMapData, currentPositionIndex]);

  useEffect(() => {
    const referencePoint = {
      lat: chartData?.[0]?.lat ?? 0,
      long: chartData?.[0]?.long ?? 0,
      angle: chartData?.[0]?.angle ?? 0
    };
    if (resetRobot) {
      setRobotLocation({ x: '0.0', y: '0.0', angle: 0 });
      setOnlineRobotPath([]);
    } else {
      let diff_angle = robotHeadingRad - referencePoint.angle;
      if (robotLng && robotLat) {
        setRobotLocation({
          lng: parseFloat(robotLng),
          lat: parseFloat(robotLat),
          angle: robotHeadingRad ? diff_angle * 57.2958 : 0
        });
        onlineRobotPath.push({ label: `${index}`, lng: parseFloat(robotLng), lat: parseFloat(robotLat) });
        setOnlineRobotPath(onlineRobotPath);
      }
    }
  }, [robotLng, robotLat, robotHeadingRad, setOnlineRobotPath, onlineRobotPath, resetRobot]);

  return (
    <div style={customStyle ? styles.remoteGuardianStyle : styles.chartComponentsContainer}>
      <div />
      <div style={styles.chartWrapper}>
        {robotEnabled ? (
          <LineChart
            customStyle={customStyle}
            width={430}
            height={430}
            isTeach={isTeach}
            data={chartData}
            robotLocation={robotLocation}
            onlineRobotPath={onlineRobotPath}
          />
        ) : (
          <LineChart width={430} height={430} data={chartData} highlightedPoints={highlightedPoints} />
        )}
      </div>
      <div />
    </div>
  );
}

export default GridMap;
