import React from 'react';
import { FormControl, MenuItem, Grid, Tooltip, CircularProgress, Typography, Box, IconButton } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { observer } from 'mobx-react-lite';
import { capitalize, formatStringForReadbility } from '../../utils/ui.utils';
import { PORTAL_TARGETS, autonomyNavigationModes, attachmentTypes, robotDeploymentTargetTypes } from '../../utils/constants';

/**
 * Child component for edit-robot-dialog. Component handles rendering and user interaction
 * for Robot Configurations section
 * @param {Object} classes Makestyle instance for styling components
 * @param {Boolean} formError Checks if form has error. Errors range from empty fields to invalid values
 * @param {Object} uiParameters An object containing ui state values to handle user interaction
 * @param {Function} handleUiParametersChange Handles changes to values uiParameters state
 * @param {Object} setOpenRobotAttributesDialog Handles rendering tooltip for Robot Attributes.
 * @param {Boolean} isSelectedRobotConnectedROS Checks if the robot currently being configured has an active ROS connection
 * @param {Object} isSelectedRobotInUse Checks if robot is currently being used for autonomous operations
 * @param {Function} robotDeploymentTarget Robot's software deployment target. Current options are Dev, Stage, and Prod
 * @param {Object} setrobotDeploymentTarget Sets robot's deployment
 * @param {Function} autonomyNavigationControllerOptions State variable containing motion controller options
 * @param {Object} autonomyAttachmentControllerOptions State setter for motion controller options
 * @returns None
 */
const RobotConfiguration = observer(
  ({
    classes,
    setOpenRobotAttributesDialog,
    isSelectedRobotConnectedROS,
    isSelectedRobotInUse,
    robotDeploymentTarget,
    setrobotDeploymentTarget,
    formError,
    autonomyNavigationControllerOptions,
    autonomyAttachmentControllerOptions,
    uiParameters,
    handleUiParametersChange,
    portalTarget
  }) => (
    <>
      <Grid item xs={12} style={{ opacity: !portalTarget && '0.4' }} container direction="row" justifyContent="center" alignItems="center">
        <Box mt={2} mb={1} style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}>
          <Typography variant="h4" align="center">
            Robot Configurations
            <Tooltip title={<Typography>Show additional information</Typography>}>
              <IconButton size="small" onClick={() => setOpenRobotAttributesDialog(true)}>
                <InfoOutlinedIcon />
              </IconButton>
            </Tooltip>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
        <Tooltip
          title={
            <span>
              <em>Some Development Branch</em> means that experimental code is deployed on the robot. For more information on our software
              development cycle, see the Robot Configurations Show Additional Information info circle.
            </span>
          }
        >
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="software-branch"
              select
              label="Robot Deployment Target"
              variant="outlined"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
              value={robotDeploymentTarget}
              onChange={(event) => {
                setrobotDeploymentTarget(event.target.value);
              }}
              error={!robotDeploymentTarget && formError}
            >
              {robotDeploymentTargetTypes.map((type) => (
                <MenuItem key={type.id} value={type.id}>
                  {type.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
        <Tooltip
          title={
            <span>
              <em>Teach & Repeat</em> is the default mode,
              <em>Route Following</em> is for infinitely repeating specifically designed paths and Route Following is used for repeating
              paths created via the Route Following page.
            </span>
          }
        >
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="autonomy-navigation-mode"
              name="autonomyNavigationMode"
              select
              label="Autonomy navigation mode"
              variant="outlined"
              disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
              style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
              value={uiParameters.autonomyNavigationMode}
              onChange={(event) => {
                handleUiParametersChange(event, false, false);
              }}
              error={!uiParameters.autonomyNavigationMode && formError}
            >
              {autonomyNavigationModes.map((mode) => (
                <MenuItem key={mode.id} value={mode.id}>
                  {mode.name}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>
        </Tooltip>
      </Grid>
      <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            required
            id="autonomy-navigation-controller"
            select
            label="Autonomy Navigation Controller"
            variant="outlined"
            disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
            style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
            value={uiParameters.autonomyNavigationController}
            onChange={(event) => {
              handleUiParametersChange(event, false, false);
            }}
            error={!uiParameters.autonomyNavigationController && formError}
          >
            {autonomyNavigationControllerOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {formatStringForReadbility(type)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            required
            name="attachmentType"
            id="attachment-type"
            select
            label="Attachment type"
            variant="outlined"
            disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
            style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
            value={uiParameters.attachmentType}
            onChange={(event) => {
              handleUiParametersChange(event, false, false);
            }}
            error={!uiParameters.attachmentType && formError}
          >
            {attachmentTypes.map((type) => (
              <MenuItem key={type.id} value={type.id}>
                {type.name}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6} container direction="row" justifyContent="flex-start" alignItems="center">
        <FormControl variant="outlined" fullWidth className={classes.formControl}>
          <TextField
            required
            name="autonomyAttachmentController"
            id="attachment-controller-type"
            select
            label="Attachment Controller Type"
            variant="outlined"
            disabled={!isSelectedRobotConnectedROS || isSelectedRobotInUse}
            style={{ opacity: (!isSelectedRobotConnectedROS || isSelectedRobotInUse) && '0.5' }}
            value={uiParameters.autonomyAttachmentController}
            onChange={(event) => {
              handleUiParametersChange(event, false, false);
            }}
            error={!uiParameters.autonomyAttachmentController && formError}
          >
            {autonomyAttachmentControllerOptions.map((type) => (
              <MenuItem key={type} value={type}>
                {formatStringForReadbility(type)}
              </MenuItem>
            ))}
          </TextField>
        </FormControl>
      </Grid>
      <Grid
        item
        xs={12}
        md={6}
        style={{ opacity: (!portalTarget || !isSelectedRobotConnectedROS) && '0.5' }}
        container
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
      >
        <Tooltip title="This changes what portal the robot appears on.">
          <FormControl variant="outlined" fullWidth className={classes.formControl}>
            <TextField
              required
              id="portal-target"
              name="portalTarget"
              select
              label="Portal Deployment Target"
              variant="outlined"
              value={portalTarget ?? 'loading'}
              disabled={!portalTarget || !isSelectedRobotConnectedROS || isSelectedRobotInUse}
              onChange={(event) => {
                handleUiParametersChange(event, false, false);
              }}
            >
              {portalTarget ? (
                Object.entries(PORTAL_TARGETS).map(([key, name]) => (
                  <MenuItem key={key} value={key}>
                    {capitalize(name)}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="loading">
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <CircularProgress color="inherit" size="1.125rem" />
                  </Grid>
                </MenuItem>
              )}
            </TextField>
          </FormControl>
        </Tooltip>
      </Grid>
    </>
  )
);

export default RobotConfiguration;
