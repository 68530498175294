import { useEffect, useRef, useState, React, useCallback } from 'react';
import html2canvas from 'html2canvas';
import { observer } from 'mobx-react-lite';
import { Grid, makeStyles, Typography, Box, Fade, Button, useTheme, useMediaQuery, Tooltip, Icon, IconButton } from '@material-ui/core';
import WarningOutlinedIcon from '@material-ui/icons/WarningOutlined';
import Skeleton from '@material-ui/lab/Skeleton';
import { reaction } from 'mobx';
import { Videocam, Update, CloudDownload, Storage, LockOpen, Lock, PhotoCamera, PowerSettingsNew } from '@material-ui/icons';
// import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import BluetoothSearchingIcon from '@material-ui/icons/BluetoothSearching';
import SpeedIcon from '@material-ui/icons/Speed';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import PanToolIcon from '@material-ui/icons/PanTool';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import RobotsTable from '../components/tables/RobotsTable';
import { useStores } from '../store/root/root.store';
import { ChaperoneRobotConnectionService } from '../services/chaperone/robot-connection.service';
import ActionsDialog from '../components/dialogs/actions.dialog';
import MotorDetails from '../components/diagnostics/motor-details.component';
import BatteryDetails from '../components/diagnostics/battery-details.component';
import SafetyDetails from '../components/diagnostics/safety-details.component';
import SwapbotDetails from '../components/diagnostics/software-details.component';
import ControllerDetails from '../components/diagnostics/controller-details.component';
import BluetoothAdapterDetails from '../components/diagnostics/bluetooth-adapter-details.component';
import NetworkDetails from '../components/diagnostics/network-details.component';
import MotorsOpsHours from '../components/diagnostics/motors-ops-hours.component';
import { ROUTE_DIAGNOSTICS } from './routes';
import {
  DEFAULT_NO_DATA,
  isDevMode,
  convertMinutesToHoursMinutes,
  getFileNameSafeDate,
  buildTopicAndPayloadForMqttEstop
} from '../utils/ui.utils';
import PairControllerDialog from '../components/dialogs/pair-controller.dialog';
import SpeedTestDialog from '../components/dialogs/speed-test.dialog';
import BatteryIcon from '../components/diagnostics/battery-icon.component';
import ConfirmActionDialog from '../components/dialogs/confirm-action.dialog';
import AlertModal from '../components/dialogs/alert-modal';
import { BLUETOOTH_SERVICES } from '../utils/constants';
import { MqttClient } from '../realtimeClient';

const useStyles = makeStyles((theme) => ({
  normalScreen: {
    padding: theme.spacing(2)
  },
  extraSmallScreen: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2)
  },
  title: {
    color: theme.palette.inverted.main
  },
  text: {
    color: theme.palette.inverted.main
  },
  propertyText: {
    color: 'black'
  },
  propertySelectionBox: {
    borderRadius: '0.25rem',
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
    backgroundColor: 'white',
    overflow: 'hidden'
  },
  propertySelect: {
    color: 'black'
  },
  skeleton: {
    background: theme.palette.primary.main,
    opacity: '75%'
  },
  skeletonPlaceholder: {
    background: theme.palette.primary.main,
    opacity: '50%'
  },
  robotUtility: {
    height: '15%',
    width: '10%',
    marginLeft: '15px',
    marginBottom: '5px'
  },
  speedTestButton: {
    width: 'fit-content',
    padding: '1px',
    margin: '0px',
    marginLeft: '15px'
  },
  robotUtilityImg: {
    height: '50%',
    width: '50%',
    display: 'inline'
  },
  rotateIcon: {
    animation: '$spin 2s linear infinite'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  '@keyframes spin': {
    '0%': {
      transform: 'rotate(360deg)'
    },
    '100%': {
      transform: 'rotate(0deg)'
    }
  },
  flashIcon: {
    animation: '$flash 2s linear infinite'
  },
  '@keyframes flash': {
    '50%': {
      color: 'red'
    }
  },
  robotLockoutLocked: {
    height: '15%',
    width: '15%',
    marginLeft: '15px',
    marginBottom: '5px',
    color: 'white',
    backgroundColor: '#ef665c'
  },
  robotLockoutUnlocked: {
    height: '15%',
    width: '15%',
    marginLeft: '15px',
    marginBottom: '5px',
    color: 'white',
    backgroundColor: '#00dd00'
  },
  batteryDetailsTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    alignItems: 'center'
  },
  captureBatterySnapshotIcon: {
    fontSize: '40px',
    color: 'Background',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    '&:hover': {
      color: theme.palette.secondary.main
    }
  },
  batteryDetailsSubContainer: {
    display: 'flex',
    marginLeft: '10px',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    alignItems: 'center'
  },
  batterySnapshotIcon: {
    marginLeft: '15px'
  }
}));

// ** SWITCH OVER TO USESTATE?
let lastPing = new Date();
let lastPingFormatted = '';
// let numOfPings = 0;
let lastNumOfPings = -1;
// If no data is recieved after x number of pings, "no data" is displayed for that component
const pingTimeout = 2;
let noData = false;

export const DiagnosticsPage = observer(() => {
  const classes = useStyles();
  const { applicationStore, autonomyRobotStore, authenticationStore } = useStores();
  const robot = autonomyRobotStore.getSelectedRobot();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [robotSelected, setRobotSelected] = useState(false);
  const [displayNoData, setDisplayNoData] = useState(false);
  const [ignoreNoData, setIgnoreNoData] = useState(false);
  const [restartDialog, setRestartDialog] = useState(false);
  const numOfPings = useRef(0);
  const isMountedRef = useRef(null);
  const robotConnectionService = useRef(null);
  const [updating, setUpdating] = useState(false);
  const [clearing, setClearing] = useState(false);
  const [showBluetoothDialog, setShowBluetoothDialog] = useState(false);
  const [showSpeedTestDialog, setShowSpeedTestDialog] = useState(false);
  const [bluetoothServiceStatus, setBluetoothServiceStatus] = useState('');
  const [speedTestStatus, setSpeedTestStatus] = useState('');
  const [pairingMessage, setPairingMessage] = useState('');
  const [speedTestMessage, setSpeedTestMessage] = useState({});
  const [confirmationDialogTitle, setConfirmationDialogTitle] = useState('');
  const [showConfirmationActionDialog, setShowConfirmationActionDialog] = useState(false);
  const robotConnection = useRef(null);
  const userName = localStorage.getItem('username');
  const robotIsEstopped =
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.estop_is_on ||
    autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sw_estop_is_on;
  const isRobotOnLatestVersion =
    autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.version.includes('latest') ||
    autonomyRobotStore.getSelectedRobot()?.robot_state?.deployment_state?.deployment_status.includes('pulling');
  const selectedRobotSerialNumber = autonomyRobotStore.getSelectedRobotSerialNumber();
  const sensingEdgeByPassed = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sensing_edge_bypassed;
  const robotIsSWestopped = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.sw_estop_is_on;
  const [showSensingEdgesByPassedAler, setShowSensingEdgesByPassedAler] = useState(sensingEdgeByPassed);
  const isRobotLockedOut = autonomyRobotStore.getSelectedRobot()?.robot_state?.safety_state?.lockoutState !== '';
  const userEmail = localStorage.getItem('userEmail');
  const isAdmin = authenticationStore.role === 'admin';
  const batteryDetailsComponrntRef = useRef();
  const mqttClient = useRef(null);

  const handleConnectionError = () => {
    if (isDevMode) {
      console.log('Developing mode...');
    }
  };

  const updateRobot = () => {
    if (robot?.robot_state?.deployment_state?.git_update_state === 5) {
      setRestartDialog(true);
      return;
    }
    setUpdating(true);
    robotConnection?.current?.ros?.cmdRobotService('UPDATE', [], (result) => {
      setUpdating(false);
      if (result.error_message === '') {
        setRestartDialog(true);
      } else if (result.error_message !== 'up to date') {
        console.log('There was the following error with updating the robot: ', result.error_message);
        applicationStore.pushError(
          'Error',
          `An error was encountered while trying to apply the updates to
            ${robot?.name}, please contact the autonomy team for assistance.`
        );
      }
    });
  };

  const freeUpRobotStorage = () => {
    setClearing(true);
    robotConnection?.current?.ros?.cmdRobotService('FREE_STORAGE', [], (result) => {
      setClearing(false);
      if (result.error_message !== 'storage has been freed up') {
        console.log('There was the following error with updating the robot: ', result.error_message);
        applicationStore.pushError(
          'Error',
          `An error was encountered while trying to apply the updates to
          ${robot?.name}, please contact the autonomy team for assistance.`
        );
      }
    });
  };

  /** Handler for RUN_SPEED_TEST service call */
  const runSpeedTest = async () => {
    try {
      setShowSpeedTestDialog(true);
      setSpeedTestStatus('loading');
      await robotConnection?.current?.ros?.cmdRobotService('RUN_SPEED_TEST', [], (result) => {
        if (Object.keys(result.data).length !== 0 && result.error_message === '' && result.success) {
          const speedData = JSON.parse(result.data);
          setSpeedTestMessage(speedData);
          setSpeedTestStatus('');
        } else {
          setSpeedTestStatus('error');
          setTimeout(() => {
            setShowSpeedTestDialog(false);
            setSpeedTestMessage({});
            setSpeedTestStatus('');
          }, 3000);
        }
      });
    } catch (e) {
      console.error(`Error running speed test. Error: ${e}`);
      applicationStore.pushError('Error', `This feature does not exist on ${robot?.name}. Please contact autonomy team`);
    }
  };

  useEffect(() => {
    // Confirm the robot has a WebSocket connection to the gateway
    (async () => {
      if (robot?.serial_number) {
        // Running async code in the effect, we need to track component is mounted
        isMountedRef.current = true;
        try {
          // On first load, initiate the robot connection
          if (isMountedRef.current && robotConnection.current === null) {
            console.debug('Connecting to robot ', autonomyRobotStore.selectedRobotId);
            robotConnectionService.current = new ChaperoneRobotConnectionService(
              () => {
                // onConnected
                console.log('Got a connection to robot');
                // To trigger the janus ML if it wasn't active
                robotConnection.current = robotConnectionService.current;
                try {
                  switch (robot.selected_action) {
                    case 'update':
                      updateRobot();
                      break;
                    case 'restart':
                      break;
                    default:
                  }
                  autonomyRobotStore.setSelectedAction(robot.serial_number, '');
                } catch (error) {
                  console.error(error);
                  // Expected output: TypeError: Cannot set properties of undefined (setting 'restarting')
                  // (Note: the exact output may be browser-dependent)
                }
                robotConnectionService?.current?.ros.subscribeToAutonomyLockout((message) =>
                  autonomyRobotStore.setAutonomyLockout(robot.serial_number, message.data)
                );
                robotConnectionService?.current?.ros.subscribeToAutonomyBaseMotionLockout((message) =>
                  autonomyRobotStore.setAutonomyBaseMotionLockout(robot.serial_number, message.data)
                );
              },
              () => {
                // onDisconnect
                console.log('Lost connection to robot');
                // checks if selected robot has disconnected
                /* if (robotConnectionService.current && robotConnectionService.current.hasConnected === true) {
                } */
                if (robotConnectionService.current !== null) {
                  console.log('Lost connection to robot!!');
                  robotConnectionService?.current?.retryConnection();
                  robotConnection.current = null;
                }
              },
              robot?.serial_number,
              userName,
              'diagnostics'
            );
          }
        } catch (error) {
          console.error('Failed to connect', error);
          autonomyRobotStore.clearSelectedRobot();
        }
      }
    })();

    robotConnectionService?.current?.connectToRobot(handleConnectionError);
    // Cleanup function
    return () => {
      isMountedRef.current = false;
      if (robotConnectionService.current !== null) {
        robotConnectionService?.current?.destroy();
        robotConnectionService.current = null;
        robotConnection.current = null;
      }
    };
    // eslint-disable-next-line
  }, [robot]);

  const closeDialogue = () => {
    setRestartDialog(false);
  };

  const restartRobot = () => {
    robotConnection?.current?.ros?.restartRobotService('robot');
  };
  const restartRobotCloseDialogue = () => {
    restartRobot();
    closeDialogue();
  };
  const restartVideo = () => {
    robotConnection?.current?.ros?.restartRobotService('video');
  };

  /**
   * Handles taking a screenshot and saving png of BatteryDetails component
   */
  const handleBatteryScreenshot = useCallback(() => {
    if (!batteryDetailsComponrntRef.current) return;
    const batteryComponent = batteryDetailsComponrntRef.current;

    // Calculate full dimensions of BatteryDewtails component
    const fullWidth = batteryComponent.scrollWidth;
    const fullHeight = batteryComponent.scrollHeight;

    // Create a clone of the BatteryDetails to capture screenshot
    const componentClone = batteryComponent.cloneNode(true);

    // Set clone's styles to remove scroll
    componentClone.style.position = 'absolute';
    componentClone.style.top = '0';
    componentClone.style.left = '0';
    componentClone.style.width = `${fullWidth}px`;
    componentClone.style.height = `${fullHeight}px`;
    componentClone.style.overflow = 'visible'; // Ensure the clone is fully visible

    // Create a container to hold the clone (ensures component is fully visible for screenshot)
    const componentContainer = document.createElement('div');
    componentContainer.style.position = 'absolute';
    componentContainer.style.top = '0';
    componentContainer.style.left = '0';
    componentContainer.style.width = `${fullWidth}px`;
    componentContainer.style.height = `${fullHeight}px`;
    componentContainer.style.overflow = 'hidden'; // Hide overflow to remove scrollbars

    // Append the clone to the container and add the container to the DOM tree
    componentContainer.appendChild(componentClone);
    document.body.appendChild(componentContainer);

    // Capture screenshot
    html2canvas(componentContainer, {
      useCORS: true,
      scrollX: 0,
      scrollY: 0,
      backgroundColor: null
    })
      .then((canvas) => {
        // Create download link
        const dataUrl = canvas.toDataURL('image/png');
        const link = document.createElement('a');
        const date = getFileNameSafeDate();
        const robotSerialNumber = autonomyRobotStore?.getSelectedRobot()?.serial_number;
        const fileName = `${robotSerialNumber}_${date}_battery_data`;
        link.download = fileName;
        link.href = dataUrl;
        link.click();

        // Remove the container and clone after capturing
        document.body.removeChild(componentContainer);
      })
      .catch((err) => {
        console.error(`Error capturing screenshot: ${err}`);
        // Remove the container if an error occurs
        document.body.removeChild(componentContainer);
      });
  }, [batteryDetailsComponrntRef]);

  function BatteryTitle() {
    return (
      <Box mb={1} mt={isSmall ? 4 : 0}>
        <Grid container justifyContent="center" alignItems="center">
          <div className={classes.batteryDetailsTitleContainer}>
            Batteries
            {robot?.robot_state?.control_state?.battery_state?.percentage ? (
              <div className={classes.batteryDetailsSubContainer}>
                <div style={{ marginRight: '5px' }}>{`#(${robot?.robot_state?.control_state?.battery_state?.number_of}) | `}</div>
                <div style={{ marginRight: '5px', display: 'flex', alignItems: 'center' }}>
                  <BatteryIcon percentage={robot?.robot_state?.control_state?.battery_state?.percentage} size="title" />
                  {` ${robot?.robot_state?.control_state?.battery_state?.percentage}% | `}
                </div>
                <div style={{ marginRight: '5px' }}>
                  {`(${(typeof robot?.robot_state?.control_state?.battery_state?.cumulativeCurrent === 'number' &&
                  !isNaN(robot.robot_state.control_state.battery_state.cumulativeCurrent)
                    ? robot.robot_state.control_state.battery_state.cumulativeCurrent
                    : 0
                  ).toFixed(1)} A) | `}
                </div>
                <div>{`(${convertMinutesToHoursMinutes(robot?.robot_state?.control_state?.battery_state?.avgTimeRemaining)})`}</div>
                <div className={classes.batterySnapshotIcon}>
                  <IconButton title="Capture Battery Snapshot" size="large" onClick={handleBatteryScreenshot}>
                    <PhotoCamera className={classes.captureBatterySnapshotIcon} />
                  </IconButton>
                </div>

                <Tooltip title={`Please drive ${robot?.name} back to the charging station. The robot is at critical battery health.`}>
                  <Icon
                    aria-label="expand row"
                    size="medium"
                    style={{
                      position: 'relative',
                      top: '2px',
                      right: '-5px',
                      color: `${robot?.robot_state?.control_state?.battery_state?.percentage > 5 ? '#eed202' : 'red'}`
                    }}
                  >
                    {robot?.robot_state?.control_state?.battery_state?.percentage > 10 ? null : (
                      <WarningOutlinedIcon style={{ fontSize: 25 }} />
                    )}
                  </Icon>
                </Tooltip>
              </div>
            ) : null}
          </div>
        </Grid>
      </Box>
    );
  }

  function SkeletonLoading(props) {
    const { amount, width, height } = props;
    return (
      <Grid container direction="row" justifyContent="center" alignItems="flex-start">
        {Array(amount)
          .fill()
          .map((item, i) => (
            <Skeleton
              key={i}
              variant="text"
              animation={robotSelected ? 'wave' : false}
              className={classes.skeleton}
              width={width}
              height={height}
            />
          ))}
      </Grid>
    );
  }

  const getRobots = async () => {
    autonomyRobotStore.getRobots();
  };

  function handleNewPing(ping) {
    const millisecondsSince = ping && lastPing ? ping.getTime() - lastPing.getTime() : 0;
    const secondsSince = (millisecondsSince / 1000).toFixed(2);
    if (secondsSince > 0) {
      numOfPings.current += 1;
      lastPing = new Date();
      lastPingFormatted = `${String(ping?.getHours()).padStart(2, '0')}:${String(ping?.getMinutes()).padStart(2, '0')}:${String(
        ping?.getSeconds()
      ).padStart(2, '0')} (${secondsSince}s)`;
    }
    return lastPingFormatted;
  }

  useEffect(() => {
    // Fetch robots on mount
    const initializeComponent = async () => {
      await getRobots();
      lastPing = new Date();
      lastPingFormatted = '';
      numOfPings.current = 0;

      if (autonomyRobotStore.selectedRobotId) {
        setRobotSelected(true);
      }
    };

    initializeComponent();

    const idDisposer = reaction(
      () => autonomyRobotStore.selectedRobotId,
      () => {
        numOfPings.current = 0;
        setDisplayNoData(false);
        lastPingFormatted = '';
        setRobotSelected(true);
        setIgnoreNoData(false);
      }
    );

    const pingDisposer = reaction(
      () => autonomyRobotStore.getSelectedRobot()?.last_ping,
      (newPing) => {
        setDisplayNoData(false);
        setIgnoreNoData(false);
        handleNewPing(newPing);
      }
    );

    const interval = setInterval(() => {
      if ((noData && !numOfPings.current) || lastNumOfPings === numOfPings.current) {
        setDisplayNoData(true);
      } else {
        setDisplayNoData(false);
      }
      noData = true;
      lastNumOfPings = numOfPings.current;
    }, pingTimeout * 5000);

    return () => {
      idDisposer();
      pingDisposer();
      clearInterval(interval);
    };
  }, []);

  /**
   * Handles soft power-cycling robot
   */
  const handleSendCommandToRobot = async (command) => {
    await robotConnection?.current?.ros?.cmdRobotService(command, [], (result) => {
      if (result.error_message !== '' || !result) {
        applicationStore.pushError('Error', `Encountered error rebooting ${robot?.name}. Please contact autonomy`);
        console.error('Encountered Error soft power-cycling robot: ');
      }
    });
  };

  /**
   * Handler for executing bluetooth and ps4 controller related services. Handler currently
   * used to pair controller and reset bluetooth adapter.
   * @param {String} command - ROS service name
   * @returns
   */
  const handleBluetoothServiceCall = async (command) => {
    try {
      if (command === 'PAIR_CONTROLLER' && robot?.robot_state?.joystick_state?.connected) {
        applicationStore.pushError('Error', `${robot?.name} is already connected to a PS4 controller.`);
        return;
      }
      setShowBluetoothDialog(true);
      setBluetoothServiceStatus('loading');
      setPairingMessage(BLUETOOTH_SERVICES[command].dialogueMessage);

      await robotConnection?.current?.ros?.cmdRobotService(command, [], (result) => {
        if (result.error_message === '' && result?.success) {
          setBluetoothServiceStatus('success');
          setPairingMessage(BLUETOOTH_SERVICES[command].successMessage);
        } else {
          setBluetoothServiceStatus('error');
          setPairingMessage(result?.error_message);
        }

        setTimeout(() => {
          setShowBluetoothDialog(false);
        }, 4500);
      });
    } catch (error) {
      console.error('Error: Encountered issue with executing service', error);
    }
  };

  /** UI handler for updating or restarting robot */
  const handleUpdateRestartRobot = async () => {
    setShowConfirmationActionDialog(false);
    if (confirmationDialogTitle === 'restart') {
      restartRobot();
    }
    if (confirmationDialogTitle === 'update') {
      updateRobot();
    }
    if (confirmationDialogTitle === 'soft-powercycle') {
      await handleSendCommandToRobot('REBOOT_ROBOT');
    }
    if (confirmationDialogTitle === 'shutdown') {
      await handleSendCommandToRobot('POWER_OFF_ROBOT');
    }
  };
  /** Shows warning dialogue when robot is selected */
  useEffect(() => {
    if (selectedRobotSerialNumber && sensingEdgeByPassed) {
      setShowSensingEdgesByPassedAler(true);
    }
  }, [selectedRobotSerialNumber, sensingEdgeByPassed]);

  /**
   * Established mqtt connection with iot broker
   * Client will be discount when component dismounts
   */
  useEffect(() => {
    (async () => {
      mqttClient.current = new MqttClient(() => {});
      await mqttClient.current.connect();
    })();

    return () => {
      if (mqttClient.current) {
        mqttClient.current.disconnect();
      }
    };
  }, []);

  return (
    <Fade in>
      <Grid container direction="column" className={isSmall ? classes.extraSmallScreen : classes.normalScreen}>
        <ConfirmActionDialog
          title={`Confirm ${confirmationDialogTitle} ${selectedRobotSerialNumber}?`}
          open={showConfirmationActionDialog}
          key="restart-reboot-confirmation"
          action="Confirm"
          body={`You are about to ${confirmationDialogTitle} ${selectedRobotSerialNumber}. Confirm Chaperones have been notified?`}
          handleClose={() => {
            setShowConfirmationActionDialog(false);
          }}
          handleAction={() => {
            handleUpdateRestartRobot();
          }}
          backdropProps={{ style: { position: 'absolute' } }}
        />
        <PairControllerDialog open={showBluetoothDialog} status={bluetoothServiceStatus} message={pairingMessage} />
        <SpeedTestDialog
          open={showSpeedTestDialog}
          status={speedTestStatus}
          data={speedTestMessage}
          close={() => {
            setSpeedTestStatus('');
            setSpeedTestStatus({});
            setShowSpeedTestDialog(false);
          }}
        />
        <AlertModal
          open={showSensingEdgesByPassedAler}
          title={`Sensing Edges Bypassed On ${robot.name}`}
          body="Sensing Edges are bypassed. Please alert the Service Team Immediately!"
          onClose={() => setShowSensingEdgesByPassedAler(false)}
        />
        {/* First Row (name, serial number, last updated) */}
        <Grid item xs={12} container direction="row">
          <Grid item xs={robot?.name ? 6 : 12} md={6} container direction="row">
            <Grid item xs={12}>
              <Typography align="left" variant="h3" className={classes.text}>
                {robot?.name || 'Select a robot...'}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography align="left" variant="h5" className={classes.text}>
                {robot?.serial_number || (isExtraSmall ? null : <>&nbsp;</>)}
              </Typography>
            </Grid>
          </Grid>
          {(!isExtraSmall && !isSmall) || robot?.name ? (
            <Grid item xs={6} container direction="row" justifyContent="flex-end" alignItems="flex-start">
              <Box ml={1}>
                <Typography align="right" variant="h6" className={classes.text} style={{ color: ignoreNoData ? 'red' : 'white' }}>
                  Last Updated:
                  {lastPingFormatted || DEFAULT_NO_DATA}
                </Typography>
              </Box>
            </Grid>
          ) : null}
        </Grid>
        {/* Second Row (all detail components) */}
        <Grid item xs={12} container direction="row" justifyContent="space-between" alignItems="flex-start">
          {/* Left Column */}
          <Grid item xs={12} md={3} container direction="row">
            <Grid item xs={12}>
              <Box mt={1}>
                <RobotsTable
                  selectable
                  location={ROUTE_DIAGNOSTICS}
                  showOnlineOnly
                  realTimeUpdate
                  propertyDropDown
                  robotConnection={robotConnectionService}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box mt={3} mb={2}>
                <Grid container justifyContent="center" alignItems="center">
                  <Typography align="center" variant="h4" className={classes.text}>
                    Software
                  </Typography>
                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={!robotSelected || !robotIsEstopped}
                    onClick={() => {
                      setConfirmationDialogTitle('soft-powercycle');
                      setShowConfirmationActionDialog(true);
                    }}
                    title="Soft Power-cycle Robot"
                  >
                    <PowerSettingsNew />
                  </Button>

                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={!robotSelected || !robotIsEstopped}
                    onClick={() => {
                      setConfirmationDialogTitle('restart');
                      setShowConfirmationActionDialog(true);
                    }}
                    title="Restart Software"
                  >
                    <Update />
                  </Button>
                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={!robotSelected || !robotIsEstopped}
                    onClick={() => {
                      setConfirmationDialogTitle('shutdown');
                      setShowConfirmationActionDialog(true);
                    }}
                    title="Shut down Robot"
                  >
                    <PowerOffIcon />
                  </Button>
                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={!robotSelected}
                    onClick={() => restartVideo()}
                    title="Restart Video"
                  >
                    <Videocam />
                  </Button>

                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={!robotSelected || !robotIsEstopped || isRobotOnLatestVersion || !isAdmin}
                    onClick={() => {
                      setConfirmationDialogTitle('update');
                      setShowConfirmationActionDialog(true);
                    }}
                    title="Update Robot"
                  >
                    <CloudDownload className={updating ? classes.rotateIcon : ''} />
                  </Button>

                  <Button
                    className={classes.robotUtility}
                    alignItems="center"
                    variant="contained"
                    color={theme.palette.primary.contrastText}
                    disabled={robot?.robot_state?.deployment_state?.free_storage > 20 || !robotSelected}
                    onClick={() => freeUpRobotStorage()}
                    title="Free Up Storage"
                  >
                    <Storage className={clearing ? classes.flashIcon : ''} />
                  </Button>
                </Grid>
              </Box>

              {typeof robot?.robot_state?.location_state?.gps_fix_status === 'string' ? (
                <SwapbotDetails />
              ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Box mb={3}>
                    <Typography align="center" variant="h5" className={classes.title}>
                      No Software data avaliable
                    </Typography>
                  </Box>
                </Grid>
              ) : (
                <SkeletonLoading amount={3} width={500} height={30} />
              )}
            </Grid>
          </Grid>
          {/* Middle Column */}
          {!(displayNoData && robot?.name) || ignoreNoData ? (
            <>
              <Grid
                item
                xs={12}
                md={7}
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                spacing={isSmall ? 0 : 4}
              >
                <Grid item xs={12} md={6}>
                  <Typography align="center" variant="h4" className={classes.text}>
                    <BatteryTitle />
                    {robot?.robot_state?.control_state?.battery_state?.battery_info?.length ? (
                      <div>
                        <BatteryDetails componentRef={batteryDetailsComponrntRef} />
                      </div>
                    ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                      <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Box>
                          <Typography align="center" variant="h5" className={classes.title}>
                            No battery data avaliable
                          </Typography>
                        </Box>
                      </Grid>
                    ) : (
                      <SkeletonLoading amount={3} width={1500} height={75} />
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                  <Box mb={1} mt={isSmall ? 3 : 0}>
                    <Typography align="center" variant="h4" className={classes.text}>
                      Bluetooth Adapter
                    </Typography>
                  </Box>
                  {robot?.robot_state?.joystick_state?.bluetooth_adapter?.length ? (
                    <Box mb={isSmall ? 3 : 0}>
                      <BluetoothAdapterDetails />
                    </Box>
                  ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Box mb={isSmall ? 3 : 0}>
                        <Typography align="center" variant="h5" className={classes.title}>
                          No Bluetooth data avaliable
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <SkeletonLoading amount={3} width={1500} height={75} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box mb={1}>
                    <Typography align="center" variant="h4" className={classes.text}>
                      Motors
                    </Typography>
                  </Box>
                  {robot?.robot_state?.control_state?.motors?.length ? (
                    <MotorDetails />
                  ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Box>
                        <Typography align="center" variant="h5" className={classes.title}>
                          No motor data avaliable
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <SkeletonLoading amount={3} width={1500} height={75} />
                  )}
                </Grid>
              </Grid>
              {/* Right Column */}
              <Grid item xs={12} md={2} container direction="row" justifyContent="center" alignItems="flex-start">
                <Grid item xs={12}>
                  <Box mb={1} mt={isSmall ? 3 : 0}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography align="center" variant="h4" className={classes.text}>
                        Controller
                      </Typography>
                      <Button
                        className={classes.robotUtility}
                        variant="contained"
                        disabled={!robotSelected || robot?.robot_state?.joystick_state?.connected}
                        onClick={() => handleBluetoothServiceCall('PAIR_CONTROLLER')}
                        title="Pair PS4 Controller"
                      >
                        <BluetoothSearchingIcon fontSize="medium" />
                      </Button>
                      <Button
                        className={classes.robotUtility}
                        variant="contained"
                        disabled={!robotSelected}
                        onClick={() => handleBluetoothServiceCall('RESET_BLUETOOTH_ADAPTER')}
                        title="Reset Bluetooth Adapter"
                      >
                        <RotateLeftIcon fontSize="medium" />
                      </Button>
                    </Grid>
                  </Box>
                  {typeof robot?.robot_state?.joystick_state?.connected === 'boolean' ? (
                    <ControllerDetails />
                  ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Box>
                        <Typography align="center" variant="h5" className={classes.title}>
                          No controller data available
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <SkeletonLoading amount={3} width={500} height={30} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box mt={3} mb={1}>
                    <Grid container justifyContent="center" alignItems="center">
                      <Typography align="center" variant="h4" className={classes.text}>
                        Safety
                      </Typography>
                      <Button
                        className={isRobotLockedOut ? classes.robotLockoutUnlocked : classes.robotLockoutLocked}
                        variant="contained"
                        disabled={!robotSelected}
                        onClick={() => robotConnection?.current?.ros?.cmdSwEstop(!isRobotLockedOut, userEmail)}
                        title={!isRobotLockedOut ? 'Lock Robot Control' : 'Unlock Robot Control'}
                      >
                        {isRobotLockedOut ? <LockOpen /> : <Lock />}
                      </Button>
                      <Button
                        className={robotIsSWestopped ? classes.robotLockoutUnlocked : classes.robotLockoutLocked}
                        variant="contained"
                        disabled={!robotSelected}
                        onClick={async () => {
                          const [topic, payload] = buildTopicAndPayloadForMqttEstop(!robotIsSWestopped, userEmail, robot?.serial_number);
                          await robotConnection?.current?.ros?.cmdSwEstop(!robotIsSWestopped);
                          await mqttClient.current.publish(topic, payload);
                        }}
                        title={!robotIsSWestopped ? 'SW-Estop Roboot' : 'Un-Estop Robot'}
                      >
                        <PanToolIcon />
                      </Button>
                    </Grid>
                  </Box>
                  {typeof robot?.robot_state?.safety_state?.estop_is_on === 'boolean' ? (
                    <SafetyDetails />
                  ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Box mb={3}>
                        <Typography align="center" variant="h5" className={classes.title}>
                          No safety data avaliable
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <SkeletonLoading amount={3} width={500} height={30} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box mt={3} mb={2} display="flex" justifyContent="center" alignItems="center">
                    <Typography align="center" variant="h4" className={classes.text}>
                      Network
                    </Typography>
                    <Button
                      className={classes.speedTestButton}
                      alignItems="center"
                      variant="contained"
                      color={theme.palette.primary.contrastText}
                      onClick={() => {
                        runSpeedTest();
                      }}
                      title="Run Speed Test"
                      disabled={!robotSelected}
                    >
                      <SpeedIcon fontSize="large" />
                    </Button>
                  </Box>
                  {typeof robot?.robot_state?.network_state?.portal_client_count === 'number' ? (
                    <NetworkDetails />
                  ) : numOfPings.current >= pingTimeout || ignoreNoData ? (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Box mb={3}>
                        <Typography align="center" variant="h5" className={classes.title}>
                          No Network data avaliable
                        </Typography>
                      </Box>
                    </Grid>
                  ) : (
                    <SkeletonLoading amount={3} width={500} height={30} />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Box mt={3} mb={1}>
                    <Typography align="center" variant="h4" className={classes.text}>
                      Motors UpTime
                    </Typography>
                  </Box>
                  {robot?.serial_number ? (
                    <MotorsOpsHours serialNumber={robot?.serial_number} />
                  ) : (
                    <SkeletonLoading amount={3} width={500} height={30} />
                  )}
                </Grid>
              </Grid>
            </>
          ) : (
            <Grid item xs={12} md={9}>
              <Box mt={5} mb={1}>
                <Typography align="center" variant="h3" className={classes.title}>
                  No Connection
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography align="center" variant="h5" className={classes.title}>
                  {`Cannot establish a connection to ${robot?.name || ''} (${robot?.serial_number || ''}).`}
                </Typography>
              </Box>
              <Grid container direction="row" justifyContent="center" alignContent="center">
                <Button variant="contained" color="primary" onClick={() => setIgnoreNoData(true)}>
                  <Typography align="center" variant="h6" className={classes.title}>
                    Show Last Avaliable State
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <ActionsDialog
          dialogTitle={`Updates have been applied to ${robot?.name}, would you like to restart the robot so that the changes take effect?`}
          open={restartDialog}
          actions={[
            { color: 'primary', name: 'Not Now', variant: 'outlined', handler: closeDialogue },
            { color: 'secondary', name: 'Restart', variant: 'contained', handler: restartRobotCloseDialogue }
          ]}
        />
      </Grid>
    </Fade>
  );
});
