/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
import React, { useEffect, useState } from 'react';
import {
  Backdrop,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ToggleButton from '@material-ui/lab/ToggleButton';
import SettingsIcon from '@material-ui/icons/Settings';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { format } from 'date-fns';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../store/root/root.store';
import OperationLineChart from '../../components/AdminDashboard/LineChart';
import InfoTile from '../../components/AdminDashboard/InfoTile';
import { guardedClient } from '../../utils/axios-instance';
import OperationPieChart, { colorForPieChartArray } from '../../components/AdminDashboard/PieChart';
import SafetyMetrics from '../../components/AdminDashboard/SafetyMetrics';
import { InfractionsTable } from '../../components/AdminDashboard/InfractionsTable';
import { INFRACTION_TYPES, SNOW_PLOWING, SOLAR_LAWN_MOWING } from '../../utils/constants';
import ReloadIcon from '../../components/reload/reload-icon';
import { getAllUsers } from '../../services/api/users.service';
import { infractionTableKeys, alphaNumericOrder } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  settingButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    '& .MuiIconButton-label': {
      '& svg': {
        fontSize: '3.5rem',

        color: theme.palette.inverted.main
      }
    }
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.inverted.main,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    '& > div': { marginBottom: '5px' },
    maxWidth: 320
  },
  modalCloseButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: '8px',
    '& > button': {
      backgroundColor: theme.palette.secondary.main,
      color: theme.palette.inverted.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main
      }
    }
  },
  pieChartContainer: {
    margin: '50px 10px',
    padding: '5px 10px 15px 10px',
    color: '#FFF',
    border: '1px solid #fff',
    backgroundColor: 'rgba(255,255,255,.1)',
    borderRadius: '8px'
  },
  pieChartHeader: { color: '#fff', textAlign: 'center' },
  toggleGroup: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(0.5)
  },
  titleLable: {
    color: '#fff',
    marginTop: theme.spacing(1)
  },
  toggleButtonGroup: {
    backgroundColor: theme.palette.grey[200]
  },
  safetyChart: {
    marginBottom: theme.spacing(2)
  },
  divider: {
    backgroundColor: 'rgba(256, 256, 256, 0.6)',
    margin: theme.spacing(1),
    width: '98%'
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  viewAllButton: {
    marginLeft: theme.spacing(2)
  }
}));

const getHistoryChartDataArrays = (initialForm) => {
  if (!initialForm?.length) return null;
  const finalForm = Object.keys(initialForm[0]).reduce((acc, key) => ({ ...acc, [key]: [] }), {});
  initialForm.forEach((element) => {
    Object.keys(finalForm).forEach((key) => finalForm[key].push(element[key]));
  });
  return finalForm;
};

const getPieChartDataArrays = (initialForm) => {
  if (!initialForm) return [];

  const finalForm = Object.keys(initialForm).map((field) => {
    const mainData = { data: [], backgroundColor: [], labels: [], borderWidth: 0 };
    const childrenData = { data: [], backgroundColor: [], labels: [], borderWidth: 0 };
    let hasChildren = false;
    let colorCounter = 0;
    initialForm[field].forEach((dataSet) => {
      mainData.data.push(dataSet.value);
      mainData.labels.push(dataSet.title);
      mainData.backgroundColor.push(colorForPieChartArray[colorCounter].borderColor);
      if (dataSet.children) {
        dataSet.children.forEach((childDataSet) => {
          childrenData.data.push(childDataSet.value);
          childrenData.labels.push(childDataSet.title);
          childrenData.backgroundColor.push(colorForPieChartArray[++colorCounter].borderColor);
        });

        hasChildren = true;
      } else {
        childrenData.data.push(dataSet.value);
        childrenData.labels.push(dataSet.title);
        childrenData.backgroundColor.push(colorForPieChartArray[colorCounter++].borderColor);
      }
    });
    mainData.borderColor = mainData.backgroundColor;
    childrenData.borderColor = childrenData.backgroundColor;
    return { title: field, dataSet: hasChildren ? [mainData, childrenData] : [mainData] };
  });
  return finalForm;
};

const SafetyPage = observer(() => {
  const classes = useStyles();
  const { autonomyRobotStore } = useStores();
  const otherInfractionTypes = [INFRACTION_TYPES.ANIMAL, INFRACTION_TYPES.DRIVE_LINE, INFRACTION_TYPES.PANEL];

  const [safetyTilesData, setSafetyTilesData] = useState([]);
  const [safetyHistoryData, setSafetyHistoryData] = useState();
  const [safetyPieData, setSafetyPieData] = useState([]);
  const [showSettingModal, setShowSettingModal] = useState(false);
  const [showTilesSettingModal, setShowTilesSettingModal] = useState(false);
  const [updateTilesOptions, setUpdateTilesOptions] = useState(true);
  const [updateChartsOptions, setUpdateChartsOptions] = useState(true);
  const [safetyStartTime, setSafetyStartTime] = useState(new Date(new Date().setDate(new Date().getDate() - 7)).getTime());
  const [safetyEndTime, setSafetyEndTime] = useState(new Date().getTime());
  const [infractionsStartTime, setInfractionsStartTime] = useState(new Date(new Date().setDate(new Date().getDate() - 1)).getTime());
  const [infractionsEndTime, setInfractionsEndTime] = useState(new Date().getTime());
  const [aggregateOver, setAggregateOver] = useState(1);
  const [aggregateOverUnit, setAggregateOverUnit] = useState('day');
  const [infractionType, setInfractionType] = useState(INFRACTION_TYPES.HUMAN);
  const [selectedRobots, setSelectedRobots] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [snowCheckboxChecked, setSnowCheckboxChecked] = useState(false);
  const [solarCheckboxChecked, setSolarCheckboxChecked] = useState(false);
  const [showInfractions, setShowInfractions] = useState(false);
  const [infractionsByZone, setInfractionsByZone] = useState({});
  const [infractionList, setInfractionList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [viewAllInfractions, setViewAllInfractions] = useState(false); // Controls the state related to the view all infractions button

  useEffect(() => {
    autonomyRobotStore.getRobots();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const data = await getAllUsers();
        setUserList(data.results);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (updateTilesOptions) {
      guardedClient
        .get('/dashboard/infractions/data', {
          params: {
            startTime: infractionsStartTime,
            endTime: infractionsEndTime,
            type: infractionType === 'other' ? otherInfractionTypes : [INFRACTION_TYPES.HUMAN],
            serialNumber: selectedRobots,
            userEmails: selectedUsers
          }
        })
        .then(({ data: { results } }) => {
          const data = results.filter((el) => {
            if (infractionType === 'other') {
              return otherInfractionTypes.includes(el.type);
            }
            return el.type === INFRACTION_TYPES.HUMAN;
          });
          setSafetyTilesData(data);
        });
      guardedClient
        .get('/dashboard/infractions/dataByZone', {
          params: {
            startTime: infractionsStartTime,
            endTime: infractionsEndTime,
            type: infractionType === 'other' ? otherInfractionTypes : [INFRACTION_TYPES.HUMAN],
            serialNumber: selectedRobots,
            userEmails: selectedUsers
          }
        })
        .then(({ data: { results } }) => {
          setInfractionsByZone(results);
        });
      guardedClient
        .get('/dashboard/infractions/pie-data', {
          params: { startTime: infractionsStartTime, endTime: infractionsEndTime, serialNumber: selectedRobots, userEmails: selectedUsers }
        })
        .then(({ data: { results } }) => {
          setSafetyPieData(getPieChartDataArrays(results));
        });
      setUpdateTilesOptions(false);
    }
  }, [setSafetyTilesData, infractionsStartTime, infractionsEndTime, infractionType, updateTilesOptions]);

  useEffect(() => {
    if (updateChartsOptions) {
      guardedClient
        .get('/dashboard/infractions/safety-score', {
          params: { startTime: safetyStartTime, endTime: safetyEndTime, aggregateOver, aggregateOverUnit, serialNumber: selectedRobots }
        })
        .then(({ data: { results } }) => {
          setSafetyHistoryData(getHistoryChartDataArrays(results));
        });
      setUpdateChartsOptions(false);
    }
  }, [safetyStartTime, safetyEndTime, aggregateOver, aggregateOverUnit, safetyHistoryData, updateChartsOptions]);

  const handleShowInfractionsTable = async (zone, direction) => {
    if (!zone && !direction) {
      setViewAllInfractions(true);
    }
    if (!userList.length) {
      const data = await getAllUsers();
      setUserList(data.results);
    }
    const { data } = await guardedClient.get('dashboard/infractions', {
      params: {
        startTime: infractionsStartTime,
        endTime: infractionsEndTime,
        zone,
        direction,
        type: infractionType === 'other' ? otherInfractionTypes : [INFRACTION_TYPES.HUMAN],
        serialNumber: selectedRobots,
        userEmails: selectedUsers
      }
    });
    setInfractionList(data.results);
    setShowInfractions(true);
  };

  const updateInfraction = async (id, infraction, updateInfractionsByZone) => {
    const data = await guardedClient.patch(`dashboard/infractions/${id}`, infraction);
    const newInfraction = data.data.results;
    const newInfractionList = infractionList.map((inf) => {
      if (inf.id === newInfraction.id) {
        return newInfraction;
      }
      return inf;
    });
    setInfractionList(newInfractionList);
    if (updateInfractionsByZone) {
      setUpdateTilesOptions(true);
    }
  };

  const toggleInfractionType = (_, toggleValue) => {
    if (toggleValue !== null) {
      setInfractionType(toggleValue);
      setUpdateTilesOptions(true);
    }
  };

  const compare = (a, b) => {
    const firstSerialNumber = a.serial_number.toLowerCase();
    const secondSerialNumber = b.serial_number.toLowerCase();
    if (firstSerialNumber < secondSerialNumber) {
      return -1;
    }
    if (firstSerialNumber > secondSerialNumber) {
      return 1;
    }
    return 0;
  };

  const addSelectedRobotsByUseCase = (useCase) =>
    setSelectedRobots([...selectedRobots, ...autonomyRobotStore.robots.filter((r) => r.use_case === useCase).map((r) => r.serial_number)]);

  const removeSelectedRobotsByUseCase = (useCase) => {
    const targetSelections = autonomyRobotStore.robots.filter((r) => r.use_case === useCase).map((r) => r.serial_number);
    setSelectedRobots(selectedRobots.filter((r) => !targetSelections.includes(r)));
  };
  const { time: chartLabel, ...chartFullData } = safetyHistoryData || {};
  const chartData = { percentage: chartFullData.percentage };

  return (
    <Fade in>
      <Grid container alignItems="stretch" className={classes.root}>
        <Grid item xs={8}>
          <Typography variant="h2" className={classes.titleLable}>
            Safety Score
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.settingButtonContainer}>
            <ReloadIcon title="Reload subsections" handler={() => setUpdateChartsOptions(true)} style={{ color: 'white' }} />
            <IconButton onClick={() => setShowSettingModal(true)}>
              <SettingsIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item xs={12} className={classes.safetyChart}>
          {!safetyHistoryData ? (
            <h1 style={{ color: '#fff' }}>
              {`There is no enough data in selected time range between ${format(new Date(safetyStartTime), "yyyy-MM-dd' - 'HH:mm")}
                and ${format(new Date(safetyEndTime), "yyyy-MM-dd' - 'HH:mm")}. Please change settings`}
            </h1>
          ) : null}

          {safetyHistoryData ? <OperationLineChart chartLabel={chartLabel} chartData={chartData} safety /> : null}
        </Grid>
        <Grid item xs={12}>
          <Divider variant="middle" className={classes.divider} />
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h2" className={classes.titleLable}>
            Safety Infractions
            {/* Button to see all safety infractions in a single list */}
            <Button className={classes.viewAllButton} size="medium" variant="contained" onClick={() => handleShowInfractionsTable()}>
              View All
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.toggleGroup}>
            <ToggleButtonGroup
              exclusive
              className={classes.toggleButtonGroup}
              value={infractionType}
              onChange={toggleInfractionType}
              aria-label="infraction-type"
            >
              <ToggleButton color="primary" value={INFRACTION_TYPES.HUMAN} aria-label="bold">
                People
              </ToggleButton>
              <ToggleButton color="secondary" value="other" aria-label="italic">
                Other
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={classes.settingButtonContainer}>
            <ReloadIcon title="Reload subsections" handler={() => setUpdateTilesOptions(true)} style={{ color: 'white' }} />
            <IconButton onClick={() => setShowTilesSettingModal(true)}>
              <SettingsIcon />
            </IconButton>
          </div>
        </Grid>
        <Grid item lg={6} md={12} xs={12}>
          <SafetyMetrics metrics={infractionsByZone} showInfractionsTable={handleShowInfractionsTable} />
        </Grid>
        <Grid container item lg={6} md={12} xs={12}>
          {safetyTilesData.map((tile) => (
            <Grid item key={tile.title} xs={6}>
              <InfoTile tile={tile} />
            </Grid>
          ))}
        </Grid>
        <Grid container alignItems="stretch">
          {safetyHistoryData &&
            safetyPieData.map(
              ({ title, dataSet }) =>
                (dataSet && dataSet[0]?.data?.filter(Boolean).length && (
                  <Grid item key={title} md={3} sm={6}>
                    <div className={classes.pieChartContainer}>
                      <h1 className={classes.pieChartHeader}>{title}</h1>
                      <OperationPieChart dataSets={dataSet} />
                    </div>
                  </Grid>
                )) ||
                null
            )}
        </Grid>
        {/* Infractions table  */}
        <InfractionsTable
          key={infractionTableKeys.ZONE_INFRACTIONS}
          infractions={infractionList}
          users={userList}
          show={showInfractions}
          viewAllInfractions={viewAllInfractions}
          onClose={() => {
            setShowInfractions(false);
          }}
          updateInfraction={updateInfraction}
        />
        <Modal
          open={showTilesSettingModal}
          onClose={() => setShowTilesSettingModal(false)}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={showTilesSettingModal}>
            <div className={classes.paper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="start time"
                    value={infractionsStartTime}
                    onChange={(date) => {
                      setInfractionsStartTime(new Date(date).getTime());
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="end time"
                    value={infractionsEndTime}
                    onChange={(date) => {
                      setInfractionsEndTime(new Date(date).getTime());
                    }}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <InputLabel id="users-select-label">Users</InputLabel>
                  <Select
                    multiple
                    labelId="users-select-label"
                    id="select-users"
                    value={selectedUsers}
                    onChange={(event) => setSelectedUsers(event.target.value)}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((email) => {
                          const selectedUser = userList.find((user) => user.email === email);
                          return (
                            <Chip key={email} label={`${selectedUser['custom:firstName']} ${selectedUser['custom:lastName']}`} className={classes.chip} />
                          );
                        })}
                      </div>
                    )}
                  >
                  {userList.toSorted(alphaNumericOrder((u) => u['custom:firstName'])).map((user) => (
                    <MenuItem key={user.email} value={user.email}>
                      {`${user['custom:firstName']} ${user['custom:lastName']}`}
                    </MenuItem>
                  ))}
                  </Select>
                </div>          
                <div>
                  <InputLabel id="robots-select-label">Robots</InputLabel>
                  <Select
                    multiple
                    labelId="robots-select-label"
                    id="select-select"
                    value={selectedRobots}
                    onChange={(event) => setSelectedRobots(event.target.value)}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {autonomyRobotStore.robots
                      .slice()
                      .sort(compare)
                      .map((robot) => (
                        <MenuItem key={robot.serial_number} value={robot.serial_number}>
                          {robot.serial_number}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div>
                  <FormControlLabel
                    checked
                    control={<Checkbox style={{ color: 'red' }} checked={solarCheckboxChecked} />}
                    onChange={(event) => {
                      setSolarCheckboxChecked(event.target.checked);
                      if (event.target.checked) addSelectedRobotsByUseCase(SOLAR_LAWN_MOWING);
                      else removeSelectedRobotsByUseCase(SOLAR_LAWN_MOWING);
                    }}
                    label="Solar robots"
                  />
                  <FormControlLabel
                    checked
                    control={<Checkbox style={{ color: 'red' }} checked={snowCheckboxChecked} />}
                    onChange={(event) => {
                      setSnowCheckboxChecked(event.target.checked);
                      if (event.target.checked) addSelectedRobotsByUseCase(SNOW_PLOWING);
                      else removeSelectedRobotsByUseCase(SNOW_PLOWING);
                    }}
                    label="Snow robots"
                  />
                </div>
                <div className={classes.modalCloseButtonContainer}>
                  <Button
                    onClick={() => {
                      setShowTilesSettingModal(false);
                      setUpdateTilesOptions(true);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </Fade>
        </Modal>
        <Modal
          open={showSettingModal}
          onClose={() => setShowSettingModal(false)}
          className={classes.modal}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500
          }}
        >
          <Fade in={showSettingModal}>
            <div className={classes.paper}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="start time"
                    value={safetyStartTime}
                    onChange={(date) => setSafetyStartTime(new Date(date).getTime())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <KeyboardDateTimePicker
                    margin="normal"
                    id="date-picker-dialog"
                    label="end time"
                    value={safetyEndTime}
                    onChange={(date) => setSafetyEndTime(new Date(date).getTime())}
                    KeyboardButtonProps={{
                      'aria-label': 'change date'
                    }}
                  />
                </div>
                <div>
                  <InputLabel id="aggregate-over-unit">Resolution Unit</InputLabel>
                  <Select
                    labelId="aggregate-over-unit"
                    id="aggregate-over-unit"
                    value={aggregateOverUnit}
                    onChange={(event) => setAggregateOverUnit(event.target.value)}
                  >
                    <MenuItem value="week">Week</MenuItem>
                    <MenuItem value="day">Day</MenuItem>
                    <MenuItem value="hour">Hour</MenuItem>
                  </Select>
                </div>
                <div>
                  <TextField
                    id="standard-basic"
                    label="resolution per unit"
                    type="number"
                    onChange={({ target: { value } }) => {
                      if (!isNaN(value) && value > 0) setAggregateOver(value);
                    }}
                    value={aggregateOver}
                  />
                </div>
                <div>
                  <InputLabel id="robots-select-label">Robots</InputLabel>
                  <Select
                    multiple
                    labelId="robots-select-label"
                    id="select-select"
                    value={selectedRobots}
                    onChange={(event) => setSelectedRobots(event.target.value)}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip key={value} label={value} className={classes.chip} />
                        ))}
                      </div>
                    )}
                  >
                    {autonomyRobotStore.robots
                      .slice()
                      .sort(compare)
                      .map((robot) => (
                        <MenuItem key={robot.serial_number} value={robot.serial_number}>
                          {robot.serial_number}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
                <div>
                  <FormControlLabel
                    checked
                    control={<Checkbox style={{ color: 'red' }} checked={solarCheckboxChecked} />}
                    onChange={(event) => {
                      setSolarCheckboxChecked(event.target.checked);
                      if (event.target.checked) addSelectedRobotsByUseCase(SOLAR_LAWN_MOWING);
                      else removeSelectedRobotsByUseCase(SOLAR_LAWN_MOWING);
                    }}
                    label="Solar robots"
                  />
                  <FormControlLabel
                    checked
                    control={<Checkbox style={{ color: 'red' }} checked={snowCheckboxChecked} />}
                    onChange={(event) => {
                      setSnowCheckboxChecked(event.target.checked);
                      if (event.target.checked) addSelectedRobotsByUseCase(SNOW_PLOWING);
                      else removeSelectedRobotsByUseCase(SNOW_PLOWING);
                    }}
                    label="Snow robots"
                  />
                </div>
                <div className={classes.modalCloseButtonContainer}>
                  <Button
                    onClick={() => {
                      setShowSettingModal(false);
                      setUpdateChartsOptions(true);
                    }}
                  >
                    Done
                  </Button>
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </Fade>
        </Modal>
      </Grid>
    </Fade>
  );
});

export default SafetyPage;
