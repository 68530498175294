import React, { useEffect, useRef, useState } from 'react';
import { withStyles, Button, Grid, makeStyles, Paper, TableCell, Box, Typography } from '@material-ui/core';
import { format } from 'date-fns';
import GoogleMapReact from 'google-map-react';
import { useObserver } from 'mobx-react-lite';
import { useReactToPrint } from 'react-to-print';
import { useEnvironment } from '../../environment';
import { useStores } from '../../store/root/root.store';
import { LabelledValue } from '../core/labelled-value.component';
import { AuditImageList } from './audit-image-list.component';
import { AuditVideoList } from './audit-video-list.component';
import { AuditMapMarker } from './audit-map-marker.component';
import 'reactjs-popup/dist/index.css';
import '../../App.css';
import { AuditRobotStatePopup } from './audit-robot-state-popup';
import { DEFAULT_NO_DATA } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  detailPanel: {
    padding: theme.spacing(2),
    height: '100%'
  },
  innerPanel: {
    height: '100%'
  },
  map: {
    // Have to define a height for the map
    height: '300px'
  },
  cameraImages: {
    maxHeight: '50%',
    overflowY: 'auto'
  },
  cameraLabel: {
    textTransform: 'capitalize'
  },
  table: {
    border: 'none'
  },
  noPrint: {
    '@media print': {
      display: 'none'
    }
  },
  accordianTypography: {
    width: '100%'
  }
}));

withStyles((theme) => ({
  root: {
    borderBottom: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0)
  }
}))(TableCell);

withStyles((theme) => ({
  root: {
    fontWeight: 'normal',
    paddingLeft: theme.spacing(0.25),
    paddingRight: theme.spacing(0)
  }
}))(TableCell);

export const AuditReportsDataDetails = () => {
  const classes = useStyles();
  const { auditReportsStore } = useStores();
  const { defaultDateFormat, defaultTimeFormat, googleMaps } = useEnvironment();
  const [openStateDialog, setOpenStateDialog] = useState(false);
  const [auditImages, setAuditImages] = useState();

  const rootRef = useRef();
  const detailRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => detailRef.current
  });

  return useObserver(() => {
    const record = auditReportsStore.selectedRecord;
    const timestamp = new Date(record?.created_at);
    const lat = record ? record.robot_state_stamped?.robot_state?.location_state?.latitude : googleMaps.defaultCenter.lat;
    const lng = record ? record.robot_state_stamped?.robot_state?.location_state?.longitude : googleMaps.defaultCenter.lng;
    const center = record ? { lat, lng } : googleMaps.defaultCenter;
    const showMapData = auditReportsStore.getCurrentDataView() === 'table';

    if (record && rootRef.current && document.getElementById('map_canvas')) {
      // Timeout just forces the scroll to the iteration after the render, so the scroll places properly
      setTimeout(() => rootRef.current.scrollIntoView({ behavior: 'smooth' }));
    }

    /**
     * Fetches and stores audit images on component mount and whenever the selected record is changed
     */
    useEffect(() => {
      const fetchAuditImages = async () => {
        if (record) {
          const result = await auditReportsStore.getAuditImages();
          setAuditImages(result);
        }
      };
      fetchAuditImages();
    }, [record]);

    return (
      <div ref={rootRef}>
        {record ? (
          <>
            <Paper color="inverted" ref={detailRef} className={classes.detailPanel} elevation={0}>
              <Grid
                item
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
                className={classes.innerPanel}
              >
                {showMapData &&
                record.robot_state_stamped?.robot_state?.location_state?.latitude !== 0 &&
                record.robot_state_stamped?.robot_state?.location_state?.longitude !== 0 &&
                record.robot_state_stamped?.robot_state?.location_state?.latitude !== undefined &&
                record.robot_state_stamped?.robot_state?.location_state?.longitude !== undefined ? (
                  <Grid item xs={12} lg={6} className={`${classes.map} ${classes.noPrint}`}>
                    <GoogleMapReact
                      bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
                      center={center}
                      defaultZoom={18.56 || googleMaps.defaultZoom}
                      options={{
                        streetViewControl: true,
                        overviewMapControl: true,
                        disableDefaultUI: false,
                        zoomControl: true,
                        mapTypeId: 'hybrid',
                        draggable: true
                      }}
                      yesIWantToUseGoogleMapApiInternals
                    >
                      <AuditMapMarker reportRecord={record} lat={lat} lng={lng} />
                    </GoogleMapReact>
                  </Grid>
                ) : null}
                <Grid
                  item
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  xs={12}
                  lg={showMapData ? 6 : 12}
                  spacing={2}
                >
                  <Grid item xs={6}>
                    <LabelledValue label="Date" value={format(timestamp, defaultDateFormat)} />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelledValue label="Time" value={format(timestamp, defaultTimeFormat)} />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelledValue label="Operator" value={record.operator} />
                  </Grid>
                  <Grid item xs={6}>
                    <LabelledValue
                      label="GPS Fix Status"
                      value={record?.robot_state_stamped?.robot_state?.location_state?.gps_fix_status || DEFAULT_NO_DATA}
                    />
                  </Grid>
                </Grid>
                <Grid item container xs={12} className={classes.cameraImages} spacing={2}>
                  {auditImages === null ? (
                    <Typography variant="h6"> No Image or Video Data</Typography>
                  ) : (
                    <AuditImageList images={auditImages} fullWidth={!showMapData} />
                  )}
                </Grid>
                <Grid item xs={12} container direction="column" alignItems="flex-end" justifyContent="center" className={classes.noPrint}>
                  <Box mb={1}>
                    <Button
                      variant="contained"
                      color="secondary"
                      size="large"
                      onClick={() => {
                        setOpenStateDialog(true);
                      }}
                    >
                      Show Full Robot State
                    </Button>
                  </Box>

                  {!record.isVideoFeed && (
                    <Button variant="contained" color="secondary" size="large" onClick={handlePrint}>
                      Export to PDF
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Paper>
            <AuditRobotStatePopup
              audit_record={[record.serial_number, record.created_at, record.robot_state_stamped]}
              isOpen={openStateDialog}
              onClose={() => setOpenStateDialog(false)}
            />
          </>
        ) : null}
      </div>
    );
  });
};
