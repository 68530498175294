import { Dialog, DialogContent, DialogTitle, Grid, IconButton, makeStyles, Typography, TableCell } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import React from 'react';
import { StripedTableRow } from '../core/striped-table-row.component';
import { StripedTable } from '../core/striped-table.component';
import { formatDate } from '../../utils/ui.utils';

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  content: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.light,
    '& > *': {
      color: theme.palette.inverted.main
    }
  },
  closeButton: {
    color: theme.palette.inverted.main,
    minwidth: 100
  },
  TableCell: {
    width: 50
  }
}));

export const SubSectionInfoDialog = ({ subsection, show = false, onClose = () => {} }) => {
  const classes = useStyles();

  return (
    <Dialog open={show} className={classes.root} fullWidth>
      <DialogTitle className={classes.title}>
        <Grid item container direction="row" justifyContent="space-between" alignItems="center">
          <Typography variant="h4" component="p">
            Sub-section Info
          </Typography>
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <Close />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent className={classes.content}>
        <StripedTable>
          <StripedTableRow className={classes.TableCell}>
            <TableCell className={classes.TableCell} align="left">
              Name
            </TableCell>
            <TableCell className={classes.TableCell} align="left">
              {subsection?.name}
            </TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Recording Date</TableCell>
            <TableCell align="left">{formatDate(subsection?.recording_date)}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Execution Period(Minutes)</TableCell>
            <TableCell align="left">{Number((subsection?.execution_period / 60.0).toFixed(2))}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Chaperone Name</TableCell>
            <TableCell align="left">{subsection?.chaperone_name}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Last Edit Date</TableCell>
            <TableCell align="left">{formatDate(subsection?.last_edit_date)}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Use Case</TableCell>
            <TableCell align="left">{subsection?.operation_robot}</TableCell>
          </StripedTableRow>
          <StripedTableRow>
            <TableCell align="left">Address</TableCell>
            <TableCell align="left">{subsection?.street_address}</TableCell>
          </StripedTableRow>
        </StripedTable>
      </DialogContent>
    </Dialog>
  );
};
