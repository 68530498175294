import { Button, Grid, makeStyles, Typography, Fade } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ROUTE_TEACH_ROUTE } from './routes';
import { useStores } from '../store/root/root.store';
import ReloadIcon from '../components/reload/reload-icon';
import RouteRobotFilter from '../components/search/route-robot-filter.component';
import { listRoutes } from '../services/api/routes.service';
import RoutesTable from '../components/tables/RoutesTable';

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(4)
  },
  label: {
    color: theme.palette.primary.contrastText,
    display: 'inline-block',
    verticalAlign: 'middle',
    minWidth: 150,
    fontSize: '36px'
  },
  teachButton: {
    marginTop: theme.spacing(1),
    marginDown: theme.spacing(1),
    height: 80,
    width: 200,
    border: '1px solid #ddd',
    '&:disabled': {
      backgroundColor: 'grey'
    }
  }
}));

export const RouteSelectionPage = observer(() => {
  const { autonomyRobotStore } = useStores();
  const classes = useStyles();
  const { push } = useHistory();
  const [routes, setRoutes] = useState([]);

  const handleTeachRoute = () => {
    autonomyRobotStore.getRobots();
    push({ pathname: ROUTE_TEACH_ROUTE });
  };

  const getRoutes = async () => {
    const routesList = await listRoutes();
    setRoutes(routesList.result);
  };

  useEffect(() => {
    async function getRoutes() {
      const routesList = await listRoutes();
      setRoutes(routesList.result);
    }
    getRoutes();
  }, []);

  return (
    <Fade in={true}>
      <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
        <RouteRobotFilter />
        <Button className={classes.teachButton} onClick={handleTeachRoute} variant="contained" disableElevation color="secondary">
          Teach Route
        </Button>
        <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="stretch" className={classes.form}>
          <Grid item xs={12}>
            <Typography variant="h6" className={classes.label}>
              Routes
            </Typography>
            <ReloadIcon title="Reload routes" handler={getRoutes} style={{ color: 'white' }} />
          </Grid>
          <RoutesTable routes={routes} />
        </Grid>
      </Grid>
    </Fade>
  );
});
