import { types, flow, getEnv } from 'mobx-state-tree';
import { ResourceStore } from './resource.store';
import { Country } from './models/country.model';
import { alphaNumericOrder } from '../../utils/ui.utils';

const CountriesStoreInternal = types
  .model('CountriesStore', {
    countries: types.array(Country),
    selectedCountryId: types.maybeNull(types.integer),
    loading: types.boolean
  })
  .views((self) => ({
    isLoading: () => self.loading,
    hasSelected: () => !!self.selectedCountryId,
    getById: (id) => self.countries.find((r) => r.id === parseInt(id)),
    getByName: (name) => self.countries.find((r) => r.name === name),
    getSelectedCountry: () => self.countries.find((r) => r.id === self.selectedCountryId) || {}
  }))
  .actions((self) => ({
    getApiFetch: flow(function* f(filter) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.fetchCountries();
    }),
    getApiCreate: flow(function* f(country) {
      const { RegionsService } = getEnv(self);
      return yield RegionsService.addCountry(country);
    }),
    onFetchSuccess(results) {
      self.countries = results.toSorted(alphaNumericOrder((c) => c.name));
    },
    onCreateSuccess(created) {
      console.log('sucess');
      //  self.regions.push(created);
    },
    onFetchError(error) {
      console.log(error);
      self.throwError(error?.message || 'Unable to load Countries');
    },
    createCountry: flow(function* createCountry(country) {
      return yield self.create(country);
    }),
    getCountries: flow(function* getCountries() {
      yield self.fetch();
    }),
    // onCreateError(error) {
    //   self.throwError(error?.message || 'Failed to save region');
    // },
    // onSaveError(error) {
    //   self.throwError(error?.message || 'region already exist');
    // },
    setSelectedCountry(id) {
      self.selectedCountryId = id;
    },
    clearSelectedCountry() {
      self.selectedCountryId = null;
    },
    setError(msg) {
      self.throwError(msg);
    },
    checkDuplicates(country) {
      var exist = self.countries.some((item) => country.name === item.name);
      if (exist) return true;
      else return false;
    }
  }));

export const CountriesStore = types.compose(CountriesStoreInternal, ResourceStore).named('CountriesStore');
