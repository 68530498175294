import { types } from 'mobx-state-tree';

const CurrentPathState = types.model({
  path_name: types.maybeNull(types.string),
  path_type: types.maybeNull(types.string)
});

export const NavigationState = types.model({
  wps_state: types.maybeNull(types.number),
  current_path_state: types.maybeNull(CurrentPathState),
  autonomy_navigation_mode: types.maybeNull(types.string),
  autonomy_navigation_controller: types.maybeNull(types.string),
  autonomy_navigation_deviation_check: types.maybeNull(types.boolean),
  autonomy_navigation_deviation_slow_threshold: types.maybeNull(types.number),
  autonomy_navigation_deviation_stop_threshold: types.maybeNull(types.number),
  autonomy_navigation_deviation_slow_speed: types.maybeNull(types.number)
});
